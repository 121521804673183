<template>
  <b-modal
    :id="'add-document-modal'+suffix"
    :ref="'add-document-modal'"
    :modal-class="'has-confirmation-modal'"
    no-close-on-backdrop
    hide-footer
    :title="addDocument ? $t('message.manageDocuments.addDocument') : $t('message.editDocument.title')">
    <div
      :class="drag ? 'drag-zone' : ''"
      @drop.prevent="addFile"
      @dragover="drag = true"
      @dragleave.prevent>
      <div
        class="dragover-mask"
        @dragleave="drag=false" />
      <div
        v-if="contact !== null || contactPersons.length > 1"
        class="mb-2">
        <label>{{ $t('message.calendar.reminder.contact') }}</label>
        <coozzy-multiselect
          id="tenants"
          v-model="contactId"
          :options="contactToList"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :preselect-first="false"
          :taggable="false"
          label="name"
          track-by="id"
          :check-valide="$v.contactId.$dirty && $v.contactId.$error ? 'is-invalid' : ''" />
      </div>
      <coozzy-form-input
        v-model="name"
        class="mb-2"
        :disabled="uploading"
        :state="$v.name.$dirty && $v.name.$error ? false : null"
        :name="$t('message.manageDocuments.name') + '*'" />

      <!--suppress XmlInvalidId -->
      <label for="type">{{ $t('message.manageDocuments.type.title') }}</label>
      <coozzy-form-select
        id="type"
        v-model="type"
        class="mb-2"
        :disabled="uploading"
        :name="$t('message.manageDocuments.type.title')">
        <option
          v-for="(item, index) in availableTypesSorted"
          :key="index"
          :value="item">
          {{ $t(`message.manageDocuments.type.${item.toLowerCase()}`) }}
        </option>
      </coozzy-form-select>
      <!--suppress XmlInvalidId -->
      <label
        v-if="addDocument"
        for="file">{{ $t('message.manageDocuments.document') + '*' }}</label>
      <coozzy-form-file-input
        v-if="addDocument"
        id="file"
        ref="file-input"
        v-model="file"
        accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
        :state="$v.file.$dirty && $v.file.$error ? false : null"
        :disabled="uploading" />
      <div
        v-if="addDocument"
        class="w-100 float-right mt-3">
        <coozzy-button
          size="normal"
          design="green"
          class="float-right ml-3"
          :disabled="uploading"
          @click="uploadClicked">
          {{ $t('message.generic.form.upload') }}
        </coozzy-button>
        <coozzy-form-checkbox
          v-if="!noAddAnother"
          v-model="addAnotherCheckbox"
          class="float-right pr-3 mt-2 mr-0 display-none-xs font-weight-lighter">
          {{ $t('message.generic.uploadMore') }}
        </coozzy-form-checkbox>
        <coozzy-button
          size="normal"
          design="default"
          :disabled="uploading"
          @click="$bvModal.hide('add-document-modal'+suffix)">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
      <div
        v-else
        class="w-100 float-right mt-3">
        <coozzy-button
          size="normal"
          design="green"
          class="float-right ml-3"
          @click="updateDocument">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
        <coozzy-button
          size="normal"
          design="default"
          @click="hideModal">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import MediaApi from '../../misc/apis/MediaApi'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { media } from '@/mixins/media'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'

export default {
  name: 'AddDocumentModal',
  components: { CoozzyFormCheckbox, CoozzyFormSelect, CoozzyFormFileInput, CoozzyButton, CoozzyFormInput, CoozzyMultiselect },
  mixins: [media],
  props: {
    suffix: {
      type: String,
      default: ''
    },
    contactPersons: {
      type: Array,
      default() {
        return []
      }
    },
    contact: {
      type: Object,
      default() {
        return null
      }
    },
    ownerId: {
      type: [String, Number],
      default: 0
    },
    noAddAnother: {
      type: Boolean,
      default: false
    },
    document: {
      type: Object,
      default() {
        return null
      }
    },
    addDocument: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      drag: false,
      contactId: [],
      name: '',
      type: 'OTHER',
      addAnotherCheckbox: false,
      availableTypes: [
        'OTHER',
        'APPLICATION_FORM',
        'HANDOVER_PROTOCOL',
        'CONTRACT',
        'BUILDING_PLAN',
        'INVOICE',
        'HOUSE_REGULATION',
        'INSTRUCTION_MANUAL',
        'CREDIT_CHECK',
        'OWNERS_MEETING',
        'PAY_IN_SLIP',
        'ENERGY_CERTIFICATE',
        'WARRANTY_CERTIFICATE',
        'BANK_STATEMENT',
        'ADDITIONAL_COSTS_BILL',
        'SEPA_DIRECT_DEBIT_MANDATE',
        'AGREEMENT',
        'DEPOSIT_CONFIRMATION',
        'BUILDING_INSURANCE_POLICY',
        'KEY_LIST',
        'TERMINATION_DOC_TYPE',
        'RENT_ADJUSTMENT_DOC_TYPE',
        'REFERENCES_DOC_TYPE',
        'MAINTENANCE_CONTRACT_DOC_TYPE',
        'REGULATIONS',
        'REGULATIONS_FOR_USE_AND_MANAGEMENT',
        'ANNUAL_FINANCIAL_STATEMENT',
        'PURCHASE_CONTRACT',
        'LAND_REGISTER',
        'CONDOMINIUM_OWNERSHIP',
        'RENEWAL_FOND',
        'MORTGAGE',
        'ORDER',
        'OFFER',
        'IDENTITY',
        'EXTRACT_FROM_DEBT_COLLECTION_REGISTER',
        'BANK_ACCOUNT_DETAILS',
        'SITUATION_PLAN',
        'CONSTRUCTION_LAW_AGREEMENT',
        'EASEMENT_AGREEMENT',
        'CADASTRAL_ESTIMATE',
        'LETTER',
        'TRADE_REGISTER_EXCERPT',
        'PAYMENT_REMINDER',
        'PROTOCOL',
        'COURT_DOC_TYPE',
        'HONORARIUM',
        'BUDGET'
      ],
      file: null,
      uploading: false
    }
  },
  computed: {
    contactToList() {
      if (this.contact) {
        return [this.contact].concat(this.contactPersons)
      } else if (this.contactPersons.length > 1) {
        return this.contactPersons
      }
      return []
    },
    availableTypesSorted() {
      const array = this.availableTypes
      array.sort((a, b) => {
        if (this.$t(`message.manageDocuments.type.${a.toLowerCase()}`) < this.$t(`message.manageDocuments.type.${b.toLowerCase()}`)) {
          return -1
        }
        if (this.$t(`message.manageDocuments.type.${a.toLowerCase()}`) > this.$t(`message.manageDocuments.type.${b.toLowerCase()}`)) {
          return 1
        }
        return 0
      })
      return array
    }
  },
  watch: {
    name: {
      handler: function (val) {
        if (val !== '') {
          this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
        }
      }
    },
    type: {
      deep: true,
      handler: function (val) {
        if (val !== 'OTHER') {
          this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
        }
      }
    },
    file: {
      deep: true,
      handler: function (val) {
        if (val !== null) {
          this.drag = false
          this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
          if (val.name) {
            this.updateUploadedFileName(this, val.name)
          }
        }
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'add-document-modal' + this.suffix) {
        this.$v.$reset()

        this.name = ''
        this.contactId = []
        this.file = null
      }
    })
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'add-document-modal' + this.suffix) {
        if (this.contact && !this.contactId.includes(this.contact)) {
          this.contactId.push(this.contact)
        }
        document.addEventListener('drop', function(e) {
          e.preventDefault()
        })
        document.addEventListener('dragover', function(e) {
          e.preventDefault()
        })
        if (this.suffix === 'land-register') {
          this.availableTypes = [
            'SITUATION_PLAN',
            'PURCHASE_CONTRACT',
            'CONSTRUCTION_LAW_AGREEMENT',
            'EASEMENT_AGREEMENT',
            'CADASTRAL_ESTIMATE',
            'OTHER',
            'LAND_REGISTER'
          ]
        } else if (this.suffix === 'floorplan') {
          this.type = 'BUILDING_PLAN'
        } else if (this.suffix?.startsWith('tenancies-adjustments')) {
          this.type = 'RENT_ADJUSTMENT_DOC_TYPE'
        } else if (this.suffix?.startsWith('tenancies') && this.$route.path.includes('/activateTenancy')) {
          this.type = 'CONTRACT'
        } else if (this.suffix?.startsWith('termination-document')) {
          this.type = 'TERMINATION_DOC_TYPE'
        } else if (this.suffix?.startsWith('insurances')) {
          this.type = 'BUILDING_INSURANCE_POLICY'
        } else {
          this.type = 'OTHER'
        }
      }
      if (this.document) {
        this.type = this.document.type
        this.name = this.document.name
        this.$nextTick(() => {
          this.$store.dispatch('user/setChangedModal', false)
        })
      }
    })
    if (this.suffix === 'floorplan') {
      this.type = 'BUILDING_PLAN'
    } else if (this.suffix?.startsWith('tenancies-adjustments')) {
      this.type = 'RENT_ADJUSTMENT_DOC_TYPE'
    } else if (this.suffix?.startsWith('tenancies') && this.$route.path.includes('/activateTenancy')) {
      this.type = 'CONTRACT'
    } else if (this.suffix?.startsWith('termination-document')) {
      this.type = 'TERMINATION_DOC_TYPE'
    }
  },
  methods: {
    show() {
      this.$bvModal.show('add-document-modal' + this.suffix)
    },
    hideModal() {
      this.$bvModal.hide('add-document-modal' + this.suffix)
      this.$emit('hide-modal')
    },
    updateDocument() {
      const document = JSON.parse(JSON.stringify(this.document))
      document.name = this.name
      document.type = this.type
      this.$emit('document-updated', document)
    },
    uploadClicked() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.uploading = true
        this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
        const formData = new FormData()
        const fileName = this.editFileName(this.file.name)
        formData.append('file', this.file, fileName)
        formData.append('filename', this.file.name)
        formData.append('public', 'false')
        if (this.ownerId) {
          formData.append('accountId', this.ownerId.toString())
        }

        MediaApi.uploadMedia(formData)
          .then(media => {
            MediaApi.createDocument(this.ownerId, this.name, this.type, media.id)
              .then(document => {
                this.$emit('document-created', document, this.suffix, this.contactId, this.addAnotherCheckbox)
                this.reset()
                if (this.addAnotherCheckbox === false) {
                  this.hide()
                }
              })
              .catch(e => {
                this.uploading = false
                this.handleError(e)
              })
          })
          .catch(e => {
            this.uploading = false
            if (e.response && e.response.data && e.response.data.message === 'Not supported MIME type') {
              Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
              if (this.$refs['file-input']) this.$refs['file-input'].resetPlaceholder()
              this.file = null
            } else {
              Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
            }
          })
          .finally(() => {
            this.uploading = false
          })
      }
    },
    handleError(e) {
      this.reset()
      if (this.addAnotherCheckbox === false) {
        this.hide()
      }
      console.log(e)
      Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
    },
    reset() {
      this.$v.$reset()
      this.uploading = false
      this.name = ''
      if (this.$refs['file-input']) this.$refs['file-input'].resetPlaceholder()
      if (this.suffix === 'floorplan') {
        this.type = 'BUILDING_PLAN'
      } else if (this.suffix.startsWith('tenancies-adjustments')) {
        this.type = 'RENT_ADJUSTMENT_DOC_TYPE'
      } else {
        this.type = 'OTHER'
      }
      this.file = null
    },
    hide() {
      this.$bvModal.hide('add-document-modal' + this.suffix)
    },
    addFile(event) {
      if (event.dataTransfer?.files?.length) {
        this.file = event.dataTransfer.files[0]
        this.updateUploadedFileName(this, event.dataTransfer.files[0].name)
      }
    }
  },
  validations() {
    const validation = {
      name: {
        required
      },
      file: {
        required
      }
    }

    if (this.contact !== null || this.contactPersons.length > 1) {
      validation.contactId = {
        required
      }
    }

    return validation
  }
}
</script>

<style lang="scss" scoped>
  label {
    margin-bottom: 0;
  }
  .drag-zone  .dragover-mask{
    display: block !important;
    transition: z-index 0s step-end;
    z-index: 2500;;
  }
  .dragover-mask{
    display: none;
    background: rgba(255,255,255,0.3);
    border: 3px dashed $color-primary;
    border-radius: 5px;
    bottom: 0;
    left: 0;
    margin: 2px;
    position: absolute;
    right: 0;
    top: 0;
    transition: z-index .2s step-end;
    z-index: -1;
  }
</style>
