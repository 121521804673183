<template>
  <div
    id="ticketDetail">
    <div
      v-if="ticketDeleted === true"
      class="row">
      <h1 class="col-12 mt-3 mb-3 pl-0 text-center text-lg">
        {{ $t('message.loadingErrors.ticketDeleted') }}
      </h1>
    </div>
    <div
      v-else-if="ticketClosed === true"
      class="row">
      <h1 class="col-12 mt-3 mb-3 pl-0 text-center text-lg">
        {{ $t('message.loadingErrors.ticketClosed', { ordernumber: ticket.key }) }}
      </h1>
    </div>
    <section
      v-else>
      <div
        class="row">
        <div class="col-12 mb-2">
          <coozzy-page-title
            v-if="ticket"
            :title="(ticket.key ? ticket.key : '') + (ticket.title !== '' ? (' - ' + ticket.title) : '')"
            :action-text="!publicTicket && !orderTicket ? $t('message.generic.back') : ''"
            :from="'ticketDetails'"
            action-button-design="secondary"
            @click="backClicked">
            <template #before-title-slot>
              <img
                v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.type.' + ticket.type)}"
                height="20px"
                class="mr-1 mb-1"
                :src="`/img/ticket/${ticket.type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                alt="type"> {{ $t('message.ticketOverview.ticket.type.' + ticket.type) }}
            </template>
          </coozzy-page-title>
        </div>
      </div>
      <coozzy-card v-if="ticket">
        <div class="row">
          <div class="col-12 col-md-7">
            <div class="row">
              <h6
                v-if="publicTicket"
                class="mb-3 col-12 position-relative">
                <img
                  class="logo_company mb-2"
                  :src="publicTicket.creator.accountLogoUrl"
                  alt="Logo">
                <label class="d-block">{{ publicTicket.creator.accountName }}</label>
                <address-text :address="publicTicket.creator.address" />
              </h6>
            </div>
            <div class="row mb-2">
              <div class="col">
                <label for="title">{{ $t('message.ticketOverview.ticket.description') }}</label>
                <!-- eslint-disable vue/no-v-html -->
                <p
                  class="pre-wrap mb-1"
                  v-html="ticket.description" />
                <!-- eslint-enable vue/no-v-html -->
              </div>
            </div>
            <!-- Attachments -->
            <div
              v-if="(orderTicket && orderTicket.listAttachement && orderTicket.listAttachement.length > 0) || filteredMediaList.length > 0"
              class="row mb-2">
              <div class="col">
                <label for="attachment"> {{ $t('message.ticketOverview.ticket.attachment') }} ({{ filteredMediaList.length + (orderTicket?.listAttachement?.length || 0) }})</label>
                <div
                  v-if="filteredMediaList.length > 0 || (orderTicket && orderTicket.listAttachement && orderTicket.listAttachement.length > 0)"
                  class="row m-0">
                  <div class="col-12 p-0">
                    <div
                      v-if="countDocs > 0 || (orderTicket && orderTicket.listAttachement && orderTicket.listAttachement.length > 0)"
                      class="row m-0 mb-2">
                      <label
                        class="col-12 p-0 subtitle"> {{ $t('message.ticketOverview.attachement.document') }} ({{ countDocs + (orderTicket?.listAttachement?.length || 0) }})</label>
                      <template
                        v-if="countDocs > 0">
                        <div
                          v-for="media in filteredMediaList"
                          :key="media.media ? media.media.id : media.id"
                          class="col-12 pb-1 px-0">
                          <template
                            v-if="!(['image/jpeg', 'image/png', 'image/gif', 'video/webm', 'video/mp4'].indexOf(media.media ? media.media.mimeType : media.mimeType) !== -1)">
                            <div
                              :key="media.id"
                              class="border mr-1">
                              <a
                                class="cardDocs"
                                :href="media.media ? media.media.url : media.url"
                                target="_blank"
                                :download="media.media ? media.media.filename : media.filename">
                                <div
                                  class="row m-0 d-flex">
                                  <div
                                    class="col-auto iconFile d-flex align-items-center"
                                    @click="downloadFiles(media, $event)">
                                    <coozzy-file-download-icon />
                                  </div>
                                  <div
                                    class="col-11 pl-0 pr-1">
                                    <p class="m-0 docName">
                                      <span class="link">{{ media.media ? media.media.filename : media.filename || 'unknown_filename' }}</span>
                                    </p>
                                    <p class="m-0 docSize">
                                      <span class="link">{{ Math.floor((media.size) / 1024) + ' KB' }}</span>
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </template>
                        </div>
                      </template>
                      <template
                        v-if="orderTicket && orderTicket.listAttachement && orderTicket.listAttachement.length > 0">
                        <div
                          v-for="media in orderTicket.listAttachement"
                          :key="media.media ? media.media.id : media.id"
                          class="col-12 col-md-12 pb-1 px-0">
                          <template
                            v-if="!(['image/jpeg', 'image/png', 'image/gif'].indexOf(media.media ? media.media.mimeType : media.mimeType) !== -1)">
                            <div
                              :key="media.id"
                              class="border mr-1">
                              <a
                                class="cardDocs"
                                @click="documentPreview(media.url, media.renderId)">
                                <div
                                  class="row m-0 d-flex">
                                  <div
                                    class="col-auto iconFile d-flex align-items-center">
                                    <div
                                      v-if="media.loading"
                                      class="text-center">
                                      <coozzy-spinner />
                                    </div>
                                    <div v-else>
                                      <coozzy-file-download-icon />
                                    </div>
                                  </div>
                                  <div
                                    class="col-11 pl-0 pr-1">
                                    <p class="m-0 docName">
                                      <span class="link">{{ media.media ? media.media.filename : media.filename || 'unknown_filename' }}</span>
                                    </p>
                                    <p class="m-0 docSize">
                                      <span class="link">{{ media.size ? (Math.floor((media.size) / 1024) + ' KB') : '- KB' }}</span>
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </template>
                        </div>
                      </template>
                    </div>
                    <div
                      v-if="countImg > 0"
                      class="row m-0 mb-2">
                      <label class="col-12 p-0 subtitle"> {{ $t('message.ticketOverview.attachement.image') }} ({{ countImg }})</label>
                      <template
                        v-for="media in filteredMediaList">
                        <div
                          v-if="['image/jpeg', 'image/png', 'image/gif'].indexOf(media.mimeType) !== -1 && media.url"
                          :key="media.id"
                          class="media-block col-12 col-md-2 square">
                          <div
                            class="uploaded-file cursor-pointer w-100"
                            :style="{ 'background-image': 'url(' + media.url + ')' }"
                            @click="imageClicked(media)" />
                        </div>
                      </template>
                    </div>
                    <div
                      v-if="countVideo > 0"
                      class="row m-0 mb-2">
                      <label class="col-12 p-0 subtitle"> {{ $t('message.ticketOverview.attachement.video') }} ({{ countVideo }})</label>
                      <template
                        v-for="media in filteredMediaList">
                        <div
                          v-if="['video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1"
                          :key="media.id"
                          class="media-block col-12 col-md-2 square">
                          <coozzy-thumbnail
                            v-if="getMediaUrl(media) !== null"
                            :key="media.id"
                            :video-src="getMediaUrl(media)"
                            :video-url="media.url"
                            class="uploaded-file cursor-pointer"
                            @imageClicked="openVideo(media)" />
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Linked tickets -->
            <div
              v-if="(ticket.links && ticket.links.length > 0) || showLinkInputs"
              id="linkedTickets"
              class="row mb-2">
              <div class="col">
                <label for="linkedTickets">{{ showLinkInputs ? $t('message.ticketOverview.ticket.linkTickets') : $t('message.ticketOverview.ticket.linkedTickets') }}</label>
                <div
                  v-if="showLinkInputs && (isEditor || isTicketEditor)"
                  class="row">
                  <div class="col-3 mb-2">
                    <coozzy-form-select
                      v-model="typeOfLink">
                      <option
                        v-for="item in availableLinkTypes"
                        :key="item"
                        :value="item">
                        {{ $t('message.ticketOverview.ticket.linkType.' + item) }}
                      </option>
                    </coozzy-form-select>
                  </div>
                  <div class="col-9">
                    <vue-autosuggest
                      :key="refreshAutosuggestKey"
                      v-model="searchForTickets"
                      :suggestions="resultTicketsForLink"
                      :input-props="inputProps"
                      :get-suggestion-value="getSuggestionValue"
                      @item-changed="itemChanged"
                      @input="getTickets()"
                      @selected="onSelectLinked">
                      <template slot-scope="{suggestion}">
                        <span
                          class="my-suggestion-item"
                          :class="checkIsDisabled(suggestion.item) ? 'disableOptionHover' : ''">
                          <img
                            height="20px"
                            class="mr-1 mb-1"
                            :src="`/img/ticket/${suggestion.item.type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                            alt="type">{{ suggestion.item.key }} {{ suggestion.item.title }}</span>
                      </template>
                    </vue-autosuggest>
                  </div>
                  <div class="col">
                    <coozzy-button
                      size="small"
                      class="border float-right mb-0 ml-2"
                      design="transparent"
                      @click="showLinkInputs = !showLinkInputs">
                      {{ $t('message.generic.cancel') }}
                    </coozzy-button>
                    <coozzy-button
                      size="small"
                      class="float-right mb-0"
                      design="green"
                      :disabled="ticketToLink?.length === 0 || searchForTickets?.length === 0"
                      @click="updateTicket('link')">
                      {{ $t('message.ticketOverview.ticket.link') }}
                    </coozzy-button>
                  </div>
                </div>
                <template v-if="!showLinkInputs">
                  <p
                    v-if="filteredTicketLinked('TICKET_LINK_TYPE_BLOCKS').length === 0 && filteredTicketLinked('TICKET_LINK_TYPE_RELATES').length === 0 && filteredTicketLinked('TICKET_LINK_TYPE_IS_BLOCKED_BY').length === 0"
                    class="mb-1">
                    -
                  </p>
                </template>
                <template v-if="filteredTicketLinked('TICKET_LINK_TYPE_BLOCKS').length > 0">
                  <div class="row">
                    <div class="col">
                      <label
                        for="blocks"
                        class="subtitle">
                        {{ $t('message.ticketOverview.ticket.linkType.TICKET_LINK_TYPE_BLOCKS') }}
                      </label>
                      <template
                        v-for="item in filteredTicketLinked('TICKET_LINK_TYPE_BLOCKS')">
                        <div
                          v-if="listTickets.find(x => x.id === item.ticketId)"
                          :key="'TICKET_LINK_TYPE_BLOCKS' + item.ticketId"
                          class="row mx-0 mb-2 p-1 linked-row">
                          <div class="col-8 p-0 label">
                            <router-link
                              :to="{ name: getTicketDetailRoute(listTickets.find(x => x.id === item.ticketId)).name, params: getTicketDetailRoute(listTickets.find(x => x.id === item.ticketId)).params }">
                              <img
                                v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.type.' + listTickets.find(x => x.id === item.ticketId).type)}"
                                height="20px"
                                class="mr-1"
                                :src="`/img/ticket/${listTickets.find(x => x.id === item.ticketId).type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                                alt="type"> {{ listTickets.find(x => x.id === item.ticketId).key }}
                              {{ listTickets.find(x => x.id === item.ticketId).title }}
                            </router-link>
                          </div>
                          <div
                            v-if="!publicTicket"
                            class="col d-inline pr-1">
                            <coozzy-button
                              v-if="isEditor || isTicketEditor"
                              size="small"
                              class="remove-linked-btn float-right mb-0 ml-2"
                              design="transparent"
                              @click="removeTicketLinkedClicked(item)">
                              <coozzy-close-icon />
                            </coozzy-button>
                            <div
                              :class="`d-inline float-right status-design ${(listTickets.find(x => x.id === item.ticketId).status.split('TICKET_STATUS_')[1]).toLowerCase()}`">
                              {{
                                $t('message.ticketOverview.status.' + listTickets.find(x => x.id === item.ticketId).status)
                              }}
                            </div>
                            <img
                              v-b-tooltip="{container: '#linkedTickets', title: listTickets.find(x => x.id === item.ticketId).assignee ? listTickets.find(x => x.id === item.ticketId).assignee.name : ''}"
                              class="align-self-center float-right avatar mr-2"
                              :src="listTickets.find(x => x.id === item.ticketId).assignee ? listTickets.find(x => x.id === item.ticketId).assignee.profilePictureUrl ? listTickets.find(x => x.id === item.ticketId).assignee.profilePictureUrl : '/avatar.png' : '/avatar.png'"
                              alt="assignee">
                            <img
                              height="20px"
                              class="mr-2 float-right ml-2"
                              :src="`/img/priority/prio-${listTickets.find(x => x.id === item.ticketId).priority}.svg`"
                              alt="priority">
                          </div>
                          <div
                            v-if="publicTicket"
                            class="col d-inline pr-1">
                            <coozzy-button
                              v-if="isEditor || isTicketEditor"
                              size="small"
                              class="border float-right mb-0 ml-2"
                              design="transparent"
                              @click="removeTicketLinked(item.ticketId)">
                              <coozzy-delete-icon />
                            </coozzy-button>
                            <div :class="`d-inline float-right status-design ${(listTickets.find(x => x.id === item.ticketId).status.split('TICKET_STATUS_')[1]).toLowerCase()}`">
                              {{ $t('message.ticketOverview.status.' + listTickets.find(x => x.id === item.ticketId).status) }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
                <template v-if="filteredTicketLinked('TICKET_LINK_TYPE_RELATES').length > 0">
                  <div class="row">
                    <div class="col">
                      <label
                        for="blocks"
                        class="subtitle">
                        {{ $t('message.ticketOverview.ticket.linkType.TICKET_LINK_TYPE_RELATES') }}
                      </label>
                      <template
                        v-for="item in filteredTicketLinked('TICKET_LINK_TYPE_RELATES')">
                        <div
                          v-if="listTickets.find(x => x.id === item.ticketId)"
                          :key="'TICKET_LINK_TYPE_RELATES' + item.ticketId"
                          class="row mx-0 mb-2 p-1 linked-row">
                          <div class="col-8 p-0 label">
                            <router-link
                              :to="{ name: getTicketDetailRoute(listTickets.find(x => x.id === item.ticketId)).name, params: getTicketDetailRoute(listTickets.find(x => x.id === item.ticketId)).params }">
                              <img
                                v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.type.' + listTickets.find(x => x.id === item.ticketId).type)}"
                                :src="`/img/ticket/${listTickets.find(x => x.id === item.ticketId).type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                                alt="type"
                                class="mr-1"
                                height="20px"> {{ listTickets.find(x => x.id === item.ticketId).key }}
                              {{ listTickets.find(x => x.id === item.ticketId).title }}
                            </router-link>
                          </div>
                          <div
                            v-if="!publicTicket"
                            class="col d-inline pr-1">
                            <coozzy-button
                              v-if="isEditor || isTicketEditor"
                              size="small"
                              class="remove-linked-btn float-right mb-0 ml-2"
                              design="transparent"
                              @click="removeTicketLinkedClicked(item)">
                              <coozzy-close-icon />
                            </coozzy-button>
                            <div
                              :class="`d-inline float-right status-design ${(listTickets.find(x => x.id === item.ticketId).status.split('TICKET_STATUS_')[1]).toLowerCase()}`">
                              {{
                                $t('message.ticketOverview.status.' + listTickets.find(x => x.id === item.ticketId).status)
                              }}
                            </div>
                            <img
                              v-b-tooltip="{container: '#linkedTickets', title: listTickets.find(x => x.id === item.ticketId).assignee ? listTickets.find(x => x.id === item.ticketId).assignee.name : ''}"
                              class="align-self-center float-right avatar mr-2"
                              :src="listTickets.find(x => x.id === item.ticketId).assignee ? listTickets.find(x => x.id === item.ticketId).assignee.profilePictureUrl ? listTickets.find(x => x.id === item.ticketId).assignee.profilePictureUrl : '/avatar.png' : '/avatar.png'"
                              alt="assignee">
                            <img
                              height="20px"
                              class="mr-2 float-right ml-2"
                              :src="`/img/priority/prio-${listTickets.find(x => x.id === item.ticketId).priority}.svg`"
                              alt="priority">
                          </div>
                          <div
                            v-if="publicTicket"
                            class="col d-inline pr-1">
                            <coozzy-button
                              v-if="publicTicket && (isEditor || isTicketEditor)"
                              size="small"
                              class="border float-right mb-0 ml-2"
                              design="transparent"
                              @click="removeTicketLinked(item.ticketId)">
                              <coozzy-delete-icon />
                            </coozzy-button>
                            <div :class="`d-inline float-right status-design ${(listTickets.find(x => x.id === item.ticketId).status.split('TICKET_STATUS_')[1]).toLowerCase()}`">
                              {{ $t('message.ticketOverview.status.' + listTickets.find(x => x.id === item.ticketId).status) }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
                <template v-if="filteredTicketLinked('TICKET_LINK_TYPE_IS_BLOCKED_BY').length > 0">
                  <div class="row">
                    <div class="col">
                      <label
                        for="blocks"
                        class="subtitle">
                        {{ $t('message.ticketOverview.ticket.linkType.TICKET_LINK_TYPE_IS_BLOCKED_BY') }}
                      </label>
                      <template
                        v-for="item in filteredTicketLinked('TICKET_LINK_TYPE_IS_BLOCKED_BY')">
                        <div
                          v-if="listTickets.find(x => x.id === item.ticketId)"
                          :key="'TICKET_LINK_TYPE_IS_BLOCKED_BY' + item.ticketId"
                          class="row mx-0 mb-2 p-1 linked-row">
                          <div class="col-8 p-0 label">
                            <router-link
                              :to="{ name: getTicketDetailRoute(listTickets.find(x => x.id === item.ticketId)).name, params: getTicketDetailRoute(listTickets.find(x => x.id === item.ticketId)).params }">
                              <img
                                v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.type.' + listTickets.find(x => x.id === item.ticketId).type)}"
                                height="20px"
                                class="mr-1"
                                :src="`/img/ticket/${listTickets.find(x => x.id === item.ticketId).type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                                alt="type"> {{ listTickets.find(x => x.id === item.ticketId).key }}
                              {{ listTickets.find(x => x.id === item.ticketId).title }}
                            </router-link>
                          </div>
                          <div
                            v-if="!publicTicket"
                            class="col d-inline pr-1">
                            <coozzy-button
                              v-if="isEditor || isTicketEditor"
                              size="small"
                              class="remove-linked-btn float-right mb-0 ml-2"
                              design="transparent"
                              @click="removeTicketLinkedClicked(item)">
                              <coozzy-close-icon />
                            </coozzy-button>
                            <div
                              :class="`d-inline float-right status-design ${(listTickets.find(x => x.id === item.ticketId).status.split('TICKET_STATUS_')[1]).toLowerCase()}`">
                              {{
                                $t('message.ticketOverview.status.' + listTickets.find(x => x.id === item.ticketId).status)
                              }}
                            </div>
                            <img
                              v-b-tooltip="{container: '#linkedTickets', title: listTickets.find(x => x.id === item.ticketId).assignee ? listTickets.find(x => x.id === item.ticketId).assignee.name : ''}"
                              class="align-self-center float-right avatar mr-2"
                              :src="listTickets.find(x => x.id === item.ticketId).assignee ? listTickets.find(x => x.id === item.ticketId).assignee.profilePictureUrl ? listTickets.find(x => x.id === item.ticketId).assignee.profilePictureUrl : '/avatar.png' : '/avatar.png'"
                              alt="assignee">
                            <img
                              height="20px"
                              class="mr-2 float-right ml-2"
                              :src="`/img/priority/prio-${listTickets.find(x => x.id === item.ticketId).priority}.svg`"
                              alt="priority">
                          </div>
                          <div
                            v-if="publicTicket"
                            class="col d-inline pr-1">
                            <coozzy-button
                              v-if="publicTicket && (isEditor || isTicketEditor)"
                              size="small"
                              class="border float-right mb-0 ml-2"
                              design="transparent"
                              @click="removeTicketLinked(item.ticketId)">
                              <coozzy-delete-icon />
                            </coozzy-button>
                            <div :class="`d-inline float-right status-design ${(listTickets.find(x => x.id === item.ticketId).status.split('TICKET_STATUS_')[1]).toLowerCase()}`">
                              {{ $t('message.ticketOverview.status.' + listTickets.find(x => x.id === item.ticketId).status) }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <b-tabs
              v-if="!orderTicket"
              v-model="tabIndex"
              class="mb-3 hrefSelector">
              <b-tab
                lazy>
                <template #title>
                  <h6
                    class="mb-0 title-card">
                    {{ $t('message.ticketOverview.ticket.tabs.comments') }}<span
                      class="text-blue"> | <b>{{ commentList.length }}</b></span>
                  </h6>
                </template>
                <comment
                  v-if="(canEditTicket && (isEditor || isTicketCreator)) || publicTicket"
                  ref="commentBlock"
                  :key="`comment-block`"
                  :reference-id="ticket.id"
                  :old-comment="comment"
                  :is-public="!!publicTicket"
                  :email="email"
                  :contact-list="contactList"
                  :employee-list="employeeList"
                  @comment-added="commentAdded"
                  @comment-changed="watchComment"
                  @comment-value="commentEdited" />
                <template v-if="commentList.length > 0">
                  <template v-for="item in (showAllComments ? commentList : commentList.slice(0,5))">
                    <comment
                      :key="`list-comment-block-${item.id}`"
                      :comment-item="item"
                      :read-only="true"
                      :contact-list="contactList"
                      :employee-list="employeeList"
                      @comment-updated="commentUpdated"
                      @comment-deleted="commentDeleted" />
                  </template>
                </template>
                <p
                  v-else
                  class="mt-2 mb-2 text-gray text-center">
                  {{ $t('message.ticketOverview.ticket.comment.emptyCommentsList') }}
                </p>
                <div
                  v-if="commentList.length > 5"
                  class="text-center borderTop">
                  <a
                    class="link"
                    @click="showAllComments = !showAllComments">
                    <template v-if="showAllComments === false">
                      {{ $t('message.ticketOverview.showAll') }}
                    </template>
                    <template v-else>
                      {{ $t('message.ticketOverview.hideAll') }}
                    </template>
                  </a>
                </div>
              </b-tab>
              <b-tab
                lazy>
                <template #title>
                  <h6
                    class="mb-0 title-card">
                    {{ $t('message.ticketOverview.ticket.tabs.history') }}<span
                      class="text-blue"> | <b>{{ historyLength }}</b></span>
                  </h6>
                </template>
                <template>
                  <ticket-history
                    :ticket="ticket"
                    :list-event="history"
                    :list-links="historyLinkedTicket"
                    :list-references="historyReferences" />
                </template>
              </b-tab>
            </b-tabs>
          </div>
          <div class="col-12 col-md-5 order-first order-md-last mt-4">
            <div class="row mb-2">
              <div class="col">
                <div class="d-inline-block">
                  <label class="mr-2">{{ $t('message.ticketOverview.status.title') }}: </label>
                  <coozzy-form-select
                    v-model="ticket.status"
                    :class="`status-design ${(ticket.status.split('TICKET_STATUS_')[1]).toLowerCase()}`"
                    :disabled="orderTicket !== null || publicTicket !== null || !canEditStatus"
                    @change="(value) => { handleTicketStatusChanged(value) }">
                    <option
                      v-for="status in availableStatus"
                      :key="status"
                      :value="status"
                      :disabled="publicTicket && (status === 'TICKET_STATUS_OPEN' || status === 'TICKET_STATUS_CLOSED' || (status === 'TICKET_STATUS_IN_PROGRESS' && ticket.status === 'TICKET_STATUS_DONE')) ">
                      {{ $t('message.ticketOverview.status.' + status) }}
                    </option>
                  </coozzy-form-select>
                </div>
                <div
                  v-if="publicTicket && ticket.type === 'TICKET_TYPE_ORDER' && ['TICKET_STATUS_OPEN', 'TICKET_STATUS_IN_PROGRESS'].includes(ticket.status)"
                  class="d-inline-block">
                  <!--  Status Ticket Open -->
                  <template
                    v-if="ticket.status === 'TICKET_STATUS_OPEN'">
                    <coozzy-button
                      v-if="!declinedTicket"
                      size="small"
                      class="ml-2 mb-0"
                      design="prop-green"
                      @click="changePublicTicketStatus('TICKET_STATUS_IN_PROGRESS')">
                      {{ $t('message.ticketOverview.status.acceptOrder') }}
                    </coozzy-button>
                    <coozzy-button
                      size="small"
                      class="ml-2 mb-0"
                      design="red"
                      :disabled="declinedTicket"
                      @click="openModalDeclineTicket()">
                      {{ $t('message.ticketOverview.status.declineOrder') }}
                    </coozzy-button>
                  </template>
                  <template
                    v-if="ticket.status === 'TICKET_STATUS_IN_PROGRESS'">
                    <coozzy-button
                      size="small"
                      class="ml-2 mb-0"
                      design="prop-green"
                      @click="changePublicTicketStatus('TICKET_STATUS_DONE')">
                      {{ $t('message.ticketOverview.status.finishOrder') }}
                    </coozzy-button>
                  </template>
                </div>
              </div>
              <div
                v-if="!publicTicket && !orderTicket"
                class="col">
                <div class="align-actions">
                  <coozzy-dropdown
                    v-if="(isTicketingEnabled && canEditTicket) || isTicketDeletor"
                    :text="$t('message.generic.actions')"
                    class="col-auto mr-1 pl-0 more-btn"
                    design="light"
                    size="sm">
                    <coozzy-dropdown-item
                      v-if="canEditTicket"
                      @click="editTicket">
                      {{ $t('message.generic.edit') }}
                    </coozzy-dropdown-item>
                    <coozzy-dropdown-item
                      v-if="canEditTicket"
                      @click="markAsUnread">
                      {{ $t('message.ticketOverview.ticket.actions.markAsUnread') }}
                    </coozzy-dropdown-item>
                    <coozzy-dropdown-item
                      v-if="canEditTicket && ticket.type === 'TICKET_TYPE_ORDER'"
                      @click="editTicket('changeOperator')">
                      {{ $t('message.ticketOverview.ticket.changeOperator') }}
                    </coozzy-dropdown-item>
                    <coozzy-dropdown-item
                      v-if="canEditTicket"
                      @click="showLinkBlock">
                      {{ $t('message.ticketOverview.ticket.actions.link') }}
                    </coozzy-dropdown-item>
                    <coozzy-dropdown-item
                      v-if="isTicketingEnabled && (isCompanyAdmin || isEditor || isTicketEditor || isTicketCreator) && ticket.type === 'TICKET_TYPE_TASK' && !isOwnerModule && !isAssetModule"
                      @click="showAddTicketConnectionsModal()">
                      {{ $t('message.createOrder.title') }}
                    </coozzy-dropdown-item>
                    <coozzy-dropdown-item
                      v-if="isTicketDeletor || canEditTicket"
                      @click="confirmDeleteTicket">
                      {{ $t('message.ticketOverview.ticket.actions.delete') }}
                    </coozzy-dropdown-item>
                  </coozzy-dropdown>
                </div>
              </div>
            </div>
            <b-card>
              <div class="row mb-2">
                <div class="col-12 col-md-3">
                  <label v-if="!publicTicket && ticket.type === 'TICKET_TYPE_ORDER'">{{ $t('message.createOrder.watcher') }}</label>
                  <br v-if="!publicTicket && ticket.type === 'TICKET_TYPE_ORDER'">
                  <b-dropdown
                    v-if="!publicTicket && ticket.type === 'TICKET_TYPE_ORDER'"
                    right
                    class="watcher-element"
                    toggle-class="text-decoration-none pl-0 border-0 transparent icon-dropdown text-left"
                    no-caret>
                    <template #button-content>
                      <coozzy-watcher-icon :class="ticket.watchers.find(x => x.userId === $store.getters['user/getUserId']) ? 'active-watcher' : ''" /> <label
                        class="length-watchers"
                        :class="ticket.watchers.find(x => x.userId === $store.getters['user/getUserId']) ? 'active' : ''"> {{ ticket.watchers.length }} </label>
                    </template>
                    <template
                      v-if="!ticket.assignee || ticket.assignee.userId !== userId">
                      <b-dropdown-item
                        v-if="ticket.watchers.find(x => x.userId === $store.getters['user/getUserId'])"
                        @click="removeWatcher">
                        <coozzy-watcher-icon />
                        {{ $t('message.ticketOverview.ticket.watcher.stopWatcher') }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-else-if="isEditor || isCompanyAdmin || isTicketEditor || isTicketReader || isTicketCreator"
                        @click="addWatcher">
                        <coozzy-watcher-icon />
                        {{ $t('message.ticketOverview.ticket.watcher.startWatcher') }}
                      </b-dropdown-item>
                      <b-dropdown-divider v-if="isEditor || isCompanyAdmin || isTicketEditor || isTicketReader || isTicketCreator || ticket.watchers.find(x => x.userId === $store.getters['user/getUserId'])" />
                    </template>
                    <b-dropdown-text>{{ $t('message.ticketOverview.ticket.watcher.watchingIssue') }}</b-dropdown-text>
                    <b-dropdown-divider />
                    <b-dropdown-group
                      v-for="(watc, index) in ticket.watchers"
                      :key="watc.accountId + '_' + index">
                      <b-dropdown-item>
                        <img
                          height="25"
                          class="avatar"
                          :alt="watc.name"
                          :src="`${watc.profilePictureUrl ? watc.profilePictureUrl : '/avatar.png'}`"> {{ watc.name }}
                      </b-dropdown-item>
                    </b-dropdown-group>
                  </b-dropdown>
                </div>
                <div
                  v-if="!publicTicket && ticket.type === 'TICKET_TYPE_ORDER'"
                  class="col-12 col-md-3">
                  <label>{{ $t('message.createOrder.statusUpdate.title') }}</label>
                  <span
                    v-if="countExternalWatchers > 0"
                    v-b-tooltip.hover.html="getWatchersNames()"
                    class="iconsStatusUpdate mb-1">
                    : <br><coozzy-success-icon />
                  </span>
                  <span
                    v-else
                    class="iconsStatusUpdate mb-1">
                    : <br><coozzy-fail-icon />
                  </span>
                </div>
                <div
                  v-if="ticket.type === 'TICKET_TYPE_ORDER' && isTicketReferenceInMail(ticket) && ticket.createdAt >= 1709638200"
                  class="col-12 col-md-5">
                  <label class="d-block"> {{ $t('message.tableColumnHeadings.receivedOpened') }}: </label>
                  <template v-if="isTicketReferenceInMail(ticket).deliverData.status === 'DELIVER_STATUS_DELIVERED'">
                    <coozzy-success-icon />
                  </template>
                  <template v-else-if="isTicketReferenceInMail(ticket).deliverData.status === 'DELIVER_STATUS_PENDING'">
                    <coozzy-pending-icon />
                  </template>
                  <template v-else>
                    <coozzy-fail-icon
                      v-b-tooltip.hover.left
                      :title="isTicketReferenceInMail(ticket).deliverData.errorMessage" />
                  </template>
                  /
                  <template v-if="isTicketReferenceInMail(ticket).engagementData.status === 'ENGAGEMENT_STATUS_OPENED'">
                    <coozzy-success-icon />
                  </template>
                  <template v-else>
                    <coozzy-fail-icon />
                  </template>
                </div>
              </div>
              <div class="row">
                <div
                  v-if="!publicTicket && ticket.type === 'TICKET_TYPE_TASK'"
                  class="col-12 col-md-4">
                  <label v-if="!publicTicket && ticket.type === 'TICKET_TYPE_TASK'">{{ $t('message.createOrder.watcher') }}</label>
                  <b-dropdown
                    v-if="!publicTicket && ticket.type === 'TICKET_TYPE_TASK'"
                    right
                    class="watcher-element col-12 p-0"
                    toggle-class="text-decoration-none border-0 transparent icon-dropdown text-left"
                    no-caret>
                    <template #button-content>
                      <coozzy-watcher-icon :class="ticket.watchers.find(x => x.userId === $store.getters['user/getUserId']) ? 'active-watcher' : ''" /> <label
                        class="length-watchers"
                        :class="ticket.watchers.find(x => x.userId === $store.getters['user/getUserId']) ? 'active' : ''"> {{ ticket.watchers.length }} </label>
                    </template>
                    <template
                      v-if="!ticket.assignee || ticket.assignee.userId !== userId">
                      <b-dropdown-item
                        v-if="ticket.watchers.find(x => x.userId === $store.getters['user/getUserId'])"
                        @click="removeWatcher">
                        <coozzy-watcher-icon />
                        {{ $t('message.ticketOverview.ticket.watcher.stopWatcher') }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-else-if="isEditor || isCompanyAdmin || isTicketEditor || isTicketReader || isTicketCreator"
                        @click="addWatcher">
                        <coozzy-watcher-icon />
                        {{ $t('message.ticketOverview.ticket.watcher.startWatcher') }}
                      </b-dropdown-item>
                      <b-dropdown-divider v-if="isEditor || isCompanyAdmin || isTicketEditor || isTicketReader || isTicketCreator || ticket.watchers.find(x => x.userId === $store.getters['user/getUserId'])" />
                    </template>
                    <b-dropdown-text>{{ $t('message.ticketOverview.ticket.watcher.watchingIssue') }}</b-dropdown-text>
                    <b-dropdown-divider />
                    <b-dropdown-group
                      v-for="(watc, index) in ticket.watchers"
                      :key="watc.accountId + '_' + index">
                      <b-dropdown-item>
                        <img
                          height="25"
                          class="avatar"
                          :alt="watc.name"
                          :src="`${watc.profilePictureUrl ? watc.profilePictureUrl : '/avatar.png'}`"> {{ watc.name }}
                      </b-dropdown-item>
                    </b-dropdown-group>
                  </b-dropdown>
                </div>
                <div
                  v-if="ticket.priority"
                  class="col-12"
                  :class="ticket.type === 'TICKET_TYPE_ORDER' ? 'col-md-3' : 'col-md-4'">
                  <label>{{ $t('message.ticketOverview.ticket.priority.title') }}</label>
                  <p
                    class="mb-1">
                    <img
                      :alt="$t('message.ticketOverview.ticket.priority.' + ticket.priority)"
                      :src="`/img/priority/prio-${ticket.priority}.svg`"
                      height="15px">
                    {{ $t('message.ticketOverview.ticket.priority.' + ticket.priority) }}
                  </p>
                </div>
                <div
                  v-if="ticket.startDate"
                  class="col-12"
                  :class="ticket.type === 'TICKET_TYPE_ORDER' ? 'col-md-3' : 'col-md-4'">
                  <label>{{ $t('message.ticketOverview.ticket.orderBeExecutedFrom') }}</label>
                  <p
                    class="mb-1">
                    {{ ticket.startDate | objectToDate }}
                  </p>
                </div>
                <div
                  v-if="ticket.dueDate"
                  class="col-12"
                  :class="ticket.type === 'TICKET_TYPE_ORDER' ? 'col-md-3' : 'col-md-4'">
                  <label>{{ $t('message.ticketOverview.ticket.dueDate') }}</label>
                  <p
                    class="mb-1">
                    {{ ticket.dueDate | objectToDate }}
                  </p>
                </div>
              </div>
              <div
                v-b-toggle.collapse-detail
                class="mt-3 collapse-title collapse-detail">
                <h5 class="cursor-pointer">
                  {{ $t('message.ticketOverview.ticket.details') }}
                </h5>
              </div>
              <b-collapse
                :id="'collapse-detail'"
                :visible="true">
                <div class="row mb-2">
                  <template
                    v-for="(data, index) in orderData">
                    <template
                      v-if="data === 'assignee'">
                      <div
                        v-if="!ticket.assignee"
                        :key="'info_' + index"
                        class="col-12 col-md-6 mb-2">
                        <label>{{ $t('message.ticketOverview.ticket.assignee') }}</label>
                        <p
                          class="m-0">
                          <img
                            class="avatar"
                            :alt="'avatar'"
                            :src="'/avatar.png'"> {{ $t('message.generic.unassigned') }}
                        </p>
                      </div>
                      <div
                        v-else
                        :key="'info_' + index"
                        class="col-12 col-md-6 mb-2">
                        <label>{{ $t('message.ticketOverview.ticket.assignee') }}</label>
                        <p
                          class="m-0">
                          <img
                            class="avatar"
                            :alt="ticket.assignee.name"
                            :src="ticket.assignee.profilePictureUrl !== '' ? ticket.assignee.profilePictureUrl : '/avatar.png'"> {{ ticket.assignee.name }}
                        </p>
                        <p
                          v-if="ticket.assignee.accountName"
                          class="m-0">
                          <span>{{ ticket.assignee.accountName }}</span>
                        </p>
                        <p
                          v-if="ticket.assignee.address"
                          class="m-0">
                          <address-text
                            :hide-if-empty="true"
                            :address="ticket.assignee.address" />
                        </p>
                        <p
                          v-if="ticket.assignee.email"
                          class="m-0">
                          <span>{{ ticket.assignee.email }}</span>
                        </p>
                        <p
                          v-if="ticket.assignee.phone"
                          class="m-0">
                          <span>{{ ticket.assignee.phone }}</span>
                        </p>
                      </div>
                    </template>
                    <div
                      v-if="data === 'operator' && ticket.correspondences.length !== 0"
                      :key="'info_' + index"
                      class="col-12 col-md-6 mb-2">
                      <label>{{ $t('message.ticketOverview.ticket.operator') }}</label>
                      <template
                        v-for="cor in ticket.correspondences">
                        <p
                          :key="'name_' + cor.contactId"
                          class="m-0">
                          <span>{{ cor.name }}</span>
                        </p>
                        <p
                          v-if="cor.companyName !== ''"
                          :key="'companyName_' + cor.contactId"
                          class="m-0">
                          <span>{{ cor.companyName }}</span>
                        </p>
                        <p
                          v-if="cor.address"
                          :key="'adr_' + cor.contactId"
                          class="m-0">
                          <address-text
                            :hide-if-empty="true"
                            :address="cor.address" />
                        </p>
                        <p
                          v-if="cor.email"
                          :key="'email_' + cor.contactId"
                          class="m-0">
                          <span>{{ cor.email }}</span>
                        </p>
                        <p
                          v-if="cor.phone"
                          :key="'phone_' + cor.contactId"
                          class="m-0">
                          <span>{{ cor.phone }}</span>
                        </p>
                        <p
                          v-else-if="cor.phoneNumbers && cor.phoneNumbers.length > 0"
                          :key="'phone_' + cor.contactId"
                          class="m-0">
                          <span
                            v-if="getPhoneNumbers(cor.phoneNumbers, 'mobile') !== '-'">{{ getPhoneNumbers(cor.phoneNumbers, 'mobile') }}</span>
                          <span
                            v-else-if="getPhoneNumbers(cor.phoneNumbers, 'work') !== '-'">{{ getPhoneNumbers(cor.phoneNumbers, 'work') }}</span>
                          <span
                            v-else-if="getPhoneNumbers(cor.phoneNumbers, 'home') !== '-'">{{ getPhoneNumbers(cor.phoneNumbers, 'home') }}</span>
                        </p>
                      </template>
                    </div>
                    <div
                      v-if="data === 'creator' && !publicTicket && ticket.creator"
                      :key="'info_' + index"
                      class="col-12 col-md-6 mb-2">
                      <label>{{ $t('message.ticketOverview.ticket.creator') }}</label>
                      <p
                        class="m-0">
                        <img
                          class="avatar"
                          :alt="ticket.creator.name"
                          :src="ticket.creator.profilePictureUrl !== '' ? ticket.creator.profilePictureUrl : '/avatar.png'">
                        <template v-if="ticket.creator.userId === 'system'">
                          {{ $t('message.ticketOverview.ticket.createdBySystem') }}
                        </template>
                        <template v-else>
                          {{ ticket.creator.name }}
                        </template>
                      </p>
                      <template v-if="ticket.creator.userId !== 'system'">
                        <p
                          v-if="ticket.creator.accountName"
                          class="m-0">
                          <span>{{ ticket.creator.accountName }}</span>
                        </p>
                        <p
                          v-if="ticket.creator.address"
                          class="m-0">
                          <address-text
                            :hide-if-empty="true"
                            :address="ticket.creator.address" />
                        </p>
                        <p
                          v-if="ticket.creator.email"
                          class="m-0">
                          <span>{{ ticket.creator.email }}</span>
                        </p>
                        <p
                          v-if="ticket.creator.phone"
                          class="m-0">
                          <span>{{ ticket.creator.phone }}</span>
                        </p>
                      </template>
                    </div>
                    <!-- this part of code for the new ticket that has onSiteContacts (array) -->
                    <div
                      v-if="data === 'onsite' && ticket.onSiteContacts.length > 0"
                      :key="'info_' + index"
                      class="col-12 col-md-6 mb-2">
                      <label>{{ $t('message.ticketOverview.ticket.contactPersonOnSite') }}</label>
                      <div
                        v-for="(onSiteContact, j) in ticket.onSiteContacts"
                        :key="'onSiteContact_' + j"
                        :class="ticket.onSiteContacts.length <= 2 ? 'mb-2' : 'mb-1'">
                        <p
                          class="m-0">
                          <span>{{ onSiteContact.name }}</span>
                        </p>
                        <template
                          v-if="ticket.onSiteContacts.length <= 2 || publicTicket">
                          <p
                            v-if="onSiteContact.companyName !== ''"
                            class="m-0">
                            <span>{{ onSiteContact.companyName }}</span>
                          </p>
                          <p
                            v-if="onSiteContact.address"
                            class="m-0">
                            <address-text
                              :hide-if-empty="true"
                              :address="onSiteContact.address" />
                          </p>
                          <p
                            v-if="onSiteContact.email"
                            class="m-0">
                            <span>{{ onSiteContact.email }}</span>
                          </p>
                          <p
                            v-if="onSiteContact?.phone"
                            :key="'phone_' + onSiteContact.contactId"
                            class="m-0">
                            <span>{{ onSiteContact.phone }}</span>
                          </p>
                          <p
                            v-else-if="onSiteContact.phoneNumbers && onSiteContact.phoneNumbers.length > 0"
                            class="m-0">
                            <span
                              v-if="getPhoneNumbers(onSiteContact.phoneNumbers, 'mobile') !== '-'">{{ getPhoneNumbers(onSiteContact.phoneNumbers, 'mobile') }}</span>
                            <span
                              v-else-if="getPhoneNumbers(onSiteContact.phoneNumbers, 'work') !== '-'">{{ getPhoneNumbers(onSiteContact.phoneNumbers, 'work') }}</span>
                            <span
                              v-else-if="getPhoneNumbers(onSiteContact.phoneNumbers, 'home') !== '-'">{{ getPhoneNumbers(onSiteContact.phoneNumbers, 'home') }}</span>
                          </p>
                        </template>
                      </div>
                    </div>
                    <!-- this part of code for the old ticket that has only onSiteContact (object) -->
                    <div
                      v-else-if="data === 'onsite' && ticket.onSiteContact"
                      :key="'info_' + index"
                      class="col-12 col-md-6 mb-2">
                      <label>{{ $t('message.ticketOverview.ticket.contactPersonOnSite') }}</label>
                      <p
                        class="m-0">
                        <span>{{ ticket.onSiteContact.name }}</span>
                      </p>
                      <p
                        v-if="ticket.onSiteContact.companyName !== ''"
                        class="m-0">
                        <span>{{ ticket.onSiteContact.companyName }}</span>
                      </p>
                      <p
                        v-if="ticket.onSiteContact.address"
                        class="m-0">
                        <address-text
                          :hide-if-empty="true"
                          :address="ticket.onSiteContact.address" />
                      </p>
                      <p
                        v-if="ticket.onSiteContact.email"
                        class="m-0">
                        <span>{{ ticket.onSiteContact.email }}</span>
                      </p>
                      <p
                        v-if="ticket.onSiteContact?.phone"
                        :key="'phone_' + ticket.onSiteContact.contactId"
                        class="m-0">
                        <span>{{ ticket.onSiteContact.phone }}</span>
                      </p>
                      <p
                        v-else-if="ticket.onSiteContact.phoneNumbers && ticket.onSiteContact.phoneNumbers.length > 0"
                        class="m-0">
                        <span
                          v-if="getPhoneNumbers(ticket.onSiteContact.phoneNumbers, 'mobile') !== '-'">{{ getPhoneNumbers(ticket.onSiteContact.phoneNumbers, 'mobile') }}</span>
                        <span
                          v-else-if="getPhoneNumbers(ticket.onSiteContact.phoneNumbers, 'work') !== '-'">{{ getPhoneNumbers(ticket.onSiteContact.phoneNumbers, 'work') }}</span>
                        <span
                          v-else-if="getPhoneNumbers(ticket.onSiteContact.phoneNumbers, 'home') !== '-'">{{ getPhoneNumbers(ticket.onSiteContact.phoneNumbers, 'home') }}</span>
                      </p>
                    </div>
                  </template>
                </div>
              </b-collapse>
            </b-card>
            <b-card
              v-if="!publicTicket && references.length !== 0">
              <div
                v-b-toggle.collapse-connections
                class="collapse-detail collapse-title">
                <h5 class="cursor-pointer">
                  {{ $t('message.ticketOverview.ticket.connections') }}
                </h5>
              </div>
              <b-collapse
                id="collapse-connections"
                :visible="true">
                <div
                  class="row mb-2 connection">
                  <div
                    v-for="(ref, index) in references"
                    :key="index"
                    class="col-12 d-flex align-items-center">
                    <div class="containerCircles">
                      <div
                        v-if="ref.tenancy"
                        v-b-tooltip="{container: '#collapse-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.tenancy')}"
                        class="color-box mt-0 ml-2 mr-2 tenancy" />
                      <div
                        v-if="ref.condominium"
                        v-b-tooltip="{container: '#collapse-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.condo_owner')}"
                        class="color-box mt-0 ml-2 mr-2 condo_owner" />
                      <div
                        v-if="ref.object && ref.object.condominiumOwnership === true && !ref.tenancy && !ref.condominium && !ref.device && !ref.request"
                        v-b-tooltip="{container: '#collapse-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.condominium')}"
                        class="color-box mt-0 ml-2 mr-2 condominium" />
                      <div
                        v-if="ref.object && ref.object.condominiumOwnership === false && !ref.tenancy && !ref.condominium && !ref.device && !ref.request"
                        v-b-tooltip="{container: '#collapse-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.object')}"
                        class="color-box mt-0 ml-2 mr-2 object" />
                      <div
                        v-if="ref.device"
                        v-b-tooltip="{container: '#collapse-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.device')}"
                        class="color-box mt-0 ml-2 mr-2 device" />
                      <div
                        v-if="ref.mortgage"
                        v-b-tooltip="{container: '#collapse-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.mortgage')}"
                        class="color-box mt-0 ml-2 mr-2 mortgage" />
                      <div
                        v-if="ref.insurance"
                        v-b-tooltip="{container: '#collapse-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.insurance')}"
                        class="color-box mt-0 ml-2 mr-2 insurance" />
                      <div
                        v-if="ref.building && !ref.object && !ref.device && !ref.mortgage && !ref.insurance"
                        v-b-tooltip="{container: '#collapse-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.building')}"
                        class="color-box mt-0 ml-2 mr-2 not_activated" />
                      <div
                        v-if="ref.property && !ref.building && !ref.device && !ref.mortgage && !ref.insurance"
                        v-b-tooltip="{container: '#collapse-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.property')}"
                        class="color-box mt-0 ml-2 mr-2 current" />
                      <div
                        v-if="ref.contact"
                        v-b-tooltip="{container: '#collapse-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.contact')}"
                        class="color-box mt-0 ml-2 mr-2 terminated" />
                      <div
                        v-if="ref.request"
                        v-b-tooltip="{container: '#collapse-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.request')}"
                        class="color-box mt-0 ml-2 mr-2 request" />
                    </div>
                    <div class="">
                      <span v-if="ref.property && !ref.device">
                        <router-link
                          v-if="!isMarketingModule"
                          :to="redirectToPropertyDetail(ref.property.objectID || ref.property.id)">
                          {{ ref.property.name }}
                        </router-link>
                        <span v-else>{{ ref.property.name }}</span>
                      </span>
                      <span v-if="ref.building && !ref.device">
                        <span v-if="ref.property"> > </span>
                        <router-link
                          v-if="!isMarketingModule"
                          :to="redirectToBuildingDetail(ref.building.objectID || ref.building.id)">
                          {{ ref.building.name }}
                        </router-link>
                        <span v-else>{{ ref.building.name }}</span>
                      </span>
                      <span v-if="ref.object && !ref.device">
                        <span v-if="ref.building"> > </span>
                        <router-link
                          v-if="!isMarketingModule"
                          :to="redirectToDetail(ref.object.buildingId, ref.object.objectID || ref.object.id, 'objects')">
                          {{ ref.object.name ? ref.object.name : ref.object.number !== '' ? ref.object.number : ref.object.numericId }}
                        </router-link>
                        <span v-else>{{ ref.object.name ? ref.object.name : ref.object.number !== '' ? ref.object.number : ref.object.numericId }}</span>
                      </span>
                      <span v-if="ref.room">
                        <template v-if="ref.object">
                          <span> > </span>
                          <span
                            v-if="!isMarketingModule"
                            @click="setRoomId('object', ref.room.id)"
                            @click.middle="setRoomId('object', ref.room.id)">
                            <router-link
                              :to="redirectToDetail(ref.object.buildingId, ref.object.objectID || ref.object.id, 'objects')">
                              {{ ref.room.name }}
                            </router-link>
                          </span>
                          <span v-else>{{ ref.room.name }}</span>
                        </template>
                        <template v-if="ref.building && !ref.object">
                          <span> > </span>
                          <span
                            v-if="!isMarketingModule"
                            @click="setRoomId('building', ref.room.id)"
                            @click.middle="setRoomId('building', ref.room.id)">
                            <router-link
                              :to="redirectToBuildingDetail(ref.building.id)">
                              {{ ref.room.name }}
                            </router-link>
                          </span>
                          <span v-else>{{ ref.room.name }}</span>
                        </template>
                      </span>
                      <span v-if="ref.mortgage">
                        <template v-if="ref.building">
                          <span> > </span>
                          <span
                            v-if="!isMarketingModule"
                            @click="setMortgageId('building', ref.mortgage.id)"
                            @click.middle="setMortgageId('building', ref.mortgage.id)">
                            <router-link
                              :to="redirectToBuildingDetail(ref.building.id)">
                              {{ ref.mortgage.bankName + ' - ' + $t('message.onBoarding.mortgage.mortgageDesignation.' + ref.mortgage.mortgageDesignation) }}
                            </router-link>
                          </span>
                          <span v-else>{{ ref.mortgage.bankName + ' - ' + $t('message.onBoarding.mortgage.mortgageDesignation.' + ref.mortgage.mortgageDesignation) }}</span>
                        </template>
                        <template v-if="ref.property && !ref.building">
                          <span> > </span>
                          <span
                            v-if="!isMarketingModule"
                            @click="setMortgageId('property', ref.mortgage.id)"
                            @click.middle="setMortgageId('property', ref.mortgage.id)">
                            <router-link
                              v-if="!isMarketingModule"
                              :to="redirectToPropertyDetail(ref.property.id)">
                              {{ ref.mortgage.bankName + ' - ' + $t('message.onBoarding.mortgage.mortgageDesignation.' + ref.mortgage.mortgageDesignation) }}
                            </router-link>
                          </span>
                          <span v-else>{{ ref.mortgage.bankName + ' - ' + $t('message.onBoarding.mortgage.mortgageDesignation.' + ref.mortgage.mortgageDesignation) }}</span>
                        </template>
                      </span>
                      <span v-if="ref.insurance">
                        <template v-if="ref.building">
                          <span> > </span>
                          <span
                            v-if="!isMarketingModule"
                            @click="setInsuranceId('building', ref.insurance.id)"
                            @click.middle="setInsuranceId('building', ref.insurance.id)">
                            <router-link
                              v-if="!isMarketingModule"
                              :to="redirectToBuildingDetail(ref.building.id)">
                              {{ $t('message.onBoarding.buildings.insurances.type.' + ref.insurance.type) }}
                            </router-link>
                          </span>
                          <span v-else>{{ $t('message.onBoarding.buildings.insurances.type.' + ref.insurance.type) }}</span>
                        </template>
                        <template v-if="ref.property && !ref.building">
                          <span> > </span>
                          <span
                            v-if="!isMarketingModule"
                            @click="setInsuranceId('property', ref.insurance.id)"
                            @click.middle="setInsuranceId('property', ref.insurance.id)">
                            <router-link
                              v-if="!isMarketingModule"
                              :to="redirectToPropertyDetail(ref.property.id)">
                              {{ $t('message.onBoarding.buildings.insurances.type.' + ref.insurance.type) }}
                            </router-link>
                          </span>
                          <span v-else>{{ $t('message.onBoarding.buildings.insurances.type.' + ref.insurance.type) }}</span>
                        </template>
                      </span>
                      <span v-if="ref.device">
                        <!--                        <span v-if="ref.object || ref.building || ref.property"> > </span>-->
                        <router-link
                          v-if="!isMarketingModule && redirectToDeviceDetail(ref.device.referenceId, ref.device.objectID || ref.device.id)"
                          :to="redirectToDeviceDetail(ref.device.referenceId, ref.device.objectID || ref.device.id)">
                          <template v-if="ref.device.type && ref.device.type !== 'DEVICE_TYPE_UNDEFINED'">
                            {{ $t('message.onBoarding.devices.typeValues.' + ref.device.type).length > 50 ? $t('message.onBoarding.devices.typeValues.' + ref.device.type).slice(0,50)+ '...' : $t('message.onBoarding.devices.typeValues.' + ref.device.type) }}
                          </template>
                          <template v-else>
                            -
                          </template>
                        </router-link>
                        <span v-else>
                          <template v-if="ref.device.type && ref.device.type !== 'DEVICE_TYPE_UNDEFINED'">
                            {{ $t('message.onBoarding.devices.typeValues.' + ref.device.type).length > 50 ? $t('message.onBoarding.devices.typeValues.' + ref.device.type).slice(0,50)+ '...' : $t('message.onBoarding.devices.typeValues.' + ref.device.type) }}
                          </template>
                          <template v-else>
                            -
                          </template>
                        </span>
                        <template
                          v-if="ref.relatedReference.length > 0">
                          <template
                            v-for="relRef in ref.relatedReference">
                            <p
                              v-if="relRef && relRef.id.startsWith('building')"
                              :key="relRef.id">
                              <span
                                class="pr-2">-</span>
                              <router-link
                                v-if="!isMarketingModule"
                                :to="redirectToBuildingDetail(relRef.id)">
                                {{ relRef.name + ', ' + relRef.address.street + ' ' + relRef.address.streetNumber + ', ' + relRef.address.zip + ' ' + relRef.address.city }}
                              </router-link>
                              <span v-else>{{ relRef.name + ', ' + relRef.address.street + ' ' + relRef.address.streetNumber + ', ' + relRef.address.zip + ' ' + relRef.address.city }}</span>
                            </p>
                            <p
                              v-if="relRef && relRef.id.startsWith('obj')"
                              :key="relRef.id">
                              <span
                                class="pr-2">-</span>
                              <router-link
                                v-if="!isMarketingModule"
                                :to="redirectToDetail(ref.object.buildingId, ref.object.objectID || ref.object.id, 'objects')">
                                {{ getRoomName(ref, ref.device.roomId) + formatFloor(relRef.characteristics.floor) + ', ' }}{{ ref.object.name ? ref.object.name : ref.object.number !== '' ? ref.object.number : ref.object.numericId }}
                              </router-link>
                              <span v-else>{{ getRoomName(ref, ref.device.roomId) + formatFloor(relRef.characteristics.floor) + ', ' }}{{ ref.object.name ? ref.object.name : ref.object.number !== '' ? ref.object.number : ref.object.numericId }}</span>
                              <router-link
                                v-if="!isMarketingModule"
                                :to="redirectToBuildingDetail(ref.building?.objectID || ref.building?.id)">
                                {{ ref.building?.address.street + ' ' + ref.building?.address.streetNumber + ', ' + ref.building?.address.zip + ' ' + ref.building?.address.city }}
                              </router-link>
                              <span v-else>{{ ref.building?.address.street + ' ' + ref.building?.address.streetNumber + ', ' + ref.building?.address.zip + ' ' + ref.building?.address.city }}</span>
                            </p>
                            <p
                              v-if="relRef && relRef.id.startsWith('prop')"
                              :key="relRef.id">
                              <span
                                class="pr-2">-</span>
                              <router-link
                                v-if="!isMarketingModule"
                                :to="redirectToPropertyDetail(relRef.id)">
                                <span>{{ ref.property.name }}</span>
                              </router-link>
                              <span v-else>{{ ref.property.name }}</span>
                            </p>
                          </template>
                        </template>
                      </span>
                      <span v-if="ref.object && ref.tenancy">
                        <span v-if="ref.object"> > </span>
                        <router-link
                          v-if="!isMarketingModule"
                          :to="redirectToDetail(ref.object.buildingId, ref.tenancy.objectID || ref.tenancy.id, 'tenants')">
                          <template v-if="ref.tenancy.tenants">
                            <span
                              v-for="(contact, j) in ref.tenancy.tenants"
                              :key="j">
                              {{ contact.name }}
                            </span>
                          </template>
                          <template v-else-if="ref.tenancy.tenant && ref.listContact && ref.tenancy.tenant.contactIds.length > 0">
                            <span
                              v-for="(contact, j) in ref.tenancy.tenant.contactIds"
                              :key="j">
                              <template v-if="ref.listContact.find(x => x.id === contact)">
                                {{ ref.listContact.find(x => x.id === contact).name }} <span v-if="j < ref.tenancy.tenant.contactIds.length - 1">-</span>
                              </template>
                            </span>
                          </template>
                          <template v-else>
                            <span
                              v-for="(contact, j) in ref.listContact"
                              :key="j">
                              {{ contact.name }}
                            </span>
                          </template>
                        </router-link>
                        <span v-else>
                          <template v-if="ref.tenancy.tenants">
                            <span
                              v-for="(contact, j) in ref.tenancy.tenants"
                              :key="j">
                              {{ contact.name }}
                            </span>
                          </template>
                          <template v-else-if="ref.tenancy.tenant && ref.listContact && ref.tenancy.tenant.contactIds.length > 0">
                            <span
                              v-for="(contact, j) in ref.tenancy.tenant.contactIds"
                              :key="j">
                              <template v-if="ref.listContact.find(x => x.id === contact)">
                                {{ ref.listContact.find(x => x.id === contact).name }} <span v-if="j < ref.tenancy.tenant.contactIds.length - 1">-</span>
                              </template>
                            </span>
                          </template>
                          <template v-else>
                            <span
                              v-for="(contact, j) in ref.listContact"
                              :key="j">
                              {{ contact.name }}
                            </span>
                          </template>
                        </span>
                      </span>
                      <span v-if="ref.object && ref.condominium">
                        <span v-if="ref.object"> > </span>
                        <router-link
                          v-if="!isMarketingModule"
                          :to="redirectToDetail(ref.object.buildingId, ref.condominium.objectID || ref.condominium.id, 'condominium')">
                          <template v-if="ref.condominium.condominiumOwners">
                            <span
                              v-for="(contact, j) in ref.condominium.condominiumOwners"
                              :key="j">
                              {{ contact.name }}
                            </span>
                          </template>
                          <template v-else>
                            <span
                              v-for="(contact, j) in ref.listContact"
                              :key="j">
                              {{ contact.name }}
                            </span>
                          </template>
                        </router-link>
                        <span v-else>
                          <template v-if="ref.condominium.condominiumOwners">
                            <span
                              v-for="(contact, j) in ref.condominium.condominiumOwners"
                              :key="j">
                              {{ contact.name }}
                            </span>
                          </template>
                          <template v-else>
                            <span
                              v-for="(contact, j) in ref.listContact"
                              :key="j">
                              {{ contact.name }}
                            </span>
                          </template>
                        </span>
                      </span>
                      <span v-if="ref.contact">
                        <router-link
                          v-if="!isMarketingModule"
                          :to="redirectToContactDetail(ref.contact.objectID || ref.contact.id)">
                          {{ ref.contact.name }}
                        </router-link>
                        <span v-else>{{ ref.contact.name }}</span>
                      </span>
                      <span v-if="ref.request">
                        <span> > </span>
                        <template
                          v-if="!isMarketingModule">
                          <span
                            v-for="(contact, j) in ref.listContact"
                            :key="j">
                            <span v-if="j > 0">- </span>
                            <router-link
                              :to="redirectToContactDetail(contact.id)">
                              {{ contact.name }}
                            </router-link>
                          </span>
                        </template>
                        <router-link
                          v-else
                          :to="redirectToRequestDetail(ref.request.objectId, ref.request.id)">
                          <span
                            v-for="(contact, j) in ref.listContact"
                            :key="j">
                            <span v-if="j > 0">- </span>{{ contact.name }}
                          </span>
                        </router-link>
                      </span>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </b-card>
            <b-card
              v-if="!publicTicket && mailList.length > 0">
              <div
                v-b-toggle.collapse-linked-mail
                class="collapse-detail collapse-title mb-3">
                <h5 class="cursor-pointer">
                  {{ $t('message.ticketOverview.ticket.linkedEmail') }}
                </h5>
              </div>
              <b-collapse
                id="collapse-linked-mail"
                :visible="true">
                <div
                  class="row">
                  <div
                    v-for="item in mailList"
                    :key="item.id"
                    class="col-12 block pl-md-0">
                    <div class="pb-0 d-flex">
                      - <p
                        class="ml-3 mb-2 mb-0"
                        @click="showDetail(item)">
                        <i>{{ item.timestamp | timestampToDateTime }}</i> - {{ item.subject }}
                        <coozzy-button
                          design="transparent"
                          class="only-on-hover pb-0 pt-0 pl-0"
                          @click="$event.stopPropagation(); $bvModal.show('confirmationModalUnlinkTicket' + item ? item.id : '')">
                          <coozzy-delete-icon />
                        </coozzy-button>
                      </p>
                    </div>
                    <b-modal
                      v-if="item"
                      :id="'confirmationModalUnlinkTicket' + item ? item.id : ''"
                      :ref="'confirmationModalUnlinkTicket'+ item ? item.id : ''"
                      no-close-on-backdrop
                      hide-footer
                      :title="$t('message.ticketOverview.ticket.unlinkMailConfirmation.title')">
                      <template>
                        <div class="col p-0">
                          <p>{{ $t('message.ticketOverview.ticket.unlinkMailConfirmation.text') }}</p>
                        </div>
                        <div class="col p-0">
                          <coozzy-button
                            size="small"
                            class="mb-0 border"
                            design="transparent"
                            @click="$bvModal.hide('confirmationModalUnlinkTicket' + item ? item.id : '')">
                            {{ $t('message.generic.cancel') }}
                          </coozzy-button>
                          <coozzy-button
                            size="small"
                            class="float-right mb-0"
                            design="green"
                            @click="unlinkTicket(item)">
                            {{ $t('message.generic.delete') }}
                          </coozzy-button>
                        </div>
                      </template>
                    </b-modal>
                  </div>
                </div>
              </b-collapse>
            </b-card>
            <ticket-dates
              v-if="!orderTicket"
              class="d-none d-md-block"
              :ticket="ticket" />
          </div>
        </div>
        <div
          v-if="!orderTicket && publicTicket"
          class="row d-block d-md-none">
          <div class="col-12">
            <ticket-dates
              v-if="!orderTicket"
              :ticket="ticket" />
          </div>
        </div>
      </coozzy-card>
      <b-modal
        id="confirmationModal"
        ref="confirmationModal"
        no-close-on-backdrop
        :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')"
        hide-footer>
        <p>{{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}</p>
        <div class="col">
          <coozzy-button
            size="small"
            class="border mb-0"
            design="transparent"
            @click="$bvModal.hide('confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="discardChanges">
            {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
          </coozzy-button>
        </div>
      </b-modal>
      <b-modal
        :id="'confirmationModalDeleteTicket'"
        ref="confirmationModalDeleteTicket"
        no-close-on-backdrop
        hide-footer
        :title="$t('message.ticketOverview.ticket.deleteConfirmation.title')">
        <template>
          <div class="col p-0">
            <p>{{ $t('message.ticketOverview.ticket.deleteConfirmation.text') }}</p>
          </div>
          <div class="col p-0">
            <coozzy-button
              size="small"
              class="mb-0 border"
              design="transparent"
              @click="$bvModal.hide('confirmationModalDeleteTicket')">
              {{ $t('message.generic.cancel') }}
            </coozzy-button>
            <coozzy-button
              size="small"
              class="float-right mb-0"
              design="green"
              @click="deleteTicket()">
              {{ $t('message.generic.delete') }}
            </coozzy-button>
          </div>
        </template>
      </b-modal>
      <b-modal
        :id="'confirmationModalRemoveLinkedTicket'"
        ref="confirmationModalRemoveLinkedTicket"
        no-close-on-backdrop
        hide-footer
        :title="$t('message.marketingMessages.confirmModal.confirmationTitle')">
        <div
          class="col p-0">
          <p>{{ $t('message.ticketOverview.ticket.deleteConfirmation.confirmationTextDeleteLinkedTicket') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('confirmationModalRemoveLinkedTicket')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="removeTicketLinked()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </b-modal>
      <b-modal
        id="confirmationModalTab"
        ref="confirmationModalTab"
        no-close-on-backdrop
        :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')"
        hide-footer>
        <p>{{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}</p>
        <div class="col">
          <coozzy-button
            size="small"
            class="border mb-0"
            design="transparent"
            @click="cancelCancellation">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="confirmCancellation">
            {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
          </coozzy-button>
        </div>
      </b-modal>
      <b-modal
        v-if="selectedObjectVideo !== ''"
        ref="show-video"
        hide-footer
        hide-header>
        <div class="row mb-2">
          <div class="col">
            <b-embed
              type="video"
              controls>
              <source
                :src="selectedObjectVideo"
                type="video/webm">
              <source
                :src="selectedObjectVideo"
                type="video/mp4">
            </b-embed>
          </div>
        </div>
      </b-modal>
      <b-modal
        id="unregistered-name-modal"
        ref="unregistered-name-modal"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        :title="$t('message.settings.mailbox.name')"
        hide-footer>
        <coozzy-form-input
          v-model="unregisteredName"
          :state="$v.unregisteredName.$dirty && $v.unregisteredName.$error ? false : null"
          :placeholder="$t('message.settings.mailbox.name')" />
        <div class="col mt-4">
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="confirmUnregisteredName">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
        </div>
      </b-modal>
      <b-modal
        v-if="showDeclineTicketModal"
        id="declineTicketModal"
        ref="declineTicketModal"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close
        :title="$t('message.ticketOverview.status.declineOrder')"
        hide-footer>
        <coozzy-form-textarea
          id="description"
          v-model="descriptionDeclineTicket"
          :initial="descriptionDeclineTicket"
          :value-text-area="descriptionDeclineTicket"
          :text="descriptionDeclineTicket"
          :placeholder="$t('message.ticketOverview.status.reason')"
          :show-label="false"
          :rows="5" />
        <div class="col p-0 mt-2">
          <coozzy-button
            size="small"
            class="border mb-0"
            design="transparent"
            @click="hideModalDeclineTicket">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="confirmDeclineTicket">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
        </div>
      </b-modal>
      <message-details-modal
        v-if="selectedMail"
        ref="mail-detail-modal"
        :key="selectedMail.id + '_' + dynamicId"
        :email="selectedMail"
        :ticket="ticket"
        @restore-mail="refreshList"
        @reply-mail="replyMail"
        @reply-all-mail="replyAllMail"
        @forward-mail="refreshList"
        @archive-mail="refreshList"
        @refresh-list="refreshList"
        @refresh-reminders="refreshList"
        @delete-mail="refreshList"
        @print-message="printMessage"
        @forward-as-attachement="forwardAsAttachement" />
      <add-ticket-connections-modal
        v-if="addTicketConnectionsModal"
        ref="addTicketConnectionsModal"
        :ticket-id="ticket.id"
        :connections="filteredReferences"
        @close-modal="closeAddTicketConnectionsModal" />
    </section>
  </div>
</template>

<script>
import MessageDetailsModal from '@/contact/components/MessageDetailsModal'
import TicketApi from '@/misc/apis/TicketApi'
import MediaApi from '@/misc/apis/MediaApi'
import NoteApi from '@/misc/apis/NoteApi'
import MessageApi from '@/misc/apis/MessageApi'
import ObjectApi from '@/misc/apis/ObjectApi'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ContactApi from '../../../misc/apis/ContactApi'
import { algolia } from '@/mixins/algolia'
import { routeChecks } from '@/mixins/routeChecks'
import { VueAutosuggest } from 'vue-autosuggest'
import { responsivity } from '@/mixins/responsivity'
import RequestApi from '@/marketingBasic/api/RequestApi'
import CoozzyWatcherIcon from '@/framework/components/icons/CoozzyWatcherIcon'
import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { user } from '@/mixins/user'
import Comment from '@/management/components/ticket/Comment'
import TicketHistory from '@/management/components/ticket/TicketHistory'
import { dateUtils } from '@/mixins/dateUtils'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import AddressText from '@/framework/components/misc/AddressText'
import { saveAs } from 'file-saver'
import RoomApi from '../../../misc/apis/RoomApi'
import { formatting } from '@/mixins/formatting'
import { contact } from '@/mixins/contact'
import { mail } from '@/mixins/mail'
import { ticket } from '@/mixins/ticket'
import CoozzyFileDownloadIcon from '@/framework/components/icons/CoozzyFileDownloadIcon.vue'
import CoozzyThumbnail from '@/framework/components/img/CoozzyThumbnail.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import CoozzyCloseIcon from '@/framework/components/icons/CoozzyCloseIcon.vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import TicketDates from '@/management/components/ticket/TicketDates.vue'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon.vue'
import CoozzyFailIcon from '@/framework/components/icons/CoozzyFailIcon.vue'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import CoozzyPendingIcon from '@/framework/components/icons/CoozzyPendingIcon.vue'
import AddTicketConnectionsModal from '@/management/components/ticket/addTicketConnectionsModal.vue'

function moreThanTwoChar(value) {
  return value.replace(/ /g, '').length > 1
}

export default {
  name: 'TicketDetailsView',
  components: {
    AddTicketConnectionsModal,
    CoozzyPendingIcon,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyFormTextarea,
    CoozzyFailIcon,
    CoozzySuccessIcon,
    TicketDates,
    CoozzyFormInput,
    CoozzyCloseIcon,
    CoozzySpinner,
    CoozzyThumbnail,
    CoozzyFileDownloadIcon,
    CoozzyPageTitle,
    Comment,
    CoozzyFormSelect,
    CoozzyDeleteIcon,
    CoozzyCard,
    CoozzyButton,
    VueAutosuggest,
    CoozzyWatcherIcon,
    AddressText,
    TicketHistory,
    MessageDetailsModal
  },
  mixins: [user, algolia, routeChecks, responsivity, dateUtils, formatting, contact, mail, ticket],
  beforeRouteUpdate(to, from, next) {
    if (to.name === from.name && from.params.id !== to.params.id) {
      next()
      if (to.params.id && to.params.id !== '') {
        this.getTicketByKey(to.params.id)
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.nextPath = next
    if (this.hasChanged) {
      this.$refs.confirmationModal.show()
    } else {
      if (this.nextPath !== null) {
        this.nextPath()
      }
    }
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    orderTicket: {
      type: Object,
      default: null
    },
    publicTicket: {
      type: Object,
      default: null
    }
  },
  metaInfo() {
    if (this.id !== '') {
      return {
        title: this.ticket ? (this.ticket.key ? this.ticket.key + ' - ' : '') + this.ticket.title : ''
      }
    } else if (this.publicTicket) {
      return {
        title: this.publicTicket.key + ' - ' + this.publicTicket.title
      }
    } else if (!this.orderTicket) {
      return {
        title: this.$t('message.ticketOverview.createTicket')
      }
    }
  },
  data() {
    return {
      mailList: [],
      mailOutboundList: [],
      selectedMail: null,
      dynamicId: '',
      unregisteredName: '',
      showAllComments: false,
      hasChanged: false,
      nextPath: null,
      tabIndex: 0,
      oldValTab: null,
      removeLinkedTicket: null,
      showLinkInputs: false,
      showCommentInputs: false,
      galleryImages: [],
      selectedObjectVideo: '',
      comment: '',
      refreshAutosuggestKey: '',
      contactList: [],
      ticketsForLink: [],
      typeOfLink: 'TICKET_LINK_TYPE_RELATES',
      ticketToLink: '',
      searchForTickets: '',
      listTickets: [],
      ticket: null,
      mediasList: [],
      references: [],
      availableTypes: ['TICKET_TYPE_ORDER', 'TICKET_TYPE_OFFER', 'TICKET_TYPE_TASK'],
      availableLinkTypes: ['TICKET_LINK_TYPE_RELATES', 'TICKET_LINK_TYPE_BLOCKS', 'TICKET_LINK_TYPE_IS_BLOCKED_BY'],
      availablePriority: ['DEFAULT', 'LOW', 'HIGH'],
      availableStatus: ['TICKET_STATUS_OPEN', 'TICKET_STATUS_IN_PROGRESS', 'TICKET_STATUS_DONE', 'TICKET_STATUS_CLOSED'],
      commentList: [],
      dates: {
        startDate: null,
        dueDate: null
      },
      countDocs: 0,
      countImg: 0,
      countVideo: 0,
      ticketDeleted: false,
      ticketClosed: false,
      history: [],
      historyLinkedTicket: [],
      historyReferences: [],
      historyLength: 0,
      orderData: ['assignee', 'onsite', 'operator', 'creator'],
      filteredMediaList: [],
      showDeclineTicketModal: false,
      descriptionDeclineTicket: '',
      declinedTicket: false,
      addTicketConnectionsModal: false
    }
  },
  computed: {
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : '/'
    },
    prevRouteQuery() {
      return this.prevRoute ? this.prevRoute.query : null
    },
    resultTicketsForLink() {
      // return this.ticketsForLink
      return [{ data: this.ticketsForLink.filter(x => x && x.key && x.key !== this.ticket.key) }]
    },
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees()
      }
      return []
    },
    countExternalWatchers() {
      return this.ticket.externalTicketWatchers?.filter(y => y.name !== '' && y.notifyOnlyStatusChanges).length || 0
    },
    canEditTicket() {
      // only Assignee, creator, administrator and ticket editor should be able to edit the ticket
      return (this.ticket?.assignee?.userId === this.userId || this.ticket?.creator?.userId === this.userId) || this.isCompanyAdmin || this.isTicketEditor || false
    },
    canEditStatus() {
      return this.canEditTicket || (this.publicTicket && this.publicTicket.status !== 'TICKET_STATUS_OPEN') || false
    },
    filteredReferences() {
      // this list is used to exclude connections (request / insurance / mortgage) for create order process
      return this.references.filter(x => !x.request && !x.insurance && !x.mortgage)
    }
  },
  watch: {
    mailboxesForCurrentUser: function () {
      if (this.ticket?.id && this.ticket.id !== '') {
        this.loadMails([this.ticket.id])
      }
    },
    mediasList: function (newVal) {
      this.countDocs = 0
      const array = JSON.parse(JSON.stringify(newVal))
      array.sort((val1, val2) => {
        return parseInt(val2.uploadedAt) - parseInt(val1.uploadedAt)
      })
      array.forEach(element => {
        if (!(['image/jpeg', 'image/png', 'image/gif', 'video/webm', 'video/mp4'].indexOf(element.mimeType) !== -1)) {
          this.countDocs = this.countDocs + 1
        }
        if (['image/jpeg', 'image/png', 'image/gif'].indexOf(element.mimeType) !== -1) {
          this.countImg = this.countImg + 1
        }
        if (['video/webm', 'video/mp4'].indexOf(element.mimeType) !== -1) {
          this.countVideo = this.countVideo + 1
        }
      })
      this.filteredMediaList = array
    },
    tabIndex: function (newVal, oldVal) {
      if (this.hasChanged && this.oldValTab !== newVal && this.comment !== '') {
        this.$refs.confirmationModalTab.show()
        this.oldValTab = oldVal
      }
    },
    typeOfLink: function (newVal, oldVal) {
      this.hasChanged = true
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.searchForTickets = ''
      }
    },
    id: {
      deep: true,
      handler() {
        this.loadTicketData()
      }
    }
  },
  mounted: function () {
    if (this.orderTicket !== null) {
      // ticket preview from create order process
      this.ticket = this.orderTicket
      if (this.ticket?.id && this.ticket.id !== '') {
        this.loadMails([this.ticket.id])
      }
      this.loadTicketData()
    } else if (this.id && this.id !== '') {
      // ticket preview from ticket list
      this.getTicketByKey(this.id)
    } else {
      // ticket preview from public ticket
      if (localStorage.getItem('unregisteredName' + this.email) === null) {
        this.$refs['unregistered-name-modal'].show()
      }
      this.orderData = ['assignee', 'onsite', 'creator', 'operator']
      this.ticket = this.publicTicket
      this.loadTicketData()
      this.getTicketHistory(this.ticket.id, this.ticket.publicId)
      this.$store.dispatch('internationalization/changeCurrentLanguage', ['en', 'de', 'it'].includes(window.navigator.language.substring(0, 2)) ? window.navigator.language.substring(0, 2) : 'de').then()
      // filter status closed if public ticket
      if (this.publicTicket) {
        this.availableStatus = ['TICKET_STATUS_OPEN', 'TICKET_STATUS_IN_PROGRESS', 'TICKET_STATUS_DONE']
      }
      if (this.publicTicket.status === 'TICKET_STATUS_CLOSED') {
        this.ticketClosed = true
      }
      if (this.publicTicket) {
        const list = localStorage.getItem('listDeclinedTickets') ? JSON.parse(localStorage.getItem('listDeclinedTickets')) : []
        this.declinedTicket = list.length > 0 && list.includes(this.publicTicket.publicId)
      }
    }
    this.loadEmployees()
    if (this.isAuthenticated) {
      this.getContacts()
    }
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    ...mapMutations('message', [
      'setSendMailContacts',
      'setEmailToForwardAsAttachment',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSendMailCC',
      'setSendMailOnlyCurrentContact',
      'setSendMailHasContext',
      'setMailsLoadedAt',
      'setChangingStatusMails',
      'resetStatusMails',
      'setSubject'
    ]),
    getWatchersNames() {
      const names = this.ticket.externalTicketWatchers.filter(y => y.name !== '' && y.notifyOnlyStatusChanges).map(x => x.name)
      if (names.length > 0) {
        let html = '<p class="text-left mb-0">'
        names.forEach(name => {
          html += name + '<br>'
        })
        html += '</p>'
        return html
      }
      return ''
    },
    loadMails(referenceIds, filterFolder = 'FILTER_FOLDER_ALL', mailboxIds = this.mailboxesForCurrentUser.map(mailbox => mailbox.id), query = '') {
      if (this.mailboxesForCurrentUser?.length > 0 && referenceIds.length > 0) {
        MessageApi.listMailsByReferenceIds(referenceIds, filterFolder, mailboxIds, 1, null, query)
          .then(async response => {
            this.mailOutboundList = response.mails.filter(x => !x.inbound)
            this.mailList = response.mails.filter(x => x.mailType !== 'MAIL_TYPE_TICKET_NOTIFICATION')
          }).catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.message'), { type: 'error' })
          })
      }
    },
    isTicketReferenceInMail(ticket) {
      // Check the mail recipient TO and compare it to operator email. If there are still more than 1 then use the one with lowest timestamp. That way we get the first email sent to the operator which this status should represent
      if (ticket.type === 'TICKET_TYPE_ORDER') {
        const arr = this.mailOutboundList.filter(x => x.referenceIds.includes(ticket.id)).filter((email) =>
          email.to.some((recipient) => recipient?.email === ticket.correspondences[0]?.email)
        )?.sort((a, b) => {
            return (b.timestamp * 1000) - (a.timestamp * 1000)
          })
        return arr.length > 0 ? arr[0] : null
      }
      return null
    },
    getContacts() {
      ContactApi.getContacts(this.$store.getters['user/getAccountId'])
        .then(response => {
          this.contactList = response.persons.concat(response.companies)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
    },
    markAsUnread() {
      TicketApi.markUnread([this.ticket.id])
        .then(() => {
          this.getCountMyUnreadTickets(true)
          Vue.toasted.show(this.$t('message.successMessages.markTicketUnread'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.ticket'), { type: 'error' })
        })
    },
    getTicketByKey(key) {
      TicketApi.getTicketByKey(key)
        .then(response => {
          this.ticket = response.ticket
          if (this.ticket.readStatus && !this.ticket.readStatus.assignee && this.ticket.assignee && this.ticket.assignee.userId === this.$store.getters['user/getUserId']) {
            TicketApi.markRead([this.ticket.id])
              .then(() => {
                // this.$parent.$refs['coozzy-header'].getCountMyUnreadTickets()
                this.getCountMyUnreadTickets(true)
              })
              .catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.ticket'), { type: 'error' })
              })
          }
          this.loadMails([this.ticket.id])
          this.getTicketHistory(this.ticket.id)
          this.loadTicketData()
        })
        .catch(e => {
          const error = e.response.data
          if (error.code === 5 && error.message === 'ticket not found') {
            this.ticketDeleted = true
          } else {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.ticket'), { type: 'error' })
          }
        })
    },
    getRoomName(list, id) {
      let name = ''
      list.relatedReference?.forEach((element) => {
        if (element && element.id === id) {
          name = element.name + ', '
        }
      })
      return name
    },
    documentPreview(url, renderId) {
      if (url !== '') {
        window.open(url, '_blank')
      } else {
        if (this.orderTicket.listAttachement.find(e => e.renderId === renderId)?.loading === false) {
          this.generateTemplates(renderId)
        }
      }
    },
    generateTemplates(renderId) {
      this.orderTicket.listAttachement.forEach(element => {
        if (element.renderId === renderId) {
          element.loading = true
        }
      })
      const recheckDocs = setInterval(() => {
        ObjectApi.listRenderStatus([renderId])
          .then(response => {
            const stillInProgress = response.status.filter(function (v) {
              return v.finished === false && v.failed === false
            })
            if (stillInProgress.length === 0) {
              const isFinished = response.status.every(function (v) {
                return v.finished !== false || v.failed !== false
              })
              if (isFinished) {
                const onlyFinished = response.status.filter(x => x.finished)
                const onlyFailed = response.status.filter(x => x.failed)
                clearInterval(recheckDocs)
                if (onlyFailed.length > 0) {
                  this.orderTicket.listAttachement.forEach(element => {
                    if (element.renderId === renderId) {
                      element.loading = false
                    }
                  })
                  Vue.toasted.show(this.$t('message.savingErrors.renderingContractError') + onlyFailed.map(x => x.renderId).toString() + ' ' + onlyFailed.map(x => x.errorMessage).toString(), { type: 'error' })
                }
                if (onlyFinished.length > 0) {
                  ObjectApi.getRenderFile(renderId)
                    .then(element => {
                      // decode base64 string
                      const binary = window.atob(element.contract)
                      const len = binary.length
                      const buffer = new ArrayBuffer(len)
                      const view = new Uint8Array(buffer)
                      for (let i = 0; i < len; i++) {
                        view[i] = binary.charCodeAt(i)
                      }
                      let blob = ''
                      blob = new Blob([view], { type: 'application/pdf' })
                      const link = document.createElement('a')
                      link.href = window.URL.createObjectURL(blob)
                      this.orderTicket.listAttachement.forEach(element => {
                        if (element.renderId === renderId) {
                          element.url = link.href
                          element.loading = false
                          element.size = blob.size
                          element.blob = blob
                        }
                      })
                      window.open(link.href, '_blank')
                    })
                }
              }
            }
          })
      }, 3000)
    },
    downloadFiles(media, e) {
      const mimeType = media.media ? media.media.mimeType : media.mimeType
      if (mimeType === 'application/pdf') {
        e.preventDefault()
        const url = media.media ? media.media.url : media.url
        const fileName = media.media ? media.media.filename : media.filename
        saveAs(url, fileName)
      }
    },
    getTicketHistory(id, publicId = '') {
      TicketApi.getTicketHistory(id, publicId).then(response => {
        this.history = response.events.reverse()
        const promises = []
        let listLinkedTicket = []
        const listConnections = []
        this.history.forEach(element => {
          if (element.historyType === 'HISTORY_TYPE_ADDED_CONNECTIONS') {
            if (element.connectionAdded?.references?.length > 0) {
              element.connectionAdded?.references.forEach(element => {
                listConnections.push(element)
              })
            }
          }
          if (element.historyType === 'HISTORY_TYPE_REMOVED_CONNECTIONS') {
            if (element.connectionRemoved?.references?.length > 0) {
              element.connectionRemoved?.references.forEach(element => {
                listConnections.push(element)
              })
            }
          }
          if (element.historyType === 'HISTORY_TYPE_ADDED_LINKS') {
            if (element.linksAdded?.links?.length > 0) {
              listLinkedTicket = listLinkedTicket.concat(element.linksAdded?.links.map(x => x.ticketId))
            }
          }
          if (element.historyType === 'HISTORY_TYPE_REMOVED_LINKS') {
            if (element.linksRemoved?.links?.length > 0) {
              listLinkedTicket = listLinkedTicket.concat(element.linksRemoved?.links.map(x => x.ticketId))
            }
          }

          if (element.historyType !== 'HISTORY_TYPE_NEW_COMMENT' && element.historyType !== 'HISTORY_TYPE_EDITED_COMMENT' && element.historyType !== 'HISTORY_TYPE_DELETED_COMMENT') {
            this.historyLength++
          } else {
            if (element.commentNew !== null || element.commentEdited !== null || element.commentDeleted !== null) {
              this.historyLength++
            }
          }
        })
        listLinkedTicket = listLinkedTicket.map((x) => {
          return x.substring(x.indexOf('ticket'))
        })
        this.historyReferences = this.mountedReference(listConnections)
        if (listLinkedTicket.length > 0) {
          promises.push(TicketApi.getTicketByIds(listLinkedTicket))
          if (promises.length > 0) {
            Promise.all(promises)
              .then(response => {
                response.forEach(element => {
                  if (element?.tickets?.length > 0) {
                    this.historyLinkedTicket = element.tickets
                  }
                })
              })
          }
        }
      }).catch(e => {
        console.log(e)
      })
    },
    openVideo(media) {
      this.selectedObjectVideo = media.url
      this.$nextTick(() => {
        this.$refs['show-video'].show()
      })
    },
    itemChanged(selected) {
      if (selected && selected.item) {
        this.searchForTickets = selected.item.key + ' ' + selected.item.title
      }
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.key + ' ' + suggestion.item.title
    },
    checkIsDisabled(item) {
      if (this.typeOfLink) {
        if (this.typeOfLink === 'TICKET_LINK_TYPE_IS_BLOCKED_BY' && this.filteredTicketLinked('TICKET_LINK_TYPE_IS_BLOCKED_BY').length > 0) {
          for (const ticket of this.filteredTicketLinked('TICKET_LINK_TYPE_IS_BLOCKED_BY')) {
            if (item.id === ticket.ticketId) {
              return true
            }
          }
        } else if (this.typeOfLink === 'TICKET_LINK_TYPE_BLOCKS' && this.filteredTicketLinked('TICKET_LINK_TYPE_BLOCKS').length > 0) {
          for (const ticket of this.filteredTicketLinked('TICKET_LINK_TYPE_BLOCKS')) {
            if (item.id === ticket.ticketId) {
              return true
            }
          }
        } else if (this.typeOfLink === 'TICKET_LINK_TYPE_RELATES' && this.filteredTicketLinked('TICKET_LINK_TYPE_RELATES').length > 0) {
          for (const ticket of this.filteredTicketLinked('TICKET_LINK_TYPE_RELATES')) {
            if (item.id === ticket.ticketId) {
              return true
            }
          }
        } else {
          return false
        }
      }
      return false
    },
    getMediaUrl(media) {
      if (media.thumbnails === undefined || media.thumbnails === null || typeof media.thumbnails === 'undefined') {
        return null
      }
      for (const thumbnail of media.thumbnails) {
        if (thumbnail.type === 1 || thumbnail.type === 'M') {
          return thumbnail.url
        }
      }
      return media.url
    },
    getTicketDetailRoute(ticket) {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerTicketDetailsView',
          params: { id: ticket.key }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminTicketDetailsView',
          params: { id: ticket.key }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetTicketDetailsView',
          params: { id: ticket.key }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingTicketDetailsView',
          params: { id: ticket.key }
        }
      } else {
        return {
          name: 'MarketingTicketDetailsView',
          params: { id: ticket.key }
        }
      }
    },
    openModalDeclineTicket() {
      this.showDeclineTicketModal = true
      this.$nextTick(() => {
        this.$refs.declineTicketModal.show()
      })
    },
    hideModalDeclineTicket() {
      this.$refs.declineTicketModal.hide()
      this.showDeclineTicketModal = false
    },
    confirmDeclineTicket() {
      const comment = this.$t('message.ticketOverview.status.declineOrder') + (this.descriptionDeclineTicket !== '' ? '\n' + this.descriptionDeclineTicket : '')
      const name = localStorage.getItem('unregisteredName' + this.email) ? localStorage.getItem('unregisteredName' + this.email) : ''
      NoteApi.newCommentUnregistered(this.$route?.query?.email || '', name, [this.ticket.id], comment, [])
        .then(async response => {
          this.commentAdded(response.note)
          this.hideModalDeclineTicket()
          if (!localStorage.getItem('listDeclinedTickets')) {
            localStorage.setItem('listDeclinedTickets', JSON.stringify([this.ticket.publicId]))
            this.declinedTicket = true
          } else {
            const list = JSON.parse(localStorage.getItem('listDeclinedTickets'))
            const index = list.findIndex(x => x === this.ticket.publicId)
            this.declinedTicket = true
            if (index === -1) {
              list.push(this.ticket.publicId)
              localStorage.setItem('listDeclinedTickets', JSON.stringify(list))
            }
          }
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.note'), { type: 'error' })
        })
    },
    changePublicTicketStatus(value) {
      this.ticket.status = value
      this.handleTicketStatusChanged(value)
    },
    handleTicketStatusChanged(value) {
      if (this.availableStatus.includes(value)) {
        this.updateTicket('status')
      }
    },
    loadTicketData() {
      if (this.ticket?.references) {
        this.references = this.mountedReference(this.ticket.references)
      }
      if (this.ticket?.links.length > 0) {
        TicketApi.getTicketByIds(this.ticket.links.map(x => x.ticketId))
          .then(response => {
            this.listTickets = response.tickets
          })
      }
      if (this.ticket?.mediaIds.length > 0) {
        MediaApi.getListMedia(this.ticket.mediaIds)
          .then(response => {
            const list = []
            for (const media of response.media) {
              list.push(media)
            }
            this.mediasList = list
          })
          .catch(() => {
            Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
          })
      } else {
        this.mediasList = []
      }
      this.loadComments()
      this.loadEmployees()
    },
    editTicket(source = '') {
      let query = {}
      if (source === 'changeOperator') {
        query = { source: 'changeOperator' }
      }
      if (this.isOwnerModule) {
        this.$router.replace({
          name: 'OwnerTicketEdition',
          params: { id: this.ticket.key },
          query: query
        }, () => {
        })
      } else if (this.isAdminModule) {
        this.$router.replace({
          name: 'AdminTicketEdition',
          params: { id: this.ticket.key },
          query: query
        }, () => {
        })
      } else if (this.isAssetModule) {
        this.$router.replace({
          name: 'AssetTicketEdition',
          params: { id: this.ticket.key },
          query: query
        }, () => {
        })
      } else if (this.isAccountingModule) {
        this.$router.replace({
          name: 'AccountingTicketEdition',
          params: { id: this.ticket.key },
          query: query
        }, () => {
        })
      } else {
        this.$router.replace({
          name: 'MarketingTicketEdition',
          params: { id: this.ticket.key },
          query: query
        }, () => {
        })
      }
    },
    filteredTicketLinked(type) {
      return this.ticket.links.filter(x => x.type === type)
    },
    redirectToContactDetail(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return {
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isMarketingModule) {
          return {
            name: 'MarketingContactDetailsView',
            params: { id: contactId }
          }
        } else {
          return {
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          }
        }
      }
    },
    redirectToBuildingDetail(buildingId) {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      }
    },
    redirectToPropertyDetail(propertyId) {
      if (this.isOwnerModule) {
        return { name: 'OwnerPropertyDetailsView', params: { id: propertyId } }
      } else if (this.isAdminModule) {
        return { name: 'AdminPropertyDetailsView', params: { id: propertyId } }
      } else if (this.isAssetModule) {
        return { name: 'AssetPropertyDetailsView', params: { id: propertyId } }
      } else if (this.isAccountingModule) {
        return { name: 'AccountingPropertyDetailsView', params: { id: propertyId } }
      }
    },
    redirectToDeviceDetail(referenceId, deviceId) {
      if (referenceId.startsWith('obj') || referenceId.startsWith('building')) {
        let id = null
        if (referenceId.startsWith('building')) {
          id = referenceId
        } else {
          const item = this.references.filter(x => x.device && x.device.id === deviceId)
          if (item.length > 0) {
            id = item[0].building?.id
          }
        }
        if (id && deviceId) {
          if (this.isOwnerModule) {
            return { name: 'OwnerBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          } else if (this.isAdminModule) {
            return { name: 'AdminBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          } else if (this.isAssetModule) {
            return { name: 'AssetBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          } else if (this.isAccountingModule) {
            return { name: 'AccountingBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          }
        }
      } else {
        let id = null
        id = referenceId
        if (this.isOwnerModule) {
          return { name: 'OwnerPropertyDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
        } else if (this.isAdminModule) {
          return { name: 'AdminPropertyDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetPropertyDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingPropertyDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
        }
      }
    },
    setRoomId(source, id) {
      if (source === 'building') {
        localStorage.setItem('expandBuildingRoomId', id)
      } else if (source === 'object') {
        localStorage.setItem('expandObjectRoomId', id)
      }
    },
    setMortgageId(source, id) {
      if (source === 'building') {
        localStorage.setItem('expandBuildingMortgageId', id)
      } else if (source === 'property') {
        localStorage.setItem('expandPropertyMortgageId', id)
      }
    },
    setInsuranceId(source, id) {
      if (source === 'building') {
        localStorage.setItem('expandBuildingInsuranceId', id)
      } else if (source === 'property') {
        localStorage.setItem('expandPropertyInsuranceId', id)
      }
    },
    redirectToDetail(buildingId, objectId, view) {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      }
    },
    linkToTicket() {
      if (this.ticketToLink !== '') {
        this.ticket.links.push({
          ticketId: this.ticketToLink,
          type: this.typeOfLink
        })
        this.listTickets.push(this.ticketsForLink.find(w => w.id === this.ticketToLink))
        this.typeOfLink = 'TICKET_LINK_TYPE_RELATES'
        this.ticketToLink = ''
        this.searchForTickets = ''
      }
    },
    showLinkBlock() {
      this.showLinkInputs = true
      const yOffset = -70
      const domRect = this.$el.querySelector('#linkedTickets')
      if (domRect) {
        const y = domRect.getBoundingClientRect().top + window.pageYOffset + yOffset

        window.scrollTo({
          top: y,
          behavior: 'smooth'
        })
      }
    },
    updateTicket(action) {
      if (action === 'link' && this.ticketToLink !== '') {
        this.ticket.links.push({
          ticketId: this.ticketToLink,
          type: this.typeOfLink
        })
        this.listTickets.push(this.ticketsForLink.find(w => w.id === this.ticketToLink))
        this.typeOfLink = 'TICKET_LINK_TYPE_RELATES'
        this.ticketToLink = ''
        this.searchForTickets = ''
      }
      let updateMask = null
      if (action === 'watcher') {
        updateMask = 'id,watchers'
      }
      if (this.publicTicket) {
        TicketApi.changedStatusPublicTicket(this.publicTicket.id, this.email, this.unregisteredName, this.publicTicket.status)
          .then(() => {
            this.hasChanged = false
            Vue.toasted.show(this.$t('message.successMessages.ticket'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            if (e.response.data.code === 2) {
              Vue.toasted.show(this.$t('message.savingErrors.ticketToItSelf'), { type: 'error' })
            } else {
              Vue.toasted.show(this.$t('message.savingErrors.ticket'), { type: 'error' })
            }
          })
      }
      if ((action !== 'status' && this.publicTicket) || !this.publicTicket) {
        TicketApi.updateTicket(this.ticket, updateMask)
          .then(response => {
            if (action === 'link') {
              this.showLinkInputs = false
            } else if (action === 'watcher') {
              this.ticket = response.ticket
            }
            this.hasChanged = false
            if (action === 'link') {
              Vue.toasted.show(this.$t('message.successMessages.addLinkTicket'), { type: 'success' })
            } else if (action === 'removeLink') {
              Vue.toasted.show(this.$t('message.successMessages.removeLinkTicket'), { type: 'success' })
            } else {
              Vue.toasted.show(this.$t('message.successMessages.ticket'), { type: 'success' })
            }
          })
          .catch(e => {
            console.log(e)
            if (e.response.data.code === 2) {
              Vue.toasted.show(this.$t('message.savingErrors.ticketToItSelf'), { type: 'error' })
            } else {
              Vue.toasted.show(this.$t('message.savingErrors.ticket'), { type: 'error' })
            }
          })
      }
    },
    confirmUnregisteredName() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        localStorage.setItem('unregisteredName' + this.email, this.unregisteredName)
        this.$refs['unregistered-name-modal'].hide()
      }
    },
    confirmCancellation() {
      this.$refs.confirmationModalTab.hide()
      this.comment = ''
      this.hasChanged = false
      this.oldValTab = this.tabIndex
    },
    cancelCancellation() {
      this.$refs.confirmationModalTab.hide()
      this.tabIndex = this.oldValTab
      this.$nextTick(function () {
        if (this.$refs.commentBlock) {
          this.$refs.commentBlock.commentInputEnabled = true
        }
        this.$el.querySelector('#block-comment textarea').focus()
      })
    },
    removeTicketLinked() {
      this.ticket.links = this.ticket.links.filter(x => !(x.ticketId === this.removeLinkedTicket.ticketId && x.type === this.removeLinkedTicket.type))
      this.updateTicket('removeLink')
      this.removeLinkedTicket = null
      this.$refs.confirmationModalRemoveLinkedTicket.hide()
    },
    removeTicketLinkedClicked(ticket) {
      this.removeLinkedTicket = ticket
      this.$refs.confirmationModalRemoveLinkedTicket.show()
    },
    confirmDeleteTicket() {
      this.$bvModal.show('confirmationModalDeleteTicket')
    },
    showAddTicketConnectionsModal() {
      this.addTicketConnectionsModal = true
      this.$nextTick(function () {
        this.$refs.addTicketConnectionsModal?.show()
      })
    },
    closeAddTicketConnectionsModal() {
      this.addTicketConnectionsModal = false
    },
    deleteTicket() {
      TicketApi.deleteTicket(this.ticket.id)
        .then(() => {
          this.redirectToOverview()
          Vue.toasted.show(this.$t('message.successMessages.deleteTicket'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.ticket'), { type: 'error' })
        })
    },
    loadComments() {
      if (this.ticket.id) {
        NoteApi.getCommentByReferenceIds([this.ticket.id])
          .then(async response => {
            this.commentList = response.notes
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.events'), { type: 'error' })
          })
      }
    },
    redirectToOverview() {
      let query = null
      if (this.prevRoutePath.includes('/tickets/')) {
        query = this.prevRouteQuery
      }
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketOverview', query: query }, () => {
        })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketOverview', query: query }, () => {
        })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketOverview', query: query }, () => {
        })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketOverview', query: query }, () => {
        })
      } else {
        this.$router.push({ name: 'MarketingTicketOverview', query: query }, () => {
        })
      }
    },
    commentAdded(note) {
      this.commentList.unshift(note)
      this.hasChanged = false
    },
    commentUpdated(note) {
      this.commentList.forEach((value, index) => {
        if (value.id === note.id) {
          Vue.set(this.commentList, index, note)
        }
      })
      this.hasChanged = false
    },
    commentDeleted(note) {
      const index = this.commentList.findIndex(c => c.id === note.id)
      Vue.delete(this.commentList, index)
    },
    watchComment(value) {
      this.hasChanged = value
    },
    commentEdited(value) {
      this.comment = value
    },
    getDateTimeFormatted(timestampInSeconds) {
      return this.$options.filters.timestampToDateTime(timestampInSeconds)
    },
    backClicked() {
      if (this.prevRoutePath.includes('tickets/edit') || this.prevRoutePath.includes('tickets/create')) {
        if (this.isOwnerModule) {
          this.$router.push({ name: 'OwnerTicketOverview' }, () => {})
        } else if (this.isAdminModule) {
          this.$router.push({ name: 'AdminTicketOverview' }, () => {})
        } else if (this.isAssetModule) {
          this.$router.push({ name: 'AssetTicketOverview' }, () => {})
        } else if (this.isAccountingModule) {
          this.$router.push({ name: 'AccountingTicketOverview' }, () => {})
        } else {
          this.$router.push({ name: 'MarketingTicketOverview' }, () => {})
        }
      } else {
        this.$router.go(-1)
      }
    },
    redirectToRequestDetail(objectId, requestId) {
      return {
        name: 'MarketingObjectDetailsView',
        params: { id: objectId },
        query: {
          view: 0,
          elementSelected: requestId
        }
      }
    },
    getTickets() {
      TicketApi.ListTicketByQuery(this.searchForTickets)
        .then(response => {
          this.ticketsForLink = response.tickets
        })
    },
    formatDateForCalendar(date, time) {
      // start and end need to be in format the calendar package can understand
      return `${date.day.toString().padStart(2, '0')}.${date.month.toString().padStart(2, '0')}.${date.year}` +
        ` ${time.hours.toString().padStart(2, '0')}:${time.minutes.toString().padStart(2, '0')}`
    },
    imageClicked(item) {
      if (item.url && item.url !== '') {
        this.galleryImages = [
          item.url
        ]
        this.$nextTick(function () {
          this.$viewerApi({
            images: this.galleryImages,
            options: this.viewerOptions
          })
        })
      }
    },
    onSelectLinked(selected) {
      if (selected) {
        if (!this.checkIsDisabled(selected.item)) {
          this.ticketToLink = selected.item.id
          this.searchForTickets = null
          this.$nextTick(function () {
            this.searchForTickets = selected.item.key + ' ' + selected.item.title
            this.refreshAutosuggestKey = Math.floor(Math.random() * 100)
            this.hasChanged = true
          })
        } else {
          this.$nextTick(function () {
            this.searchForTickets = null
            this.ticketToLink = ''
            this.ticketsForLink = []
          })
        }
      }
    },
    removeWatcher() {
      const index = this.ticket.watchers.findIndex(c => c.userId === this.$store.getters['user/getUserId'])
      Vue.delete(this.ticket.watchers, index)
      this.updateTicket('watcher')
    },
    addWatcher() {
      this.ticket.watchers.push({
        accountId: this.accountId,
        userId: this.userId
      })
      this.updateTicket('watcher')
    },
    mountedReference(references) {
      const refs = []

      // Skip loading of references if we're not logged in (public page)
      if (!this.accountId) {
        return refs
      }

      for (let index = 0; index < references.length; index++) {
        refs.push({
          object: null,
          building: null,
          property: null,
          condominium: null,
          tenancy: null,
          device: null,
          contact: null,
          request: null,
          room: null,
          mortgage: null,
          insurance: null,
          listContact: [],
          relatedReference: []
        })
        const element = references[index].id
        if (element.startsWith('condominium') || element.startsWith('tenancy')) {
          if (element.startsWith('condominium')) {
            ObjectApi.getCondominiumByIds([element])
              .then(response => {
                refs[index].condominium = response.condominiums[0]
                if (refs[index] && refs[index].condominium) {
                  ContactApi.contactResolve(refs[index].condominium.owner.contactIds)
                    .then(responseCon => {
                      refs[index].listContact = responseCon.persons.concat(responseCon.companies)
                    })
                  ObjectApi.getObject(refs[index].condominium.objectId)
                    .then(responseObj => {
                      refs[index].object = responseObj.object
                      ObjectApi.getBuildingByObjectId(refs[index].object.id).then(responseBuild => {
                        refs[index].building = responseBuild.building
                        ObjectApi.getPropertiesByBuildingIds([refs[index].building.id])
                          .then(responseProp => {
                            refs[index].property = responseProp.properties[0]
                          })
                      }).catch(e => {
                        console.log(e)
                        Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                      })
                    })
                }
              })
          } else if (element.startsWith('tenancy')) {
            ObjectApi.getTenanciesByIds([element])
              .then(response => {
                refs[index].tenancy = response.tenancies[0]
                ContactApi.contactResolve(refs[index].tenancy.tenant.contactIds)
                  .then(responseCon => {
                    refs[index].listContact = responseCon.persons.concat(responseCon.companies)
                  })
                ObjectApi.getObject(refs[index].tenancy.objectId)
                  .then(response => {
                    refs[index].object = response.object
                    ObjectApi.getBuildingByObjectId(refs[index].object.id).then(response => {
                      refs[index].building = response.building
                      ObjectApi.getPropertiesByBuildingIds([refs[index].building.id])
                        .then(response => {
                          refs[index].property = response.properties[0]
                        })
                    }).catch(e => {
                      console.log(e)
                      Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                    })
                  })
              })
          }
        }
        if (element.startsWith('request')) {
          RequestApi.listByIds([element])
            .then(response => {
              this.references[index].request = response.requests[0]
              const cont = response.requests[0].requesters[0].contactId
              ContactApi.contactResolve([cont])
                .then(response => {
                  this.references[index].listContact = response.persons.concat(response.companies).filter(c => cont.includes(c.id))
                })
              if (this.references[index].request) {
                ObjectApi.getObject(this.references[index].request.objectId)
                  .then(response => {
                    this.references[index].object = response.object
                    ObjectApi.getBuildingByObjectId(this.references[index].object.id).then(response => {
                      this.references[index].building = response.building
                      ObjectApi.getPropertiesByBuildingIds([this.references[index].building.id])
                        .then(response => {
                          this.references[index].property = response.properties[0]
                        })
                    }).catch(e => {
                      console.log(e)
                      Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                    })
                  })
              }
            })
        }
        if (element.startsWith('obj')) {
          ObjectApi.getObject(element)
            .then(response => {
              refs[index].object = response.object
              ObjectApi.getBuildingByObjectId(refs[index].object.id).then(response => {
                refs[index].building = response.building
                ObjectApi.getPropertiesByBuildingIds([refs[index].building.id])
                  .then(response => {
                    refs[index].property = response.properties[0]
                  })
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
              })
            })
        }
        if (element.startsWith('room')) {
          RoomApi.listById([element]).then(roomResponse => {
            const room = roomResponse.rooms[0]
            refs[index].room = room
            if (room && room.referenceId.startsWith('obj')) {
              ObjectApi.getObject(room.referenceId)
                .then(response => {
                  refs[index].object = response.object
                  ObjectApi.getBuildingByObjectId(refs[index].object.id).then(response => {
                    refs[index].building = response.building
                    ObjectApi.getPropertiesByBuildingIds([refs[index].building.id])
                      .then(response => {
                        refs[index].property = response.properties[0]
                      })
                  }).catch(e => {
                    console.log(e)
                    Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                  })
                })
            }
            if (room && room.referenceId.startsWith('building')) {
              ObjectApi.getBuilding(room.referenceId).then(response => {
                refs[index].building = response.building
                ObjectApi.getPropertiesByBuildingIds([refs[index].building.id])
                  .then(response => {
                    refs[index].property = response.properties[0]
                  })
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
              })
            }
          })
        }
        if (element.startsWith('building')) {
          ObjectApi.getBuilding(element).then(response => {
            refs[index].building = response.building
            ObjectApi.getPropertiesByBuildingIds([element])
              .then(response => {
                refs[index].property = response.properties[0]
              })
          })
        }
        if (element.startsWith('prop')) {
          ObjectApi.getPropertyListByIds([element])
            .then(response => {
              refs[index].property = response.properties[0]
            })
        }
        if (element.startsWith('contact')) {
          ContactApi.contactResolve([element])
            .then(response => {
              refs[index].contact = (response.persons.concat(response.companies)).find(x => x.id === element)
            })
        }
        if (element.startsWith('device')) {
          ObjectApi.listDevicesByIds([element])
            .then(response => {
              if (response.devices.length > 0) {
                refs[index].device = response.devices[0]
                if (response.devices[0].referenceId?.startsWith('obj')) {
                  ObjectApi.getObject(response.devices[0].referenceId)
                    .then(response1 => {
                      refs[index].object = response1.object
                      refs[index].relatedReference.push(response1.object)
                      RoomApi.getRoomsByReferenceIds([response1.object.id]).then(response2 => {
                        if (response2.rooms.length > 0) {
                          refs[index].relatedReference.push(response2.rooms.find(x => x.id === response.devices[0].roomId))
                        }
                      })
                      ObjectApi.getBuildingByObjectId(refs[index].object.id).then(response => {
                        refs[index].building = response.building
                        ObjectApi.getPropertiesByBuildingIds([refs[index].building.id])
                          .then(response => {
                            refs[index].property = response.properties[0]
                          })
                      }).catch(e => {
                        console.log(e)
                        Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                      })
                    })
                }
                if (response.devices[0].referenceId?.startsWith('building')) {
                  ObjectApi.getBuilding(response.devices[0].referenceId)
                    .then(response => {
                      refs[index].building = response.building
                      refs[index].relatedReference.push(response.building)
                      ObjectApi.getPropertiesByBuildingIds(response.building.id)
                        .then(response => {
                          refs[index].property = response.properties[0]
                        })
                    })
                }
                if (response.devices[0].referenceId?.startsWith('prop')) {
                  ObjectApi.getPropertyListByIds([response.devices[0].referenceId])
                    .then(response => {
                      refs[index].property = response.properties[0]
                      refs[index].relatedReference.push(response.properties[0])
                    })
                }
              }
            })
        }
        if (element.startsWith('mortgage')) {
          ObjectApi.getMortgagesByIds([element])
            .then(response => {
              if (response.mortgages.length > 0) {
                refs[index].mortgage = response.mortgages[0]
                if (response.mortgages[0].referenceId?.startsWith('building')) {
                  ObjectApi.getBuilding(response.mortgages[0].referenceId)
                    .then(buildingResponse => {
                      refs[index].building = buildingResponse.building
                      ObjectApi.getPropertiesByBuildingIds(buildingResponse.building.id)
                        .then(propertResponse => {
                          refs[index].property = propertResponse.properties[0]
                        })
                    })
                }
                if (response.mortgages[0].referenceId?.startsWith('prop')) {
                  ObjectApi.getPropertyListByIds([response.mortgages[0].referenceId])
                    .then(propertyResponse => {
                      refs[index].property = propertyResponse.properties[0]
                    })
                }
              }
            })
        }
        if (element.startsWith('ins')) {
          ObjectApi.getInsurancesByIds([element])
            .then(response => {
              if (response.insurances.length > 0) {
                refs[index].insurance = response.insurances[0]
                if (response.insurances[0].referenceId?.startsWith('building')) {
                  ObjectApi.getBuilding(response.insurances[0].referenceId)
                    .then(buildingResponse => {
                      refs[index].building = buildingResponse.building
                      ObjectApi.getPropertiesByBuildingIds(buildingResponse.building.id)
                        .then(propertResponse => {
                          refs[index].property = propertResponse.properties[0]
                        })
                    })
                }
                if (response.insurances[0].referenceId?.startsWith('prop')) {
                  ObjectApi.getPropertyListByIds([response.insurances[0].referenceId])
                    .then(propertyResponse => {
                      refs[index].property = propertyResponse.properties[0]
                    })
                }
              }
            })
        }
      }
      return refs
    },
    replyMail(email) {
      this.prepareReplyMail(email)
    },
    replyAllMail(email) {
      this.prepareReplyAll(email)
    },
    forwardMail(email) {
      this.prepareForwardMail(email)
    },
    printMessage(shortContent) {
      this.shortContent = shortContent
    },
    refreshList() {
      if (this.ticket?.id && this.ticket.id !== '') {
        this.loadMails([this.ticket.id])
      }
    },
    showDetail(mail) {
      this.dynamicId = Math.random().toString(36).substr(2, 9)
      this.selectedMail = mail
      this.$nextTick(function () {
        this.$refs['mail-detail-modal']?.show()
      })
    },
    forwardAsAttachement(mediaList, email) {
      this.setSubject('Fwd: ' + email.subject)
      this.setSendMailHasContext(true)
      this.setSendMailContacts([])
      this.setEmailToForwardAsAttachment(mediaList)
      this.$nextTick(function () {
        if (this.getMailboxesForUserId(this.userId).length === 0) {
          this.$nextTick(function () {
            Vue.toasted.show(this.$t('message.settings.mailbox.configMailboxToast'), { type: 'error' })
          })
        } else {
          this.callShowSendMailModal()
        }
      })
    },
    unlinkTicket(email) {
      MessageApi.setReferenceIds([email.id], email.referenceIds.filter(x => x !== this.ticket.id))
        .then(() => {
          Vue.toasted.show(this.$t('message.successMessages.emailUnLinked'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.reference'), { type: 'error' })
        })
        .finally(() => {
          this.loadMails([this.ticket.id])
          this.$bvModal.hide('confirmationModalUnlinkTicket' + email ? email.id : '')
        })
    },
    discardChanges() {
      if (this.$refs.confirmationModal) {
        this.$refs.confirmationModal.hide()
      }
      this.hasChanged = false
      if (this.nextPath !== null) {
        this.nextPath()
      } else {
        this.$router.go(-1)
      }
    }
  },
  validations: {
    unregisteredName: {
      required,
      moreThanTwoChar
    }
  }
}
</script>

<style lang="scss" scoped>
.linked-row {
  border-radius: 0.2rem;
  background-color: white;
  border: 1px solid $color-input-border;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

  .label {
    line-height: 1.8rem;
  }

  .status-design {
    min-width: 125px !important;
    text-align: center;
    padding: 0.35rem 0.5rem;
    text-transform: uppercase;
    font-size: small;
    border-radius: 0.2rem;

    &.open {
      background: lightgrey;
    }

    &.in_progress {
      background: lightskyblue;
    }

    &.done {
      background: darkseagreen;
    }
  }

  .remove-linked-btn {
    visibility: hidden;
  }
}

.linked-row:hover {
  .remove-linked-btn {
    visibility: visible !important;
  }
}

:deep(.status-design) {
  text-transform: uppercase;
  font-size: small;
  width: auto;
  border-radius: 0.2rem;

  option {
    background-color: #FFF;
  }

  &.open {
    background-color: lightgrey;
  }

  &.in_progress {
    background-color: lightskyblue;
  }

  &.done {
    background-color: darkseagreen;
  }
}

:deep(.icon-dropdown) {
  padding: 0 0.5rem;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-decoration: none !important;
  background-color: transparent;

  &:hover, &:active {
    background-color: transparent;
  }
}

.watcher-element {
  :deep(.dropdown-menu-right) {
    width: 14rem;

    .dropdown-item, .b-dropdown-text {
      background-color: transparent;
      padding: 0.25rem 0.5rem;
      color: initial !important;
      text-decoration: none !important;
    }
  }
}

.uploaded-file {
  background-position: center center;
  background-size: cover;
}

.subtitle {
  color: #495057;
  font-weight: 400;
}
.z-index3 {
  z-index: 3;
}
.pos_abs {
  position: absolute;
  right: 20px;
  width: auto;
  text-align: right;
  z-index: 2;
}
.media-block {
    padding: 0.5rem;
    padding-left: 0;
}

@media (min-width: 768px) {
  .uploaded-file {
    max-height: 100px;
    min-height: 4em;
  }
}

@media (min-width: 992px) {
  .uploaded-file {
    max-height: 110px;
    min-height: 5em;
  }
}

@media (min-width: 1200px) {
  .uploaded-file {
    max-height: 120px;
    min-height: 5.5em;
  }
}

@media (min-width: 1600px) {
  .uploaded-file {
    max-height: 130px;
    min-height: 7em;
  }
}
.borderTop {
  border-top: 1px solid #ced4da;
  padding-top: 5px;
}
.title-card {
  color: #000;
}
:deep(.hrefSelector .nav-item a:hover) {
  text-decoration: none !important;
  cursor: default !important;
}
:deep(.hrefSelector .nav-item a .text-blue) {
  font-size: 0.875rem;
}
.disableOptionHover {
  cursor: default !important;
  color: #6c757d !important;
  opacity: 0.5;
}
.color-box.condo_owner{
  background: #f589c7;
}
.color-box.condominium{
  background: #913489;
}
.color-box.tenancy{
  background: #00a4bd;
}
.color-box.object{
  background: #f38d38;
}
.color-box.device{
  background: #767171;
}
.color-box.request {
  background: #f7b6ae;
}
.containerCircles {
  height: 21px;
  align-items: center;
  display: flex;
  align-self: baseline;
}
.connection a.router-link-active, .connection a {
  color: inherit !important;
  text-decoration: none !important;
}
.connection a.router-link-active:hover, .connection a:hover {
    color: #0055b7 !important;
    text-decoration: underline !important;
    cursor: pointer;
}
.align-actions {
  position: absolute;
  right: 0;
}
.logo_company {
  height: 40px;
}
:deep(.autosuggest__results-item.autosuggest__results-item--highlighted) {
  background-color: rgba(0,0,0,0.03);
}
.text-lg {
  font-size: 2.1875rem;
}
  :deep(.spinner-border) {
    width: 16px !important;
    height: 16px !important;
  }
  .iconFile {
    max-width: 45px;
  }

  :deep(.collapse-title) {
    &.collapsed {
      h5 {
        background-image: url('../../../assets/arrow_down.svg');
      }
    }

    h5 {
      background-image: url('../../../assets/arrow_up.svg');
      background-repeat: no-repeat;
      font-size: large;
      font-weight: 500 !important;
      height: 1.2rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      background-position: right;
    }
  }
  .iconsStatusUpdate {
    width: 20px;
  }
  .order-actions {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .active-watcher {
    color: #1398e3;
  }
  .length-watchers {
    color: #000;
    &.active {
      color: #1398e3;
    }
  }
  .link-icon {
    line-height: 2 !important;
  }
.block{
  cursor: pointer;
  .only-on-hover {
      visibility: hidden;
      line-height: 0;
  }
  &:hover .only-on-hover {
    visibility: visible;
  }
}
</style>
