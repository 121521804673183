<template>
  <section>
    <coozzy-card class="global-card no-top-border table-card">
      <div class="p-3">
        <!-- Heading -->
        <div class="row justify-content-between align-items-center">
          <div class="col-auto">
            <h5>
              {{ totalObjectCount }} {{ $t('message.managementOverview.buildings') }}
            </h5>
          </div>
          <div
            v-if="isEditor"
            class="col-12 col-md-2">
            <coozzy-button
              v-if="isOwnerModule"
              class="my-2 w-100"
              design="prop-green"
              @click="createBuildingClicked">
              {{ $t('message.generic.createNewBuildingActionButton') }}
            </coozzy-button>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-2 col-sm-6 col-12">
            <coozzy-dropdown
              design="green"
              size="sm"
              class="w-100"
              :text="selectedEntriesText">
              <coozzy-dropdown
                v-if="isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator)"
                design="transparent"
                size="sm"
                class="w-100 ticket-dropdown-bulk-action"
                :class="selectedEntries.length > 0 ? 'enabled' : ''"
                :disabled="true"
                :text="$t('message.ticketOverview.createTicket')"
                dropright
                @click.prevent>
                <coozzy-dropdown-item
                  :disabled="selectedEntries.length === 0"
                  @click="navigateToCreateOrder()">
                  {{ $t('message.ticketOverview.createOrder') }}
                </coozzy-dropdown-item>
                <coozzy-dropdown-item
                  :disabled="selectedEntries.length === 0"
                  @click="createTicket('TICKET_TYPE_TASK')">
                  {{ $t('message.ticketOverview.createTask') }}
                </coozzy-dropdown-item>
              </coozzy-dropdown>
              <coozzy-dropdown-item
                v-if="isEditor"
                :disabled="selectedEntries.length === 0"
                @click="showActivity()">
                {{ $t('message.contact.activity.create') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="$refs['reminder-creation'].show()">
                {{ $t('message.calendar.reminder.create') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-space-divider v-if="isEditor" />
              <coozzy-dropdown-item
                v-if="isEditor"
                :disabled="selectedEntries.length === 0 || selectionsHaveMultipleOwners || !isSelectedBuildingHasObjects"
                @click="callAction('addJanitors')">
                {{ $t('message.manageBuilding.bulkAction.addJanitors') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="isEditor"
                :disabled="selectedEntries.length === 0 || selectionsHaveMultipleOwners || !isSelectedBuildingHasObjects"
                @click="callAction('addCraftsmen')">
                {{ $t('message.manageBuilding.bulkAction.addCraftsmen') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="isEditor"
                :disabled="selectedEntries.length === 0 || selectionsHaveMultipleOwners || !isSelectedBuildingHasObjects"
                @click="callAction('addServiceProviders')">
                {{ $t('message.manageBuilding.bulkAction.addServiceProviders') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-space-divider />
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="redirectToExcelReport">
                {{ $t('message.generateExcel.title') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="!isJanitor"
                :disabled="selectedEntries.length === 0"
                @click="showCreateTenantReportModal">
                {{ $t('message.navigation.reporting.createTenantReport') }}
              </coozzy-dropdown-item>
              <!--              <coozzy-dropdown-space-divider v-if="isEditor && propertySelected.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP'" />-->
              <!--              <coozzy-dropdown-item-->
              <!--                v-if="propertySelected.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP'"-->
              <!--                :disabled="selectedEntries.length === 0"-->
              <!--                @click="createMassRental">-->
              <!--                {{ $t('message.massRental.title') }}-->
              <!--              </coozzy-dropdown-item>-->
              <coozzy-dropdown-space-divider v-if="isEditor" />
              <div
                v-if="isEditor"
                class="row m-0"
                :class="selectedEntries.length === 0 || propertySelected.transferToAccounting || isSelectedBuildingHasActivatedTenancy ? 'coozzyDropdownItemTooltip' : ''">
                <coozzy-dropdown-item
                  :disabled="selectedEntries.length === 0 || propertySelected.transferToAccounting || isSelectedBuildingHasActivatedTenancy"
                  @click="$bvModal.show('confirmationModalDeleteBuilding')">
                  {{ $t('message.manageBuilding.bulkAction.removeBuilding') }}
                </coozzy-dropdown-item>
                <div class="pl-0">
                  <coozzy-info-circle-icon
                    v-if="propertySelected.transferToAccounting"
                    v-b-tooltip.hover.html="$t('message.deleteErrors.deleteBuildingSynchronizedWithAccountingOrHasActiveTenancyTooltip')"
                    class="mt-2" />
                </div>
              </div>
            </coozzy-dropdown>
          </div>
          <div class="col-md-2 col-sm-6 col-12">
            <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
              <coozzy-form-checkbox
                v-model="selectAllEntries"
                :initial="selectAllEntries"
                :class="'p-0 w-100 btn-height-auto'"
                name="selectAllEntries"
                button>
                {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
              </coozzy-form-checkbox>
            </template>
          </div>
          <div class="col-md-2 col-sm-6 col-12 offset-md-6 mt-2 mt-sm-0">
            <coozzy-form-select
              v-model="nbrPerPage"
              name="numberEntries">
              <option value="5">
                5 {{ $t('message.generic.entries') }}
              </option>
              <option value="10">
                10 {{ $t('message.generic.entries') }}
              </option>
              <option value="15">
                15 {{ $t('message.generic.entries') }}
              </option>
              <option value="20">
                20 {{ $t('message.generic.entries') }}
              </option>
            </coozzy-form-select>
          </div>
        </div>
        <div
          id="rowBuildingsTable"
          class="row mt-2">
          <div class="col">
            <b-table
              id="buildingsTable"
              ref="buildingsTable"
              bordered
              hover
              responsive="true"
              stacked="md"
              selectable
              select-mode="single"
              :fields="fields"
              :busy="isBusy"
              :items="buildingsList"
              @row-clicked="onRowClicked"
              @row-middle-clicked="onMiddlelicked">
              <!-- Busy state -->
              <div
                slot="table-busy"
                class="text-center text-danger my-2">
                <coozzy-spinner />
              </div>

              <!-- Headings -->
              <template #head(checkbox)>
                <coozzy-form-checkbox
                  ref="header_checkbox"
                  @change="(value) => { handleCheckboxesInsideTable(value) }" />
              </template>
              <template #head(thumbnail)>
                {{ $t('message.generic.thumbnail') }}
              </template>
              <template #head(buildingsName)>
                {{ $t('message.tableColumnHeadings.buildingsName') }}
              </template>
              <template #head(numericId)>
                {{ $t('message.generic.buildingID.idShort') }}
              </template>
              <template #head(valueQuota)>
                {{ $t('message.tableColumnHeadings.valueQuota') }}
              </template>
              <template #head(buildingStrategy)>
                <span>{{ $t('message.onBoarding.buildings.buildingStrategy.type' ) }}</span>
              </template>
              <template #head(building_strategy_review)>
                <coozzy-sort-dropdown
                  ref="building_strategy_review_sort"
                  sorting-field="building_strategy_review"
                  :text="$t('message.onBoarding.buildings.buildingStrategy.reviewAt')"
                  @updateSorting="updateSorting" />
              </template>
              <template #head(address)>
                {{ $t('message.generic.address') }}
              </template>
              <template #head(objects)>
                <coozzy-sort-dropdown
                  ref="object_count_sort"
                  sorting-field="object_count"
                  :text="$t('message.managementOverview.objects')"
                  @updateSorting="updateSorting" />
              </template>
              <template #head(gross_target_rent_year)>
                <coozzy-sort-dropdown
                  ref="gross_target_rent_year_sort"
                  sorting-field="gross_target_rent_year"
                  :text="$t('message.tableColumnHeadings.grossTargetRentYear')"
                  @updateSorting="updateSorting" />
              </template>
              <template #head(gross_actual_rent_year)>
                <coozzy-sort-dropdown
                  ref="gross_actual_rent_year_sort"
                  sorting-field="gross_actual_rent_year"
                  :text="$t('message.tableColumnHeadings.grossActualRentYear')"
                  @updateSorting="updateSorting" />
              </template>
              <template #head(net_target_rent_year)>
                <coozzy-sort-dropdown
                  ref="net_target_rent_year_sort"
                  sorting-field="net_target_rent_year"
                  :text="$t('message.tableColumnHeadings.netTargetRentYear')"
                  @updateSorting="updateSorting" />
              </template>
              <template #head(net_actual_rent_year)>
                <coozzy-sort-dropdown
                  ref="net_actual_rent_year_sort"
                  sorting-field="net_actual_rent_year"
                  :text="$t('message.tableColumnHeadings.netActualRentYear')"
                  @updateSorting="updateSorting" />
              </template>
              <template
                #head(grossOverallRentPerYear)>
                <span>{{ $t('message.tableColumnHeadings.grossOverallRentPerYear' ) }}</span>
              </template>
              <template
                #head(netOverallRentPerYear)>
                <span>{{ $t('message.tableColumnHeadings.netOverallRentPerYear' ) }}</span>
              </template>
              <template
                #head(owner)>
                <span>{{ $t('message.tableColumnHeadings.owner' ) }}</span>
              </template>

              <!-- Columns -->
              <template #cell(checkbox)="data">
                <coozzy-form-checkbox
                  :id="'check_' + data.item.id"
                  @change="(value) => { itemSelected(data.item, value) }" />
              </template>
              <template #cell(thumbnail)="data">
                <coozzy-thumbnail
                  v-if="data.item.image"
                  :src="data.item.image.media.thumbnails[0].url"
                  :original="data.item.image.url" />
                <div
                  v-else
                  class="d-flex justify-content-center">
                  <coozzy-thumbnail
                    class="thumbnail"
                    src="/img/image-placeholder.png"
                    alt="Placeholder" />
                </div>
              </template>
              <template #cell(buildingsName)="data">
                {{ data.item.name }}
              </template>
              <template #cell(numericId)="data">
                {{ data.item.numericId | displayOptionalValue }}
              </template>
              <template #cell(buildingStrategy)="data">
                <template v-if="!data.item.buildingStrategy || data.item.buildingStrategy.type === 'BUILDING_STRATEGY_TYPE_UNDEFINED'">
                  -
                </template>
                <template v-else>
                  {{ $t('message.onBoarding.buildings.buildingStrategy.' + data.item.buildingStrategy.type ) }}
                </template>
              </template>
              <template #cell(building_strategy_review)="data">
                <template v-if="!data.item.buildingStrategy">
                  -
                </template>
                <template v-else>
                  {{ data.item.buildingStrategy.reviewAt | objectToDate }}
                </template>
              </template>
              <template #cell(address)="data">
                <address-text :address="data.item.address" />
              </template>
              <template #cell(objects)="data">
                {{ data.item.objectIds.length | displayOptionalValue }}
              </template>
              <template #cell(valueQuota)="data">
                {{ getValueQuotaByBuilding(data.item.id) + '/' + totalPropertyValueQuota | displayOptionalValue }}
              </template>
              <template #cell(gross_target_rent_year)="data">
                {{ data.item.grossActualRentPerYear | formatPrice }}
              </template>
              <template #cell(gross_actual_rent_year)="data">
                {{ data.item.grossTargetRentPerYear | formatPrice }}
              </template>
              <template #cell(net_target_rent_year)="data">
                {{ data.item.netActualRentPerYear | formatPrice }}
              </template>
              <template #cell(net_actual_rent_year)="data">
                {{ data.item.netTargetRentPerYear | formatPrice }}
              </template>
              <template #cell(grossOverallRentPerYear)="data">
                {{ data.item.grossOverallRentPerYear | formatPrice }}
                <coozzy-info-circle-icon
                  v-if="targetRentIncomplete(data.item.id)"
                  v-b-tooltip="{container: '#buildingsTable', title: $t('message.manageBuilding.noExactValue')}" />
              </template>
              <template #cell(netOverallRentPerYear)="data">
                {{ data.item.netOverallRentPerYear | formatPrice }}
                <coozzy-info-circle-icon
                  v-if="targetRentIncomplete(data.item.id)"
                  v-b-tooltip="{container: '#buildingsTable', title: $t('message.manageBuilding.noExactValue')}" />
              </template>
              <template #cell(owner)>
                <template v-if="owner && owner.company">
                  {{ owner.company.name }}
                </template>
                <template v-else-if="owner && owner.individual">
                  {{ owner.individual.firstName.substr(0, 1) }}. {{ owner.individual.lastName }}
                </template>
                <template v-else>
                  -
                </template>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              align="center"
              class="mb-3"
              :total-rows="buildingList.length"
              :per-page="nbrPerPage" />
          </div>
        </div>
      </div>
      <create-buildings-modal
        ref="new-building"
        :default-address="propertySelected.address"
        @building-created="buildingCreated" />
      <reminder-creation-modal
        ref="reminder-creation"
        :references-ids="selectedEntriesIds"
        :source="'building'"
        @new-reminder="clearCheckbox" />
      <object-contacts-bulk-action
        v-if="selectedActions"
        ref="object-contacts-bulk"
        :title="selectedActions"
        :owner-ids="selectedOwnerIds"
        :building-ids="selectedEntriesIds"
        @action-done="clearArrays()"
        @action-cancel="clearArrays()" />
      <b-modal
        :id="'confirmationModalDeleteBuilding'"
        ref="confirmationModalDeleteBuilding"
        no-close-on-backdrop
        hide-footer
        :title="$t('message.manageBuilding.deleteConfirmation.title')">
        <div
          v-if="loadingDeletion"
          class="row">
          <div class="col text-center">
            <coozzy-spinner class="m-3" />
          </div>
        </div>
        <template v-else>
          <div class="col p-0">
            <p>{{ $t('message.manageBuilding.deleteConfirmation.text') }}</p>
          </div>
          <div class="col p-0">
            <coozzy-button
              size="small"
              class="mb-0 border"
              design="transparent"
              @click="$bvModal.hide('confirmationModalDeleteBuilding')">
              {{ $t('message.generic.cancel') }}
            </coozzy-button>
            <coozzy-button
              size="small"
              class="float-right mb-0"
              design="green"
              @click="deleteBuilding()">
              {{ $t('message.generic.delete') }}
            </coozzy-button>
          </div>
        </template>
      </b-modal>
      <create-activity-modal
        ref="activity-creation"
        :key="keyActivity"
        :ids="selectedEntriesIds"
        @activity-created="clearCheckbox" />
      <create-tenant-report-modal
        v-if="showCreateTenantReport"
        ref="createTenantReportModal"
        :buildings-ids="selectedEntriesIds"
        @hide="hideCreateTenantReportModal" />
    </coozzy-card>
  </section>
</template>

<script>
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import ObjectApi from '@/misc/apis/ObjectApi'
import ReportingApi from '@/misc/apis/ReportingApi'
import AddressText from '../../framework/components/misc/AddressText'
import CoozzyThumbnail from '../../framework/components/img/CoozzyThumbnail'
import CoozzyFormCheckbox from '../../framework/components/form/checkbox/CoozzyFormCheckbox'
import MediaApi from '@/misc/apis/MediaApi'
import ShareApi from '@/misc/apis/ShareApi'
import { algolia } from '@/mixins/algolia'
import ObjectContactsBulkAction from '@/owner/ObjectContactsBulkAction'
import Vue from 'vue'
import CreateBuildingsModal from '../../building/CreateBuildingsModal'
import CoozzySortDropdown from '../../framework/components/dropdown/CoozzySortDropdown'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzyFormSelect from '../../framework/components/form/select/CoozzyFormSelect'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import CoozzyInfoCircleIcon from '../../framework/components/icons/CoozzyInfoCircleIcon'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import CoozzyDropdownSpaceDivider from '@/framework/components/dropdown/CoozzyDropdownSpaceDivider'
import { routeChecks } from '@/mixins/routeChecks'
import { tables } from '@/mixins/tables'
import { user } from '@/mixins/user'
import UserApi from '@/misc/apis/UserApi'
import CreateTenantReportModal from '@/reporting/CreateTenantReportModal.vue'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'

export default {
  name: 'BuildingsTab',
  components: {
    CoozzyCard,
    CreateTenantReportModal,
    CoozzyDropdownSpaceDivider,
    CoozzyDropdownItem,
    CreateActivityModal,
    CoozzyDropdown,
    CoozzyInfoCircleIcon,
    CoozzySpinner,
    CoozzyFormSelect,
    CoozzyButton,
    CoozzySortDropdown,
    CreateBuildingsModal,
    CoozzyFormCheckbox,
    CoozzyThumbnail,
    AddressText,
    ReminderCreationModal,
    ObjectContactsBulkAction
  },
  mixins: [routeChecks, algolia, tables, user],
  props: {
    propertySelected: {
      type: Object,
      default: null
    },
    view: {
      type: String,
      default: 'null'
    }
  },
  data() {
    return {
      galleryImages: [],
      galleryIndex: null,
      sharesList: [],
      isBusy: true,
      owner: null,
      keyActivity: 0,
      buildingList: [],
      selectedEntries: [],
      nbrPerPage: 5,
      currentPage: 1,
      selectedActions: '',
      selectedObjectIds: [],
      totalObjectCount: 0,
      totalPageCount: 0,
      filterList: [],
      selectedOwnerIds: [],
      buildingRentReports: null,
      sorting: null,
      loadingDeletion: false,
      objectHasActivatedTenancy: [],
      selectionsHaveMultipleOwners: false,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'thumbnail',
          label: this.$t('message.generic.thumbnail'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center'
        },
        {
          key: 'buildingsName',
          label: this.$t('message.tableColumnHeadings.buildingsName'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'numericId',
          label: this.$t('message.generic.buildingID.idShort'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center'
        },
        {
          key: 'buildingStrategy',
          label: this.$t('message.generic.buildingStrategy.type'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center border-left-0'
        },
        {
          key: 'building_strategy_review',
          label: this.$t('message.onBoarding.buildings.buildingStrategy.reviewAt'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center border-left-0'
        },
        {
          key: 'address',
          label: this.$t('message.generic.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'objects',
          label: this.$t('message.managementOverview.objects'),
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle'
        },
        {
          key: 'valueQuota',
          label: this.$t('message.tableColumnHeadings.valueQuota'),
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle'
        },
        {
          key: 'gross_target_rent_year',
          label: this.$t('message.tableColumnHeadings.grossTargetRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'gross_actual_rent_year',
          label: this.$t('message.tableColumnHeadings.grossActualRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'grossOverallRentPerYear',
          label: this.$t('message.tableColumnHeadings.grossOverallRentPerYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'net_target_rent_year',
          label: this.$t('message.tableColumnHeadings.netTargetRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'net_actual_rent_year',
          label: this.$t('message.tableColumnHeadings.netActualRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'netOverallRentPerYear',
          label: this.$t('message.tableColumnHeadings.netOverallRentPerYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        }
      ],
      valuesQuota: [],
      showCreateTenantReport: false,
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: []
    }
  },
  computed: {
    nbrTotalOfHits() {
      return this.buildingList.length
    },
    isSelectedBuildingHasActivatedTenancy() {
      return this.objectHasActivatedTenancy.some(x => x.hasActivatedTenancy && this.selectedObjectIds.includes(x.objectId))
    },
    buildingsList() {
      let list = this.buildingList
      const current = this
      if (this.sorting !== null) {
        list = list.sort(function (a, b) {
          const sortValue = current.sorting.value === 'asc' ? 1 : -1
          if (current.sorting.field === 'object_count') {
            return (a.objectIds.length - b.objectIds.length) * sortValue
          }
          if (current.sorting.field === 'gross_target_rent_year') {
            return (a.grossTargetRentPerYear - b.grossTargetRentPerYear) * sortValue
          }
          if (current.sorting.field === 'gross_actual_rent_year') {
            return (a.grossActualRentPerYear - b.grossActualRentPerYear) * sortValue
          }
          if (current.sorting.field === 'net_target_rent_year') {
            return (a.netTargetRentPerYear - b.netTargetRentPerYear) * sortValue
          }
          if (current.sorting.field === 'net_actual_rent_year') {
            return (a.netActualRentPerYear - b.netActualRentPerYear) * sortValue
          }
          if (current.sorting.field === 'building_strategy_review') {
            const reviewA = a.buildingStrategy.reviewAt !== null ? new Date(current.$options.filters.objectToDate(a.buildingStrategy.reviewAt)) : 0
            const reviewB = b.buildingStrategy.reviewAt !== null ? new Date(current.$options.filters.objectToDate(b.buildingStrategy.reviewAt)) : 0
            return (reviewA - reviewB) * sortValue
          }
          return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0
        })
      } else {
        list = list.sort(function (a, b) {
          return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0
        })
      }
      if (this.nbrPerPage !== 'all') {
        list = list.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
      }
      return list
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.id) : this.selectedEntries.map(x => x.id)
    },
    isSelectedBuildingHasObjects() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.filter(entry => entry.objectIds && entry.objectIds.length > 0).length > 0
    },
    totalPropertyValueQuota() {
      return this.valuesQuota.reduce((accumulator, object) => {
        return accumulator + object.valueQuota
      }, 0).toFixed(2)
    }
  },
  watch: {
    currentPage: function () {
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    selectedOwnerIds: function() {
      this.selectionsHaveMultipleOwners = this.checkMultiple()
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        this.allEntries = this.buildingList
        this.$nextTick(async () => {
          this.selectedOwnerIds = this.allEntries.map(x => x.ownerId)
          this.selectedObjectIds = this.allEntries.flatMap(x => x.objectIds)
        })
      } else {
        this.selectedOwnerIds = this.selectedEntries.map(x => x.ownerId)
        this.selectedObjectIds = this.selectedEntries.flatMap(x => x.objectIds)
      }
    }
  },
  mounted: function () {
    if (this.isJanitor) {
      this.fields = this.fields.filter(x => x.key !== 'gross_target_rent_year' && x.key !== 'gross_actual_rent_year' && x.key !== 'net_target_rent_year' && x.key !== 'net_actual_rent_year')
    }
    if (this.isAdminModule || this.isAssetModule || this.isAccountingModule) {
      UserApi.getAccount(this.propertySelected.ownerId)
        .then(response => {
          this.owner = response.account
        })
      this.fields.push(
        {
          key: 'owner',
          label: 'Owner',
          thClass: 'th-assignee d-none d-lg-table-cell align-middle ',
          tdClass: 'align-middle d-none d-lg-table-cell'
        }
      )
    }
    if (this.propertySelected?.type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
      this.fields = this.fields.filter(x => x.key !== 'gross_target_rent_year' && x.key !== 'gross_actual_rent_year' && x.key !== 'net_target_rent_year' && x.key !== 'net_actual_rent_year')
    } else {
      this.fields = this.fields.filter(x => x.key !== 'valueQuota')
    }
    this.getBuildingList()
  },
  methods: {
    createMassRental() {
      const buildingIds = this.selectedEntriesIds.join()
      this.$router.push({ name: this.moduleRoutePrefix + 'CreateMassRentalView', query: { source: 'building', buildingIds: buildingIds } })
    },
    hideCreateTenantReportModal() {
      this.showCreateTenantReport = false
      this.clearCheckbox()
    },
    showCreateTenantReportModal() {
      this.showCreateTenantReport = true
      this.$nextTick(() => {
        this.$refs.createTenantReportModal?.show()
      })
    },
    getValueQuota() {
      for (let i = 0; i < this.buildingList.length; i++) {
        const buildingId = this.buildingList[i].id
        ObjectApi.getTotalValueQuotaForBuilding(buildingId)
          .then(response => {
            const obj = { buildingId: buildingId, valueQuota: response.totalValueQuota }
            this.valuesQuota.push(obj)
          })
      }
    },
    getValueQuotaByBuilding(id) {
      const building = this.valuesQuota.find(x => x.buildingId === id)
      if (building) {
        return building.valueQuota.toFixed(2)
      }
      return '-'
    },
    createTicket(type) {
      const defaultAssignee = this.propertySelected.ownerId
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      }
    },
    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const buildingIds = this.selectedEntriesIds.join(',')
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { buildingIds: buildingIds, source: 'building' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { buildingIds: buildingIds, source: 'building' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { buildingIds: buildingIds, source: 'building' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { buildingIds: buildingIds, source: 'building' } }, () => {})
      }
    },
    onRowClicked(item, index, event) {
      if (event.srcElement && (event.srcElement.classList.contains('custom-checkbox') || event.srcElement.classList.contains('checkbox-cell'))) {
        this.itemSelected(item, !document.getElementById('check_' + item.id).checked)
        document.getElementById('check_' + item.id).checked = !document.getElementById('check_' + item.id).checked
      } else {
        this.buildingClicked(item, index, event)
      }
    },
    showActivity() {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.$nextTick(() => {
        this.$refs['activity-creation'].show()
      })
    },
    onMiddlelicked(item) {
      if (item) {
        let route = null
        if (this.isOwnerModule) {
          route = this.$router.resolve({
            name: 'OwnerBuildingDetailsView',
            params: { id: item.id },
            query: { view: 'building' }
          })
        } else if (this.isAdminModule) {
          route = this.$router.resolve({
            name: 'AdminBuildingDetailsView',
            params: { id: item.id },
            query: { view: 'building' }
          })
        } else if (this.isAssetModule) {
          route = this.$router.resolve({
            name: 'AssetBuildingDetailsView',
            params: { id: item.id },
            query: { view: 'building' }
          })
        } else if (this.isAccountingModule) {
          route = this.$router.resolve({
            name: 'AccountingBuildingDetailsView',
            params: { id: item.id },
            query: { view: 'building' }
          })
        }
        window.open(route.href)
      }
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    clearArrays() {
      this.clearCheckbox()
      this.selectedActions = ''
      this.selectedOwnerIds = []
      this.selectedObjectIds = []
    },
    checkMultiple() {
      for (let index = 0; index < this.selectedOwnerIds.length - 1; index++) {
        if (this.selectedOwnerIds[index] !== this.selectedOwnerIds[index + 1]) {
          return true
        }
      }
      return false
    },
    callAction(action) {
      this.selectedActions = action
      this.$nextTick(function () {
        this.$refs['object-contacts-bulk'].show()
      })
    },
    buildingClicked(building, index, event) {
      const element = event.srcElement
      if (element.tagName === 'IMG' && element.classList.contains('thumbnail')) {
        if (building && building.image) {
          this.galleryImages = [
            building.image.media.url
          ]
          this.$nextTick(function () {
            this.$viewerApi({
              images: this.galleryImages,
              options: this.viewerOptions
            })
          })
        }
      } else {
        if (this.view === 'editing') {
          this.$emit('building-clicked', building.id)
        } else {
          if (this.isOwnerModule) {
            this.$router.push({
              name: 'OwnerBuildingDetailsView',
              params: { id: building.id },
              query: { view: 'building' }
            })
          } else if (this.isAdminModule) {
            this.$router.push({
              name: 'AdminBuildingDetailsView',
              params: { id: building.id },
              query: { view: 'building' }
            })
          } else if (this.isAssetModule) {
            this.$router.push({
              name: 'AssetBuildingDetailsView',
              params: { id: building.id },
              query: { view: 'building' }
            })
          } else if (this.isAccountingModule) {
            this.$router.push({
              name: 'AccountingBuildingDetailsView',
              params: { id: building.id },
              query: { view: 'building' }
            })
          }
        }
      }
    },
    buildingCreated(building) {
      this.propertySelected.buildingIds.push(building.building.id)
      ObjectApi.updateProperty(this.propertySelected)
        .then(() => {
          this.buildingList.push(building.building)
          this.totalObjectCount++
        })
        .catch(e => {
          console.log(e)
          this.isBusy = false
          Vue.toasted.show(this.$t('message.savingErrors.building'), { type: 'error' })
        })
    },
    clickSearch() {
      this.currentPage = 1
      this.getBuildingList()
    },
    createBuildingClicked() {
      this.$refs['new-building'].show()
    },
    getBuildingList() {
      this.isBusy = true
      const current = this
      ObjectApi.getBuildingsListByIds(this.propertySelected.buildingIds)
        .then(async response => {
          this.totalObjectCount = response.buildings.length
          this.buildingList = response.buildings
          if (this.propertySelected?.type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
            this.getValueQuota()
          }
          let objectList = []
          const promises = []
          for (const building of this.buildingList) {
            objectList = objectList.concat(building.objectIds)
            if (building.imageMediaId) {
              promises.push(MediaApi.getMediaByID(building.imageMediaId))
            } else {
              promises.push(Promise.resolve(null))
            }
          }
          Promise.all(promises)
            .then(listImages => {
              for (const building of response.buildings) {
                if (building.imageMediaId) {
                  building.image = listImages.find(x => x && x.media && x.media.id === building.imageMediaId)
                }
              }
              this.buildingList = response.buildings
            })

          if (objectList.length > 0) {
            ObjectApi.hasActivatedTenancy(objectList).then((response) => {
              this.objectHasActivatedTenancy = response.entries
            })
            ShareApi.listByResource(objectList)
              .then(response => {
                current.sharesList = response.shares
              })
              .catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.networkPartners'), { type: 'error' })
              })
          }
          ReportingApi.getBuildingRentReports(this.propertySelected.buildingIds)
            .then(response => {
              this.buildingRentReports = response.reports
              for (let index = 0; index < this.buildingList.length; index++) {
                const report = response.reports.find(element => element.referenceId === this.buildingList[index].id)
                if (report) {
                  this.buildingList[index].grossTargetRentPerYear = report.grossOverallRentPerYear
                  this.buildingList[index].grossActualRentPerYear = report.grossActualRentPerYear
                  this.buildingList[index].netTargetRentPerYear = report.netOverallRentPerYear
                  this.buildingList[index].netActualRentPerYear = report.netActualRentPerYear
                  this.buildingList[index].grossOverallRentPerYear = report.grossTargetRentPerYear
                  this.buildingList[index].netOverallRentPerYear = report.netTargetRentPerYear
                }
              }
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.rentReports'), { type: 'error' })
            })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.building'), { type: 'error' })
        })
        .finally(() => {
          this.isBusy = false
        })
    },
    handleCheckboxesInsideTable(value) {
      const allCheckboxesComponents = this.$refs.buildingsTable.$children[1].$children
      this.handleCheckbox(allCheckboxesComponents, value)
      this.selectAllVisibleEntries = value
      if (value === false) {
        this.selectAllEntries = false
      }
    },
    itemSelected(element, checked) {
      if (checked) {
        this.selectedEntries.push(element)
        this.selectedOwnerIds.push(element.ownerId)
        this.selectedObjectIds = this.selectedObjectIds.concat(element.objectIds)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.id !== element.id)
        const index = this.selectedOwnerIds.findIndex(x => x === element.ownerId)
        this.selectedOwnerIds.splice(index, 1)
        this.selectedObjectIds = this.selectedObjectIds.filter(function(obj) {
          return !this.has(obj)
        }, new Set(element.objectIds.map(obj => obj)))
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    targetRentIncomplete(buildingId) {
      if (this.buildingRentReports !== null) {
        const report = this.buildingRentReports.find(report => report.referenceId === buildingId)
        if (report) {
          return report.targetRentIncomplete
        }
      }
      return false
    },
    updateSorting(value, field) {
      if (field !== 'object_count') {
        this.$refs.object_count_sort.direction = ''
      }
      if (field !== 'gross_target_rent_year') {
        this.$refs.gross_target_rent_year_sort.direction = ''
      }
      if (field !== 'gross_actual_rent_year') {
        this.$refs.gross_actual_rent_year_sort.direction = ''
      }
      if (field !== 'net_target_rent_year') {
        this.$refs.net_target_rent_year_sort.direction = ''
      }
      if (field !== 'net_actual_rent_year') {
        this.$refs.net_actual_rent_year_sort.direction = ''
      }
      if (field !== 'building_strategy_review') {
        this.$refs.building_strategy_review_sort.direction = ''
      }
      this.sorting = null
      if (value !== '') {
        this.sorting = {
          field: field,
          value: value
        }
      }
    },
    deleteBuilding() {
      this.loadingDeletion = true
      const promises = []
      for (const id of this.selectedEntriesIds) {
        promises.push(ObjectApi.deleteBuilding(id))
      }
      Promise.all(promises)
        .then(() => {
          this.getBuildingList()
          this.$bvModal.hide('confirmationModalDeleteBuilding')
          this.loadingDeletion = false
          Vue.toasted.show(this.$t('message.successMessages.buildingDeletion'), { type: 'success' })
        })
        .catch(e => {
          this.loadingDeletion = false
          this.$bvModal.hide('confirmationModalDeleteBuilding')
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.building'), { type: 'error' })
        })
    },
    redirectToExcelReport() {
      let source = ''
      if (this.$route.fullPath.includes('view=objects')) {
        source = 'objects'
      } else if (this.$route.fullPath.includes('view=buildings')) {
        source = 'buildings'
      } else {
        source = 'properties'
      }
      localStorage.setItem('generateExcel-page', source)
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerGenerateExcel', params: { buildingIds: this.selectedEntriesIds } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminGenerateExcel', params: { buildingIds: this.selectedEntriesIds } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetGenerateExcel', params: { buildingIds: this.selectedEntriesIds } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingGenerateExcel', params: { buildingIds: this.selectedEntriesIds } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .thumbnail {
    max-width: 100px;
  }
</style>
