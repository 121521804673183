<template>
  <section>
    <div class="floating-wrapper">
      <coozzy-button
        class="floating-button"
        design="transparent"
        :disabled="!hasChanged"
        @click="saveData">
        <coozzy-save-icon />
      </coozzy-button>
    </div>
    <div
      v-if="property !== null && property !== undefined"
      class="row">
      <div
        v-if="loading"
        class="col-12 text-center">
        <coozzy-spinner class="m-3" />
      </div>
      <div
        v-else
        class="col-12">
        <h1>{{ $t('message.propertiesView.tabs.property') +': '+property.name }}</h1>
        <b-tabs
          v-model="tabIndex"
          class="mb-3">
          <b-tab
            :title="$t('message.propertiesView.tabs.property')"
            class="p-0">
            <property-tab
              ref="property-tab"
              :property-selected="property"
              @set-loading="setLoading"
              @has-change="onHasChanges" />
          </b-tab>
          <b-tab
            class="p-0"
            :title="$t('message.propertiesView.tabs.landRegister')">
            <land-register-tab
              ref="land-register-tab"
              :property="property"
              :read-only="false"
              :element-selected="elementSelected"
              :add-new-item="addNewItemLandRegister"
              @change-land-register="onHasChanges"
              @remove-land-register="saveData"
              @set-loading="setLoading"
              @selected-land-register="selectedLandRegister" />
          </b-tab>
          <b-tab
            class="p-0"
            :title="$t('message.propertiesView.tabs.buildings')">
            <buildings-tab
              :view="'editing'"
              :property-selected="property"
              @building-clicked="buildingClicked" />
          </b-tab>
          <b-tab
            class="p-0"
            :title="$t('message.onBoarding.createBuilding.device')">
            <devices-tab
              ref="devices-tab"
              :key="randomKey"
              :property="property"
              :read-only="false"
              :element-selected="elementSelected"
              :add-new-item="addNewItem"
              @change-device="onDeviceHasChanges"
              @set-loading="setLoading"
              @selected-device="selectedDevice"
              @mont-device-tab="reMountDeviceTab"
              @delete-device="onDeviceHasChanges"
              @all-devices="deviceList" />
          </b-tab>
          <b-tab
            class="p-0"
            :title="$t('message.onBoardingTabs.contacts')">
            <contacts-tab
              ref="contactsTab"
              :property="property" />
          </b-tab>
          <b-tab
            :title="$t('message.onBoardingTabs.history')">
            <template #title>
              <router-link
                class="tab_title_color"
                :to="getPropertyRoute('history')">
                <span>{{ $t('message.onBoardingTabs.history') }}</span>
                <span
                  v-if="hasNotification"
                  class="small-dot" />
              </router-link>
            </template>
            <history-tab
              ref="activitiesTab"
              :property="property"
              :device-list="arrDeviceList"
              :element-selected="elementSelected"
              :route-name="$route.name"
              @has-notification="updateHasNotification" />
          </b-tab>
          <template #tabs-end>
            <b-nav-item
              href="#"
              class="item-button">
              <coozzy-button
                design="primary"
                :disabled="!hasChanged"
                class="nav-button nav-button-center"
                @click="saveData">
                {{ $t('message.generic.form.save') }}
              </coozzy-button>
            </b-nav-item>
            <b-nav-item
              href="#"
              class="item-button">
              <coozzy-button
                design="secondary"
                class="nav-button nav-button-right"
                @click="showConfirm">
                {{ $t('message.generic.readView') }}
              </coozzy-button>
            </b-nav-item>
          </template>
        </b-tabs>
      </div>
    </div>

    <b-modal
      ref="modal-confirmation"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')">
      <div class="row mb-2">
        <div class="col">
          <p class="mb-3">
            {{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}
          </p>
          <coozzy-button
            size="normal"
            design="green"
            @click="discardChanges">
            {{ $t('message.generic.discardChanges') }}
          </coozzy-button>
          <coozzy-button
            size="normal"
            class="float-right"
            design="green"
            @click="saveData">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
          <coozzy-button
            size="normal"
            class="float-right mr-3 border"
            design="transparent"
            @click="closeModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import ObjectApi from '@/misc/apis/ObjectApi'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import BuildingsTab from './../tabs/BuildingsTab'
import LandRegisterTab from './../tabs/LandRegisterTab'
import PropertyTab from './../tabs/PropertyTab'
import DevicesTab from './../tabs/DevicesTab'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import Vue from 'vue'
import CoozzySaveIcon from '@/framework/components/icons/CoozzySaveIcon'
import { routeChecks } from '@/mixins/routeChecks'
import ContactsTab from '@/property/tabs/ContactsTab.vue'
import HistoryTab from '@/property/tabs/HistoryTab.vue'

export default {
  name: 'PropertyEditingView',
  components: {
    ContactsTab,
    CoozzySaveIcon,
    CoozzyButton,
    BuildingsTab,
    PropertyTab,
    CoozzySpinner,
    DevicesTab,
    HistoryTab,
    LandRegisterTab
  },
  mixins: [routeChecks],
  beforeRouteLeave (to, from, next) {
    if (this.goToBuilding || this.confirmedRedirection) {
      this.goToBuilding = false
      this.confirmedRedirection = false
      this.nextPath = next
    } else {
      this.$store.dispatch('onboarding/setOpenedCollapses')
      this.nextPath = next
      if (this.hasChanged) {
        this.$refs['modal-confirmation'].show()
      } else {
        next()
      }
    }
  },
  props: {
    id: {
      type: String,
      default: null
    },
    view: {
      type: String,
      default: 'property'
    },
    elementSelected: {
      type: String,
      default: ''
    },
    addNewItem: {
      type: Boolean,
      default: false
    },
    addNewItemLandRegister: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasNotification: false,
      selectedBuilding: null,
      tabIndex: 0,
      loading: false,
      property: null,
      hasChanged: false,
      checkBack: false,
      nextPath: null,
      goToBuilding: false,
      confirmedRedirection: false,
      randomKey: Math.random().toString(36).substr(2, 9),
      arrDeviceList: []
    }
  },
  watch: {
    tabIndex: function (newVal) {
      const viewName = newVal === 1 ? 'landRegister' : newVal === 0 ? 'property' : newVal === 2 ? 'buildings' : newVal === 3 ? 'devices' : newVal === 4 ? 'contacts' : 'history'
      if (this.$route && (!this.$route.query || this.$route.query.view !== viewName)) {
        this.$router.replace({
          name: this.$route.name,
          params: { id: this.id },
          query: { view: viewName }
        })
      }
    }
  },
  mounted() {
    localStorage.removeItem('toReadView')
    this.loading = true
    ObjectApi.getPropertyListByIds([this.id])
      .then(response => {
        this.loading = false
        this.property = response.properties[0]
      })
      .catch(e => {
        this.loading = false
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
      })
    this.openSelectedTabs()
  },
  methods: {
    deviceList(devices) {
      this.arrDeviceList = devices
    },
    updateHasNotification(value) {
      this.hasNotification = value
    },
    reMountDeviceTab(device) {
      this.updateElementSelected(device)
      this.randomKey = Math.random().toString(36).substr(2, 9)
    },
    selectedDevice(device) {
      this.updateElementSelected(device)
    },
    selectedLandRegister(plot) {
      this.updateElementSelected(plot)
    },
    updateElementSelected(item) {
      if (item && item.id && this.elementSelected !== item.id) {
        this.$router.replace({
          name: this.$route.name,
          params: { id: this.id },
          query: {
            view: this.$route.query.view,
            elementSelected: item.id
          }
        })
      }
    },
    onHasChanges(value) {
      this.hasChanged = value
    },
    onDeviceHasChanges(value) {
      this.hasChanged = value
    },
    buildingClicked(buildingId) {
      this.selectedBuilding = buildingId
      if (this.hasChanged) {
        this.$refs['modal-confirmation'].show()
        this.goToBuilding = true
      } else {
        this.goToBuildingEditing()
      }
    },
    closeModal() {
      this.$refs['modal-confirmation'].hide()
      this.checkBack = true
    },
    discardChanges() {
      if (this.goToBuilding) {
        this.goToBuildingEditing()
      } else if (this.nextPath !== null) {
        this.nextPath()
      } else {
        this.confirmedRedirection = true
        this.$router.go(-1)
      }
    },
    goToBuildingEditing() {
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerOnboardingView', params: { id: this.selectedBuilding }, query: { view: 'building' } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminOnboardingView', params: { id: this.selectedBuilding }, query: { view: 'building' } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingOnboardingView', params: { id: this.selectedBuilding }, query: { view: 'building' } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetOnboardingView', params: { id: this.selectedBuilding }, query: { view: 'building' } })
      }
    },
    openSelectedTabs() {
      if (this.view === 'property') {
        this.tabIndex = 0
      } else if (this.view === 'landRegister') {
        this.tabIndex = 1
      } else if (this.view === 'buildings') {
        this.tabIndex = 2
      } else if (this.view === 'devices') {
        this.tabIndex = 3
      } else if (this.view === 'contacts') {
        this.tabIndex = 4
      } else if (this.view === 'history') {
        this.tabIndex = 5
      }
    },
    async saveData() {
      let savePromise
      if (this.view === 'property') {
        savePromise = this.$refs['property-tab'].saveData()
      } else if (this.view === 'devices') {
        savePromise = this.$refs['devices-tab'].saveData()
      } else if (this.view === 'landRegister') {
        savePromise = this.$refs['land-register-tab'].saveData()
      } else {
        savePromise = Promise.all([
          this.$refs['property-tab'].saveData(),
          this.$refs['devices-tab'].saveData(),
          this.$refs['land-register-tab'].saveData()
        ])
      }
      await savePromise
      if (localStorage.getItem('toReadView')) {
        localStorage.removeItem('toReadView')
        this.redirectToReadView()
      } else if (this.nextPath !== null) {
        this.nextPath()
      }
      this.hasChanged = false
      this.loading = false
    },
    setLoading(loading) {
      this.hasChanged = false
      this.loading = loading
      this.$refs['modal-confirmation'].hide()
    },
    showConfirm() {
      if (this.hasChanged) {
        this.$refs['modal-confirmation'].show()
        localStorage.setItem('toReadView', true)
        this.checkBack = true
      } else {
        this.redirectToReadView()
      }
    },
    redirectToReadView() {
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerPropertyDetailsView', params: { id: this.id }, query: { view: this.view, elementSelected: this.elementSelected, addNewItem: this.addNewItem } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminPropertyDetailsView', params: { id: this.id }, query: { view: this.view, elementSelected: this.elementSelected, addNewItem: this.addNewItem } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetPropertyDetailsView', params: { id: this.id }, query: { view: this.view, elementSelected: this.elementSelected, addNewItem: this.addNewItem } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingPropertyDetailsView', params: { id: this.id }, query: { view: this.view, elementSelected: this.elementSelected, addNewItem: this.addNewItem } }, () => {})
      }
    },
    getPropertyRoute(view) {
      if (this.isOwnerModule) {
        return { name: 'OwnerPropertyEditingView', params: { id: this.id }, query: { view: view } }
      } else if (this.isAdminModule) {
        return { name: 'AdminPropertyEditingView', params: { id: this.id }, query: { view: view } }
      } else if (this.isAssetModule) {
        return { name: 'AssetPropertyEditingView', params: { id: this.id }, query: { view: view } }
      } else if (this.isAccountingModule) {
        return { name: 'AccountingPropertyEditingView', params: { id: this.id }, query: { view: view } }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .floating-wrapper{
    right: 5vw;
    bottom: 4vh;
    z-index: 999;
    position: fixed;
    .floating-button{
      border-radius: 100px !important;
      display: flex;
      align-items: center;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.20), 0 4px 4px rgba(0, 0, 0, 0.15);
      z-index: 2;
      justify-content: center;
      background-color: $color-primary-dark;
      padding: 20px !important;
      cursor: pointer;
      svg{
        color: white;
        font-size: 15px;
      }
    }
  }

  :deep(.collapse-title) {
    &.collapsed{
      h5{
        background-image: url('../../assets/arrow_down.svg');
      }
    }
    h5{
      background-image: url('../../assets/arrow_up.svg');
      background-repeat: no-repeat;
      font-size: large;
      font-weight: 500 !important;
      height: 1.2rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      background-position: right;
    }
  }
  :deep(.global-card) {
    margin-bottom: 0;
    & > .card-body{
      -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
      -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
      box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    }
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    &.no-top-border{
      border-top: 0;
    }
  }

</style>
