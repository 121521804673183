<template>
  <section>
    <div>
      <coozzy-page-title
        :title="$t('message.navigation.contact.title')"
        :action-text="isEditor ? $t('message.contact.newContact') : ''"
        @click="createNewContactClicked()" />
      <div
        id="contactSuggestion"
        class="row">
        <div
          class="col-12 col-md-5 mb-2">
          <ais-instant-search
            v-if="searchClient"
            :search-client="searchClient"
            :routing="routing"
            index-name="contact">
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ais-configure
              :hitsPerPage="10"
              :filters="filterQuery" />
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ais-autocomplete>
              <template slot-scope="{ indices, refine }">
                <vue-autosuggest
                  :key="refreshAutosuggestKey"
                  v-model="emailSuggestion"
                  :suggestions="indicesToSuggestions(indices)"
                  :input-props="inputProps"
                  @input="onChange(refine,emailSuggestion)"
                  @selected="onSelect">
                  <template slot-scope="{ suggestion }">
                    <strong>{{ suggestion.item.firstName }} {{ suggestion.item.lastName }}</strong>
                    <br>
                    <address-text
                      :show-short-country="true"
                      :hide-if-empty="true"
                      :address="suggestion.item.address" />
                    <template v-if="!isOwnerModule">
                      <br>
                      {{ suggestion.item.owner.name }}
                    </template>
                  </template>
                </vue-autosuggest>
              </template>
            </ais-autocomplete>
          </ais-instant-search>
        </div>
      </div>
      <div
        v-if="contact"
        class="row mt-2">
        <div class="col-12 col-md-5">
          <div class="row">
            <div class="col-12 col-md-12">
              <coozzy-detail-card
                :title="!contact.numericId ? $t('message.contact.title') : $t('message.contact.title') + ' ' + contact.numericId + ' - ' + contactOwnerName"
                :link-activity="true"
                :is-editable="!((contact.types || []).includes('CLIENT') || !isEditor)"
                @onEdit="editContactClicked(contact)"
                @goToActivities="openActivities()"
                @goToActivitiesMiddle="openActivitiesMiddle()"
                @onAccept="onApplicationFormAllInfoAccepted()">
                <div
                  v-if="loading"
                  class="p-0 text-center">
                  <coozzy-spinner class="m-3" />
                </div>
                <contact-detail-card
                  v-else
                  :ref="'contact-detail-card'"
                  :key="uniqueId"
                  :contact="contact"
                  :type="'self'"
                  @edit-address="editAddress"
                  @contact-edited="contactEdited" />
              </coozzy-detail-card>
            </div>
            <div
              v-if="countRelationsContacts !== 0"
              class="col-12 col-md-12">
              <coozzy-detail-card
                v-for="item in relationsContacts"
                :key="item.id"
                :title="$t('message.contact.title') + ' ' + item.numericId + ' - ' + getContactOwnerName(item.ownerId)"
                :is-editable="!((item.types || []).includes('CLIENT') || !isEditor)"
                :is-linked="!((item.types || []).includes('CLIENT') || !isEditor)"
                @onEdit="editContactClicked(item)"
                @onUnlink="openUnlinkModal(item)">
                <div
                  v-if="loading"
                  class="p-0 text-center">
                  <coozzy-spinner class="m-3" />
                </div>
                <contact-detail-card
                  v-else
                  :ref="'contact-detail-card'"
                  :key="uniqueId"
                  :contact="item"
                  @edit-address="editAddress"
                  @contact-edited="contactEdited" />
              </coozzy-detail-card>
            </div>
            <div
              v-if="countContactPerson !== 0 && typeOfContact === 'company' && isEditor"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.tableColumnHeadings.contactPersons') + ' (' + countContactPerson +')'">
                <div class="col p-0">
                  <b-table
                    ref="contactPersonTable"
                    bordered
                    hover
                    responsive="true"
                    stacked="md"
                    class="m-0"
                    select-mode="single"
                    :busy="loading"
                    :fields="fieldsContactPersons"
                    :items="countContactPerson <= 5 || showAllContacts ? contactPerson : contactPerson.slice(0,5)">
                    <!-- Busy state -->
                    <div
                      slot="table-busy"
                      class="text-center text-danger my-2">
                      <coozzy-spinner />
                    </div>

                    <!-- Headings -->
                    <template #head(name)>
                      {{ $t('message.generic.name') }}
                    </template>
                    <template #head(phoneNumber)>
                      {{ $t('message.generic.phoneNumber') }}
                    </template>
                    <template #head(email)>
                      {{ $t('message.generic.email') }}
                    </template>
                    <template #head(action)>
                      {{ '' }}
                    </template>

                    <!-- Columns -->
                    <template
                      #cell(name)="data">
                      <div
                        class="clickable-cell cursor-pointer"
                        :class="[isDeath(data.item.dateOfDeath) || !data.item.active ? 'text-gray' : '']"
                        @click="showPerson(data.item)">
                        <span>
                          <template v-if="data.item.gender === 'MALE'">
                            {{ $t('message.generic.title.mr') }}
                          </template>
                          <template v-if="data.item.gender === 'FEMALE'">
                            {{ $t('message.generic.title.mrs') }}
                          </template>
                          <template v-if="data.item.gender === 'OTHER'">
                            {{ $t('message.generic.title.divers') }}
                          </template>
                        </span>
                        <span
                          v-if="data.item.title && data.item.title !== ''">{{ data.item.title }} </span>
                        <div class="font-weight-bold">
                          {{ data.item.name | displayOptionalValue }}
                        </div>
                        <div v-if="data.item.employment && data.item.employment.companyPosition !== ''">
                          {{ data.item.employment.companyPosition }}
                        </div>
                        <small v-if="data.item.preferredLanguage && data.item.preferredLanguage !== 'PREFERRED_LANGUAGE_UNDEFINED'">{{ $t('message.contact.preferredLanguage') }}: {{ $t('message.contact.preferredLanguageValue.'+ data.item.preferredLanguage) }}</small>
                      </div>
                    </template>
                    <template
                      #cell(phoneNumber)="data">
                      <div
                        v-if="getPhoneNumbers(data.item.phoneNumbers, 'WORK') !== '-'"
                        class="row"
                        :class="[isDeath(data.item.dateOfDeath) || !data.item.active ? 'text-gray' : '']">
                        <div class="col-1">
                          <coozzy-phone-icon />
                        </div>
                        <div class="col">
                          <a
                            class="link"
                            :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'WORK')">
                            {{ getPhoneNumbers(data.item.phoneNumbers, 'WORK') }}
                          </a>
                        </div>
                      </div>
                      <div
                        v-if="getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') !== '-'"
                        class="row"
                        :class="[isDeath(data.item.dateOfDeath) || !data.item.active ? 'text-gray' : '']">
                        <div class="col-1">
                          <coozzy-mobile-alt-icon />
                        </div>
                        <div class="col">
                          <a
                            class="link"
                            :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'MOBILE')">
                            {{ getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') }}
                          </a>
                        </div>
                      </div>
                    </template>
                    <template
                      #cell(email)="data">
                      <div
                        class="row"
                        :class="[isDeath(data.item.dateOfDeath) || !data.item.active ? 'text-gray' : '']">
                        <div class="col-1">
                          <img
                            class="email-icon-img"
                            src="@/assets/icon/arroba.svg"
                            alt="Placeholder">
                        </div>
                        <div class="col">
                          <template v-if="data.item.email !== ''">
                            <a
                              class="link"
                              @click="sendEmailModal(data.item)">{{ data.item.email }}</a>
                          </template>
                          <template v-else>
                            {{ data.item.email | displayOptionalValue }}
                          </template>
                        </div>
                      </div>
                    </template>
                    <template
                      #cell(action)="data">
                      <coozzy-button
                        class="col-1 p-0"
                        design="transparent"
                        @click="showPerson(data.item)">
                        <coozzy-edit-icon />
                      </coozzy-button>
                    </template>
                  </b-table>
                  <div class="text-center">
                    <a
                      v-if="countContactPerson > 5"
                      class="link"
                      @click="showAllContacts = !showAllContacts">
                      <coozzy-angle-double-up-icon v-if="showAllContacts === true" />
                      <coozzy-angle-double-down-icon v-else />
                    </a>
                  </div>
                </div>
              </coozzy-detail-card>
            </div>
            <div
              v-if="countDocument !== 0 && !isJanitor"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.generic.documents') + ' (' + countDocument +')'"
                :download-all="true"
                @download-all-documents="downloadContactDocuments($event)">
                <div class="col p-0">
                  <document
                    v-for="doc in (countDocument <= 5 || showAllDocuments ? listDocuments : listDocuments.slice(0,5))"
                    :key="doc.id"
                    :document="doc"
                    class="no-lower-height"
                    :owner="getDocumentOwner(doc.id)"
                    @version-added="versionAdded"
                    @delete-document="deleteDocument" />
                  <div class="text-center">
                    <a
                      v-if="countDocument > 5"
                      class="link col"
                      @click="showAllDocuments = !showAllDocuments">
                      <coozzy-angle-double-up-icon v-if="showAllDocuments === true" />
                      <coozzy-angle-double-down-icon v-else />
                    </a>
                  </div>
                </div>
              </coozzy-detail-card>
            </div>
            <div
              v-if="listRentalDocuments.length !== 0 && !isJanitor"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.generic.rentalDocuments') + ' (' + listRentalDocuments.length +')'"
                :download-all="true"
                @download-all-documents="downloadRentalDocuments($event)">
                <div class="col p-0">
                  <document
                    v-for="doc in (listRentalDocuments.length <= 5 || showAllRentalDocuments ? listRentalDocuments : listRentalDocuments.slice(0,5))"
                    :key="doc.id"
                    :document="doc"
                    :use-label="true"
                    class="no-lower-height"
                    :is-read-only="true" />
                  <div class="text-center">
                    <a
                      v-if="listRentalDocuments.length > 5"
                      class="col"
                      @click="showAllRentalDocuments = !showAllRentalDocuments">
                      <coozzy-angle-double-up-icon v-if="showAllRentalDocuments === true" />
                      <coozzy-angle-double-down-icon v-else />
                    </a>
                  </div>
                </div>
              </coozzy-detail-card>
            </div>
            <div
              v-if="listCondominiumDocuments.length !== 0 && !isJanitor"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.generic.condominiumDocuments') + ' (' + listCondominiumDocuments.length +')'"
                :download-all="true"
                @download-all-documents="downloadCondominiumDocuments($event)">
                <div class="col p-0">
                  <document
                    v-for="doc in (listCondominiumDocuments.length <= 5 || showCondominiumDocuments ? listCondominiumDocuments : listCondominiumDocuments.slice(0,5))"
                    :key="doc.id"
                    :document="doc"
                    :use-label="true"
                    class="no-lower-height"
                    :is-read-only="true" />
                  <div class="text-center">
                    <a
                      v-if="listCondominiumDocuments.length > 5"
                      class="col"
                      @click="showCondominiumDocuments = !showCondominiumDocuments">
                      <coozzy-angle-double-up-icon v-if="showCondominiumDocuments === true" />
                      <coozzy-angle-double-down-icon v-else />
                    </a>
                  </div>
                </div>
              </coozzy-detail-card>
            </div>
          </div>
        </div>
        <div
          v-if="isEditor || isJanitor"
          class="col-12 col-md-7">
          <div class="row">
            <div
              v-if="isEditor"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.contact.actions.title')">
                <div class="col-12 col-md-12 mt-3 d-flex flex-wrap">
                  <coozzy-button
                    class="mr-3 mb-3"
                    :disabled="!contact.active"
                    @click="openSendMailModal()">
                    <img
                      class="email-icon-img mb-1"
                      src="@/assets/icon/arroba.svg"
                      alt="Placeholder">
                    <span class="ml-1">{{ $t('message.contact.actions.email') }} </span>
                    <coozzy-favorite-icon
                      v-if="contact.preferredCommunicationChannel=== 'PREFERRED_COMMUNICATION_CHANNEL_EMAIL'"
                      :statefavorite="true" />
                  </coozzy-button>

                  <coozzy-button
                    class="mr-3 mb-3"
                    :disabled="!contact.active"
                    @click="openSerialEmail()">
                    <img
                      class="letter-img mb-1"
                      src="@/assets/icon/emailLetter.svg"
                      alt="Placeholder">
                    <span class="ml-1">{{ $t('message.contract.letter') }} </span>
                    <coozzy-favorite-icon
                      v-if="contact.preferredCommunicationChannel=== 'PREFERRED_COMMUNICATION_CHANNEL_LETTER'"
                      :statefavorite="true" />
                  </coozzy-button>

                  <coozzy-button
                    class="mr-3 mb-3"
                    :disabled="!contact.active"
                    @click="createReminderClicked(null)">
                    <coozzy-reminder-icon class="mb-1" />
                    <span class="ml-1">{{ $t('message.contact.actions.reminder') }} </span>
                  </coozzy-button>

                  <coozzy-button
                    class="mr-3 mb-3"
                    :disabled="!contact.active"
                    @click="editActivity(null)">
                    <coozzy-activity-icon class="mb-1" />
                    <span class="ml-1">{{ $t('message.contact.actions.activity') }} </span>
                  </coozzy-button>

                  <coozzy-button
                    class="mr-3 mb-3"
                    :disabled="!contact.active"
                    @click="createEventClicked(null)">
                    <coozzy-calendar-check-icon class="mb-1" />
                    <span class="ml-1">{{ $t('message.contact.actions.event') }} </span>
                  </coozzy-button>

                  <coozzy-button
                    v-if="!isExternalEmployee"
                    class="mr-3 mb-3"
                    :disabled="!contact.active"
                    @click="addDocumentClicked('contactDocument')">
                    <coozzy-document-icon class="mb-1" />
                    <span class="ml-1">{{ $t('message.contact.actions.document') }} </span>
                  </coozzy-button>
                  <coozzy-button
                    v-if="!isExternalEmployee && typeOfContact === 'company'"
                    class="mr-3 mb-3"
                    :disabled="!contact.active"
                    @click="openContactModal(null)">
                    <coozzy-contact-person-icon class="mb-1" />
                    <span class="pb-1 ml-1">{{ $t('message.contact.actions.contactPerson') }} </span>
                  </coozzy-button>

                  <coozzy-button
                    v-if="!isExternalEmployee"
                    class="mr-3 mb-3"
                    :disabled="!contact.active"
                    @click="linkContact()">
                    <coozzy-contact-person-icon class="mb-1" />
                    <span class="ml-1">{{ $t('message.contact.actions.linkContact') }} </span>
                  </coozzy-button>

                  <coozzy-button
                    v-if="!isExternalEmployee"
                    class="mr-3 mb-3"
                    :disabled="!contact.active"
                    @click="addAddress()">
                    <coozzy-address-card-icon class="mb-1" />
                    <span class="ml-1">{{ $t('message.contact.actions.addAddress') }} </span>
                  </coozzy-button>
                  <coozzy-button
                    v-if="isTicketingEnabled && (isEditor || isTicketEditor)"
                    class="mr-3 mb-3"
                    :disabled="!contact.active"
                    @click="createTicket()">
                    <coozzy-ticket-icon
                      :name="'ticket'"
                      class="mb-1" />
                    <span class="ml-1">{{ $t('message.contact.actions.createTicket') }} </span>
                  </coozzy-button>
                  <coozzy-button
                    v-if="isBriefButlerEnabled"
                    class="mr-3 mb-3"
                    :disabled="!contact.active"
                    @click="$refs['brief-butler-modal'].show()">
                    <coozzy-brief-butler-icon
                      :name="'briefButler'"
                      class="mb-1 m-0" />
                    <span class="ml-1">{{ $t('message.contact.actions.briefButler') }} </span>
                  </coozzy-button>
                </div>
              </coozzy-detail-card>
            </div>
            <div
              class="col-12 col-md-12">
              <tenancies-contact-card
                v-if="(contact.types?.includes('TENANT') || contact.types?.includes('SUB_TENANT')) && !isJanitor"
                :ref="'tenancies-contact-card'"
                :key="uniqueId"
                :available-contact="availableContact"
                :contact="contact"
                @list-tenancies="loadRentalDocument" />
            </div>
            <div
              class="col-12 col-md-12">
              <tenancies-contact-card
                v-if="contact.types?.includes('GUARANTOR')"
                :ref="'tenancies-contact-card'"
                :key="uniqueId"
                :type="'GUARANTOR'"
                :available-contact="availableContact"
                :contact="contact"
                @list-tenancies="loadRentalDocument" />
            </div>
            <div
              class="col-12 col-md-12">
              <condominium-contact-card
                v-if="contact.types?.includes('CONDOMINIUM_OWNER') && !isJanitor"
                :ref="'condominiums-contact-card'"
                :key="uniqueId"
                :available-contact="availableContact"
                :contact="contact"
                @list-condominium="loadCondominiumDocuments" />
            </div>
            <div
              class="col-12 col-md-12">
              <building-list-contact-card
                v-if="(contact.types?.includes('CRAFTSMAN') || contact.types?.includes('CARETAKER')) || contact.types?.includes('SERVICE_PROVIDER') || isJanitor"
                :ref="'building-list-contact-card'"
                :key="uniqueId"
                :available-contact="availableContact"
                :contact="contact"
                @list-building="fillBuildings" />
            </div>
            <div
              v-if="countSignRequests !== 0"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.deepSign.title') + ' (' + countSignRequests + ')'">
                <div class="col p-0">
                  <b-table
                    ref="signRequestTable"
                    bordered
                    hover
                    class="m-0"
                    selectable
                    :busy="isBusySignature"
                    :fields="fieldsSignature"
                    :items="countSignRequests <= 5 || showAllSignatures ? signatureRequests : signatureRequests.slice(0,5)"
                    @row-hovered="mouseEnterEvents($event)"
                    @row-unhovered="mouseLeaveEvents($event)"
                    @row-clicked="onRowClicked">
                    <!-- Busy state -->
                    <div
                      slot="table-busy"
                      class="text-center text-danger my-2">
                      <coozzy-spinner />
                    </div>

                    <!-- Headings -->
                    <template #head(created)>
                      {{ $t('message.deepSign.created') }}
                    </template>
                    <template #head(contacts)>
                      {{ $t('message.deepSign.contacts') }}
                    </template>
                    <template #head(documentType)>
                      {{ $t('message.deepSign.documentType') }}
                    </template>
                    <template #head(signers)>
                      {{ $t('message.deepSign.signers') }}
                    </template>
                    <template #head(status)>
                      {{ $t('message.deepSign.status.title') }}
                    </template>

                    <!-- Data cells -->
                    <template
                      #cell(created)="data">
                      {{ data.item.created / 1000 | timestampToDate }}
                    </template>
                    <!-- name column -->
                    <template
                      #cell(contacts)="data">
                      <template v-for="item in data.item.signers">
                        <p
                          :key="item.contactId"
                          class="mb-0">
                          <a
                            v-if="contact.id !== item.contactId"
                            class="link contactName"
                            @click="redirectToDetailPage(item.contactId)"
                            @click.middle="redirectToDetailPage(item.contactId, true)"
                            @mousedown.middle.prevent.stop>
                            {{ getSignerName(item) }}
                          </a>
                          <span v-else>
                            {{ getSignerName(item) }}
                          </span>
                        </p>
                      </template>
                    </template>
                    <template
                      #cell(documentType)="data">
                      {{
                        data.item.documentType ? $t('message.manageDocuments.type.' + data.item.documentType.toLowerCase()) : '-'
                      }}
                    </template>
                    <!-- signers name  -->
                    <template
                      #cell(signers)="data">
                      <div
                        id="link-button"
                        class="fieldToShow d-inline"
                        @mousemove="onMouseMove($event)"
                        @mouseleave="showPopover = false">
                        {{ data.item.signers.filter(signer => signer.signed).length }}/{{ data.item.signers.length }}
                      </div>
                    </template>
                    <!-- contactPerson Phone -->
                    <template
                      #cell(status)="data">
                      {{ $t('message.deepSign.status.' + getStatus(data.item)) }}
                    </template>
                  </b-table>
                  <div class="text-center">
                    <a
                      v-if="countSignRequests > 5"
                      class="link"
                      @click="showAllSignatures = !showAllSignatures">
                      <coozzy-angle-double-up-icon v-if="showAllSignatures === true" />
                      <coozzy-angle-double-down-icon v-else />
                    </a>
                  </div>
                  <div
                    v-if="showPopover"
                    id="eventPopover"
                    class="eventPopoverSignRequest"
                    :style="{ left: page.left + 'px', top: page.top + 'px' }">
                    <div
                      v-if="selectedPopOverEvent"
                      class="row">
                      <div class="col employees-calendar">
                        <div class="row">
                          <div
                            v-for="item in selectedPopOverEvent.signers"
                            :key="item.id"
                            class="col-12">
                            {{ getSignerName(item) }}
                            <img
                              v-if="!item.signed"
                              width="15px"
                              height="15px"
                              src="@/assets/icon/hourglass.svg"
                              :alt="'hourglass'">
                            <coozzy-success-icon v-if="item.signed" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </coozzy-detail-card>
            </div>
            <div
              v-if="!isJanitor"
              class="col-12 col-md-12">
              <contact-event-card
                :ref="'contact-event-card'"
                :key="uniqueId"
                :contact-id="id"
                :source="'contact'"
                :available-attendees="availableAttendees"
                :contact="contact" />
            </div>
            <div
              class="col-12 col-md-12">
              <contact-reminder-card
                :ref="'contact-reminder-card'"
                :key="uniqueId"
                :available-contact="availableContact"
                :contact="contact" />
            </div>
            <div
              v-if="countActivity !== 0"
              class="col-12 col-md-12">
              <coozzy-detail-card
                :title="$t('message.ticketOverview.activities.title') + ' (' + countActivity + ')'">
                <div class="col p-0">
                  <b-table
                    ref="activityTable"
                    bordered
                    hover
                    responsive="true"
                    stacked="md"
                    selectable
                    select-mode="single"
                    class="m-0"
                    :busy="isBusyActivity"
                    :fields="fieldsActivity"
                    :items="countActivity <= 5 || showAllActivities ? activityList : activityList.slice(0,5)"
                    @row-clicked="editActivity">
                    <!-- Busy state -->
                    <div
                      slot="table-busy"
                      class="text-center text-danger my-2">
                      <coozzy-spinner />
                    </div>

                    <!-- Headings -->
                    <template #head(date)>
                      {{ $t('message.calendar.reminder.date') }}
                    </template>
                    <template #head(subject)>
                      {{ $t('message.calendar.reminder.subject') }}
                    </template>
                    <template #head(employee)>
                      {{ $t('message.calendar.reminder.employee') }}
                    </template>

                    <!-- Columns -->
                    <template
                      #cell(date)="data">
                      {{ data.item.timestampStart | timestampToDateTime }}
                    </template>
                    <template
                      #cell(subject)="data">
                      <p class="m-0 auto-size">
                        <coozzy-paper-clip-icon v-if="data.item.mediaIds?.length > 0" />
                        {{ data.item.subject | displayOptionalValue }}
                      </p>
                    </template>
                    <template
                      #cell(employee)="data">
                      <template v-for="(employeeContactId, index) in data.item.employeeContactIds?.filter(x => getEmployeeById(x) !== '-')">
                        {{ index !== 0 ? ',': '' }}
                        {{ getEmployeeById(employeeContactId).name | displayOptionalValue }}
                      </template>
                    </template>
                  </b-table>
                  <div class="text-center">
                    <a
                      v-if="countActivity > 5"
                      class="link"
                      @click="showAllActivities = !showAllActivities">
                      <coozzy-angle-double-up-icon v-if="showAllActivities === true" />
                      <coozzy-angle-double-down-icon v-else />
                    </a>
                  </div>
                </div>
              </coozzy-detail-card>
            </div>
            <small-messages-table
              v-if="isEditor && (mailList.length > 0 || filterFolder !== 'FILTER_FOLDER_ALL' || hasEmails)"
              :mail-list="mailList"
              :total-page-mail-count="totalPageMailCount"
              :total-mails-count="totalMailsCount"
              :has-emails="hasEmails"
              :contact="contact"
              @show-email="showEmail"
              @search-emails="searchEmails" />
          </div>
        </div>
      </div>
    </div>
    <create-contact-modal
      :key="contactToEdit ? contactToEdit.id : null"
      :for-owner-id="isOwnerModule ? accountId : null"
      :show-search="false"
      :show-request-sources="false"
      :set-interested-party-role="false"
      :show-add-another="true"
      :contact-to-edit="contactToEdit"
      :enable-delete="contactToEdit !== null"
      :list-buildings="lisfOfBuildings"
      @contact-created="contactCreated"
      @contact-updated="contactUpdated"
      @modal-hide="modalHide"
      @contact-deleted="contactDeleted" />
    <create-activity-modal
      v-if="contact && showModalActivity"
      ref="create-activity"
      :key="'create-activity' + keyActivity + (selectedActivity ? selectedActivity.id : '')"
      :activity-detail="selectedActivity"
      :contact="contact"
      :contact-persons="activatedContactPerson"
      :ids="referencesContactIds"
      @activity-created="loadActivities"
      @activity-updated="loadActivities" />
    <message-details-modal
      v-if="selectedEmail"
      ref="detail-mail"
      :key="'detail-mail-' + selectedEmail.id"
      :email="selectedEmail"
      @reply-mail="replyMail"
      @reply-all-mail="replyAllMail"
      @forward-mail="forwardMail"
      @delete-mail="reloadSmallMessagesData"
      @archive-mail="reloadSmallMessagesData"
      @restore-mail="reloadSmallMessagesData"
      @forward-as-attachement="forwardAsAttachement" />
    <add-document-modal
      v-if="contact !== null && contact !== undefined && contact && contact.ownerId"
      :suffix="'contactDocument'"
      :owner-id="contact ? contact.ownerId : 0"
      :contact="contact"
      :contact-persons="typeOfContact === 'person' ? relationsContacts : []"
      @document-created="documentCreated" />
    <contact-person-creation
      ref="contact-person-modal"
      :key="selectedPerson !== null ? 'modal-person'+selectedPerson.id : 'modal-person'"
      :company-contact="contact"
      :contact-type="typeOfContact"
      :selected-contact="selectedPerson"
      @new-contact-person="contactPersonCreated"
      @edit-contact-person="contactPersonEdited"
      @delete-contact-person="contactPersonDeleted" />
    <add-address-modal
      :contact="contact"
      :contact-persons="typeOfContact === 'person' ? relationsContacts : contact ? contact.contactPersons : []"
      :item-to-edit="itemToEdit"
      @address-added="addressAdded"
      @clear-modal="clearModal" />
    <b-modal
      :id="'confirmationModalLinkContact'"
      ref="confirmationModalLinkContact"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.contact.linkContact.title')">
      <div class="col">
        <p>{{ $t('message.contact.linkContact.unlink') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('confirmationModalLinkContact')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="disabledLinkBtn"
          @click="unlinkPersons()">
          {{ $t('message.generic.confirm') }}
        </coozzy-button>
      </div>
    </b-modal>
    <digital-signature-modal
      v-if="showDigitalSignature"
      ref="digitalSignatureModal"
      :contacts="contactsList"
      :read-only="readOnlyDigitSign"
      :tenancy="tenancySignRequest"
      :signature-request="selectedSignRequest"
      @cancel-signature="signatureRequestCanceled"
      @hide="hideDigitalSignatureModal" />
  </section>
</template>

<script>
import ContactApi from '../../misc/apis/ContactApi'
import MediaApi from '../../misc/apis/MediaApi'
import MessageApi from '../../misc/apis/MessageApi'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import Document from '@/properties/components/Document'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import ContactEventCard from './ContactEventCard'
import ContactReminderCard from './ContactReminderCard'
import TenanciesContactCard from '@/contact/components/TenanciesContactCard'
import BuildingListContactCard from '@/contact/components/BuildingListContactCard'
import CondominiumContactCard from '@/contact/components/CondominiumContactCard'
import ContactDetailCard from './ContactDetailCard'
import ContactPersonCreation from '@/contact/components/ContactPersonCreation'
import SmallMessagesTable from '@/messages/SmallMessagesTable'
import MessageDetailsModal from './MessageDetailsModal'
import CreateActivityModal from './CreateActivityModal'
import CoozzyDetailCard from '@/framework/components/card/CoozzyDetailCard'
import CoozzyPhoneIcon from '../../framework/components/icons/CoozzyPhoneIcon'
import CoozzyPaperClipIcon from '../../framework/components/icons/CoozzyPaperClipIcon'
import CoozzyMobileAltIcon from '../../framework/components/icons/CoozzyMobileAltIcon'
import CoozzyActivityIcon from '../../framework/components/icons/CoozzyActivityIcon'
import CoozzyContactPersonIcon from '../../framework/components/icons/CoozzyContactPersonIcon'
import CoozzyAddressCardIcon from '../../framework/components/icons/CoozzyAddressCardIcon'
import CoozzyCalendarCheckIcon from '../../framework/components/icons/CoozzyCalendarCheckIcon'
import CoozzyDocumentIcon from '../../framework/components/icons/CoozzyDocumentIcon'
import CoozzyReminderIcon from '../../framework/components/icons/CoozzyReminderIcon'
import CoozzyAngleDoubleUpIcon from '../../framework/components/icons/CoozzyAngleDoubleUpIcon'
import CoozzyAngleDoubleDownIcon from '../../framework/components/icons/CoozzyAngleDoubleDownIcon'
import Vue from 'vue'
import CoozzyPageTitle from '../../framework/layout/CoozzyPageTitle'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { formatting } from '@/mixins/formatting'
import { user } from '@/mixins/user'
import { contact } from '@/mixins/contact'
import { mail } from '@/mixins/mail'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import AddAddressModal from '@/contact/components/AddAddressModal'
import { algolia } from '@/mixins/algolia'
import { VueAutosuggest } from 'vue-autosuggest'
import AddressText from '@/framework/components/misc/AddressText'
import { routeChecks } from '@/mixins/routeChecks'
import CreateContactModal from '@/contact/components/CreateContactModal'
import UserApi from '@/misc/apis/UserApi'
import CoozzyFavoriteIcon from '@/framework/components/icons/CoozzyFavoriteIcon'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon.vue'
import { responsivity } from '@/mixins/responsivity'
import DeepSignApi from '@/misc/apis/DeepSignApi'
import DigitalSignatureModal from '@/user/DigitalSignatureModal.vue'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'
import CoozzyTicketIcon from '@/framework/components/icons/CoozzyTicketIcon.vue'
import CoozzyBriefButlerIcon from '@/framework/components/icons/CoozzyBriefButlerIcon.vue'
import { media } from '@/mixins/media'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'ContactDetailView',
  components: {
    CoozzyBriefButlerIcon,
    CoozzyTicketIcon,
    CoozzyEditIcon,
    DigitalSignatureModal,
    CoozzySuccessIcon,
    CreateContactModal,
    CoozzyButton,
    SmallMessagesTable,
    ContactDetailCard,
    ContactEventCard,
    ContactReminderCard,
    ContactPersonCreation,
    CoozzyPageTitle,
    CreateActivityModal,
    MessageDetailsModal,
    AddDocumentModal,
    Document,
    CoozzyDetailCard,
    CoozzyMobileAltIcon,
    CoozzyCalendarCheckIcon,
    CoozzyReminderIcon,
    CoozzyPaperClipIcon,
    CoozzyDocumentIcon,
    CoozzyPhoneIcon,
    CoozzyActivityIcon,
    CoozzyContactPersonIcon,
    CoozzySpinner,
    CoozzyAngleDoubleUpIcon,
    CoozzyAngleDoubleDownIcon,
    CoozzyAddressCardIcon,
    AddAddressModal,
    VueAutosuggest,
    AddressText,
    CoozzyFavoriteIcon,
    TenanciesContactCard,
    BuildingListContactCard,
    CondominiumContactCard
  },
  mixins: [user, formatting, contact, algolia, routeChecks, mail, responsivity, media],
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  metaInfo() {
    return {
      title: `${this.$t('message.navigation.contact.title')} ${this.contactName}`
    }
  },
  data() {
    return {
      listbuilding: [],
      refreshAutosuggestKey: '',
      loading: false,
      keyActivity: '',
      contact: {},
      contactToEdit: null,
      emailSuggestion: '',
      debounceTimeout: null,
      referenceIds: [],
      isBusyActivity: false,
      employeeList: [],
      reminderList: [],
      activityList: [],
      activityEmployeeList: [],
      mailList: [],
      filterFolder: 'FILTER_FOLDER_ALL',
      totalPageMailCount: 1,
      totalMailsCount: 0,
      contactOwnerAccount: null,
      contactRelationOwnerAccount: [],
      showAllContacts: false,
      showAllActivities: false,
      showAllDocuments: false,
      showAllRentalDocuments: false,
      showCondominiumDocuments: false,
      contactDocuments: [],
      rentalDocuments: [],
      condominiumDocs: [],
      relationsContacts: [],
      selectedPerson: null,
      selectedEmail: null,
      emailToReply: null,
      selectedActivity: null,
      typeOfContact: '',
      elementChanged: null,
      uniqueId: '',
      disabledLinkBtn: false,
      emailToContact: null,
      listTenancies: [],
      listCondoOwners: [],
      fieldsActivity: [
        {
          key: 'date',
          label: this.$t('message.calendar.reminder.date'),
          tdClass: 'align-middle'
        },
        {
          key: 'subject',
          label: this.$t('message.calendar.reminder.subject'),
          tdClass: 'align-middle max-w-subject'
        },
        {
          key: 'employee',
          label: this.$t('message.calendar.reminder.employee'),
          tdClass: 'align-middle'
        }
      ],
      fieldsContactPersons: [
        {
          key: 'name',
          label: this.$t('message.generic.name'),
          tdClass: 'align-middle p-md-0'
        },
        {
          key: 'phoneNumber',
          label: this.$t('message.generic.phoneNumber'),
          tdClass: 'align-middle phone-cell'
        },
        {
          key: 'email',
          label: this.$t('message.generic.email'),
          tdClass: 'align-middle'
        },
        {
          key: 'action',
          label: '',
          tdClass: 'align-middle align-icon'
        }
      ],
      itemToEdit: null,
      hasEmails: false,
      showModalActivity: false,
      isBusySignature: false,
      showAllSignatures: false,
      fieldsSignature: [
        {
          key: 'created',
          label: this.$t('message.deepSign.created'),
          thClass: 'th-active',
          tdClass: 'align-middle border-right-0'
        },
        {
          key: 'contacts',
          label: this.$t('message.deepSign.contacts'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'documentType',
          label: this.$t('message.deepSign.documentType'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'Signers',
          label: this.$t('message.deepSign.Signers'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'status',
          label: this.$t('message.deepSign.status.title'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      signatureRequests: [],
      showPopover: false,
      selectedPopOverEvent: null,
      page: {
        left: 0,
        top: 0
      },
      contactsList: [],
      selectedSignRequest: null,
      tenancySignRequest: null,
      readOnlyDigitSign: false,
      showDigitalSignature: false
    }
  },
  computed: {
    ...mapGetters('message', ['getSentMails', 'getMailboxesForUserId']),
    lisfOfBuildings() {
      return this.listbuilding
    },
    countSignRequests() {
      return this.signatureRequests.length
    },
    filterQuery() {
      return 'isOwner:false AND active:true AND isEmployee:false AND isOnlyInterestedParty:false'
    },
    contactOwnerName() {
      const acc = this.contactOwnerAccount ? this.contactOwnerAccount : null

      if (acc) {
        if (acc.company) {
          return acc.company.name
        } else if (acc.individual) {
          return acc.individual.firstName + ' ' + acc.individual.lastName
        }
      }

      return ''
    },
    contactName() {
      return this.contact && this.contact.name ? this.contact.name : ''
    },
    contactPerson() {
      if (this.contact && this.countContactPerson !== 0 && this.typeOfContact === 'company') {
        const cp = this.contact.contactPersons
        return cp.sort(function(a, b) { return b.active - a.active })
      }
      return []
    },
    activatedContactPerson() {
      if (this.typeOfContact === 'company') {
        return this.contact.contactPersons.filter(x => x.active)
      } else {
        return this.relationsContacts.filter(x => x.active)
      }
    },
    contactPersonsList() {
      if (this.typeOfContact === 'company') {
        return this.contact.contactPersons
      } else {
        return this.relationsContacts
      }
    },
    availableEmployees() {
      const array = []
      if (this.employeeList.length !== 0) {
        this.employeeList.forEach((emp) => {
          if (typeof (emp) !== 'undefined') {
            array.push({
              label: (emp.profile.firstName ? emp.profile.firstName.substr(0, 1) + '. ' : '') + emp.profile.lastName + (emp.deleted ? ` (${this.$t('message.generic.deleted')})` : ''),
              value: emp.id
            })
          }
        })
      }
      return array
    },
    referencesContactIds() {
      let arr = []
      if (this.contact?.id) {
        arr = [this.contact.id]
        if (this.typeOfContact === 'person') {
          return arr.concat(this.relationsContacts.map(d => d.id))
        }
      }
      return arr
    },
    availableAttendees() {
      const array = []
      if (this.contact !== null) {
        const cont = {
          firstName: this.contact.firstName,
          lastName: this.contact.lastName,
          name: this.contact.name,
          email: this.contact.email
        }
        array.push(cont)
        if (this.contact.contactPersons !== undefined && this.contact.contactPersons.length > 0) {
          this.contact.contactPersons.filter(x => x.active).forEach(element => {
            const cont = {
              firstName: element.firstName,
              lastName: element.lastName,
              name: element.name,
              email: element.email
            }
            array.push(cont)
          })
        }
        if (this.employeeList.length !== 0) {
          this.employeeList.forEach(element => {
            const cont = {
              firstName: element.profile.firstName,
              lastName: element.profile.lastName,
              email: element.email
            }
            array.push(cont)
          })
        }
      }
      return array
    },
    availableContact() {
      let result = [this.contact].concat(this.relationsContacts)

      if (this.contact.contactPersons && this.contact.contactPersons.length > 0) {
        result = result.concat(this.contact.contactPersons)
      }

      return result.filter(x => x.active)
    },
    countActivity() {
      return this.activityList.length
    },
    countRelationsContacts() {
      return this.relationsContacts.length
    },
    countContactPerson() {
      if (this.contact !== null && this.contact.contactPersons) {
        return this.contact.contactPersons.length
      }
      return 0
    },
    countDocument() {
      let count = 0
      if (this.contactDocuments) {
        count = this.contactDocuments.length
      }
      return count
    },
    isExternalEmployee() {
      return this.contact && parseInt(this.contact.ownerId) !== parseInt(this.accountId) && (this.contact.types || []).includes('EMPLOYEE')
    },
    listDocuments() {
      const docs = this.contactDocuments
      return docs.sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    listRentalDocuments() {
      const docs = this.rentalDocuments
      return docs.sort((a, b) => b.contractNumber - a.contractNumber)
    },
    listCondominiumDocuments() {
      const docs = this.condominiumDocs
      return docs.sort((a, b) => b.contractNumber - a.contractNumber)
    }
  },
  watch: {
    id: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        // clear contact data when pushing to the same route with different contact id
        this.clearContactData()
        this.loadContact()
      }
    },
    getSentMails: function (newVal) {
      if (newVal.length > 0) {
        this.reloadSmallMessagesData()
      }
    },
    elementChanged: function () {
      this.loadContact()
    }
  },
  mounted: function () {
    document.body.onmousedown = function(e) {
      if (e.button === 1) {
        e.preventDefault()
        return false
      }
    }
    this.loadInstantSearch()
    this.loadContact()
    this.loadMailboxesForUserId(this.userId)
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailHasContext',
      'setSubject',
      'setEmailToForwardAsAttachment',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply'
    ]),
    ...mapActions('message', ['loadMailboxesForUserId']),
    fillBuildings(buildings) {
      this.listbuilding = buildings
    },
    downloadContactDocuments() {
      const idsAndNames = this.listDocuments.map(x => ({ id: x.versions[x.versions.length - 1].mediaId, mediumName: x.name }))
      const nameOfMergedFile = this.contact.name + (this.countRelationsContacts > 0 ? (', ' + this.relationsContacts.map(x => x.name).join(', ')) : '')
      this.mergeDownloadAllDocuments(idsAndNames, nameOfMergedFile)
    },
    downloadRentalDocuments() {
      const idsAndNames = this.listRentalDocuments.map(x => ({ id: x.versions[x.versions.length - 1].mediaId, mediumName: x.name }))
      const nameOfMergedFile = this.contact.name + (this.countRelationsContacts > 0 ? (', ' + this.relationsContacts.map(x => x.name).join(', ')) : '')
      this.mergeDownloadAllDocuments(idsAndNames, nameOfMergedFile)
    },
    downloadCondominiumDocuments() {
      const idsAndNames = this.listCondominiumDocuments.map(x => ({ id: x.versions[x.versions.length - 1].mediaId, mediumName: x.name }))
      const nameOfMergedFile = this.contact.name + (this.countRelationsContacts > 0 ? (', ' + this.relationsContacts.map(x => x.name).join(', ')) : '')
      this.mergeDownloadAllDocuments(idsAndNames, nameOfMergedFile)
    },
    onRowClicked(item, index, event) {
      if (event.srcElement.classList.contains('contactName') || event.srcElement.classList.contains('contactName') || event.srcElement.classList.contains('contractNumber') || event.srcElement.classList.contains('contractNumber')) {
        event.preventDefault()
      } else {
        this.selectedSignRequest = item
        this.readOnlyDigitSign = true
        const tenancyId = item.attachToReferenceIds.find((ref) => ref.startsWith('tenancy_')) || null
        if (tenancyId) {
          this.tenancySignRequest = this.listTenancies.find(x => x.id === tenancyId)
        }
        this.showDigitalSignatureModal()
      }
    },
    showDigitalSignatureModal() {
      this.showDigitalSignature = true
      this.readOnlyDigitSign = true
      this.$nextTick(() => {
        this.$refs.digitalSignatureModal?.show()
      })
    },
    signatureRequestCanceled(id) {
      this.signatureRequests.map((item) => {
        if (item.id === id) {
          item.cancelled = true
        }
        return true
      })
    },
    hideDigitalSignatureModal() {
      this.showDigitalSignature = false
      this.readOnlyDigitSign = false
      this.selectedSignRequest = null
      this.tenancySignRequest = null
    },
    redirectToDetailPage(contactId, newOnglet = false) {
      let name = ''
      if (this.isOwnerModule) {
        name = 'OwnerContactDetailsView'
      } else if (this.isAssetModule) {
        name = 'AssetContactDetailsView'
      } else if (this.isAdminModule) {
        name = 'AdminContactDetailsView'
      } else if (this.isAccountingModule) {
        name = 'AccountingContactDetailsView'
      } else {
        name = 'MarketingContactDetailsView'
      }
      if (newOnglet) {
        const routerHref = this.$router.resolve({
          name: name,
          params: { id: contactId }
        }, () => {
        })
        window.open(routerHref.href, '_blank')
      } else {
        this.$router.push({
          name: name,
          params: { id: contactId }
        }, () => {
        })
      }
    },
    getSignerName(signer) {
      const contact = this.contactsList.find(c => c.id === signer.contactId)
      if (contact) {
        return contact.name
      }
      return ''
    },
    onMouseMove(e) {
      this.showPopover = this.hasSomeParentTheClass(e.srcElement, 'fieldToShow')
      if (this.showPopover && document.getElementById('eventPopover')) {
        this.page.left = e.layerX
        this.page.top = e.layerY
      }
    },

    mouseLeaveEvents() {
      this.selectedPopOverEvent = null
    },
    mouseEnterEvents(event) {
      this.selectedPopOverEvent = event
    },
    getStatus(item) {
      if (item.cancelled) {
        return 'cancelled'
      } else if (item.signers.every((x) => x.signed) || item.completed) {
        return 'complete'
      } else if (item.signers.some(signer => !signer.signed)) {
        return 'incomplete'
      }
    },
    loadSignatureRequest() {
      this.isBusySignature = true
      const refIds = [this.contact.id]
      if (this.listTenancies?.length > 0) {
        refIds.push(...this.listTenancies.map(t => t.id))
      }
      DeepSignApi.listSignRequestsByAttachToReferenceIds(refIds).then((response) => {
        this.signatureRequests = response.signRequests
        this.isBusySignature = false
        if (response.signRequests.length > 0) {
          let contactIds = []
          response.signRequests.forEach((signRequest) => {
            if (signRequest.completed === false && signRequest.cancelled === false) {
              this.hasSignatureRequestIncomplete = true
            }
            contactIds = contactIds.concat(signRequest.signers.map((signer) => {
              return signer.contactId
            }))
          })
          contactIds = [...new Set(contactIds)]
          contactIds = contactIds.filter((id) => {
            return !this.contactsList.some((contact) => {
              return contact.id === id
            })
          })
          ContactApi.contactResolve(contactIds)
            .then(responseCon => {
              this.contactsList = this.contactsList.concat(responseCon.persons.concat(responseCon.companies))
            })
            .catch(e => {
              console.error(e)
            })
        }
      }).catch((e) => {
        console.log(e)
        this.isBusySignature = false
      })
    },
    onApplicationFormAllInfoAccepted() {
      this.$refs['contact-detail-card']?.onApplicationFormAllInfoAccepted()
    },
    searchEmails(filterFolder, mailBoxId, more, query) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.debounceTimeout = null
        this.loadMails(filterFolder, mailBoxId, more, query)
      }, 300)
    },
    editAddress(item, user) {
      this.itemToEdit = item
      this.itemToEdit.userId = user?.id || null
      this.$bvModal.show('add-address-modal')
    },
    contactEdited() {
      this.loadContact()
    },
    clearModal() {
      this.itemToEdit = null
    },
    reloadSmallMessagesData() {
      this.loadMails()
    },
    createTicket() {
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation', query: { fromBulk: true, ids: this.id, defaultAssignee: this.contact.ownerId } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation', query: { fromBulk: true, ids: this.id, defaultAssignee: this.contact.ownerId } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation', query: { fromBulk: true, ids: this.id, defaultAssignee: this.contact.ownerId } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketCreation', query: { fromBulk: true, ids: this.id, defaultAssignee: this.contact.ownerId } })
      } else {
        this.$router.push({ name: 'MarketingTicketCreation', query: { fromBulk: true, ids: this.id, defaultAssignee: this.contact.ownerId } })
      }
    },
    getContactOwnerName(id) {
      const acc = this.contactRelationOwnerAccount.find(x => x.id === id)
      if (acc) {
        if (acc.company) {
          return acc.company.name
        } else if (acc.individual) {
          return acc.individual.firstName + ' ' + acc.individual.lastName
        }
      }
      return ''
    },
    loadContact() {
      this.loading = true
      this.isBusyActivity = true
      this.referenceIds = [this.id]
      ContactApi.contactResolve([this.id])
        .then(response => {
          this.contact = response.persons.concat(response.companies).find(x => x.id === this.id || ('contactPersons' in x && x.contactPersons.some(cp => cp.id === this.id)))
          if (!('contactPersons' in this.contact)) {
            this.typeOfContact = 'person'
          } else if (('contactPersons' in this.contact)) {
            this.typeOfContact = 'company'
            this.referenceIds = this.referenceIds.concat(this.contact.contactPersons.map(d => d.id))
          } else {
            this.$router.go(-1)
            Vue.toasted.show(this.$t('message.loadingErrors.notAllowedContacts'), { type: 'error' })
          }
          if (this.contact.relations.length > 0) {
            this.relationsContacts = response.persons.concat(response.companies).filter(c => c.id !== this.contact.id)
            this.referenceIds = this.referenceIds.concat(this.relationsContacts.map(d => d.id))
          }

          this.changeUniqueId()
          this.loadDocuments()
          this.loadMails()
          this.loadActivities()
          this.loadOwner()
          this.loadRelationContactOwner()
          if (!((this.contact.types?.includes('TENANT') || this.contact.types?.includes('SUB_TENANT')) && !this.contact.types?.includes('CONDOMINIUM_OWNER'))) {
            this.loadSignatureRequest()
          }
        })
        .catch(e => {
          console.log(e)
          this.isBusyActivity = false
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadDocuments() {
      let documentIds = this.contact.documentIds
      for (const relationsContact of this.relationsContacts.filter(x => x.active)) {
        documentIds = documentIds.concat(relationsContact.documentIds)
      }

      const uniqueDocumentIds = [...new Set(documentIds)]
      if (uniqueDocumentIds.length > 0) {
        MediaApi.listDocuments(uniqueDocumentIds)
          .then(response => {
            this.contactDocuments = response.documents.reverse()
          })
      }
    },
    loadOwner() {
      // Load the accounts to display owner names
      UserApi.getAccount(this.contact.ownerId).then(response => {
        this.contactOwnerAccount = response.account
      })
    },
    loadRelationContactOwner() {
      // Load the accounts to display owner names
      const relationContactOwnerIds = this.relationsContacts.flatMap(x => x.ownerId)
      if (relationContactOwnerIds.length > 0) {
        UserApi.listAccountsByIds(relationContactOwnerIds).then(response => {
          this.contactRelationOwnerAccount = response.accounts
        })
      }
    },
    getDocumentOwner(documentId) {
      const ownerName = []
      if (this.relationsContacts.length > 0) {
        if (this.contact.documentIds.includes(documentId)) {
          ownerName.push(this.contact.name)
        }
        const owners = this.relationsContacts.filter(obj => obj.documentIds.includes(documentId))
        owners.forEach(element => {
          ownerName.push(element.name)
        })
        return ownerName
      } else {
        return []
      }
    },
    loadMails(filterFolder = 'FILTER_FOLDER_ALL', mailboxIds = this.mailboxesForCurrentUser.map(mailbox => mailbox.id), more = false, query = '') {
      if (mailboxIds.length > 0) {
        MessageApi.listMailsByReferenceIds(this.referenceIds, filterFolder, mailboxIds, 1, more ? this.totalMailsCount : 5, query)
          .then(async response => {
            this.mailList = response.mails
            this.filterFolder = filterFolder
            this.totalPageMailCount = response.totalPageCount
            this.totalMailsCount = response.totalMailsCount
            if (response.mails.length > 0) {
              this.hasEmails = true
            }
          }).catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.message'), { type: 'error' })
          })
      }
    },
    loadActivities() {
      ContactApi.getActivitiesByReferenceIdsV3(this.referenceIds)
        .then(async response => {
          this.activityList = response.activities
          this.activityList.sort(function (a, b) {
            return b.timestampStart - a.timestampStart
          })
          const employeeIds = this.activityList.flatMap(d => d.employeeContactIds)
          if (employeeIds.length !== 0) {
            const ids = [...new Set(employeeIds)]
            ContactApi.contactResolve(ids)
              .then(response => {
                this.activityEmployeeList = response
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
              })
          }
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.activity'), { type: 'error' })
        })
        .finally(() => {
          this.isBusyActivity = false
          this.showModalActivity = false
        })
    },
    isDeath(date) {
      return !!(date && new Date().getTime() >= new Date(date.year + '-' + date.month + '-' + date.day).getTime())
    },
    addDocumentClicked(suffix = '') {
      this.$bvModal.show('add-document-modal' + suffix)
    },
    contactCreated(data) {
      if (data.addingAnother !== true && data.takeOverAddress !== true && data.contact) {
        this.redirectToDetailPage(data.contact.id)
      }
    },
    contactUpdated(data) {
      if (data.contact && this.contact.id === data.contact.id) {
        this.contact = data.contact
      } else {
        this.relationsContacts.forEach((value, index) => {
          if (value.id === data.contact.id) {
            Vue.set(this.relationsContacts, index, data.contact)
          }
        })
      }
    },
    contactDeleted() {
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerContactOverview' })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminContactOverview' })
      } else if (this.isMarketingModule) {
        this.$router.push({ name: 'MarketingContactOverview' })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetContactOverview' })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingContactOverview' })
      }
    },
    addAddress() {
      this.itemToEdit = null
      this.$bvModal.show('add-address-modal')
    },
    addressAdded() {
      this.itemToEdit = null
      this.loadContact()
    },
    documentCreated(document, suffix, contacts) {
      const contactsIds = contacts.map(x => x.id)
      const promises = []
      contacts.forEach(contact => {
        promises.push(ContactApi.addDocuments(contact.id, [document.id]))
      })

      Promise.all(promises)
        .then(() => {
          if (contacts.filter(x => x.id === this.contact.id).length > 0) {
            this.contact.documentIds.push(document.id)
          }
          this.contactDocuments.unshift(document)
          const relationsContacts = this.relationsContacts.find(obj => contactsIds.includes(obj.id))
          if (relationsContacts) {
            relationsContacts.documentIds.push(document.id)
          }
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.document'), { type: 'error' })
        })
    },
    versionAdded(updatedDocument) {
      this.contactDocuments.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.contactDocuments.splice(index, 1, updatedDocument)
        }
      }, this)
    },
    openActivities() {
      const othersIds = (this.contactPersonsList.concat(this.listTenancies)).map(x => x.id)
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerActivitiesTicketView', params: { id: this.contact?.id }, query: { view: 'tickets', othersIds: othersIds } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminActivitiesTicketView', params: { id: this.contact?.id }, query: { view: 'tickets', othersIds: othersIds } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetActivitiesTicketView', params: { id: this.contact?.id }, query: { view: 'tickets', othersIds: othersIds } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingActivitiesTicketView', params: { id: this.contact?.id }, query: { view: 'tickets', othersIds: othersIds } }, () => {})
      } else {
        this.$router.push({ name: 'MarketingActivitiesTicketView', params: { id: this.contact.id }, query: { view: 'tickets', othersIds: othersIds } }, () => {})
      }
    },
    openActivitiesMiddle() {
      let routeData = ''
      const othersIds = (this.contactPersonsList.concat(this.listTenancies)).map(x => x.id)
      if (this.isOwnerModule) {
        routeData = this.$router.resolve({ name: 'OwnerActivitiesTicketView', params: { id: this.contact?.id }, query: { view: 'tickets', othersIds: othersIds } }, () => {})
      } else if (this.isAdminModule) {
        routeData = this.$router.resolve({ name: 'AdminActivitiesTicketView', params: { id: this.contact?.id }, query: { view: 'tickets', othersIds: othersIds } }, () => {})
      } else if (this.isAssetModule) {
        routeData = this.$router.resolve({ name: 'AssetActivitiesTicketView', params: { id: this.contact?.id }, query: { view: 'tickets', othersIds: othersIds } }, () => {})
      } else if (this.isAccountingModule) {
        routeData = this.$router.resolve({ name: 'AccountingActivitiesTicketView', params: { id: this.contact?.id }, query: { view: 'tickets', othersIds: othersIds } }, () => {})
      } else {
        routeData = this.$router.resolve({ name: 'MarketingActivitiesTicketView', params: { id: this.contact.id }, query: { view: 'tickets', othersIds: othersIds } }, () => {})
      }
      window.open(routeData.href, '_blank')
    },
    deleteDocument(document) {
      let contactId = null
      if (this.relationsContacts.length > 0) {
        if (this.contact.documentIds.includes(document.id)) {
          contactId = this.contact.id
        }
        const owner = this.relationsContacts.find(obj => obj.documentIds.includes(document.id))
        if (owner && !contactId) {
          contactId = owner.id
        }
      } else {
        contactId = this.contact.id
      }
      ContactApi.deleteDocuments(contactId, document.id)
        .then(() => {
          this.contact.documentIds.forEach((doc, index) => {
            if (doc === document.id) {
              this.contact.documentIds.splice(index, 1)
            }
          }, this)
          this.contactDocuments.forEach((doc, index) => {
            if (doc.id === document.id) {
              this.contactDocuments.splice(index, 1)
            }
          }, this)
        }).catch(e => {
          Vue.toasted.show(this.$t('message.savingErrors.document'), { type: 'error' })
          console.log(e)
        })
    },
    getPhoneNumbers(phoneNumbers, type) {
      if (phoneNumbers && phoneNumbers.length > 0) {
        if (phoneNumbers.find(element => element.type === type)) {
          return phoneNumbers.find(element => element.type === type).number
        }
      }
      return '-'
    },
    createReminderClicked() {
      this.$refs['contact-reminder-card'].createReminderClicked()
    },
    createEventClicked() {
      if (this.$route.name === 'MarketingContactDetailsView') {
        this.$router.push({ name: 'MarketingCalendarOverview', params: { viewCalendar: 'week' }, query: { referenceIds: this.referenceIds.toString(), fromPage: 'contact' } })
      } else if (this.$route.name === 'AdminContactDetailsView') {
        this.$router.push({ name: 'AdminCalendarOverview', params: { viewCalendar: 'week' }, query: { referenceIds: this.referenceIds.toString(), fromPage: 'contact' } })
      } else if (this.$route.name === 'AssetContactDetailsView') {
        this.$router.push({ name: 'AssetCalendarOverview', params: { viewCalendar: 'week' }, query: { referenceIds: this.referenceIds.toString(), fromPage: 'contact' } })
      } else if (this.$route.name === 'AccountingContactDetailsView') {
        this.$router.push({ name: 'AccountingCalendarOverview', params: { viewCalendar: 'week' }, query: { referenceIds: this.referenceIds.toString(), fromPage: 'contact' } })
      } else {
        this.$router.push({ name: 'OwnerCalendarOverview', params: { viewCalendar: 'week' }, query: { referenceIds: this.referenceIds.toString(), fromPage: 'contact' } })
      }
    },
    openSerialEmail() {
      const uniqKey = uuidv4()
      localStorage.setItem(uniqKey, [this.contact.id])
      this.$nextTick(() => {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerSerialLetterProcessView',
            query: {
              source: 'contact',
              uuid: uniqKey,
              previousRoute: this.$route.fullPath
            }
          })
        } else if (this.isMarketingModule) {
          this.$router.push({
            name: 'MarketingSerialLetterProcessView',
            query: {
              source: 'contact',
              uuid: uniqKey,
              previousRoute: this.$route.fullPath
            }
          })
        } else if (this.isAdminModule) {
          this.$router.push({
            name: 'AdminSerialLetterProcessView',
            query: {
              source: 'contact',
              uuid: uniqKey,
              previousRoute: this.$route.fullPath
            }
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetSerialLetterProcessView',
            query: {
              source: 'contact',
              uuid: uniqKey,
              previousRoute: this.$route.fullPath
            }
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingSerialLetterProcessView',
            query: {
              source: 'contact',
              uuid: uniqKey,
              previousRoute: this.$route.fullPath
            }
          })
        }
      })
    },
    openSendMailModal() {
      let contactsWithEmail = null
      if ('relations' in this.contact) {
        contactsWithEmail = this.relationsContacts.filter(contactPersons => contactPersons.email !== '')
        if (this.typeOfContact === 'company') {
          contactsWithEmail = contactsWithEmail.concat(this.contact.contactPersons.filter(contactPersons => contactPersons.email !== ''))
        }
      } else {
        contactsWithEmail = this.contact.contactPersons.filter(contactPersons => contactPersons.email !== '')
      }
      if ((this.contact.email && this.contact.email !== '') || contactsWithEmail.length > 0) {
        if ((this.contact.email && this.contact.email !== '')) {
          this.setSendMailPreselectedContact(this.contact)
          this.setSendMailContacts([this.contact])
        } else {
          this.setSendMailContacts(contactsWithEmail.filter(x => x.active))
        }
        this.setSendMailReferenceIds([])
        this.setSendMailEmailToReply(null)
        this.$nextTick(function () {
          this.callShowSendMailModal()
        })
      } else {
        Vue.toasted.show(this.$t('message.loadingErrors.contactHasNoEmail'), { type: 'error' })
      }
    },
    replyMail(email) {
      this.prepareReplyMail(email)
    },
    forwardAsAttachement(mediaList, email) {
      this.setSubject('Fwd: ' + email.subject)
      this.setSendMailHasContext(true)
      this.setSendMailContacts([])
      this.setEmailToForwardAsAttachment(mediaList)
      this.$nextTick(function () {
        if (this.getMailboxesForUserId(this.userId).length === 0) {
          this.$nextTick(function () {
            Vue.toasted.show(this.$t('message.settings.mailbox.configMailboxToast'), { type: 'error' })
          })
        } else {
          this.callShowSendMailModal()
        }
      })
    },
    replyAllMail(email) {
      this.prepareReplyAll(email)
    },
    showEmail(item) {
      this.selectedEmail = item
      this.emailToReply = null
      this.$nextTick(function () {
        console.log(this.$refs)
        this.$refs['detail-mail'].show()
      })
    },
    getContactById(contactId) {
      if (this.contact.id === contactId) return this.contact
      if (!this.contact || (this.contact.contactPersons && this.contact.contactPersons.length === 0)) return '-'
      if (!this.contact || (this.contact.relations)) return this.relationsContacts.find(object => object.id === contactId) || '-'
      return this.contact.contactPersons.find(object => object.id === contactId) || '-'
    },
    getEmployeeById(employeeId) {
      if (this.activityEmployeeList.length === 0) return '-'
      return this.activityEmployeeList.persons.find(object => object.id === employeeId) || '-'
    },
    editActivity(item) {
      this.selectedActivity = null
      this.showModalActivity = true
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.selectedActivity = item
      this.$nextTick(function () {
        this.$refs['create-activity'].show()
      })
    },
    changeUniqueId() {
      this.uniqueId = Math.random().toString(36).substr(2, 9)
    },
    linkContact() {
      if (this.$route.name === 'MarketingContactDetailsView') {
        this.$router.push({ name: 'MarketingContactOverview', query: { linkContact: this.contact.id, linkContactType: this.typeOfContact } })
      } else if (this.$route.name === 'AdminContactDetailsView') {
        this.$router.push({ name: 'AdminContactOverview', query: { linkContact: this.contact.id, linkContactType: this.typeOfContact } })
      } else if (this.$route.name === 'AssetContactDetailsView') {
        this.$router.push({ name: 'AssetContactOverview', query: { linkContact: this.contact.id, linkContactType: this.typeOfContact } })
      } else if (this.$route.name === 'AccountingContactDetailsView') {
        this.$router.push({ name: 'AccountingContactOverview', query: { linkContact: this.contact.id, linkContactType: this.typeOfContact } })
      } else {
        this.$router.push({ name: 'OwnerContactOverview', query: { linkContact: this.contact.id, linkContactType: this.typeOfContact } })
      }
    },
    openUnlinkModal(element) {
      this.contactToUnlink = element.id
      this.$refs.confirmationModalLinkContact.show()
    },
    unlinkPersons() {
      this.disabledLinkBtn = true
      if (this.typeOfContact === 'company') {
        ContactApi.unLinkCompanies(this.contactToUnlink, this.contact.id)
          .then(() => {
            this.$refs.confirmationModalLinkContact.hide()
            this.disabledLinkBtn = false
            const t = this
            this.contact.relations = this.contact.relations.filter(function (obj) {
              return obj.incomingContactId !== t.contactToUnlink
            })
            this.relationsContacts = this.relationsContacts.filter(function (obj) {
              return obj.id !== t.contactToUnlink
            })
            this.loadDocuments()
            Vue.toasted.show(this.$t('message.successMessages.contactUnLinked'), { type: 'success' })
          })
          .catch(e => {
            this.disabledLinkBtn = false
            console.log(e)
            Vue.toasted.show(this.$t('message.deleteErrors.contact'), { type: 'error' })
          })
      } else {
        ContactApi.unLinkPersons(this.contactToUnlink, this.contact.id)
          .then(() => {
            this.$refs.confirmationModalLinkContact.hide()
            this.disabledLinkBtn = false
            const t = this
            this.contact.relations = this.contact.relations.filter(function (obj) {
              return obj.incomingContactId !== t.contactToUnlink
            })
            this.relationsContacts = this.relationsContacts.filter(function (obj) {
              return obj.id !== t.contactToUnlink
            })
            this.loadDocuments()
            Vue.toasted.show(this.$t('message.successMessages.contactUnLinked'), { type: 'success' })
          })
          .catch(e => {
            this.disabledLinkBtn = false
            console.log(e)
            Vue.toasted.show(this.$t('message.deleteErrors.contact'), { type: 'error' })
          })
      }
    },
    openContactModal() {
      this.selectedPerson = null
      this.$nextTick(function () {
        this.$refs['contact-person-modal']?.show()
      })
    },
    createNewContactClicked() {
      this.contactToEdit = null
      this.$bvModal.show('create-contact-modal')
    },
    contactPersonCreated(item) {
      this.contact.contactPersons.push(item)
    },
    showPerson(item) {
      this.selectedPerson = item
      this.$nextTick(function () {
        this.$refs['contact-person-modal'].show()
      })
    },
    contactPersonEdited(item) {
      this.selectedPerson = null
      for (let index = 0; index < this.contact.contactPersons.length; index++) {
        if (this.contact.contactPersons[index].id === item.id) {
          this.contact.contactPersons[index] = item
          break
        }
      }
      this.$refs.contactPersonTable.refresh()
    },
    contactPersonDeleted(id) {
      for (let index = 0; index < this.contact.contactPersons.length; index++) {
        if (this.contact.contactPersons[index].id === id) {
          this.contact.contactPersons.splice(index, 1)
        }
      }
    },
    editContactClicked(contact) {
      this.contactToEdit = contact
      this.$nextTick(function () {
        this.$bvModal.show('create-contact-modal')
      })
    },
    sendEmailModal(account) {
      this.emailToContact = { id: account.id, name: account.name, email: account.email }
      this.setSendMailContacts([this.emailToContact])
      this.setSendMailPreselectedContact(this.emailToContact)
      this.setSendMailEmailToReply(null)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    modalHide() {
      this.contactToEdit = null
    },
    clearContactData() {
      this.contact = null
      this.relationsContacts = []
      this.contactDocuments = []
      this.typeOfContact = ''
      this.mailList = []
      this.activityList = []
      this.activityEmployeeList = []
      this.reminderList = []
      this.rentalDocuments = []
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({
        data: hits
      }))
    },
    onChange(refine, email) {
      if (email?.length > 0) {
        if (email !== '') clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          this.debounceTimeout = null
          refine(email)
        }, 300)
      }
    },
    loadRentalDocument(list, listContacts) {
      this.listTenancies = list
      let documentIds = []
      for (const element of list) {
        documentIds = documentIds.concat(element.documentIds)
      }

      const uniqueDocumentIds = [...new Set(documentIds)]
      if (uniqueDocumentIds.length > 0) {
        MediaApi.listDocuments(uniqueDocumentIds)
          .then(response => {
            this.rentalDocuments = response.documents.reverse()
            for (let index = 0; index < this.rentalDocuments.length; index++) {
              const el = list.find(x => x.documentIds.includes(this.rentalDocuments[index].id))
              if (el) {
                let name = ''
                const ids = [...new Set([el.tenant.billingContactId].concat(el.tenant.contactIds))]
                ids.forEach(element => {
                  name += listContacts.find(x => x.id === element)?.name + ', '
                })
                this.rentalDocuments[index].label = el.numericId + ': ' + (name !== '' ? name.slice(0, -2) : '')
                this.rentalDocuments[index].contractNumber = el.numericId
              }
            }
          })
      }
      this.loadSignatureRequest()
    },
    loadCondominiumDocuments(list, listContacts) {
      this.listCondoOwners = list
      let documentIds = []
      for (const element of list) {
        documentIds = documentIds.concat(element.documentIds)
      }

      const uniqueDocumentIds = [...new Set(documentIds)]
      if (uniqueDocumentIds.length > 0) {
        MediaApi.listDocuments(uniqueDocumentIds)
          .then(response => {
            this.condominiumDocs = response.documents.reverse()
            for (let index = 0; index < this.condominiumDocs.length; index++) {
              const el = list.find(x => x.documentIds.includes(this.condominiumDocs[index].id))
              if (el) {
                let name = ''
                const ids = [...new Set([el.owner.billingContactId].concat(el.owner.contactIds))]
                ids.forEach(element => {
                  name += listContacts.find(x => x.id === element)?.name + ', '
                })
                this.condominiumDocs[index].label = el.numericId + ': ' + (name !== '' ? name.slice(0, -2) : '')
                this.condominiumDocs[index].contractNumber = el.numericId
              }
            }
          })
      }
    },
    onSelect(selected) {
      if (selected) {
        const selectedContactId = selected.item.objectID
        this.redirectToDetailPage(selectedContactId)
        this.$nextTick(function () {
          this.emailSuggestion = ''
          this.refreshAutosuggestKey = Math.floor(Math.random() * 100)
        })
      }
    },
    forwardMail(email) {
      this.prepareForwardMail(email)
    },
    deletedMail(mailId) {
      if (mailId) {
        const index = this.mailList.findIndex(x => x.id === mailId)
        if (index > -1) {
          this.mailList.splice(index, 1)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.link {
  left: 47%;
}

.card-data {
  p {
    padding: 0.7rem 0.7rem;
  }

  & > .row.m-0:nth-child(odd) {
    background-color: $color-bg-white;
  }

  & > .row.m-0:nth-child(even) {
    background-color: $color-bg-white;
  }

  a:hover {
    text-decoration: none;
  }
}

.pointer-link {
  cursor: pointer;
  text-decoration: underline;
  color: #333;
}

.auto-size {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:deep(.max-w-subject) {
  @media (min-width: 768px) {
    max-width: 15vw;
  }
}
:deep(.align-icon > div) {
  float: right;
}

:deep(table.table) {
  border-radius: 0;
  box-shadow: none;

  tbody > .b-table-row-selected td {
    color: inherit !important;
  }

  tbody > tr:hover td {
    background-color: rgb(240 240 240) !important
  }

  @media (min-width: 768px) {
    tbody > tr td {
      background-color: $color-bg-white !important;
    }
  }

  .clickable-cell {
    padding: 0.4rem 0.75rem;
  }
}

:deep(thead > tr > th) {
  background-color: #f6f6f6bd !important;
}
.letter-img{
    width: 15px;
  }
  :deep(svg.ticket-icon path) {
  fill:#fff
}
:deep(.no-lower-height.lower-height) {
  margin-bottom: 0;
  & > .card-body {
    padding: 1.25rem !important;
  }
}
</style>
