<template>
  <section>
    <div v-if="object">
      <h1
        v-if="!loading">
        <address-text
          :one-line="true"
          :address="object.address" />
      </h1>
      <h5
        v-if="object && !loading">
        <span>{{ object.publicId }} | </span>
        <span v-if="object.name && object.name !== ''">{{ object.name }} | </span>
        <span v-if="getObjectRoom(object) > 0">{{ formatRooms(getObjectRoom(object)) }} </span>
        <span>{{ $t('message.onBoarding.buildings.objects.category.' + object.category.toLowerCase()) }} </span>
        <span v-if="getObjectFloor(object) > -10000">{{ formatFloor(getObjectFloor(object)) }}</span>
      </h5>
    </div>
    <div
      v-if="object && !loading"
      class="row">
      <div class="col">
        <coozzy-card class="readonly-wrapper global-card mb-3">
          <object-basic
            :ref="'object-basic-' + object.id"
            :key="`basic-${object.id}`"
            :object-selected="object"
            :read-only="true"
            :visible="false"
            :from-admin="$route.path.startsWith('/admin') || $route.path.startsWith('/asset') || $route.path.startsWith('/accounting')" />
        </coozzy-card>
        <coozzy-card
          class="readonly-wrapper global-card mb-3">
          <object-pricing
            :ref="`object-pricing-${object.id}`"
            :key="`pricing-${object.id}`"
            :object-selected="object"
            :expand-by-default="false"
            :read-only="true" />
        </coozzy-card>
        <coozzy-card
          v-if="lastTenancy"
          class="readonly-wrapper global-card mb-3">
          <div
            id="Tenant"
            v-b-toggle="`coozzy-card-${object.id}`"
            class="collapse-title">
            <h5 class="cursor-pointer">
              {{ $t('message.onBoarding.buildings.objects.tenancies.title') }}
            </h5>
          </div>
          <div class="col-12 p-0">
            <b-collapse
              :id="`coozzy-card-${object.id}`"
              :ref="`coozzy-card-${object.id}`"
              :visible="true">
              <object-tenancy
                :ref="`object-last-tenancy-${object.id}`"
                :tenancy="lastTenancy"
                :read-only="true"
                :expand-by-default="false"
                :enable-tenant-selection="false"
                :show-delete-button="false"
                :index="object.id"
                :hide-actions="true"
                :end-of-contract-from-enter-termination="detailsOfTermination.length === 1 ? detailsOfTermination[0].terminatedOn : -1"
                mode="adding" />
            </b-collapse>
          </div>
        </coozzy-card>
        <coozzy-card class="global-card mb-3">
          <div
            v-if="target === 'enterTermination'"
            class="col-12">
            <coozzy-form-checkbox
              v-model="noVacancy">
              {{
                $t('message.enterTermination.noVacancy')
              }}
            </coozzy-form-checkbox>
          </div>
          <object-vacancy
            v-if="loadVacancyData"
            :ref="`object-vacancy-${object.id}`"
            :tenancy="tenancy"
            :expand-by-default="true"
            :enable-tenant-selection="false"
            :show-delete-button="false"
            :target="target"
            :index="object.id"
            :init-object="object"
            :disabled-fields="noVacancy"
            :is-inprogress="true"
            mode="adding"
            @updated-tenancy="tenancyUpdated" />
        </coozzy-card>
        <coozzy-button
          v-if="target !== 'addTenancy' && target !== 'enterTermination'"
          class="col-12 col-md-2 mb-3"
          design="primary"
          @click="cancelClicked">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          v-if="target !== 'addTenancy' && target !== 'enterTermination'"
          class="col-12 col-md-2 offset-md-8 float-right"
          design="primary"
          @click="saveClicked">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
      </div>
    </div>
    <b-modal
      id="confirmationModal"
      ref="confirmationModal"
      no-close-on-backdrop
      :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')"
      hide-footer>
      <p>{{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}</p>
      <div class="col">
        <coozzy-button
          size="small"
          class="border mb-0"
          design="transparent"
          @click="$bvModal.hide('confirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="confirmCancellation">
          {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Vue from 'vue'
import ObjectApi from '@/misc/apis/ObjectApi'
import AddressText from '@/framework/components/misc/AddressText'
import { obj } from '@/mixins/object'
import { formatting } from '@/mixins/formatting'
import ObjectBasic from '@/building/tabs/components/ObjectBasic'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import ObjectPricing from '@/building/tabs/components/ObjectPricing'
import ObjectVacancy from '@/building/tabs/components/ObjectVacancy'
import ObjectTenancy from '@/building/tabs/components/ObjectTenancy'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import { dateUtils } from '@/mixins/dateUtils'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'

export default {
  name: 'AddVacancyView',
  components: {
    CoozzyFormCheckbox,
    CoozzyButton,
    ObjectVacancy,
    ObjectTenancy,
    ObjectPricing,
    CoozzyCard,
    ObjectBasic,
    AddressText
  },
  mixins: [formatting, obj, dateUtils, routeChecks],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  beforeRouteLeave (to, from, next) {
    this.nextToPath = to
    if (!this.confirmCancellationProcess) {
      this.$refs.confirmationModal.show()
    } else {
      next()
    }
  },
  props: {
    objectId: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: 'tenants'
    },
    detailsOfTermination: {
      type: Array,
      default: function () {
        return []
      }
    },
    enterTerminationExecuteSave: {
      type: Boolean,
      default: false
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.onBoarding.buildings.objects.addVacancy') + ' - ' + (this.object ? this.object.name : '')
    }
  },
  data() {
    return {
      loading: false,
      property: null,
      object: null,
      tenancy: null,
      lastTenancy: null,
      noVacancy: false,
      tenancies: null,
      loadVacancyData: false,
      confirmCancellationProcess: false,
      nextToPath: null
    }
  },
  computed: {
    prevRoutePath() {
      return this.prevRoute ? this.prevRoute.path : '/'
    },
    prevRouteQuery() {
      return this.prevRoute ? this.prevRoute.query : null
    }
  },
  watch: {
    detailsOfTermination: {
      deep: true,
      handler() {
        if (this.tenancy) {
          if (!this.tenancy.startingAt || (this.detailsOfTermination?.length === 1 && this.detailsOfTermination[0]?.terminatedOn !== parseInt(this.tenancy.startingAt, 10) - 86400)) {
            let tempDate = new Date(this.$options.filters.objectToDateInput(this.detailsOfTermination[0]?.terminatedOn))
            tempDate = tempDate.setDate(tempDate.getDate() + 1)
            this.tenancy.startingAt = this.toObject(tempDate)
            if (this.$refs['object-vacancy-' + this.object.id]) {
              this.$refs['object-vacancy-' + this.object.id].getDatesFromObjects()
            }
          }
        }
      }
    }
  },
  mounted() {
    this.loading = true

    ObjectApi.getObject(this.objectId)
      .then(response => {
        this.tenancy = {
          internalId: Date.now(),
          ownerId: response.object.ownerId,
          objectId: response.object.id,
          vacancy: true,
          vacancyReason: 'VACANCY_REASON_NO_NEXT_TENANT',
          subTenants: [],
          roommates: [],
          contract: {
            activationDate: null,
            creationDate: null,
            number: '',
            type: 'UNDEFINED_CONTRACT_TYPE',
            rentType: '',
            typeOfUse: ''
          },
          tenant: {
            contactIds: [],
            industryName: '',
            numberOfPersons: -1,
            type: 'UNDEFINED_TENANT_TYPE'
          },
          deposit: {
            type: 'DEPOSIT_TYPE_NO_DEPOSIT',
            address: {
              country: 'CH',
              state: '',
              zip: '',
              city: '',
              street: '',
              streetNumber: ''
            },
            amount: -1,
            dueDate: null,
            paidAt: null,
            paidAmount: -1,
            bankAccount: null,
            bankName: null
          },
          rent: {
            advancePaymentValues: [],
            flatRateValues: [],
            additionalValues: [],
            dueDates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            additionalCosts: -1,
            coldRent: -1,
            coldRentM2PerYear: -1,
            heatingCosts: -1,
            additionalCostsBillingType: 'ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE',
            monthlyRentDueBy: -1,
            countryIndex: {
              baseDate: null,
              date: null,
              inflation: -1
            },
            referenceInterestRate: {
              date: null,
              rate: -10000,
              ratePossible: -10000
            },
            costIncreaseType: 'COST_INCREASE_TYPE_FLAT_RATE',
            costIncreaseDate: null,
            costIncreaseValue: -1,
            adjustments: [
              {
                calculationBasis: '',
                byDate: null,
                dueDates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                additionalCosts: -1,
                coldRent: -1,
                coldRentM2PerYear: -1,
                coldRentPerMonth: -1,
                heatingCosts: -1,
                additionalCostsBillingType: 'UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE',
                countryIndex: {
                  baseDate: null,
                  date: null,
                  inflation: -1
                },
                referenceInterestRate: {
                  date: null,
                  rate: -10000,
                  ratePossible: -10000
                },
                costIncreaseType: 'COST_INCREASE_TYPE_FLAT_RATE',
                costIncreaseDate: null,
                costIncreaseValue: -1,
                monthlyRentDueBy: 1,
                inArrears: false,
                advancePaymentValues: [],
                flatRateValues: [],
                additionalValues: [],
                documentIds: []
              }
            ]
          },
          terminationTenant: {
            earliestDate: null,
            possibleMonths: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            periodInMonths: -1
          },
          terminationLandlord: {
            earliestDate: null,
            possibleMonths: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            periodInMonths: -1
          },
          indexData: {
            additionalCostsAdjustmentAllowed: false,
            belowInitialRentAllowed: false,
            maximumRate: null,
            referenceMonthForIndex: null,
            rentAdjustmentAdditionalBenefit: false,
            rentAdjustmentByMonths: null,
            rentAdjustmentByPoints: null,
            rentAdjustmentMonths: [],
            contractPercentImputedAtIndex: 100,
            rentAdjustmentNotification: {
              unit: 'RENT_ADJUSTMENT_NOTIFICATION_MONTHS',
              value: null
            }
          },
          startingAt: null,
          endingAt: null,
          documentIds: []
        }
        const promises = []
        this.object = response.object
        // get last tenant of object
        const t = this
        promises.push(ObjectApi.getTenancies([this.object.id])
          .then(response => {
            this.tenancies = response.tenancies
            if (response.tenancies.length > 0) {
              if (response.tenancies.find(x => x.status === 'TENANCY_STATUS_CURRENT' && x.endingAt < 0)) {
                this.lastTenancy = response.tenancies.find(x => x.status === 'TENANCY_STATUS_CURRENT' && x.endingAt < 0)
              } else {
                this.lastTenancy = response.tenancies.sort(function (a, b) {
                  return new Date(t.$options.filters.objectToDateInput(b.endingAt)).getTime() - new Date(t.$options.filters.objectToDateInput(a.endingAt)).getTime()
                })[0]
              }
            }
          }))

        promises.push(ObjectApi.getPropertiesByBuildingIds([this.object.buildingId])
          .then(response => {
            if (response.properties.length > 0) {
              this.property = response.properties[0]
              this.loading = false
            } else {
              Vue.toasted.show(this.$t('message.loadingErrors.property'), { type: 'error' })
              this.$router.back()
            }
          }))

        Promise.all(promises)
          .then(() => {
            if (this.lastTenancy) {
              this.$nextTick(() => {
                this.setDefaultValuesFromPreviousTenancy(this.lastTenancy)
                this.loadVacancyData = true
              })
            } else {
              if (this.property) {
                this.loadTenancyRentComponents(this.property)
                this.loadVacancyData = true
              }
            }
          })
      })
      .catch(e => {
        this.loading = false
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        this.$router.back()
      })
  },
  methods: {
    confirmCancellation() {
      this.confirmCancellationProcess = true
      if (this.nextToPath !== null) {
        this.$router.push({ name: this.nextToPath.name, params: this.nextToPath.params, query: this.nextToPath.query }, () => {
        })
      } else {
        this.$router.push({ path: this.prevRoutePath, query: this.prevRouteQuery }, () => {
        })
      }
    },
    tenancyUpdated(tenancy) {
      this.tenancy = tenancy
    },
    cancelClicked() {
      this.$router.back()
    },
    validateBeforeSave() {
      if (this.noVacancy) {
        this.$emit('validated-vacancy', true, this.object.id)
        return true
      }
      if (this.lastTenancy) {
        if (this.target === 'enterTermination' && new Date(this.$options.filters.objectToDateInput(this.detailsOfTermination[0].terminatedOn)).getTime() >= new Date(this.$options.filters.objectToDateInput(this.tenancy.startingAt)).getTime()) {
          Vue.toasted.show(this.$t('message.addVacancy.startsBeforePrevious'), { type: 'error' })
          return false
        }

        if (new Date(this.$options.filters.objectToDateInput(this.lastTenancy.endingAt)).getTime() >= new Date(this.$options.filters.objectToDateInput(this.tenancy.startingAt)).getTime()) {
          Vue.toasted.show(this.$t('message.addVacancy.startsBeforePrevious'), { type: 'error' })
          return false
        }
      }

      const allTenancies = this.tenancies
      for (let index = 0; index < this.tenancies.length; index++) {
        const element = this.tenancies[index]
        if (element.vacancy === true) {
          if ((allTenancies[index + 1] && allTenancies[index + 1].vacancy === true && allTenancies[index + 1].vacancyReason === element.vacancyReason)) {
            Vue.toasted.show(this.$t('message.savingErrors.reasonVacancyOnTwoVacancies'), { type: 'error' })
            console.log('Reason for vacancy cannot be the same on two vacancies in a row.')
            return false
          }
        }
      }

      if (this.$refs['object-vacancy-' + this.object.id] && this.$refs['object-vacancy-' + this.object.id].isInvalid()) {
        this.$emit('validated-vacancy', false, this.object.id)
        return false
      }
      this.$emit('validated-vacancy', true, this.object.id)
      return true
    },
    saveClicked() {
      this.loading = true
      if (this.noVacancy) {
        this.$emit('added-vacancy', true, this.object.id)
        this.loading = false
        return true
      }
      if (this.validateBeforeSave() === false) {
        this.loading = false
        return
      }

      this.confirmCancellationProcess = true

      ObjectApi.createTenancy(this.tenancy)
        .then(() => {
          Vue.toasted.show(this.$t('message.successMessages.vacancyAdded'), { type: 'success' })
          this.$emit('added-vacancy', true, this.object.id)
          if (this.target === 'tenants') {
            if (this.isAdminModule) {
              this.$router.push({ name: 'AdminOnboardingView', params: { id: this.object.buildingId }, query: { view: 'objects', elementSelected: this.object.id } }, () => { })
            } else if (this.isAssetModule) {
              this.$router.push({ name: 'AssetOnboardingView', params: { id: this.object.buildingId }, query: { view: 'objects', elementSelected: this.object.id } }, () => { })
            } else if (this.isAccountingModule) {
              this.$router.push({ name: 'AccountingOnboardingView', params: { id: this.object.buildingId }, query: { view: 'objects', elementSelected: this.object.id } }, () => { })
            }
          }
        })
        .catch(e => {
          this.$emit('added-vacancy', false, this.object.id)
          console.log(e)
          this.loading = false
          Vue.toasted.show(this.$t('message.savingErrors.vacancy'), { type: 'error' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadTenancyRentComponents(property) {
      if (property && property.advancePaymentRentComponents) {
        property.advancePaymentRentComponents.forEach(element => {
          this.tenancy.rent.adjustments.forEach(adj => {
            if (!adj.advancePaymentValues.find(x => x.type === element.rentComponent)) {
              adj.advancePaymentValues.push({
                type: element.rentComponent,
                value: null
              })
            }
          })
        })
        property.flatRateRentComponents.forEach(element => {
          this.tenancy.rent.adjustments.forEach(adj => {
            if (!adj.flatRateValues.find(x => x.type === element.rentComponent)) {
              adj.flatRateValues.push({
                type: element.rentComponent,
                value: null
              })
            }
          })
        })
        property.additionalRentComponents.forEach(element => {
          this.tenancy.rent.adjustments.forEach(adj => {
            if (!adj.additionalValues.find(x => x.type === element.rentComponent)) {
              adj.additionalValues.push({
                type: element.rentComponent,
                value: null
              })
            }
          })
        })
      }
    },
    setDefaultValuesFromPreviousTenancy(previousTenant) {
      if (previousTenant) {
        if (previousTenant.rent.adjustments.length > 0) {
          const previousAdjustment = previousTenant.rent.adjustments.sort(function (a, b) {
            return b.byDate - a.byDate
          })[0]
          this.tenancy.rent.adjustments[0].advancePaymentValues = []
          this.tenancy.rent.adjustments[0].flatRateValues = []
          this.tenancy.rent.adjustments[0].additionalValues = []
          for (let i = 0; i < previousAdjustment.advancePaymentValues.length; i++) {
            if (this.property.advancePaymentRentComponents.flatMap(x => x.rentComponent).includes(previousAdjustment.advancePaymentValues[i].type)) {
              this.tenancy.rent.adjustments[0].advancePaymentValues.push(previousAdjustment.advancePaymentValues[i])
            }
          }
          for (let i = 0; i < previousAdjustment.flatRateValues.length; i++) {
            if (this.property.flatRateRentComponents.flatMap(x => x.rentComponent).includes(previousAdjustment.flatRateValues[i].type)) {
              this.tenancy.rent.adjustments[0].flatRateValues.push(previousAdjustment.flatRateValues[i])
            }
          }
          for (let i = 0; i < previousAdjustment.additionalValues.length; i++) {
            if (this.property.additionalRentComponents.flatMap(x => x.rentComponent).includes(previousAdjustment.additionalValues[i].type)) {
              this.tenancy.rent.adjustments[0].additionalValues.push(previousAdjustment.additionalValues[i])
            }
          }
          this.tenancy.rent.adjustments[0].dueDates = previousAdjustment.dueDates
          this.tenancy.rent.adjustments[0].referenceInterestRate.rate = previousAdjustment.referenceInterestRate.rate
          this.tenancy.rent.adjustments[0].referenceInterestRate.ratePossible = previousAdjustment.referenceInterestRate.ratePossible
          this.tenancy.rent.adjustments[0].referenceInterestRate.date = previousAdjustment.referenceInterestRate.date
          this.tenancy.rent.adjustments[0].countryIndex.date = previousAdjustment.countryIndex.date
          this.tenancy.rent.adjustments[0].countryIndex.baseDate = previousAdjustment.countryIndex.baseDate
          this.tenancy.rent.adjustments[0].costIncreaseType = previousAdjustment.costIncreaseType
          this.tenancy.rent.adjustments[0].costIncreaseDate = previousAdjustment.costIncreaseDate
          this.tenancy.rent.adjustments[0].costIncreaseValue = previousAdjustment.costIncreaseValue
          this.tenancy.rent.adjustments[0].dueDates = previousAdjustment.dueDates
          this.tenancy.rent.adjustments[0].countryIndex.inflation = previousAdjustment.countryIndex.inflation

          this.$nextTick(() => {
            this.tenancy.rent.adjustments[0].coldRent = previousAdjustment.coldRent
            this.tenancy.rent.adjustments[0].coldRentPerMonth = previousAdjustment.coldRentPerMonth
            this.tenancy.rent.adjustments[0].coldRentM2PerYear = previousAdjustment.coldRentM2PerYear
            this.tenancy.rent.adjustments[0].calculationBasis = previousAdjustment.calculationBasis
            this.tenancy.rent.adjustments[0].additionalCostsBillingType = previousAdjustment.additionalCostsBillingType
          })
        }
        this.tenancy.rent.additionalCostsBillingType = previousTenant.rent.additionalCostsBillingType
        let tempDate = new Date(this.$options.filters.objectToDateInput(previousTenant.endingAt))
        tempDate = tempDate.setDate(tempDate.getDate() + 1)
        const startingDate = this.toObject(tempDate)
        this.tenancy.startingAt = { ...this.tenancy.startingAt, ...startingDate }
        if (this.$refs['object-vacancy-' + this.object.id]) {
          this.$refs['object-vacancy-' + this.object.id].getDatesFromObjects()
        }
        this.tenancy.rent.adjustments[0].byDate = { ...this.tenancy.rent.adjustments[0].byDate, ...startingDate }
        if (previousTenant.contract.rentType) {
          this.tenancy.contract.rentType = previousTenant.contract.rentType
        }
        if (previousTenant.contract.typeOfUse) {
          this.tenancy.contract.typeOfUse = previousTenant.contract.typeOfUse
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.readonly-wrapper {
  .inputs-container {
    overflow-y: scroll;
    height: calc(100vh - 100px);
  }

  .item {
    cursor: pointer;
  }

  :deep(.details-row-bg-1) {
    background-color: $color-table-row-2;
  }

  :deep(.details-row-bg-2) {
    background-color: $color-table-row-1;
  }

  :deep(label) {
    width: 100%;
  }

  :deep(.is-disabled) {
    .sort button {
      display: block !important;
    }

    button:not(.sort):not(.page-link) {
      display: none;
    }

    .multiselect, .custom-checkbox {
      pointer-events: none;

      .multiselect__select, .multiselect__placeholder {
        display: none;
      }
    }

    .filter-inputs .multiselect, .custom-checkbox {
      pointer-events: auto !important;

      .multiselect__select, .multiselect__placeholder {
        display: block !important;
      }
    }

    .basic {
      input {
        opacity: 1;
        border: thin solid #ced4da;
        -webkit-box-shadow: inherit;
        box-shadow: inherit;
        margin-bottom: 0;
        pointer-events: initial;
        padding-left: 0.2rem;
        background: none;
      }
    }

    .multi-select-value {
      line-height: 1.5;
    }
  }

  :deep(.collapse-title) {
    &.collapsed {
      h5 {
        background-image: url('../assets/arrow_down.svg');
      }
    }

    h5 {
      background-image: url('../assets/arrow_up.svg');
      background-repeat: no-repeat;
      font-size: large;
      font-weight: 500 !important;
      height: 1.2rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
      background-position: right;
    }
  }

  :deep(.global-card) {
    margin-bottom: 0;

    & > .card-body {
      -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
      -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
      box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    }

    border-left: 0;
    border-right: 0;
    border-bottom: 0;

    &.no-top-border {
      border-top: 0;
    }
  }

  .floating-wrapper {
    right: 5vw;
    bottom: 4vh;
    z-index: 999;
    position: fixed;

    .floating-button {
      border-radius: 100px !important;
      display: flex;
      align-items: center;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.20), 0 4px 4px rgba(0, 0, 0, 0.15);
      z-index: 2;
      justify-content: center;
      background-color: $color-primary-dark;
      padding: 20px !important;
      cursor: pointer;

      svg {
        color: white;
        font-size: 15px;
      }
    }
  }
}
</style>
