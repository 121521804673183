<template>
  <div
    v-if="isBusy"
    class="row">
    <div class="col-12">
      <h5>{{ $t('message.applicationForm.miscInfo') }}</h5>
    </div>
    <!-- Busy -->
    <div
      class="col text-center">
      <coozzy-spinner />
    </div>
  </div>
  <div
    v-else
    class="row">
    <div class="col-12">
      <h5>{{ $t('message.applicationForm.miscInfo') }}</h5>
    </div>
    <div class="col-12 col-md-6">
      <label for="foreclosure">{{ $t('message.applicationForm.foreclosureCompany') }} {{ (sending ? '*' : '' ) }}</label>
      <coozzy-form-select
        id="foreclosure"
        v-model="foreclosureLastTwoYears"
        :state="object.category !== 'PARKING_SPACE' && object.category !== 'SECONDARY_ROOM' && $v.foreclosureLastTwoYears.$dirty && $v.foreclosureLastTwoYears.$error ? false : null">
        <option value="UNDEFINED_VALUE">
          -
        </option>
        <option value="APPLIES">
          {{ $t('message.generic.selectField.yes') }}
        </option>
        <option value="DOES_NOT_APPLY">
          {{ $t('message.generic.selectField.no') }}
        </option>
      </coozzy-form-select>
    </div>
    <div class="col-12 col-md-6">
      <label for="debtCertificate">{{ $t('message.applicationForm.debtCertificateCompany') }} {{ (sending ? '*' : '' ) }}</label>
      <coozzy-form-select
        id="debtCertificate"
        v-model="debtCertificateLastFiveYears"
        :state="object.category !== 'PARKING_SPACE' && object.category !== 'SECONDARY_ROOM' && $v.debtCertificateLastFiveYears.$dirty && $v.debtCertificateLastFiveYears.$error ? false : null">
        <option value="UNDEFINED_VALUE">
          -
        </option>
        <option value="APPLIES">
          {{ $t('message.generic.selectField.yes') }}
        </option>
        <option value="DOES_NOT_APPLY">
          {{ $t('message.generic.selectField.no') }}
        </option>
      </coozzy-form-select>
    </div>
    <div class="col-12 col-md-6">
      <label for="liabilityInsurance">{{ $t('message.applicationForm.liabilityInsuranceCompany') }}</label>
      <coozzy-form-select
        id="liabilityInsurance"
        v-model="hasLiabilityInsurance">
        <option value="UNDEFINED_VALUE">
          -
        </option>
        <option value="APPLIES">
          {{ $t('message.generic.selectField.yes') }}
        </option>
        <option value="DOES_NOT_APPLY">
          {{ $t('message.generic.selectField.no') }}
        </option>
      </coozzy-form-select>
    </div>
    <div
      v-if="parkingOptions.length > 0"
      class="col-12 col-md-12 mt-3">
      <label>
        {{ $t('message.applicationForm.desiredParkingOptions') }}
      </label>
      <div class="row m-0">
        <template v-for="(parkingTypeValue, parkingTypeIndex) in parkingOptions">
          <div
            :key="parkingTypeIndex"
            class="col-12">
            <div class="row">
              <coozzy-form-checkbox
                v-model="parkingTypeValue.checked"
                :initial="parkingTypeValue.checked"
                :class="'col-3 d-flex align-items-center'"
                @change="parkingOptionChanged">
                <span>{{ $t('message.onBoarding.buildings.objects.parkingSpaces.parkingType.' + parkingTypeValue.type) }}</span><span class="ml-1">(CHF {{ parkingTypeValue.costs }})</span>
              </coozzy-form-checkbox>
              <coozzy-form-input
                v-if="parkingTypeValue.checked"
                :id="'licensePlate_' + parkingTypeIndex"
                v-model="parkingTypeValue.licensePlate"
                type="text"
                :class="'col-3'"
                :name="$t('message.applicationForm.licensePlate')"
                @input="parkingOptionChanged" />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="col-12 col-md-12 mt-3">
      <coozzy-form-textarea
        ref="remark"
        v-model="remark"
        :initial="remark"
        :value-text-area="remark"
        name="remark"
        :rows="2">
        {{ $t('message.applicationForm.remark') }}
      </coozzy-form-textarea>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { validation } from '@/mixins/validation'
import { required } from 'vuelidate/lib/validators'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import * as Sentry from '@sentry/vue'

function isUndefinedValue(value, vm) {
  return value !== 'UNDEFINED_VALUE'
}

export default {
  name: 'ApplicationFormCompanyMisc',
  components: { CoozzyFormSelect, CoozzySpinner },
  mixins: [validation],
  props: {
    company: {
      type: Object,
      default: null
    },
    sending: {
      type: Boolean,
      default: false
    },
    isBusy: {
      type: Boolean,
      default: false
    },
    object: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      parkingOptions: []
    }
  },
  computed: {
    foreclosureLastTwoYears: {
      get() {
        return this.company.legal.foreclosureLastTwoYears
      },
      set(value) {
        const com = this.company
        com.legal.foreclosureLastTwoYears = value
        this.updateCompany(com)
      }
    },
    debtCertificateLastFiveYears: {
      get() {
        return this.company.legal.debtCertificateLastFiveYears
      },
      set(value) {
        const com = this.company
        com.legal.debtCertificateLastFiveYears = value
        this.updateCompany(com)
      }
    },
    hasLiabilityInsurance: {
      get() {
        return this.company.insurance.hasLiabilityInsurance
      },
      set(value) {
        const com = this.company
        com.insurance.hasLiabilityInsurance = value
        this.updateCompany(com)
      }
    },
    parkingSpacesRequested: {
      get() {
        return this.company.parkingSpacesRequested
      },
      set(value) {
        const com = this.company
        com.parkingSpacesRequested = value
        this.updateCompany(com)
      }
    },
    remark: {
      get() {
        return this.company.remark
      },
      set(value) {
        const com = this.company
        com.remark = value
        this.updateCompany(com)
      }
    }
  },
  mounted() {
    if (this.object && this.object.parking.length > 0) {
      this.object.parking.forEach((parking) => {
        this.parkingOptions.push({
          type: parking.parkingType,
          costs: parking.costs,
          checked: false,
          licensePlate: ''
        })
      })
    }
  },
  methods: {
    ...mapMutations('applicationForm', ['updateCompany']),
    parkingOptionChanged() {
      const com = this.company
      com.requestedParkingSpaces = this.parkingOptions
      this.updateCompany(com)
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        Sentry.setExtra('companyMiscValidation', this.$v)
        this.scrollToError()
        return true
      }
      return false
    }
  },
  validations() {
    const validationMisc = {}
    if (this.object.category !== 'PARKING_SPACE' && this.object.category !== 'SECONDARY_ROOM') {
      validationMisc.foreclosureLastTwoYears = {
        required,
        isUndefinedValue
      }
      validationMisc.debtCertificateLastFiveYears = {
        required,
        isUndefinedValue
      }
    }
    return validationMisc
  }
}
</script>

<style lang="scss" scoped>
</style>
