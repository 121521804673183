<template>
  <div class="row">
    <div class="col-12">
      <h5>{{ $t('message.applicationForm.infoAboutTenant') }}</h5>
    </div>
    <div class="col-12 col-md-12 mb-2">
      <label
        for="preferredLanguage"
        class="mb-0">
        {{ $t('message.contact.preferredLanguage') }}
      </label>
      <coozzy-form-select
        id="preferredLanguage"
        v-model="preferredLanguage">
        <option value="PREFERRED_LANGUAGE_GERMAN">
          {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_GERMAN') }}
        </option>
        <option value="PREFERRED_LANGUAGE_ENGLISH">
          {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_ENGLISH') }}
        </option>
        <option value="PREFERRED_LANGUAGE_ITALIAN">
          {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_ITALIAN') }}
        </option>
        <option value="PREFERRED_LANGUAGE_FRENCH">
          {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_FRENCH') }}
        </option>
      </coozzy-form-select>
    </div>
    <!-- has the viewing Done -->
    <div class="col-6 col-md-6">
      <label for="viewingDone">{{ $t('message.applicationForm.hasViewingDone') }} {{ (sending ? '*' : '' ) }}</label>
      <coozzy-form-select
        id="viewingDone"
        v-model="viewingDone">
        <option value="no">
          {{ $t('message.generic.selectField.no') }}
        </option>
        <option value="yes">
          {{ $t('message.generic.selectField.yes') }}
        </option>
      </coozzy-form-select>
    </div>
    <!-- Desired start date of the contract -->
    <div class="col-12 col-md-6">
      <coozzy-form-input
        :id="desiredStartOfContract"
        ref="desiredStartOfContract"
        v-model="dates.desiredStartOfContract"
        :filter="'formatDate'"
        :state="$v.dates.desiredStartOfContract.$dirty && $v.dates.desiredStartOfContract.$error ? false : null"
        type="date"
        :name="$t('message.applicationForm.desiredStartOfContract') + (sending ? '*' : '' )"
        @blur="formatDate()" />
    </div>
    <div
      class="col-12 col-md-12">
      <coozzy-form-input
        v-model="name"
        :state="$v.name.$dirty && $v.name.$error ? false : null"
        :name="$t('message.generic.companyName')+ (sending ? '*' : '' )" />
    </div>
    <!-- Address -->
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="company.address.street"
        :name="$t('message.generic.street') + (sending ? '*' : '' )"
        :state="$v.street.$dirty && $v.street.$error ? false : null"
        type="text" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="company.address.streetNumber"
        :name="$t('message.generic.streetNumber') + (sending ? '*' : '' )"
        :state="$v.streetNumber.$dirty && $v.streetNumber.$error ? false : null"
        type="text" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="company.address.zip"
        :name="$t('message.generic.zip') + (sending ? '*' : '' )"
        :type="(company.address.country === 'CH' || company.address.country === 'DE') ? 'number': 'text'"
        :limit="zipLength"
        :state="$v.zip.$dirty && $v.zip.$error ? false : null"
        @input="getCityFromZip"
        @keydown.tab="isCitiesSuggested ? tabClicked() : ''" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-if="!isCitiesSuggested"
        v-model="company.address.city"
        :name="$t('message.generic.city') + (sending ? '*' : '' )"
        :state="$v.city.$dirty && $v.city.$error ? false : null"
        type="text" />
      <template v-else>
        <label for="type">{{ $t('message.generic.city') }}*</label>
        <coozzy-form-select
          id="city"
          v-model="citySelect"
          :state="$v.city.$dirty && $v.city.$error ? false : null"
          @change="cityChanged">
          <option
            v-for="(item, typeIndex) in citiesValue"
            :key="typeIndex"
            :value="item"
            :selected="typeIndex === 0">
            {{ item.city }}
          </option>
        </coozzy-form-select>
      </template>
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-country-select
        v-model="country"
        :initial-value="country"
        :state="$v.country.$dirty && $v.country.$error ? false : null"
        :required="true" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-region-select
        v-model="state"
        :initial-value="state"
        :state="$v.state.$dirty && $v.state.$error ? false : null"
        :country="country" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="purpose"
        :name="$t('message.applicationForm.businessPurpose') + (sending ? '*' : '' )"
        :state="$v.purpose.$dirty && $v.purpose.$error ? false : null" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="website"
        :name="$t('message.generic.website')" />
    </div>
    <div class="col-12 col-md-6">
      <label for="tradeRegister">{{ $t('message.applicationForm.tradeRegister') }} {{ (sending ? '*' : '' ) }}</label>
      <coozzy-form-select
        id="tradeRegister"
        v-model="tradeRegisterEntry"
        :state="$v.tradeRegisterEntry.$dirty && $v.tradeRegisterEntry.$error ? false : null">
        <option value="UNDEFINED_VALUE">
          -
        </option>
        <option value="APPLIES">
          {{ $t('message.generic.selectField.yes') }}
        </option>
        <option value="DOES_NOT_APPLY">
          {{ $t('message.generic.selectField.no') }}
        </option>
      </coozzy-form-select>
    </div>
    <div class="col-12 col-md-6">
      <label for="vatLiable">{{ $t('message.applicationForm.vatLiable') }} {{ (sending ? '*' : '' ) }}</label>
      <coozzy-form-select
        id="vatLiable"
        v-model="vatLiable"
        :state="$v.vatLiable.$dirty && $v.vatLiable.$error ? false : null">
        <option value="UNDEFINED_VALUE">
          -
        </option>
        <option value="APPLIES">
          {{ $t('message.generic.selectField.yes') }}
        </option>
        <option value="DOES_NOT_APPLY">
          {{ $t('message.generic.selectField.no') }}
        </option>
      </coozzy-form-select>
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        id="founded"
        ref="founded"
        v-model="dates.foundedDate"
        type="date"
        :name="$t('message.applicationForm.founded')+ (sending ? '*' : '' )"
        :state="$v.dates.foundedDate.$dirty && $v.dates.foundedDate.$error ? false : null"
        @blur="formatDate()" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-model="spaceUtilization"
        :name="$t('message.applicationForm.spaceUtilisation') + (sending ? '*' : '' )"
        :state="$v.spaceUtilization.$dirty && $v.spaceUtilization.$error ? false : null" />
    </div>
  </div>
</template>

<script>
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import { mapMutations, mapGetters } from 'vuex'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { validation, isZipValid } from '@/mixins/validation'
import { required } from 'vuelidate/lib/validators'
import CoozzyFormCountrySelect from '@/framework/components/form/select/CoozzyFormCountrySelect'
import CoozzyFormRegionSelect from '@/framework/components/form/select/CoozzyFormRegionSelect'
import { user } from '@/mixins/user'
import * as Sentry from '@sentry/vue'
import { address } from '@/mixins/address'

function isUndifinedValue(value, vm) {
  return value !== 'UNDEFINED_VALUE'
}

export default {
  name: 'ApplicationFormCompanyBasic',
  components: { CoozzyFormRegionSelect, CoozzyFormCountrySelect, CoozzyFormInput, CoozzyFormSelect },
  mixins: [user, validation, address],
  props: {
    company: {
      type: Object,
      default: null
    },
    sending: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCitiesSuggested: false,
      citiesValue: [],
      dates: {
        foundedDate: '',
        desiredStartOfContract: null
      },
      citySelect: null
    }
  },
  computed: {
    ...mapGetters('applicationForm', ['getViewingDone', 'getDesiredStartOfContract']),
    zipLength() {
      if (this.company) {
        const country = this.company.address.country
        if (country === 'CH') {
          return 4
        } else if (country === 'DE') {
          return 5
        } else {
          return 15
        }
      }
      return -1
    },
    name: {
      get() {
        return this.company.name
      },
      set(value) {
        const com = this.company
        com.name = value
      }
    },
    street: {
      get() {
        return this.company.address.street
      },
      set(value) {
        const com = this.company
        com.address.street = value
        this.updateCompany(com)
      }
    },
    preferredLanguage: {
      get() {
        return this.company.preferredLanguage
      },
      set(value) {
        const com = this.company
        com.preferredLanguage = value
        if (com.preferredLanguage === 'PREFERRED_LANGUAGE_UNDEFINED' || com.preferredLanguage === 'PREFERRED_LANGUAGE_GERMAN') {
          this.$store.dispatch('internationalization/changeCurrentLanguage', 'de').then()
        } else if (com.preferredLanguage === 'PREFERRED_LANGUAGE_ENGLISH') {
          this.$store.dispatch('internationalization/changeCurrentLanguage', 'en').then()
        } else {
          this.$store.dispatch('internationalization/changeCurrentLanguage', 'it').then()
        }
        this.updateCompany(com)
      }
    },
    streetNumber: {
      get() {
        return this.company.address.streetNumber
      },
      set(value) {
        const com = this.company
        com.address.streetNumber = value
        this.updateCompany(com)
      }
    },
    zip: {
      get() {
        return this.company.address.zip
      },
      set(value) {
        const com = this.company
        com.address.zip = value
        this.updateCompany(com)
      }
    },
    city: {
      get() {
        return this.company.address.city
      },
      set(value) {
        const com = this.company
        com.address.city = value
        this.updateCompany(com)
      }
    },
    country: {
      get() {
        return this.company.address.country
      },
      set(value) {
        const com = this.company
        com.address.country = value
        this.updateCompany(com)
      }
    },
    state: {
      get() {
        return this.company.address.state
      },
      set(value) {
        const com = this.company
        com.address.state = value
        this.updateCompany(com)
      }
    },
    vatLiable: {
      get() {
        return this.company.vatLiable
      },
      set(value) {
        const com = this.company
        com.vatLiable = value
        this.updateCompany(com)
      }
    },
    website: {
      get() {
        return this.company.website
      },
      set(value) {
        const com = this.company
        com.website = value
        this.updateCompany(com)
      }
    },
    purpose: {
      get() {
        return this.company.purpose
      },
      set(value) {
        const com = this.company
        com.purpose = value
        this.updateCompany(com)
      }
    },
    spaceUtilization: {
      get() {
        return this.company.spaceUtilization
      },
      set(value) {
        const com = this.company
        com.spaceUtilization = value
        this.updateCompany(com)
      }
    },
    tradeRegisterEntry: {
      get() {
        return this.company.tradeRegisterEntry
      },
      set(value) {
        const com = this.company
        com.tradeRegisterEntry = value
        this.updateCompany(com)
      }
    },
    viewingDone: {
      get() {
        return this.getViewingDone() === true ? 'yes' : 'no'
      },
      set(value) {
        value === 'yes' ? this.setViewingDone(true) : this.setViewingDone(false)
      }
    },
    desiredStartOfContract: {
      get() {
        return this.getDesiredStartOfContract()
      },
      set(value) {
        this.setDesiredStartOfContract(value)
      }
    }
  },
  mounted() {
    this.setUserpreferredLanguage()
    this.dates.foundedDate = this.company?.founded?.year
      ? `${this.company.founded.year}-${this.company.founded.month.toString().padStart(2, '0')}-${this.company.founded.day.toString().padStart(2, '0')}`
      : null
    this.dates.desiredStartOfContract = this.desiredStartOfContract?.year && this.desiredStartOfContract.month?.toString() && this.desiredStartOfContract.day?.toString() ? `${this.desiredStartOfContract.year}-${this.desiredStartOfContract.month.toString().padStart(2, '0')}-${this.desiredStartOfContract.day.toString().padStart(2, '0')}` : null
  },
  methods: {
    ...mapMutations('applicationForm', ['updateCompany', 'setViewingDone', 'setDesiredStartOfContract']),
    setUserpreferredLanguage() {
      const userPreferredLanguage = this.currentLanguage
      if (userPreferredLanguage === 'en') {
        this.preferredLanguage = 'PREFERRED_LANGUAGE_ENGLISH'
      } else if (userPreferredLanguage === 'it') {
        this.preferredLanguage = 'PREFERRED_LANGUAGE_ITALIAN'
      } else if (userPreferredLanguage === 'fr') {
        this.preferredLanguage = 'PREFERRED_LANGUAGE_FRENCH'
      } else if (userPreferredLanguage === 'de') {
        this.preferredLanguage = 'PREFERRED_LANGUAGE_GERMAN'
      }
    },
    tabClicked() {
      if (this.citiesValue.length > 0) {
        this.citySelect = this.citiesValue[0].city
        this.cityChanged(this.citiesValue[0])
      }
    },
    formatDate() {
      if (this.dates.foundedDate) {
        const date = new Date(this.dates.foundedDate)
        this.company.founded = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      }
      if (this.dates.desiredStartOfContract) {
        const date = new Date(this.dates.desiredStartOfContract)
        this.desiredStartOfContract = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      }
      this.updateCompany(this.company)
    },
    cityChanged(event) {
      this.company.address.city = event.city
      this.company.address.state = event.state
    },
    async getCityFromZip() {
      if (this.company.address.zip.length > 3 && this.company.address.country === 'CH') {
        const zipData = await this.getZipInfo(this.company.address.zip)
        if (zipData) {
          this.company.address.state = zipData.state
          if (zipData.cities.length > 1) {
            this.isCitiesSuggested = true
            this.citiesValue = zipData.cities
          } else {
            this.isCitiesSuggested = false
            this.company.address.city = zipData.cities[0].city
          }
        }
      }
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        Sentry.setExtra('companyBasicValidation', this.$v)
        this.scrollToError()
        return true
      }
      return false
    }
  },
  validations() {
    const validationBasic = {
      name: {
        required
      },
      street: {
        required
      },
      streetNumber: {
        required
      },
      zip: {
        required,
        isZipValid: isZipValid(this.company ? this.company.address : null)
      },
      city: {
        required
      },
      country: {
        required
      },
      state: {
        required
      },
      purpose: {
        required
      },
      tradeRegisterEntry: {
        required,
        isUndifinedValue
      },
      vatLiable: {
        required,
        isUndifinedValue
      },
      spaceUtilization: {
        required
      },
      dates: {
        desiredStartOfContract: {
          required
        },
        foundedDate: {
          required
        }
      }
    }
    return validationBasic
  }
}
</script>

<style lang="scss" scoped>
.custom-radio {
  display: inline-block;
}
</style>
