<template>
  <div class="row">
    <div class="col-12">
      <h5>{{ $t('message.applicationForm.infoAboutTenant') }}</h5>
    </div>
    <div class="col-12 col-md-12 mb-2">
      <label
        for="preferredLanguage"
        class="mb-0">
        {{ $t('message.contact.preferredLanguage') }}
      </label>
      <coozzy-form-select
        id="preferredLanguage"
        v-model="preferredLanguage">
        <option value="PREFERRED_LANGUAGE_GERMAN">
          {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_GERMAN') }}
        </option>
        <option value="PREFERRED_LANGUAGE_ENGLISH">
          {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_ENGLISH') }}
        </option>
        <option value="PREFERRED_LANGUAGE_ITALIAN">
          {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_ITALIAN') }}
        </option>
        <option value="PREFERRED_LANGUAGE_FRENCH">
          {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_FRENCH') }}
        </option>
      </coozzy-form-select>
    </div>
    <!-- sameAsMainIndividual -->
    <div
      v-if="mainIndividual.internalId !== individualId"
      class="col-12 mb-4">
      <coozzy-form-checkbox
        :id="'check_' + individualId"
        v-model="sameAddressAsMainTenant"
        :initial="sameAddressAsMainTenant"
        name="sameAddressAsMainTenant"
        class="mt-4">
        {{ $t('message.applicationForm.sameAsMainIndividual') }}
      </coozzy-form-checkbox>
    </div>
    <template v-if="!isMainIndividual && individualId">
      <div class="col-12">
        <coozzy-form-input
          v-model="relationToMainIndividual"
          :name="$t('message.applicationForm.relationToMainIndividual') + (sending ? '*' : '' )"
          type="text"
          :state="$v.relationToMainIndividual.$dirty && $v.relationToMainIndividual.$error ? false : null" />
      </div>
    </template>
    <!-- has the viewing Done -->
    <div
      v-if="isMainIndividual"
      class="col-6 col-md-6">
      <label for="viewingDone">{{ $t('message.applicationForm.hasViewingDone') }} {{ (sending ? '*' : '' ) }}</label>
      <coozzy-form-select
        id="viewingDone"
        v-model="viewingDone">
        <option value="no">
          {{ $t('message.generic.selectField.no') }}
        </option>
        <option value="yes">
          {{ $t('message.generic.selectField.yes') }}
        </option>
      </coozzy-form-select>
    </div>
    <!-- Desired start date of the contract -->
    <div
      v-if="isMainIndividual"
      class="col-12 col-md-6">
      <coozzy-form-input
        :id="desiredStartOfContract"
        ref="desiredStartOfContract"
        v-model="dates.desiredStartOfContract"
        :filter="'formatDate'"
        :name="$t('message.applicationForm.desiredStartOfContract') + (sending ? '*' : '' )"
        :state="$v.dates.desiredStartOfContract.$dirty && $v.dates.desiredStartOfContract.$error ? false : null"
        type="date"
        @blur="dateToTimestamp()" />
    </div>
    <!-- Gender -->
    <div class="col-12">
      <label class="d-block">{{ $t('message.generic.title.title') }} {{ sending ? '*' : '' }}</label>
      <coozzy-form-radio
        v-model="gender"
        :default-model="gender"
        :name="'gender_'+individualId"
        class="mr-3"
        select-value="MALE"
        :state="$v.gender.$dirty && $v.gender.$error ? false : null">
        {{ $t('message.generic.title.mr') }}
      </coozzy-form-radio>
      <coozzy-form-radio
        v-model="gender"
        :default-model="gender"
        :name="'gender_'+individualId"
        class="mr-3"
        select-value="FEMALE">
        {{ $t('message.generic.title.mrs') }}
      </coozzy-form-radio>
      <coozzy-form-radio
        v-model="gender"
        :default-model="gender"
        :name="'gender_'+individualId"
        class="mr-3"
        select-value="OTHER">
        {{ $t('message.generic.title.divers') }}
      </coozzy-form-radio>
    </div>
    <!-- Name -->
    <div class="col-12 col-md-6">
      <coozzy-form-input
        :id="individualId"
        v-model="firstName"
        :name="$t('message.generic.firstName') + (sending ? '*' : '' )"
        :state="$v.firstName.$dirty && $v.firstName.$error ? false : null"
        type="text" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        :id="individualId"
        v-model="lastName"
        :name="$t('message.generic.lastName') + (sending ? '*' : '' )"
        :state="$v.lastName.$dirty && $v.lastName.$error ? false : null"
        type="text" />
    </div>
    <!-- Address -->
    <div class="col-12 col-md-6">
      <coozzy-form-input
        :id="individualId"
        v-model="street"
        :disabled="sameAddressAsMainTenant"
        :name="$t('message.generic.street') + (sending ? '*' : '' )"
        :state="$v.street.$dirty && $v.street.$error ? false : null"
        type="text" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        :id="individualId"
        v-model="streetNumber"
        :disabled="sameAddressAsMainTenant"
        :name="$t('message.generic.streetNumber') + (sending ? '*' : '' )"
        :state="$v.streetNumber.$dirty && $v.streetNumber.$error ? false : null"
        type="text" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        :id="individualId"
        v-model="zip"
        :disabled="sameAddressAsMainTenant"
        :type="(country === 'CH' || country === 'DE') ? 'number': 'text'"
        :limit="zipLength"
        :name="$t('message.generic.zip') + (sending ? '*' : '' )"
        :state="$v.zip.$dirty && $v.zip.$error ? false : null"
        @input="getCityFromZip"
        @keydown.tab="isCitiesSuggested ? tabClicked() : ''" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        v-if="!isCitiesSuggested"
        :id="individualId"
        v-model="city"
        :disabled="sameAddressAsMainTenant"
        :name="$t('message.generic.city') + (sending ? '*' : '' )"
        :state="$v.city.$dirty && $v.city.$error ? false : null"
        type="text" />
      <template v-else>
        <label for="type">{{ $t('message.generic.city') }}*</label>
        <coozzy-form-select
          id="city"
          v-model="citySelect"
          :state="$v.city.$dirty && $v.city.$error ? false : null"
          @change="cityChanged">
          <option
            v-for="(item, typeIndex) in citiesValue"
            :key="typeIndex"
            :value="item"
            :selected="typeIndex === 0">
            {{ item.city }}
          </option>
        </coozzy-form-select>
      </template>
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-country-select
        :id="individualId"
        v-model="country"
        :initial-value="country"
        :disabled="sameAddressAsMainTenant"
        :state="$v.country.$dirty && $v.country.$error ? false : null"
        :required="true" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-region-select
        :id="individualId"
        v-model="state"
        :initial-value="state"
        :disabled="sameAddressAsMainTenant"
        :state="$v.state.$dirty && $v.state.$error ? false : null"
        :country="country" />
    </div>
    <!-- Birth -->
    <div class="col-12 col-md-6">
      <coozzy-form-input
        :id="individualId"
        ref="dob"
        v-model="dates.dob"
        :filter="'formatDate'"
        :name="$t('message.generic.birthday') + (sending ? '*' : '' )"
        type="date"
        :state="$v.dates.dob.$dirty && $v.dates.dob.$error ? false : null"
        @blur="dateToTimestamp()" />
    </div>
    <div class="col-12 col-md-6">
      <label :for="`nationality${individualId}`">{{ $t('message.generic.nationality.title') }} {{ (sending ? '*' : '' ) }}</label>
      <coozzy-form-select
        :id="`nationality${individualId}`"
        v-model="nationality"
        :state="$v.nationality.$dirty && $v.nationality.$error ? false : null">
        <option :value="null">
          -
        </option>
        <option value="CH">
          {{ $t('message.generic.nationality.switzerland') }}
        </option>
        <option value="FOREIGN_CITIZENSHIP">
          {{ $t('message.generic.nationality.foreignCitizenship') }}
        </option>
      </coozzy-form-select>
    </div>
    <!-- Residence status -->
    <div
      v-if="nationality === 'FOREIGN_CITIZENSHIP'"
      class="col-12 col-md-3">
      <label :for="`residenceStatus${individualId}`">{{ $t('message.generic.residenceStatus') }} {{ (sending ? '*' : '' ) }}</label>
      <coozzy-form-select
        :id="`residenceStatus${individualId}`"
        v-model="residenceStatus"
        :state="$v.residenceStatus.$dirty && $v.residenceStatus.$error ? false : null">
        <option value="UNDEFINED_RESIDENCE_STATUS">
          -
        </option>
        <option value="RESIDENCE_STATUS_B_STAY">
          {{ $t('message.generic.residenceStatusB') }}
        </option>
        <option value="RESIDENCE_STATUS_C_SETTLE">
          {{ $t('message.generic.residenceStatusC') }}
        </option>
        <option value="RESIDENCE_STATUS_CI_STAY_EMPLOYMENT">
          {{ $t('message.generic.residenceStatusCi') }}
        </option>
        <option value="RESIDENCE_STATUS_G_BORDER_CROSSER">
          {{ $t('message.generic.residenceStatusG') }}
        </option>
        <option value="RESIDENCE_STATUS_L_SHORT_STAY">
          {{ $t('message.generic.residenceStatusL') }}
        </option>
        <option value="RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER">
          {{ $t('message.generic.residenceStatusF') }}
        </option>
        <option value="RESIDENCE_STATUS_N_SEEKING_ASYLUM">
          {{ $t('message.generic.residenceStatusN') }}
        </option>
        <option value="RESIDENCE_STATUS_S_PROTECTION_NEEDED">
          {{ $t('message.generic.residenceStatusS') }}
        </option>
      </coozzy-form-select>
    </div>
    <div
      v-if="nationality === 'FOREIGN_CITIZENSHIP' && (!residenceStatus || residenceStatus !== 'UNDEFINED_RESIDENCE_STATUS')"
      class="col-12 col-md-3">
      <coozzy-form-input
        :id="individualId"
        v-model="dates.residenceStatusValidUntil"
        :filter="'formatDate'"
        :name="$t('message.generic.validUntil') + (sending ? '*' : '' )"
        :state="$v.dates.residenceStatusValidUntil.$dirty && $v.dates.residenceStatusValidUntil.$error ? false : null"
        type="date"
        @blur="dateToTimestamp()" />
    </div>
    <div
      v-else-if="nationality === 'FOREIGN_CITIZENSHIP'"
      class="col-12 col-md-3" />
    <!-- Contact info (phone, email) -->
    <div class="col-12 col-md-6">
      <coozzy-form-input
        :id="individualId"
        v-model="email"
        :name="$t('message.generic.email') + (sending ? '*' : '' )"
        :state="$v.email.$dirty && $v.email.$error ? false : null"
        type="text" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        :id="individualId"
        v-model="phoneNumber"
        :name="$t('message.generic.phone.home1')"
        type="text" />
    </div>
    <div class="col-12 col-md-6">
      <coozzy-form-input
        :id="individualId"
        v-model="mobileNumber"
        :name="$t('message.generic.phone.mobile') + (sending ? '*' : '' )"
        :state="$v.mobileNumber.$dirty && $v.mobileNumber.$error ? false : null"
        type="text" />
    </div>
    <!-- Civil status -->
    <div class="col-12 col-md-6">
      <label :for="`civilStatus${individualId}`">{{ $t('message.applicationForm.civilStatus.title') }} </label>
      <coozzy-form-select
        :id="`civilStatus${individualId}`"
        v-model="civilStatus">
        <option value="UNDEFINED_CIVIL_STATUS">
          -
        </option>
        <option value="CIVIL_STATUS_SINGLE">
          {{ $t('message.applicationForm.civilStatus.single') }}
        </option>
        <option value="CIVIL_STATUS_MARRIED">
          {{ $t('message.applicationForm.civilStatus.married') }}
        </option>
        <option value="CIVIL_STATUS_DIVORCED">
          {{ $t('message.applicationForm.civilStatus.divorced') }}
        </option>
        <option value="CIVIL_STATUS_WIDOWED">
          {{ $t('message.applicationForm.civilStatus.widowed') }}
        </option>
        <option value="CIVIL_STATUS_SEPARATED">
          {{ $t('message.applicationForm.civilStatus.separated') }}
        </option>
        <option value="CIVIL_STATUS_REGISTERED_PARTNERSHIP">
          {{ $t('message.applicationForm.civilStatus.registeredPartnership') }}
        </option>
        <option value="CIVIL_STATUS_DISSOLVED_PARTNERSHIP">
          {{ $t('message.applicationForm.civilStatus.dissolvedPartnership') }}
        </option>
      </coozzy-form-select>
    </div>
    <!-- preferredCommunication -->
    <div
      class="col-12"
      :class="[nationality === 'FOREIGN_CITIZENSHIP' ? 'col-md-6' : ' col-md-3']">
      <label :for="`preferredCommunication${individualId}`">{{ $t('message.contact.preferredCommunicationChannel') }}</label>
      <coozzy-form-select
        :id="`preferredCommunication${individualId}`"
        v-model="preferredCommunicationChannel">
        <option value="PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED">
          -
        </option>
        <option value="PREFERRED_COMMUNICATION_CHANNEL_EMAIL">
          {{ $t('message.contact.preferredCommunicationChannelType.email') }}
        </option>
        <option value="PREFERRED_COMMUNICATION_CHANNEL_LETTER">
          {{ $t('message.contact.preferredCommunicationChannelType.letter') }}
        </option>
      </coozzy-form-select>
    </div>
    <div
      class="col-12 col-md-6"
      :class="[nationality === 'FOREIGN_CITIZENSHIP' ? '' : 'offset-md-3']">
      <div class="row">
        <div class="col-md-6">
          <label :for="`pets${individualId}`">{{ $t('message.applicationForm.pets') }}</label>
          <coozzy-form-select
            :id="`pets${individualId}`"
            v-model="pets">
            <option value="UNDEFINED_VALUE">
              -
            </option>
            <option value="APPLIES">
              {{ $t('message.generic.selectField.yes') }}
            </option>
            <option value="DOES_NOT_APPLY">
              {{ $t('message.generic.selectField.no') }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-md-6">
          <coozzy-form-input
            v-if="pets === 'APPLIES'"
            :id="individualId"
            v-model="petsDescription"
            :name="$t('message.applicationForm.petsDescription')"
            type="text" />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <label :for="`instruments${individualId}`">{{ $t('message.applicationForm.instruments') }}</label>
      <coozzy-form-select
        :id="`instruments${individualId}`"
        v-model="instruments">
        <option value="UNDEFINED_VALUE">
          -
        </option>
        <option value="APPLIES">
          {{ $t('message.generic.selectField.yes') }}
        </option>
        <option value="DOES_NOT_APPLY">
          {{ $t('message.generic.selectField.no') }}
        </option>
      </coozzy-form-select>
    </div>
    <div class="col-12 col-md-3">
      <coozzy-form-input
        v-if="instruments === 'APPLIES'"
        :id="individualId"
        v-model="instrumentsDescription"
        :name="$t('message.applicationForm.instrumentsDescription')"
        type="text" />
    </div>
    <div class="col-12 col-md-3">
      <label :for="`smokers${individualId}`">{{ $t('message.applicationForm.smokers') }}</label>
      <coozzy-form-select
        :id="`smokers${individualId}`"
        v-model="smokers">
        <option value="UNDEFINED_VALUE">
          -
        </option>
        <option value="APPLIES">
          {{ $t('message.generic.selectField.yes') }}
        </option>
        <option value="DOES_NOT_APPLY">
          {{ $t('message.generic.selectField.no') }}
        </option>
      </coozzy-form-select>
    </div>
  </div>
</template>

<script>
import CoozzyFormRadio from '@/framework/components/form/radio/CoozzyFormRadio'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import { mapGetters, mapMutations } from 'vuex'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { noFutureDateObject, validation, isZipValid } from '@/mixins/validation'
import { required, email } from 'vuelidate/lib/validators'
import { user } from '@/mixins/user'
import * as Sentry from '@sentry/vue'
import { address } from '@/mixins/address'

function notUndefined(value, vm) {
  return value !== 'UNDEFINED_RESIDENCE_STATUS'
}

export default {
  name: 'ApplicationFormIndividualBasic',
  components: { CoozzyFormSelect, CoozzyFormInput, CoozzyFormRadio },
  mixins: [user, validation, address],
  props: {
    individualId: {
      type: String,
      required: true
    },
    sending: {
      type: Boolean,
      default: false
    },
    changedAddress: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isCitiesSuggested: false,
      citiesValue: [],
      dates: {
        dob: null,
        residenceStatusValidUntil: null,
        desiredStartOfContract: null
      },
      sameAddressAsMainTenant: false,
      citySelect: null
    }
  },
  computed: {
    ...mapGetters('applicationForm', ['getIndividualByInternalId', 'getMainIndividual', 'getViewingDone', 'getDesiredStartOfContract']),
    individual() {
      return this.getIndividualByInternalId(this.individualId)
    },
    mainIndividual() {
      return this.getMainIndividual()
    },
    isMainIndividual() {
      return this.individual.mainIndividual
    },
    zipLength() {
      if (this.individual) {
        const country = this.individual.address.country
        if (country === 'CH') {
          return 4
        } else if (country === 'DE') {
          return 5
        } else {
          return 15
        }
      }
      return -1
    },
    relationToMainIndividual: {
      get() {
        return this.individual.tenancy.relationToMainIndividual
      },
      set(value) {
        const i = this.individual
        i.tenancy.relationToMainIndividual = value
        this.updateIndividual(i)
      }
    },
    gender: {
      get() {
        return this.individual.gender
      },
      set(value) {
        const ind = this.individual
        ind.gender = value
        this.updateIndividual(ind)
      }
    },
    firstName: {
      get() {
        return this.individual.firstName
      },
      set(value) {
        const ind = this.individual
        ind.firstName = value
        this.updateIndividual(ind)
      }
    },
    lastName: {
      get() {
        return this.individual.lastName
      },
      set(value) {
        const ind = this.individual
        ind.lastName = value
        this.updateIndividual(ind)
      }
    },
    street: {
      get() {
        return this.individual.address.street
      },
      set(value) {
        const ind = this.individual
        ind.address.street = value
        this.updateIndividual(ind)
      }
    },
    streetNumber: {
      get() {
        return this.individual.address.streetNumber
      },
      set(value) {
        const ind = this.individual
        ind.address.streetNumber = value
        this.updateIndividual(ind)
      }
    },
    zip: {
      get() {
        return this.individual.address.zip
      },
      set(value) {
        const ind = this.individual
        ind.address.zip = value
        this.updateIndividual(ind)
      }
    },
    city: {
      get() {
        return this.individual.address.city
      },
      set(value) {
        const ind = this.individual
        ind.address.city = value
        this.updateIndividual(ind)
      }
    },
    country: {
      get() {
        return this.individual.address.country
      },
      set(value) {
        const ind = this.individual
        ind.address.country = value
        this.updateIndividual(ind)
      }
    },
    state: {
      get() {
        return this.individual.address.state
      },
      set(value) {
        const ind = this.individual
        ind.address.state = value
        this.updateIndividual(ind)
      }
    },
    dob: {
      get() {
        return this.individual.dob
      },
      set(value) {
        const ind = this.individual
        ind.dob = value
        this.updateIndividual(ind)
      }
    },
    nationality: {
      get() {
        return this.individual.nationality
      },
      set(value) {
        const ind = this.individual
        ind.nationality = value
        this.updateIndividual(ind)
      }
    },
    residenceStatus: {
      get() {
        return this.individual.residenceStatus ? this.individual.residenceStatus : 'UNDEFINED_RESIDENCE_STATUS'
      },
      set(value) {
        const ind = this.individual
        ind.residenceStatus = value
        this.updateIndividual(ind)
      }
    },
    residenceStatusValidUntil: {
      get() {
        return this.individual.residenceStatusValidUntil
      },
      set(value) {
        const ind = this.individual
        ind.residenceStatusValidUntil = value
        this.updateIndividual(ind)
      }
    },
    email: {
      get() {
        return this.individual.email
      },
      set(value) {
        const ind = this.individual
        ind.email = value
        this.updateIndividual(ind)
      }
    },
    phoneNumber: {
      get() {
        return this.individual.phoneNumber
      },
      set(value) {
        const ind = this.individual
        ind.phoneNumber = value
        this.updateIndividual(ind)
      }
    },
    mobileNumber: {
      get() {
        return this.individual.mobileNumber
      },
      set(value) {
        const ind = this.individual
        ind.mobileNumber = value
        this.updateIndividual(ind)
      }
    },
    civilStatus: {
      get() {
        return this.individual.civilStatus
      },
      set(value) {
        const ind = this.individual
        ind.civilStatus = value
        this.updateIndividual(ind)
      }
    },
    pets: {
      get() {
        return this.individual.household.pets
      },
      set(value) {
        const ind = this.individual
        ind.household.pets = value
        this.updateIndividual(ind)
      }
    },
    petsDescription: {
      get() {
        return this.individual.household.petsDescription
      },
      set(value) {
        const ind = this.individual
        ind.household.petsDescription = value
        this.updateIndividual(ind)
      }
    },
    instruments: {
      get() {
        return this.individual.household.instruments
      },
      set(value) {
        const ind = this.individual
        ind.household.instruments = value
        this.updateIndividual(ind)
      }
    },
    instrumentsDescription: {
      get() {
        return this.individual.household.instrumentsDescription
      },
      set(value) {
        const ind = this.individual
        ind.household.instrumentsDescription = value
        this.updateIndividual(ind)
      }
    },
    smokers: {
      get() {
        return this.individual.household.smokers
      },
      set(value) {
        const ind = this.individual
        ind.household.smokers = value
        this.updateIndividual(ind)
      }
    },
    preferredCommunicationChannel: {
      get() {
        return this.individual.preferredCommunicationChannel
      },
      set(value) {
        const ind = this.individual
        ind.preferredCommunicationChannel = value
        this.updateIndividual(ind)
      }
    },
    viewingDone: {
      get() {
        return this.getViewingDone() === true ? 'yes' : 'no'
      },
      set(value) {
        value === 'yes' ? this.setViewingDone(true) : this.setViewingDone(false)
      }
    },
    preferredLanguage: {
      get() {
        return this.individual.preferredLanguage
      },
      set(value) {
        const ind = this.individual
        ind.preferredLanguage = value
        if (ind.preferredLanguage === 'PREFERRED_LANGUAGE_UNDEFINED' || ind.preferredLanguage === 'PREFERRED_LANGUAGE_GERMAN') {
          this.$store.dispatch('internationalization/changeCurrentLanguage', 'de').then()
        } else if (ind.preferredLanguage === 'PREFERRED_LANGUAGE_ENGLISH') {
          this.$store.dispatch('internationalization/changeCurrentLanguage', 'en').then()
        } else {
          this.$store.dispatch('internationalization/changeCurrentLanguage', 'it').then()
        }
        this.updateIndividual(ind)
      }
    },
    desiredStartOfContract: {
      get() {
        return this.getDesiredStartOfContract()
      },
      set(value) {
        this.setDesiredStartOfContract(value)
      }
    }
  },
  watch: {
    changedAddress: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (this.sameAddressAsMainTenant) {
          this.copyAddress()
        }
      }
    },
    sameAddressAsMainTenant: function (newVal) {
      if (newVal === true) {
        this.copyAddress()
        const ind = this.individual
        ind.tenancy.sameAsMainIndividual = newVal
        this.updateIndividual(ind)
      } else {
        const ind = this.individual
        ind.tenancy.sameAsMainIndividual = newVal
        this.updateIndividual(ind)
      }
    },
    street: function (newVal) {
      if (this.isMainIndividual) {
        this.$emit('address-changed', newVal)
      }
    },
    streetNumber: function (newVal) {
      if (this.isMainIndividual) {
        this.$emit('address-changed', newVal)
      }
    },
    zip: function (newVal) {
      if (this.isMainIndividual) {
        this.$emit('address-changed', newVal)
      }
    },
    city: function (newVal) {
      if (this.isMainIndividual) {
        this.$emit('address-changed', newVal)
      }
    },
    country: function (newVal) {
      if (this.isMainIndividual) {
        this.$emit('address-changed', newVal)
      }
    },
    state: function (newVal) {
      if (this.isMainIndividual) {
        this.$emit('address-changed', newVal)
      }
    },
    'individual.nationality': function (newVal) {
      if (newVal !== 'FOREIGN_CITIZENSHIP') {
        this.residenceStatus = null
        this.residenceStatusValidUntil = null
        this.dates.residenceStatusValidUntil = null
      }
    }
  },
  mounted() {
    this.setUserpreferredLanguage()
    this.dates.dob = this.dob?.year ? `${this.dob.year}-${this.dob.month.toString().padStart(2, '0')}-${this.dob.day.toString().padStart(2, '0')}` : null
    this.dates.residenceStatusValidUntil = this.residenceStatusValidUntil?.year ? `${this.residenceStatusValidUntil.year}-${this.residenceStatusValidUntil.month.toString().padStart(2, '0')}-${this.residenceStatusValidUntil.day.toString().padStart(2, '0')}` : null
    this.dates.desiredStartOfContract = this.desiredStartOfContract?.year && this.desiredStartOfContract.month?.toString() && this.desiredStartOfContract.day?.toString() ? `${this.desiredStartOfContract.year}-${this.desiredStartOfContract.month.toString().padStart(2, '0')}-${this.desiredStartOfContract.day.toString().padStart(2, '0')}` : null
  },
  methods: {
    ...mapMutations('applicationForm', ['updateIndividual', 'setViewingDone', 'setDesiredStartOfContract']),
    setUserpreferredLanguage() {
      const userPreferredLanguage = this.currentLanguage
      if (userPreferredLanguage === 'en') {
        this.preferredLanguage = 'PREFERRED_LANGUAGE_ENGLISH'
      } else if (userPreferredLanguage === 'it') {
        this.preferredLanguage = 'PREFERRED_LANGUAGE_ITALIAN'
      } else if (userPreferredLanguage === 'fr') {
        this.preferredLanguage = 'PREFERRED_LANGUAGE_FRENCH'
      } else if (userPreferredLanguage === 'de') {
        this.preferredLanguage = 'PREFERRED_LANGUAGE_GERMAN'
      }
    },
    tabClicked() {
      if (this.citiesValue.length > 0) {
        this.citySelect = this.citiesValue[0].city
        this.cityChanged(this.citiesValue[0])
      }
    },
    copyAddress() {
      // this.$nextTick(() => {
      this.street = this.mainIndividual.address.street
      this.streetNumber = this.mainIndividual.address.streetNumber
      this.zip = this.mainIndividual.address.zip
      this.city = this.mainIndividual.address.city
      this.country = this.mainIndividual.address.country
      this.$nextTick(() => {
        this.state = this.mainIndividual.address.state
      })
    },
    dateToTimestamp() {
      if (this.dates.dob) {
        const date = new Date(this.dates.dob)
        this.dob = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      }
      if (this.dates.residenceStatusValidUntil !== null) {
        const dateResidenceStatusValidUntil = new Date(this.dates.residenceStatusValidUntil)
        this.residenceStatusValidUntil = {
          year: dateResidenceStatusValidUntil.getFullYear(),
          month: dateResidenceStatusValidUntil.getMonth() + 1,
          day: dateResidenceStatusValidUntil.getDate()
        }
      }
      if (this.dates.desiredStartOfContract) {
        const date = new Date(this.dates.desiredStartOfContract)
        this.desiredStartOfContract = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate()
        }
      }
    },
    cityChanged(event) {
      this.city = event.city
      this.state = event.state
    },
    async getCityFromZip() {
      if (this.zip.length > 3 && this.country === 'CH') {
        const zipData = await this.getZipInfo(this.zip)
        if (zipData) {
          this.state = zipData.state
          if (zipData.cities.length > 1) {
            this.isCitiesSuggested = true
            this.citiesValue = zipData.cities
          } else {
            this.isCitiesSuggested = false
            this.$nextTick(() => {
              this.city = zipData.cities[0].city
            })
          }
        }
      }
    },
    residenceStatusValidUntilTimeStamp() {
      const date = new Date(this.dates.residenceStatusValidUntil)
      this.residenceStatusValidUntil = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        Sentry.setExtra('individualBasicValidation-' + this.individualId, this.$v)
        this.scrollToError()
        return true
      }
      return false
    }
  },
  validations() {
    const validationBasic = {}
    validationBasic.gender = {
      required
    }
    validationBasic.firstName = {
      required
    }
    validationBasic.lastName = {
      required
    }
    validationBasic.street = {
      required
    }
    validationBasic.streetNumber = {
      required
    }
    validationBasic.zip = {
      required,
      isZipValid: isZipValid(this.individual.address)
    }
    validationBasic.city = {
      required
    }
    validationBasic.country = {
      required
    }
    validationBasic.state = {
      required
    }
    validationBasic.nationality = {
      required
    }
    validationBasic.email = {
      email,
      required
    }
    validationBasic.mobileNumber = {
      required
    }
    if (this.isMainIndividual) {
      validationBasic.dates = {
        desiredStartOfContract: {
          required
        },
        dob: {
          required, noFutureDateObject
        }
      }
    } else {
      validationBasic.dates = {
        dob: {
          required, noFutureDateObject
        }
      }
    }
    if (!this.isMainIndividual) {
      validationBasic.relationToMainIndividual = {
        required
      }
    }
    if (this.nationality === 'FOREIGN_CITIZENSHIP') {
      validationBasic.residenceStatus = {
        required,
        notUndefined
      }
      validationBasic.dates = {
        desiredStartOfContract: {
          required
        },
        dob: {
          required, noFutureDateObject
        },
        residenceStatusValidUntil: {
          required
        }
      }
    }
    return validationBasic
  }
}
</script>

<style lang="scss" scoped>
.custom-radio {
  display: inline-block;
}
</style>
