<template>
  <section>
    <b-modal
      id="modal-add-event"
      ref="modal-add-event"
      :modal-class="'has-confirmation-modal'"
      no-close-on-backdrop
      hide-footer>
      <div
        slot="modal-header"
        class="w-100">
        <h5
          v-if="isEditable"
          class="mb-0 d-inline normal modal-title">
          {{ $t('message.calendar.event.create') }}
        </h5>
        <h5
          v-else
          class="mb-0 d-inline modal-title">
          {{ $t('message.calendar.event.edit') }}
        </h5>
        <coozzy-button
          design="transparent"
          class="float-right"
          @click="hideModal()">
          <img
            class="close-img"
            src="@/assets/icon_close.svg"
            alt="Placeholder">
        </coozzy-button>
        <coozzy-button
          v-if="eventDetail !== null && canBeEdited && isOwner && allowedToDelete"
          design="transparent"
          class="float-right"
          @click="showDeleteConfirmationModal">
          <coozzy-delete-icon />
        </coozzy-button>
        <coozzy-button
          v-if="!isEditable && canBeEdited && isOwner && allowedToEdit"
          class="float-right"
          design="transparent"
          @click="editEvent(true, null)">
          <coozzy-edit-icon />
        </coozzy-button>
      </div>
      <div
        v-if="loading"
        class="d-block text-center text-danger my-2">
        <coozzy-spinner />
      </div>
      <div
        v-else
        class="d-block">
        <div class="row mb-2">
          <div class="col">
            <label>{{ $t('message.navigation.calendar.title') }} </label>
            <coozzy-form-select
              v-if="isEditable"
              v-model="organizer"
              :disabled="eventDetail !== null"
              name="numberEntries"
              class="select-entries float-right">
              <option
                v-for="(employee, index) in availableEmployees"
                :key="index"
                :value="employee">
                {{ employee.firstName }} {{ employee.lastName }}
              </option>
            </coozzy-form-select>
            <p
              v-else
              class="show-data">
              {{ organizer ? getOrganizer : '' }}
            </p>
          </div>
          <div
            v-if="!isAdminModule && source !== 'contact'"
            class="col">
            <label>{{ $t('message.calendar.event.objectReference') }} </label>
            <p v-if="(!isEditable && event.referenceIds.filter(x => x.startsWith('req')).length > 0)">
              <a
                role="button"
                tabindex="0"
                class="lh-1"
                @click="redirectToRequest"
                @click.middle="redirectToRequestMiddleClick">
                {{ objectName }}
              </a>
            </p>
            <coozzy-form-input
              v-else-if="(objectReference && isEditable) || event.referenceIds.filter(x => x.startsWith('req')).length > 0"
              v-model="objectName"
              disabled
              type="text" />
            <p
              v-else-if="event.referenceIds.length === 0 && !isEditable"
              class="show-data">
              {{ $t('message.contact.types.NONE') }}
            </p>
            <a
              v-else-if="(event.referenceIds.length > 0 || objectReference) && !isEditable"
              class="show-data d-block"
              @click="objectUrl"
              @click.middle="objectUrlMiddleClick">
              {{ objectName }}
            </a>
          </div>
        </div>
        <div class="row mb-2">
          <div
            v-if="source !== 'dashbord' || event.private || isEditable"
            class="col-3">
            <coozzy-form-checkbox
              v-if="source === '' || isEditable"
              id="private"
              key="private"
              v-model="event.private"
              :initial="event.private"
              :disabled="!isEditable || (organizer && organizer.id !== $store.getters['user/getUserId'])"
              class="align-self-center mt-1">
              {{ $t('message.calendar.event.privateEvent') }}
            </coozzy-form-checkbox>
            <p
              v-else-if="event.private"
              class="show-data">
              {{ $t('message.calendar.event.privateEvent') }}
            </p>
          </div>
          <div class="col-3">
            <coozzy-form-checkbox
              v-if="source === '' || isEditable"
              id="allDay"
              key="allDAy"
              v-model="event.allDay"
              :initial="event.allDay"
              :disabled="!isEditable"
              class="align-self-center mt-1">
              {{ $t('message.calendar.event.allDay') }}
            </coozzy-form-checkbox>
            <p
              v-else-if="event.allDay"
              class="show-data">
              {{ $t('message.calendar.event.allDay') }}
            </p>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <label>{{ $t('message.calendar.event.start') }}*</label>
            <coozzy-form-input
              v-if="isEditable"
              id="startDate"
              ref="startDate"
              v-model="event.startDate"
              :state="$v.event.startDate.$dirty && $v.event.startDate.$error ? false : null"
              type="date" />
            <p
              v-else
              class="show-data">
              {{ labelFormatDate(event.startDate) }}
            </p>
          </div>
          <div
            v-if="source === 'dashbord' && event.allDay"
            class="col" />
          <div
            v-else
            class="col">
            <label>{{ $t('message.calendar.event.time') }}</label>
            <coozzy-form-select
              v-if="isEditable"
              id="startTime"
              v-model="event.startTime"
              :disabled="event.allDay">
              <option
                v-for="t in timeList"
                :key="'start' + t"
                :value="t">
                {{ t }}
              </option>
            </coozzy-form-select>
            <p
              v-else
              class="show-data">
              {{ labelFormatTime(event.startTime) }}
            </p>
          </div>
          <div class="col">
            <label>{{ $t('message.calendar.event.end') }}*</label>
            <coozzy-form-input
              v-if="isEditable"
              id="endDate"
              ref="endDate"
              v-model="event.endDate"
              :state="$v.event.endDate.$dirty && $v.event.endDate.$error ? false : null"
              type="date" />
            <p
              v-else
              class="show-data">
              {{ labelFormatDate(event.endDate) }}
            </p>
          </div>
          <div
            v-if="source === 'dashbord' && event.allDay"
            class="col" />
          <div
            v-else
            class="col">
            <label>{{ $t('message.calendar.event.time') }}</label>
            <coozzy-form-select
              v-if="isEditable"
              id="endTime"
              v-model="event.endTime"
              :disabled="event.allDay">
              <option
                v-for="t in timeList"
                :key="'end' + t"
                :value="t">
                {{ t }}
              </option>
            </coozzy-form-select>
            <p
              v-else
              class="show-data">
              {{ labelFormatTime(event.endTime) }}
            </p>
          </div>
        </div>
        <!--    Recurring Events Part   -->
        <template
          v-if="!isEditable || !eventDetail || (isEditable && editAllRecurringEvents)">
          <div
            class="row mb-2">
            <div
              class="col-6">
              <coozzy-form-checkbox
                id="recurringEvent"
                key="recurringEvent"
                v-model="event.recurringEvent"
                :initial="event.recurringEvent"
                :disabled="!isEditable"
                class="align-self-center mt-1">
                {{ $t('message.calendar.event.recurringEvent.checkbox') }}
              </coozzy-form-checkbox>
            </div>
          </div>
          <div
            v-if="event.recurringEvent"
            class="row mb-2">
            <div class="col-12">
              <label for="recurrencePattern">
                {{ $t('message.calendar.event.recurringEvent.recurrencePattern.title') }}
              </label>
            </div>
            <div
              class="col-2">
              <coozzy-form-radio
                v-model="event.frequency"
                :disabled="!isEditable"
                :default-model="event.frequency"
                :select-value="'EVENT_FREQUENCY_DAILY'"
                name="frequency">
                {{ $t('message.calendar.event.recurringEvent.recurrencePattern.EVENT_FREQUENCY_DAILY') }}
              </coozzy-form-radio>
            </div>
            <div
              class="col-2">
              <coozzy-form-radio
                v-model="event.frequency"
                :disabled="!isEditable"
                :default-model="event.frequency"
                :select-value="'EVENT_FREQUENCY_WEEKLY'"
                name="frequency">
                {{ $t('message.calendar.event.recurringEvent.recurrencePattern.EVENT_FREQUENCY_WEEKLY') }}
              </coozzy-form-radio>
            </div>
            <div
              class="col-2">
              <coozzy-form-radio
                v-model="event.frequency"
                :disabled="!isEditable"
                :default-model="event.frequency"
                :select-value="'EVENT_FREQUENCY_MONTHLY'"
                name="frequency">
                {{ $t('message.calendar.event.recurringEvent.recurrencePattern.EVENT_FREQUENCY_MONTHLY') }}
              </coozzy-form-radio>
            </div>
            <div
              class="col-2">
              <coozzy-form-radio
                v-model="event.frequency"
                :disabled="!isEditable"
                :default-model="event.frequency"
                :select-value="'EVENT_FREQUENCY_YEARLY'"
                name="frequency">
                {{ $t('message.calendar.event.recurringEvent.recurrencePattern.EVENT_FREQUENCY_YEARLY') }}
              </coozzy-form-radio>
            </div>
          </div>
          <div
            v-if="event.frequency !== 'EVENT_FREQUENCY_UNDEFINED' && event.recurringEvent"
            class="row mb-2">
            <div
              class="col-3">
              <label>{{ $t('message.calendar.event.start') }}</label>
              <coozzy-form-input
                v-if="isEditable"
                id="startDateRecurrence"
                ref="startDateRecurrence"
                v-model="event.startDateRecurrence"
                :state="$v.event.startDateRecurrence.$dirty && $v.event.startDateRecurrence.$error ? false : null"
                type="date" />
              <p
                v-else
                class="show-data">
                {{ labelFormatDate(event.startDateRecurrence) }}
              </p>
            </div>
            <div
              class="col-9">
              <div class="row mt-4">
                <div
                  class="col-3">
                  <coozzy-form-radio
                    v-model="endingRecurrence"
                    :disabled="!isEditable"
                    :default-model="endingRecurrence"
                    :select-value="'endByDateRecurrence'"
                    name="endingRecurrence">
                    {{ $t('message.calendar.event.recurringEvent.rangeOfRecurrence.endBy') }}
                  </coozzy-form-radio>
                </div>
                <div
                  v-if="endingRecurrence === 'endByDateRecurrence'"
                  class="col-3">
                  <coozzy-form-input
                    v-if="isEditable"
                    id="endBy"
                    ref="endBy"
                    v-model="event.endByDateRecurrence"
                    type="date" />
                  <p
                    v-else
                    class="show-data">
                    {{ labelFormatDate(event.endByDateRecurrence) }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-3">
                  <coozzy-form-radio
                    v-model="endingRecurrence"
                    :disabled="!isEditable"
                    :default-model="endingRecurrence"
                    :select-value="'endAfter'"
                    name="endingRecurrence">
                    {{ $t('message.calendar.event.recurringEvent.rangeOfRecurrence.endAfter') }}
                  </coozzy-form-radio>
                </div>
                <div
                  v-if="endingRecurrence === 'endAfter'"
                  class="col-4 d-flex">
                  <coozzy-form-input
                    v-if="isEditable"
                    id="endAfter"
                    ref="endAfter"
                    v-model="event.countRecurrence"
                    class="mr-2"
                    :state="$v.event?.countRecurrence && $v.event.countRecurrence.$dirty && $v.event.countRecurrence.$error ? false : null"
                    type="text" />
                  <span
                    v-if="isEditable"
                    class="show-data"> {{ $t('message.calendar.event.recurringEvent.rangeOfRecurrence.occurences') }}
                  </span>
                  <p
                    v-else
                    class="show-data">
                    {{ event.countRecurrence }} {{ $t('message.calendar.event.recurringEvent.rangeOfRecurrence.occurences') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div
          v-if="!eventDetail"
          class="row mb-2">
          <div class="col">
            <label>{{ $t('message.settings.template.title') }}</label>
            <coozzy-form-select
              v-model="templateId">
              <option :value="null" />
              <option
                v-for="template in templates"
                :key="template.id"
                :value="template">
                {{ template.name }}
              </option>
            </coozzy-form-select>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <label>{{ $t('message.calendar.event.title') }}</label>
            <coozzy-form-input
              id="title"
              v-model="event.title"
              :is-read-only="!isEditable"
              type="text" />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <label>{{ $t('message.calendar.event.description') }}</label>
            <div
              v-if="!isEditable"
              v-dompurify-html="textWithLinks(event.description)"
              class="description-text" />
            <editor
              v-else
              :key="keyRefresh"
              v-model="event.description"
              :api-key="editorApi"
              :init="{
                toolbar: 'undo redo | bold italic underline forecolor | numlist bullist link table | fullscreen print',
                plugins: 'fullscreen lists code image table link',
                height: '150',
                menubar: '',
                language: currentLanguage,
                elementpath: false,
                invalid_styles: {
                  '*': 'font-family',
                },
                automatic_uploads: true,
                license_key: 'gpl',
                content_style: contentStyle,
                font_formats: fontFormats,
                branding: false,
                remove_trailing_brs: false,
                browser_spellcheck: true,
                table_toolbar: '',
                font_size_formats: fontsizeFormats,
                convert_urls: false
              }" />
          </div>
        </div>
        <div
          v-if="isEditable"
          class="row mb-2">
          <div class="col">
            <label for="file">{{ $t('message.calendar.event.attachement') }}</label>
            <coozzy-form-file-input
              id="file"
              ref="attachmentUpload"
              class=""
              multiple
              accept="text/plain, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
              ,application/vnd.ms-powerpoint,  application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf,   image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx .pdf"
              :show-name="false"
              :disabled="uploading"
              @change="addFile" />
            <div
              v-if="filteredMediasList.length > 0"
              class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <template
                      v-for="media in filteredMediasList">
                      <div
                        v-if="!(['image/jpeg', 'image/png', 'image/gif', 'video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1)"
                        :key="media.id"
                        class="col-12 p-0 square">
                        <div
                          class="file-block">
                          <a
                            data-v-09485260=""
                            tabindex="0"
                            :href="media.url"
                            target="_blank">
                            {{ media.filename }}
                          </a>
                          <coozzy-button
                            design="transparent"
                            class="delete-icon"
                            @click="deleteMedia($event, media.id)">
                            <coozzy-delete-icon />
                          </coozzy-button>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="row m-0 img">
                  <template
                    v-for="media in filteredMediasList">
                    <div
                      v-if="['image/jpeg', 'image/png', 'image/gif'].indexOf(media.mimeType) !== -1"
                      :key="media.id"
                      class="media-block p-1 col-2 square">
                      <div
                        class="uploaded-file cursor-pointer"
                        style="background-repeat: no-repeat;background-size: cover;background-position: center center;"
                        :style="{ 'background-image': 'url(' + media.url + ')' }"
                        @click="imageClicked(media)">
                        <coozzy-button
                          design="transparent"
                          class="delete-icon image"
                          @click="deleteMedia($event, media.id)">
                          <coozzy-delete-icon />
                        </coozzy-button>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="row m-0 video">
                  <template
                    v-for="media in filteredMediasList">
                    <div
                      v-if="['video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1"
                      :key="media.id"
                      class="media-block p-1 col-2 square">
                      <coozzy-thumbnail
                        v-if="getMediaUrl(media) !== null"
                        :key="media.id"
                        :video-src="getMediaUrl(media)"
                        :video-url="media.url"
                        class="uploaded-file cursor-pointer"
                        @imageClicked="openVideo(media)" />
                      <coozzy-button
                        design="transparent"
                        class="delete-icon video"
                        @click="deleteMedia($event, media.id)">
                        <coozzy-delete-icon />
                      </coozzy-button>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="filteredMediasList.length > 0"
          class="row mb-2">
          <div class="col-12">
            <label v-if="!isEditable">{{ $t('message.calendar.event.attachement') }}</label>
            <div class="row">
              <template
                v-for="media in filteredMediasList">
                <div
                  v-if="!(['image/jpeg', 'image/png', 'image/gif', 'video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1)"
                  :key="media.id"
                  class="col-12 square">
                  <div
                    class="file-block">
                    <a
                      data-v-09485260=""
                      tabindex="0"
                      :href="media.url"
                      target="_blank">
                      {{ media.filename }}
                    </a>
                  </div>
                </div>
              </template>
            </div>
            <div class="row m-0 img">
              <template
                v-for="media in filteredMediasList">
                <div
                  v-if="['image/jpeg', 'image/png', 'image/gif'].indexOf(media.mimeType) !== -1"
                  :key="media.id"
                  class="media-block p-1 col-2 square">
                  <div
                    class="uploaded-file cursor-pointer"
                    style="background-repeat: no-repeat;background-size: cover;background-position: center center;"
                    :style="{ 'background-image': 'url(' + media.url + ')' }"
                    @click="imageClicked(media)" />
                </div>
              </template>
            </div>
            <div class="row m-0 video">
              <template
                v-for="media in filteredMediasList">
                <div
                  v-if="['video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1"
                  :key="media.id"
                  class="media-block p-1 col-2 square">
                  <coozzy-thumbnail
                    v-if="getMediaUrl(media) !== null"
                    :key="media.id"
                    :video-src="getMediaUrl(media)"
                    :video-url="media.url"
                    class="uploaded-file cursor-pointer"
                    @imageClicked="openVideo(media)" />
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <label for="notification">
              {{ $t('message.calendar.event.notification') }}
            </label>
          </div>
          <div class="col-3">
            <coozzy-form-select
              v-if="isEditable"
              v-model="alarmIntervalUnit"
              name="alarmIntervalUnit">
              <option value="">
                -
              </option>
              <option value="5_MINUTE">
                5 {{ $t('message.generic.minutes') }}
              </option>
              <option value="15_MINUTE">
                15 {{ $t('message.generic.minutes') }}
              </option>
              <option value="30_MINUTE">
                30 {{ $t('message.generic.minutes') }}
              </option>
              <option value="1_HOUR">
                1 {{ $t('message.generic.hour') }}
              </option>
              <option value="6_HOUR">
                6 {{ $t('message.generic.hours') }}
              </option>
              <option value="12_HOUR">
                12 {{ $t('message.generic.hours') }}
              </option>
              <option value="1_DAY">
                1 {{ $t('message.generic.day') }}
              </option>
            </coozzy-form-select>
            <template v-else>
              <template v-if="alarmIntervalUnit === '5_MINUTE'">
                5 {{ $t('message.generic.minutes') }}
              </template>
              <template v-if="alarmIntervalUnit === '15_MINUTE'">
                15 {{ $t('message.generic.minutes') }}
              </template>
              <template v-if="alarmIntervalUnit === '30_MINUTE'">
                30 {{ $t('message.generic.minutes') }}
              </template>
              <template v-else-if="alarmIntervalUnit === '1_HOUR'">
                1 {{ $t('message.generic.hour') }}
              </template>
              <template v-else-if="alarmIntervalUnit === '6_HOUR'">
                6 {{ $t('message.generic.hours') }}
              </template>
              <template v-else-if="alarmIntervalUnit === '12_HOUR'">
                12 {{ $t('message.generic.hours') }}
              </template>
              <template v-else-if="alarmIntervalUnit === '1_DAY'">
                1 {{ $t('message.generic.day') }}
              </template>
              <template v-else-if="alarmIntervalUnit === ''">
                -
              </template>
            </template>
          </div>
        </div>
        <div class="row mb-2">
          <div
            v-if="isEditable"
            class="col-md-6 align-self-end">
            <div class="row">
              <coozzy-form-radio
                v-model="eventLocation"
                name="eventLocation"
                class="col-6 eventLocation"
                :class="eventLocation === 'onSite' ? 'selected' : ''"
                button-variant="outline"
                select-value="onSite"
                button>
                {{ $t('message.generic.onSite') }}
              </coozzy-form-radio>
              <coozzy-form-radio
                v-model="eventLocation"
                name="eventLocation"
                class="col-6 eventLocation"
                :class="eventLocation === 'inOffice' ? 'selected' : ''"
                button-variant="outline"
                select-value="inOffice"
                button>
                {{ $t('message.generic.inOffice') }}
              </coozzy-form-radio>
            </div>
          </div>
          <div
            :class="isEditable ? 'col-md-6' : 'col-12'">
            <label for="location">{{ $t('message.calendar.event.location') }}</label>
            <coozzy-form-input
              id="location"
              v-model="event.location"
              :is-read-only="!isEditable"
              type="text" />
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <template v-if="isEditable">
              <label>{{ $t('message.calendar.event.searchParticipants') }}</label>
              <ais-instant-search
                v-if="searchClient"
                :search-client="searchClient"
                :routing="routing"
                index-name="contact">
                <!-- eslint-disable vue/attribute-hyphenation -->
                <ais-configure
                  :filters="'isOwner:false AND active:true'"
                  :hitsPerPage="10" />
                <!-- eslint-enable vue/attribute-hyphenation -->
                <ais-autocomplete>
                  <template slot-scope="{ indices, refine }">
                    <vue-autosuggest
                      ref="autosuggest"
                      v-model="searchPartipant"
                      :suggestions="indicesToSuggestions(indices)"
                      :input-props="{id:'autosuggest__input', class: searchPartipantValidation || searchPartipant === '' ? 'form-control-sm default-border': 'form-control-sm red-border' }"
                      @selected="selectHandler"
                      @keyup.enter="checkEnter"
                      @input="onChange(refine,searchPartipant)">
                      <template slot-scope="{ suggestion }">
                        <span class="my-suggestion-item">{{ suggestion.item.name }} {{ suggestion.item.email ? '- ' + suggestion.item.email : '' }}</span>
                      </template>
                    </vue-autosuggest>
                  </template>
                </ais-autocomplete>
              </ais-instant-search>
            </template>
          </div>
          <div
            v-if="isEditable"
            class="col">
            <label>{{ $t('message.calendar.event.invitationMailbox') }}{{ checkSelectedEmloyees ? '*' : '' }}</label>
            <coozzy-form-select
              v-model="event.mailBox"
              :state="checkSelectedEmloyees && $v.event.mailBox.$dirty && $v.event.mailBox.$error ? false : null"
              name="template">
              <option :value="''">
                -
              </option>
              <option :value="null">
                {{ $t('message.calendar.event.sendNoInvitation') }}
              </option>
              <option
                v-for="item in listMailboxes"
                :key="item.id"
                :value="item.id">
                {{ item.outboundEmail }}
              </option>
            </coozzy-form-select>
          </div>
          <div class="col-12">
            <b-table
              hover
              bordered
              class="mb-0 mt-3 font-small"
              :fields="fields"
              :items="computedListSelectedAttendees">
              <template
                #head(invited)>
                <span>{{ $t('message.calendar.event.invited') }}</span>
              </template>
              <template #head(contactPerson)>
                {{ $t('message.interestedParty.reference.landlord.contactPerson') }}
              </template>
              <template #head(email)>
                {{ $t('message.generic.email') }}
              </template>
              <template
                #head(status)>
                <span>{{ $t('message.generic.status') }}</span>
              </template>
              <template
                #head(editStatus) />
              <template
                #head(action)>
                <span>{{ $t('message.calendar.event.action') }}</span>
              </template>
              <template
                #cell(invited)="data">
                <div class="d-flex">
                  <span v-if="isEditable && data.item && data.item.name">{{ data.item.name }}</span>
                  <a
                    v-else-if="!isEditable && data.item"
                    role="button"
                    tabindex="0"
                    @click="redirectToDetailPage(data.item.id)"
                    @click.middle="redirectToDetailPageMiddle(data.item.id)">
                    {{ data.item.name }}
                  </a>
                </div>
              </template>
              <template #cell(contactPerson)="data">
                <coozzy-form-select
                  v-if="data.item.contactPersons && isEditable"
                  id="contactPerson"
                  :ref="'contactPerson_' + data.item.id"
                  v-model="data.item.selectedContactPerson"
                  @change="changeNotifiedEmail(data.item)">
                  <option :value="null">
                    -
                  </option>
                  <option
                    v-for="contact of data.item.contactPersons"
                    :key="contact.id"
                    :value="contact.id">
                    {{ contact.name }}
                  </option>
                </coozzy-form-select>
                <span v-else>
                  <span v-if="data.item.contactPersons && !isEditable">
                    {{ getContactName(data.item.contactPersons, data.item.selectedContactPerson) }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </span>
              </template>
              <template #cell(email)="data">
                <coozzy-form-select
                  v-if="data.item && hasWorkEmail(data.item.id) && (data.item.statusEdit || isEditable)"
                  v-model="data.item.email "
                  name="listEmails"
                  class="select-entries list-emails-select float-right">
                  <option
                    v-for="(email, index) in listEmails(data.item.id)"
                    :key="index"
                    :value="email">
                    {{ email }}
                  </option>
                </coozzy-form-select>
                <span v-else>
                  {{ data.item.email !== '' ? data.item.email : '-' }}
                </span>
              </template>
              <template
                #cell(status)="data">
                <template v-if="data.item.statusEdit || isEditable">
                  <coozzy-form-select
                    v-model="data.item.status"
                    name="numberEntries"
                    :disabled="!(organizer ? data.item.email !== organizer?.email : data.item.email !== eventDetail.organizer?.email)"
                    class="select-entries float-right">
                    <option
                      v-for="(status, index) in attendeesStatus"
                      :key="index"
                      :value="status">
                      {{ $t('message.calendar.event.status.' + status) }}
                    </option>
                  </coozzy-form-select>
                </template>
                <template v-else>
                  <template v-if="data.item.status">
                    {{ $t('message.calendar.event.status.' + data.item.status) }}
                  </template>
                  <template v-else>
                    {{ $t('message.calendar.event.status.ATTENDEE_STATUS_NEEDS_ACTION') }}
                  </template>
                </template>
              </template>
              <template
                v-if="!isEditable"
                #cell(editStatus)="data">
                <template v-if="canBeEdited && (organizer ? data.item.email !== organizer?.email : data.item.email !== eventDetail.organizer?.email) && (allowedToEdit || data.item.email === userEmail)">
                  <template v-if="data.item && !data.item.statusEdit">
                    <coozzy-button
                      v-if="isOwner"
                      class="float-right"
                      design="transparent"
                      @click="editEvent(false, data.item)">
                      <coozzy-edit-icon />
                    </coozzy-button>
                  </template>
                  <template v-else>
                    <coozzy-button
                      v-if="isOwner"
                      design="transparent"
                      class="float-right"
                      @click="onEditStatusAttendeeClicked(data.item.email !== '' ? data.item.email : data.item.name ? data.item.name : data.item.lastName + '_' + data.item.firstName)">
                      <coozzy-save-icon />
                    </coozzy-button>
                  </template>
                </template>
              </template>
              <template
                #cell(action)="data">
                <coozzy-button
                  v-if="organizer ? data.item.email !== organizer?.email : data.item.email !== eventDetail.organizer?.email"
                  design="transparent"
                  @click="removeInvitation(data.item)">
                  <coozzy-delete-icon />
                </coozzy-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div
        v-if="isEditable || (source === 'email' && !userInAttendeesNeedsAction && !userInAttendeesAcceptDecline)"
        class="d-block w-100 mt-3">
        <coozzy-button
          design="green"
          class="float-right ml-3"
          :disabled="loading"
          @click="checkNewEvent">
          <span v-if="source === 'email'">
            {{ $t('message.generic.form.addToCalendar') }}
          </span>
          <span v-else>
            {{ $t('message.generic.form.save') }}
          </span>
        </coozzy-button>
        <coozzy-button
          design="default"
          :disabled="loading"
          @click="hideModal()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
      <div
        v-if="userInAttendeesNeedsAction && !isEditable"
        class="d-block w-100 mt-3">
        <coozzy-button
          design="prop-green"
          class="float-right ml-3"
          :disabled="loading"
          @click="updateStatusButtonsClicked('accept')">
          {{ $t('message.generic.accept') }}
        </coozzy-button>
        <coozzy-button
          design="red"
          :disabled="loading"
          @click="updateStatusButtonsClicked('decline')">
          {{ $t('message.generic.decline') }}
        </coozzy-button>
      </div>
    </b-modal>

    <b-modal
      :id="'confirmationRecurrence'"
      :ref="'confirmationRecurrence'"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.calendar.event.recurringEvent.modal.title')">
      <div class="col-12">
        <p>{{ $t('message.calendar.event.recurringEvent.modal.bodyText') }}</p>
      </div>
      <div class="col-12">
        <div
          class="row mb-3">
          <div
            class="col-12">
            <coozzy-form-radio
              v-model="editAllRecurringEvents"
              name="type"
              :default-model="editAllRecurringEvents"
              :select-value="false">
              {{ $t('message.calendar.event.recurringEvent.modal.editSingleEvent') }}
            </coozzy-form-radio>
          </div>
          <div
            class="col-12">
            <coozzy-form-radio
              v-model="editAllRecurringEvents"
              name="type"
              :default-model="editAllRecurringEvents"
              :select-value="true">
              {{ $t('message.calendar.event.recurringEvent.modal.editAllEvent') }}
            </coozzy-form-radio>
          </div>
        </div>
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="hideConfirmationRecurrenceModal">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="acceptEditRecurringEvent">
          {{ $t('message.onBoarding.buildings.objects.inactivePopup.ok') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      :id="'delete-confirmation-modal'"
      :ref="'delete-confirmation-modal'"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.calendar.event.deleteConfirmation.title')">
      <div class="col">
        <p>{{ $t('message.calendar.event.deleteConfirmation.text') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('delete-confirmation-modal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          v-if="eventDetail && eventDetail.occurrenceEventDate"
          size="small"
          class="float-right mb-0"
          design="green"
          @click="deleteEvent('single')">
          {{ $t('message.calendar.event.recurringEvent.modal.deleteSingleEvent') }}
        </coozzy-button>
        <coozzy-button
          v-if="eventDetail && eventDetail.occurrenceEventDate"
          size="small"
          class="float-right mb-0 mr-2"
          design="green"
          @click="deleteEvent">
          {{ $t('message.calendar.event.recurringEvent.modal.deleteAllEvent') }}
        </coozzy-button>
        <coozzy-button
          v-else
          size="small"
          class="float-right mb-0 mr-2"
          design="green"
          @click="deleteEvent">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      v-if="selectedObjectVideo !== ''"
      ref="show-video"
      hide-footer
      hide-header>
      <div class="row mb-2">
        <div class="col">
          <b-embed
            type="video"
            controls>
            <source
              :src="selectedObjectVideo"
              type="video/webm">
            <source
              :src="selectedObjectVideo"
              type="video/mp4">
          </b-embed>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="globalConfirmationModal"
      ref="globalConfirmationModal"
      no-close-on-backdrop
      :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')"
      hide-footer>
      <p>{{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}</p>
      <div class="col">
        <coozzy-button
          size="small"
          class="border mb-0"
          design="transparent"
          @click="$bvModal.hide('globalConfirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="closeCurrentModal">
          {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>
<script>
import CoozzyFormSelect from '../../framework/components/form/select/CoozzyFormSelect'
import CoozzyFormInput from '../../framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import { VueAutosuggest } from 'vue-autosuggest'
import ContactApi from '@/misc/apis/ContactApi'
import ObjectApi from '@/misc/apis/ObjectApi'
import MessageApi from '@/misc/apis/MessageApi'
import MediaApi from '@/misc/apis/MediaApi'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { user } from '@/mixins/user'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { maxValue, minValue, required } from 'vuelidate/lib/validators'
import { algolia } from '@/mixins/algolia'
import CalendarApi from '../../misc/apis/CalendarApi'
import { dateUtils } from '@/mixins/dateUtils'
import { calendar } from '@/mixins/calendar'
import { routeChecks } from '@/mixins/routeChecks'
import Editor from '@tinymce/tinymce-vue'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput.vue'
import CoozzyThumbnail from '@/framework/components/img/CoozzyThumbnail.vue'
import CoozzySaveIcon from '@/framework/components/icons/CoozzySaveIcon.vue'
import CoozzyFormRadio from '@/framework/components/form/radio/CoozzyFormRadio.vue'
import linkifyStr from 'linkify-string'

function moreThanStarting(value, vm) {
  if (this.event.allDay) {
    return parseInt(new Date(value).getTime() / 1000) >= parseInt(new Date(vm.startDate).getTime() / 1000)
  } else {
    return parseInt(new Date(value + ' ' + this.event.endTime).getTime() / 1000) > parseInt(new Date(vm.startDate + ' ' + this.event.startTime).getTime() / 1000)
  }
}
function mailboxRequired(value) {
  return value !== ''
}
export default {
  name: 'EventCreationModal',
  components: { CoozzyFormRadio, CoozzySaveIcon, CoozzyThumbnail, CoozzyFormFileInput, CoozzyFormCheckbox, CoozzySpinner, CoozzyEditIcon, CoozzyDeleteIcon, VueAutosuggest, CoozzyButton, CoozzyFormInput, CoozzyFormSelect, Editor },
  mixins: [user, algolia, calendar, routeChecks, dateUtils],
  props: {
    availableEmployees: {
      type: Array,
      default() {
        return []
      }
    },
    contactsList: {
      type: Array,
      default() {
        return []
      }
    },
    reference: {
      type: Object,
      default: null
    },
    defaultDate: {
      type: Date,
      default() {
        return new Date()
      }
    },
    eventDetail: {
      type: Object,
      default: null
    },
    source: {
      type: String,
      default: ''
    },
    isAllDay: {
      type: Boolean,
      default: false
    },
    objectReference: {
      type: Object,
      default: null
    },
    additionalReferences: {
      type: String,
      default: ''
    },
    canBeEdited: {
      type: Boolean,
      default: true
    },
    userContact: {
      type: Object,
      default: null
    },
    startDateOccurrencesResponse: {
      type: Object,
      default: null
    },
    endDateOccurrencesResponse: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      initialEventDetail: null,
      alarmIntervalUnit: '',
      isChanged: false,
      isEditable: this.eventDetail === null,
      object: null,
      fields: [
        {
          key: 'invited',
          label: 'invited',
          thClass: 'table-header',
          tdClass: 'align-middle'
        },
        {
          key: 'contactPerson',
          label: this.$t('message.interestedParty.reference.landlord.contactPerson'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'email',
          label: this.$t('message.generic.email'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'status',
          label: 'status',
          thClass: 'table-header',
          tdClass: 'align-middle'
        },
        {
          key: 'editStatus',
          label: '',
          thClass: 'table-header',
          tdClass: 'align-middle'
        }
      ],
      templateId: null,
      event: {
        title: '',
        category: '',
        startDate: '',
        startDateObject: null,
        startDateRecurrence: null,
        startDateRecurrenceObject: null,
        endDate: '',
        endDateObject: null,
        endByDateRecurrence: null,
        startTime: '',
        startTimeObject: null,
        startTimeRecurrence: null,
        endDateRecurrence: null,
        endTimeRecurrence: null,
        endTime: '',
        endTimeObject: '',
        description: '',
        location: '',
        private: false,
        allDay: this.isAllDay,
        referenceIds: [],
        mailBox: '',
        userId: '',
        mediaIds: [],
        alarms: [],
        frequency: 'EVENT_FREQUENCY_UNDEFINED',
        recurringEvent: false,
        countRecurrence: null
      },
      endingRecurrence: 'endByDateRecurrence',
      listReferences: [],
      searchPartipant: '',
      searchPartipantValidation: true,
      selectedEmployees: [],
      loading: false,
      timeList: [],
      organizer: null,
      attendeesStatus: ['ATTENDEE_STATUS_NEEDS_ACTION', 'ATTENDEE_STATUS_ACCEPTED', 'ATTENDEE_STATUS_DECLINED'],
      loaded: false,
      uploading: false,
      mediasList: [],
      attachment: [],
      selectedObjectVideo: '',
      galleryImages: [],
      keyRefresh: 0,
      eventLocation: '',
      listSelectedAttendees: [],
      listContactAttendees: [],
      editAllRecurringEvents: false,
      attendeeToEdit: null,
      newStatusButton: '',
      listMailboxes: []
    }
  },
  computed: {
    ...mapState('message', ['templates']),
    ...mapGetters('message', ['getMailboxesForUserId', 'getMailboxEntries']),
    fontSetting() {
      return this.$store.getters['user/getFontSettings']
    },
    contentStyle() {
      const defaultFontFamily = 'Lato, sans-serif'
      const fontFamily = this.fontSetting && this.fontSetting.family !== '' ? `'${this.fontSetting.family}', ` : ''
      const fontSize = this.fontSetting && this.fontSetting.size !== '' ? this.fontSetting.size + 'pt' : '10pt'
      return `body { color: #495057 !important; font-family: ${fontFamily}'${defaultFontFamily} !important'; font-size: ${fontSize};}`
    },
    fontsizeFormats() {
      let sizeOptions = []
      for (let i = 4; i <= 36; i++) {
        sizeOptions.push(`${i}pt`)
      }
      return sizeOptions.join(' ')
    },
    fontFormats() {
      const defaultFont = 'Lato=Lato, sans-serif;'
      const userFont = this.fontSetting && this.fontSetting.family !== '' ? `${this.fontSetting.family}=${this.fontSetting.family}, sans-serif;` : ''
      return `${defaultFont}${userFont}`
    },
    allowedToEdit() {
      return this.eventDetail?.organizer?.email === this.userEmail || false
    },
    allowedToDelete() {
      let attendee = null
      if (this.eventDetail?.organizer?.email === this.userEmail) {
        return true
      }
      if (this.eventDetail?.attendees.length > 0) {
        attendee = this.eventDetail?.attendees.find(element => element.email === this.userEmail) || null
      }
      return attendee !== null
    },
    isOwner() {
      return this.$store.getters['user/getUserId'] === this.eventDetail?.userId
    },
    computedListSelectedAttendees() {
      // make the userContact first in the list then sort alphabetically by name
      return this.listSelectedAttendees.slice().sort((a, b) => {
        const email = (this.organizer ? this.organizer.email : this.eventDetail?.organizer?.email) || ''
        if (a.email === email) {
          return -1
        }
        if (b.email === email) {
          return 1
        }
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
    },
    userInAttendeesNeedsAction() {
      return this.eventDetail && this.eventDetail.attendees?.length > 0 && this.eventDetail.attendees.find(x => x.email === this.userEmail && x.status === 'ATTENDEE_STATUS_NEEDS_ACTION') !== undefined
    },
    userInAttendeesAcceptDecline() {
      return this.eventDetail && this.eventDetail.attendees?.length > 0 && this.eventDetail.attendees.find(x => x.email === this.userEmail && ['ATTENDEE_STATUS_ACCEPTED', 'ATTENDEE_STATUS_DECLINED'].includes(x.status)) !== undefined
    },
    alarms: function () {
      return this.event.alarms
    },
    filteredMediasList() {
      const array = this.mediasList
      array.sort((val1, val2) => {
        return parseInt(val2.uploadedAt) - parseInt(val1.uploadedAt)
      })
      return array
    },
    mailboxesForCurrentUser() {
      return this.getMailboxesForUserId(this.userId)
    },
    objectName() {
      if (this.objectReference) {
        return this.objectReference.name
      } else if (this.object) {
        return this.object.name
      }
      return ''
    },
    checkSelectedEmloyees() {
      return this.selectedEmployees.filter(x => x.email !== '').length > 0
    },
    getOrganizer() {
      if (this.organizer) {
        return this.organizer.firstName ? this.organizer.firstName + ' ' + this.organizer.lastName : this.organizer.profile ? this.organizer.profile.firstName + ' ' + this.organizer.profile.lastName : ''
      }
      return ''
    },
    editorApi() {
      return 'gpl'
    }
  },
  watch: {
    'event.recurringEvent': function (val) {
      if (!val) {
        this.endingRecurrence = 'endByDateRecurrence'
        this.event.endByDateRecurrence = null
        this.event.countRecurrence = null
        this.event.frequency = 'EVENT_FREQUENCY_UNDEFINED'
        this.event.startDateRecurrence = null
      }
    },
    'event.frequency': function (val) {
      if (val !== 'EVENT_FREQUENCY_UNDEFINED' && this.event.startDateRecurrence === null) {
        this.event.startDateRecurrence = this.event.startDate
      }
    },
    'event.startDateRecurrence': function (val) {
      if (this.isEditable) {
        this.$refs.startDate = val
        this.event.startDate = val
      }
    },
    alarmIntervalUnit: function (val) {
      if (val !== '') {
        const alarmIntervalValue = val.substring(0, val.indexOf('_'))
        const alarmIntervalUnit = val.substring(val.indexOf('_') + 1)
        if (this.event.alarms.length > 0) {
          this.event.alarms[0].alarmIntervalValue = alarmIntervalValue
          this.event.alarms[0].alarmIntervalUnit = 'ALARM_UNIT_' + alarmIntervalUnit
        } else {
          this.event.alarms.push({
            alarmIntervalValue: alarmIntervalValue,
            alarmIntervalUnit: 'ALARM_UNIT_' + alarmIntervalUnit
          })
        }
      } else {
        this.event.alarms = []
      }
    },
    mailboxesForCurrentUser: function (newMailboxes) {
      if (!this.eventDetail) {
        const connectedMailbox = newMailboxes.find(obj => obj.outboundEmail === this.userEmail)
        if (connectedMailbox) {
          this.event.mailBox = connectedMailbox.id
        }
      }
    },
    templateId: function (val) {
      if (val !== null) {
        this.renderTemplate()
      }
    },
    isEditable: function (newVal) {
      if (newVal) {
        this.fields.push({ key: 'Action', label: '' })
        this.event.description = this.eventDetail.description.replace(/\n/g, '<br>')
      }
      this.loaded = true
    },
    organizer: function (newVal) {
      if (newVal) {
        this.editEmailInvitation(newVal.id)
        if (newVal.id !== this.$store.getters['user/getUserId']) {
          this.event.private = false
        }
        this.listSelectedAttendees.forEach((element, index) => {
          if (element.email === newVal.email) {
            this.listSelectedAttendees.splice(index, 1)
          }
        }, this)
        if (this.isEditable) {
          this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
        }
      }
    },
    event: {
      deep: true,
      handler: function () {
        if (this.isEditable && this.loaded && this.canBeEdited) {
          this.isChanged = true
          this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
        }
      }
    },
    'event.startTime': function (val) {
      const index = this.timeList.indexOf(val)
      const indexOfEndTime = this.timeList.indexOf(this.event.endTime)
      if (!this.eventDetail || index >= indexOfEndTime) {
        this.event.endTime = this.timeList[index + 4 - (index >= this.timeList.length - 4 ? this.timeList.length : 0)]
        if (index >= this.timeList.length - 4) {
          let endDate = new Date(this.event.startDate)
          endDate.setDate(endDate.getDate() + 1)
          this.event.endDate = endDate.getFullYear() + '-' + ('0' + (endDate.getMonth() + 1)).slice(-2) + '-' + ('0' + endDate.getDate()).slice(-2)
        }
      }
      if (this.loaded) {
        this.changeAttendeesStatus()
      }
    },
    'event.startDate': function (val) {
      if (val !== '') {
        this.$refs.startDate = val
      }
      if (this.isEditable) {
        this.$refs.endDate = val
        this.event.endDate = val
        this.$refs.startDateRecurrence = val
        this.event.startDateRecurrence = val
      }
      if (this.loaded) {
        this.changeAttendeesStatus()
      }
    },
    'event.endDate': function () {
      if (this.loaded) {
        this.changeAttendeesStatus()
      }
    },
    'event.endTime': function () {
      if (this.loaded) {
        this.changeAttendeesStatus()
      }
    },
    endingRecurrence: function () {
      if (this.loaded) {
        this.event.endByDateRecurrence = null
        this.event.countRecurrence = null
      }
    },
    eventLocation: function (val) {
      this.getLocation(val)
    }
  },
  mounted: function () {
    this.loadInstantSearch()
    this.getListTimes()
    this.loadTemplateList(this.accountId)
    this.loadMailboxesForUserId(this.userId)

    if (this.eventDetail) {
      this.initialEventDetail = this.eventDetail
      if (this.eventDetail.mediaIds.length > 0) {
        MediaApi.getListMedia(this.eventDetail.mediaIds)
          .then(response => {
            for (const media of response.media) {
              this.mediasList.push(media)
            }
          })
          .catch(() => {
            Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
          })
      }
      if (this.eventDetail.attendees.length > 0) {
        const attendees = this.eventDetail.attendees.map(x => x.contactId)
        this.loadContacts(attendees)
      }
    }
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'modal-add-event') {
        this.$emit('hide-modal')
      }
      if (modalId === 'modal-add-event' && this.eventDetail === null) {
        this.event = {
          category: '',
          title: '',
          startDate: '',
          endDate: '',
          startTime: '',
          endTime: '',
          description: '',
          location: '',
          private: false,
          allDay: false,
          referenceIds: [],
          userId: '',
          alarms: [],
          frequency: 'EVENT_FREQUENCY_UNDEFINED',
          recurringEvent: false,
          endByDateRecurrence: null,
          countRecurrence: null
        }
        this.endingRecurrence = 'endByDateRecurrence'
        this.selectedEmployees = []
        this.listSelectedAttendees = []
        this.isChanged = false
        this.$nextTick(function () {
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
        })
      }
    })
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'modal-add-event' && this.eventDetail === null && this.mailboxesForCurrentUser.length > 0) {
        this.listMailboxes = this.mailboxesForCurrentUser.filter(obj => obj.mainUserId === this.organizer?.id)
        const connectedMailbox = this.mailboxesForCurrentUser.find(obj => obj.outboundEmail === this.userEmail)
        if (connectedMailbox) {
          this.event.mailBox = connectedMailbox.id
        }
        this.$nextTick(function () {
          this.keyRefresh += 1
        })
      }
      if (this.isEditable && this.additionalReferences !== '') {
        this.eventLocation = 'onSite'
        this.getLocation('onSite')
      }
    })
    if (this.eventDetail === null) {
      if (this.contactsList.length > 0) {
        this.selectedEmployees = this.selectedEmployees.concat(this.contactsList)
      } else if (this.reference !== null) {
        this.selectedEmployees.push(this.availableEmployees.find(obj => obj.email === this.reference.email))
      }
      this.$nextTick(function () {
        this.selectedEmployees.forEach(element => {
          this.listSelectedAttendees.push({
            name: element.name ? element.name : element.lastName + ' ' + element.firstName,
            email: element.email,
            id: element.id || element.objectID,
            status: element.status || 'ATTENDEE_STATUS_NEEDS_ACTION',
            contactPersons: 'contactPersons' in element && element.contactPersons?.length > 0 ? element.contactPersons : null,
            selectedContactPerson: null,
            statusEdit: false
          })
        })
      })
    }
    if (this.availableEmployees && this.availableEmployees.length > 0) {
      if (this.eventDetail && this.eventDetail.organizer) {
        this.organizer = this.availableEmployees.find(employee => employee.email === this.eventDetail.organizer.email) || null
      } else if (this.eventDetail && this.eventDetail.userId) {
        this.organizer = this.availableEmployees.find(employee => employee.id === this.eventDetail.userId) || null
      } else {
        this.organizer = this.availableEmployees.find(employee => employee.email && employee.email.toLowerCase() === this.$store.getters['user/getCurrentUser'].email.toLowerCase()) || null
      }
    }
    if (this.eventDetail !== null) {
      const contactIds = []
      const userEmails = []
      this.alarmIntervalUnit = this.eventDetail.alarms.length > 0 ? this.eventDetail.alarms[0].alarmIntervalValue + '_' + this.eventDetail.alarms[0].alarmIntervalUnit.substring(11) : ''
      this.eventDetail.attendees.forEach(element => {
        // check if attendees has contactId
        if (element.contactId !== '') {
          contactIds.push(element.contactId)
        } else {
          let org = null
          this.availableEmployees ? org = this.availableEmployees.find(obj => obj.email === element.email) : org = null
          if (org && org.email !== '') {
            userEmails.push(org.email)
            // org.status = element.status
            // this.selectedEmployees.push(org)
          } else {
            this.selectedEmployees.push({
              name: element.displayName,
              email: element.email,
              status: element.status,
              id: element.id
            })
          }
        }
      })
      this.$nextTick(function () {
        const promises = []
        if (contactIds.length > 0) {
          promises.push(ContactApi.contactResolve(contactIds))
        }
        if (userEmails.length > 0) {
          promises.push(ContactApi.resolveByMail(this.accountId, userEmails))
        }
        Promise.all(promises).then(globalResponse => {
          let list = []
          globalResponse.forEach(response => {
            // keep only contacts that has an id on contactIds or has email on userEmails
            list = list.concat(response.persons).concat(response.companies)
              .filter(contact => contactIds.includes(contact.id) || (('contactPersons') in contact && contact.contactPersons.some(cp => contactIds.includes(cp.id))) || userEmails.includes(contact.email))
          })
          // remove duplicated contacts
          list = [...new Map(list.map(item => [item.id, item])).values()]
          this.selectedEmployees = this.selectedEmployees.concat(list)
          this.$nextTick(function () {
            this.eventDetail.attendees.forEach(attendee => {
              const contact = this.selectedEmployees.find(x => x.id === attendee.contactId || (('contactPersons') in x && x.contactPersons.some(cp => cp.id === attendee.contactId)) || (attendee.contactId === '' && x.email === attendee.email))
              if (contact) {
                if (('contactPersons') in contact && contact.contactPersons.some(cp => cp.id === attendee.contactId)) {
                  const contactPerson = contact.contactPersons.find(x => x.id === attendee.contactId)
                  this.listSelectedAttendees.push({
                    name: contact.name ? contact.name : contact.lastName + ' ' + contact.firstName,
                    email: contactPerson.email !== '' ? contactPerson.email : '',
                    id: contact.id || contact.objectID || '',
                    status: attendee ? attendee.status : 'ATTENDEE_STATUS_NEEDS_ACTION',
                    contactPersons: 'contactPersons' in contact && contact.contactPersons?.length > 0 ? contact.contactPersons : null,
                    selectedContactPerson: contactPerson ? contactPerson.id : null,
                    statusEdit: false
                  })
                } else {
                  this.listSelectedAttendees.push({
                    name: contact.name ? contact.name : contact.lastName + ' ' + contact.firstName,
                    email: contact.email,
                    id: contact.id || contact.objectID || '',
                    status: attendee ? attendee.status : 'ATTENDEE_STATUS_NEEDS_ACTION',
                    contactPersons: 'contactPersons' in contact && contact.contactPersons?.length > 0 ? contact.contactPersons : null,
                    selectedContactPerson: null,
                    statusEdit: false
                  })
                }
              }
            })
          })
        })
      })
      const isWholeDay = this.eventDetail.startTime === null || (this.eventDetail.startTime.hours === 0 && this.eventDetail.startTime.minutes === 0 &&
        this.eventDetail.endTime.hours === 23 && this.eventDetail.endTime.minutes === 59)
        this.event = {
        category: '',
        title: this.eventDetail.title,
        startDate: this.formatDateForCalendar(this.eventDetail.startDate),
        startDateObject: this.eventDetail.startDate,
        startDateRecurrence: this.formatDateForCalendar(this.eventDetail.startDateRecurrence),
        startDateRecurrenceObject: this.eventDetail.startDateRecurrence,
        endDate: this.formatDateForCalendar(this.eventDetail.endDate),
        endDateObject: this.eventDetail.endDate,
        endByDateRecurrence: this.formatDateForCalendar(this.eventDetail.endByDateRecurrence),
        endDateRecurrence: this.eventDetail.endDateRecurrence,
        endTimeRecurrence: this.eventDetail.endTimeRecurrence,
        startTime: this.formatTimeForCalendar(this.eventDetail.startTime),
        startTimeRecurrence: this.eventDetail.startTimeRecurrence,
        startTimeObject: this.eventDetail.startTime,
        endTime: this.formatTimeForCalendar(this.eventDetail.endTime),
        endTimeObject: this.eventDetail.endTime,
        description: this.eventDetail.description,
        location: this.eventDetail.location,
        mailBox: this.eventDetail.mailboxId === '' ? null : this.eventDetail.mailboxId,
        private: this.eventDetail.private,
        allDay: isWholeDay,
        url: this.eventDetail.url,
        referenceIds: this.eventDetail.referenceIds,
        organizer: this.organizer,
        userId: this.eventDetail.userId,
        alarms: this.eventDetail.alarms,
        frequency: this.eventDetail.frequency,
        recurringEvent: this.eventDetail.frequency !== 'EVENT_FREQUENCY_UNDEFINED',
        countRecurrence: this.eventDetail.countRecurrence
      }
      if (this.event.endByDateRecurrence) {
        this.endingRecurrence = 'endByDateRecurrence'
      } else if (this.event.countRecurrence !== '' && this.event.countRecurrence !== null) {
        this.endingRecurrence = 'endAfter'
      }
      if (this.event.startTime === '00:00' && this.event.endTime === '00:00') {
        this.event.allDay = true
      }
      this.$nextTick(function () {
        this.event.endTime = this.formatTimeForCalendar(this.eventDetail.endTime)
        this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      })
    } else if (this.defaultDate) {
      const hours = ('0' + (this.defaultDate.getHours())).slice(-2)
      this.event.startDate = this.defaultDate.getFullYear() + '-' + ('0' + (this.defaultDate.getMonth() + 1)).slice(-2) + '-' + ('0' + this.defaultDate.getDate()).slice(-2)
      this.event.startTime = hours + ':00'
      this.$nextTick(function () {
        this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      })
      this.fields.push({ key: 'Action', label: '' })
    }
    if (this.event.referenceIds.length > 0) {
      const objId = this.event.referenceIds.find(x => x.startsWith('obj'))
      if (objId !== undefined && objId !== '') {
        ObjectApi.getObject(objId)
          .then(response => {
            this.object = response.object
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
          })
      }
      ContactApi.contactResolve(this.event.referenceIds)
        .then(response => {
          this.listReferences = response.persons.concat(response.companies)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
    }
    this.$nextTick(function () {
      if (this.isEditable) {
        this.loaded = true
      }
    })
  },
  methods: {
    ...mapActions('message', ['loadMailboxesForUserId', 'loadTemplateList']),
    async editEmailInvitation(organizerId) {
      let userMailbox = this.getMailboxesForUserId(organizerId) || []
      if (userMailbox.length === 0) {
        await this.loadMailboxesForUserId(organizerId)
        this.$nextTick(async () => {
          userMailbox = this.getMailboxesForUserId(organizerId) || []
        })
      }
      this.$nextTick(() => {
        this.listMailboxes = userMailbox.length > 0 ? userMailbox.filter(x => x.mainUserId === organizerId) : []
        this.event.mailBox = this.listMailboxes.length > 0 ? this.listMailboxes[0].id : ''
      })
    },
    textWithLinks(bodyText) {
      const textWithLinks = linkifyStr(bodyText, { target: '_blank' })
      return textWithLinks.replace(/\n/g, '<br>')
    },
    loadContacts(contactIds) {
      ContactApi.contactResolve(contactIds)
        .then(response => {
          this.listContactAttendees = response.persons.concat(response.companies)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
    },
    getContactName(list, id) {
      const contact = list.find(x => x.id === id)
      return contact ? (contact.name ? contact.name : contact.lastName + ' ' + contact.firstName) : '-'
    },
    redirectToDetailPageMiddle(id) {
      const contact = this.selectedEmployees.find(x => x.id === id || (('contactPersons') in x && x.contactPersons.some(cp => cp.id === id)))
      if (contact !== null) {
        let route = ''
        if (this.isOwnerModule) {
          route = this.$router.resolve({
            name: 'OwnerContactDetailsView',
            params: { id: contact.id }
          })
        } else if (this.isAssetModule) {
          route = this.$router.resolve({
            name: 'AssetContactDetailsView',
            params: { id: contact.id }
          })
        } else if (this.isAccountingModule) {
          route = this.$router.resolve({
            name: 'AccountingContactDetailsView',
            params: { id: contact.id }
          })
        } else if (this.isMarketingModule) {
          route = this.$router.resolve({
            name: 'MarketingContactDetailsView',
            params: { id: contact.id }
          })
        } else {
          route = this.$router.resolve({
            name: 'AdminContactDetailsView',
            params: { id: contact.id }
          })
        }
        window.open(route.href)
      }
    },
    redirectToDetailPage(id) {
      const contact = this.selectedEmployees.find(x => x.id === id || (('contactPersons') in x && x.contactPersons.some(cp => cp.id === id)))
      if (contact) {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerContactDetailsView',
            params: { id: contact.id }
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetContactDetailsView',
            params: { id: contact.id }
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingContactDetailsView',
            params: { id: contact.id }
          })
        } else if (this.isMarketingModule) {
          this.$router.push({
            name: 'MarketingContactDetailsView',
            params: { id: contact.id }
          })
        } else {
          this.$router.push({
            name: 'AdminContactDetailsView',
            params: { id: contact.id }
          })
        }
      }
    },
    redirectToRequest() {
      if (this.isMarketingModule && (this.additionalReferences?.split(',').find(x => x.includes('req')) || this.event.referenceIds?.find(x => x.includes('req'))) && (this.objectReference || this.object)) {
        this.$router.push({ name: 'MarketingObjectDetailsView', params: { id: this.objectReference?.id || this.object?.id }, query: { view: 0, elementSelected: this.additionalReferences?.split(',').find(x => x.includes('req')) || this.event.referenceIds?.find(x => x.includes('req')) } })
      }
    },
    redirectToRequestMiddleClick() {
      if (this.isMarketingModule && (this.additionalReferences?.split(',').find(x => x.includes('req')) || this.event.referenceIds?.find(x => x.includes('req'))) && (this.objectReference || this.object)) {
        const route = this.$router.resolve({ name: 'MarketingObjectDetailsView', params: { id: this.objectReference?.id || this.object?.id }, query: { view: 0, elementSelected: this.additionalReferences?.split(',').find(x => x.includes('req')) || this.event.referenceIds?.find(x => x.includes('req')) } })
        window.open(route.href)
      }
    },
    getLocation(location) {
      let addressObject = null
      if (location === 'onSite') {
        if (this.additionalReferences !== '') {
          const listReferences = this.additionalReferences.split(',')
          if (listReferences.length > 0) {
            if (listReferences[0].startsWith('contact')) {
              addressObject = this.contactsList.find(contact => contact.id === listReferences[0]).address || null
            } else if (listReferences[0].startsWith('request')) {
              addressObject = this.objectReference?.address || null
            }
          }
        } else if (this.eventDetail && this.listSelectedAttendees.length > 0) {
          const attendees = this.listSelectedAttendees.filter(x => x.email !== this.eventDetail.organizer.email)
          if (attendees.length === 1) {
            const contact = this.listContactAttendees.find(contact => contact.id === attendees[0].id)
            if (contact) {
              addressObject = contact.address || null
            }
          } else if (attendees.length > 1 && this.eventDetail.referenceIds.length > 0) {
            if (this.eventDetail.referenceIds[0].startsWith('contact')) {
              addressObject = this.listReferences.find(contact => contact.id === this.eventDetail.referenceIds[0])?.address || null
            }
          }
        }
      } else if (location === 'inOffice' && this.userContact) {
          addressObject = this.userContact.address || null
      }
      if (addressObject) {
        const addr = (addressObject.street ? addressObject.street + (addressObject.streetNumber ? ' ' + addressObject.streetNumber : '') : '') +
          (addressObject.zip ? ', ' + addressObject.zip + ' ' : '') +
          (addressObject.city ? addressObject.city : '')

        if (addr) {
          this.event.location = addr
        }
      } else {
        this.event.location = ''
      }
    },

    changeNotifiedEmail(contact) {
      if (contact.selectedContactPerson) {
        this.listSelectedAttendees.forEach(x => {
          if (x.contactPersons && x.contactPersons.length > 0) {
            const contactPerson = x.contactPersons.find(item => item.id === contact.selectedContactPerson)
            if (contactPerson) {
              x.email = contactPerson.email ? contactPerson.email : ''
              // x.name = contactPerson.name ? contactPerson.name : contactPerson.firstName + ' ' + contactPerson.lastName
            }
          }
        })
      } else {
        this.listSelectedAttendees.forEach(x => {
          if (x.id === contact.id) {
            const item = this.selectedEmployees.find(item => item.id === contact.id || item.objectID === contact.id)
            if (item) {
              x.email = item.email
            }
          }
        })
      }
    },
    listEmails(id) {
      const item = this.selectedEmployees.find(item => item.id === id || item.objectID === id)
      const array = []
      if (item) {
        if (item?.email !== '') {
          array.push(item.email)
        }
        if (item?.workEmail && item.workEmail?.use && item.workEmail?.email !== '') {
          array.push(item.workEmail.email)
        }
      }
      return array
    },
    objectUrl() {
      const object = this.objectReference || this.object
      if (object) {
        if (this.isAdminModule || (this.isMarketingModule && this.hasAccessAdminBase)) {
          this.$router.push({ name: 'AdminBuildingDetailsView', params: { id: object.buildingId }, query: { view: 'objects', elementSelected: object.id || object.objectID } })
        } else if (this.isOwnerModule || (this.isMarketingModule && this.hasAccessOwnerBase)) {
          this.$router.push({ name: 'OwnerBuildingDetailsView', params: { id: object.buildingId }, query: { view: 'objects', elementSelected: object.id || object.objectID } })
        } else if (this.isAssetModule || (this.isMarketingModule && this.hasAccessAssetBase)) {
          this.$router.push({ name: 'AssetBuildingDetailsView', params: { id: object.buildingId }, query: { view: 'objects', elementSelected: object.id || object.objectID } })
        } else if (this.isAccountingModule || (this.isMarketingModule && this.hasAccessAccountingBase)) {
          this.$router.push({ name: 'AccountingBuildingDetailsView', params: { id: object.buildingId }, query: { view: 'objects', elementSelected: object.id || object.objectID } })
        }
      }
      return '#'
    },
    objectUrlMiddleClick() {
      const object = this.objectReference || this.object
      if (object) {
        let routeData = null
        if (this.isAdminModule || (this.isMarketingModule && this.hasAccessAdminBase)) {
          routeData = this.$router.resolve({ name: 'AdminBuildingDetailsView', params: { id: object.buildingId }, query: { view: 'objects', elementSelected: object.id || object.objectID } })
        } else if (this.isOwnerModule || (this.isMarketingModule && this.hasAccessOwnerBase)) {
          routeData = this.$router.resolve({ name: 'OwnerBuildingDetailsView', params: { id: object.buildingId }, query: { view: 'objects', elementSelected: object.id || object.objectID } })
        } else if (this.isAssetModule || (this.isMarketingModule && this.hasAccessAssetBase)) {
          routeData = this.$router.resolve({ name: 'AssetBuildingDetailsView', params: { id: object.buildingId }, query: { view: 'objects', elementSelected: object.id || object.objectID } })
        } else if (this.isAccountingModule || (this.isMarketingModule && this.hasAccessAccountingBase)) {
          routeData = this.$router.resolve({ name: 'AccountingBuildingDetailsView', params: { id: object.buildingId }, query: { view: 'objects', elementSelected: object.id || object.objectID } })
        }
        window.open(routeData.href, '_blank')
      }
      return '#'
    },
    imageClicked(item) {
      if (item.url && item.url !== '') {
        this.galleryImages = [
          item.url
        ]
        this.$nextTick(function () {
          this.$viewerApi({
            images: this.galleryImages,
            options: this.viewerOptions
          })
        })
      }
    },
    openVideo(media) {
      this.selectedObjectVideo = media.url
      this.$nextTick(() => {
        this.$refs['show-video'].show()
      })
    },
    addFile(event) {
      if (event.dataTransfer && event.dataTransfer.files.length > 0) {
        for (let index = 0; index < event.dataTransfer.files.length; index++) {
          const element = event.dataTransfer.files[index]
          this.attachment.push(element)
        }
        this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
      }
      if (event.target.files && event.target.files.length > 0) {
        for (let index = 0; index < event.target.files.length; index++) {
          const element = event.target.files[index]
          this.attachment.push(element)
        }
        this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
      }
      this.loading2 = true
      this.sendFile()
    },
    deleteMedia(e, id) {
      e.stopPropagation()
      if (this.mediasList) {
        this.mediasList = this.mediasList.filter(el => el.id !== id)
        this.mediaUpdated = true
      }
    },
    sendFile() {
      const promises = []
      this.uploading = true
      this.attachment.forEach(element => {
        if (element !== null) {
          const formData = new FormData()
          formData.append('file', element)
          formData.append('public', 'true')
          promises.push(MediaApi.uploadMedia(formData))
        }
      })
      Promise.all(promises)
        .then(response => {
          for (const media of response) {
            this.mediasList.push(media)
          }
        })
        .then(() => {
          this.attachment = []
          this.uploading = false
          this.mediaUpdated = true
          this.$nextTick(() => {
            this.$refs?.attachmentUpload?.resetPlaceholder()
          })
        })
        .catch(e => {
          console.log(e)
          if (e.response && e.response.data && e.response.data.message === 'Not supported MIME type') {
            Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
          } else {
            Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
          }
        })
        .finally(() => {
          this.uploading = false
        })
    },
    changeAttendeesStatus() {
      this.listSelectedAttendees.forEach(x => {
        if (this.eventDetail?.organizer && x.email === this.eventDetail?.organizer.email) {
          x.status = 'ATTENDEE_STATUS_ACCEPTED'
        } else {
          x.status = 'ATTENDEE_STATUS_NEEDS_ACTION'
        }
      })
    },
    renderTemplate() {
      const objectId = this.objectReference ? this.objectReference.id : this.object ? this.object.id : ''
      MessageApi.renderTemplate(this.templateId.id, '', objectId)
        .then(response => {
          this.mediasList = []
          this.event.title = response.subject
          this.event.description = response.text
          if (response.attachmentMediaIds.length > 0) {
            MediaApi.getListMedia(response.attachmentMediaIds)
              .then(response => {
                for (const media of response.media) {
                  this.mediasList.push(media)
                }
              })
              .catch(() => {
                Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
              })
          }
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.renderTemplate'), { type: 'error' })
        })
    },
    showDeleteConfirmationModal() {
      this.$refs['delete-confirmation-modal'].show()
    },
    newAttendeesStatusEditClicked(item) {
      const attendeeIndex = this.listSelectedAttendees.findIndex(x => x.email === item || x.name === item)
      if (attendeeIndex !== -1) {
        this.listSelectedAttendees[attendeeIndex].statusEdit = true
      }
    },
    labelFormatDate(date) {
      return date.substr(8, 2) + '.' + date.substr(5, 2) + '.' + date.substr(0, 4)
    },
    labelFormatTime(time) {
      if (this.event.allDay) {
        return '-'
      } else {
        return time
      }
    },
    formatTimeForCalendar(time) {
      if (time === null) {
        return '-'
      }
      // start and end need to be in format the calendar package can understand
      return (time.hours.toString().length === 2 ? time.hours.toString() : '0' + time.hours.toString()) + ':' + (time.minutes.toString().length === 2 ? time.minutes.toString() : '0' + time.minutes.toString())
    },
    getListTimes() {
      let hours, minutes
      const list = []
      for (let i = 0; i <= 1425; i += 15) {
        hours = Math.floor(i / 60)
        minutes = i % 60
        if (minutes < 10) {
          minutes = '0' + minutes // adding leading zero
        }
        if (hours < 10) {
          hours = '0' + hours // adding leading zero
        }
        if (hours === 24) {
          hours = '00'
        }
        list.push(hours + ':' + minutes)
      }
      this.timeList = list
    },
    editEvent(allEvent = false, attendee = null) {
      if (this.eventDetail && this.eventDetail.occurrenceEventDate) {
        if (!allEvent && attendee) {
          this.attendeeToEdit = attendee
        }
        this.$refs.confirmationRecurrence?.show()
      } else {
        if (allEvent) {
          this.isEditable = true
        } else {
          this.newAttendeesStatusEditClicked(attendee.email !== '' ? attendee.email : attendee.name ? attendee.name : attendee.lastName + '_' + attendee.firstName)
        }
      }
    },
    acceptEditRecurringEvent() {
      this.$refs.confirmationRecurrence?.hide()
      if (this.newStatusButton !== '') {
        this.editAttendeeStatus(this.newStatusButton)
      } else if (this.attendeeToEdit) {
        this.newAttendeesStatusEditClicked(this.attendeeToEdit.email !== '' ? this.attendeeToEdit.email : this.attendeeToEdit.name ? this.attendeeToEdit.name : this.attendeeToEdit.lastName + '_' + this.attendeeToEdit.firstName)
      } else {
        this.isEditable = true
      }
    },
    hideConfirmationRecurrenceModal() {
      this.$refs.confirmationRecurrence?.hide()
      this.attendeeToEdit = null
      this.newStatusButton = false
    },
    updateStatusButtonsClicked(status) {
      if (this.eventDetail.occurrenceEventDate) {
        this.$refs.confirmationRecurrence?.show()
        this.newStatusButton = status
      } else {
        this.editAttendeeStatus(status)
      }
    },
    checkNewEvent() {
      this.$v.event.$touch()
      if (!this.$v.event.$invalid) {
          this.newEvent()
      } else if ((this.$v.event.startDate.$dirty && this.$v.event.startDate.$error && !this.$v.event.startDate.required) || (this.$v.event.endDate.$dirty && this.$v.event.endDate.$error && !this.$v.event.endDate.required)) {
        Vue.toasted.show(this.$t('message.savingErrors.invalidDate'), { type: 'error' })
      } else if (this.$v.event.endDate.$dirty && this.$v.event.endDate.$error) {
        Vue.toasted.show(this.$t('message.savingErrors.endTimeAfterStart'), { type: 'error' })
      }
    },
    newEvent() {
        this.loading = true
        if (this.event.allDay) {
          this.event.startTime = '00:00'
          this.event.endTime = '00:00'
          const endDate = new Date(this.event.endDate)
          this.event.endDate = this.$options.filters.timestampToDateInput((endDate.setDate(endDate.getDate() + 1)) / 1000)
        }
        this.event.startTimeObject = {
          hours: parseInt(this.event.startTime.substr(0, 2)),
          minutes: parseInt(this.event.startTime.substr(3, 2))
        }
        this.event.endTimeObject = {
          hours: parseInt(this.event.endTime.substr(0, 2)),
          minutes: parseInt(this.event.endTime.substr(3, 2))
        }
        this.event.endDateObject = {
          year: parseInt(this.event.endDate.substr(0, 4)),
          month: parseInt(this.event.endDate.substr(5, 2)),
          day: parseInt(this.event.endDate.substr(8, 2))
        }
        if (this.event.endByDateRecurrence) {
          this.event.endByDateRecurrenceObject = {
            year: parseInt(this.event.endByDateRecurrence.substr(0, 4)),
            month: parseInt(this.event.endByDateRecurrence.substr(5, 2)),
            day: parseInt(this.event.endByDateRecurrence.substr(8, 2))
          }
        }
        this.event.startDateObject = {
          year: parseInt(this.event.startDate.substr(0, 4)),
          month: parseInt(this.event.startDate.substr(5, 2)),
          day: parseInt(this.event.startDate.substr(8, 2))
        }

        const attendees = this.listSelectedAttendees.map(function (x) {
          return {
            displayName: x.name,
            email: x.email,
            contactId: x.selectedContactPerson ? x.selectedContactPerson : x.id,
            status: x.status !== '' ? x.status : 'ATTENDEE_STATUS_NEEDS_ACTION'
          }
        }) || this.eventDetail.attendees

        this.event.userId = this.organizer ? this.organizer.id : this.eventDetail ? this.eventDetail.userId : ''
        if (this.additionalReferences !== '') {
          const allReferences = (this.listSelectedAttendees.map(x => x.id || x.objectID).concat(this.additionalReferences.split(','))).filter(y => y !== null && y !== undefined)
          if (this.eventDetail === null) {
            this.event.referenceIds = [...new Set(allReferences)].filter(y => y !== null && y !== undefined) || []
          } else {
            this.event.referenceIds = this.eventDetail.referenceIds.concat([...new Set(allReferences)])
          }
        } else {
          if (this.eventDetail === null) {
            this.event.referenceIds = this.listSelectedAttendees.map(x => x.id || x.objectID).filter(y => y !== null && y !== undefined) || []
          } else {
            this.event.referenceIds = (this.eventDetail.referenceIds.concat(this.listSelectedAttendees.map(x => x.id || x.objectID)).filter(y => y !== null && y !== undefined))
          }
        }
        let endDateWithRecurrence = null
        if (this.event.countRecurrence !== null && this.endDateOccurrencesResponse) {
          const dateItem = new Date(this.endDateOccurrencesResponse.year + '/' + this.endDateOccurrencesResponse.month + '/' + this.endDateOccurrencesResponse.day)
          switch (this.event.frequency) {
            case 'EVENT_FREQUENCY_DAILY':
              endDateWithRecurrence = dateItem.setDate(dateItem.getDate() + parseInt(this.event.countRecurrence))
              break
            case 'EVENT_FREQUENCY_WEEKLY':
              endDateWithRecurrence = dateItem.setDate(dateItem.getDate() + (parseInt(this.event.countRecurrence) * 7))
              break
            case 'EVENT_FREQUENCY_MONTHLY':
              endDateWithRecurrence = dateItem.setMonth(dateItem.getMonth() + parseInt(this.event.countRecurrence))
              break
            case 'EVENT_FREQUENCY_YEARLY':
              endDateWithRecurrence = dateItem.setFullYear(dateItem.getFullYear() + parseInt(this.event.countRecurrence))
              break
            default:
              break
          }
        }
        this.event.startDateOccurrencesResponse = new Date(this.$options.filters.objectToDateInput(this.startDateOccurrencesResponse)) < new Date(this.$options.filters.objectToDateInput(this.event.startDateObject)) ? this.startDateOccurrencesResponse : this.event.startDateObject
        // if (this.event.endDateRecurrenceObject) {
        //   console.log('if 1111')
        //   let biggestDate = new Date(this.$options.filters.objectToDateInput(this.event.endDateRecurrenceObject))
        //
        //   // Compare date2 with biggestDate
        //   if (new Date(this.$options.filters.objectToDateInput(this.endDateOccurrencesResponse)) > biggestDate) {
        //     console.log('00001')
        //     biggestDate = new Date(this.$options.filters.objectToDateInput(this.endDateOccurrencesResponse))
        //   }
        //   if (endDateWithRecurrence !== null) {
        //     console.log('00002')
        //     if (new Date(endDateWithRecurrence) > biggestDate) {
        //       console.log('00003')
        //       biggestDate = new Date(endDateWithRecurrence)
        //     }
        //   }
        //   // Compare date3 with biggestDate
        //   if (new Date(this.$options.filters.objectToDateInput(this.event.endDate)) > biggestDate) {
        //     console.log('00004')
        //     biggestDate = new Date(this.$options.filters.objectToDateInput(this.event.endDate))
        //   }
        //   console.log('biggestDate', biggestDate)
        //   this.event.endDateOccurrencesResponse = this.toObject(biggestDate)
        // } else {
        //   console.log('else 1111')
        //   if (endDateWithRecurrence !== null && new Date(endDateWithRecurrence) > new Date(this.$options.filters.objectToDateInput(this.endDateOccurrencesResponse)) && new Date(endDateWithRecurrence) > new Date(this.$options.filters.objectToDateInput(this.event.endDate))) {
        //     console.log('11111')
        //     this.event.endDateOccurrencesResponse = endDateWithRecurrence
        //   } else if (new Date(this.$options.filters.objectToDateInput(this.endDateOccurrencesResponse)) > new Date(this.$options.filters.objectToDateInput(this.event.endDate))) {
        //     console.log('11122')
        //     this.event.endDateOccurrencesResponse = this.endDateOccurrencesResponse
        //   } else {
        //     console.log('11133')
        //     let endDate = new Date(this.event.endDate)
        //     endDate.setDate(endDate.getDate() + 1)
        //     this.event.endDateOccurrencesResponse = this.toObject(endDate)
        //   }
        // }
        let endDate = new Date(this.$options.filters.objectToDateInput((new Date(this.$options.filters.objectToDateInput(this.endDateOccurrencesResponse)) > new Date(this.$options.filters.objectToDateInput(this.event.endDateObject)) ? this.endDateOccurrencesResponse : this.event.endDateObject)))
        endDate.setDate(endDate.getDate() + 1)
        this.event.endDateOccurrencesResponse = this.toObject(endDate)
        if (this.eventDetail !== null && this.source !== 'email') {
          // check if some properties are changed like startdate, endDate, frequence, occurences
          if (new Date(this.$options.filters.objectToDateInput(this.initialEventDetail.startDate)).getTime() !== new Date(this.event.startDate).getTime() || new Date(this.$options.filters.objectToDateInput(this.initialEventDetail.endDate)).getTime() !== new Date(this.event.endDate).getTime() || this.initialEventDetail.frequency !== this.event.frequency || new Date(this.$options.filters.objectToDateInput(this.initialEventDetail.endByDateRecurrence)).getTime() !== new Date(this.event.endByDateRecurrence).getTime() || this.initialEventDetail.countRecurrence !== this.event.countRecurrence) {
            this.event.resetOccurrencesEvents = true
          }
          this.event.mediaIds = this.mediasList.map(x => x.id)
          if (this.editAllRecurringEvents && this.event.startDateRecurrenceObject) {
            const index = this.timeList.indexOf(this.formatTimeForCalendar(this.event.startTimeRecurrence))
            const indexOfStartTime = this.timeList.indexOf(this.event.startTime)
            if (index < indexOfStartTime) {
              this.event.startTimeObject = this.event.startTimeRecurrence
            } else {
              this.event.startTimeObject = {
                hours: parseInt(this.event.startTime.substr(0, 2)),
                minutes: parseInt(this.event.startTime.substr(3, 2))
              }
            }
            this.event.startDateObject = this.event.startDateRecurrenceObject
          }
          if (this.editAllRecurringEvents && this.event.endDateRecurrence) {
            const index = this.timeList.indexOf(this.formatTimeForCalendar(this.event.endTimeRecurrence))
            const indexOfendTime = this.timeList.indexOf(this.event.endTime)
            if (index > indexOfendTime) {
              this.event.endTimeObject = this.event.endTimeRecurrence
            } else {
              this.event.endTimeObject = {
                hours: parseInt(this.event.endTime.substr(0, 2)),
                minutes: parseInt(this.event.endTime.substr(3, 2))
              }
            }
            this.event.endDateObject = this.event.endDateRecurrence
          }
          this.event.startDateOccurrencesResponse = new Date(this.$options.filters.objectToDateInput(this.startDateOccurrencesResponse)) < new Date(this.$options.filters.objectToDateInput(this.event.startDateObject)) ? this.startDateOccurrencesResponse : this.event.startDateObject
          CalendarApi.updateEvent(this.event, this.$store.getters['user/getAccountId'], attendees, this.event.referenceIds, !this.editAllRecurringEvents && this.eventDetail.occurrenceEventDate ? this.eventDetail.occurrenceEventDate || this.event.occurrenceEventDate : null)
            .then(response => {
              this.loading = false
              this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
              this.hide()
              response.events.forEach(item => {
                item.attendees = attendees
                item.organizer = this.organizer
                item.allDay = this.event.allDay
                if (item.endByDateRecurrence) {
                  this.endingRecurrence = 'endByDateRecurrence'
                } else if (item.countRecurrence !== '' && item.countRecurrence !== null) {
                  this.endingRecurrence = 'endAfter'
                }
              })
              this.$emit('post-creation', response.events, !!this.editAllRecurringEvents)
            })
            .catch(e => {
              console.log(e)
              this.loading = false
              const endDate = new Date(this.event.endDate)
              this.event.endDate = this.$options.filters.timestampToDateInput((endDate.setDate(endDate.getDate() - 1)) / 1000)
              Vue.toasted.show(this.$t('message.savingErrors.calendar'), { type: 'error' })
            })
        } else {
          this.event.mediaIds = this.mediasList.map(x => x.id)
          this.event.startDateOccurrencesResponse = new Date(this.$options.filters.objectToDateInput(this.startDateOccurrencesResponse)) < new Date(this.$options.filters.objectToDateInput(this.event.startDateObject)) ? this.startDateOccurrencesResponse : this.event.startDateObject
          CalendarApi.createEvent(this.event, this.$store.getters['user/getAccountId'], attendees)
            .then(response => {
              this.loading = false
              this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
              this.hide()
              response.events.forEach(ev => {
                  ev.organizer = this.organizer
                  ev.allDay = this.event.allDay
              })
              this.$emit('post-creation', response.events)
            })
            .catch(e => {
              console.log(e)
              this.loading = false
              const endDate = new Date(this.event.endDate)
              this.event.endDate = this.$options.filters.timestampToDateInput((endDate.setDate(endDate.getDate() - 1)) / 1000)
              Vue.toasted.show(this.$t('message.savingErrors.calendar'), { type: 'error' })
            })
        }
    },
    show() {
      this.keyRefresh += 1
      this.$refs['modal-add-event'].show()
      this.keyRefresh += 1
    },
    hide() {
      this.keyRefresh += 1
      this.$refs['modal-add-event'].hide()
      this.keyRefresh += 1
      this.$emit('hide-modal')
    },
    hideModal() {
      if (this.isChanged && this.isEditable) {
        this.$refs.globalConfirmationModal.show()
      } else {
        this.hide()
      }
    },
    checkEnter() {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.searchPartipantValidation = re.test(String(this.searchPartipant).toLowerCase())
      if (this.searchPartipantValidation) {
        if (this.searchPartipant !== '') {
          this.$refs.autosuggest.internalValue = this.searchPartipant
          // select the first item in the list of suggestions if there is one and the user presses enter key on the input field, if not, the user will be able to add the email address as a new attendee
          if (this.$refs.autosuggest.suggestions.length > 0 && this.$refs.autosuggest.suggestions[0].data.length > 0) {
            this.selectHandler({ item: this.$refs.autosuggest.suggestions[0].data[0] })
          } else {
            this.selectedEmployees.push({
              name: this.searchPartipant,
              email: this.searchPartipant,
              id: null,
              status: 'ATTENDEE_STATUS_NEEDS_ACTION',
              contactPersons: null,
              selectedContactPerson: null,
              statusEdit: false
            })
            this.listSelectedAttendees.push({
              name: this.searchPartipant,
              email: this.searchPartipant,
              id: null,
              status: 'ATTENDEE_STATUS_NEEDS_ACTION',
              contactPersons: null,
              selectedContactPerson: null,
              statusEdit: false
            })
          }
        }
        this.searchPartipant = ''
        this.searchPartipantValidation = true
      }
    },
    closeCurrentModal() {
      this.$refs.globalConfirmationModal.hide()
      this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      this.hide()
    },
    selectHandler(selected) {
      if (selected && selected.item) {
        if (selected.item.email === '') {
          const attendeesAlreadyExist = this.listSelectedAttendees.filter(x => x.name === selected.item.name).length === 0
          if (attendeesAlreadyExist) {
            this.selectedEmployees.push(selected.item)
            this.listSelectedAttendees.push({
              name: selected.item.name,
              email: selected.item.email,
              id: selected.item.id || selected.item.objectID,
              status: 'ATTENDEE_STATUS_NEEDS_ACTION',
              contactPersons: 'contactPersons' in selected.item && selected.item.contactPersons?.length > 0 ? selected.item.contactPersons.filter(cp => cp.active) : null,
              selectedContactPerson: null,
              statusEdit: false
            })

            this.$nextTick(function () {
              this.searchPartipant = ''
              this.$refs.autosuggest.internalValue = ''
              this.searchPartipantValidation = true
            })
          }
        } else if (selected.item.email !== this.organizer.email) {
          const attendeesAlreadyExist = this.listSelectedAttendees.filter(x => x.email === selected.item.email).length === 0
          if (attendeesAlreadyExist) {
            this.selectedEmployees.push(selected.item)
            this.listSelectedAttendees.push({
              name: selected.item.name,
              email: selected.item.email,
              id: selected.item.id || selected.item.objectID,
              status: 'ATTENDEE_STATUS_NEEDS_ACTION',
              contactPersons: 'contactPersons' in selected.item && selected.item.contactPersons?.length > 0 ? selected.item.contactPersons.filter(cp => cp.active) : null,
              selectedContactPerson: null,
              statusEdit: false
            })

            this.$nextTick(function () {
              this.searchPartipant = ''
              this.$refs.autosuggest.internalValue = ''
              this.searchPartipantValidation = true
            })
          }
        } else {
          this.$nextTick(function () {
            this.searchPartipant = ''
            this.$refs.autosuggest.internalValue = ''
            this.searchPartipantValidation = true
          })
        }
      }
    },
    removeInvitation(item) {
      if (item.id) {
        this.listSelectedAttendees.forEach((element, index) => {
          if (element.id === item.id) {
            this.listSelectedAttendees.splice(index, 1)
          }
        }, this)
      } else {
        if (item.email !== '') {
          this.listSelectedAttendees.forEach((element, index) => {
            if (element.email === item.email) {
              this.listSelectedAttendees.splice(index, 1)
            }
          }, this)
        } else if (item.name) {
          this.listSelectedAttendees.forEach((element, index) => {
            if (element.name === item.name) {
              this.listSelectedAttendees.splice(index, 1)
            }
          }, this)
        }
      }
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name
    },
    deleteEvent(type) {
      this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))

      const occurrenceEventDate = type === 'single' && this.eventDetail.occurrenceEventDate ? this.eventDetail.occurrenceEventDate : null

      CalendarApi.deleteEvent(this.eventDetail.url || this.event.url, this.eventDetail.mailBox || this.event.mailBox, type, occurrenceEventDate)
        .then(() => {
          this.$refs['delete-confirmation-modal']?.hide()
          this.hide()
          this.$emit('remove-event', this.eventDetail, type === 'single')
        })
        .catch(e => {
          console.log(e)
          this.loading = false
          Vue.toasted.show(this.$t('message.savingErrors.calendar'), { type: 'error' })
        })
    },
    indicesToSuggestions(indices) {
      const merged = indices.map(({ hits }) => ({
        data: hits.concat.apply([], hits.map(x => x.contactPersons ? x.contactPersons : []))
      }))
      if (this.reference && this.reference.contactPersons) {
        return indices.map(({ hits }) => ({
          data: hits.filter(x => x.email !== (this.organizer?.email || this.eventDetail.organizer?.email)).concat(this.reference.contactPersons).concat(merged.length > 0 ? merged[0].data : [])
        }))
      } else {
        return indices.map(({ hits }) => ({
          data: hits.filter(x => x.email !== (this.organizer?.email || this.eventDetail.organizer?.email)).concat(merged.length > 0 ? merged[0].data : [])
        }))
      }
    },
    onChange(refine, email) {
      this.searchPartipantValidation = true
      refine(email)
    },
    editAttendeeStatus(action) {
      let status = ''
      if (action === 'accept') {
        status = 'ATTENDEE_STATUS_ACCEPTED'
      } else {
        status = 'ATTENDEE_STATUS_DECLINED'
      }
      if (this.organizer?.email || this.eventDetail.organizer?.email) {
        if (this.organizer?.email === this.userEmail || this.eventDetail.organizer?.email === this.userEmail) {
          this.organizer.status = status
        } else {
          const element = this.listSelectedAttendees.findIndex(x => x.email === this.userEmail)
          if (element !== -1) {
            this.listSelectedAttendees[element].status = status
          }
        }
        this.onEditStatusAttendeeClicked(this.userEmail)
      }
    },
    onEditStatusAttendeeClicked(email, isGlobal = false) {
      if (!this.eventDetail) {
        const attendeeIndex = this.listSelectedAttendees.findIndex(x => x.email === email || x.name === email)
        if (attendeeIndex !== -1) {
          this.listSelectedAttendees[attendeeIndex].statusEdit = false
        }
      } else {
        const organizer = JSON.parse(JSON.stringify(this.organizer)) || null
        if (organizer && this.userContact?.email === organizer.email) {
          organizer.id = this.userContact.id
        }
        const list = this.listSelectedAttendees.filter(x => organizer ? x.email !== organizer?.email : x.email !== this.eventDetail.organizer?.email)
        const attendees = list.map(function (x) {
          return {
            displayName: x.lastName && x.firstName ? x.lastName + ' ' + x.firstName : x.name,
            email: x.email,
            contactId: x.selectedContactPerson ? x.selectedContactPerson : x.id ? x.id : x.objectID,
            status: x.status ? x.status : 'ATTENDEE_STATUS_NEEDS_ACTION'
          }
        })
        const eventId = this.eventDetail.url || this.eventDetail.id

        // for the case when Propbase user invited in 1 occurrence of an external recurrence event
        let occurrenceEventDate = null
        if (this.eventDetail.occurrenceEventDate && (((this.attendeeToEdit || this.newStatusButton !== '') && !this.editAllRecurringEvents) || (this.eventDetail.countRecurrence === 0 && this.eventDetail.frequency === 'EVENT_FREQUENCY_UNDEFINED' && !this.eventDetail.endByDateRecurrence))) {
          occurrenceEventDate = this.eventDetail.occurrenceEventDate
        }

        CalendarApi.updateAttendeesStatus(eventId, attendees, occurrenceEventDate)
          .then(() => {
            if (!isGlobal) {
              const attendeeIndex = this.listSelectedAttendees.findIndex(x => x.email === email || x.name === email)
              if (attendeeIndex !== -1) {
                this.listSelectedAttendees[attendeeIndex].statusEdit = false
              }
            }
            this.$emit('attendees-changed')
            if (this.source === 'email') {
              this.$emit('hide-modal')
            }
          })
          .catch(e => {
            console.log(e)
            this.loading = false
            Vue.toasted.show(this.$t('message.savingErrors.calendar'), { type: 'error' })
          })
      }
    },
    hasWorkEmail(id) {
      const item = this.selectedEmployees.find(item => item.id === id || item.objectID === id)
      return this.listEmails(id).length > 0 && item.workEmail && item.workEmail.use && item.workEmail.email !== ''
    }
  },
  validations() {
    const vali = {
      event: {
        startDate: {
          required
        },
        endDate: {
          required,
          moreThanStarting
        }
      }
    }
    if (this.checkSelectedEmloyees) {
      vali.event.mailBox = {
        mailboxRequired
      }
    }
    if (this.event.frequency !== 'EVENT_FREQUENCY_UNDEFINED' && this.event.recurringEvent) {
      vali.event.startDateRecurrence = {
        required
      }
      if (this.endingRecurrence === 'endAfter') {
        vali.event.countRecurrence = {
          required,
          maxValue: maxValue(999)
        }
      }
    }
    return vali
  }
}
</script>
<style lang="scss" scoped>
  :deep(.default-border) {
    border: 1px solid #ced4da;
  }
  :deep(.red-border) {
    border: 1px solid $color-red;
  }
  .title-name {
    line-height: 2;
  }
  .close-img{
    width: 15px;
  }
  :deep(table.table > thead > tr > th) {
    background-color: rgba(0, 68, 95, .06);
  }
  .uploaded-file {
    max-height: 100px;
    background-position: center center;
    background-size: cover;
    min-height: 5em;
  }
  .list-emails-select {
    max-width: 220px;
    margin-left: 1rem;
  }

  .img .media-block:first-child,
  .video .media-block:first-child {
    padding-left: 0 !important;
  }

  .img .media-block:last-child,
  .video .media-block:last-child {
    padding-right: 0 !important;
  }

  @media (min-width: 768px) {
    .uploaded-file {
      max-height: 100px;
      min-height: 3em;
    }
  }

  @media (min-width: 992px) {
    .uploaded-file {
      max-height: 110px;
      min-height: 3.5em;
    }
  }

  @media (min-width: 1200px) {
    .uploaded-file {
      max-height: 120px;
      min-height: 5em;
    }
  }

  @media (min-width: 1600px) {
    .uploaded-file {
      max-height: 130px;
      min-height: 6em;
    }
  }
  @media (min-width: 1900px) {
    .uploaded-file {
      max-height: 130px;
      min-height: 7em;
    }
  }
  .eventLocation :deep(.btn-outline) {
    width: 100%;
    color: #515151;
    background-color: #fff;
    border-color: #ced4da;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .eventLocation.selected :deep(.btn-outline) {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }

  .description-text {
    word-wrap: break-word;
  }
</style>
