<template>
  <div>
    <b-table
      ref="deviceTable"
      bordered
      hover
      responsive="true"
      stacked="md"
      selectable
      select-mode="single"
      :fields="fields"
      :items="displayedDevices">
      <template #head(title)>
        {{ $t('message.onBoarding.devices.description') }}
      </template>
      <template #head(type)>
        <coozzy-sort-dropdown
          ref="type_sort"
          sorting-field="type"
          initial-sorting="desc"
          :text="$t('message.onBoarding.devices.type')"
          @updateSorting="updateSorting" />
      </template>
      <template #head(brand)>
        {{ $t('message.onBoarding.devices.brand') }}
      </template>
      <template #head(model)>
        {{ $t('message.onBoarding.devices.model') }}
      </template>
      <template #head(acquisitionCosts)>
        <coozzy-sort-dropdown
          ref="acquisitionCosts_sort"
          sorting-field="acquisitionCosts"
          initial-sorting="desc"
          :text="$t('message.onBoarding.devices.acquisitionCosts')"
          @updateSorting="updateSorting" />
      </template>
      <template #head(installation)>
        <coozzy-sort-dropdown
          ref="installation_sort"
          sorting-field="installation"
          initial-sorting="desc"
          :text="$t('message.onBoarding.devices.installation')"
          @updateSorting="updateSorting" />
      </template>
      <template #head(uninstallation)>
        <coozzy-sort-dropdown
          ref="uninstallation_sort"
          sorting-field="uninstallation"
          initial-sorting="desc"
          :text="$t('message.onBoarding.devices.uninstallation')"
          @updateSorting="updateSorting" />
      </template>
      <template #head(warrantyExpiration)>
        <coozzy-sort-dropdown
          ref="warrantyExpiration_sort"
          sorting-field="warrantyExpiration"
          initial-sorting="desc"
          :text="$t('message.onBoarding.devices.warrantyExpiration')"
          @updateSorting="updateSorting" />
      </template>
      <template #head(nextMaintenance)>
        <coozzy-sort-dropdown
          ref="nextMaintenance_sort"
          sorting-field="nextMaintenance"
          initial-sorting="desc"
          :text="$t('message.onBoarding.devices.maintenance.nextControl')"
          @updateSorting="updateSorting" />
      </template>
      <template #head(maintenanceCosts)>
        <coozzy-sort-dropdown
          ref="maintenanceCosts_sort"
          sorting-field="maintenanceCosts"
          initial-sorting="desc"
          :text="$t('message.onBoarding.devices.maintenance.costs.maintenanceCosts')"
          @updateSorting="updateSorting" />
      </template>
      <!-- Body -->
      <template #cell(title)="data">
        <template v-if="data.item.referenceId.startsWith('prop_')">
          {{ $t('message.propertiesView.property') }}
        </template>
        <template v-else-if="data.item.referenceId.startsWith('building_')">
          {{ $t('message.onBoardingTabs.building') + ' ' + getBuildingName(data.item.referenceId) }}
        </template>
        <template v-else-if="data.item.referenceId.startsWith('obj_')">
          {{
            $t('message.onBoarding.buildings.objects.titleObject') + ' ' + getObjectName(data.item.referenceId)
          }}
        </template>
      </template>
      <template #cell(type)="data">
        <template v-if="data.item.type && data.item.type !== 'DEVICE_TYPE_UNDEFINED'">
          {{ $t('message.onBoarding.devices.typeValues.' + data.item.type) }}
        </template>
        <template v-else>
          -
        </template>
      </template>
      <template #cell(brand)="data">
        {{ data.item.brand | displayOptionalValue }}
      </template>
      <template #cell(acquisitionCosts)="data">
        {{ data.item.acquisitionCosts | formatPrice }}
      </template>
      <template #cell(model)="data">
        {{ data.item.model | displayOptionalValue }}
      </template>
      <template #cell(installation)="data">
        {{ data.item.installation | objectToDate }}
      </template>
      <template #cell(uninstallation)="data">
        {{ data.item.uninstallation | objectToDate }}
      </template>
      <template #cell(warrantyExpiration)="data">
        {{ data.item.warrantyEnd | objectToDate }}
      </template>
      <template #cell(nextMaintenance)="data">
        {{ data.item.maintenance.nextMaintenance | objectToDate }}
      </template>
      <template #cell(maintenanceCosts)="data">
        {{ data.item.maintenance.costs.amount | formatPrice }}
      </template>
      <template
        #row-details="data"
        class="p-0">
        <div class="col-12 p-0">
          <coozzy-form-textarea
            ref="note"
            :key="data.item.id"
            v-model="data.item.orderDescription"
            :initial="data.item.orderDescription"
            :value-text-area="data.item.orderDescription"
            :show-label="false"
            name="note"
            :rows="5"
            :placeholder="$t('message.createOrder.orderDescription')"
            @blur="noteChanged(data.item.id, data.item.orderDescription)" />
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      align="center"
      class="mt-3 mb-3"
      :total-rows="devices.length"
      :per-page="5" />
  </div>
</template>

<script>
import CoozzySortDropdown from '@/framework/components/dropdown/CoozzySortDropdown'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea'
import { dateUtils } from '@/mixins/dateUtils'

export default {
  name: 'OrderDeviceTab',
  components: {
    CoozzyFormTextarea,
    CoozzySortDropdown
  },
  mixins: [dateUtils],
  props: {
    devices: {
      type: Array,
      required: true
    },
    objects: {
      type: Array,
      required: true
    },
    buildings: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      fields: [
        {
          key: 'title',
          label: this.$t('message.onBoarding.devices.description'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'type',
          label: this.$t('message.onBoarding.buildings.objects.type.title'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'brand',
          label: this.$t('message.onBoarding.devices.brand'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'model',
          label: this.$t('message.onBoarding.devices.model'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'acquisitionCosts',
          label: this.$t('message.onBoarding.devices.acquisitionCosts'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'installation',
          label: this.$t('message.onBoarding.devices.installation'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'uninstallation',
          label: this.$t('message.onBoarding.devices.uninstallation'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'warrantyExpiration',
          label: this.$t('message.onBoarding.devices.warrantyExpiration'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'nextMaintenance',
          label: this.$t('message.onBoarding.devices.maintenance.nextControl'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'maintenanceCosts',
          label: this.$t('message.onBoarding.devices.maintenance.costs.maintenanceCosts'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        }
      ]
    }
  },
  computed: {
    displayedDevices() {
      let list = this.devices
      for (let i = 0; i < list.length; i++) {
        if (list[i]) {
          list[i]._showDetails = true
          const l = this.devicesForApi && this.devicesForApi.length > 0 ? this.devicesForApi.find(x => x.id === list[i].id).note : null
          if (l) {
            list[i].orderDescription = l
          }
        }
      }
      list = list.slice((this.currentPage - 1) * 5, this.currentPage * 5)
      return list
    }
  },
  methods: {
    getObjectName(objectId) {
      const obj = this.objects.find(e => e.id === objectId)
      return obj ? obj.name : ''
    },
    getBuildingName(buildingId) {
      const building = this.buildings.find(e => e.id === buildingId)
      return building ? building.name : ''
    },
    noteChanged(id, note) {
      this.$emit('note-changed', id, note)
    },
    updateSorting(value, field) {
      if (field !== 'acquisitionCosts') {
        this.$refs.acquisitionCosts_sort.direction = ''
      }
      if (field !== 'installation') {
        this.$refs.installation_sort.direction = ''
      }
      if (field !== 'uninstallation') {
        this.$refs.uninstallation_sort.direction = ''
      }
      if (field !== 'warrantyExpiration') {
        this.$refs.warrantyExpiration_sort.direction = ''
      }
      if (field !== 'nextMaintenance') {
        this.$refs.nextMaintenance_sort.direction = ''
      }
      if (field !== 'maintenanceCosts') {
        this.$refs.maintenanceCosts_sort.direction = ''
      }
      if (field !== 'type') {
        this.$refs.type_sort.direction = ''
      }
      this.sortValue = value
      this.sortField = field
      this.$nextTick(function () {
        if (this.sortValue !== '' && this.sortField !== '') {
          this.displayedDevices.sort(this.filterDevices(this.sortField, this.sortValue))
        }
      })
    },
    filterDevices(key, order = 'asc') {
      const current = this
      return function innerSort(a, b) {
        let varA
        let varB
        if (key === 'installation') {
          varA = current.dateObjectToTimeStamp(a[key])
          varB = current.dateObjectToTimeStamp(b[key])
        } else if (key === 'uninstallation') {
          varA = current.dateObjectToTimeStamp(a[key])
          varB = current.dateObjectToTimeStamp(b[key])
        } else if (key === 'warrantyExpiration') {
          varA = current.dateObjectToTimeStamp(a.warrantyEnd)
          varB = current.dateObjectToTimeStamp(b.warrantyEnd)
        } else if (key === 'nextMaintenance') {
          varA = current.dateObjectToTimeStamp(a.maintenance.nextMaintenance)
          varB = current.dateObjectToTimeStamp(b.maintenance.nextMaintenance)
        } else if (key === 'maintenanceCosts') {
          varA = a.maintenance.costs.amount
          varB = b.maintenance.costs.amount
        } else if (key === 'type') {
          varA = current.$t('message.onBoarding.devices.typeValues.' + a[key])
          varB = current.$t('message.onBoarding.devices.typeValues.' + b[key])
        } else {
          varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase()
: a[key]
          varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase()
: b[key]
        }
        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
