<template>
  <section>
    <div>
      <coozzy-card
        :title="$t('message.createOrder.orderDetails')">
        <div class="row">
          <div class="col">
            <label for="employee">{{ $t('message.createOrder.orderCreator') + '*' }}</label>
            <coozzy-form-select
              id="employee"
              v-model="emitting.selectedEmployee"
              name="employee"
              :state="$v.emitting.selectedEmployee.$dirty && $v.emitting.selectedEmployee.$error ? false : null">
              <option value="">
                -
              </option>
              <option
                v-for="element in employeeContacts"
                :key="element.id"
                :value="element.id">
                {{ element.profile.lastName }} {{ element.profile.firstName }}
              </option>
            </coozzy-form-select>
          </div>
          <div class="col-12 mt-3">
            <label>{{ $t('message.createOrder.contactPersonOnSite') }} </label>
            <div class="row px-3 mt-3">
              <template>
                <coozzy-form-radio
                  v-if="employeeContacts.length > 0"
                  v-model="emitting.onSiteContactType"
                  :default-model="emitting.onSiteContactType"
                  name="typeContact"
                  class="col"
                  select-value="VIEWING_APPOINTMENT_CONTACT_TYPE_ASSIGNEE">
                  {{ $t('message.generic.user') }}
                </coozzy-form-radio>
                <coozzy-form-radio
                  v-if="tenantContacts.length > 0"
                  v-model="emitting.onSiteContactType"
                  :default-model="emitting.onSiteContactType"
                  name="typeContact"
                  class="col"
                  select-value="VIEWING_APPOINTMENT_CONTACT_TYPE_TENANT">
                  {{ $t('message.generic.tenant') }}
                </coozzy-form-radio>
                <coozzy-form-radio
                  v-if="condoOwnersContact.length > 0"
                  v-model="emitting.onSiteContactType"
                  :default-model="emitting.onSiteContactType"
                  name="typeContact"
                  class="col"
                  select-value="VIEWING_APPOINTMENT_CONTACT_TYPE_CONDO_OWNER">
                  {{ $t('message.generic.condo_owner') }}
                </coozzy-form-radio>
                <coozzy-form-radio
                  v-if="listJanitors.length > 0"
                  v-model="emitting.onSiteContactType"
                  :default-model="emitting.onSiteContactType"
                  name="typeContact"
                  class="col"
                  select-value="VIEWING_APPOINTMENT_CONTACT_TYPE_CARETAKER">
                  {{ $t('message.contact.types.janitor') }}
                </coozzy-form-radio>
                <coozzy-form-radio
                  v-if="serviceProviderContacts.length > 0"
                  v-model="emitting.onSiteContactType"
                  :default-model="emitting.onSiteContactType"
                  name="typeContact"
                  class="col"
                  select-value="VIEWING_APPOINTMENT_CONTACT_TYPE_SERVICE_PROVIDER">
                  {{ $t('message.contact.types.SERVICE_PROVIDER') }}
                </coozzy-form-radio>
                <coozzy-form-radio
                  v-if="craftsmanContacts.length > 0"
                  v-model="emitting.onSiteContactType"
                  :default-model="emitting.onSiteContactType"
                  name="typeContact"
                  class="col"
                  select-value="VIEWING_APPOINTMENT_CONTACT_TYPE_CRAFTSMAN">
                  {{ $t('message.contact.types.CRAFTSMAN') }}
                </coozzy-form-radio>
              </template>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div
                v-if="emitting.onSiteContactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_ASSIGNEE'"
                class="col-12 mt-3">
                <coozzy-form-select
                  v-model="onSiteContactPersonId"
                  name="numberEntries"
                  class="select-entries float-right">
                  <option value="">
                    -
                  </option>
                  <option :value="emitting.selectedEmployee">
                    {{ $t('message.createOrder.sameAsOrderCreator') }}
                  </option>
                  <option
                    v-for="(employee) in employeeContacts"
                    :key="employee.id"
                    :value="employee.id">
                    {{ employee.profile.lastName }} {{ employee.profile.firstName }}
                  </option>
                </coozzy-form-select>
              </div>
              <div
                v-if="emitting.onSiteContactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_TENANT'"
                class="col-12 mt-3">
                <b-table
                  ref="tenancyContactsTable"
                  hover
                  bordered
                  responsive="true"
                  stacked="md"
                  selectable
                  select-mode="single"
                  :fields="fieldsTenantsList"
                  :items="listContactTenancies"
                  class="overflow-auto shadow">
                  <!-- Busy state -->
                  <div
                    slot="table-busy"
                    class="text-center text-danger my-2">
                    <coozzy-spinner />
                  </div>

                  <!-- Headings -->
                  <template #head(checkbox)>
                    <coozzy-form-checkbox
                      ref="header_checkbox_tenancy_contacts"
                      @change="(value) => { handleCheckboxesInsideTenancyContactsTable(value) }" />
                  </template>
                  <template #head(name)>
                    {{ $t('message.generic.name') }}
                  </template>
                  <template #head(contactPerson)>
                    {{ $t('message.interestedParty.reference.landlord.contactPerson') }}
                  </template>
                  <template #head(type)>
                    {{ $t('message.generic.type') }}
                  </template>
                  <template #head(communication)>
                    {{ $t('message.generic.communication') }}
                  </template>
                  <template #head(action) />

                  <!-- Body -->
                  <template #cell(checkbox)="data">
                    <coozzy-form-checkbox
                      :id="'check_box_' + data.item.id"
                      @change="(value) => { tenancyContactSelected(data.item, value) }" />
                  </template>
                  <template #cell(name)="data">
                    {{ data.item.name + (isFutureTenant(data.item.id) ? ' ' + $t('message.createOrder.futureTenant') : '') }}
                  </template>
                  <template #cell(contactPerson)="data">
                    <coozzy-form-select
                      v-if="data.item.contactPersons"
                      id="contactPerson"
                      :ref="'contactPerson_' + data.item.id"
                      v-model="data.item.selectedContactPerson"
                      @change="changeTenantContact(data.item)">
                      <option :value="null">
                        -
                      </option>
                      <option
                        v-for="contact of data.item.contactPersons"
                        :key="contact.id"
                        :value="contact.id">
                        {{ contact.name }}
                      </option>
                    </coozzy-form-select>
                    <span v-else>
                      -
                    </span>
                  </template>
                  <template #cell(type)="data">
                    {{ data.item.type }}
                  </template>
                  <template #cell(communication)="data">
                    <div
                      v-if="getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') !== '-'"
                      class="row">
                      <div class="col-1">
                        <coozzy-mobile-alt-icon />
                      </div>
                      <div class="col">
                        <a
                          class="link"
                          :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'MOBILE')">
                          {{ getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') }}
                        </a>
                      </div>
                    </div>
                    <div
                      v-if="getPhoneNumbers(data.item.phoneNumbers, 'HOME') !== '-'"
                      class="row">
                      <div class="col-1">
                        <coozzy-phone-icon />
                      </div>
                      <div class="col">
                        <a
                          class="link"
                          :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'HOME')">
                          {{ getPhoneNumbers(data.item.phoneNumbers, 'HOME') }}
                        </a>
                      </div>
                    </div>
                    <div
                      v-if="getPhoneNumbers(data.item.phoneNumbers, 'WORK') !== '-'"
                      class="row">
                      <div class="col-1">
                        <coozzy-headset-icon />
                      </div>
                      <div class="col">
                        <a
                          class="link"
                          :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'WORK')">
                          {{ getPhoneNumbers(data.item.phoneNumbers, 'WORK') }}
                        </a>
                      </div>
                    </div>
                    <div
                      v-if="getPhoneNumbers(data.item.phoneNumbers, 'FAX') !== '-'"
                      class="row">
                      <div class="col-1">
                        <coozzy-fax-icon />
                      </div>
                      <div class="col">
                        <a
                          class="link"
                          :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'FAX')">
                          {{ getPhoneNumbers(data.item.phoneNumbers, 'FAX') }}
                        </a>
                      </div>
                    </div>
                    <div
                      v-if="data.item.email !== ''"
                      class="row">
                      <div class="col-1">
                        <img
                          class="email-icon-img"
                          src="@/assets/icon/arroba.svg"
                          alt="Placeholder">
                      </div>
                      <div class="col">
                        <template v-if="data.item.email !== ''">
                          <a
                            class="link"
                            tabindex="0"
                            @click="sendEmailModal(data.item)">
                            {{ data.item.email }}
                          </a>
                        </template>
                        <template v-else>
                          {{ data.item.email | displayOptionalValue }}
                        </template>
                      </div>
                    </div>
                    <div
                      v-if="data.item.website !== ''"
                      class="row">
                      <div class="col-1">
                        <coozzy-globe-icon />
                      </div>
                      <div class="col">
                        <template v-if="data.item.website !== ''">
                          <a
                            class="link"
                            tabindex="0"
                            target="_blank"
                            :href="getUrlWebsite(data.item.website)">
                            {{ data.item.website }}
                          </a>
                        </template>
                        <template v-else>
                          {{ data.item.website | displayOptionalValue }}
                        </template>
                      </div>
                    </div>
                  </template>
                  <template #cell(action)="data">
                    <coozzy-button
                      class=""
                      @click="selectContactToEdit(data.item, 'statusUpdate')">
                      <coozzy-edit-icon class="too-left" />
                    </coozzy-button>
                  </template>
                </b-table>
              </div>
              <div
                v-if="emitting.onSiteContactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_CONDO_OWNER'"
                class="col-12 mt-3">
                <coozzy-form-select
                  v-model="selectedCondoOwner"
                  name="numberEntries"
                  class="select-entries float-right">
                  <option value="">
                    -
                  </option>
                  <option
                    v-for="(tenant) in condoOwnersContact"
                    :key="tenant.id"
                    :value="tenant.id">
                    {{ getContactName(tenant.id) + (isFutureCondoOwner(tenant.id) ? ' ' + $t('message.createOrder.futureTenant') : '') }}
                  </option>
                </coozzy-form-select>
                <label
                  v-if="listCondoOwnerCompany.length > 0">{{ $t('message.createOrder.contactPersonOfCompany') }} </label>
                <coozzy-form-select
                  v-if="listCondoOwnerCompany.length > 0"
                  v-model="onSiteContactPersonId"
                  name="employeeCompany"
                  class="select-entries float-right">
                  <option value="">
                    -
                  </option>
                  <option
                    v-for="(tenant) in listCondoOwnerCompany"
                    :key="tenant.id"
                    :value="tenant.id">
                    {{ tenant.name }}
                  </option>
                </coozzy-form-select>
              </div>
              <div
                v-if="emitting.onSiteContactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_CARETAKER'"
                class="col-12 mt-3">
                <coozzy-form-select
                  v-model="selectedUser"
                  name="numberEntries"
                  class="select-entries float-right">
                  <option value="">
                    -
                  </option>
                  <option
                    v-for="(jani) in listJanitors"
                    :key="jani.id"
                    :value="jani.id">
                    {{ getContactName(jani.id) }}
                  </option>
                </coozzy-form-select>
                <label
                  v-if="listEmployeeCompany.length > 0">{{ $t('message.createOrder.contactPersonOfCompany') }} </label>
                <coozzy-form-select
                  v-if="listEmployeeCompany.length > 0"
                  v-model="onSiteContactPersonId"
                  name="employeeCompany"
                  class="select-entries float-right">
                  <option value="">
                    -
                  </option>
                  <option
                    v-for="(jani) in listEmployeeCompany"
                    :key="jani.id"
                    :value="jani.id">
                    {{ jani.name }}
                  </option>
                </coozzy-form-select>
              </div>
              <div
                v-if="emitting.onSiteContactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_SERVICE_PROVIDER'"
                class="col-12 mt-3">
                <coozzy-form-select
                  v-model="selectedServiceProvider"
                  name="numberEntries"
                  class="select-entries float-right">
                  <option value="">
                    -
                  </option>
                  <option
                    v-for="(jani) in serviceProviderContacts"
                    :key="jani.id"
                    :value="jani.id">
                    {{ getContactName(jani.id) }}
                  </option>
                </coozzy-form-select>
                <label
                  v-if="listServiceProviderCompany.length > 0">{{ $t('message.createOrder.contactPersonOfCompany') }} </label>
                <coozzy-form-select
                  v-if="listServiceProviderCompany.length > 0"
                  v-model="onSiteContactPersonId"
                  name="employeeCompany"
                  class="select-entries float-right">
                  <option value="">
                    -
                  </option>
                  <option
                    v-for="(jani) in listServiceProviderCompany"
                    :key="jani.id"
                    :value="jani.id">
                    {{ jani.name }}
                  </option>
                </coozzy-form-select>
              </div>
              <div
                v-if="emitting.onSiteContactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_CRAFTSMAN'"
                class="col-12 mt-3">
                <coozzy-form-select
                  v-model="selectedCraftsman"
                  name="numberEntries"
                  class="select-entries float-right">
                  <option value="">
                    -
                  </option>
                  <option
                    v-for="(jani) in craftsmanContacts"
                    :key="jani.id"
                    :value="jani.id">
                    {{ getContactName(jani.id) }}
                  </option>
                </coozzy-form-select>
                <label
                  v-if="listCraftsmanCompany.length > 0">{{ $t('message.createOrder.contactPersonOfCompany') }} </label>
                <coozzy-form-select
                  v-if="listCraftsmanCompany.length > 0"
                  v-model="onSiteContactPersonId"
                  name="employeeCompany"
                  class="select-entries float-right">
                  <option value="">
                    -
                  </option>
                  <option
                    v-for="(jani) in listCraftsmanCompany"
                    :key="jani.id"
                    :value="jani.id">
                    {{ jani.name }}
                  </option>
                </coozzy-form-select>
              </div>
              <div
                v-if="onSiteContactToShow && emitting.onSiteContactType !== 'VIEWING_APPOINTMENT_CONTACT_TYPE_ASSIGNEE' && emitting.onSiteContactType !== 'VIEWING_APPOINTMENT_CONTACT_TYPE_TENANT'"
                class="col-12">
                <coozzy-alert
                  class="my-2"
                  variant="blue"
                  size="sm">
                  <div>
                    <div
                      class="d-inline-flex float-right">
                      <coozzy-button
                        class=""
                        @click="selectContactToEdit(onSiteContactToShow, 'contactPersonOnsite')">
                        <coozzy-edit-icon class="too-left" />
                      </coozzy-button>
                    </div>
                  </div>
                  <div
                    v-if="onSiteContactToShow"
                    class="">
                    <p
                      class="m-0">
                      <span>{{ onSiteContactToShow.name }}</span>
                    </p>
                    <p
                      v-if="onSiteContactToShow.email"
                      class="m-0">
                      <span>{{ onSiteContactToShow.email }}</span>
                    </p>
                    <p
                      v-if="onSiteContactToShow?.phone"
                      class="m-0">
                      <span>{{ onSiteContactToShow.phone }}</span>
                    </p>
                    <p
                      v-else-if="onSiteContactToShow.phoneNumbers && onSiteContactToShow.phoneNumbers.length > 0"
                      class="m-0">
                      <span
                        v-if="getPhoneNumbers(onSiteContactToShow.phoneNumbers, 'mobile') !== '-'">{{ getPhoneNumbers(onSiteContactToShow.phoneNumbers, 'mobile') }}<br></span>
                      <span
                        v-if="getPhoneNumbers(onSiteContactToShow.phoneNumbers, 'home') !== '-'">{{ getPhoneNumbers(onSiteContactToShow.phoneNumbers, 'home') }}<br></span>
                    </p>
                  </div>
                </coozzy-alert>
              </div>
            </div>
          </div>
          <div class="col mt-3">
            <coozzy-form-input
              v-model="dates.executeFrom"
              :filter="'formatDate'"
              type="date"
              :name="$t('message.createOrder.executeFrom')"
              @blur="dateToObject()" />
          </div>
          <div class="col mt-3">
            <coozzy-form-input
              v-model="dates.executeUntil"
              :filter="'formatDate'"
              type="date"
              :state="$v.emitting.executeUntil.$dirty && $v.emitting.executeUntil.$error ? false : null"
              :name="$t('message.createOrder.executeUntil')"
              @blur="dateToObject()" />
          </div>
          <div class="col mt-3">
            <label>{{ $t('message.ticketOverview.ticket.priority.title') }}</label>
            <v-select
              v-model="emitting.priority"
              :options="availablePriority"
              :clearable="false"
              class="v-select-custom"
              label="title">
              <template
                slot="option"
                slot-scope="option">
                <img
                  :src="`/img/priority/prio-${option.title}.svg`"
                  :alt="$t('message.ticketOverview.ticket.priority.' + option.title)"
                  height="15px">
                {{ $t('message.ticketOverview.ticket.priority.' + option.title) }}
              </template>
              <template
                slot="selected-option"
                slot-scope="option">
                <div class="selected-option">
                  <img
                    height="15"
                    :alt="$t('message.ticketOverview.ticket.priority.' + option.title)"
                    :src="`/img/priority/prio-${option.title}.svg`">
                  {{ $t('message.ticketOverview.ticket.priority.' + option.title) }}
                </div>
              </template>
            </v-select>
          </div>
          <div
            class="col-12 mt-3">
            <coozzy-form-input
              v-model="title"
              :state="$v.title && $v.title.$dirty && $v.title.$error ? false : null"
              :name="$t('message.tableColumnHeadings.title') + ' *'" />
          </div>
          <div
            class="col-12 mt-3">
            <label>{{ $t('message.generic.placeholder') }}</label>
            <p class="ml-2 mb-0">
              <span>#anrede# <br></span>
              <span>#eigentuemer# <br></span>
            </p>
          </div>
          <div class="col-12 mt-3">
            <label>{{ $t('message.settings.template.titleTicket') }}</label>
            <coozzy-form-select
              v-model="selectedTemplate"
              name="numberEntries">
              <option
                value="">
                -
              </option>
              <option
                v-for="template in sortedTemplate"
                :key="template.id"
                :value="template.id">
                {{ template.name }}
              </option>
            </coozzy-form-select>
          </div>
          <div class="col-sm-12 col-md-12 mt-3">
            <coozzy-form-textarea
              ref="orderDescription"
              v-model="emitting.orderDescription"
              :initial="emitting.orderDescription"
              :value-text-area="emitting.orderDescription"
              :text="emitting.orderDescription"
              name="orderDescription"
              :rows="5"
              :placeholder="$t('message.createOrder.orderDetails')">
              {{ $t('message.createOrder.orderDetails') }}
            </coozzy-form-textarea>
          </div>
          <div class="col-12 mt-3">
            <div class="row mb-2">
              <div class="col-12">
                <label for="attachment"> {{ $t('message.ticketOverview.ticket.attachment') }} ({{ filteredMediaList.length }})</label>
                <coozzy-form-file-input
                  id="file"
                  ref="coozzy-file-input"
                  multiple
                  accept="text/plain, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
      ,application/vnd.ms-powerpoint,  application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf,   image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx .pdf"
                  :disabled="uploading"
                  @change="addFile" />
                <div class="row mt-2 m-0">
                  <div class="col-12 p-0">
                    <div
                      v-if="countDocs > 0"
                      class="row mb-2">
                      <label
                        class="col-12 subtitle"> {{ $t('message.ticketOverview.attachement.document') }} ({{ countDocs }})</label>
                      <template
                        v-for="media in filteredMediaList">
                        <div
                          v-if="!(['image/jpeg', 'image/png', 'image/gif', 'video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1)"
                          :key="media.id"
                          class="col-12 square">
                          <div
                            class="file-block">
                            <a
                              data-v-09485260=""
                              tabindex="0"
                              :href="media.url"
                              target="_blank">
                              {{ media.filename }}
                            </a>
                            <coozzy-button
                              design="transparent"
                              class="delete-icon"
                              @click="deleteMedia($event, media.id)">
                              <coozzy-delete-icon />
                            </coozzy-button>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div
                      v-if="countImg > 0"
                      class="row m-0 mb-2">
                      <label class="col-12 p-0 subtitle"> {{ $t('message.ticketOverview.attachement.image') }} ({{ countImg }})</label>
                      <template
                        v-for="media in filteredMediaList">
                        <div
                          v-if="['image/jpeg', 'image/png', 'image/gif'].indexOf(media.mimeType) !== -1"
                          :key="media.id"
                          class="media-block col-2 square">
                          <div
                            class="uploaded-file w-100"
                            style="background-repeat: no-repeat;background-size: cover;background-position: center center;"
                            :style="{ 'background-image': 'url(' + media.url + ')' }"
                            @click="imageClicked(media)">
                            <coozzy-button
                              design="transparent"
                              class="delete-icon image"
                              @click="deleteMedia($event, media.id)">
                              <coozzy-delete-icon />
                            </coozzy-button>
                          </div>
                        </div>
                      </template>
                    </div>
                    <div
                      v-if="countVideo > 0"
                      class="row m-0">
                      <label class="col-12 p-0 subtitle"> {{ $t('message.ticketOverview.attachement.video') }} ({{ countVideo }})</label>
                      <template
                        v-for="media in filteredMediaList">
                        <div
                          v-if="['video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1"
                          :key="media.id"
                          class="media-block col-2 square">
                          <coozzy-thumbnail
                            v-if="getMediaUrl(media) !== null"
                            :key="media.id"
                            :video-src="getMediaUrl(media)"
                            :video-url="media.url"
                            class="uploaded-file cursor-pointer"
                            @imageClicked="openVideo(media)" />
                          <coozzy-button
                            design="transparent"
                            class="delete-icon video"
                            @click="deleteMedia($event, media.id)">
                            <coozzy-delete-icon />
                          </coozzy-button>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </coozzy-card>
      <coozzy-card
        v-if="source === 'device'"
        :title="$t('message.settings.template.titleContract')">
        <template-table
          :source="'tenancy'"
          :default-category="'TEMPLATE_CATEGORY_ORDERS'"
          @selected-contract="itemSelected" />
        <div
          v-if="!isTicketingEnabled"
          class="d-flex justify-content-end mr-4">
          <coozzy-form-checkbox
            :key="Math.random().toString(36).substr(2, 9)"
            v-model="attachAuto"
            :initial="attachAuto"
            class="">
            {{ $t('message.settings.template.addDocumentToDevice') }}
          </coozzy-form-checkbox>
        </div>
      </coozzy-card>
      <!--   Status Updates Card   -->
      <coozzy-card
        :title="$tc('message.createOrder.statusUpdate.title')">
        <div
          class="">
          <p>{{ $tc('message.createOrder.statusUpdate.description') }}</p>
          <div class="row">
            <div class="col-4">
              <coozzy-form-radio
                v-model="sendStatusUpdates"
                :default-model="sendStatusUpdates"
                name="statusUpdates"
                :select-value="false">
                {{ $tc('message.createOrder.statusUpdate.doNotSendStatus') }}
              </coozzy-form-radio>
            </div>
            <div class="col-4">
              <coozzy-form-radio
                v-model="sendStatusUpdates"
                :default-model="sendStatusUpdates"
                name="statusUpdates"
                :select-value="true">
                {{ $tc('message.createOrder.statusUpdate.sendStatus') }}
              </coozzy-form-radio>
            </div>
            <div
              v-if="sendStatusUpdates"
              class="col-4">
              <coozzy-form-checkbox
                v-model="includeCorrespondenceContact">
                {{ $t('message.createOrder.includeCorrespondenceContact') }}
              </coozzy-form-checkbox>
            </div>
          </div>
          <div
            v-if="sendStatusUpdates"
            class="row">
            <div class="col-md-4 col-sm-6 mt-3">
              <coozzy-form-input
                ref="searchInputText"
                v-model="querySearch"
                :placeholder="$t('message.generic.search')"
                @keyup="searchInputText()" />
            </div>
            <div class="col-12 mt-3">
              <b-table
                ref="contactsStatusUpdatesTable"
                hover
                bordered
                responsive="true"
                stacked="md"
                selectable
                select-mode="single"
                :fields="fieldsStatusUpdate"
                :items="filteredStatusUpdatesContacts"
                class="overflow-auto shadow">
                <!-- Busy state -->
                <div
                  slot="table-busy"
                  class="text-center text-danger my-2">
                  <coozzy-spinner />
                </div>

                <!-- Headings -->
                <template #head(checkbox)>
                  <coozzy-form-checkbox
                    ref="header_checkbox_status_updates"
                    @change="(value) => { handleCheckboxesInsideStatusUpdatesTable(value) }" />
                </template>
                <template #head(name)>
                  {{ $t('message.generic.name') }}
                </template>
                <template #head(contactPerson)>
                  {{ $t('message.interestedParty.reference.landlord.contactPerson') }}
                </template>
                <template #head(type)>
                  {{ $t('message.generic.type') }}
                </template>
                <template #head(role)>
                  {{ $t('message.generic.role') }}
                </template>
                <template #head(email)>
                  {{ $t('message.generic.email') }}
                </template>
                <template #head(action) />

                <!-- Body -->
                <template #cell(checkbox)="data">
                  <coozzy-form-checkbox
                    :id="'check_' + data.item.id"
                    :initial="checkStatusCheckbox(data.item.id)"
                    @change="(value) => { contactSelected(data.item, value) }" />
                </template>
                <template #cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template #cell(contactPerson)="data">
                  <coozzy-form-select
                    v-if="data.item.contactPersons"
                    id="contactPerson"
                    :ref="'contactPerson_' + data.item.id"
                    v-model="data.item.selectedContactPerson"
                    @change="changeNotifiedEmail(data.item)">
                    <option :value="null">
                      -
                    </option>
                    <option
                      v-for="contact of data.item.contactPersons"
                      :key="contact.id"
                      :value="contact.id">
                      {{ contact.name }}
                    </option>
                  </coozzy-form-select>
                  <span v-else>
                    -
                  </span>
                </template>
                <template #cell(type)="data">
                  {{ data.item.type }}
                </template>
                <template #cell(role)="data">
                  {{ data.item.role }}
                </template>
                <template #cell(email)="data">
                  {{ data.item.email }}
                </template>
                <template #cell(action)="data">
                  <coozzy-button
                    class=""
                    @click="selectContactToEdit(data.item, 'statusUpdate')">
                    <coozzy-edit-icon class="too-left" />
                  </coozzy-button>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </coozzy-card>
    </div>
    <b-modal
      v-if="selectedObjectVideo !== ''"
      ref="show-video"
      hide-footer
      hide-header>
      <div class="row mb-2">
        <div class="col">
          <b-embed
            type="video"
            controls>
            <source
              :src="selectedObjectVideo"
              type="video/webm">
            <source
              :src="selectedObjectVideo"
              type="video/mp4">
          </b-embed>
        </div>
      </div>
    </b-modal>
    <create-contact-modal
      v-if="contactToEdit"
      :key="contactToEdit.id"
      ref="create-contact-modal"
      :for-owner-id="isOwnerModule ? accountId : null"
      :show-request-sources="false"
      :show-roles-input="false"
      :set-interested-party-role="false"
      :show-add-another="false"
      :contact-to-edit="contactToEdit"
      :enable-delete="false"
      @contact-updated="updateContact" />
    <contact-person-creation
      v-if="selectedPerson"
      ref="contact-person-modal"
      :key="selectedPerson ? 'modal-person'+selectedPerson.id : 'modal-person'"
      :company-contact="contactToEdit"
      :contact-type="typeOfContact"
      :selected-contact="selectedPerson"
      @edit-contact-person="contactPersonEdited" />
  </section>
</template>

<script>
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyFormRadio from '@/framework/components/form/radio/CoozzyFormRadio'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import TemplateTable from '@/settings/components/TemplateTable'
import { user } from '@/mixins/user'
import { contact } from '@/mixins/contact'
import { dateUtils } from '@/mixins/dateUtils'
import Vue from 'vue'
import MediaApi from '@/misc/apis/MediaApi'
import { media } from '@/mixins/media'
import CreateContactModal from '@/contact/components/CreateContactModal'
import ContactPersonCreation from '@/contact/components/ContactPersonCreation'
import { required } from 'vuelidate/lib/validators'
import CoozzyAlert from '@/framework/components/alert/CoozzyAlert.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import CoozzyThumbnail from '@/framework/components/img/CoozzyThumbnail.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import { tables } from '@/mixins/tables'
import { mapState } from 'vuex'
import MessageApi from '@/misc/apis/MessageApi'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'
import CoozzyHeadsetIcon from '@/framework/components/icons/CoozzyHeadsetIcon.vue'
import CoozzyFaxIcon from '@/framework/components/icons/CoozzyFaxIcon.vue'
import CoozzyMobileAltIcon from '@/framework/components/icons/CoozzyMobileAltIcon.vue'
import CoozzyPhoneIcon from '@/framework/components/icons/CoozzyPhoneIcon.vue'
import CoozzyGlobeIcon from '@/framework/components/icons/CoozzyGlobeIcon.vue'
import { withParams } from 'vuelidate'

function greaterThanExecuteFrom(value) {
  if (!value) {
    return true
  }
  if (!this.emitting.executeFrom) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(this.emitting.executeFrom))
}

export default {
  name: 'OrderDataTab',
  components: {
    CoozzyGlobeIcon,
    CoozzyPhoneIcon,
    CoozzyMobileAltIcon,
    CoozzyFaxIcon,
    CoozzyHeadsetIcon,
    CoozzyFormTextarea,
    CoozzySpinner,
    CoozzyThumbnail,
    CoozzyDeleteIcon,
    CoozzyFormFileInput,
    CoozzyFormCheckbox,
    CoozzyEditIcon,
    CoozzyButton,
    CoozzyAlert,
    TemplateTable,
    CoozzyCard,
    CoozzyFormInput,
    CoozzyFormRadio,
    CoozzyFormSelect,
    CreateContactModal,
    ContactPersonCreation
  },
  mixins: [user, dateUtils, media, contact, tables],
  props: {
    employeeContacts: {
      type: Array,
      required: true
    },
    tenantContacts: {
      type: Array,
      required: true
    },
    janitorContacts: {
      type: Array,
      required: true
    },
    craftsmanContacts: {
      type: Array,
      required: true
    },
    serviceProviderContacts: {
      type: Array,
      required: true
    },
    condoOwnersContact: {
      type: Array,
      required: true
    },
    ownersList: {
      type: Array,
      required: true
    },
    tenancies: {
      type: Array,
      default: () => {
        return []
      }
    },
    condoOwners: {
      type: Array,
      default: () => {
        return []
      }
    },
    source: {
      type: String,
      default: ''
    },
    objects: {
      type: Array,
      default: () => {
        return []
      }
    },
    references: {
      type: Array,
      default: () => {
        return []
      }
    },
    operator: {
      type: Object,
      default: null
    },
    rooms: {
      type: Array,
      default: () => {
        return []
      }
    },
    ticketTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      emitting: {
        selectedEmployee: '',
        onSiteContactType: 'VIEWING_APPOINTMENT_CONTACT_TYPE_ASSIGNEE',
        onSiteContactPersonIds: [],
        contact: {
          firstName: '',
          lastName: '',
          email: '',
          phone: ''
        },
        executeFrom: null,
        executeUntil: null,
        orderDescription: '',
        selectedContracts: '',
        priority: 'DEFAULT'
      },
      dates: {
        executeFrom: null,
        executeUntil: null
      },
      selectedUser: '',
      selectedTenants: [],
      selectedCondoOwner: '',
      selectedCraftsman: '',
      selectedServiceProvider: '',
      listEmployeeCompany: [],
      listCondoOwnerCompany: [],
      listCraftsmanCompany: [],
      listServiceProviderCompany: [],
      listJanitors: [],
      attachAuto: false,
      mediasList: [],
      countDocs: 0,
      countImg: 0,
      countVideo: 0,
      attachment: [],
      uploading: false,
      selectedObjectVideo: '',
      galleryImages: [],
      onSiteContactToShow: null,
      contactToEdit: null,
      selectedPerson: null,
      typeOfContact: null,
      includeCorrespondenceContact: false,
      title: '',
      availablePriority: ['LOW', 'DEFAULT', 'HIGH'],
      sendStatusUpdates: false,
      statusUpdatesContacts: [],
      filteredStatusUpdatesContacts: [],
      fieldsStatusUpdate: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'name',
          label: this.$t('message.generic.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'contactPerson',
          label: this.$t('message.interestedParty.reference.landlord.contactPerson'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'type',
          label: this.$t('message.generic.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'role',
          label: this.$t('message.generic.role'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'email',
          label: this.$t('message.generic.email'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'action',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      fieldsTenantsList: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'name',
          label: this.$t('message.generic.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'contactPerson',
          label: this.$t('message.interestedParty.reference.landlord.contactPerson'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'type',
          label: this.$t('message.generic.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'communication',
          label: this.$t('message.generic.communication'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'action',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      selectedContactsUpdatesStatus: [],
      querySearch: '',
      selectedTemplate: '',
      listContactTenancies: [],
      onSiteContactPersonId: ''
    }
  },
  computed: {
    ...mapState('message', ['templates']),
    sortedTemplate() {
      return this.templates.filter(x => x.type === 'TEMPLATE_TYPE_TICKET').slice().sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (b.name > a.name) {
          return -1
        }
        return 0
      })
    },
    combinedContacts() {
      return this.tenantContacts.concat(this.janitorContacts).concat(this.craftsmanContacts).concat(this.serviceProviderContacts).concat(this.condoOwnersContact)
    },
    filteredMediaList() {
      const array = this.mediasList
      array.sort((val1, val2) => {
        return parseInt(val2.uploadedAt) - parseInt(val1.uploadedAt)
      })
      if (array.length > 0) {
        array.forEach(element => {
          if (!(['image/jpeg', 'image/png', 'image/gif', 'video/webm', 'video/mp4'].indexOf(element.mimeType) !== -1)) {
            this.countDocs = this.countDocs + 1
          }
          if (['image/jpeg', 'image/png', 'image/gif'].indexOf(element.mimeType) !== -1) {
            this.countImg = this.countImg + 1
          }
          if (['video/webm', 'video/mp4'].indexOf(element.mimeType) !== -1) {
            this.countVideo = this.countVideo + 1
          }
        })
      }
      return array
    }
  },
  watch: {
    selectedTemplate: {
      deep: true,
      handler: async function (val) {
        if (val !== '') {
          const template = this.sortedTemplate.find(x => x.id === val)
          const list = []
          if (this.references.length > 0) {
            this.references.forEach(element => {
              if (element.id.startsWith('obj')) {
                let objectId = element.id.startsWith('obj') ? element.id : element.relatedIds.find(item => item.startsWith('obj'))
                list.push({
                  contactId: this.operator ? ('id' in this.operator ? this.operator.id : this.operator.objectID) : '',
                  objectId: objectId
                })
              }
              if (element.id.startsWith('building')) {
                list.push({
                  contactId: this.operator ? ('id' in this.operator ? this.operator.id : this.operator.objectID) : '',
                  buildingId: element.id
                })
              }
              if (element.id.startsWith('prop')) {
                list.push({
                  contactId: this.operator ? ('id' in this.operator ? this.operator.id : this.operator.objectID) : '',
                  propertyId: element.id
                })
              }
              if (element.id.startsWith('device')) {
                list.push({
                  contactId: this.operator ? ('id' in this.operator ? this.operator.id : this.operator.objectID) : '',
                  propertyId: element.relatedIds.find(item => item.startsWith('prop'))
                })
              }
              if (element.id.startsWith('room') && this.rooms.length > 0) {
                const room = this.rooms.find(x => x.id === element.id)
                if (room?.referenceId.startsWith('building')) {
                  list.push({
                    contactId: this.operator ? ('id' in this.operator ? this.operator.id : this.operator.objectID) : '',
                    buildingId: room.referenceId
                  })
                }
                if (room?.referenceId.startsWith('obj')) {
                  list.push({
                    contactId: this.operator ? ('id' in this.operator ? this.operator.id : this.operator.objectID) : '',
                    objectId: room.referenceId
                  })
                }
              }
            })
          } else if (this.operator) {
            list.push({
              contactId: this.operator ? ('id' in this.operator ? this.operator.id : this.operator.objectID) : ''
            })
          }
          this.emitting.orderDescription = ''
            if (list.length > 0) {
              await MessageApi.bulkRenderText(list, template.message, '').then(response => {
                if (response?.entries.length > 0) {
                  response?.entries.forEach(element => {
                    if (element?.text) {
                      this.emitting.orderDescription += element.text + '\n'
                    }
                  })
                }
              })
            } else {
              this.emitting.orderDescription = template.message
            }
        } else {
          this.emitting.orderDescription = ''
        }
      }
    },
    attachAuto: {
      deep: true,
      handler: function (val) {
        this.$emit('update-attach-auto', val)
      }
    },
    emitting: {
      deep: true,
      handler: function () {
        this.$emit('updated', this.emitting)
      }
    },
    dates: {
      deep: true,
      handler: function () {
        this.$v.$reset()
      }
    },
    onSiteContactPersonId: {
      deep: true,
      handler: function (value) {
        if (value !== '') {
          this.emitting.onSiteContactPersonIds = [value]
            this.combinedContacts.forEach((element) => {
              if (element.id === value) {
                this.onSiteContactToShow = element
                // this.contactToEdit = element
              } else if ('contactPersons' in element && element.contactPersons?.length > 0) {
                element.contactPersons.forEach((contact) => {
                  if (contact.id === value) {
                    this.onSiteContactToShow = contact
                    this.selectedPerson = contact
                  }
                })
              }
            })
        } else {
          if (this.emitting.onSiteContactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_CARETAKER') {
            if (this.selectedUser !== '') {
              this.onSiteContactPersonId = this.selectedUser
            }
          }
          if (this.emitting.onSiteContactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_SERVICE_PROVIDER') {
            if (this.selectedServiceProvider !== '') {
              this.onSiteContactPersonId = this.selectedServiceProvider
            }
          }
          if (this.emitting.onSiteContactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_CRAFTSMAN') {
            if (this.selectedCraftsman !== '') {
              this.onSiteContactPersonId = this.selectedCraftsman
            }
          }
        }
      }
    },
    selectedUser: function (value) {
      if (value !== '') {
        const item = this.janitorContacts.find(x => x.id === value)
        this.onSiteContactToShow = this.combinedContacts.filter(x => x.id === value)[0]
        if (item && !('contactPersons' in item)) {
          this.onSiteContactPersonId = value
          this.listEmployeeCompany = []
        } else {
          if (item && item.contactPersons.length > 0) {
            this.onSiteContactPersonId = value
            this.listEmployeeCompany = item.contactPersons
          } else {
            this.onSiteContactPersonId = value
            this.listEmployeeCompany = []
          }
        }
      } else {
        this.onSiteContactToShow = null
        this.onSiteContactPersonId = ''
        this.listEmployeeCompany = []
      }
    },
    selectedTenants: function (value) {
      const ids = []
      value.forEach(contact => {
        this.listContactTenancies.forEach(tenancy => {
          if (tenancy.id === contact.id && !tenancy.selectedContactPerson) {
            ids.push(tenancy.id)
          } else if (tenancy.id === contact.id && tenancy.selectedContactPerson) {
            ids.push(tenancy.selectedContactPerson)
          }
        })
      })
      this.emitting.onSiteContactPersonIds = ids
    },
    selectedCondoOwner: function (value) {
      if (value !== '') {
        const item = this.condoOwnersContact.find(x => x.id === value)
        this.onSiteContactToShow = this.combinedContacts.filter(x => x.id === value)[0]
        if (item && !('contactPersons' in item)) {
          this.onSiteContactPersonId = value
          this.listCondoOwnerCompany = []
        } else {
          if (item && item.contactPersons.length > 0) {
            this.onSiteContactPersonId = value
            this.listCondoOwnerCompany = item.contactPersons
          } else {
            this.onSiteContactPersonId = value
            this.listCondoOwnerCompany = []
          }
        }
      } else {
        this.onSiteContactToShow = null
        this.onSiteContactPersonId = ''
        this.listCondoOwnerCompany = []
      }
    },
    selectedCraftsman: function (value) {
      if (value !== '') {
        const item = this.craftsmanContacts.find(x => x.id === value)
        this.onSiteContactToShow = this.combinedContacts.filter(x => x.id === value)[0]
        if (item && !('contactPersons' in item)) {
          this.onSiteContactPersonId = value
          this.listCraftsmanCompany = []
        } else {
          if (item && item.contactPersons.length > 0) {
            this.onSiteContactPersonId = value
            this.listCraftsmanCompany = item.contactPersons
          } else {
            this.onSiteContactPersonId = value
            this.listCraftsmanCompany = []
          }
        }
      } else {
        this.onSiteContactToShow = null
        this.onSiteContactPersonId = ''
        this.listCraftsmanCompany = []
      }
    },
    selectedServiceProvider: function (value) {
      if (value !== '') {
        const item = this.serviceProviderContacts.find(x => x.id === value)
        this.onSiteContactToShow = this.combinedContacts.filter(x => x.id === value)[0]
        if (item && !('contactPersons' in item)) {
          this.onSiteContactPersonId = value
          this.listServiceProviderCompany = []
        } else {
          if (item && item.contactPersons.length > 0) {
            this.onSiteContactPersonId = value
            this.listServiceProviderCompany = item.contactPersons
          } else {
            this.onSiteContactPersonId = value
            this.listServiceProviderCompany = []
          }
        }
      } else {
        this.onSiteContactToShow = null
        this.onSiteContactPersonId = ''
        this.listServiceProviderCompany = []
      }
    },
    'emitting.onSiteContactType': function () {
      this.onSiteContactToShow = null
      this.includeCorrespondenceContact = false

      // reset all selected contacts in different type
      this.selectedUser = ''
      this.selectedTenants = []
      this.selectedCondoOwner = ''
      this.selectedCraftsman = ''
      this.selectedServiceProvider = ''
    },
    janitorContacts: function () {
      this.listJanitors = this.janitorContacts.filter(x => x.types && x.types.includes('CARETAKER'))
    },
    combinedContacts: function () {
      const contacts = this.tenantContacts.concat(this.janitorContacts).concat(this.craftsmanContacts).concat(this.serviceProviderContacts).concat(this.condoOwnersContact)
      if (this.statusUpdatesContacts.length === 0 || contacts.length !== this.statusUpdatesContacts.length) {
        this.loadStatusUpdatesContacts()
        this.loadTenanciesContacts()
      } else {
        this.editStatusUpdatesContacts()
        this.editTenancyContacts()
      }
    },
    selectedContactsUpdatesStatus: function (value) {
      if (value.length === 0) {
        this.handleCheckboxesInsideStatusUpdatesTable(false)
      }
      const contacts = this.statusUpdatesContacts.filter(x => value.includes(x.id))
      this.$emit('update-status-contact', contacts)
    },
    sendStatusUpdates: function (value) {
      if (value === false) {
        this.selectedContactsUpdatesStatus = []
      }
      const contacts = this.statusUpdatesContacts.filter(x => this.selectedContactsUpdatesStatus.includes(x.id))
      this.$emit('update-status-contact', contacts)
    },
    filteredStatusUpdatesContacts: function () {
      this.filteredStatusUpdatesContacts.forEach((element) => {
        const j = this.statusUpdatesContacts.findIndex(x => x.id === element.id)
        if (j !== -1) {
          this.statusUpdatesContacts[j] = element
        }
      })
    },
    ticketTitle: {
      immediate: true,
      handler(value) {
        this.title = value
      }
    }
  },
  mounted() {
    const currentEmployee = this.employeeContacts.find(e => e.email === this.userEmail)
    if (currentEmployee) {
      this.emitting.selectedEmployee = currentEmployee.id
    }
    this.emitting.executeFrom = this.toObject(new Date())
    this.dates.executeFrom = this.$options.filters.objectToDateInput(this.emitting.executeFrom)
    if (this.janitorContacts.length > 0) {
      this.listJanitors = this.janitorContacts.filter(x => x.types && x.types.includes('CARETAKER'))
    }
    this.loadStatusUpdatesContacts()
    this.loadTenanciesContacts()
  },
  methods: {
    getUrlWebsite(item) {
      if (item.indexOf('//') > -1) {
        return item
      } else {
        return '//' + item
      }
    },
    getPhoneNumbers(phoneNumbers, type) {
      if (phoneNumbers && phoneNumbers.length > 0) {
        if (phoneNumbers.find(element => element.type?.toLowerCase() === type?.toLowerCase())) {
          return phoneNumbers.find(element => element.type.toLowerCase() === type.toLowerCase()).number
        }
      }
      return '-'
    },
    checkStatusCheckbox(id) {
      return this.selectedContactsUpdatesStatus.includes(id)
    },
    changeNotifiedEmail(contact) {
      if (contact.selectedContactPerson) {
        this.filteredStatusUpdatesContacts.forEach(x => {
          if (x.contactPersons && x.contactPersons.length > 0) {
            const contactPerson = x.contactPersons.find(item => item.id === contact.selectedContactPerson)
            if (contactPerson) {
              x.email = contactPerson.email
            }
          }
        })
      } else {
        this.filteredStatusUpdatesContacts.forEach(x => {
          if (x.id === contact.id) {
            const item = this.combinedContacts.find(item => item.id === contact.id)
            if (item) {
              x.email = item.email
            }
          }
        })
      }
    },
    changeTenantContact(contact) {
      if (contact.selectedContactPerson) {
        this.listContactTenancies.forEach(x => {
          if (x.contactPersons && x.contactPersons.length > 0) {
            const contactPerson = x.contactPersons.find(item => item.id === contact.selectedContactPerson)
            if (contactPerson) {
              x.email = contactPerson.email
              x.phoneNumbers = contactPerson.phoneNumbers.length > 0 ? contactPerson.phoneNumbers : x.phoneNumbers
            }
            this.selectedTenants = this.selectedTenants.filter(x => x.id !== contact.id)
            this.selectedTenants.push(x)
          }
        })
      } else {
        this.listContactTenancies.forEach(x => {
          if (x.id === contact.id) {
            const item = this.combinedContacts.find(item => item.id === contact.id)
            if (item) {
              x.email = item.email
              x.phoneNumbers = item.phoneNumbers
            }
            this.selectedTenants = this.selectedTenants.filter(x => x.id !== contact.id)
            this.selectedTenants.push(x)
          }
        })
      }
    },
    contactPersonEdited(item) {
      for (let index = 0; index < this.contactToEdit.contactPersons.length; index++) {
        if (this.contactToEdit.contactPersons[index].id === item.id) {
          this.contactToEdit.contactPersons[index] = item
          this.onSiteContactToShow = item
          this.selectedPerson = item

          const i = this.combinedContacts.findIndex(x => x.id === this.contactToEdit.id)
          if (i !== -1) {
            this.combinedContacts[i].contactPersons[index] = item
          }

          this.$emit('contact-updated', this.contactToEdit)
          break
        }
      }
    },
    showPerson() {
      if (!('contactPersons' in this.selectedPerson)) {
        this.typeOfContact = 'person'
      } else if (('contactPersons' in this.selectedPerson)) {
        this.typeOfContact = 'company'
      }
      this.$nextTick(function () {
        this.$refs['contact-person-modal'].show()
      })
    },
    updateContact(data) {
      if (data.contact && this.contactToEdit.id === data.contact.id) {
        this.onSiteContactToShow = data.contact

        const index = this.combinedContacts.findIndex(x => x.id === data.contact.id)
        if (index !== -1) {
          this.combinedContacts[index] = data.contact
        }

        this.$emit('contact-updated', data.contact)
      }
    },
    editContactClicked(from) {
      if (this.contactToEdit.id === this.onSiteContactToShow?.id || from === 'statusUpdate') {
        this.$refs['create-contact-modal'].show()
      } else {
        this.showPerson()
      }
    },
    imageClicked(item) {
      if (item.url && item.url !== '') {
        this.galleryImages = [
          item.url
        ]
        this.$nextTick(function () {
          this.$viewerApi({
            images: this.galleryImages,
            options: this.viewerOptions
          })
        })
      }
    },
    openVideo(media) {
      this.selectedObjectVideo = media.url
      this.$nextTick(() => {
        this.$refs['show-video'].show()
      })
    },
    getMediaUrl(media) {
      if (media.thumbnails === undefined || media.thumbnails === null || typeof media.thumbnails === 'undefined') {
        return null
      }
      for (const thumbnail of media.thumbnails) {
        if (thumbnail.type === 1 || thumbnail.type === 'M') {
          return thumbnail.url
        }
      }
      return media.url
    },
    addFile(event) {
      if (event.dataTransfer && event.dataTransfer.files.length > 0) {
        for (let index = 0; index < event.dataTransfer.files.length; index++) {
          const element = event.dataTransfer.files[index]
          this.attachment.push(element)
        }
        this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
      }
      if (event.target.files && event.target.files.length > 0) {
        for (let index = 0; index < event.target.files.length; index++) {
          const element = event.target.files[index]
          this.attachment.push(element)
        }
        this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
      }
      this.sendFile()
    },
    sendFile() {
      const promises = []
      this.uploading = true
      this.attachment.forEach(element => {
        if (element !== null) {
          const formData = new FormData()
          const fileName = this.editFileName(element.name)
          formData.append('file', element, fileName)
          formData.append('filename', element.name)
          formData.append('public', 'true')
          promises.push(MediaApi.uploadMedia(formData))
        }
      })
      Promise.all(promises)
        .then(response => {
          for (const media of response) {
            this.mediasList.push(media)
          }
        })
        .then(() => {
          this.attachment = []
          this.uploading = false
          this.$emit('medias-list-updated', this.mediasList)
          this.initCount()
          this.$nextTick(() => {
            this.$refs['coozzy-file-input'].resetPlaceholder()
          })
        })
        .catch(e => {
          console.log(e)
          if (e.response && e.response.data && e.response.data.message === 'Not supported MIME type') {
            Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
          } else {
            Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
          }
        })
        .finally(() => {
          this.uploading = false
        })
    },
    initCount() {
      this.countDocs = 0
      this.countImg = 0
      this.countVideo = 0
    },
    deleteMedia(e, id) {
      e.stopPropagation()
      if (this.mediasList) {
        this.mediasList = this.mediasList.filter(el => el.id !== id)
        this.$emit('medias-list-updated', this.mediasList)
        this.initCount()
      }
    },
    isFutureTenant(id) {
      if (id && this.tenancies.length > 0) {
        const tenant = this.tenancies.find(x => x.tenant?.contactIds?.includes(id))
        if (tenant) {
          const today = new Date()
          return new Date(this.$options.filters.objectToDateInput(tenant.startingAt)).getTime() >= today.getTime()
        }
        return false
      }
      return false
    },
    isFutureCondoOwner(id) {
      if (id && this.condoOwners.length > 0) {
        const tenant = this.condoOwners.find(x => x.owner?.contactIds?.includes(id))
        if (tenant) {
          const today = new Date()
          return new Date(this.$options.filters.objectToDateInput(tenant.startingAt)).getTime() >= today.getTime()
        }
        return false
      }
      return false
    },
    itemSelected(ids, contracts) {
      this.$emit('selected-contract', ids, contracts)
    },
    // getOwnersNames() {
    //   const promises = []
    //   if (this.ownersList.length > 0) {
    //     for (const id of this.ownersList) {
    //       promises.push(UserApi.getAccount(id))
    //     }
    //     Promise.all(promises).then((result) => {
    //       console.log('response :', result)
    //       const names = []
    //       result.forEach(response => {
    //         names.push(response.account?.company?.name)
    //       })
    //       console.log('names: ', names)
    //       this.$nextTick(() => {
    //         this.emitting.orderDescription = this.$t('message.createOrder.descriptionOrderDetails', { ownerBaseName: names.toString() })
    //       })
    //
    //     })
    //   }
    // },
    getContactName(contactId) {
      const contact = this.combinedContacts.find(contact => contact.id === contactId)
      return contact ? contact.name : '-'
    },
    dateToObject() {
      this.emitting.executeFrom = this.toObject(this.dates.executeFrom)
      this.emitting.executeUntil = this.toObject(this.dates.executeUntil)
    },
    getDatesFromObjects: function () {
      this.dates.executeFrom = this.$options.filters.objectToDateInput(this.emitting.executeFrom)
      this.dates.executeUntil = this.$options.filters.objectToDateInput(this.emitting.executeUntil)
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        if (this.$v.emitting.executeUntil.$dirty && this.$v.emitting.executeUntil.$error) {
          Vue.toasted.show(this.$t('message.savingErrors.executeUntilBeforeExecuteFrom'), { type: 'error' })
        }
        if (this.$v.selectedContactsUpdatesStatus && this.$v.selectedContactsUpdatesStatus.$dirty && this.$v.selectedContactsUpdatesStatus.$error) {
          Vue.toasted.show(this.$t('message.savingErrors.someContactNoEmail'), { type: 'error' })
        }
        return true
      }

      return false
    },
    handleCheckboxesInsideStatusUpdatesTable(value) {
      if (this.$refs.contactsStatusUpdatesTable) {
        const allCheckboxesComponents = this.$refs.contactsStatusUpdatesTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
      }
      if (this.$refs.header_checkbox_status_updates && value === false) {
        this.$refs.header_checkbox_status_updates.checked = false
      }
    },
    contactSelected(contact, checked) {
      if (checked) {
        this.selectedContactsUpdatesStatus.push(contact.id)
      } else {
        this.selectedContactsUpdatesStatus = this.selectedContactsUpdatesStatus.filter(entry => entry !== contact.id)
      }
      const contacts = this.statusUpdatesContacts.filter(x => this.selectedContactsUpdatesStatus.includes(x.id))
      this.$emit('update-status-contact', contacts)
    },
    handleCheckboxesInsideTenancyContactsTable(value) {
      if (this.$refs.tenancyContactsTable) {
        const allCheckboxesComponents = this.$refs.tenancyContactsTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
      }
      if (this.$refs.header_checkbox_tenancy_contacts && value === false) {
        this.$refs.header_checkbox_tenancy_contacts.checked = false
      }
    },
    tenancyContactSelected(contact, checked) {
      if (checked) {
        this.selectedTenants.push(contact)
      } else {
        this.selectedTenants = this.selectedTenants.filter(entry => entry.id !== contact.id)
      }
    },
    searchInputText() {
      this.filteredStatusUpdatesContacts = this.statusUpdatesContacts.filter(contact => {
        const cdtName = contact.name.toLowerCase().includes(this.querySearch.toLowerCase())
        const cdtType = contact.type.toLowerCase().includes(this.querySearch.toLowerCase())
        const cdtRole = contact.role.toLowerCase().includes(this.querySearch.toLowerCase())
        const cdtEmail = contact.email.toLowerCase().includes(this.querySearch.toLowerCase())
        return cdtName || cdtType || cdtRole || cdtEmail
      })
    },
    loadStatusUpdatesContacts() {
      this.statusUpdatesContacts = []
      this.combinedContacts.forEach(contact => {
        const tenancy = this.tenancies.find(tenancy => tenancy.tenant?.contactIds?.includes(contact.id))
        const object = this.objects.find(object => object.id === tenancy?.objectId) || null
        this.statusUpdatesContacts.push({
          id: contact.id,
          name: contact.name,
          type: object ? this.$t('message.onBoarding.buildings.objects.category.' + object.category.toLowerCase()) : '-',
          role: contact.types?.length > 0 ? contact.types.map(obj => this.$t('message.contact.types.' + obj.toUpperCase())).join(', ') : '',
          email: contact.email,
          contactPersons: 'contactPersons' in contact && contact.contactPersons?.length > 0 ? contact.contactPersons : null
        })
      })
      this.$nextTick(() => {
        // add the item 'selectedContactPerson' if it doesn't exist when reloading the contacts
        for (let i = 0; i < this.statusUpdatesContacts.length; i++) {
          if (!('selectedContactPerson' in this.statusUpdatesContacts[i])) {
            this.statusUpdatesContacts[i].selectedContactPerson = null
          }
        }
        const contacts = [...new Set(this.statusUpdatesContacts.map(x => x.id))].map(id => { return this.statusUpdatesContacts.find(a => a.id === id) })
        this.filteredStatusUpdatesContacts = JSON.parse(JSON.stringify(contacts))
      })
    },
    loadTenanciesContacts() {
      this.listContactTenancies = []
      const list = []
      this.tenantContacts.forEach(contact => {
        const tenancy = this.tenancies.find(tenancy => tenancy.tenant?.contactIds?.includes(contact.id))
        const object = this.objects.find(object => object.id === tenancy?.objectId) || null
        list.push({
          id: contact.id,
          name: contact.name,
          type: object ? this.$t('message.onBoarding.buildings.objects.category.' + object.category.toLowerCase()) : '-',
          role: contact.types?.length > 0 ? contact.types.map(obj => this.$t('message.contact.types.' + obj.toUpperCase())).join(', ') : '',
          email: contact.email,
          contactPersons: 'contactPersons' in contact && contact.contactPersons?.length > 0 ? contact.contactPersons : null,
          phoneNumbers: contact.phoneNumbers,
          website: contact.website
        })
      })
      this.$nextTick(() => {
        // add the item 'selectedContactPerson' if it doesn't exist when reloading the contacts
        for (let i = 0; i < list.length; i++) {
          if (!('selectedContactPerson' in list[i])) {
            list[i].selectedContactPerson = null
          }
        }
        const contacts = [...new Set(list.map(x => x.id))].map(id => { return list.find(a => a.id === id) })
        this.listContactTenancies = JSON.parse(JSON.stringify(contacts))
      })
    },
    editStatusUpdatesContacts() {
        this.statusUpdatesContacts.forEach((element) => {
          const contact = this.combinedContacts.find(x => x.id === element.id)
          if (contact) {
            element.name = contact.name
            element.role = contact.types?.length > 0 ? contact.types.map(obj => this.$t('message.contact.types.' + obj.toUpperCase())).join(', ') : ''
            element.email = contact.email
            element.contactPersons = 'contactPersons' in contact && contact.contactPersons?.length > 0 ? contact.contactPersons : null
          }
        })
        this.filteredStatusUpdatesContacts.forEach((element) => {
          const contact = this.combinedContacts.find(x => x.id === element.id)
          if (contact) {
            if (!element.selectedContactPerson) {
              element.name = contact.name
              element.role = contact.types?.length > 0 ? contact.types.map(obj => this.$t('message.contact.types.' + obj.toUpperCase())).join(', ') : ''
              element.email = contact.email
              element.contactPersons = 'contactPersons' in contact && contact.contactPersons?.length > 0 ? contact.contactPersons : null
            } else {
              const newContactPerson = contact.contactPersons.find(x => x.id === element.selectedContactPerson)
              if (newContactPerson) {
                element.email = newContactPerson.email
              }
            }
          }
        })
    },
    editTenancyContacts() {
        this.listContactTenancies.forEach((element) => {
          const contact = this.combinedContacts.find(x => x.id === element.id)
          if (contact) {
            if (!element.selectedContactPerson) {
              element.name = contact.name
              element.role = contact.types?.length > 0 ? contact.types.map(obj => this.$t('message.contact.types.' + obj.toUpperCase())).join(', ') : ''
              element.email = contact.email
              element.contactPersons = 'contactPersons' in contact && contact.contactPersons?.length > 0 ? contact.contactPersons : null
              element.phoneNumbers = contact.phoneNumbers
              element.website = contact.website
            } else {
              const newContactPerson = contact.contactPersons.find(x => x.id === element.selectedContactPerson)
              if (newContactPerson) {
                element.email = newContactPerson.email
                element.phoneNumbers = newContactPerson.phoneNumbers
                element.website = newContactPerson.website
              }
            }
          }
        })
    },
    selectContactToEdit(contact, from) {
      let isContactPerson = false
      if (this.combinedContacts.find(item => item.id === contact.id)) {
        isContactPerson = false
        this.contactToEdit = this.combinedContacts.find(item => item.id === contact.id)
      } else if (this.combinedContacts.find(item => 'contactPersons' in item && item.contactPersons?.find(x => x.id === contact.id))) {
        isContactPerson = true
        this.contactToEdit = this.combinedContacts.find(item => 'contactPersons' in item && item.contactPersons?.find(x => x.id === contact.id))
      }
      if (isContactPerson || ('selectedContactPerson' in contact && contact.selectedContactPerson !== null)) {
        if ('selectedContactPerson' in contact && contact.selectedContactPerson !== null) {
          this.selectedPerson = contact.contactPersons.find(x => x.id === contact.selectedContactPerson)
        } else {
          this.selectedPerson = contact
        }
        this.$nextTick(() => {
          this.showPerson()
        })
      } else {
        this.$nextTick(() => {
          this.editContactClicked(from)
        })
      }
    }
  },
  validations() {
    const validation = {
      emitting: {
        executeUntil: {
          greaterThanExecuteFrom
        },
        selectedEmployee: {
          required
        }
      },
      title: {
        required
      }
    }
    if (this.sendStatusUpdates && this.selectedContactsUpdatesStatus.length > 0) {
      validation.selectedContactsUpdatesStatus = {
        allContactHasEmail: withParams({ type: 'allContactHasEmail' }, (value) => {
          // Check each selected contact ID
          for (const contactId of value) {
            // Find the corresponding contact in combinedContacts
            const contact = this.combinedContacts.find(c => c.id === contactId)

            // If contact doesn't exist or doesn't have an email, validation fails
            if (!contact || !contact.email) {
              return false
            }
          }

          return true
        })
      }
    }
    return validation
  }
}
</script>

<style lang="scss" scoped>

.uploaded-file {
  max-height: 100px;
  background-position: center center;
  background-size: cover;
  min-height: 5em;
}
.file-block {
  display: flex;
  align-items: center;
}
.delete-icon.image {
  position: absolute;
  right: 15px;
}
.delete-icon.video {
  position: absolute;
  right: 15px;
  top: 5px;
}

.media-block {
  padding: 0.5rem;
  padding-left: 0;
}

@media (min-width: 768px) {
  .uploaded-file {
    max-height: 100px;
    min-height: 4em;
  }
}

@media (min-width: 992px) {
  .uploaded-file {
    max-height: 110px;
    min-height: 5em;
  }
}

@media (min-width: 1200px) {
  .uploaded-file {
    max-height: 120px;
    min-height: 5.5em;
  }
}

@media (min-width: 1600px) {
  .uploaded-file {
    max-height: 130px;
    min-height: 7em;
  }
}
:deep(.v-select-custom) > div {
  height: calc(1.5em + 0.5rem + 2px);
}
:deep(.selected-option) {
  line-height: 1;
}
</style>
