// @ts-nocheck
import network from './network'
import networkTS from '@/misc/apis/networkTS'
import { Accountv2Account } from '@/types/api'

export default {
  resolveToken(token) {
    return network.get(`/web/v2/user/resolveToken/${token}`)
      .then(response => {
        return response.data
      })
  },
  changeBilling(accountId, billing) {
    return network.post(`/web/v2/user/account/${accountId}/changeBilling`, {
      id: accountId,
      billableSetDate: billing.billableSetDate,
      billable: billing.billable,
      billingAddressAccountId: billing.billingAddressAccountId?.length ? billing.billingAddressAccountId[0].value : null,
      note: billing.note,
      contractStart: billing.contractStart,
      pricingModel: billing.pricingModel?.length ? billing.pricingModel[0].value : null
    })
      .then(response => {
        return response.data
      })
  },
  changeSubType(accountId, subType) {
    return network.post(`/web/v2/user/account/${accountId}/changeSubType`, { subType: subType })
      .then(response => {
        return response.data
      })
  },
  setFontSettings(accountId: String, fontSettings: { font: String, size: String }) {
    return networkTS.accountServiceSetFontSettings(accountId, fontSettings)
      .then(response => {
        return response.data
      })
  },
  getFontSettings(accountId: String) {
    return networkTS.accountServiceGetFontSettings(accountId)
      .then(response => {
        return response.data
      })
  },
  listAllEmployees(accountIds) {
    return network.post('/web/v2/user/listAllEmployees', { accountIds: accountIds })
      .then(response => {
        return response.data
      })
  },
  registerNewUser(token, password) {
    return network.post('web/v2/user/register', {
      token: token,
      password: password
    })
      .then(response => {
        return response.data
      })
  },
  getUser(userId) {
    return network.get(`/web/v2/user/get/${userId}`)
      .then(response => {
        return response.data
      })
  },
  changeEmail(userId, newEmail) {
    return network.post(`/web/v2/user/${userId}/changeEmail`, {
      newEmail: newEmail
    })
      .then(response => {
        return response.data
      })
  },
  changePassword(userId, newPassword) {
    return network.post(`/web/v2/user/${userId}/changePassword`, {
      newPassword: newPassword
    })
      .then(response => {
        return response.data
      })
  },
  updateUserProfile(userId, account) {
    const profile = {
      firstName: account.profile.firstName,
      lastName: account.profile.lastName,
      language: account.profile.language,
      gender: account.profile.gender,
      phoneNumber: account.profile.phoneNumber,
      birthday: account.profile.birthday,
      job: account.profile.job,
      pictureUrl: account.profile.pictureUrl,
      signatureAuthorization: account.profile.signatureAuthorization,
      signatureMediaId: account.profile.signatureMediaId
    }
    if (account.profile.color) {
      profile.color = account.profile.color
    }
    return network.post(`/web/v2/user/${userId}/updateProfile`, {
      profile: profile
    })
  },
  setCompanyData(accountId, company) {
    return network.post(`/web/v2/user/account/${accountId}/updateCompany`, { company: company })
      .then(response => {
        return response.data
      })
  },
  setIndividualData(accountId, individual) {
    return network.post(`/web/v2/user/account/${accountId}/updateIndividual`, { individual: individual })
      .then(response => {
        return response.data
      })
  },
  createAccount(type, subType, company, individual) {
    return network.post('/web/v2/user/account/create', {
      type: type,
      subType: subType,
      company: company,
      individual: individual
    }).then(response => {
      return response.data
    })
  },
  listAccounts(page, itemsPerPage, subTypes) {
    let url = `/web/v2/user/account/list/${page}?perPage=${itemsPerPage}`

    if (Array.isArray(subTypes)) {
      for (const subType of subTypes) {
        url += `&subTypes=${subType}`
      }
    }

    return network.get(url)
      .then(response => {
        return response.data
      })
  },
  getAccount(id) {
    return network.get(`/web/v2/user/account/get/${id}`)
      .then(response => {
        return response.data
      })
  },
  listAccountsByIds(accountIds) {
    if (accountIds.length === 0) {
      return new Promise(resolve => resolve({ accounts: [] as Accountv2Account[] }))
    }
    return networkTS.accountServiceListByIds(accountIds)
      .then(response => {
        return response.data
      })
  },
  getUserByMail(mail) {
    return networkTS.userServiceGetByMail(mail)
      .then(response => {
        return response.data
      })
  },
  setAccountLogo(id, logoUrl) {
    return network.post(`/web/v2/user/account/${id}/setLogo`, {
      logoUrl: logoUrl
    }).then(response => {
      return response.data
    })
  },
  updateStates(id, managementStates, marketingStates, accountingStates, assetStates) {
    return network.post(`/web/v2/user/account/${id}/updateStates`, {
      managementStates: managementStates,
      marketingStates: marketingStates,
      accountingStates: accountingStates,
      assetStates: assetStates
    }).then(response => {
      return response.data
    })
  },
  updateContactPerson(id, contactPerson) {
    return network.post(`/web/v2/user/account/${id}/updateContactPerson`, {
      id: id,
      contactPerson: contactPerson
    }).then(response => {
      return response.data
    })
  },
  getNotificationToken(userId) {
    return network.get(`web/v2/user/notification/getToken?userId=${userId}`).then(response => {
      return response.data
    })
  },
  publishUpdateNotification(messageDe, messageEn, messageIt) {
    return network.post('web/v2/user/notification/publishUpdateMessage', {
      message: messageDe,
      messageEn: messageEn,
      messageIt: messageIt
    }).then(response => {
      return response.data
    })
  },
  blockUser(userIds) {
    return network.post(`web/v2/user/${userIds}/block`).then(response => {
      return response.data
    })
  },
  unBlockUser(userIds) {
    return network.post(`web/v2/user/${userIds}/unBlock`).then(response => {
      return response.data
    })
  },
  postNotificationSettings(channels, ticketNotifications, userId) {
    return network.post('web/v2/user/notification/settings', {
      channels: channels,
      ticketNotifications: ticketNotifications,
      userId: userId
    }).then(response => {
      return response.data
    })
  },
  getNotificationSettings(userId) {
    return network.get(`web/v2/user/notification/settings?userId=${userId}`).then(response => {
      return response.data
    })
  },
  getPortalStatus(accountId) {
    return network.get(`/web/user/portal/hasPortalEnabled/${accountId}`).then(response => {
      return response.data
    })
  },
  updatePortalStatus(accountIds, enable) {
    return network.post('/web/user/portal/enablePortal', {
      accountIds: accountIds,
      enable: enable
    }).then(response => {
      return response.data
    })
  }
}
