<template>
  <section>
    <div>
      <!-- Heading -->
      <coozzy-page-title
        :title="$t('message.briefButler.title')" />
      <coozzy-card>
        <div
          v-if="loading"
          class="row">
          <div class="col text-center">
            <coozzy-spinner class="m-3" />
          </div>
        </div>
        <div
          v-else
          class="row">
          <div class="col-sm-12 col-md-12 mb-2">
            {{ $t('message.briefButler.modal.howSendDoc') }}
          </div>
          <div class="col-sm-12 col-md-12 mb-2">
            <template
              v-for="(item, itemIndex) in profiles">
              <div
                :key="`item_${itemIndex}`"
                class="col-6 pl-0 pr-0">
                <coozzy-form-radio
                  v-model="selectedProfile"
                  :default-model="selectedProfile"
                  :select-value="item.briefbutlerId"
                  :name="item.briefbutlerId"
                  :state="$v.selectedProfile.$dirty && $v.selectedProfile.$error ? false : null"
                  class="mt-2">
                  {{ item.description }}
                </coozzy-form-radio>
              </div>
            </template>
          </div>
          <div class="col-sm-12 col-md-6 mb-2">
            <label for="file">{{ $t('message.manageDocuments.document') + ' ' + $t('message.briefButler.onlyPdf') + ' *' }}</label>
            <coozzy-form-file-input
              id="file"
              ref="file-input"
              v-model="file"
              accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
              :state="$v.file.$dirty && $v.file.$error ? false : null"
              :disabled="uploading" />
          </div>
          <div
            v-if="filteredMediaList.length > 0"
            class="col-12">
            <div
              class="row mb-2">
              <label
                class="col-12 subtitle"> {{ $t('message.ticketOverview.attachement.document') }} ({{ filteredMediaList.length }})</label>
              <template
                v-for="media in filteredMediaList">
                <div
                  v-if="!(['image/jpeg', 'image/png', 'image/gif', 'video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1)"
                  :key="media.id"
                  class="col-12 square">
                  <div
                    class="file-block">
                    <a
                      class="link">
                      {{ media.name }}
                    </a>
                    <coozzy-button
                      design="transparent"
                      class="delete-icon"
                      @click="deleteMedia()">
                      <coozzy-delete-icon />
                    </coozzy-button>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 mb-2">
            <coozzy-button
              design="green"
              class="float-right ml-3"
              :disabled="loading || file === null"
              @click="sendDocument">
              {{ $t('message.generic.form.send') }}
            </coozzy-button>
          </div>
        </div>
      </coozzy-card>
      <brief-butler-errors />
    </div>
  </section>
</template>

<script>
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import { required } from 'vuelidate/lib/validators'
import MediaApi from '../../misc/apis/MediaApi'
import Vue from 'vue'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import { media } from '@/mixins/media'
import BriefButlerErrors from '@/management/components/BriefButlerErrors.vue'
import BriefButlerApi from '@/misc/apis/BriefButlerApi'
import { user } from '@/mixins/user'

export default {
  name: 'BriefButlerOverview',
  components: {
    CoozzyPageTitle,
    CoozzyButton,
    CoozzyFormFileInput,
    CoozzySpinner,
    BriefButlerErrors
  },
  mixins: [media, user],
  data() {
    return {
      profiles: [],
      loading: false,
      mediaUpload: null,
      file: null,
      uploading: false,
      selectedProfile: null,
      filteredMediaList: [],
      deleting: false
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.navigation.briefButler')
    }
  },
  computed: {
  },
  watch: {
    file: {
      deep: true,
      handler: function (val) {
        if (val !== null) {
          if (val.type === 'application/pdf') {
            this.drag = false
            this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
            if (val.name) {
              this.updateUploadedFileName(this, val.name)
              this.filteredMediaList[0] = val
            }
          } else {
            this.file = null
            this.$refs['file-input'].resetPlaceholder()
            if (!this.deleting) {
              Vue.toasted.show(this.$t('message.briefButler.error.fileType'), { type: 'error' })
            }
          }
        }
        if (this.deleting) {
          this.deleting = false
        }
      }
    }
  },
  mounted: function () {
    this.loading = true
    console.log('loadSettings')
    BriefButlerApi.loadSettings(this.$store.getters['user/getAccountId'])
      .then(response => {
        this.$store.dispatch('user/setIsBriefButlerEnabled', response.enabled)
        this.profiles = response.profiles
      })
      .catch(e => {
        console.error(e)
        Vue.toasted.show('Die Buchhaltungsstatistiken konnten nicht geladen werden', { type: 'error' })
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    hide() {
      this.$refs['send-brief-butler-modal'].hide()
    },
    show() {
      this.$refs['send-brief-butler-modal'].show()
    },
    sendDocument() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.file.type === 'application/pdf') {
          this.uploading = true
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
          const formData = new FormData()
          const fileName = this.editFileName(this.file.name)
          formData.append('file', this.file, fileName)
          formData.append('filename', this.file.name)
          formData.append('public', 'false')
          if (this.ownerId) {
            formData.append('accountId', this.ownerId.toString())
          }

          MediaApi.uploadMedia(formData)
            .then(media => {
              this.reset()
              console.log(media.id)
              BriefButlerApi.sendRawDocument(this.selectedProfile, media.id)
                .then(response => {
                  Vue.toasted.show(this.$t('message.successMessages.sendRawDocument'), { type: 'success' })
                })
                .catch(e => {
                  console.error(e)
                  Vue.toasted.show('Die Buchhaltungsstatistiken konnten nicht geladen werden', { type: 'error' })
                })
                .finally(() => {
                  this.loading = false
                })
            })
            .catch(e => {
              this.uploading = false
              if (e.response && e.response.data && e.response.data.message === 'Not supported MIME type') {
                Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
                if (this.$refs['file-input']) this.$refs['file-input'].resetPlaceholder()
                this.file = null
              } else {
                Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
              }
            })
            .finally(() => {
              this.uploading = false
            })
        } else {
          this.uploading = false
          Vue.toasted.show(this.$t('message.briefButler.error.fileType'), { type: 'error' })
        }
      }
    },
    handleError(e) {
      this.reset()
      console.log(e)
      Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
    },
    reset() {
      this.$v.$reset()
      this.uploading = false
      this.name = ''
      this.file = null
    },
    deleteMedia() {
      if (this.filteredMediaList.length > 0) {
        this.deleting = true
        this.file = null
        this.$refs['file-input'].resetPlaceholder()
        this.filteredMediaList = []
      }
    }
  },
  validations: {
    selectedProfile: {
      required
    },
    file: {
      required
    }
  }
}
</script>
