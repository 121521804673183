<template>
  <section>
    <b-modal
      id="modal-create-activity"
      ref="modal-create-activity"
      :modal-class="'has-confirmation-modal'"
      no-close-on-backdrop>
      <div
        slot="modal-header"
        class="w-100">
        <h5
          v-if="activityDetail"
          class="mb-0 d-inline normal modal-title">
          {{ $t('message.contact.activity.edit') }}
        </h5>
        <h5
          v-else
          class="mb-0 d-inline modal-title">
          {{ $t('message.contact.activity.create') }}
        </h5>
        <coozzy-button
          design="transparent"
          class="float-right"
          @click="hide()">
          <img
            class="close-img"
            src="@/assets/icon_close.svg"
            alt="Placeholder">
        </coozzy-button>
        <coozzy-button
          v-if="!isEditable"
          class="float-right"
          design="transparent"
          @click="isEditable = true">
          <coozzy-edit-icon />
        </coozzy-button>
      </div>
      <div
        v-if="loading"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <div
        v-else
        class="row">
        <div class="col-sm-12 col-md-12 mb-2">
          <coozzy-form-input
            v-if="isEditable"
            v-model="activity.subject"
            :name="$t('message.calendar.reminder.subject')+ '*'"
            :state="$v.activity.subject?.$dirty && $v.activity.subject?.$error ? false : null" />
          <template v-else>
            <label>{{ $t('message.calendar.reminder.subject') }}</label>
            <p>{{ activity.subject }}</p>
          </template>
        </div>
        <div class="col-sm-12 col-md-12 mb-2">
          <label> {{ $t('message.onBoarding.buildings.objects.renovation.text') }} </label>
          <coozzy-form-textarea
            v-if="isEditable"
            v-model="activity.text"
            :value-text-area="activity.text"
            :initial="activity.text"
            :show-label="false"
            :rows="3">
            {{ activity.text }}
          </coozzy-form-textarea>
          <p v-else>
            {{ activity.text }}
          </p>
        </div>
        <div class="col-6 mb-2">
          <label>{{ $t('message.contact.activity.source') }}</label>
          <coozzy-form-select
            v-if="isEditable"
            id="source"
            v-model="activity.source">
            <option
              value="ACTIVITY_SOURCE_UNDEFINED">
              -
            </option>
            <option
              value="ACTIVITY_SOURCE_PHONE">
              {{ $t('message.contact.activity.sourceType.ACTIVITY_SOURCE_PHONE' ) }}
            </option>
            <option
              value="ACTIVITY_SOURCE_MAIL">
              {{ $t('message.contact.activity.sourceType.ACTIVITY_SOURCE_MAIL' ) }}
            </option>
            <option
              value="ACTIVITY_SOURCE_PERSONAL">
              {{ $t('message.contact.activity.sourceType.ACTIVITY_SOURCE_PERSONAL' ) }}
            </option>
          </coozzy-form-select>
          <p
            v-else
            class="show-data">
            <span v-if="activity.source === 'ACTIVITY_SOURCE_PHONE'">{{ $t('message.contact.activity.sourceType.ACTIVITY_SOURCE_PHONE' ) }}</span>
            <span v-else-if="activity.source === 'ACTIVITY_SOURCE_MAIL'">{{ $t('message.contact.activity.sourceType.ACTIVITY_SOURCE_MAIL' ) }}</span>
            <span v-else-if="activity.source === 'ACTIVITY_SOURCE_PERSONAL'">{{ $t('message.contact.activity.sourceType.ACTIVITY_SOURCE_PERSONAL' ) }}</span>
            <span v-else>-</span>
          </p>
        </div>
        <div class="col-6 mb-2">
          <label>{{ $t('message.calendar.event.date') }}*</label>
          <coozzy-form-input
            v-if="isEditable"
            id="startDate"
            ref="startDate"
            v-model="time.date"
            :state="$v.activity.timestampStart?.$dirty && $v.activity.timestampStart?.$error ? false : null"
            type="date"
            @blur="updateTimestamp" />
          <template v-else>
            <p>{{ time.date || '-' }}</p>
          </template>
        </div>
        <div class="col-4 mb-2">
          <label>{{ $t('message.contact.activity.start') }}</label>
          <coozzy-form-input
            v-if="isEditable"
            id="onlyTime"
            ref="onlyTime"
            v-model="time.start"
            :step="60"
            type="time"
            @blur="updateTimestamp" />
          <template v-else>
            <p>{{ activity.hasStartTime ? time.start : '-' }}</p>
          </template>
        </div>
        <div class="col-4 mb-2">
          <label>{{ $t('message.contact.activity.end') }}</label>
          <coozzy-form-input
            v-if="isEditable"
            id="onlyTime"
            ref="onlyTime"
            v-model="time.end"
            :step="60"
            type="time"
            @blur="updateTimestamp()" />
          <template v-else>
            <p>{{ activity.hasEndTime ? time.end : '-' }}</p>
          </template>
        </div>
        <div class="col-4 mb-2">
          <label>{{ $t('message.contact.activity.duration') }}</label>
          <coozzy-form-input
            v-if="isEditable"
            id="onlyTime"
            ref="onlyTime"
            v-model="duration"
            :disabled="true"
            :step="60"
            type="time"
            @blur="updateTimestamp()" />
          <template v-else>
            <p>{{ duration }}</p>
          </template>
        </div>
        <div
          id="list-connections"
          class="col-sm-12 col-md-12 mb-2">
          <label>{{ $t('message.ticketOverview.ticket.connections') }}</label>
          <div
            v-for="(ref, index) in references"
            :key="index"
            class="d-flex align-items-center">
            <coozzy-button
              v-if="isEditable"
              size="small"
              class="border mb-2"
              design="transparent"
              @click="removeReference(index)">
              <coozzy-delete-icon />
            </coozzy-button>
            <div class="containerCircles">
              <div
                v-if="ref.tenancy"
                v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.tenancy')}"
                class="color-box mt-0 ml-2 mr-2 tenancy" />
              <div
                v-if="ref.condominium"
                v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.condo_owner')}"
                class="color-box mt-0 ml-2 mr-2 condo_owner" />
              <div
                v-if="ref.object && ref.object.condominiumOwnership === true && !ref.tenancy && !ref.condominium && !ref.device"
                v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.condominium')}"
                class="color-box mt-0 ml-2 mr-2 condominium" />
              <div
                v-if="ref.object && ref.object.condominiumOwnership === false && !ref.tenancy && !ref.condominium && !ref.device"
                v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.object')}"
                class="color-box mt-0 ml-2 mr-2 object" />
              <div
                v-if="ref.device"
                v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.device')}"
                class="color-box mt-0 ml-2 mr-2 device" />
              <div
                v-if="ref.building && !ref.object && !ref.device"
                v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.building')}"
                class="color-box mt-0 ml-2 mr-2 not_activated" />
              <div
                v-if="ref.property && !ref.building && !ref.device"
                v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.property')}"
                class="color-box mt-0 ml-2 mr-2 current" />
              <div
                v-if="ref.contact"
                v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.contact')}"
                class="color-box mt-0 ml-2 mr-2 terminated" />
              <div
                v-if="ref.request"
                v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.request')}"
                class="color-box mt-0 ml-2 mr-2 request" />
            </div>
            <span v-if="ref.request">
              <router-link
                :to="redirectToRequestDetail(ref.request.objectId, ref.request.id)">
                <span
                  v-for="(contact, j) in ref.listContact"
                  :key="j">
                  <span v-if="j > 0">- </span>{{ contact.name }}
                </span>
              </router-link>
            </span>
            <span v-if="ref.property">
              <router-link
                v-if="!isMarketingModule"
                :to="redirectToPropertyDetail(ref.property.objectID || ref.property.id)">
                {{ ref.property.name }}
              </router-link>
              <span v-else>{{ ref.property.name }}</span>
            </span>
            <span v-if="ref.building">
              <span v-if="ref.property"> > </span>
              <router-link
                v-if="!isMarketingModule"
                :to="redirectToBuildingDetail(ref.building.objectID || ref.building.id)">
                {{ ref.building.name }}
              </router-link>
              <span v-else>{{ ref.building.name }}</span>
            </span>
            <span v-if="ref.object">
              <span v-if="ref.building"> > </span>
              <router-link
                v-if="!isMarketingModule"
                :to="redirectToDetail(ref.object.buildingId, ref.object.objectID || ref.object.id, 'objects')">
                {{
                  ref.object.name !== '' ? ref.object.name : ref.object.number !== '' ? ref.object.number : ref.object.numericId
                }}
              </router-link>
              <span v-else> {{ ref.object.name !== '' ? ref.object.name : ref.object.number !== '' ? ref.object.number : ref.object.numericId }}</span>
            </span>
            <span v-if="ref.device">
              <span v-if="ref.object || ref.building || ref.property"> > </span>
              <router-link
                v-if="redirectToDeviceDetail(ref.device.referenceId, ref.device.objectID || ref.device.id) && !isMarketingModule"
                :to="redirectToDeviceDetail(ref.device.referenceId, ref.device.objectID || ref.device.id)">
                <template v-if="ref.device.type && ref.device.type !== 'DEVICE_TYPE_UNDEFINED'">
                  {{ $t('message.onBoarding.devices.typeValues.' + ref.device.type).length > 50 ? $t('message.onBoarding.devices.typeValues.' + ref.device.type).slice(0,50)+ '...' : $t('message.onBoarding.devices.typeValues.' + ref.device.type) }}
                </template>
                <template v-else>
                  -
                </template>
              </router-link>
              <span v-else>
                <template v-if="ref.device.type && ref.device.type !== 'DEVICE_TYPE_UNDEFINED'">
                  {{ $t('message.onBoarding.devices.typeValues.' + ref.device.type).length > 50 ? $t('message.onBoarding.devices.typeValues.' + ref.device.type).slice(0,50)+ '...' : $t('message.onBoarding.devices.typeValues.' + ref.device.type) }}
                </template>
                <template v-else>
                  -
                </template>
              </span>
            </span>
            <span v-if="ref.object && ref.tenancy">
              <span v-if="ref.object"> > </span>
              <router-link
                v-if="!isMarketingModule"
                :to="redirectToDetail(ref.object.buildingId, ref.tenancy.objectID || ref.tenancy.id, 'tenants')">
                <template v-if="ref.tenancy.tenants">
                  <span
                    v-for="(contact, j) in ref.tenancy.tenants"
                    :key="j">
                    <span v-if="j > 0">- </span>{{ contact.name }}
                  </span>
                </template>
                <template v-else>
                  <span
                    v-for="(contact, j) in ref.listContact"
                    :key="j">
                    <span v-if="j > 0">- </span>{{ contact.name }}
                  </span>
                </template>
              </router-link>
              <span v-else>
                <template v-if="ref.tenancy.tenants">
                  <span
                    v-for="(contactTen, j) in ref.tenancy.tenants"
                    :key="j">
                    <span v-if="j > 0">- </span>{{ contactTen.name }}
                  </span>
                </template>
                <template v-else>
                  <span
                    v-for="(contact, j) in ref.listContact"
                    :key="j">
                    <span v-if="j > 0">- </span>{{ contact.name }}
                  </span>
                </template>
              </span>
            </span>
            <span v-if="ref.object && ref.condominium">
              <span v-if="ref.object"> > </span>
              <router-link
                v-if="!isMarketingModule"
                :to="redirectToDetail(ref.object.buildingId, ref.condominium.objectID || ref.condominium.id, 'condominium')">
                <template v-if="ref.condominium.condominiumOwners">
                  <span
                    v-for="(contact, j) in ref.condominium.condominiumOwners"
                    :key="j">
                    {{ contact.name }}
                  </span>
                </template>
                <template v-else>
                  <span
                    v-for="(contact, j) in ref.listContact"
                    :key="j">
                    {{ contact.name }}
                  </span>
                </template>
              </router-link>
              <span v-else>
                <template v-if="ref.condominium.condominiumOwners">
                  <span
                    v-for="(contact, j) in ref.condominium.condominiumOwners"
                    :key="j">
                    {{ contact.name }}
                  </span>
                </template>
                <template v-else>
                  <span
                    v-for="(contact, j) in ref.listContact"
                    :key="j">
                    {{ contact.name }}
                  </span>
                </template>
              </span>
            </span>
            <span v-if="ref.contact">
              <router-link
                :to="redirectToContactDetail(ref.contact.objectID || ref.contact.id)">
                {{ ref.contact.name }}
              </router-link>
            </span>
          </div>
          <div
            v-if="isEditable"
            class="row">
            <div class="col-3">
              <coozzy-form-select
                v-model="typeOfConnection">
                <option
                  :value="null"
                  :class="typeOfConnection === null ? 'selectedOption' : ''">
                  {{ $t('message.search.filter').toLowerCase() }}
                </option>
                <option
                  v-for="item in availableConnectionTypes"
                  :key="item.key"
                  :value="item"
                  :class="typeOfConnection?.key === item.key ? 'selectedOption' : ''">
                  {{ $t('message.ticketOverview.ticket.connection_items.' + item.key) }}
                </option>
              </coozzy-form-select>
            </div>
            <div class="col-9">
              <ais-instant-search
                v-if="isEditable && searchClient"
                :search-client="searchClient"
                :routing="routing"
                class="w-100"
                :index-name="typeOfConnection ? typeOfConnection.value : 'object'">
                <!-- eslint-disable vue/attribute-hyphenation -->
                <ais-configure
                  :filters="query"
                  :hitsPerPage="10" />
                <template v-if="typeOfConnection === null">
                  <ais-index index-name="building" />
                  <ais-index index-name="tenancy" />
                  <ais-index index-name="property" />
                  <ais-index index-name="condominium" />
                  <ais-index index-name="contact" />
                </template>
                <!-- eslint-disable vue/attribute-hyphenation -->
                <ais-autocomplete v-if="!indexNameChanging">
                  <template slot-scope="{ indices, refine }">
                    <vue-autosuggest
                      :key="refrecheAutosuggestKey"
                      v-model="emailSuggestion"
                      :suggestions="indicesToSuggestions(indices)"
                      :input-props="{...inputProps, placeholder: $t('message.generic.search')}"
                      @input="onChange(refine,emailSuggestion)"
                      @selected="onSelect">
                      <template slot-scope="{ suggestion }">
                        <span
                          :id="'tooltip-target_' + suggestion.item.objectID">
                          <template
                            v-if="suggestion.item.objectID.startsWith('tenancy')">
                            <div
                              class="row m-0"
                              :class="checkSelectedElement(suggestion.item.objectID)">
                              <div
                                class="p-0">
                                <div
                                  v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.tenancy')}"
                                  class="color-box ml-2 mr-2 tenancy" />
                              </div>

                              <div class="col p-0">
                                <p
                                  v-if="suggestion.item.objectID.startsWith('tenancy')"
                                  class="mb-0">
                                  <strong v-if="suggestion.item.tenants.length > 0">
                                    <span
                                      v-for="(tn, j) in suggestion.item.tenants"
                                      :key="j">
                                      {{ tn.name }} <span v-if="j < suggestion.item.tenants.length - 1">-</span>
                                    </span>
                                  </strong>
                                  <strong v-else>-</strong>
                                </p>
                                <p class="mb-0">
                                  {{ suggestion.item.startingAt | objectToDate }} -
                                  {{ suggestion.item.endingAt | objectToDate }}
                                </p>
                              </div>
                              <span class="showSelected">
                                {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                              </span>
                            </div>
                          </template>
                          <template
                            v-if="suggestion.item.objectID.startsWith('condominium')">
                            <div
                              class="row m-0"
                              :class="checkSelectedElement(suggestion.item.objectID)">
                              <div
                                class="p-0">
                                <div
                                  v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.condo_owner')}"
                                  class="color-box ml-2 mr-2 condo_owner" />
                              </div>

                              <div class="col p-0">
                                <p
                                  v-if="suggestion.item.objectID.startsWith('condominium')"
                                  class="mb-0">
                                  <strong>
                                    {{ suggestion.item.condominiumOwners.length > 0 ? suggestion.item.condominiumOwners[0].name : '-' }}
                                  </strong>
                                </p>
                                <p class="mb-0">
                                  {{ suggestion.item.startingAt | objectToDate }} -
                                  {{ suggestion.item.endingAt | objectToDate }}
                                </p>
                              </div>
                              <span class="showSelected">
                                {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                              </span>
                            </div>
                          </template>
                          <template
                            v-else-if="suggestion.item.objectID.startsWith('obj')">
                            <div
                              class="row m-0"
                              :class="checkSelectedElement(suggestion.item.objectID)">
                              <div
                                class="p-0">
                                <div
                                  v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.' + [suggestion.item.condominiumOwnership === true ? 'condominium' : 'object'])}"
                                  class="color-box ml-2 mr-2"
                                  :class="[suggestion.item.condominiumOwnership === true ? 'condominium' : 'object']" />
                              </div>
                              <div class="col p-0">
                                <p class="mb-0">
                                  <b>{{ suggestion.item.name }}</b>
                                </p>
                                <p class="mb-0">
                                  <address-text
                                    :show-short-country="true"
                                    :hide-if-empty="true"
                                    :address="suggestion.item.address" />
                                </p>
                              </div>
                              <span class="showSelected">
                                {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                              </span>
                            </div>
                          </template>
                          <template
                            v-else-if="suggestion.item.objectID.startsWith('prop')">
                            <div
                              class="row m-0"
                              :class="checkSelectedElement(suggestion.item.objectID)">
                              <div
                                class="p-0">
                                <div
                                  v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.property')}"
                                  class="color-box ml-2 mr-2 current" />
                              </div>
                              <div class="col p-0">
                                <p class="mb-0">
                                  <b>{{ suggestion.item.name }}</b>
                                </p>
                                <p class="mb-0">
                                  <address-text
                                    :show-short-country="true"
                                    :hide-if-empty="true"
                                    :address="suggestion.item.address" />
                                </p>
                              </div>
                              <span class="showSelected">
                                {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                              </span>
                            </div>
                          </template>
                          <template
                            v-else-if="suggestion.item.objectID.startsWith('building')">
                            <div
                              class="row m-0"
                              :class="checkSelectedElement(suggestion.item.objectID)">
                              <div
                                class="p-0">
                                <div
                                  v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.building')}"
                                  class="color-box ml-2 mr-2 not_activated" />
                              </div>
                              <div class="col p-0">
                                <p class="mb-0">
                                  <b>{{ suggestion.item.name }}</b>
                                </p>
                                <p class="mb-0">
                                  <address-text
                                    :show-short-country="true"
                                    :hide-if-empty="true"
                                    :address="suggestion.item.address" />
                                </p>
                              </div>
                              <span class="showSelected">
                                {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                              </span>
                            </div>
                          </template>
                          <template v-else-if="suggestion.item.objectID.startsWith('contact')">
                            <div
                              class="row m-0"
                              :class="checkSelectedElement(suggestion.item.objectID)">
                              <div
                                class="p-0">
                                <div
                                  v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.contact')}"
                                  class="color-box ml-2 mr-2 terminated" />
                              </div>

                              <div class="col p-0">
                                <strong>{{ suggestion.item.lastName }} {{ suggestion.item.firstName }}</strong>
                                <br>
                                <address-text
                                  :show-short-country="true"
                                  :hide-if-empty="true"
                                  :address="suggestion.item.address" />
                                <br>
                                <span v-if="getListRole(suggestion.item).length > 0">
                                  {{ $t('message.generic.roles') }}: {{ getListRole(suggestion.item) }}
                                </span>
                              </div>
                              <span class="showSelected">
                                {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                              </span>
                            </div>
                          </template>
                          <b-tooltip
                            v-if="checkSelectedElement(suggestion.item.objectID) === 'selected'"
                            :container="'#list-connections'"
                            :target="'tooltip-target_' + suggestion.item.objectID"
                            placement="left"
                            triggers="hover">
                            {{ $t('message.generic.alreadySelected') }}
                          </b-tooltip>
                        </span>
                      </template>
                    </vue-autosuggest>
                  </template>
                </ais-autocomplete>
              </ais-instant-search>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 mb-2">
          <ais-instant-search
            v-if="searchClientInternal && isEditable"
            :search-client="searchClientInternal"
            :routing="routing"
            index-name="contact">
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ais-configure
              :hitsPerPage="10"
              :filters="'active:true AND isEmployee:true AND owner.id:'+ accountId" />
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ais-autocomplete>
              <template slot-scope="{ indices, refine }">
                <label>{{ $t('message.calendar.reminder.employee') }}</label>
                <coozzy-search-multiselect
                  v-model="selectedEmployee"
                  :options="indices[0].hits"
                  :multiple="true"
                  :placeholder="''"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :preselect-first="false"
                  :taggable="false"
                  track-by="objectID"
                  label="name"
                  :with-address="false"
                  @search-change="see(refine, $event)" />
              </template>
            </ais-autocomplete>
          </ais-instant-search>
          <template v-if="!isEditable">
            <label>{{ $t('message.calendar.reminder.employee') }}</label>
            <p>{{ selectedEmployee.length === 0 ? '-' : selectedEmployee.map(x => x.name).join(', ') }}</p>
          </template>
        </div>
        <div class="col-sm-12 col-md-12 mb-2">
          <label for="file">{{ $t('message.ticketOverview.ticket.attachment') }}</label>
          <coozzy-form-file-input
            v-if="isEditable"
            id="file"
            ref="coozzy-file-input"
            v-model="mediaUpload"
            accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx" />
          <div class="row">
            <div
              v-for="media in mediasList"
              :key="media.id"
              class="media-block col-12">
              <div
                class="file-block p-1 mt-2 mb-0">
                <a
                  :href="media.url"
                  target="_blank">
                  {{ media.filename }}
                </a>
                <coozzy-button
                  v-if="isEditable"
                  design="transparent"
                  class="delete-icon"
                  @click="deleteMedia(media.id)">
                  <coozzy-delete-icon />
                </coozzy-button>
              </div>
            </div>
            <p
              v-if="!isEditable && mediasList.length === 0"
              class="col-12">
              -
            </p>
          </div>
        </div>
      </div>
      <div
        slot="modal-footer"
        class="w-100 float-right">
        <coozzy-button
          v-if="!activityDetail && isEditable"
          design="green"
          class="float-right ml-3"
          :disabled="loading || uploadProcessing"
          @click="createActivity">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
        <coozzy-button
          v-else-if="isEditable"
          design="green"
          class="float-right ml-3"
          :disabled="loading || uploadProcessing"
          @click="updateActivity">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
        <coozzy-button
          design="default"
          :disabled="loading"
          @click="$bvModal.hide('modal-create-activity')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import ContactApi from '../../misc/apis/ContactApi'
import AddressText from '@/framework/components/misc/AddressText'
import MediaApi from '../../misc/apis/MediaApi'
import { user } from '@/mixins/user'
import Vue from 'vue'
import CoozzySearchMultiselect from '@/framework/components/multiselect/CoozzySearchMultiselect'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import CoozzyFormInput from '../../framework/components/form/input/CoozzyFormInput'
import CoozzyFormTextarea from '../../framework/components/form/textarea/CoozzyFormTextarea'
import CoozzyFormSelect from '../../framework/components/form/select/CoozzyFormSelect'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
// import CoozzyMultiselect from '../../framework/components/multiselect/CoozzyMultiselect'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyDeleteIcon from '../../framework/components/icons/CoozzyDeleteIcon'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput'
import { mapActions, mapGetters } from 'vuex'
import { algolia } from '@/mixins/algolia'
import { VueAutosuggest } from 'vue-autosuggest'
import { media } from '@/mixins/media'
import ObjectApi from '@/misc/apis/ObjectApi'
import RequestApi from '@/marketingBasic/api/RequestApi'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'

export default {
  name: 'CreateActivityModal',
  components: {
    CoozzyEditIcon,
    CoozzySearchMultiselect,
    CoozzyFormFileInput,
    // CoozzyMultiselect,
    AddressText,
    VueAutosuggest,
    CoozzyDeleteIcon,
    CoozzyButton,
    CoozzyFormSelect,
    CoozzyFormTextarea,
    CoozzyFormInput,
    CoozzySpinner
  },
  mixins: [user, algolia, media, routeChecks],
  props: {
    activityDetail: {
      type: Object,
      default() {
        return null
      }
    },
    referencesIds: {
      type: Array,
      default() {
        return []
      }
    },
    contactPersons: {
      type: Array,
      default() {
        return []
      }
    },
    tenantList: {
      type: Array,
      default() {
        return []
      }
    },
    singleTenant: {
      type: Object,
      default() {
        return null
      }
    },
    contacts: {
      type: Array,
      default() {
        return []
      }
    },
    condominium: {
      type: Object,
      default() {
        return null
      }
    },
    ids: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isEditable: this.activityDetail === null,
      selectedEmployee: [],
      loading: false,
      emailSuggestion: '',
      listEntities: [],
      debounceTimeout: null,
      refrecheAutosuggestKey: '',
      references: [],
      activity: {
        subject: '',
        text: '',
        timestampStart: null,
        timestampEnd: null,
        source: 'ACTIVITY_SOURCE_UNDEFINED',
        references: [],
        employeeContactId: '',
        mediaIds: [],
        hasStartTime: false,
        hasEndTime: false
      },
      time: {
        date: null,
        start: '',
        end: ''
      },
      employeeList: [],
      usedContacts: [],
      mediaUpload: null,
      mediasList: [],
      currentUser: null,
      typeOfConnection: null,
      availableConnectionTypes: [
        { key: 'property', value: 'property' },
        { key: 'building', value: 'building' },
        { key: 'object', value: 'object' },
        { key: 'condominium', value: 'object' },
        { key: 'tenancy', value: 'tenancy' },
        { key: 'condo_owner', value: 'condominium' },
        { key: 'contact', value: 'contact' }
      ],
      query: '',
      indexNameChanging: false,
      uploadProcessing: false
    }
  },
  computed: {
    duration() {
      if (this.activity.hasStartTime && this.activity.hasEndTime) {
        if (this.isEditable === false && this.activity.timestampStart && this.activity.timestampEnd) {
          const timestampStart = parseInt(this.activity.timestampStart)
          const timestampEnd = parseInt(this.activity.timestampEnd)
          const start = new Date(timestampStart).getTime()
          const end = new Date(timestampEnd).getTime()
          const diff = end - start
          const filterInstance = this.$options.filters
          return `${filterInstance.timestampToTimeOnly(diff)}`
        } else if (this.isEditable === true && this.time.start && this.time.end) {
          const dateStart = new Date(this.time.date)
          const dateEnd = new Date(this.time.date)
          const splitStartTime = this.time.start?.split(':')
          dateStart.setHours(splitStartTime[0])
          dateStart.setMinutes(splitStartTime[1])

          const splitEndTime = this.time.end?.split(':')
          dateEnd.setHours(splitEndTime[0])
          dateEnd.setMinutes(splitEndTime[1])

          const timeStampStart = dateStart.getTime() / 1000
          const timeStampEnd = dateEnd.getTime() / 1000
          const diff = timeStampEnd - timeStampStart
          const filterInstance = this.$options.filters
          return `${filterInstance.timestampToTimeOnly(diff)}`
        }
      }
      return '-'
    },
    connectedEmployee() {
      const currentUser = this.$store.getters['user/getCurrentUser']
      return this.employeeList.find(object => object.email === currentUser.email)
    },
    currentEmployee() {
      return this.employeeList.find(object => object.id === this.activity.employeeContactId)
    }
  },
  watch: {
    activity: {
      deep: true,
      handler: function () {
        this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
      }
    },
    mediaUpload: function (newVal) {
      if (newVal !== null) {
        this.sendFile()
      }
    },
    typeOfConnection: function (item) {
      this.query = ''
      this.indexNameChanging = true
      if (item) {
        if (item.key === 'condominium') {
          this.query = 'condominiumOwnership:true'
        }
        if (item.key === 'object') {
          this.query = 'condominiumOwnership:false'
        }
        if (item.value === 'contact') {
          this.query = 'isOwner:false AND isEmployee:false AND isOnlyInterestedParty:false'
        }
      }
      setTimeout(() => {
        this.indexNameChanging = false
      }, 200)
    },
    isEditable: function (value) {
      if (value === true) {
        this.time.date = this.$options.filters.timestampToDateInput(this.activityDetail.timestampStart)
      }
    },
    'time.start': function (value) {
      this.activity.hasStartTime = value !== ''
    },
    'time.end': function (value) {
      this.activity.hasEndTime = value !== ''
    }
  },
  mounted: function () {
    this.loadEmployees()
    this.loadInstantSearch()
    this.loadInstantSearchInternal()
    const userEmail = this.userEmail
    const accountId = this.accountId

    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'modal-create-activity') {
        this.activity = {
          subject: '',
          text: '',
          timestampStart: null,
          timestampEnd: null,
          source: 'ACTIVITY_SOURCE_UNDEFINED',
          references: [],
          employeeContactId: '',
          mediaIds: [],
          hasStartTime: false,
          hasEndTime: false
        }
        this.mediasList = []
      }
    })
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'modal-create-activity') {
        if (this.activityDetail === null) {
          if (((typeof this.ids === 'string' || this.ids instanceof String) && this.ids !== '') || this.ids.length > 0) {
            let arr = []
            if (typeof this.ids === 'string' || this.ids instanceof String) {
              arr = [this.ids]
            } else {
              arr = this.ids
            }
            if (arr[0] !== null) {
              if (arr[0].startsWith('device')) {
                ObjectApi.listDevicesByIds(arr)
                  .then(response => {
                    response.devices.forEach(element => {
                      this.addToReferences({ item: element }, false)
                    })
                  })
              } else if (arr[0].startsWith('condominium')) {
                ObjectApi.getCondominiumByIds(arr)
                  .then(response => {
                    response.condominiums.forEach(element => {
                      this.addToReferences({ item: element }, false)
                    })
                  })
              } else if (arr[0].startsWith('tenancy')) {
                ObjectApi.getTenanciesByIds(arr)
                  .then(response => {
                    response.tenancies.forEach(element => {
                      this.addToReferences({ item: element }, false)
                    })
                  })
              } else if (arr[0].startsWith('obj')) {
                ObjectApi.listObjectsByIds(arr)
                  .then(response => {
                    response.objects.forEach(element => {
                      this.addToReferences({ item: element }, false)
                    })
                  })
              } else if (arr[0].startsWith('building')) {
                ObjectApi.getBuildingsListByIds(arr)
                  .then(response => {
                    response.buildings.forEach(element => {
                      this.addToReferences({ item: element }, false)
                    })
                  })
              } else if (arr[0].startsWith('prop')) {
                ObjectApi.getPropertyListByIds(arr)
                  .then(response => {
                    response.properties.forEach(element => {
                      this.addToReferences({ item: element }, false)
                    })
                  })
              } else if (arr[0].startsWith('req')) {
                RequestApi.listByIds(arr.filter(x => x.startsWith('request')))
                  .then(response => {
                    response.requests.forEach(element => {
                      this.addToReferences({ item: element }, false)
                    })
                  })
              } else if (arr[0].startsWith('contact')) {
                ContactApi.contactResolve(arr)
                  .then(response => {
                    response.persons.concat(response.companies).filter(x => arr.includes(x.id)).forEach(element => {
                      this.addToReferences({ item: element }, false)
                    })
                  })
              }
            }
            this.listEntities = arr.filter(x => !x.startsWith('req'))
          }

          const today = new Date()
          const minutes = today.getMinutes().toString().padStart(2, '0')
          const hours = today.getHours().toString().padStart(2, '0')
          this.time.start = `${hours.toString().padStart(2, '0')}:${minutes}`
          this.time.date = this.$options.filters.timestampToDateInput(today.getTime() / 1000)
          this.activity.timestampStart = Math.round(today.getTime() / 1000)
          this.activity.employeeContactId = this.connectedEmployee ? this.connectedEmployee.id : ''
          ContactApi.resolveByMail(accountId, [userEmail])
            .then((response) => {
              if (response.persons.length > 0) {
                let person = response.persons[0]
                person.objectID = person.id
                this.selectedEmployee.push(person)
                this.currentUser = person.id
              } else if (response.companies.length > 0) {
                this.selectedEmployee.push(response.companies[0])
              }
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.savingErrors.activity'), { type: 'error' })
            })
        } else {
          this.activity = Object.assign({}, this.activityDetail)
          this.loadCurrentEmployee(this.activityDetail.employeeContactIds)
          this.time.date = this.formatDateForDateInput(this.activity.timestampStart)
          if (this.activity.hasStartTime) {
            this.time.start = this.formatTimeForCalendar(this.activity.timestampStart)
          }
          if (this.activity.hasEndTime && this.activity.timestampEnd) {
            this.time.end = this.formatTimeForCalendar(this.activity.timestampEnd)
          }
          if (this.activity?.references.length > 0) {
            this.mountedReference(this.activity.references)
          }
          if (this.activity.mediaIds?.length !== 0) {
            for (let index = 0; index < this.activity.mediaIds?.length; index++) {
              MediaApi.getMediaByID(this.activity.mediaIds[index])
                .then(response => {
                  this.mediasList.push(response.media)
                })
                .catch(e => {
                  console.log(e)
                  Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
                })
            }
          } else {
            this.mediasList = []
          }
        }
        this.updateTimestamp()

        this.$nextTick(function () {
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
        })
      }
    })
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    getListRole(contact) {
      if (contact !== null && contact.types !== undefined) {
        if (contact.types && contact.types.length === 1 && contact.types[0] === 'NONE') {
          return '-'
        } else {
          const array = []
          contact.types.forEach(role => {
            if (role !== 'NONE') {
              array.push(this.$t('message.contact.types.' + role.toUpperCase()))
            }
          })
          return array.join(', ')
        }
      }
      return '-'
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({
        data: hits
      }))
    },
    redirectToContactDetail(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return {
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isMarketingModule) {
          return {
            name: 'MarketingContactDetailsView',
            params: { id: contactId }
          }
        } else {
          return {
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          }
        }
      }
    },
    redirectToBuildingDetail(buildingId) {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      }
    },
    redirectToPropertyDetail(propertyId) {
      if (this.isOwnerModule) {
        return { name: 'OwnerPropertyDetailsView', params: { id: propertyId } }
      } else if (this.isAdminModule) {
        return { name: 'AdminPropertyDetailsView', params: { id: propertyId } }
      } else if (this.isAssetModule) {
        return { name: 'AssetPropertyDetailsView', params: { id: propertyId } }
      } else if (this.isAccountingModule) {
        return { name: 'AccountingPropertyDetailsView', params: { id: propertyId } }
      }
    },
    redirectToRequestDetail(objectId, requestId) {
      return {
        name: 'MarketingObjectDetailsView',
        params: { id: objectId },
        query: {
          view: 0,
          elementSelected: requestId
        }
      }
    },
    redirectToDetail(buildingId, objectId, view) {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      }
    },
    onSelect(selected) {
      if (selected) {
        let alreadyExist = false
        const id = selected.item.objectID
        this.listEntities.push(id)
        if (selected.item.objectID.startsWith('condominium')) {
          if (this.references.find(x => x.condominium && (x.condominium.id === id || x.condominium.objectID === id))) {
            this.references = this.references.filter(x => !x.condominium || (x.condominium && ((x.condominium.objectID && x.condominium.objectID !== id) || (x.condominium.id && x.condominium.id !== id))))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('tenancy')) {
          if (this.references.find(x => x.tenancy && (x.tenancy.id === id || x.tenancy.objectID === id))) {
            this.references = this.references.filter(x => !x.tenancy || (x.tenancy && ((x.tenancy.objectID && x.tenancy.objectID !== id) || (x.tenancy.id && x.tenancy.id !== id))))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('obj')) {
          if (this.references.find(x => x.object && (x.object.id === id || x.object.objectID === id) && (!x.tenancy && !x.condominium))) {
            this.references = this.references.filter(x => !x.object || (x.object && ((x.object.objectID && x.object.objectID !== id) || (x.object.id && x.object.id !== id) || (x.tenancy || x.condominium))))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('building')) {
          if (this.references.find(x => x.building && (x.building.id === id || x.building.objectID === id) && !x.object)) {
            this.references = this.references.filter(x => !x.building || (x.building && ((x.building.objectID && x.building.objectID !== id) || (x.building.id && x.building.id !== id) || x.object)))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('prop')) {
          if (this.references.find(x => x.property && (x.property.id === id || x.property.objectID === id) && !x.building)) {
            this.references = this.references.filter(x => !x.property || (x.property && ((x.property.objectID && x.property.objectID !== id) || (x.property.id && x.property.id !== id) || x.building)))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('contact')) {
          if (this.references.find(x => x.contact && (x.contact.id === id || x.contact.objectID === id))) {
            this.references = this.references.filter(x => !x.contact || (x.contact && ((x.contact.objectID && x.contact.objectID !== id) || (x.contact.id && x.contact.id !== id))))
            alreadyExist = true
          }
        }
        if (alreadyExist === false) {
          this.addToReferences(selected)
        }
        this.$nextTick(function () {
          this.emailSuggestion = ''
          this.refrecheAutosuggestKey = Math.floor(Math.random() * 100)
        })
      }
    },
    addToReferences(selected, fromInput = true) {
      this.emailSuggestion = null
      const lengthOfConnections = this.references.length
      this.references.push({
        request: null,
        object: null,
        building: null,
        property: null,
        condominium: null,
        tenancy: null,
        contact: null,
        device: null,
        listContact: []
      })
      if (selected.item.objectID?.startsWith('condominium') || selected.item.id?.startsWith('condominium') || selected.item.objectID?.startsWith('tenancy') || selected.item.id?.startsWith('tenancy')) {
        if (selected.item.objectID?.startsWith('condominium') || selected.item.id?.startsWith('condominium')) {
          this.references[lengthOfConnections].condominium = selected.item
          if (selected.item.id || selected.item.objectID) {
            ContactApi.contactResolve(selected.item.owner.contactIds)
              .then(responseCon => {
                this.references[lengthOfConnections].listContact = responseCon.persons.concat(responseCon.companies).filter(c => selected.item.owner.contactIds.includes(c.id))
              })
          }
        } else if (selected.item.objectID?.startsWith('tenancy') || selected.item.id?.startsWith('tenancy')) {
          this.references[lengthOfConnections].tenancy = selected.item
          if (selected.item.id || selected.item.objectID) {
            const tenant = selected.item.tenant ? selected.item.tenant.contactIds : selected.item.tenants[0].contactIds
            ContactApi.contactResolve(tenant)
              .then(responseCon => {
                this.references[lengthOfConnections].listContact = responseCon.persons.concat(responseCon.companies).filter(c => tenant.includes(c.id))
              })
          }
        }
        ObjectApi.getObject(selected.item.obj?.id || selected.item.objectId)
          .then(response => {
            this.references[lengthOfConnections].object = response.object
            ObjectApi.getBuildingByObjectId(this.references[lengthOfConnections].object.id).then(response => {
              this.references[lengthOfConnections].building = response.building
              ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
                .then(response => {
                  this.references[lengthOfConnections].property = response.properties[0]
                })
            }).catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
            })
          })
      }
      if (selected.item.objectID?.startsWith('obj') || selected.item.id?.startsWith('obj')) {
        this.references[lengthOfConnections].object = selected.item
        // add address of object to description
        if (!fromInput && selected.item && this.fromBulk && this.ticketType === 'TICKET_TYPE_ORDER') {
          this.ticket.description += '\n' + selected.item.name
          this.ticket.description += '\n' + this.$t('message.onBoarding.buildings.objects.category.' + selected.item.category.toLowerCase()) + (selected.item.characteristics?.floor !== null ? ' - ' + this.formatFloor(selected.item.characteristics?.floor) : '')
          this.ticket.description += '\n' + this.convertAddress(selected.item.address) + '\n'
        }
        ObjectApi.getBuildingByObjectId(selected.item.objectID || selected.item.id).then(response => {
          this.references[lengthOfConnections].building = response.building
          ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
            .then(response => {
              this.references[lengthOfConnections].property = response.properties[0]
            })
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
        })
      }
      if (selected.item.objectID?.startsWith('building') || selected.item.id?.startsWith('building')) {
        this.references[lengthOfConnections].building = selected.item
        // add address of building to description
        if (!fromInput && selected.item && this.fromBulk && this.ticketType === 'TICKET_TYPE_ORDER') {
          this.ticket.description += '\n' + selected.item.name
          this.ticket.description += '\n' + this.convertAddress(selected.item.address) + '\n'
        }
        ObjectApi.getPropertiesByBuildingIds([selected.item.objectID || selected.item.id])
          .then(response => {
            this.references[lengthOfConnections].property = response.properties[0]
          })
      }
      if (selected.item.objectID?.startsWith('prop') || selected.item.id?.startsWith('prop')) {
        this.references[lengthOfConnections].property = selected.item
        // add the addresses of all the buildings of a property to the description
        if (!fromInput && selected.item?.buildingIds.length > 0 && this.fromBulk && this.ticketType === 'TICKET_TYPE_ORDER') {
          ObjectApi.getBuildingsListByIds(selected.item.buildingIds).then(response => {
            this.ticket.description += '\n' + selected.item.name
            response.buildings.forEach(element => {
              this.ticket.description += '\n' + this.convertAddress(element.address)
            })
            this.$nextTick(() => {
              this.ticket.description += '\n'
            })
          })
        }
      }
      if (selected.item.objectID?.startsWith('contact') || selected.item.id?.startsWith('contact')) {
        this.references[lengthOfConnections].contact = selected.item
      }
      if (selected.item.objectID?.startsWith('device') || selected.item.id?.startsWith('device')) {
        this.references[lengthOfConnections].device = selected.item

        if (selected.item.referenceId?.startsWith('obj')) {
          ObjectApi.getObject(selected.item.referenceId)
            .then(response => {
              this.references[lengthOfConnections].object = response.object
              ObjectApi.getBuildingByObjectId(this.references[lengthOfConnections].object.id).then(response => {
                this.references[lengthOfConnections].building = response.building
                ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
                  .then(response => {
                    this.references[lengthOfConnections].property = response.properties[0]
                  })
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
              })
            })
        }
        if (selected.item.referenceId?.startsWith('building')) {
          ObjectApi.getBuilding(selected.item.referenceId)
            .then(response => {
              this.references[lengthOfConnections].building = response.building
              ObjectApi.getPropertiesByBuildingIds(response.building.id)
                .then(response => {
                  this.references[lengthOfConnections].property = response.properties[0]
                })
            })
        }
        if (selected.item.referenceId?.startsWith('prop')) {
          ObjectApi.getPropertyListByIds([selected.item.referenceId])
            .then(response => {
              this.references[lengthOfConnections].property = response.properties[0]
            })
        }
      }
      if (selected.item.objectID?.startsWith('req') || selected.item.id?.startsWith('req')) {
        this.references[lengthOfConnections].request = selected.item
        if (selected.item.id || selected.item.objectID) {
          const cont = selected.item.requesters.map(r => r.contactId)
          ContactApi.contactResolve(cont)
            .then(response => {
              this.references[lengthOfConnections].listContact = response.persons.concat(response.companies).filter(c => cont.includes(c.id))
            })
        }
      }
    },
    onChange(refine, email) {
      if (email.length > 0) {
        if (email !== '') clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          this.debounceTimeout = null
          refine(email)
        }, 300)
      }
    },
    formatDateForDateInput(date) {
      return this.$options.filters.timestampToDate(date)
    },
    mountedReference(references) {
      for (let index = 0; index < references.length; index++) {
        this.references.push({
          object: null,
          building: null,
          property: null,
          condominium: null,
          tenancy: null,
          contact: null,
          device: null,
          request: null,
          listContact: []
        })
        const element = references[index].id
        this.listEntities.push(element)
        if (element.startsWith('condominium') || element.startsWith('tenancy')) {
          if (element.startsWith('condominium')) {
            ObjectApi.getCondominiumByIds([element])
              .then(response => {
                this.references[index].condominium = response.condominiums[0]
                ContactApi.contactResolve(this.references[index].condominium.owner.contactIds)
                  .then(responseCon => {
                    this.references[index].listContact = responseCon.persons.concat(responseCon.companies)
                  })
                ObjectApi.getObject(this.references[index].condominium.objectId)
                  .then(responseObj => {
                    this.references[index].object = responseObj.object
                    ObjectApi.getBuildingByObjectId(this.references[index].object.id).then(responseBuild => {
                      this.references[index].building = responseBuild.building
                      ObjectApi.getPropertiesByBuildingIds([this.references[index].building.id])
                        .then(responseProp => {
                          this.references[index].property = responseProp.properties[0]
                        })
                    }).catch(e => {
                      console.log(e)
                      Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                    })
                  })
              })
          } else if (element.startsWith('tenancy')) {
            ObjectApi.getTenanciesByIds([element])
              .then(response => {
                this.references[index].tenancy = response.tenancies[0]
                ContactApi.contactResolve(this.references[index].tenancy.tenant.contactIds)
                  .then(responseCon => {
                    this.references[index].listContact = responseCon.persons.concat(responseCon.companies).filter(c => this.references[index].tenancy.tenant.contactIds.includes(c.id))
                  })
                ObjectApi.getObject(this.references[index].tenancy.objectId)
                  .then(response => {
                    this.references[index].object = response.object
                    ObjectApi.getBuildingByObjectId(this.references[index].object.id).then(response => {
                      this.references[index].building = response.building
                      ObjectApi.getPropertiesByBuildingIds([this.references[index].building.id])
                        .then(response => {
                          this.references[index].property = response.properties[0]
                        })
                    }).catch(e => {
                      console.log(e)
                      Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                    })
                  })
              })
          }
        }
        if (element.startsWith('device') || element.startsWith('device')) {
          ObjectApi.listDevicesByIds([element])
            .then(response => {
              this.references[index].device = response.devices[0]
              if (response.devices[0].referenceId?.startsWith('obj')) {
                ObjectApi.getObject(response.devices[0].referenceId)
                  .then(response => {
                    this.references[index].object = response.object
                    ObjectApi.getBuildingByObjectId(this.references[index].object.id).then(response => {
                      this.references[index].building = response.building
                      ObjectApi.getPropertiesByBuildingIds([this.references[index].building.id])
                        .then(response => {
                          this.references[index].property = response.properties[0]
                        })
                    }).catch(e => {
                      console.log(e)
                      Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                    })
                  })
              }
              if (response.devices[0].referenceId?.startsWith('building')) {
                ObjectApi.getBuilding(response.devices[0].referenceId)
                  .then(response => {
                    this.references[index].building = response.building
                    ObjectApi.getPropertiesByBuildingIds(response.building.id)
                      .then(response => {
                        this.references[index].property = response.properties[0]
                      })
                  })
              }
              if (response.devices[0].referenceId?.startsWith('prop')) {
                ObjectApi.getPropertyListByIds([response.devices[0].referenceId])
                  .then(response => {
                    this.references[index].property = response.properties[0]
                  })
              }
            })
        }
        if (element.startsWith('request')) {
          RequestApi.listByIds([element])
            .then(response => {
              this.references[index].request = response.requests[0]
              const cont = response.requests[0].requesters[0].contactId
              ContactApi.contactResolve([cont])
                .then(response => {
                  this.references[index].listContact = response.persons.concat(response.companies).filter(c => cont.includes(c.id))
                })
            })
        }
        if (element.startsWith('obj')) {
          ObjectApi.getObject(element)
            .then(response => {
              this.references[index].object = response.object
              ObjectApi.getBuildingByObjectId(this.references[index].object.id).then(response => {
                this.references[index].building = response.building
                ObjectApi.getPropertiesByBuildingIds([this.references[index].building.id])
                  .then(response => {
                    this.references[index].property = response.properties[0]
                  })
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
              })
            })
        }
        if (element.startsWith('building')) {
          ObjectApi.getBuildingsListByIds([element]).then(response => {
            this.references[index].building = response.buildings[0]
            ObjectApi.getPropertiesByBuildingIds([this.references[index].building.id])
              .then(response => {
                this.references[index].property = response.properties[0]
              })
          })
        }
        if (element.startsWith('prop')) {
          ObjectApi.getPropertyListByIds([element])
            .then(response => {
              this.references[index].property = response.properties[0]
            })
        }
        if (element.startsWith('contact')) {
          ContactApi.contactResolve([element])
            .then(response => {
              this.references[index].contact = (response.persons.concat(response.companies)).find(x => x.id === element)
            })
        }
      }
    },
    removeReference(index) {
      this.listEntities = this.listEntities.filter((element, i) => i !== index)
      this.$delete(this.references, index)
    },
    checkSelectedElement(id) {
      if (id.startsWith('condominium')) {
        if (this.references.find(x => x.condominium && (x.condominium.id === id || x.condominium.objectID === id))) {
          return 'selected'
        }
      }
      if (id.startsWith('tenancy')) {
        if (this.references.find(x => x.tenancy && (x.tenancy.id === id || x.tenancy.objectID === id))) {
          return 'selected'
        }
      }
      if (id.startsWith('obj')) {
        if (this.references.find(x => x.object && (x.object.id === id || x.object.objectID === id) && (!x.tenancy && !x.condominium))) {
          return 'selected'
        }
      }
      if (id.startsWith('building')) {
        if (this.references.find(x => x.building && (x.building.id === id || x.building.objectID === id) && !x.object)) {
          return 'selected'
        }
      }
      if (id.startsWith('prop')) {
        if (this.references.find(x => x.property && (x.property.id === id || x.property.objectID === id) && !x.building)) {
          return 'selected'
        }
      }
      if (id.startsWith('contact')) {
        if (this.references.find(x => x.contact && (x.contact.id === id || x.contact.objectID === id))) {
          return 'selected'
        }
      }
      return ''
    },
    formatTimeForCalendar(time) {
      const date = new Date(time * 1000)
      return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
    },
    see(refine, value) {
      refine(value)
    },
    updateTimestamp() {
      // for calcule start time on edit mode
      if (this.time.date !== '' && this.isEditable) {
        const start = new Date(this.time.date)
        if (this.time.start) {
          const splitTime = this.time.start?.split(':')
          start.setHours(splitTime[0])
          start.setMinutes(splitTime[1])
        }
        if (!isNaN(start)) {
          this.activity.timestampStart = start.getTime() / 1000
        }
      }
      // for calcule end time on edit mode
      if (this.time.date !== '' && this.isEditable) {
          const end = new Date(this.time.date)
          if (this.time.end) {
            const splitTime = this.time.end?.split(':')
            end.setHours(splitTime[0])
            end.setMinutes(splitTime[1])
          }
          if (!isNaN(end)) {
            this.activity.timestampEnd = end.getTime() / 1000
          }
      }
      if (this.time.end === '') {
        this.activity.timestampEnd = null
        this.activity.hasEndTime = false
      }
      if (this.time.start === '') {
        this.activity.hasStartTime = false
      }
      if (this.time.date === '') {
        this.activity.timestampStart = null
        this.activity.timestampEnd = null
      }
    },
    sendFile() {
      const selectedFile = this.mediaUpload
      this.uploadProcessing = true
      const formData = new FormData()
      const fileName = this.editFileName(selectedFile.name)
      formData.append('file', selectedFile, fileName)
      formData.append('filename', selectedFile.name)
      formData.append('public', 'true')
      MediaApi.uploadMedia(formData)
        .then(response => {
          this.mediasList.push(response)
          this.activity.mediaIds.push(response.id)
          this.mediaUpload = null
        })
        .catch(e => {
          console.log(e)
          if (e.response && e.response.data && e.response.data.message === 'Not supported MIME type') {
            Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
          } else {
            Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
          }
        })
        .finally(() => {
          this.uploadProcessing = false
        })
      this.$refs['coozzy-file-input'].resetPlaceholder()
    },
    createActivity() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true
        this.activity.employeeContactIds = this.selectedEmployee.map(x => x.objectID)
        const connectionReferences = []
        this.references.forEach(element => {
          if (element.contact) {
            const id = element.contact.id ? element.contact.id : element.contact.objectID
            connectionReferences.push({
              id: id,
              relatedIds: []
            })
          } else if (element.tenancy) {
            const id = element.tenancy.id ? element.tenancy.id : element.tenancy.objectID
            const idProperty = element.property.id ? element.property.id : element.property.objectID
            const idBuilding = element.building.id ? element.building.id : element.building.objectID
            const idObject = element.object.id ? element.object.id : element.object.objectID
            connectionReferences.push({
              id: id,
              relatedIds: [idProperty, idBuilding, idObject]
            })
          } else if (element.request) {
            const id = element.request.id ? element.request.id : element.request.objectID
            connectionReferences.push({
              id: id,
              relatedIds: []
            })
          } else if (element.condominium) {
            const id = element.condominium.id ? element.condominium.id : element.condominium.objectID
            const idProperty = element.property.id ? element.property.id : element.property.objectID
            const idBuilding = element.building.id ? element.building.id : element.building.objectID
            const idObject = element.object.id ? element.object.id : element.object.objectID
            connectionReferences.push({
              id: id,
              relatedIds: [idProperty, idBuilding, idObject]
            })
          } else if (element.device) {
            const id = element.device.id ? element.device.id : element.device.objectID
            let idProperty
            let idBuilding = null
            let idObject = null
            let ids
            if (element.object) {
              idProperty = element.property.id ? element.property.id : element.property.objectID
              idBuilding = element.building?.id ? element.building.id : element.building.objectID
              idObject = element.object?.id ? element.object.id : element.object.objectID

              ids = [idProperty, idBuilding, idObject]
            } else if (element.building) {
              idProperty = element.property.id ? element.property.id : element.property.objectID
              idBuilding = element.building?.id ? element.building.id : element.building.objectID

              ids = [idProperty, idBuilding]
            } else {
              idProperty = element.property.id ? element.property.id : element.property.objectID

              ids = [idProperty]
            }
            connectionReferences.push({
              id: id,
              relatedIds: ids
            })
          } else if (element.object) {
            const id = element.object.id ? element.object.id : element.object.objectID
            const idProperty = element.property.id ? element.property.id : element.property.objectID
            const idBuilding = element.building.id ? element.building.id : element.building.objectID
            connectionReferences.push({
              id: id,
              relatedIds: [idProperty, idBuilding]
            })
          } else if (element.building) {
            const id = element.building.id ? element.building.id : element.building.objectID
            const idProperty = element.property.id ? element.property.id : element.property.objectID
            connectionReferences.push({
              id: id,
              relatedIds: [idProperty]
            })
          } else if (element.property) {
            const id = element.property.id ? element.property.id : element.property.objectID
            connectionReferences.push({
              id: id,
              relatedIds: []
            })
          }
        })
        this.activity.references = connectionReferences
        this.activity.ownerId = this.accountId
        ContactApi.addActivityV3(this.activity)
          .then(response => {
            this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
            this.hide()
            this.$emit('activity-created', response.activity)
            Vue.toasted.show(this.$t('message.successMessages.activityCreation'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.activity'), { type: 'error' })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    deleteMedia(id) {
      this.activity.mediaIds = this.activity.mediaIds.filter(e => e !== id)
      this.mediasList = this.mediasList.filter(el => el.id !== id)
    },
    hide() {
      this.$v.$reset()
      this.$refs['modal-create-activity'].hide()
    },
    show() {
      this.$refs['modal-create-activity'].show()
    },
    updateActivity() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true
        const connectionReferences = []
        this.references.forEach(element => {
          if (element.contact) {
            const id = element.contact.id ? element.contact.id : element.contact.objectID
            connectionReferences.push({
              id: id,
              relatedIds: []
            })
          } else if (element.tenancy) {
            const id = element.tenancy.id ? element.tenancy.id : element.tenancy.objectID
            const idProperty = element.property.id ? element.property.id : element.property.objectID
            const idBuilding = element.building.id ? element.building.id : element.building.objectID
            const idObject = element.object.id ? element.object.id : element.object.objectID
            connectionReferences.push({
              id: id,
              relatedIds: [idProperty, idBuilding, idObject]
            })
          } else if (element.request) {
            const id = element.request.id ? element.request.id : element.request.objectID
            connectionReferences.push({
              id: id,
              relatedIds: []
            })
          } else if (element.condominium) {
            const id = element.condominium.id ? element.condominium.id : element.condominium.objectID
            const idProperty = element.property.id ? element.property.id : element.property.objectID
            const idBuilding = element.building.id ? element.building.id : element.building.objectID
            const idObject = element.object.id ? element.object.id : element.object.objectID
            connectionReferences.push({
              id: id,
              relatedIds: [idProperty, idBuilding, idObject]
            })
          } else if (element.device) {
            const id = element.device.id ? element.device.id : element.device.objectID
            let idProperty
            let idBuilding = null
            let idObject = null
            let ids
            if (element.object) {
              idProperty = element.property.id ? element.property.id : element.property.objectID
              idBuilding = element.building?.id ? element.building.id : element.building.objectID
              idObject = element.object?.id ? element.object.id : element.object.objectID

              ids = [idProperty, idBuilding, idObject]
            } else if (element.building) {
              idProperty = element.property.id ? element.property.id : element.property.objectID
              idBuilding = element.building?.id ? element.building.id : element.building.objectID

              ids = [idProperty, idBuilding]
            } else {
              idProperty = element.property.id ? element.property.id : element.property.objectID

              ids = [idProperty]
            }
            connectionReferences.push({
              id: id,
              relatedIds: ids
            })
          } else if (element.object) {
            const id = element.object.id ? element.object.id : element.object.objectID
            const idProperty = element.property.id ? element.property.id : element.property.objectID
            const idBuilding = element.building.id ? element.building.id : element.building.objectID
            connectionReferences.push({
              id: id,
              relatedIds: [idProperty, idBuilding]
            })
          } else if (element.building) {
            const id = element.building.id ? element.building.id : element.building.objectID
            const idProperty = element.property.id ? element.property.id : element.property.objectID
            connectionReferences.push({
              id: id,
              relatedIds: [idProperty]
            })
          } else if (element.property) {
            const id = element.property.id ? element.property.id : element.property.objectID
            connectionReferences.push({
              id: id,
              relatedIds: []
            })
          }
        })
        this.activity.references = connectionReferences
        this.activity.ownerId = this.accountId
        this.activity.employeeContactIds = this.selectedEmployee.map(x => x.objectID)
        ContactApi.updateActivityV3(this.activity)
          .then(() => {
            this.$emit('activity-updated', this.activity, this.currentEmployee)
            this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
            this.hide()
            Vue.toasted.show(this.$t('message.successMessages.activityUpdated'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.activity'), { type: 'error' })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    loadCurrentEmployee(employeeContactId) {
      if (this.searchClientInternal && (employeeContactId !== '' || employeeContactId.length > 0)) {
        const index = this.searchClientInternal.initIndex('contact')
        let filter = ''
        if (Array.isArray(employeeContactId)) {
          filter = employeeContactId.map(e => `objectID:${e}`).join(' OR ')
        } else {
          filter = `objectID:${employeeContactId}`
        }
        index
          .search('', {
            filters: filter
          })
          .then(({ hits }) => {
            this.selectedEmployee = hits.filter(x => employeeContactId.includes(x.objectID.toString()))
            return hits
          })
      }
      return null
    }
  },
  validations: {
    activity: {
      subject: {
        required
      },
      timestampStart: {
        required
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.media-block {
  margin-right: 0.5%;

  .uploaded-file {
    background-position: center center;
    background-size: cover;
    min-height: 7em;
  }

  .file-block {
    border-style: solid;
    border-bottom-color: rgb(206, 212, 218);
    border-width: 0 0 1px 0;

    a {
      overflow-wrap: break-word;
    }
  }
}

.delete-icon {
  position: absolute;
  right: 1rem;
}

.delete-icon :deep(svg) {
  vertical-align: text-top;
}
:deep(.multiselect__single) {
  margin-bottom: 5px;
  margin-top: 2px;
}
:deep(.multiselect__tag) {
  padding: 4px 22px 3px 7px;
  margin-bottom: -1px;
  margin-top: 1px;
}

.showSelected {
  display: none;
}
.selected {
  background-color: rgba(0, 0, 0, 0.08);
}
.close-img{
  width: 15px;
}
.selected .showSelected {
  display: block;
  color: rgba(0, 0, 0, 0.5);
  align-self: center;
  margin-right: 20px;
  font-weight: 600;
}
.color-box.condo_owner{
  background: #f589c7;
}
.color-box.condominium{
  background: #913489;
}
.color-box.tenancy{
  background: #00a4bd;
}
.color-box.object{
  background: #f38d38;
}
.color-box.device{
  background: #767171;
}
.color-box.request{
  background: #f7b6ae;
}
.containerCircles {
  height: 21px;
  align-items: center;
  display: flex;
}
:deep(.autosuggest__results) {
  z-index: 9998 !important;
}
.tooltip {
  z-index: 9999;
}
.autosuggest__results-item .selected{
  color: rgba(0, 0, 0, 0.5) !important;
}
:deep(.tooltip.b-tooltip) {
  z-index: 9999;
}
.selectedOption {
    color: #495057;
    background-color: #e9ecef;
  }
</style>
