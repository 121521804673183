<template>
  <section>
    <div>
      <ais-instant-search
        v-if="searchClient"
        ref="instant-search"
        :search-client="searchClient"
        index-name="contact"
        :routing="routing">
        <app-refresh
          ref="app-refresh"
          @refresh-done="refreshDone" />
        <ais-stats>
          <template #default="{ nbHits }">
            <coozzy-page-title
              v-if="!linkMail"
              :title="linkContact ? $t('message.marketingMessages.titleLinkContact', { contactName: getContactFromLink }) : $t('message.navigation.contact.title')"
              :count="nbHits"
              :count-text="$t('message.generic.contacts')"
              :action-text="isEditor ? $t('message.contact.newContact') : '' "
              :clear-filter="checkfilter || isFiltered"
              @click="$bvModal.show('create-contact-modal')"
              @clear-filter="clickOnClear" />
            <coozzy-page-title
              v-else
              :title="$t('message.marketingMessages.linkMailToContact.title')"
              :count="nbHits"
              :count-text="$t('message.generic.contacts')"
              :action-text="$t('message.contact.newContact')"
              :clear-filter="checkfilter || isFiltered"
              @click="$bvModal.show('create-contact-modal')"
              @clear-filter="clickOnClear" />
          </template>
        </ais-stats>
        <!-- Filter -->
        <div class="row justify-content-between align-items-center call-action">
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <ais-search-box>
              <template #default="{ currentRefinement }">
                <debounced-algolia-search-box
                  ref="debouncedAlgolia"
                  :default-value="currentRefinement"
                  :delay="300" />
              </template>
            </ais-search-box>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="types"
              :limit="100">
              <template #default="{items, refine}">
                {{ defaultRefining('filterRole', items) }}
                <coozzy-multiselect
                  v-model="filterRole"
                  :options="sortedFilterValues(filterRoleOption(items), customLabel)"
                  :multiple="true"
                  :placeholder="$t('message.generic.roles')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="industries"
              :limit="100">
              <template #default="{items, refine}">
                {{ defaultRefining('filterIndustries', items) }}
                <coozzy-multiselect
                  v-model="filterIndustries"
                  :options="sortedFilterValues(getFilterIndustriesOptions(items), customLabelIndustries)"
                  :multiple="true"
                  :placeholder="$t('message.generic.industry')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelIndustries"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="tags"
              :limit="100">
              <template #default="{items, refine}">
                {{ defaultRefining('filterTags', items) }}
                <coozzy-multiselect
                  v-model="filterTags"
                  :options="sortedFilterValues(getFilterTagsOptions(items), customLabelTags)"
                  :multiple="true"
                  :placeholder="$t('message.generic.tags')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelTags"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div
            class="col-12 col-md-2 mb-2 mb-md-0">
            <coozzy-form-select
              v-model="filterPreferredCommunicationChannel">
              <option
                :value="null">
                {{ $t('message.contact.preferredCommunicationChannel') }}
              </option>
              <option value="PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED">
                -
              </option>
              <option value="PREFERRED_COMMUNICATION_CHANNEL_EMAIL">
                {{ $t('message.contact.preferredCommunicationChannelType.email') }}
              </option>
              <option value="PREFERRED_COMMUNICATION_CHANNEL_LETTER">
                {{ $t('message.contact.preferredCommunicationChannelType.letter') }}
              </option>
            </coozzy-form-select>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <coozzy-form-select v-model="filterStatus">
              <option value="all">
                {{ $t('message.generic.all') }}
              </option>
              <option value="active">
                {{ $t('message.generic.active') }}
              </option>
              <option value="inactive">
                {{ $t('message.generic.inactive') }}
              </option>
            </coozzy-form-select>
          </div>
          <div
            v-if="$route.name === 'AdminContactOverview' || $route.name === 'AssetContactOverview' || $route.name === 'MarketingContactOverview' || $route.name === 'AccountingContactOverview'"
            class="col-12 col-md-2 mt-2">
            <ais-refinement-list
              attribute="owner.name"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('filterOwner', items) }}
                <coozzy-multiselect
                  v-model="filterOwner"
                  :options="items"
                  :multiple="true"
                  :placeholder="$t('message.tableColumnHeadings.owner')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customOwnerLabel"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="refineResult(refine, $event.value)"
                  @remove="refineResult(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div
            class="col-12 col-md-2 mb-2 mb-md-0 mt-2"
            :class="$route.name === 'OwnerContactOverview' ? ' offset-md-10' :' offset-md-8'">
            <ais-refinement-list
              attribute="isOnlyInterestedParty"
              :limit="100">
              <template #default="{refine}">
                <coozzy-form-checkbox
                  :id="'isOnlyInterestedParty'"
                  ref="isOnlyInterestedParty"
                  v-model="filterShowInterestedParties"
                  class="label-padding-top-005rem"
                  :initial="$route.fullPath.includes('isOnlyInterestedParty')"
                  @change="() => { refineResult(refine, true);refineResult(refine, false);refineCheckboxResult(refine, filterShowInterestedParties); }">
                  {{
                    $t('message.generic.showInterestedParties')
                  }}
                </coozzy-form-checkbox>
              </template>
            </ais-refinement-list>
          </div>
        </div>
        <!-- eslint-disable vue/attribute-hyphenation -->
        <ais-configure
          :hitsPerPage="nbrPerPage"
          :filters="filterQuery" />
        <!-- eslint-enable vue/attribute-hyphenation -->
        <div class="row mt-2 align-items-center">
          <div
            v-if="!linkMail && !linkContact"
            class="col-sm-12 col-md-2">
            <coozzy-dropdown
              design="green"
              size="sm"
              class="w-100"
              :text="selectedEntriesText">
              <coozzy-dropdown-item
                v-if="isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator)"
                :disabled="selectedEntries.length === 0"
                @click="createTicket">
                {{ $t('message.ticketOverview.createTicket') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="isEditor"
                :disabled="selectedEntries.length === 0"
                @click="showActivity()">
                {{ $t('message.contact.activity.create') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="$refs['reminder-creation'].show()">
                {{ $t('message.calendar.reminder.create') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="isEditor"
                :disabled="selectedEntries.length === 0"
                @click="serialEmail()">
                {{ selectedEntries.length === 1 ? $t('message.generic.oneEmail') : $t('message.generic.serialEmail') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="isEditor"
                :disabled="selectedEntries.length === 0"
                @click="serialLetter()">
                {{ selectedEntries.length === 1 ? $t('message.generic.oneSerialLetter') : $t('message.generic.serialLetter') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-space-divider v-if="isEditor" />
              <coozzy-dropdown-item
                v-if="isEditor"
                :disabled="selectedEntries.length === 0"
                @click="editAddress()">
                {{ $t('message.contact.actions.editAddress') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-space-divider v-if="isEditor" />
              <div
                v-if="isEditor && filterStatus === 'active'"
                class="row m-0"
                :class="selectedEntries.length === 0 || checkIfOneIsTenant ? 'coozzyDropdownItemTooltip' : ''">
                <coozzy-dropdown-item
                  class="col pr-0 pl-0"
                  :disabled="selectedEntries.length === 0 || checkIfOneIsTenant"
                  @click="selectedEntries.length !== 0 ? openConfirmationModal() : ''">
                  {{ $t('message.generic.inactivate') }}
                </coozzy-dropdown-item>
                <div class="pl-0">
                  <coozzy-info-circle-icon
                    v-if="checkIfOneIsTenant"
                    v-b-tooltip.hover.html="$t('message.deleteErrors.inactivatecontactisActiveTenancyTooltip')"
                    class="mt-2" />
                </div>
              </div>
              <coozzy-dropdown-item
                v-else-if="isEditor && filterStatus === 'inactive'"
                :disabled="selectedEntries.length === 0"
                @click="selectedEntries.length !== 0 ? openConfirmationModal() : ''">
                {{ $t('message.generic.activate') }}
              </coozzy-dropdown-item>
            </coozzy-dropdown>
          </div>
          <div class="col-md-2">
            <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
              <coozzy-form-checkbox
                v-model="selectAllEntries"
                :initial="selectAllEntries"
                :class="'p-0 w-100 btn-height-auto'"
                name="selectAllEntries"
                button>
                {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
              </coozzy-form-checkbox>
            </template>
            <ais-clear-refinements :included-attributes="['query', 'owner.name', 'types', 'tags', 'industries', 'isOnlyInterestedParty']">
              <template #default="{ canRefine, refine }">
                {{ setCanRefine(canRefine) }}
                <a
                  v-if="canRefine || filterStatus !== 'all' || filterShowInterestedParties || filterPreferredCommunicationChannel"
                  ref="clearClick"
                  @click="initFilter(refine)" />
              </template>
            </ais-clear-refinements>
          </div>
          <div
            :class="[linkMail === '' ? 'offset-md-6' : 'offset-md-8', !linkContact ? 'offset-md-6' : 'offset-md-8']"
            class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0">
            <coozzy-form-select
              v-model="nbrPerPage"
              name="numberEntries"
              class="select-entries float-right">
              <option value="10">
                10 {{ $t('message.generic.entries') }}
              </option>
              <option value="50">
                50 {{ $t('message.generic.entries') }}
              </option>
              <option value="100">
                100 {{ $t('message.generic.entries') }}
              </option>
              <option value="500">
                500 {{ $t('message.generic.entries') }}
              </option>
              <option value="1000">
                1000 {{ $t('message.generic.entries') }}
              </option>
            </coozzy-form-select>
          </div>
        </div>
        <ais-state-results>
          <template #default="{ hits, results }">
            {{ getState(results) }}
            <b-table
              ref="contactOverviewTable"
              bordered
              hover
              responsive="true"
              stacked="md"
              selectable
              select-mode="single"
              class="mt-2"
              :fields="fields"
              :items="filteredHits(hits)"
              :per-page="nbrPerPage"
              :current-page="currentPage"
              @row-clicked="onRowClicked"
              @row-middle-clicked="onMiddlelicked">
              <!-- Busy state -->
              <div
                slot="table-busy"
                class="text-center text-danger my-2">
                <coozzy-spinner />
              </div>

              <!-- Headings -->
              <template
                v-if="!linkContact"
                #head(checkbox)>
                <coozzy-form-checkbox
                  ref="header_checkbox"
                  @change="(value) => { handleCheckboxesInsideTable(value) }" />
              </template>
              <template #head(numericId)>
                {{ $t('message.tableColumnHeadings.addressNumber') }}
              </template>
              <template #head(name)>
                {{ $t('message.generic.name') }}
              </template>
              <template #head(contactPerson)>
                {{ $t('message.tableColumnHeadings.contactPersons') }}
              </template>
              <template #head(address)>
                {{ $t('message.generic.address') }}
              </template>
              <template #head(communication)>
                {{ $t('message.generic.communication') }}
              </template>
              <template #head(roles)>
                {{ $t('message.generic.roles') }}
              </template>
              <template #head(owner)>
                {{ $t('message.tableColumnHeadings.owner') }}
              </template>

              <!-- Columns -->
              <template
                v-if="!linkContact"
                #cell(checkbox)="data">
                <coozzy-form-checkbox
                  :id="'check_' + data.item.objectID"
                  @change="(value) => { contactSelected(data.item, value) }" />
              </template>
              <template #cell(numericId)="data">
                {{ data.item.numericId }}
              </template>
              <template
                #cell(name)="data">
                {{ data.item.name }} <span class="text-red">{{ data.item.active ? '' : '(' + $t('message.generic.inactive') + ')' }}</span>
              </template>
              <!-- Contact person -->
              <template
                #cell(contactPerson)="data">
                <template v-if="data.item.contactPersons && data.item.contactPersons.length > 0">
                  <span
                    v-for="(person, index) in data.item.contactPersons"
                    :key="person.id + index">
                    <span v-if="person.active">{{ person.name }}<br></span>
                  </span>
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <!-- Address -->
              <template
                #cell(address)="data">
                <address-text :address="data.item.address" />
              </template>
              <!-- Communication -->
              <template
                #cell(communication)="data">
                <div
                  v-if="getPhoneNumbers(data.item.phoneNumbers, 'mobile') !== '-'"
                  class="row">
                  <div class="col-1">
                    <coozzy-mobile-alt-icon />
                  </div>
                  <div class="col">
                    <a
                      class="link"
                      :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'mobile')">
                      {{ getPhoneNumbers(data.item.phoneNumbers, 'mobile') }}
                    </a>
                  </div>
                </div>
                <div
                  v-if="getPhoneNumbers(data.item.phoneNumbers, 'home') !== '-' && !data.item.isCompany"
                  class="row">
                  <div class="col-1">
                    <coozzy-phone-icon />
                  </div>
                  <div class="col">
                    <a
                      class="link"
                      :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'home')">
                      {{ getPhoneNumbers(data.item.phoneNumbers, 'home') }}
                    </a>
                  </div>
                </div>
                <div
                  v-if="getPhoneNumbers(data.item.phoneNumbers, 'work') !== '-'"
                  class="row">
                  <div class="col-1">
                    <coozzy-headset-icon />
                  </div>
                  <div class="col">
                    <a
                      class="link"
                      :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'work')">
                      {{ getPhoneNumbers(data.item.phoneNumbers, 'work') }}
                    </a>
                  </div>
                </div>
                <div
                  v-if="data.item.email !== ''"
                  class="row">
                  <div class="col-1">
                    <img
                      class="email-icon-img"
                      src="@/assets/icon/arroba.svg"
                      alt="Placeholder">
                  </div>
                  <div class="col">
                    <template v-if="data.item.email !== ''">
                      <a
                        class="link"
                        tabindex="0"
                        @click="sendEmailModal(data.item)">
                        {{ data.item.email }}
                      </a>
                    </template>
                    <template v-else>
                      {{ data.item.email | displayOptionalValue }}
                    </template>
                  </div>
                </div>
                <div
                  v-if="data.item.website !== ''"
                  class="row">
                  <div class="col-1">
                    <coozzy-globe-icon />
                  </div>
                  <div class="col">
                    <template v-if="data.item.website !== ''">
                      <a
                        class="link"
                        tabindex="0"
                        target="_blank"
                        :href="getUrlWebsite(data.item.website)">
                        {{ data.item.website }}
                      </a>
                    </template>
                    <template v-else>
                      {{ data.item.website | displayOptionalValue }}
                    </template>
                  </div>
                </div>
              </template>
              <!-- Roles -->
              <template
                #cell(roles)="data">
                {{ getListRole(data.item) }}
                <template v-if="getTranslatedIndustries(data.item) !== '-'">
                  <br>
                  {{ $t('message.generic.industry') }}:<br>
                  {{ getTranslatedIndustries(data.item) }}
                </template>
              </template>
              <!-- Owner -->
              <template
                #cell(owner)="data">
                {{ data.item.owner.name }}
              </template>
            </b-table>
          </template>
        </ais-state-results>
        <pagination-algolia
          ref="paginator"
          @changed-page="clearCheckbox()" />
      </ais-instant-search>
    </div>

    <b-modal
      :id="'confirmationModal'"
      ref="confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="filterStatus === 'inactive' ? $t('message.employeesSettings.activateContactConfirmation') : $t('message.employeesSettings.deactivateContactConfirmation')">
      <div class="col p-0">
        <p v-if="filterStatus === 'inactive'">
          {{ $t('message.employeesSettings.activeUserText') }}
        </p>
        <p v-else>
          {{ $t('message.employeesSettings.deactivateContactText') }}
        </p>
      </div>
      <div class="col p-0">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          :disabled="disabledDeleteBtn"
          @click="$bvModal.hide('confirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          v-if="filterStatus === 'inactive'"
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="disabledDeleteBtn"
          @click="activateContact()">
          {{ $t('message.generic.activate') }}
        </coozzy-button>
        <coozzy-button
          v-else
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="disabledDeleteBtn"
          @click="deactivateContact()">
          {{ $t('message.generic.inactivate') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      :id="'link-mail-to-contact'"
      :ref="'link-mail-to-contact'"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.marketingMessages.linkMailToContact.modalTitle')">
      <div class="col">
        <p>{{ $t('message.marketingMessages.linkMailToContact.modalText') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('link-mail-to-contact')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="linkMailToContact()">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      :id="'confirmationModalLinkContact'"
      ref="confirmationModalLinkContact"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.contact.linkContact.title')">
      <div class="col pl-0">
        <p>{{ $t('message.contact.linkContact.text') }}</p>
      </div>
      <div class="col pl-0">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('confirmationModalLinkContact')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="disabledLinkBtn"
          @click="linkContacts()">
          {{ $t('message.generic.confirm') }}
        </coozzy-button>
      </div>
    </b-modal>
    <create-contact-modal
      :for-owner-id="isOwnerModule ? accountId : null"
      :show-add-another="!linkContact"
      :show-search="false"
      :show-request-sources="false"
      :set-interested-party-role="false"
      :enable-delete="false"
      :restrict-new-contact-type-to="linkContactType === 'person' ? 'private' : (linkContactType === 'company' ? 'company' : null)"
      :initial-email="email"
      @contact-created="contactChanged"
      @contact-updated="contactChanged"
      @contact-deleted="contactChanged" />
    <reminder-creation-modal
      ref="reminder-creation"
      :references-ids="selectedEntriesIds"
      :source="'addressbook'"
      @new-reminder="clearCheckbox" />
    <create-activity-modal
      ref="activity-creation"
      :key="keyActivity"
      :ids="selectedEntriesIds"
      @activity-created="clearCheckbox" />
    <add-address-modal
      :list-contacts="selectAllEntries ? allEntries : selectedEntries"
      @address-added="addressAdded" />
  </section>
</template>

<script>
import ContactApi from '../../misc/apis/ContactApi'
import AddressText from '../../framework/components/misc/AddressText'
import Vue from 'vue'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzyDropdown from '../../framework/components/dropdown/CoozzyDropdown'
import CoozzyMultiselect from '../../framework/components/multiselect/CoozzyMultiselect'
import CoozzyDropdownItem from '../../framework/components/dropdown/CoozzyDropdownItem'
import CoozzyFormSelect from '../../framework/components/form/select/CoozzyFormSelect'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import CoozzyFormCheckbox from '../../framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyPhoneIcon from '../../framework/components/icons/CoozzyPhoneIcon'
import CoozzyHeadsetIcon from '../../framework/components/icons/CoozzyHeadsetIcon'
import CoozzyGlobeIcon from '../../framework/components/icons/CoozzyGlobeIcon'
import CoozzyPageTitle from '../../framework/layout/CoozzyPageTitle'
import CoozzyDropdownSpaceDivider from '../../framework/components/dropdown/CoozzyDropdownSpaceDivider'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import AppRefresh from '../../framework/components/RefreshAlgolia'
import DebouncedAlgoliaSearchBox from '../../framework/components/DebouncedAlgoliaSearchBox'
import PaginationAlgolia from '../../framework/components/PaginationAlgolia'
import { algolia } from '@/mixins/algolia'
import MessageApi from '../../misc/apis/MessageApi'
import { mapMutations } from 'vuex'
import { tables } from '@/mixins/tables'
import { contact } from '@/mixins/contact'
import { routeChecks } from '@/mixins/routeChecks'
import AddAddressModal from '@/contact/components/AddAddressModal'
import CreateContactModal from '@/contact/components/CreateContactModal'
import { user } from '@/mixins/user'
import CoozzyMobileAltIcon from '@/framework/components/icons/CoozzyMobileAltIcon'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon'
import { v4 as uuidv4 } from 'uuid'
import { mail } from '@/mixins/mail'

export default {
  name: 'ContactOverview',
  components: {
    CoozzyInfoCircleIcon,
    CoozzyMobileAltIcon,
    CreateContactModal,
    AppRefresh,
    DebouncedAlgoliaSearchBox,
    ReminderCreationModal,
    CreateActivityModal,
    CoozzyDropdownSpaceDivider,
    CoozzyPageTitle,
    PaginationAlgolia,
    CoozzyGlobeIcon,
    CoozzyPhoneIcon,
    CoozzyFormCheckbox,
    CoozzySpinner,
    CoozzyFormSelect,
    CoozzyDropdownItem,
    CoozzyMultiselect,
    CoozzyDropdown,
    CoozzyButton,
    CoozzyHeadsetIcon,
    AddAddressModal,
    AddressText
  },
  mixins: [routeChecks, algolia, tables, contact, user, mail],
  props: {
    email: {
      type: String,
      default: ''
    },
    linkMail: {
      type: String,
      default: ''
    },
    linkContact: {
      type: String,
      default: null
    },
    linkContactType: {
      type: String,
      default: null
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.navigation.contact.title')
    }
  },
  data() {
    return {
      action: '',
      showFirst: false,
      checkfilter: false,
      keyActivity: 0,
      selectedEntries: [],
      selectedLinkContact: null,
      currentPage: 1,
      disabledDeleteBtn: false,
      disabledLinkBtn: false,
      preferredCommunicationChannelOptions: [{ label: 'PREFERRED_COMMUNICATION_CHANNEL_EMAIL', value: 'PREFERRED_COMMUNICATION_CHANNEL_EMAIL' }, { label: 'PREFERRED_COMMUNICATION_CHANNEL_LETTER', value: 'PREFERRED_COMMUNICATION_CHANNEL_LETTER' }],
      fields: [
        {
          key: 'numericId',
          label: this.$t('message.tableColumnHeadings.addressNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'name',
          label: 'Name',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'contactPerson',
          label: this.$t('message.generic.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.contactPersons'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'communication',
          label: this.$t('message.generic.communication'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'roles',
          label: this.$t('message.generic.roles'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      contactDetail: {},
      filterRole: [],
      filterPreferredCommunicationChannel: null,
      filterIndustries: [],
      filterTags: [],
      contactPersonsIds: [],
      selectedRow: null,
      filterOwner: [],
      filterStatus: 'active',
      filterShowInterestedParties: false,
      linkContactResolved: '',
      paramsAlgolia: {
        query: '',
        params: '',
        index: ''
      },
      nbrTotalOfHits: 0,
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: []
    }
  },
  computed: {
    checkIfOneIsTenant() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.filter(x => x && !x.canBeSetInactive).length > 0
    },
    selectedEntriesIds() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.filter(x => x !== undefined && x !== null).map(obj => obj.objectID)
    },
    filterQuery() {
      let query = 'isOwner:false'
      if (this.linkContact && this.linkContactType !== '' && this.linkContactType) {
        // if (this.linkContactType === 'person') {
        //   query += ' isCompany:false AND isEmployee:false'
        // } else if (this.linkContactType === 'company') {
        //   query += ' isCompany:true AND isEmployee:false'
        // }
      } else {
        query += ' AND isEmployee:false'
      }
      if (!this.filterShowInterestedParties) {
        query += ' AND isOnlyInterestedParty:false'
      }

      if (this.filterStatus === 'active') {
        query += ' AND active:true'
      } else if (this.filterStatus === 'inactive') {
        query += ' AND active:false'
      }
      if (this.filterPreferredCommunicationChannel) {
        if (this.filterPreferredCommunicationChannel === 'PREFERRED_COMMUNICATION_CHANNEL_EMAIL') {
          query += ' AND preferredCommunicationChannel:PREFERRED_COMMUNICATION_CHANNEL_EMAIL'
        } else if (this.filterPreferredCommunicationChannel === 'PREFERRED_COMMUNICATION_CHANNEL_LETTER') {
          query += ' AND preferredCommunicationChannel:PREFERRED_COMMUNICATION_CHANNEL_LETTER'
        } else if (this.filterPreferredCommunicationChannel === 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED') {
          query += ' AND preferredCommunicationChannel:PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED'
        }
      }
      return query
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    getContactFromLink() {
      return this.linkContactResolved !== '' ? this.linkContactResolved : ''
    },
    isFiltered() {
      return (this.filterPreferredCommunicationChannel !== null && this.filterPreferredCommunicationChannel !== '') || this.filterStatus !== 'all'
    }
  },
  watch: {
    checkfilter: function () {
      this.clearCheckbox()
    },
    filterPreferredCommunicationChannel: function (newVal) {
      if (newVal) {
        this.clearCheckbox()
      }
    },
    filterStatus: function () {
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    nbrPerPage: function () {
      if (this.showFirst) {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.refresh()
          }
        })
      } else {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.redirect(this.$route.query.page)
          }
        })
      }
      this.showFirst = true
      this.clearCheckbox()
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    currentPage: function () {
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    linkContact: function () {
      if (!this.linkContact && !this.fields.some(obj => obj.key === 'checkbox')) {
        this.fields.unshift(
          {
            key: 'checkbox',
            label: '',
            thClass: 'th-checkbox',
            tdClass: 'align-middle border-right-0 checkbox-cell'
          })
      }
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        // check if we already call algolia with the same params
        if (this.nbrTotalOfHits !== this.allEntries.length) {
          this.allEntries = await this.getAllAvailableDataByIndex(this.paramsAlgolia)
        }
      }
    }
  },
  mounted: function () {
    this.loadInstantSearch()
    if (!this.linkContact && !this.linkMail) {
      this.fields.unshift(
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        })
    }
    if (['AdminContactOverview', 'MarketingContactOverview', 'AssetContactOverview', 'AccountingContactOverview'].includes(this.$route.name)) {
      this.fields.push(
        {
          key: 'owner',
          label: this.$t('message.tableColumnHeadings.owner'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      )
    }
    if (this.$route.fullPath.includes('isOnlyInterestedParty')) {
      this.filterShowInterestedParties = true
    }
    if (this.linkMail) {
      MessageApi.listMailsByIds([this.linkMail])
        .then(response => {
          this.email = response.mails[0]
        })
    }
    if (this.linkContact) {
      ContactApi.contactResolve([this.linkContact])
        .then(response => {
          this.linkContactResolved = response.persons.concat(response.companies).find(x => x.id === this.linkContact).name
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
    }
    setTimeout(() => {
      this.$refs.debouncedAlgolia?.setFocusInput()
    }, 500)
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSendMailSecondaryTitle',
      'setUseBcc',
      'setSendMailContactsWithoutEmail',
      'setSelectedEntries'
    ]),
    getState(result) {
      this.nbrTotalOfHits = result.nbHits
      const params = this.editAlgoliaParams(result.params)
      this.paramsAlgolia = {
        query: result.query,
        params: params,
        index: result.index,
        attributesToRetrieve: ['objectID', 'owner', 'canBeSetInactive']
      }
      return ''
    },
    createTicket() {
      const defaultAssignee = this.selectedEntries.every((val, i, arr) => val.owner.id === arr[0].owner.id) ? this.selectedEntries[0].owner.id : ''
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee } })
      }
    },
    filterRoleOption(items) {
      if (this.filterShowInterestedParties) {
        return items
      }
      return items.filter(x => x.value !== 'interested_party')
    },
    getFilterIndustriesOptions(items) {
      return items.filter(x => x.value !== 'INDUSTRY_UNDEFINED_VALUE')
    },
    getFilterTagsOptions(items) {
      return items
    },
    clickOnClear() {
      this.$refs.clearClick.click()
    },
    setCanRefine(canRefine) {
      this.checkfilter = canRefine
      return ''
    },
    initFilter(refine) {
      this.filterOwner = []
      this.filterRole = []
      this.filterIndustries = []
      this.filterTags = []
      this.filterStatus = 'all'
      this.filterShowInterestedParties = false
      this.filterPreferredCommunicationChannel = null
      this.clearCheckboxFilterShowInterestedParties()
      setTimeout(() => {
        refine()
      }, 1) // Fix for clearing filter: isOnlyInterestedParty
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    clearCheckboxFilterShowInterestedParties() {
      const filterShowInterestedParties = this.$refs.isOnlyInterestedParty
      const node = filterShowInterestedParties?.$el.childNodes[0]
      if (node && node.checked !== false) {
        node.checked = false
        let event = new Event('change')
        node.dispatchEvent(event)
      }
    },
    filteredHits(array) {
      return array.filter(x => x.isEmployee !== true)
    },
    onRowClicked(item, index, event) {
      if (this.linkContact) {
        this.selectedLinkContact = item.objectID
        this.$refs.confirmationModalLinkContact.show()
      } else if (this.linkMail) {
        this.selectedRow = item
        this.$refs['link-mail-to-contact'].show()
      } else if (event.srcElement.classList.contains('custom-checkbox') || event.srcElement.classList.contains('checkbox-cell')) {
        this.contactSelected(item, !document.getElementById('check_' + item.objectID).checked)
        document.getElementById('check_' + item.objectID).checked = !document.getElementById('check_' + item.objectID).checked
      } else {
        this.showDetail(item)
      }
    },
    serialLetter() {
      const uniqKey = uuidv4()
      localStorage.setItem(uniqKey, this.selectedEntriesIds)
      this.$nextTick(() => {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerSerialLetterProcessView',
            query: {
              source: 'contact',
              uuid: uniqKey,
              previousRoute: this.$route.fullPath
            }
          })
        } else if (this.isMarketingModule) {
          this.$router.push({
            name: 'MarketingSerialLetterProcessView',
            query: {
              source: 'contact',
              uuid: uniqKey,
              previousRoute: this.$route.fullPath
            }
          })
        } else if (this.isAdminModule) {
          this.$router.push({
            name: 'AdminSerialLetterProcessView',
            query: {
              source: 'contact',
              uuid: uniqKey,
              previousRoute: this.$route.fullPath
            }
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetSerialLetterProcessView',
            query: {
              source: 'contact',
              uuid: uniqKey,
              previousRoute: this.$route.fullPath
            }
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingSerialLetterProcessView',
            query: {
              source: 'contact',
              uuid: uniqKey,
              previousRoute: this.$route.fullPath
            }
          })
        }
      })
    },
    showActivity() {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.$nextTick(() => {
        this.$refs['activity-creation'].show()
      })
    },
    linkContacts() {
      this.disabledLinkBtn = true
      if (this.selectedLinkContact === this.linkContact) {
        Vue.toasted.show(this.$t('message.savingErrors.notSamePersonLinked'), { type: 'error' })
        this.$refs.confirmationModalLinkContact.hide()
        this.disabledLinkBtn = false
      } else {
        if (this.linkContactType === 'person') {
          ContactApi.linkPersons(this.selectedLinkContact, this.linkContact)
            .then(() => {
              this.$refs.confirmationModalLinkContact.hide()
              this.selectedLinkContact = []
              if (this.$route.name === 'MarketingContactOverview') {
                this.$router.push({ name: 'MarketingContactDetailsView', params: { id: this.linkContact } })
              } else if (this.$route.name === 'AdminContactOverview') {
                this.$router.push({ name: 'AdminContactDetailsView', params: { id: this.linkContact } })
              } else if (this.$route.name === 'AssetContactOverview') {
                this.$router.push({ name: 'AssetContactDetailsView', params: { id: this.linkContact } })
              } else if (this.$route.name === 'AccountingContactOverview') {
                this.$router.push({ name: 'AccountingContactDetailsView', params: { id: this.linkContact } })
              } else {
                this.$router.push({ name: 'OwnerContactDetailsView', params: { id: this.linkContact } })
              }
              this.disabledLinkBtn = false
              Vue.toasted.show(this.$t('message.successMessages.contactLinked'), { type: 'success' })
            })
            .catch(e => {
              this.disabledLinkBtn = false
              console.log(e)
              Vue.toasted.show(this.$t('message.deleteErrors.contact'), { type: 'error' })
            })
        } else {
          ContactApi.linkCompanies(this.selectedLinkContact, this.linkContact)
            .then(() => {
              this.$refs.confirmationModalLinkContact.hide()
              this.selectedLinkContact = []
              if (this.$route.name === 'MarketingContactOverview') {
                this.$router.push({ name: 'MarketingContactDetailsView', params: { id: this.linkContact } })
              } else if (this.$route.name === 'AdminContactOverview') {
                this.$router.push({ name: 'AdminContactDetailsView', params: { id: this.linkContact } })
              } else if (this.$route.name === 'AssetContactOverview') {
                this.$router.push({ name: 'AssetContactDetailsView', params: { id: this.linkContact } })
              } else if (this.$route.name === 'AccountingContactOverview') {
                this.$router.push({ name: 'AccountingContactDetailsView', params: { id: this.linkContact } })
              } else {
                this.$router.push({ name: 'OwnerContactDetailsView', params: { id: this.linkContact } })
              }
              this.disabledLinkBtn = false
              Vue.toasted.show(this.$t('message.successMessages.contactLinked'), { type: 'success' })
            })
            .catch(e => {
              this.disabledLinkBtn = false
              console.log(e)
              Vue.toasted.show(this.$t('message.deleteErrors.contact'), { type: 'error' })
            })
        }
      }
    },
    contactSelected(contact, checked) {
      if (checked) {
        this.selectedEntries.push(contact)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry && (entry.objectID !== contact.objectID))
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    openConfirmationModal() {
      this.$refs.confirmationModal.show()
    },
    deactivateContact() {
      this.disabledDeleteBtn = true
      ContactApi.deactivateContact(this.selectedEntriesIds)
        .then(() => {
          this.action = 'contactInActivated'
          this.$refs['app-refresh'].refresh()
        })
        .catch(e => {
          this.disabledDeleteBtn = false
          if (this.$refs.confirmationModal) {
            this.$refs.confirmationModal.hide()
          }
          console.log(e)
          if (e.response.status === 400 && e.response.data.code === 9) {
            Vue.toasted.show(this.$t('message.deleteErrors.contactisActiveTenancy'), { type: 'error' })
          } else {
            Vue.toasted.show(this.$t('message.deleteErrors.contact'), { type: 'error' })
          }
        })
    },
    activateContact() {
      this.disabledDeleteBtn = true
      ContactApi.activateContact(this.selectedEntriesIds)
        .then(() => {
          this.action = 'contactActivated'
          this.$refs['app-refresh'].refresh()
        })
        .catch(e => {
          this.disabledDeleteBtn = false
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.contact'), { type: 'error' })
        })
    },
    customLabel(item) {
      return this.$t('message.contact.types.' + item.label.toUpperCase()) + ' (' + item.count + ')'
    },
    customLabelTags(item) {
      return item.label + ' (' + item.count + ')'
    },
    customLabelIndustries(item) {
      return this.$t('message.contact.industries.' + item.label) + ' (' + item.count + ')'
    },
    customOwnerLabel(item) {
      return item.label + ' (' + item.count + ')'
    },
    getListRole(contact) {
      if (contact.types.length === 0 || (contact.types.length === 1 && contact.types[0] === 'none')) {
        return '-'
      } else {
        const array = []
        let contactType = contact.types
        if (contactType.filter(x => x === 'tenant').length > 0 && !this.filterShowInterestedParties) {
          contactType = contactType.filter(x => x !== 'interested_party')
        }
        contactType.forEach(role => {
          if (role.toUpperCase() !== 'none') {
            array.push(this.$t('message.contact.types.' + role.toUpperCase()))
          }
        })
        return array.join(', ')
      }
    },
    contactChanged(data) {
      if (this.linkContact) {
        this.selectedLinkContact = data.contact.id
        this.linkContacts()
      } else if (this.linkMail) {
        this.selectedRow = data.contact
        this.linkMailToContact()
      } else if (data.addingAnother === true || data.takeOverAddress === true) {
        this.action = 'contactAdded'
        this.$refs['app-refresh'].refresh()
      } else if (data.contact) {
        this.redirectToDetailPage(data.contact.id)
      }
    },
    editAddress() {
      this.$bvModal.show('add-address-modal')
    },
    addressAdded() {
      this.action = 'addressAdded'
      this.$refs['app-refresh'].refresh()
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.contactOverviewTable) {
        const allCheckboxesComponents = this.$refs.contactOverviewTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
        if (value === false) {
          this.selectAllEntries = false
        }
      }
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = value
      }
    },
    showDetail(item) {
      if (this.$route.name === 'MarketingContactOverview') {
        this.$router.push({ name: 'MarketingContactDetailsView', params: { id: item.objectID || item.id } })
      } else if (this.$route.name === 'AdminContactOverview') {
        this.$router.push({ name: 'AdminContactDetailsView', params: { id: item.objectID || item.id } })
      } else if (this.$route.name === 'AssetContactOverview') {
        this.$router.push({ name: 'AssetContactDetailsView', params: { id: item.objectID || item.id } })
      } else if (this.$route.name === 'AccountingContactOverview') {
        this.$router.push({ name: 'AccountingContactDetailsView', params: { id: item.objectID || item.id } })
      } else {
        this.$router.push({ name: 'OwnerContactDetailsView', params: { id: item.objectID || item.id } })
      }
    },
    onMiddlelicked(item) {
      let route = null
      if (this.$route.name === 'MarketingContactOverview') {
        route = this.$router.resolve({ name: 'MarketingContactDetailsView', params: { id: item.objectID } })
      } else if (this.$route.name === 'AdminContactOverview') {
        route = this.$router.resolve({ name: 'AdminContactDetailsView', params: { id: item.objectID } })
      } else if (this.$route.name === 'AssetContactOverview') {
        route = this.$router.resolve({ name: 'AssetContactDetailsView', params: { id: item.objectID } })
      } else if (this.$route.name === 'AccountingContactOverview') {
        route = this.$router.resolve({ name: 'AccountingContactDetailsView', params: { id: item.objectID } })
      } else {
        route = this.$router.resolve({ name: 'OwnerContactDetailsView', params: { id: item.objectID } })
      }
      window.open(route.href)
    },
    async serialEmail() {
      let contacts = []
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      this.setSelectedEntries(listEntries)
      await ContactApi.contactResolve(this.selectedEntriesIds)
        .then(response => {
          if (response.persons.length > 0) {
            contacts = response.persons
          }
          if (response.companies.length > 0) {
            contacts = contacts.concat(response.companies)
          }
        })
      const contactsWithEmail = contacts.filter(x => x.email !== '' && this.selectedEntriesIds.includes(x.id))
      const contactsWithoutEmail = contacts.filter(x => x && x.email === '' && this.selectedEntriesIds.includes(x.id))
      this.setSendMailContactsWithoutEmail(contactsWithoutEmail)
      this.setSendMailContacts(contactsWithEmail)
      this.setSendMailPreselectedContact(contacts)
      this.setSendMailReferenceIds([])
      this.setSendMailEmailToReply(null)
      this.setUseBcc(true)
      this.setSendMailSecondaryTitle(true)
      this.$nextTick(function () {
        this.callSendMailTypeModal()
      })
    },
    sendEmailModal(contact) {
      contact.id = contact.objectID
      this.setSendMailContacts([contact])
      this.setSendMailPreselectedContact(contact)
      this.setSendMailReferenceIds([])
      this.setSendMailEmailToReply(null)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    linkMailToContact() {
      MessageApi.setReferenceIds([this.linkMail], [this.selectedRow.objectID || this.selectedRow.id].concat(this.email.referenceIds.filter(x => !x.includes('contact_'))))
        .then(() => {
          Vue.toasted.show(this.$t('message.successMessages.messageLinked'), { type: 'success' })
          if (this.email !== '') {
            this.showDetail(this.selectedRow)
          } else {
            if (this.isOwnerModule) {
              this.$router.push({ name: 'OwnerMessagesView' })
            } else if (this.isMarketingModule) {
              this.$router.push({ name: 'MarketingMessagesView' })
            } else if (this.isAdminModule) {
              this.$router.push({ name: 'AdminMessagesView' })
            } else if (this.isAssetModule) {
              this.$router.push({ name: 'AssetMessagesView' })
            } else if (this.isAccountingModule) {
              this.$router.push({ name: 'AccountingMessagesView' })
            }
          }
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.messageLinked'), { type: 'error' })
        })
    },
    refreshDone() {
      if (this.$refs.confirmationModal) {
        this.$refs.confirmationModal.hide()
      }
      this.clearCheckbox()
      this.disabledDeleteBtn = false
      if (this.action === 'contactAdded') {
        // Do nothing
      } else if (this.action === 'addressAdded') {
        Vue.toasted.show(this.$t('message.successMessages.addressChanged'), { type: 'success' })
      } else if (this.action === 'contactActivated') {
        Vue.toasted.show(this.$t('message.successMessages.contactActivated'), { type: 'success' })
      } else {
        Vue.toasted.show(this.$t('message.successMessages.contactInactivated'), { type: 'success' })
      }
    },
    getUrlWebsite(item) {
      if (item.indexOf('//') > -1) {
        return item
      } else {
        return '//' + item
      }
    },
    refineResult(refine, value) {
      refine(value)
      this.clearCheckbox()
    },
    refineCheckboxResult(refine, value) {
      if (!value && this.filterRole.find(x => x.label === 'interested_party')) {
        setTimeout(() => {
          if (this.$refs.clearClick) {
            this.clickOnClear()
          }
        }, 200)
      }
      this.clearCheckbox()
    }
  }
}
</script>

<style lang="scss" scoped>

  .role-list {
    list-style-type: none;
  }

  .title-name {
    line-height: 2;
  }

  .label-padding-top-005rem :deep(label) {
    padding-top: 0.05rem;
  }
</style>
