<template>
  <div class="row">
    <div class="col-12">
      <h5>{{ $t('message.applicationForm.documents') }}</h5>
    </div>
    <div class="col-12 col-md-6">
      <div>
        <div class="d-inline-block">
          <b>{{ $t('message.applicationForm.debtEnforcementRegister') }}</b><br>
          <span class="maxFileSize">max. 3MB</span>
        </div>
        <coozzy-button
          design="green"
          class="float-right"
          @click="uploadDocumentClicked('debtUpload')">
          {{ $t('message.manageDocuments.addDocument') }}
        </coozzy-button>
      </div>
      <coozzy-spinner
        v-if="debtUploadProcessing"
        class="align-self-center" />
      <div
        v-for="item in debtEnforcementRegisterMedia"
        :key="item.id"
        class="media-block col">
        <div
          class="file-block p-1 my-2">
          <a
            data-v-09485260=""
            :href="item.url"
            target="_blank">
            {{ item.filename }}
          </a>
          <coozzy-button
            design="transparent"
            class="delete-icon"
            @click="deleteMedia(item.id, 'debtEnforcementRegisterMediaIds')">
            <coozzy-delete-icon />
          </coozzy-button>
        </div>
      </div>
      <coozzy-form-file-input
        ref="debtUpload"
        v-model="debtUpload"
        class="d-none"
        accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx" />
    </div>
    <div class="col-12 col-md-6">
      <div>
        <div class="d-inline-block">
          <b>{{ $t('message.applicationForm.tradeRegisterExcerptMedia') }}</b><br>
          <span class="maxFileSize">max. 3MB</span>
        </div>
        <coozzy-button
          design="green"
          class="float-right"
          @click="uploadDocumentClicked('tradeRegisterExcerptUpload')">
          {{ $t('message.manageDocuments.addDocument') }}
        </coozzy-button>
      </div>
      <coozzy-spinner
        v-if="tradeRegisterExcerptProcessing"
        class="align-self-center" />
      <div
        v-for="item in tradeRegisterExcerptMedia"
        :key="item.id"
        class="media-block col">
        <div
          class="file-block p-1 my-2">
          <a
            data-v-09485260=""
            :href="item.url"
            target="_blank">
            {{ item.filename }}
          </a>
          <coozzy-button
            design="transparent"
            class="delete-icon"
            @click="deleteMedia(item.id, 'tradeRegisterExcerptMediaIds')">
            <coozzy-delete-icon />
          </coozzy-button>
        </div>
      </div>
      <coozzy-form-file-input
        ref="tradeRegisterExcerptUpload"
        v-model="tradeRegisterExcerptUpload"
        class="d-none"
        accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx" />
    </div>
    <div class="col-12 col-md-6 mt-2">
      <div>
        <div class="d-inline-block">
          <b>{{ $t('message.applicationForm.idCard') }}</b><br>
          <span class="maxFileSize">max. 3MB</span>
        </div>
        <coozzy-button
          design="green"
          class="float-right"
          @click="uploadDocumentClicked('idCardUpload')">
          {{ $t('message.manageDocuments.addDocument') }}
        </coozzy-button>
      </div>
      <coozzy-spinner
        v-if="idCardUploadProcessing"
        class="align-self-center" />
      <div
        v-for="item in idCardMedia"
        :key="item.id"
        class="media-block col">
        <div
          class="file-block p-1 my-2">
          <a
            data-v-09485260=""
            :href="item.url"
            target="_blank">
            {{ item.filename }}
          </a>
          <coozzy-button
            design="transparent"
            class="delete-icon"
            @click="deleteMedia(item.id, 'idCardMedia')">
            <coozzy-delete-icon />
          </coozzy-button>
        </div>
      </div>
      <coozzy-form-file-input
        ref="idCardUpload"
        v-model="idCardUpload"
        class="d-none"
        accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx" />
    </div>
    <div class="col-12 col-md-6 mt-2">
      <div>
        <div class="d-inline-block">
          <b>{{ $t('message.applicationForm.media') }}</b><br>
          <span class="maxFileSize">max. 3MB</span>
        </div>
        <coozzy-button
          design="green"
          class="float-right"
          @click="uploadDocumentClicked('mediaUpload')">
          {{ $t('message.manageDocuments.addDocument') }}
        </coozzy-button>
      </div>
      <coozzy-spinner
        v-if="mediaUploadProcessing"
        class="align-self-center" />
      <div
        v-for="item in media"
        :key="item.id"
        class="media-block col">
        <div
          class="file-block p-1 my-2">
          <a
            data-v-09485260=""
            :href="item.url"
            target="_blank">
            {{ item.filename }}
          </a>
          <coozzy-button
            design="transparent"
            class="delete-icon"
            @click="deleteMedia(item.id, 'mediaIds')">
            <coozzy-delete-icon />
          </coozzy-button>
        </div>
      </div>
      <coozzy-form-file-input
        ref="mediaUpload"
        v-model="mediaUpload"
        class="d-none"
        accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import MediaApi from '../../../misc/apis/MediaApi'
import CoozzySpinner from '../../../framework/components/misc/CoozzySpinner'
import Vue from 'vue'
import { media } from '@/mixins/media'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput.vue'

export default {
  name: 'ApplicationFormCompanyDocuments',
  components: {
    CoozzyFormFileInput,
    CoozzyDeleteIcon,
    CoozzyButton,
    CoozzySpinner
  },
  mixins: [media],
  props: {
    company: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      debtEnforcementRegisterMedia: [],
      idCardMedia: [],
      tradeRegisterExcerptMedia: [],
      media: [],
      mediaUpload: null,
      mediaUploadProcessing: false,
      tradeRegisterExcerptUpload: null,
      tradeRegisterExcerptProcessing: false,
      idCardUpload: null,
      idCardUploadProcessing: false,
      debtUpload: null,
      debtUploadProcessing: false
    }
  },
  computed: {
    debtEnforcementRegisterMediaIds: {
      get() {
        return this.company.debtEnforcementRegisterMediaIds ? this.company.debtEnforcementRegisterMediaIds : []
      },
      set(value) {
        const com = this.company
        com.debtEnforcementRegisterMediaIds = value
        this.updateCompany(com)
      }
    },
    idCardMediaIds: {
      get() {
        return this.company.idCardMediaIds ? this.company.idCardMediaIds : []
      },
      set(value) {
        const com = this.company
        com.idCardMediaIds = value
        this.updateCompany(com)
      }
    },
    tradeRegisterExcerptMediaIds: {
      get() {
        return this.company.tradeRegisterExcerptMediaIds ? this.company.tradeRegisterExcerptMediaIds : []
      },
      set(value) {
        const com = this.company
        com.tradeRegisterExcerptMediaIds = value
        this.updateCompany(com)
      }
    },
    mediaIds: {
      get() {
        return this.company.mediaIds ? this.company.mediaIds : []
      },
      set(value) {
        const com = this.company
        com.mediaIds = value
        this.updateCompany(com)
      }
    }
  },
  watch: {
    mediaUpload: function (newValue) {
      if (newValue !== null) {
        this.mediaUploadProcessing = true
        this.documentCreated(newValue, 'mediaIds')
      }
    },
    tradeRegisterExcerptUpload: function (newValue) {
      if (newValue !== null) {
        this.tradeRegisterExcerptProcessing = true
        this.documentCreated(newValue, 'tradeRegisterExcerptMediaIds')
      }
    },
    idCardUpload: function (newValue) {
      if (newValue !== null) {
        this.idCardUploadProcessing = true
        this.documentCreated(newValue, 'idCardMediaIds')
      }
    },
    debtUpload: function (newValue) {
      if (newValue !== null) {
        this.debtUploadProcessing = true
        this.documentCreated(newValue, 'debtEnforcementRegisterMediaIds')
      }
    }
  },
  methods: {
    ...mapMutations('applicationForm', ['updateCompany']),
    uploadDocumentClicked(ref) {
      if (!this.readOnly) {
        const btn = this.$refs[ref].$el.children
        btn[0].click()
      }
    },
    documentCreated(document, suffix) {
      const docSizeMB = document.size / (1024 * 1024)
      if (docSizeMB > 3) {
        this.mediaUploadProcessing = false
        this.debtUploadProcessing = false
        this.idCardUploadProcessing = false
        this.tradeRegisterExcerptProcessing = false
        Vue.toasted.show(this.$tc('message.uploadingErrors.maxFileSize'), { type: 'error' })
      } else {
        const formData = new FormData()
        const fileName = this.editFileName(document.name)
        formData.append('file', document, fileName)
        formData.append('filename', document.name)
        formData.append('public', 'true')
        MediaApi.uploadMedia(formData)
            .then(response => {
              const docs = this[suffix] ? this[suffix] : []
              docs.push(response.id)
              this[suffix] = docs
              const noIdSuffix = suffix.replace('Ids', '')
              this[noIdSuffix].push(response)
              this.mediaUpload = null
              this.debtUpload = null
              this.tradeRegisterExcerptUpload = null
              this.idCardUpload = null
            })
            .catch(e => {
              console.log(e)
              if (e.response && e.response.data && e.response.data.message === 'Not supported MIME type') {
                Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
              } else {
                Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
              }
            })
            .finally(() => {
              this.mediaUploadProcessing = false
              this.debtUploadProcessing = false
              this.idCardUploadProcessing = false
              this.tradeRegisterExcerptProcessing = false
            })
      }
    },
    deleteMedia(id, suffix) {
      this[suffix] = this[suffix].filter(e => e !== id)
      const noIdSuffix = suffix.replace('Ids', '')
      this[noIdSuffix] = this[noIdSuffix].filter(el => el.id !== id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .maxFileSize {
    font-size: 0.875rem;
  }
</style>
