<template>
  <section>
    <div>
      <!-- Heading -->
      <coozzy-page-title
        :title="$t('message.ticketOverview.title')"
        :count="ticketLength"
        :count-text="$t('message.ticketOverview.title')"
        :action-text="isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator) ? $t('message.ticketOverview.createTicket') : ''"
        :clear-filter="checkfilter"
        @click="createTicket"
        @clear-filter="clickOnClear">
        <template #after-title>
          <div class="col-12 col-md-2 mr-auto">
            <coozzy-button
              design="transparent"
              class="mt-2"
              @click="changeColumnView(!isColumnView)">
              <coozzy-list-icon v-if="isColumnView" />
              <coozzy-column-icon v-else />
            </coozzy-button>
          </div>
        </template>
      </coozzy-page-title>
      <ticket-overview-data
        ref="ticket-overview-data"
        :link-email="linkEmail"
        :name-linked="nameLinked"
        :default-text="defaultText"
        :default-type="defaultType"
        :default-creator="defaultCreator"
        :default-assigne="defaultAssigne"
        :default-priority="defaultPriority"
        :default-overdue="defaultOverdue"
        :default-watched="defaultWatched"
        :default-new-event="defaultNewEvent"
        :default-operator="defaultOperator"
        :default-empty-assignee="defaultEmptyAssignee"
        :owner-ids="ownerIds"
        :reference-ids="referenceIds"
        @is-filtered="setCheckfilter"
        @ticket-length="getTicketLength" />
    </div>
  </section>
</template>

<script>
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import TicketOverviewData from './TicketOverviewData'
import CoozzyListIcon from '@/framework/components/icons/CoozzyListIcon'
import CoozzyColumnIcon from '@/framework/components/icons/CoozzyColumnIcon'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import { routeChecks } from '@/mixins/routeChecks'
import { user } from '@/mixins/user'

export default {
  name: 'TicketOverview',
  components: {
    CoozzyPageTitle,
    TicketOverviewData,
    CoozzyListIcon,
    CoozzyColumnIcon,
    CoozzyButton
  },
  metaInfo() {
    return {
      title: this.$t('message.navigation.tickets')
    }
  },
  mixins: [routeChecks, user],
  beforeRouteLeave(to, from, next) {
    if (to.fullPath !== from.fullPath && to.fullPath !== localStorage.getItem('ticket-path')) {
      if (from.query.linkEmail) {
        // remove linkMail parameter from the full path
        const modifiedPath = from.fullPath.replace(`&linkEmail=${from.query.linkEmail}`, '')
        localStorage.setItem('ticket-path', modifiedPath)
      } else {
        localStorage.setItem('ticket-path', from.fullPath)
      }
      next()
    } else {
      next()
    }
  },
  props: {
    defaultText: {
      type: String,
      default: ''
    },
    defaultType: {
      type: String,
      default: ''
    },
    defaultCreator: {
      type: String,
      default: ''
    },
    defaultAssigne: {
      type: String,
      default: ''
    },
    defaultPriority: {
      type: String,
      default: ''
    },
    defaultOperator: {
      type: String,
      default: ''
    },
    defaultOverdue: {
      type: String,
      default: ''
    },
    defaultWatched: {
      type: String,
      default: ''
    },
    defaultEmptyAssignee: {
      type: Boolean,
      default: false
    },
    linkEmail: {
      type: String,
      default: null
    },
    nameLinked: {
      type: String,
      default: null
    },
    ownerIds: {
      type: String,
      default: ''
    },
    referenceIds: {
      type: String,
      default: ''
    },
    defaultNewEvent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checkfilter: false,
      ticketLength: 0,
      isColumnView: true
    }
  },
  created() {
    // check if the user has a ticket path in the local storage with the same beginning of the current path we will redirect him to the ticket path and remove the ticket path from the local storage
    if (localStorage.getItem('ticket-path') && this.$route.path.startsWith(localStorage.getItem('ticket-path').substring(0, 10)) && this.linkEmail === null) {
      this.$router.replace({ path: localStorage.getItem('ticket-path') }, () => {
        localStorage.removeItem('ticket-path')
      })
    }
  },
  mounted() {
    if (localStorage.getItem('is-column-view-ticket')) {
      this.isColumnView = localStorage.getItem('is-column-view-ticket') === 'true'
    }
    this.$refs['ticket-overview-data'].setColumnView(this.isColumnView)
  },
  methods: {
    changeColumnView(newVal) {
      this.isColumnView = newVal
      this.$refs['ticket-overview-data'].setColumnView(newVal)
      localStorage.setItem('is-column-view-ticket', newVal)
    },
    createTicket() {
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation' }, () => {
        })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation' }, () => {
        })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation' }, () => {
        })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketCreation' }, () => {
        })
      } else {
        this.$router.push({ name: 'MarketingTicketCreation' }, () => {
        })
      }
    },
    getTicketLength(value) {
      this.ticketLength = value
    },
    setCheckfilter(value) {
      this.checkfilter = value
    },
    clickOnClear() {
      this.$refs['ticket-overview-data'].initFilter()
    }
  }
}
</script>
