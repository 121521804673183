const formatting = {
  methods: {
    formatRooms(rooms) {
      // Not set
      if (!rooms || rooms === -1) {
        return '-'
      }

      return rooms + ' ' + this.$t('message.onBoarding.buildings.objects.rooms')
    },
    formatFloor(floor) {
      // Ground floor
      if (floor === 0 || floor === '0') {
        return this.$t('message.onBoarding.buildings.objects.groundFloorLabel')
      }

      // Not set
      if (!floor || floor === -10000) {
        return '-'
      }

      // Middle floors
      if (floor === 0.5) {
        return this.$t('message.onBoarding.buildings.objects.raisedGroundFloorLabel')
      }
      if (floor === -0.5) {
        return this.$t('message.onBoarding.buildings.objects.loweredGroundFloorLabel')
      }

      // Basement
      if (floor === -1 || (floor !== undefined && floor.toString().replace(String.fromCharCode(92), '') === '-1')) {
        return this.$t('message.onBoarding.buildings.objects.firstBasementFloorLabel')
      }
      if (floor === -2 || (floor !== undefined && floor.toString().replace(String.fromCharCode(92), '') === '-2')) {
        return this.$t('message.onBoarding.buildings.objects.secondBasementFloorLabel')
      }
      if (floor === -3 || (floor !== undefined && floor.toString().replace(String.fromCharCode(92), '') === '-3')) {
        return this.$t('message.onBoarding.buildings.objects.thirdBasementFloorLabel')
      }
      const formatedFloor = floor.toString().replace(String.fromCharCode(92), '')
      if (floor < 0 || parseInt(formatedFloor) < 0) {
        return (isNaN(floor * -1) ? (parseInt(formatedFloor) * -1) : (floor * -1)) + this.$t('message.onBoarding.buildings.objects.basementFloorLabel')
      }

      // Level upwards
      if (floor === 1 || floor === '1') {
        return this.$t('message.onBoarding.buildings.objects.firstFloorLabel')
      } else if (floor === 2 || floor === '2') {
        return this.$t('message.onBoarding.buildings.objects.secondFloorLabel')
      } else if (floor === 3 || floor === '3') {
        return this.$t('message.onBoarding.buildings.objects.thirdFloorLabel')
      }

      return floor + this.$t('message.onBoarding.buildings.objects.floorLabel')
    }
  }
}

export { formatting }
