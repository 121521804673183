import MediaApi from '@/misc/apis/MediaApi'
import Vue from 'vue'

const media = {
  methods: {
    getUrlOfThumbnailS(medium) {
      for (const thumbnail of medium.thumbnails || []) {
        if (thumbnail.type === 'S') {
          return thumbnail.url
        }
      }

      return medium.url
    },
    updateUploadedFileName(ctx, fileName) {
      if (this.$refs['file-input']?.$el?.children?.length && this.$refs['file-input'].$el.children[1].children?.length && this.$refs['file-input'].$el.children[1].children[0].children.length) {
        ctx.$refs['file-input'].$el.children[1].children[0].children[0].innerHTML = fileName
      }
    },
    editFileName(name) {
      return name
        .replace(/\u00df/g, 'ss')
        .replace(/\u00e4/g, 'ae')
        .replace(/\u00f6/g, 'oe')
        .replace(/\u00fc/g, 'ue')
        .replace(/\u00c4/g, 'Ae')
        .replace(/\u00d6/g, 'Oe')
        .replace(/\u00dc/g, 'Ue')
    },
    getFileHeader(blob: Blob) {
      return new Promise(resolve => {
        const headerBytes = blob.slice(0, 4) // Read the first 4 bytes of the file
        const fileReader = new FileReader()
        fileReader.onloadend = (e: ProgressEvent<FileReader>) => {
          const arr = new Uint8Array(e?.target?.result as ArrayBufferLike).subarray(0, 4)
          let header = ''
          for (let i = 0; i < arr.length; i++) {
            header += arr[i].toString(16)
          }
          resolve(header)
        }
        fileReader.readAsArrayBuffer(headerBytes)
      })
    },
    mergeDownloadAllDocuments(idsAndNames, nameOfMergedFile) {
      Vue.toasted.show(this.$tc('message.successMessages.serialLetterCreated'), { type: 'success' })
      MediaApi.mergeAllDocuments(idsAndNames, nameOfMergedFile).then(async response => {
        const file = response.media
        const binary = window.atob(file)
        const len = binary.length
        const buffer = new ArrayBuffer(len)
        const view = new Uint8Array(buffer)
        for (let i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i)
        }
        const blob = new Blob([view])
        // check if file type is pdf
        const pdfBytePattern = '25504446'
        const fileHeader = await this.getFileHeader(blob)
        const isPdf = fileHeader === pdfBytePattern

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = nameOfMergedFile + (isPdf ? '.pdf' : '.zip')
        link.click()
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateOrderError'), { type: 'error' })
      })
    }
  }
}

export { media }
