<template>
  <b-modal
    id="automatic-response-mailbox-modal"
    ref="automatic-response-mailbox-modal"
    :modal-class="'has-confirmation-modal'"
    no-close-on-backdrop
    hide-footer
    :title="$t('message.settings.mailbox.automaticResponseFor') ">
    <div
      v-if="loading"
      class="row">
      <div class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
    </div>
    <div
      v-else
      class="row">
      <ul>
        <li
          v-for="(item) in mailBoxes"
          :key="item.id">
          {{ item.outboundEmail }}
        </li>
      </ul>
      <div
        v-if="!isSameResponse"
        class="col-12 mt-1">
        <coozzy-alert>
          {{ $t('message.settings.mailbox.alertAutomaticResponse') }}
        </coozzy-alert>
      </div>
      <div class="col-12 mb-3">
        <label>{{ $t('message.settings.mailbox.automaticResponse') }}</label>
        <div class="row px-3 mt-3">
          <coozzy-form-radio
            v-model="automaticReply.enabled"
            :default-model="automaticReply.enabled"
            name="sendAutomaticResponse"
            class="col"
            :select-value="true">
            {{ $t('message.settings.mailbox.sendAutomaticResponse') }}
          </coozzy-form-radio>
          <coozzy-form-radio
            v-model="automaticReply.enabled"
            :default-model="automaticReply.enabled"
            name="sendAutomaticResponse"
            class="col ml-4"
            :select-value="false">
            {{ $t('message.settings.mailbox.noAutomaticResponse') }}
          </coozzy-form-radio>
        </div>
      </div>
      <template v-if="automaticReply.enabled">
        <div class="col-sm-12 col-md-3 mb-3">
          <coozzy-form-input
            v-model="dates.startingAt"
            :filter="'formatDate'"
            type="date"
            :state="$v.automaticReply?.startDate.$dirty && $v.automaticReply.startDate.$error ? false : null"
            :name="$t('message.marketingMessages.mail.from') + '*'"
            @blur="dateToObject('startingAt')" />
        </div>
        <div class="col-sm-12 col-md-3 mb-3">
          <label>{{ $t('message.calendar.event.time') }}</label>
          <coozzy-form-select
            id="startTime"
            v-model="times.startingAt"
            :state="$v.automaticReply?.startTime.$dirty && $v.automaticReply.startTime.$error ? false : null">
            <option
              v-for="t in timeList"
              :key="'start' + t"
              :value="t">
              {{ t }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-sm-12 col-md-3">
          <coozzy-form-input
            v-model="dates.endingAt"
            :filter="'formatDate'"
            type="date"
            :name="$t('message.generic.until')"
            @blur="dateToObject('endingAt')" />
        </div>
        <div class="col-sm-12 col-md-3 mb-3">
          <label>{{ $t('message.calendar.event.time') }}</label>
          <coozzy-form-select
            id="endTime"
            v-model="times.endingAt"
            :state="dates.endingAt && $v.automaticReply?.endTime.$dirty && $v.automaticReply.endTime.$error ? false : null">
            <option
              v-for="t in timeList"
              :key="'end' + t"
              :value="t">
              {{ t }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-sm-12 col-md-12 mb-3">
          <label>{{ $t('message.settings.template.title') }}</label>
          <coozzy-form-select v-model="templateId">
            <option :value="null" />
            <option
              v-for="template in templates"
              :key="template.id"
              :value="template.id">
              {{ template.name }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-sm-12 col-md-12 mb-3">
          <coozzy-form-input
            v-model="automaticReply.subject"
            :state="$v.automaticReply?.subject.$dirty && $v.automaticReply.subject.$error ? false : null"
            :name="$t('message.marketingMessages.mail.subject') + '*'" />
        </div>
        <div
          class="col-sm-12 col-md-12 mb-3">
          <label for="description">{{ $t('message.marketingMessages.mail.body')+ '*' }}</label>
          <editor
            ref="editor-tiny"
            :key="keyRefresh"
            v-model="automaticReply.bodyHtml"
            :api-key="editorApi"
            :init="{
              toolbar: 'undo redo | bold italic underline forecolor | numlist bullist link table | fullscreen print',
              plugins: 'fullscreen lists code image table link',
              height: '150',
              menubar: '',
              language: currentLanguage,
              elementpath: false,
              invalid_styles: {
                '*': 'font-family',
              },
              automatic_uploads: true,
              license_key: 'gpl',
              content_style: contentStyle,
              font_formats: fontFormats,
              branding: false,
              remove_trailing_brs: false,
              browser_spellcheck: true,
              table_toolbar: '',
              font_size_formats: fontsizeFormats,
              convert_urls: false
            }" />
        </div>
      </template>

      <div class="col mt-2">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="hide()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="createAutomaticResponse">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import MessageApi from '../../misc/apis/MessageApi'
import Editor from '@tinymce/tinymce-vue'
import { user } from '@/mixins/user'
import { html } from '@/mixins/html'
import { dateUtils } from '@/mixins/dateUtils'
import { required } from 'vuelidate/lib/validators'
// import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import Vue from 'vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import { mail } from '@/mixins/mail'

export default {
  name: 'AutomaticResponseMailboxModal',
  components: {
    // CoozzyMultiselect
    Editor
  },
  mixins: [user, html, dateUtils, mail],
  props: {
    mailBoxes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      keyRefresh: 0,
      templateId: '',
      automaticReply: {
        bodyHtml: '',
        bodyText: '',
        endDate: null,
        startDate: null,
        startTime: {
          hours: 0,
          minutes: 0,
          seconds: 0
        },
        endTime: {
          hours: 0,
          minutes: 0,
          seconds: 0
        },
        subject: '',
        enabled: false
      },
      loading: false,
      dates: {
        startingAt: null,
        endingAt: null
      },
      times: {
        startingAt: null,
        endingAt: null
      },
      timeList: []
    }
  },
  computed: {
    ...mapState('message', ['templates']),
    ...mapGetters('message', ['getSignatureForUserId']),
    fontSetting() {
      return this.$store.getters['user/getFontSettings']
    },
    contentStyle() {
      const defaultFontFamily = 'Lato, sans-serif'
      const fontFamily = this.fontSetting && this.fontSetting.family !== '' ? `'${this.fontSetting.family}', ` : ''
      const fontSize = this.fontSetting && this.fontSetting.size !== '' ? this.fontSetting.size + 'pt' : '10pt'
      return `body { color: #495057 !important; font-family: ${fontFamily}'${defaultFontFamily} !important'; font-size: ${fontSize};} p {margin: 0}`
    },
    fontsizeFormats() {
      let sizeOptions = []
      for (let i = 4; i <= 36; i++) {
        sizeOptions.push(`${i}pt`)
      }
      return sizeOptions.join(' ')
    },
    fontFormats() {
      const defaultFont = 'Lato=Lato, sans-serif;'
      const userFont = this.fontSetting && this.fontSetting.family !== '' ? `${this.fontSetting.family}=${this.fontSetting.family}, sans-serif;` : ''
      return `${defaultFont}${userFont}`
    },
    isSameResponse() {
      return this.mailBoxes.every((val, i, arr) => JSON.stringify(val.automaticReply) === JSON.stringify(arr[0].automaticReply))
    },
    currentUserId() {
      return this.$store.getters['user/getUserId']
    },
    editorApi() {
      return 'gpl'
    }
  },
  watch: {
    templateId: function (newVal) {
      if (newVal) {
        this.renderTemplate()
      }
    },
    'automaticReply.enabled': function (newVal) {
      if (this.automaticReply?.bodyHtml === '') {
        this.automaticReply.bodyHtml = this.getDefaultAnswerSuffix()
      }
    },
    'times.startingAt': function(newVal) {
      this.automaticReply.startTime = this.toTimeObject(newVal)
    },
    'times.endingAt': function (newVal) {
      this.automaticReply.endTime = this.toTimeObject(newVal)
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'automatic-response-mailbox-modal') {
        this.$nextTick(function () {
          this.keyRefresh += 1
        })
      }
    })
    this.getListTimes()
    if (this.isSameResponse && this.mailBoxes.length > 0) {
      this.automaticReply = this.mailBoxes[0].automaticReply
      if (!this.automaticReply) {
        this.automaticReply = {
          bodyHtml: this.getDefaultAnswerSuffix(),
          bodyText: '',
          endDate: null,
          startDate: null,
          startTime: {
            hours: 0,
            minutes: 0,
            seconds: 0
          },
          endTime: {
            hours: 0,
            minutes: 0,
            seconds: 0
          },
          subject: '',
          enabled: false
        }
      }
      this.dates.startingAt = this.$options.filters.objectToDateInput(this.mailBoxes[0].automaticReply?.startDate)
      this.dates.endingAt = this.$options.filters.objectToDateInput(this.mailBoxes[0].automaticReply?.endDate)
      if (this.mailBoxes[0].automaticReply?.startTime) {
        const startHour = this.mailBoxes[0].automaticReply?.startTime?.hours
        const startMinutes = '0' + this.mailBoxes[0].automaticReply?.startTime?.minutes
        this.times.startingAt = ((startHour < 10) ? '0' + startHour : startHour) + ':' + startMinutes.substr(-2)
      }
      if (this.mailBoxes[0].automaticReply?.startTime) {
        const endHour = this.mailBoxes[0].automaticReply?.endTime?.hours
        const endMinutes = '0' + this.mailBoxes[0].automaticReply?.endTime?.minutes
        this.times.endingAt = ((endHour < 10) ? '0' + endHour : endHour) + ':' + endMinutes.substr(-2)
      }
    }
    if (this.automaticReply?.bodyHtml === '') {
      this.automaticReply.bodyHtml = this.getDefaultAnswerSuffix()
    }
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'automatic-response-mailbox-modal') {
        this.$nextTick(function () {
          this.keyRefresh += 1
        })
      }
    })
  },
  methods: {
    ...mapActions('message', ['resetMailboxes']),
    getListTimes() {
      let hours, minutes
      const list = []
      for (let i = 0; i <= 1425; i += 15) {
        hours = Math.floor(i / 60)
        minutes = i % 60
        if (minutes < 10) {
          minutes = '0' + minutes // adding leading zero
        }
        if (hours < 10) {
          hours = '0' + hours // adding leading zero
        }
        if (hours === 24) {
          hours = '00'
        }
        list.push(hours + ':' + minutes)
      }
      this.timeList = list
    },
    renderTemplate() {
      MessageApi.renderTemplate(this.templateId, '')
        .then(response => {
          this.automaticReply.subject = response.subject

          const contentUpdate = response.text + this.getDefaultAnswerSuffix()
          this.automaticReply.bodyHtml = contentUpdate
          this.$refs['editor-tiny']?.editor?.fire('focusin') // refresh v-model
          this.$refs['editor-tiny']?.editor?.fire('focusout') // refresh v-model
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.renderTemplate'), { type: 'error' })
        })
    },
    getDefaultAnswerSuffix() {
      let suffix = ''
      if (this.getSignatureForUserId(this.currentUserId).length) {
        suffix += '<br>' + (this.getSignatureForUserId(this.currentUserId)).replaceAll('<br />', '<br>').replaceAll('\n', '')
      }
      return suffix
    },
    toTimeObject(stringTime) {
      if (!stringTime && stringTime !== '') {
        return null
      }
      const myTime = stringTime.split(':')

      return {
        hours: parseInt(myTime[0]),
        minutes: parseInt(myTime[1]),
        seconds: 0
      }
    },
    dateToObject(field = '') {
      if (field === 'startingAt') {
        this.automaticReply.startDate = this.toObject(this.dates.startingAt)
      } else if (field === 'endingAt') {
        this.automaticReply.endDate = this.toObject(this.dates.endingAt)
      }
    },
    createAutomaticResponse() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true
        this.automaticReply.bodyText = this.convertToPlainWithBreakingLines(this.automaticReply.bodyHtml)
        MessageApi.setAutomaticReply(this.automaticReply, this.mailBoxes.map(x => x.id))
          .then(response => {
            this.$emit('automatic-response-created', response)
            this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
            this.loading = false
            this.hide()
            this.$v.$reset()
            Vue.toasted.show(this.$t('message.successMessages.automaticResponse'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            this.loading = false
            if (e.response.data.error === 'Domain is not validated for your account') {
              Vue.toasted.show(this.$t('message.savingErrors.mailboxUnvalid'), { type: 'error' })
            } else {
              Vue.toasted.show(this.$t('message.savingErrors.mailbox'), { type: 'error' })
            }
          })
      }
    },
    show() {
      this.keyRefresh += 1
      this.$refs['automatic-response-mailbox-modal'].show()
      this.keyRefresh += 1
    },
    hide() {
      this.keyRefresh += 1
      this.$refs['automatic-response-mailbox-modal'].hide()
      this.keyRefresh += 1
    }
  },
  validations() {
    let validations = {}
    if (this.automaticReply.enabled) {
      validations = {
        automaticReply: {
          startDate: {
            required
          },
          startTime: {
            required
          },
          subject: {
            required
          },
          bodyHtml: {
            required
          }
        }
      }
      if (this.dates.endingAt) {
        validations.automaticReply.endTime = {
          required
        }
      }
    }
    return validations
  }
}
</script>

<style lang="scss" scoped>

</style>
