<template>
  <section>
    <b-modal
      id="activate-tenancy-modal"
      ref="activate-tenancy-modal"
      :modal-class="'has-confirmation-modal'"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.interestedParty.action.activateTenancy')"
      @close="hide">
      <div
        v-if="uploading"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div
            v-if="!isSameTenant"
            class="col-sm-12 col-md-12 mb-3">
            <h5
              v-if="hasSignRequestComplete">
              {{ $tc('message.deepSign.documentAddedAuto') }}
            </h5>
            <label>{{ $t('message.onBoarding.buildings.objects.tenancies.activateModal.uploadContract') }}</label>
            <coozzy-form-file-input
              id="file"
              v-model="file"
              accept="text/*, application/*, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx" />
          </div>
          <div
            v-if="(isAdminModule || isAccountingModule) && tenancies.length > 1"
            class="col-md-12">
            <label class="text-md">{{ $t('message.onBoarding.buildings.objects.tenancies.activateModal.activateOtherTenancies') }}</label>
            <b-table
              ref="activeTenanciesTable"
              class="mt-2"
              bordered
              hover
              responsive="true"
              stacked="md"
              selectable
              select-mode="single"
              :fields="fields"
              :items="tenancies.filter(item => item.tenant?.contactIds.length > 0 || item.vacancy)">
              <!-- Headings -->
              <template #head(checkbox)>
                <coozzy-form-checkbox
                  ref="header_checkbox"
                  @change="(value) => { handleCheckboxesInside(value) }" />
              </template>
              <template #head(type)>
                {{ $t('message.tableColumnHeadings.type') }}
              </template>
              <template #head(tenants)>
                {{ $t('message.tableColumnHeadings.tenant') }}
              </template>
              <template #head(object)>
                {{ $t('message.tableColumnHeadings.description') }}
              </template>
              <template #head(startAt)>
                {{ $t('message.onBoarding.buildings.objects.tenancies.startAt') }}
              </template>

              <!-- Columns -->
              <template #cell(checkbox)="data">
                <!-- data needs to be used in order to work properly -->
                <coozzy-form-checkbox
                  :id="'checkbox_' + data.item.id"
                  :initial="selectedItems.includes(data.item.id)"
                  @change="(value) => { itemSelected(data.item, value) }" />
              </template>
              <template #cell(type)="data">
                <template
                  v-if="getObject(data.item.objectId) !== '-' && getObject(data.item.objectId).category && getObject(data.item.objectId).category !== 'UNDEFINED_CATEGORY'">
                  {{ $t('message.onBoarding.buildings.objects.category.' + getObject(data.item.objectId).category.toLowerCase()) }}
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <template #cell(tenants)="data">
                <template v-if="data.item.tenant?.contactIds.length === 0">
                  -
                </template>
                <template v-else>
                  <router-link
                    v-for="(contactId, tenantIndex) in data.item.tenant.contactIds"
                    :key="tenantIndex"
                    :to="{ name: 'AdminContactDetailsView', params: { id: contactId } }">
                    {{ getContactName(contactId) }}<br>
                  </router-link>
                </template>
              </template>
              <template #cell(object)="data">
                {{ getObject(data.item.objectId) !== '-' ? getObject(data.item.objectId).name : '-' }}
              </template>
              <template #cell(startAt)="data">
                {{ data.item.startingAt | objectToDate }}
              </template>
            </b-table>
          </div>
          <div class="col">
            <coozzy-button
              size="small"
              class="mb-0 border"
              design="transparent"
              @click="hide">
              {{ $t('message.generic.cancel') }}
            </coozzy-button>
            <coozzy-button
              v-if="tenancies.length > 1"
              size="small"
              class="float-right mb-0"
              design="green"
              @click="saveClicked">
              {{ $t('message.onBoarding.buildings.objects.tenancies.activateModal.title') }}
            </coozzy-button>
            <coozzy-button
              v-else-if="tenancies.length === 1"
              size="small"
              class="float-right mb-0"
              design="green"
              @click="saveClicked">
              {{ $t('message.interestedParty.action.activateTenancy') }}
            </coozzy-button>
            <coozzy-button
              v-else
              size="small"
              class="float-right mb-0"
              design="prop-green"
              @click="saveClicked">
              {{ $t('message.generic.form.save') }}
            </coozzy-button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="setFutureAddressModal"
      ref="setFutureAddressModal"
      no-close-on-backdrop
      :title="$t('message.onBoarding.interestedParties.setFutureAddressModalTenancy.title')"
      hide-footer
      @close="hidesetFutureAddressModal()">
      <div
        v-if="adressLoading"
        class="text-center">
        <coozzy-spinner />
      </div>
      <div
        v-else
        class="col">
        <p>{{ $t('message.onBoarding.interestedParties.setFutureAddressModalTenancy.body') }}</p>
        <coozzy-button
          size="small"
          design="default"
          @click="hidesetFutureAddressModal()">
          {{ $t('message.generic.selectField.no') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="prop-green"
          @click="setAddress()">
          {{ $t('message.generic.selectField.yes') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import Vue from 'vue'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import MediaApi from '@/misc/apis/MediaApi'
import ContactApi from '@/misc/apis/ContactApi'
import ObjectApi from '@/misc/apis/ObjectApi'
import { routeChecks } from '@/mixins/routeChecks'
import { tables } from '@/mixins/tables'
import { media } from '@/mixins/media'
import DeepSignApi from '@/misc/apis/DeepSignApi'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import { dateUtils } from '@/mixins/dateUtils'

export default {
  name: 'ActivateTenancyModal',
  components: { CoozzyFormCheckbox, CoozzySpinner, CoozzyButton, CoozzyFormFileInput },
  mixins: [routeChecks, tables, media, dateUtils],
  props: {
    tenancyId: {
      type: [String, Number],
      default: ''
    },
    ownerId: {
      type: [String, Number],
      default: 0
    },
    isSameTenant: {
      type: Boolean,
      default: true
    },
    selectedEntriesIds: {
      type: Array,
      default() {
        return []
      }
    },
    source: {
      type: String,
      default: ''
    },
    object: {
      type: Object,
      default: null
    },
    tenancy: {
      type: Object,
      default: null
    },
    tenancies: {
      type: Array,
      default() {
        return []
      }
    },
    contacts: {
      type: Array,
      default() {
        return []
      }
    },
    objects: {
      type: Array,
      default() {
        return []
      }
    },
    lastTenancy: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      file: null,
      uploading: false,
      adressLoading: false,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'tenants',
          label: this.$t('message.tableColumnHeadings.tenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'object',
          label: this.$t('message.tableColumnHeadings.description'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'startAt',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.startAt'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      selectedItems: [],
      hasSignRequestComplete: false
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'activate-tenancy-modal') {
        this.selectedItems = this.selectedEntriesIds
      }
    })
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'activate-tenancy-modal') {
        this.file = null
        this.uploading = false
      }
    })
    if ((this.tenancies.length === 1) || (this.tenancies.length === 0 && this.tenancy)) {
      const tenancyId = this.tenancies.length === 1 ? this.tenancies[0].id : this.tenancy.id
      DeepSignApi.listSignRequestsByAttachToReferenceIds([tenancyId]).then((response) => {
        if (response.signRequests.length > 0) {
          response.signRequests.forEach((signRequest) => {
            if (signRequest.completed === true && signRequest.cancelled === false) {
              this.hasSignRequestComplete = true
            }
          })
        }
      })
    }
  },
  methods: {
    handleCheckboxesInside(value) {
      if (this.$refs.activeTenanciesTable) {
        const allCheckboxesComponents = this.$refs.activeTenanciesTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
      }
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedItems.push(item.id)
      } else {
        this.selectedItems = this.selectedItems.filter(id => id !== item.id)
      }
    },
    getContactName(contactId) {
      const contact = this.contacts.find(function (contact) {
        return contact.id === contactId || contact.objectID === contactId
      })

      return contact ? contact.name : '-'
    },
    getObject(objectId) {
      const object = this.objects.find(function (object) {
        return object.id === objectId || object.objectID === objectId
      })

      return object || '-'
    },
    removeDuplicatedIds(items) {
      const uniqueIds = []
      return items.filter(element => {
        const isDuplicate = uniqueIds.includes(element)
        if (!isDuplicate) {
          uniqueIds.push(element)
          return true
        }
        return false
      })
    },
    updateLastTenant(tenancyId) {
      const promises = []
      this.tenancies.forEach(element => {
        const tenant = this.lastTenancy.find(x => x.objectId === element.objectId && element.id === tenancyId)
        if (tenant && tenant.vacancy === false) {
          tenant.liabilityEndsAt = tenant.endingAt
          tenant.movedOutAt = tenant.endingAt
          tenant.terminatedOn = tenant.endingAt
          const endDate = new Date(this.$options.filters.objectToDateInput(tenant.endingAt))
          const newDate = endDate.setDate(endDate.getDate() + 1)
          tenant.nextPossibleMoveInAt = this.toObject(newDate)
          this.$store.dispatch('onboarding/updateTenancy', tenant)
          promises.push(ObjectApi.updateTenancy(tenant))
        }
      })
      Promise.all(promises)
        .then(() => {
          this.handleSuccess()
        })
        .catch(e => {
          console.log(e)
        })
    },
    saveClicked() {
      this.uploading = true
      if (this.selectedItems.length > 0) {
        if (this.file) {
          const formData = new FormData()
          const fileName = this.editFileName(this.file.name)
          formData.append('file', this.file, fileName)
          formData.append('filename', this.file.name)
          formData.append('public', 'false')
          if (this.ownerId) {
            formData.append('accountId', this.ownerId.toString())
          }

          MediaApi.uploadMedia(formData)
            .then(media => {
              MediaApi.createDocument(this.ownerId, this.$t('message.interestedParty.documents.title'), 'CONTRACT', media.id)
                .then(document => {
                  this.selectedItems.forEach(tenancyId => {
                    ObjectApi.addTenancyDocument(tenancyId, [document.id])
                      .then(() => {
                        ObjectApi.activateTenancy(tenancyId)
                          .then(() => {
                            if (this.lastTenancy.length > 0) {
                              this.updateLastTenant(tenancyId)
                            } else {
                              this.handleSuccess()
                            }
                            this.uploading = false
                          })
                      })
                      .catch(e => {
                        this.handleError(e)
                        console.log(e)
                        Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
                      })
                  })
                })
                .catch(e => {
                  this.handleError(e)
                  console.log(e)
                  Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
                })
            })
            .catch(e => {
              this.handleError(e)
              console.log(e)
              Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
            })
        } else {
          this.selectedItems.forEach(tenancyId => {
            ObjectApi.activateTenancy(tenancyId)
              .then(() => {
                if (this.lastTenancy.length > 0) {
                  this.updateLastTenant(tenancyId)
                } else {
                  this.handleSuccess()
                }
              })
          })
        }
      } else {
        if (this.file) {
          const formData = new FormData()
          const fileName = this.editFileName(this.file.name)
          formData.append('file', this.file, fileName)
          formData.append('filename', this.file.name)
          formData.append('public', 'false')
          if (this.ownerId) {
            formData.append('accountId', this.ownerId.toString())
          }

          MediaApi.uploadMedia(formData)
            .then(media => {
              MediaApi.createDocument(this.ownerId, this.$t('message.interestedParty.documents.title'), 'CONTRACT', media.id)
                .then(document => {
                  ObjectApi.addTenancyDocument(this.tenancyId, [document.id])
                    .then(() => {
                      ObjectApi.activateTenancy(this.tenancyId)
                        .then(() => {
                          if (this.lastTenancy.length > 0) {
                            this.updateLastTenant(this.tenancyId)
                          } else {
                            this.handleSuccess()
                          }
                        })
                    })
                    .catch(e => {
                      this.handleError(e)
                    })
                })
                .catch(e => {
                  this.handleError(e)
                })
            })
            .catch(e => {
              this.handleError(e)
            })
        } else {
          this.hide()
          Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
        }
      }
    },
    handleError(e) {
      this.uploading = false
      this.hide()
      console.log(e)
      if (e.response && e.response.data && e.response.data.message === 'Not supported MIME type') {
        Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
      } else {
        Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
      }
    },
    handleSuccess() {
      this.uploading = false
      let list = []
      if (this.source !== 'request') {
        list = this.tenancies.filter(x => this.selectedItems.includes(x.id) && x.vacancy === false && ['HOUSE', 'APARTMENT', 'COMMERCIAL', 'GASTRONOMY'].includes(this.getObject(x.objectId).category))
      }
      if ((this.source === 'request' && ['HOUSE', 'APARTMENT', 'COMMERCIAL', 'GASTRONOMY'].includes(this.object.category)) || list.length > 0) {
        this.$refs['activate-tenancy-modal'].hide()
        this.$nextTick(() => {
          this.$refs.setFutureAddressModal.show()
        })
      } else {
        this.hide()
        this.$emit('tenancy-activated')
      }
      Vue.toasted.show(this.$t('message.successMessages.activateTenancy'), { type: 'success' })
    },
    show() {
      if (this.$refs['activate-tenancy-modal']) {
        this.$refs['activate-tenancy-modal'].show()
      }
    },
    hide() {
      if (this.$refs['activate-tenancy-modal']) {
        this.$refs['activate-tenancy-modal'].hide()
        this.$emit('hide')
      }
    },
    hidesetFutureAddressModal() {
      this.$refs.setFutureAddressModal?.hide()
      this.hide()
      this.$emit('tenancy-activated')
    },
    setAddress() {
      this.adressLoading = true
      const list = []
      const filteredList = this.tenancies.filter(x => this.selectedItems.includes(x.id) && x.vacancy === false && ['HOUSE', 'APARTMENT', 'COMMERCIAL', 'GASTRONOMY'].includes(this.getObject(x.objectId).category))
      const items = filteredList.map(x => x.id)
      if (items.length > 0) {
        items.forEach(element => {
          const item = this.tenancies.find(tenant => tenant.id === element)
          if (item) {
            const obj = { address: item.obj ? element.obj.address : this.getObject(item.objectId).address, tenant: item.tenants ? item.tenants.flatMap(x => x.contactId) : item.tenant?.contactIds, startingAt: item.startingAt || null }
            list.push(obj)
          }
        })
      }
      if (list.length > 0) {
        const promises = []
        list.forEach(element => {
          promises.push(ContactApi.setAddress(element.address, element.tenant, element.startingAt))
        })
        Promise.all(promises)
          .then(() => {
            this.adressLoading = false
            this.hidesetFutureAddressModal()
          })
          .catch(e => {
            console.log(e)
            this.adressLoading = false
            this.hidesetFutureAddressModal()
          })
      } else if (['HOUSE', 'APARTMENT', 'COMMERCIAL', 'GASTRONOMY'].includes(this.object.category)) {
        ContactApi.setAddress(this.object.address, this.tenancy.tenant.contactIds, this.tenancy.startingAt ? this.tenancy.startingAt : null)
          .then(() => {
            this.adressLoading = false
            this.hidesetFutureAddressModal()
          })
          .catch(e => {
            console.log(e)
            this.adressLoading = false
            this.hidesetFutureAddressModal()
          })
      } else {
        this.adressLoading = false
        this.hidesetFutureAddressModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  label {
    margin-bottom: 0;
  }
  .text-md {
    font-size: 1.1rem;
    color: rgb(0, 68, 95);
  }
</style>
