<template>
  <section :class="[readOnly ? 'is-disabled' : '']">
    <coozzy-card class="global-card no-top-border">
      <div class="row justify-content-between pb-1">
        <div class="col-12 col-md">
          <h5 class="pt-1 pb-1">
            {{ countCondominium }} {{ $t('message.onBoardingTabs.condominium') }}
          </h5>
        </div>
        <div
          v-if="isFiltered"
          class="col-12 col-md-2">
          <coozzy-button
            class="w-100 sort"
            @click="initFilter()">
            <coozzy-filter-icon /> {{ $t('message.generic.clearFilters') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row justify-content-between align-items-center my-2">
        <div class="col-12 col-md mb-2 mb-md-0">
          <coozzy-form-input
            ref="searchText"
            v-model="searchText"
            :placeholder="$t('message.generic.search')"
            @keyup="searchInputText()" />
        </div>
        <div class="col-12 col-md mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchStatus"
            class="filter-inputs"
            :options="availableStatus"
            :multiple="true"
            :placeholder="$t('message.generic.currentStatus')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelStatus"
            :preselect-first="false"
            :taggable="false"
            @select="filterCondominiumList"
            @remove="filterCondominiumList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchCategories"
            class="filter-inputs"
            :options="categoryFilter"
            :multiple="true"
            :placeholder="$t('message.onBoarding.buildings.objects.category.title')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelCategory"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterCondominiumList"
            @remove="filterCondominiumList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchFloor"
            class="filter-inputs"
            :options="floorFilter"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.floor')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabel"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterCondominiumList"
            @remove="filterCondominiumList" />
        </div>
        <div class="col-12 col-md-2">
          <coozzy-multiselect
            v-model="searchRooms"
            class="filter-inputs"
            :options="roomsFilter"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.rooms')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabel"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterCondominiumList"
            @remove="filterCondominiumList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-assignee-multiselect
            v-model="searchAssignee"
            class="filter-inputs"
            :options="sortedFilterValues(assigneeFilter, customLabelAssignee, 'value')"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.assignee')"
            :close-on-select="false"
            :clear-on-select="false"
            :custom-label="customLabelAssignee"
            :preserve-search="true"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterCondominiumList"
            @remove="filterCondominiumList" />
        </div>
      </div>
      <div class="row align-items-center my-2">
        <div
          class="col-12 col-md-2 mt-2">
          <coozzy-multiselect
            v-model="searchPreferredCommunicationChannel"
            class="filter-inputs"
            :options="filterPreferredCommunicationChannel"
            :multiple="true"
            :placeholder="$t('message.contact.preferredCommunicationChannel')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelPreferredCommunicationChannel"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterCondominiumList"
            @remove="filterCondominiumList" />
        </div>
        <div
          class="col-12 col-md-2 mt-2">
          <coozzy-multiselect
            v-model="searchTags"
            class="filter-inputs"
            :options="filterTags"
            :multiple="true"
            :placeholder="$t('message.generic.tags')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabel"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterCondominiumList"
            @remove="filterCondominiumList" />
        </div>
      </div>
      <div
        id="rowConfig"
        class="row mb-2 align-items-center">
        <div class="col-md-2 col-sm-6 col-12">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100 sort"
            :text="selectedEntriesText">
            <coozzy-dropdown-item
              v-if="isTicketingEnabled && (isEditor || isTicketEditor) && (isOwnerModule || isAssetModule)"
              :disabled="selectedEntries.length === 0"
              @click="createTicket('TICKET_TYPE_TASK')">
              {{ $t('message.ticketOverview.createTicket') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown
              v-if="!isOwnerModule && !isAssetModule && isTicketingEnabled && (isEditor || isTicketEditor)"
              design="transparent"
              size="sm"
              class="w-100 ticket-dropdown-bulk-action"
              :class="selectedEntries.length > 0 ? 'enabled' : ''"
              :disabled="true"
              :text="$t('message.ticketOverview.createTicket')"
              dropright
              @click.prevent>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="navigateToCreateOrder()">
                {{ $t('message.ticketOverview.createOrder') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="createTicket('TICKET_TYPE_TASK')">
                {{ $t('message.ticketOverview.createTask') }}
              </coozzy-dropdown-item>
            </coozzy-dropdown>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="showActivity()">
              {{ $t('message.contact.activity.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="$refs['reminder-creation'].show()">
              {{ $t('message.calendar.reminder.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="serialEmail()">
              {{ selectedEntries.length === 1 ? $t('message.generic.oneEmail') : $t('message.generic.serialEmail') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="serialLetter()">
              {{ selectedEntries.length === 1 ? $t('message.generic.oneSerialLetter') : $t('message.generic.serialLetter') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-space-divider v-if="isAdminModule && isEditor" />
            <div
              v-if="isEditor"
              class="row m-0"
              :class="selectedEntries.length === 0 || notAllStatusNotActivated ? 'coozzyDropdownItemTooltip' : ''">
              <coozzy-dropdown-item
                v-if="isAdminModule || isAccountingModule"
                :disabled="selectedEntries.length === 0 || notAllStatusNotActivated"
                @click="showActivateCondominiumModal()">
                {{ $t('message.manageBuilding.bulkAction.activateCondominium') }}
              </coozzy-dropdown-item>
              <div
                v-if="isAdminModule && isEditor"
                class="pl-0">
                <coozzy-info-circle-icon
                  v-if="notAllStatusNotActivated"
                  v-b-tooltip.hover.html="$t('message.deleteErrors.activateCondominiumTooltip')"
                  class="mt-2" />
              </div>
            </div>
            <coozzy-dropdown-space-divider v-if="isEditor" />
            <div
              v-if="isEditor"
              class="row m-0"
              :class="selectedEntries.length === 0 || isTenanciesActivated > 0 ? 'coozzyDropdownItemTooltip' : ''">
              <coozzy-dropdown-item
                class="col pr-0 pl-0"
                :disabled="selectedEntries.length === 0 || isTenanciesActivated > 0"
                @click="selectedEntries.length !== 0 ? openConfirmationModal() : ''">
                {{ $t('message.generic.delete') }}
              </coozzy-dropdown-item>
              <div class="pl-0">
                <coozzy-info-circle-icon
                  v-if="isTenanciesActivated > 0"
                  v-b-tooltip.hover.html="$t('message.deleteErrors.deleteActiveTenancyTooltipMsg')"
                  class="mt-2" />
              </div>
            </div>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2 col-sm-6 col-12">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
            </coozzy-form-checkbox>
          </template>
        </div>
        <div
          v-if="isCompanyAdmin === true"
          class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
          <div
            @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div
          class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0"
          :class="isCompanyAdmin !== true ? 'offset-md-8' : ''">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option value="5">
              5 {{ $t('message.generic.entries') }}
            </option>
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="15">
              15 {{ $t('message.generic.entries') }}
            </option>
            <option value="20">
              20 {{ $t('message.generic.entries') }}
            </option>
            <option value="all">
              {{ $t('message.manageInterestedParties.filter.allEntries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <b-table
        ref="condominiumTable"
        bordered
        hover
        responsive="true"
        stacked="md"
        selectable
        select-mode="single"
        :fields="fields"
        :table-class="elementSelected !== null && elementSelected !== '' ? 'b-table-selecting' : ''"
        :items="condominiumList"
        class="overflow-auto shadow"
        @row-clicked="changeSelectedCondominium">
        <template #head(checkbox)>
          <coozzy-form-checkbox
            ref="header_checkbox"
            @change="(value) => { handleCheckboxesInsideTable(value) }" />
        </template>
        <template #head(condominiumOwner)>
          {{ $t('message.tableColumnHeadings.condominiumOwner') }}
        </template>
        <template #head(status)>
          {{ $t('message.tableColumnHeadings.status') }}
        </template>
        <template #head(contractNumber)>
          {{ $t('message.tableColumnHeadings.contractNumber') }}
        </template>
        <template #head(type)>
          {{ $t('message.tableColumnHeadings.type') }}
        </template>
        <template #head(floor)>
          {{ $t('message.tableColumnHeadings.floor') }}
        </template>
        <template #head(rooms)>
          {{ $t('message.tableColumnHeadings.rooms') }}
        </template>
        <template #head(livingArea)>
          {{ $t('message.tableColumnHeadings.livingArea') }}
        </template>
        <template #head(valueQuota)>
          {{ $t('message.tableColumnHeadings.valueQuota') }}
        </template>
        <template #head(ownershipStarting)>
          {{ $t('message.tableColumnHeadings.ownershipStarting') }}
        </template>
        <template #head(ownershipEnding)>
          {{ $t('message.tableColumnHeadings.ownershipEnding') }}
        </template>
        <template #head(assignee)>
          {{ $t('message.tableColumnHeadings.assignee') }}
        </template>
        <template #head(roommate)>
          {{ $t('message.tableColumnHeadings.roommate') }}
        </template>
        <template #head(address)>
          {{ $t('message.tableColumnHeadings.address') }}
        </template>
        <template #head(numericId)>
          {{ $t('message.tableColumnHeadings.numericId') }}
        </template>
        <template #head(objectNumber)>
          {{ $t('message.tableColumnHeadings.objectNumber') }}
        </template>
        <template #head(objects)>
          {{ $t('message.tableColumnHeadings.objects') }}
        </template>
        <template #head(tenant)>
          {{ $t('message.tableColumnHeadings.tenant') }}
        </template>
        <template #head(subCategory)>
          {{ $t('message.tableColumnHeadings.subCategory') }}
        </template>

        <!-- Table content -->
        <template
          #cell(checkbox)="data">
          <!-- data needs to be used in order to work properly -->
          <coozzy-form-checkbox
            :id="'check_' + data.item.id"
            @change="(value) => { itemSelected(data.item, value) }" />
        </template>
        <template #cell(address)="data">
          <address-text
            v-if="getObject(data.item.objectId)"
            :address="getObject(data.item.objectId).address" />
        </template>
        <template #cell(numericId)="data">
          {{ getObject(data.item.objectId).numericId | displayOptionalValue }}
        </template>
        <template #cell(objectNumber)="data">
          {{ getObject(data.item.objectId).number | displayOptionalValue }}
        </template>
        <template #cell(objects)="data">
          {{ getObject(data.item.objectId).name | displayOptionalValue }}
        </template>
        <template
          #cell(condominiumOwner)="data">
          <div v-if="data.item.vacancy === true">
            {{ $t('message.onBoarding.buildings.objects.tenancies.vacancy') }}
          </div>
          <div v-else-if="data.item.owner && data.item.owner.contactIds.length > 0">
            <a
              v-for="(contactId, tenantIndex) in data.item.owner.contactIds"
              :key="tenantIndex"
              tabindex="0"
              href="#"
              @click="redirectToDetailPage(contactId)">{{ tenantIndex === 0 ? getContactName(contactId) : ', ' + getContactName(contactId) }}</a>
          </div>
          <span v-else>-</span>
        </template>
        <template
          #cell(status)="data">
          <template v-if="data.item.closed === true">
            <div
              class="color-box mr-2 mt-2 closed" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.closed') }}
          </template>
          <template v-else-if="dateObjectToTimestamp(data.item.startingAt) > (Date.now() / 1000)">
            <div
              class="color-box mr-2 mt-2 future" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.future') }}
          </template>
          <template v-else-if="dateObjectToTimestamp(data.item.endingAt) > 0 && dateObjectToTimestamp(data.item.endingAt) > (Date.now() / 1000) && dateObjectToTimestamp(data.item.startingAt) <= (Date.now() / 1000)">
            <div
              class="color-box mr-2 mt-2 terminated" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.current_terminated') }}
          </template>
          <template v-else-if="dateObjectToTimestamp(data.item.endingAt) > 0 && data.item.status !== 'CONDOMINIUM_STATUS_NOT_ACTIVATED'">
            <div
              class="color-box mr-2 mt-2 terminated" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.terminated') }}
          </template>
          <template v-else-if="(!dateObjectToTimestamp(data.item.endingAt) || dateObjectToTimestamp(data.item.endingAt) <= 0) && data.item.activated === true">
            <div
              class="color-box mr-2 mt-2 current" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.current') }}
          </template>
          <template v-else-if="data.item.activated === false">
            <div
              class="color-box mr-2 mt-2 not_activated" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.not_activated') }}
          </template>
          <template v-else>
            <div
              class="color-box mr-2 mt-2 future" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.future') }}
          </template>
        </template>
        <template #cell(contractNumber)="data">
          <div
            class="color-box mr-2" />
          {{ data.item.numericId }}
        </template>
        <template
          #cell(type)="data">
          <template v-if="getObject(data.item.objectId).category !== 'UNDEFINED_CATEGORY'">
            {{ $t('message.onBoarding.buildings.objects.category.' + getObject(data.item.objectId).category.toLowerCase()) }}
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template
          #cell(floor)="data">
          {{ formatFloor(getObject(data.item.objectId) ? getObject(data.item.objectId).characteristics.floor : '') }}
        </template>
        <template
          #cell(position)="data">
          {{ data.item.object ? getPositionLabelFromArray(getObject(data.item.objectId).object.positions) : '' }}
        </template>
        <template
          #cell(rooms)="data">
          {{ (getObject(data.item.objectId) ? getObject(data.item.objectId).characteristics.rooms : '') | displayOptionalValue }}
        </template>
        <template
          #cell(livingArea)="data">
          {{ getObject(data.item.objectId).characteristics.areas ? getObject(data.item.objectId).characteristics.areas.livingArea : '' | formatArea }}
        </template>
        <template
          #cell(valueQuota)="data">
          {{ formatValueQuota(getObject(data.item.objectId).valueQuota) }}
        </template>
        <template
          #cell(ownershipStarting)="data">
          {{ data.item.startingAt | objectToDate }}
        </template>
        <template
          #cell(ownershipEnding)="data">
          {{ data.item.endingAt | objectToDate }}
        </template>
        <template #cell(assignee)="data">
          <template v-if="isAdminModule">
            {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeAdministration !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeAdministration) : '-' }}
          </template>
          <template v-if="isAccountingModule">
            {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeAccounting !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeAccounting) : '-' }}
          </template>
          <template v-if="isAssetModule">
            {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeAsset !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeAsset) : '-' }}
          </template>
          <template v-if="isOwnerModule">
            {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeOwner !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeOwner) : '-' }}
          </template>
        </template>
        <template #cell(roommate)="data">
          <div v-if="data.item?.owner?.roommateContactIds?.length > 0">
            <a
              v-for="(roommateContactId, roommateIndex) in data.item.owner.roommateContactIds"
              :key="roommateContactId + roommateIndex"
              href=""
              @click="redirectToDetailPage(roommateContactId)">
              {{ roommateIndex === 0 ? getContactName(roommateContactId) : ', ' + getContactName(roommateContactId) }}
            </a>
          </div>
          <span v-else>-</span>
        </template>
        <template #cell(tenant)="data">
          <div v-if="data.item.tenants.length > 0">
            <template
              v-for="(tenant, tenantListIndex) in data.item.tenants">
              <template
                v-if="tenant.contactIds.length > 0">
                <a
                  v-for="(contactId, tenantIndex) in tenant.contactIds"
                  :key="Math.random() + '_' + tenantIndex"
                  @click="redirectToDetailPage(contactId)">
                  {{ tenantIndex === 0 ? getContactName(contactId) : ', ' + getContactName(contactId) }}
                  <span v-if="tenantIndex === tenant.contactIds.length - 1 && tenantListIndex < data.item.tenants.length - 1">,</span>
                </a>
              </template>
            </template>
          </div>
          <template v-else>
            -
          </template>
        </template>
        <template #cell(subCategory)="data">
          <template v-if="getObject(data.item.objectId)?.subCategories.length > 0">
            <template v-for="(subCat, subCatIndex) in getObject(data.item.objectId)?.subCategories">
              <span
                v-if="subCat !== 'UNDEFINED_SUB_CATEGORY'"
                :key="subCatIndex">
                {{ $t(`message.onBoarding.buildings.objects.subCategory.` + subCat?.toLowerCase()) }}
              </span>
              <template v-else>
                -
              </template>
            </template>
          </template>
          <template v-else>
            -
          </template>
        </template>
      </b-table>
      <b-pagination
        v-if="filterCondominiums.length > 0"
        v-model="currentPage"
        align="center"
        class="mt-3 mb-3"
        :total-rows="filterCondominiums.length"
        :per-page="nbrPerPage" />
    </coozzy-card>
    <div
      v-if="selectedCondominium !== null">
      <coozzy-card class="global-card">
        <div class="row">
          <div class="col-8 mt-3">
            <h5>
              <router-link
                :to="getObjectDetailRoute(object)">
                {{ $t('message.onBoarding.buildings.objects.titleObject') + ': ' }} {{ object.name }}
              </router-link>
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <object-condominium
              :ref="'object-condominium'"
              :key="`condominium-${selectedCondominium.id || selectedCondominium.internalId}`"
              :index="1"
              :object-category="object.category"
              :condominium="selectedCondominium"
              :read-only="readOnly" />
          </div>
        </div>
      </coozzy-card>
    </div>
    <b-modal
      :id="'tenancy-confirmationModal'"
      ref="tenancy-confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.confirmation')">
      <div
        v-if="loadingDeletion"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <template
        v-else>
        <div class="col">
          <p>{{ $t('message.onBoarding.deleteModals.bodyTenancy') }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('tenancy-confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteTenancies()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <reminder-creation-modal
      ref="reminder-creation"
      :references-ids="selectedEntriesIds"
      :source="'condominium'"
      @new-reminder="clearCheckbox" />
    <create-activity-modal
      ref="activity-creation"
      :references-ids="selectedEntriesContactIds"
      :ids="selectedEntriesIds"
      @activity-created="clearCheckbox" />
    <activate-condominium-modal
      v-if="selectedEntriesIds.length > 0"
      ref="activateCondominiumModal"
      :selected-entries-ids="selectedEntriesIds"
      :objects="objects"
      :condominiums="filterCondominiums"
      @condo-activated="condominiumActivated" />
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
  </section>
</template>

<script>
import ObjectCondominium from './components/ObjectCondominium'
import { onboarding } from '@/mixins/onboarding'
import ActivateCondominiumModal from '@/addTenancy/ActivateCondominiumModal'
import { formatting } from '@/mixins/formatting'
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import ObjectApi from '../../misc/apis/ObjectApi'
import ContactApi from '../../misc/apis/ContactApi'
import Vue from 'vue'
import { mapMutations } from 'vuex'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import CoozzyDropdownSpaceDivider from '@/framework/components/dropdown/CoozzyDropdownSpaceDivider'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import { tables } from '@/mixins/tables'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon'
import { routeChecks } from '@/mixins/routeChecks'
import { algolia } from '@/mixins/algolia'
import { toastError } from '@/mixins/toastError'
import CoozzyFilterIcon from '@/framework/components/icons/CoozzyFilterIcon'
import { mail } from '@/mixins/mail'
import AssembleListModal from '@/contact/components/AssembleListModal'
import SearchApi from '@/misc/apis/SearchApi'
import AddressText from '@/framework/components/misc/AddressText'
import { v4 as uuidv4 } from 'uuid'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon.vue'

export default {
  name: 'CreateCondominiumTab',
  components: {
    CoozzyFiltersIcon,
    CoozzyFilterIcon,
    CoozzyButton,
    CoozzySpinner,
    CoozzyFormCheckbox,
    CoozzyDropdownSpaceDivider,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyMultiselect,
    CoozzyFormInput,
    CoozzyFormSelect,
    ReminderCreationModal,
    CreateActivityModal,
    CoozzyCard,
    ActivateCondominiumModal,
    ObjectCondominium,
    CoozzyInfoCircleIcon,
    AssembleListModal,
    AddressText
  },
  mixins: [onboarding, formatting, tables, routeChecks, toastError, algolia, mail],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    elementSelected: {
      type: String,
      default: ''
    },
    condominiumFilters: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isSameOwner: false,
      loadingDeletion: false,
      filterCondominiums: [],
      keyActivity: 0,
      nbrPerPage: 5,
      currentPage: 1,
      searchText: '',
      searchCategories: [],
      searchRooms: [],
      searchFloor: [],
      searchStatus: [],
      searchZip: [],
      selectedCondominium: null,
      isBusy: false,
      isLoad: false,
      isLoaded: false,
      selectedEntries: [],
      searchAssignee: [],
      searchPreferredCommunicationChannel: [],
      searchTags: [],
      availableStatus: [
        'closed',
        'not_activated',
        'future',
        'terminated',
        'current'
      ],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
      ],
      dataFields: [
        {
          key: 'condominiumOwner',
          label: this.$t('message.tableColumnHeadings.condominiumOwner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objects',
          label: this.$t('message.tableColumnHeadings.objects'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objectNumber',
          label: this.$t('message.tableColumnHeadings.objectNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'numericId',
          label: this.$t('message.tableColumnHeadings.numericId'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'contractNumber',
          label: this.$t('message.tableColumnHeadings.contractNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'floor',
          label: this.$t('message.tableColumnHeadings.floor'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'rooms',
          label: this.$t('message.tableColumnHeadings.rooms'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'livingArea',
          label: this.$t('message.tableColumnHeadings.livingArea'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'valueQuota',
          label: this.$t('tableColumnHeadings.valueQuota'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'ownershipStarting',
          label: this.$t('message.tableColumnHeadings.ownershipStarting'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'ownershipEnding',
          label: this.$t('message.tableColumnHeadings.ownershipEnding'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'roommate',
          label: this.$t('message.tableColumnHeadings.roommate'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'tenant',
          label: this.$t('message.tableColumnHeadings.tenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'subCategory',
          label: this.$t('message.tableColumnHeadings.subCategory'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        }
      ],
      selectedFields: [],
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: []
    }
  },
  computed: {
    // allContacts() {
    //   console.log('this.objectOwnerContacts', this.objectOwnerContacts)
    //   console.log('this.contacts', this.contacts)
    //   console.log('this.employeeContacts', this.employeeContacts)
    //   return this.objectOwnerContacts.concat(this.contacts).concat(this.employeeContacts)
    // },
    nbrTotalOfHits() {
      return this.filterCondominiums.length
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.id) : this.selectedEntries.map(x => x.id)
    },
    selectedEntriesContactIds() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return [].concat.apply([], listEntries.map(obj => obj.owner.contactIds))
    },
    object() {
      return this.selectedCondominium ? this.getObject(this.selectedCondominium.objectId) : null
    },
    isFiltered() {
      return this.searchText !== '' || this.searchCategories.length > 0 || this.searchStatus.length > 0 || this.searchRooms.length > 0 || this.searchFloor.length > 0 || this.searchZip.length > 0 || this.searchTags.length > 0
    },
    countCondominium() {
      return this.filterCondominiums.length
    },
    condominiumList() {
      if (this.nbrPerPage === 'all') {
        return this.filterCondominiums
      } else {
        return this.filterCondominiums.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
      }
    },
    categoryFilter() {
      const counts = []
      const current = this
      current.filterCondominiums.map(obj => current.getObject(obj.objectId).category).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== 'UNDEFINED_CATEGORY') {
          const sameNetwork = current.filterCondominiums.map(obj => current.getObject(obj.objectId).category).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    notAllStatusNotActivated() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.length > 0 && listEntries.filter(x => x.status !== 'CONDOMINIUM_STATUS_NOT_ACTIVATED').length > 0
    },
    assigneeFilter() {
      const assigneeList = []
      let unassigned = 0
      const counts = []
      const current = this

      const assigneeProps = {
        accounting: 'contactIdAssigneeAccounting',
        administration: 'contactIdAssigneeAdministration',
        asset: 'contactIdAssigneeAsset',
        owner: 'contactIdAssigneeOwner'
      }

      const assigneeProp = current.isAccountingModule ? assigneeProps.accounting : current.isAdminModule ? assigneeProps.administration : current.isAssetModule ? assigneeProps.asset : assigneeProps.owner

      current.filterCondominiums.forEach(obj => {
        const element = current.getObject(obj.objectId)
        if (element && element.contacts && element.contacts[assigneeProp] !== null && element.contacts[assigneeProp] !== '') {
          assigneeList.push(element)
        } else {
          unassigned++
        }
      })

      const assigneeIds = assigneeList.map(x => x.contacts[assigneeProp])
      assigneeIds.forEach(id => {
        const existingCount = counts.filter(e => e.value === id)
        const sameAssignee = current.filterCondominiums.map(obj => current.getObject(obj.objectId)).filter(z => z && z.contacts && z.contacts[assigneeProp] === id)
        if (existingCount.length === 0) {
          counts.push({
            count: sameAssignee.length,
            value: id,
            label: this.getContactName(id),
            active: (this.assigneeContacts.find(x => x.id === id)?.blocked) === undefined ? true : !(this.assigneeContacts.find(x => x.id === id)?.blocked)
          })
        }
      })

      if (unassigned !== 0) {
        counts.push({
          count: unassigned,
          value: '',
          label: this.$t('message.generic.unassigned'),
          active: true
        })
      }

      return counts
    },
    floorFilter() {
      const counts = []
      const current = this
      current.filterCondominiums.map(obj => current.getObject(obj.objectId).characteristics.floor).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== -10000) {
          const sameNetwork = current.filterCondominiums.map(obj => current.getObject(obj.objectId).characteristics.floor).filter(z => z === x)
          if (x !== -10000) {
            if (existingCount.length === 0) {
              counts.push({
                count: sameNetwork.length,
                value: x
              })
            } else {
              counts.push({
                count: 1,
                value: x
              })
            }
          }
        }
      })
      return counts
    },
    roomsFilter() {
      const counts = []
      const current = this
      current.filterCondominiums.map(obj => current.getObject(obj.objectId).characteristics.rooms).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== -1) {
          const sameNetwork = current.filterCondominiums.map(obj => current.getObject(obj.objectId).characteristics.rooms).filter(z => z === x)
          if (x !== -1) {
            if (existingCount.length === 0) {
              counts.push({
                count: sameNetwork.length,
                value: x
              })
            } else {
              counts.push({
                count: 1,
                value: x
              })
            }
          }
        }
      })
      return counts
    },
    isTenanciesActivated() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.filter(x => x.activated).length
    },
    filterPreferredCommunicationChannel() {
      let channelList = []
      const counts = []
      this.filterCondominiums.forEach(element => {
        if (element.owner.contactIds.length > 0 && this.objectOwnerContacts.length > 0) {
          const contactId = element.owner.contactIds[0]
          const channelName = this.objectOwnerContacts.find(x => contactId === x.id)?.preferredCommunicationChannel
          if (channelName !== 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED') {
            channelList.push(channelName)
          }
        }
      })
      channelList = [].concat.apply([], channelList)
      channelList.forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== -1) {
          const sameNetwork = channelList.filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    filterTags() {
      const counts = []
      const current = this
      current.objects.map(obj => obj.tags).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x.length > 0) {
          const sameNetwork = current.objects.map(obj => obj.tags).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    }
  },
  watch: {
    objectOwnerContacts: function (newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.filterCondominiumList()
      }
    },
    currentPage: function () {
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    nbrPerPage: function (newVal) {
      localStorage.setItem('createTenantsTab-nbrPerPage', newVal)
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    searchText: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'condominiumFilters')
      }
    },
    searchCategories: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'condominiumFilters')
      }
    },
    searchRooms: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'condominiumFilters')
      }
    },
    searchStatus: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'condominiumFilters')
      }
    },
    searchFloor: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'condominiumFilters')
      }
    },
    searchZip: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'condominiumFilters')
      }
    },
    searchAssignee: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'condominiumFilters')
      }
    },
    condominiumOwnership: function (newVal) {
      let contactIds = []
      newVal.forEach(element => {
        if (element.owner && element.owner.contactIds.length > 0) {
          contactIds = contactIds.concat(element.owner.contactIds)
        }
        if (element.tenants?.length > 0) {
          element.tenants.forEach((tenant) => {
            contactIds = contactIds.concat(tenant.contactIds)
          })
        }
      })
      this.$nextTick(function () {
        this.availableContact([...new Set(contactIds.filter(x => x !== null && x !== ''))])
      })
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        this.allEntries = this.filterCondominiums
      }
    }
  },
  mounted() {
    this.filterCondominiumList()
    this.loadInstantSearchInternal()
    this.$nextTick(function () {
      const t = this
      const tempTenancies = this.filterCondominiums
      tempTenancies.sort(function (a, b) {
        return new Date(t.$options.filters.objectToDateInput(b.startingAt)).getTime() - new Date(t.$options.filters.objectToDateInput(a.startingAt)).getTime()
      })
      if (this.elementSelected !== null && this.elementSelected !== '' && this.isLoad === false) {
        const indexOfTenancy = tempTenancies.map(function(e) { return e.id }).indexOf(this.elementSelected)
        if (indexOfTenancy !== -1) {
          this.currentPage = (Math.floor((indexOfTenancy) / this.nbrPerPage)) + 1
          tempTenancies[indexOfTenancy]._rowVariant = 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary'
        }
      }
      if (this.elementSelected) {
        const checker = this.condominiumOwnership.find(ten => ten.id === this.elementSelected)
        if (checker !== undefined && checker !== null) {
          this.selectedCondominium = checker
        }
      }
      this.$refs.searchText?.setFocusInput()
    })
    if (this.condominiumFilters !== '') {
      const objFilter = JSON.parse(this.condominiumFilters)
      this.searchZip = objFilter.searchZip
      this.searchFloor = objFilter.searchFloor
      this.searchRooms = objFilter.searchRooms
      this.searchStatus = objFilter.searchStatus
      this.searchCategories = objFilter.searchCategories
      this.searchText = objFilter.searchText
      this.searchAssignee = objFilter.searchAssignee
    }
    this.nbrPerPage = localStorage.getItem('createTenantsTab-nbrPerPage') ? localStorage.getItem('createTenantsTab-nbrPerPage') === 'all' ? localStorage.getItem('createTenantsTab-nbrPerPage') : parseInt(localStorage.getItem('createTenantsTab-nbrPerPage')) : 5
    this.isLoaded = true
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'condo_owners_detail') {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fields.push(...this.dataFields.filter(x => x.selected))
        this.selectedFields = JSON.parse(JSON.stringify(this.dataFields))
      }
    }).catch(e => {
      console.log(e)
    })
  },
  methods: {
    ...mapMutations('onboarding', ['setObjectOwnerContacts']),
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setUseBcc',
      'setSendMailSecondaryTitle',
      'setSendMailContactsWithoutEmail',
      'setShowAllPlaceholders'
    ]),
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.objectsTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'condo_owners_detail').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    formatValueQuota(valueQuota) {
      if (valueQuota === null || valueQuota === '' || valueQuota === undefined || valueQuota === -1 || valueQuota === '-1') {
        return '-'
      } else {
        return valueQuota.toFixed((valueQuota % 1) === 0 ? 0 : 2)
      }
    },
    createTicket(type) {
      const defaultAssignee = this.selectedEntries.every((val, i, arr) => this.getObject(val.objectId) && this.getObject(arr[0].objectId) && this.getObject(arr[0].objectId).contacts.contactIdAssigneeAsset !== '' && this.getObject(val.objectId).contacts.contactIdAssigneeAsset !== '' && this.getObject(val.objectId).contacts.contactIdAssigneeAsset === this.getObject(arr[0].objectId).contacts.contactIdAssigneeAsset)
        ? this.getObject(this.selectedEntries[0].objectId).contacts?.contactIdAssigneeAsset
: this.selectedEntries.every((val, i, arr) => this.getObject(val.objectId) && this.getObject(arr[0].objectId) && this.getObject(arr[0].objectId).contacts.contactIdAssigneeAdministration !== '' && this.getObject(val.objectId).contacts.contactIdAssigneeAdministration !== '' && this.getObject(val.objectId).contacts.contactIdAssigneeAdministration === this.getObject(arr[0].objectId).contacts.contactIdAssigneeAdministration)
          ? this.getObject(this.selectedEntries[0].objectId).contacts?.contactIdAssigneeAdministration
: ''
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      }
    },
    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const condoOwnerIds = this.selectedEntriesIds.join(',')
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { condoOwnerIds: condoOwnerIds, source: 'condoOwner' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { condoOwnerIds: condoOwnerIds, source: 'condoOwner' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { condoOwnerIds: condoOwnerIds, source: 'condoOwner' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { condoOwnerIds: condoOwnerIds, source: 'condoOwner' } }, () => {})
      }
    },
    customLabelStatus(status) {
      return this.$t('message.onBoarding.buildings.objects.tenancies.status.' + status)
    },
    customLabelPreferredCommunicationChannel(item) {
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_EMAIL') {
        return this.$t('message.contact.preferredCommunicationChannelType.email') + ' (' + item.count + ')'
      }
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_LETTER') {
        return this.$t('message.contact.preferredCommunicationChannelType.letter') + ' (' + item.count + ')'
      }
      return item.value
    },
    getObjectDetailRoute(object) {
      if (object !== null) {
        return {
          name: this.isOwnerModule ? 'OwnerBuildingDetailsView' : this.isAdminModule ? 'AdminBuildingDetailsView' : this.isAssetModule ? 'AssetBuildingDetailsView' : 'AccountingBuildingDetailsView',
          params: { id: object.buildingId },
          query: { view: 'objects', elementSelected: object.id }
        }
      }
    },
    allFilters() {
      return JSON.stringify({
        searchText: this.searchText,
        searchStatus: this.searchStatus,
        searchCategories: this.searchCategories,
        searchRooms: this.searchRooms,
        searchFloor: this.searchFloor,
        searchZip: this.searchZip,
        searchAssignee: this.searchAssignee
      })
    },
    initFilter() {
      this.searchText = ''
      this.searchCategories = []
      this.searchStatus = []
      this.searchRooms = []
      this.searchFloor = []
      this.searchZip = []
      this.searchAssignee = []
      this.filterCondominiumList()
    },
    openConfirmationModal() {
      this.$refs['tenancy-confirmationModal'].show()
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    showActivateCondominiumModal() {
      // this.isSameOwner = this.selectedEntries.map(x => x.owner.contactIds[0]).every(
      //   function(value, _, array) {
      //     return array[0] === value
      //   }
      // )
      this.$refs.activateCondominiumModal.show()
    },
    condominiumActivated() {
      this.clearCheckbox()
      setTimeout(() => {
        this.filterCondominiumList()
      }, 2000)
    },
    showActivity() {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.$nextTick(() => {
        this.$refs['activity-creation'].show()
      })
    },
    deleteTenancies() {
      const promises = []
      const current = this
      for (let index = 0; index < this.selectedEntriesIds.length; index++) {
        promises.push(ObjectApi.deleteCondominium(this.selectedEntriesIds[index]))
      }
      this.loadingDeletion = true
      Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            this.loadingDeletion = false
            this.filterCondominiums = this.filterCondominiums.filter(entry => !this.selectedEntriesIds.includes(entry.id))
            for (let index = 0; index < this.selectedEntriesIds.length; index++) {
              this.removeCondominiumInstant(this.selectedEntriesIds[index])
            }
            this.clearCheckbox()
            this.refreshObjects()
            this.selectedCondominium = null
            this.$emit('selected-condominium', null)
            Vue.toasted.show(this.$t('message.successMessages.tenancies'), { type: 'success' })
            current.$refs['tenancy-confirmationModal'].hide()
          }, 2000)
        })
        .catch(e => {
          this.loadingDeletion = false
          current.$refs['tenancy-confirmationModal'].hide()
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.tenancies'), { type: 'error' })
        })
        .then(() => {
        })
    },
    handleCheckboxesInsideTable(value) {
      const allCheckboxesComponents = this.$refs.condominiumTable.$children[1].$children
      this.handleCheckbox(allCheckboxesComponents, value)
      this.selectAllVisibleEntries = value
      if (value === false) {
        this.selectAllEntries = false
      }
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.id !== item.id)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    customLabel(item) {
      return item.value + ' (' + item.count + ')'
    },
    customLabelCategory(item) {
      return this.$t('message.onBoarding.buildings.objects.category.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    searchInputText() {
      this.filterCondominiumList()
    },
    async serialEmail() {
      this.setShowAllPlaceholders(true)
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      await this.prepareSerialEmail(listEntries)
      this.loading = false
    },
    filterCondominiumList() {
      this.$nextTick(function () {
        this.filterCondominiums = this.condominiumOwnership.filter(item => {
          const contactsMaped = item.owner.contactIds.map(x => this.getContactName(x)).toString()
          const cdtSearch = contactsMaped.toLowerCase().includes(this.searchText.toLowerCase())
          const floorMapped = this.searchFloor.map(x => x.value)
          const cdtFloor = floorMapped.includes(this.getObject(item.objectId)?.characteristics.floor) || this.searchFloor.length === 0
          const roomMapped = this.searchRooms.map(x => x.value)
          const cdtRooms = roomMapped.includes(this.getObject(item.objectId)?.characteristics.rooms) || this.searchRooms.length === 0
          const tagsMapped = this.searchTags.map(x => x.value)
          const cdtTags = tagsMapped.includes(this.getObject(item.objectId)?.tags) || this.searchTags.length === 0
          const categoryMapped = this.searchCategories.map(x => x.value)
          const cdtCategory = categoryMapped.includes(this.getObject(item.objectId)?.category) || this.searchCategories.length === 0
          const assigneeMapped = this.searchAssignee.map(x => x.value)
          const cdtAssignee = assigneeMapped.includes(this.getObject(item.objectId)?.contacts.contactIdAssigneeAdministration) || this.searchAssignee.length === 0
          const cdtStatus = (this.searchStatus.includes(this.getTenantStatus(item)) !== false) || this.searchStatus.length === 0
          const currentContact = item.owner.contactIds.length > 0 ? this.objectOwnerContacts.find(x => item.owner.contactIds[0] === x.id) : null
          const currentPreferredChanel = currentContact ? currentContact.preferredCommunicationChannel : 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED'
          const cdtChannel = this.searchPreferredCommunicationChannel.map(x => x.value).includes(currentPreferredChanel) || this.searchPreferredCommunicationChannel.length === 0
          return cdtSearch && cdtCategory && cdtRooms && cdtFloor && cdtStatus && cdtAssignee && cdtChannel && cdtTags
        })
      })
    },
    getTenantStatus(item) {
      if (item.closed === true) {
        return 'closed'
      } else if (this.dateObjectToTimestamp(item.startingAt) > (Date.now() / 1000)) {
        return 'future'
      } else if (this.dateObjectToTimestamp(item.endingAt) > 0 && item.status !== 'CONDOMINIUM_STATUS_NOT_ACTIVATED') {
        return 'terminated'
      } else if ((!this.dateObjectToTimestamp(item.endingAt) || this.dateObjectToTimestamp(item.endingAt) <= 0) && item.activated === true) {
        return 'current'
      } else if (item.activated === false) {
        return 'not_activated'
      } else {
        return 'future'
      }
    },
    changeSelectedCondominium(item) {
      for (let i = 0; i < this.condominiumOwnership.length; i++) {
        if (this.condominiumOwnership[i]._rowVariant === 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary') {
          this.condominiumOwnership[i]._rowVariant = ''
        }
      }
      this.selectedCondominium = item
      this.$emit('selected-condominium', item)
    },
    serialLetter() {
      let nameOfRoute = ''
      const source = 'condominium'
      if (this.isOwnerModule) {
        nameOfRoute = 'OwnerSerialLetterProcessView'
      } else if (this.isAdminModule) {
        nameOfRoute = 'AdminSerialLetterProcessView'
      } else if (this.isAssetModule) {
        nameOfRoute = 'AssetSerialLetterProcessView'
      } else {
        nameOfRoute = 'AccountingSerialLetterProcessView'
      }
      const uniqKey = uuidv4()
      localStorage.setItem(uniqKey, this.selectedEntriesIds)
      this.$nextTick(() => {
        this.$router.push({
          name: nameOfRoute,
          query: {
            source: source,
            uuid: uniqKey,
            previousRoute: this.$route.fullPath
          }
        })
      })
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          this.$router.push({ name: 'OwnerContactDetailsView', params: { id: contactId } })
        } else if (this.isAssetModule) {
          this.$router.push({ name: 'AssetContactDetailsView', params: { id: contactId } })
        } else if (this.isAccountingModule) {
          this.$router.push({ name: 'AccountingContactDetailsView', params: { id: contactId } })
        } else {
          this.$router.push({ name: 'AdminContactDetailsView', params: { id: contactId } })
        }
      }
    },
    getContactName(contactId) {
      const contact = this.objectOwnerContacts.concat(this.contacts).concat(this.employeeContacts).find(function (contact) {
        return contact.id === contactId || contact.objectID === contactId
      })

      return contact ? contact.name : '-'
    },
    isInvalid() {
      let valid = true
      const errorArray = []
      if (this.selectedCondominium !== null) {
        if (this.$refs['object-condominium']) {
          if (this.$refs['object-condominium'].isInvalid()) {
            if (!this.$refs['object-condominium'].isCollapseOpen()) {
              errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.buildings.objects.ownerAgreement.title') + this.$t('message.savingErrors.validationError2'))
            }
            valid = false
          }
        }
      }
      this.showManyToast(errorArray)
      return !valid
    },
    customLabelAssignee(item) {
      if (item.value === '') {
        return `${this.$t('message.generic.unassigned')} (${item.count})`
      }
      const name = this.getContactName(item.value)
      return `${name} (${item.count})`
    },
    dateObjectToTimestamp(item) {
      if (item) {
        const date = new Date()
        date.setFullYear(item.year)
        date.setMonth(item.month - 1)
        date.setDate(item.day)
        return date.getTime() / 1000
      }
      return 0
    },
    availableContact(search) {
      ContactApi.contactResolve(search)
        .then(response => {
          const contacts = response.persons.concat(response.companies)
          const usedContacts = this.objectOwnerContacts.concat(contacts)
          this.setObjectOwnerContacts(usedContacts)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
