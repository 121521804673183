<template>
  <div class="row justify-content-between call-action">
    <div
      :class="from === 'ticketDetails' ? 'col-md-10' : actionText && actionText !== '' ? 'col-md-auto' : 'col-auto'">
      <h1
        class="d-none d-md-block wrap"
        :class="classTitle">
        <slot name="before-title-slot" />
        {{ title }}
      </h1>
      <h2
        class="d-block d-md-none line-height3r"
        :class="classTitle">
        {{ title }} <template v-if="countText">
          ({{ count }})
        </template>
      </h2>
      <h5
        v-if="subTitle !== ''"
        class="d-block d-md-none">
        {{ subTitle }}
      </h5>
    </div>

    <div
      v-if="countText"
      class="d-none d-md-block pl-0"
      :class="hasAfterTitleSlot ? ' col-auto' : 'col-12 col-md'">
      <coozzy-vertical-ruler />
      <span class="font-weight-light text-grey font-xl ml-3 line-height3r">
        {{ count }} {{ countText }}
      </span>
    </div>
    <slot name="after-title" />
    <div
      v-if="clearFilter"
      class="col-12 col-md-2 ml-auto AAA">
      <coozzy-button
        class="w-100 my-2"
        @click="$emit('clear-filter')">
        <coozzy-filter-icon />
        {{ $t('message.generic.clearFilters') }}
      </coozzy-button>
    </div>
    <div
      v-if="actionText && actionText !== ''"
      class="col-12 col-md-2 BBB"
      :class="[actionText === $t('message.ticketOverview.createTicket') ? 'pl-md-1' : '']">
      <coozzy-button
        class="w-100 my-2"
        :design="actionButtonDesign"
        @click="$emit('click')">
        {{ actionText }}
      </coozzy-button>
    </div>
    <div
      v-if="subTitle !== ''"
      class="col-12 d-none d-md-block">
      <h5
        class="">
        {{ subTitle }}
      </h5>
    </div>
  </div>
</template>

<script>
import CoozzyButton from '../components/button/CoozzyButton'
import CoozzyVerticalRuler from '../components/misc/CoozzyVerticalRuler'
import CoozzyFilterIcon from '@/framework/components/icons/CoozzyFilterIcon'

export default {
  name: 'CoozzyPageTitle',
  components: { CoozzyVerticalRuler, CoozzyButton, CoozzyFilterIcon },
  props: {
    actionText: {
      type: String,
      default: '',
      required: false
    },
    actionButtonDesign: {
      type: String,
      default: 'prop-green',
      required: false
    },
    clearFilter: {
      type: Boolean,
      default: false,
      required: false
    },
    count: {
      type: Number,
      default: 0,
      required: false
    },
    countText: {
      type: String,
      default: '',
      required: false
    },
    title: {
      type: String,
      required: true
    },
    classTitle: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: '',
      required: false
    },
    from: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasAfterTitleSlot () {
      return !!this.$slots['after-title'] || !!this.$scopedSlots['after-title']
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrap {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
  }
  h1 {
    color: #333
  }
  @media screen and (max-width: 767px) {
    .call-action { display: flex; flex-flow: column; }
    .BBB { order: 1 }
    .AAA { order: 2 }
  }
  .line-height3r{
    line-height: 3.2rem;
  }
  button.secondary:not(:disabled):not(.disabled):active, button.secondary:focus {
    background-color: #ffffff !important;
    border-color: rgb(0, 68, 95) !important;
    color: rgb(0, 68, 95) !important;
    box-shadow: none;
  }
  button:active {
    box-shadow: none;
  }
</style>
