<template>
  <section class="w-100">
    <h6
      :key="'single-adjustmentData'"
      v-b-toggle="`collapse-tenancy-adjustedcosts-index-${adjustment.internalId}`"
      class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
      <template v-if="untilDate">
        {{ dates.adjustmentsByDate | formatDate }} - {{ untilDate | formatDate }}
      </template>
      <template v-else>
        {{ $t('message.generic.asOf') }} {{ dates.adjustmentsByDate | formatDate }}
      </template>
    </h6>
    <b-collapse
      :id="`collapse-tenancy-adjustedcosts-index-${adjustment.internalId}`"
      :key="'collapse-single-adjustmentData'"
      :visible="adjustment.visibleCollaps || isMostRecent">
      <div
        :key="'adjustmentData'"
        class="row">
        <div
          class="col-12">
          <div class="row details-row-bg-2">
            <div class="col-sm-12 col-md-3">
              <label for="calculationBasis">
                {{ $t('message.onBoarding.buildings.objects.tenancies.calculationBasis.title') }}
              </label>
              <coozzy-form-select
                v-if="!readOnly"
                id="calculationBasis"
                v-model="calculationBasis"
                :disabled="isInactive || disabledField">
                <option value="TENANCY_CALCULATION_BASIS_DEFAULT">
                  {{
                    $t('message.onBoarding.buildings.objects.tenancies.calculationBasis.TENANCY_CALCULATION_BASIS_DEFAULT')
                  }}
                </option>
                <option value="TENANCY_CALCULATION_BASIS_M2_YEAR">
                  {{
                    $t('message.onBoarding.buildings.objects.tenancies.calculationBasis.TENANCY_CALCULATION_BASIS_M2_YEAR')
                  }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="d-block">
                <span v-if="calculationBasis === 'TENANCY_CALCULATION_BASIS_DEFAULT'">
                  {{
                    $t('message.onBoarding.buildings.objects.tenancies.calculationBasis.TENANCY_CALCULATION_BASIS_DEFAULT')
                  }}
                </span>
                <span v-else-if="calculationBasis === 'TENANCY_CALCULATION_BASIS_M2_YEAR'">
                  {{
                    $t('message.onBoarding.buildings.objects.tenancies.calculationBasis.TENANCY_CALCULATION_BASIS_M2_YEAR')
                  }}
                </span>
                <span v-else> - </span>
              </span>
            </div>
          </div>
          <div
            class="row"
            :class="readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="dates.adjustmentsByDate"
                :is-read-only="readOnly"
                :filter="'formatDate'"
                type="date"
                :disabled="isInactive || isFirstAdjustment || (tenancyActivatedAndNotAccountingModule || disabledField) && !canBeEdited && tenancyMode !== 'adding'"
                :state="$v.adjustment.byDate.$dirty && $v.adjustment.byDate.$error ? false : null"
                :name="$t('message.generic.byDate') + (readOnly ? '' : '*')"
                :tooltip-text="$t('message.onBoarding.buildings.objects.tenancies.byDateTooltip')"
                @blur="dateToObject()" />
            </div>
            <div
              v-if="!readOnly && !isFirstAdjustment && !tenancyActivatedAndNotAccountingModule"
              class="col-md-1 mt-2">
              <coozzy-button
                design="alert"
                class="align-self-center mt-3"
                @click="openConfirmationModal()">
                {{ $t('message.generic.delete') }}
              </coozzy-button>
            </div>
          </div>
        </div>
        <div
          class="col-12">
          <div class="row details-row-bg-2">
            <div
              v-if="calculationBasis === 'TENANCY_CALCULATION_BASIS_M2_YEAR'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="coldRentM2PerYear"
                type="number"
                :is-read-only="readOnly"
                :filter="'formatPrice'"
                :min="0"
                placeholder="CHF"
                :disabled="isInactive || tenancyActivatedAndNotAccountingModule || disabledField"
                :is-decimal="true"
                :name="$t('message.onBoarding.buildings.objects.tenancies.m2PerYear')"
                @input="calculateColdRent('m2')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="coldRent"
                type="number"
                :is-read-only="readOnly"
                :filter="'formatPriceDecimal'"
                :min="0"
                placeholder="CHF"
                :disabled="isInactive || tenancyActivatedAndNotAccountingModule || disabledField"
                :is-decimal="true"
                :name="$t('message.onBoarding.buildings.objects.pricing.coldRentPerDueDate') + (readOnly ? '' : '*')"
                :check-valide="$v.adjustment.coldRent.$dirty && $v.adjustment.coldRent.$error ? 'is-invalid' : ''"
                @input="calculateColdRent('standard')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                :value="getHeatingAdditionalCosts"
                type="number"
                :is-read-only="readOnly"
                :disabled="true"
                :filter="'formatPriceDecimal'"
                :min="0"
                :is-decimal="true"
                placeholder="CHF"
                :name="$t('message.onBoarding.buildings.objects.pricing.additionalAndHeatingCosts')"
                @input="adjustmentValueChanged('additionalCosts', $event)" />
            </div>
            <div :class="['col-sm-12', 'col-md-3']">
              <coozzy-form-input-numeric
                :value="getGrossRent"
                type="number"
                :is-read-only="readOnly"
                :disabled="true"
                :filter="'formatPriceDecimal'"
                :min="0"
                placeholder="CHF"
                :is-decimal="true"
                :name="$t('message.onBoarding.buildings.objects.pricing.totalRentPerDueDate')" />
            </div>
          </div>
          <div class="row details-row-bg-1">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="coldRentPerMonth"
                type="number"
                :is-read-only="readOnly"
                :filter="'formatPriceDecimal'"
                :min="0"
                placeholder="CHF"
                :disabled="isInactive || tenancyActivatedAndNotAccountingModule || disabledField"
                :is-decimal="true"
                :name="$t('message.onBoarding.buildings.objects.tenancies.coldRentPerMonth')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                :value="getHeatingAdditionalCostsPerMonth"
                type="number"
                :is-read-only="readOnly"
                :disabled="true"
                :filter="'formatPriceDecimal'"
                :min="0"
                :is-decimal="true"
                placeholder="CHF"
                :name="$t('message.onBoarding.buildings.objects.tenancies.additionalCostsPerMonth')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="grossRentPerMonth"
                type="number"
                :is-read-only="readOnly"
                :filter="'formatPriceDecimal'"
                :min="0"
                placeholder="CHF"
                :disabled="true"
                :is-decimal="true"
                :name="$t('message.onBoarding.buildings.objects.tenancies.grossRentPerMonth')" />
            </div>
          </div>
          <div
            v-if="opted"
            class="row details-row-bg-2">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                :value="taxValue"
                type="number"
                :is-read-only="readOnly"
                :disabled="true"
                :filter="'formatPercent'"
                :limited="true"
                :is-decimal="true"
                :name="$t('message.onBoarding.buildings.objects.pricing.tax')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                :value="taxNetRent"
                type="number"
                :is-read-only="readOnly"
                :disabled="true"
                :filter="'formatPriceDecimal'"
                :min="0"
                placeholder="CHF"
                :is-decimal="true"
                :name="$t('message.onBoarding.buildings.objects.pricing.taxNetRent')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                :value="taxHeatingAdditionalCosts"
                type="number"
                :is-read-only="readOnly"
                :disabled="true"
                :filter="'formatPriceDecimal'"
                :min="0"
                placeholder="CHF"
                :is-decimal="true"
                :name="$t('message.onBoarding.buildings.objects.pricing.taxHeatingAdditionalCosts')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                :value="grossRentInclTax"
                type="number"
                :is-read-only="readOnly"
                :disabled="true"
                :filter="'formatPrice'"
                :min="0"
                placeholder="CHF"
                :name="$t('message.onBoarding.buildings.objects.pricing.grossRentInclTax')" />
            </div>
          </div>
          <label
            v-if="adjustment.advancePaymentValues.length > 0 || adjustment.flatRateValues.length > 0"
            class="mt-3 mb-1">
            {{ $t('message.onBoarding.buildings.objects.tenancies.rentComponents') }}
          </label>
          <div
            v-for="(j, k) in getModulo(adjustment.advancePaymentValues)"
            :key="k">
            <div
              class="row"
              :class="j % 2 || !readOnly ? 'details-row-bg-2' : 'details-row-bg-1'">
              <div
                v-for="(item, i) in advancePaymentValues.slice(j * 4, j * 4 + 4)"
                :key="'advancePaymentValues'+i"
                class="col-sm-12 col-md-3">
                <coozzy-form-input-numeric
                  :value="item.value !== -1 ? item.value : null"
                  type="number"
                  :min="0"
                  :is-read-only="readOnly"
                  :is-decimal="true"
                  :filter="'formatPriceDecimal'"
                  :disabled="isInactive || tenancyActivatedAndNotAccountingModule || disabledField"
                  :name="$t('message.propertiesView.rentComponents.advancePaymentRentComponents.' + item.type) + ' (' +$t('message.propertiesView.rentComponents.advancePaymentRentComponents.advancePayment').charAt(0).toUpperCase() + ')'"
                  @input="advancePaymentValueChanged(item, $event)" />
              </div>
            </div>
          </div>
          <div
            v-for="(y, z) in getModulo(adjustment.flatRateValues)"
            :key="'globalflatRateValues'+z">
            <div
              class="row"
              :class="(y + getModulo(adjustment.advancePaymentValues).length) % 2 || !readOnly ? 'details-row-bg-2' : 'details-row-bg-1'">
              <div
                v-for="(item, i) in adjustment.flatRateValues.slice(y * 4, y * 4 + 4)"
                :key="'flatRateValues'+i"
                class="col-sm-12 col-md-3">
                <coozzy-form-input-numeric
                  :value="item.value !== -1 ? item.value : null"
                  type="number"
                  :min="0"
                  :is-read-only="readOnly"
                  :is-decimal="true"
                  :disabled="isInactive || tenancyActivatedAndNotAccountingModule || disabledField"
                  :filter="'formatPriceDecimal'"
                  :name="$t('message.propertiesView.rentComponents.flatRateRentComponents.' + item.type) + ' (' +$t('message.propertiesView.rentComponents.flatRateRentComponents.flatRate').charAt(0).toUpperCase() + ')'"
                  @input="flatRateValueChanged(item, $event)" />
              </div>
            </div>
          </div>
          <div
            v-for="(y, z) in getModulo(adjustment.additionalValues)"
            :key="'globaladditionalValues'+z">
            <div
              class="row"
              :class="(y + (getModulo(adjustment.advancePaymentValues).length + getModulo(adjustment.flatRateValues).length)) % 2 || !readOnly ? 'details-row-bg-2' : 'details-row-bg-1'">
              <div
                v-for="(item, i) in adjustment.additionalValues.slice(y * 4, y * 4 + 4)"
                :key="'additionalValues'+i"
                class="col-sm-12 col-md-3">
                <template
                  v-if="readOnly && item.value === 1 && item.type === 'ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION'">
                  <label for="ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION">{{
                    $t('message.propertiesView.rentComponents.additionalRentComponents.' + item.type) + ' (' + $t('message.propertiesView.rentComponents.additionalRentComponents.title').charAt(0).toUpperCase() + ')'
                  }}</label>
                  <span>
                    {{ getFormatPriceOfReduction(item.value) }}
                  </span>
                </template>
                <coozzy-form-input-numeric
                  v-else
                  :value="item.value !== -1 ? item.type === 'ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION' ? -Math.abs(item.value) : item.value : null"
                  type="number"
                  max="0"
                  :is-read-only="readOnly"
                  :is-decimal="true"
                  :disabled="isInactive || tenancyActivatedAndNotAccountingModule || disabledField"
                  :filter="'formatPriceDecimal'"
                  :name="$t('message.propertiesView.rentComponents.additionalRentComponents.' + item.type) + ' (' +$t('message.propertiesView.rentComponents.additionalRentComponents.title').charAt(0).toUpperCase() + ')'"
                  @input="additionalValuesChanged(item, $event)" />
              </div>
            </div>
          </div>
          <label class="mt-3 mb-1">
            {{ $t('message.onBoarding.buildings.objects.tenancies.rentBasis') }}
          </label>
          <div
            v-if="tenancy.contract.rentType !== 'RENT_TYPE_INDEX' && tenancy.contract.rentType !== 'RENT_TYPE_TURNOVER'"
            class="row"
            :class="(getModulo(adjustment.additionalValues).length + getModulo(adjustment.advancePaymentValues).length + getModulo(adjustment.flatRateValues).length) % 2 || !readOnly ? 'details-row-bg-2' : 'details-row-bg-1'">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="dates.adjustmentsReferenceInterestRateDate"
                :is-read-only="readOnly"
                :filter="'formatDate'"
                type="date"
                :disabled="isInactive || disabledField"
                :name="$t('message.onBoarding.buildings.objects.tenancies.referenceInterestRate.date')"
                :tooltip-text="$t('message.onBoarding.buildings.objects.tenancies.referenceInterestRate.tooltipDate')"
                @blur="dateToObject()" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="referenceInterestRate"
                type="number"
                :is-read-only="readOnly"
                :filter="'formatPercent'"
                :is-decimal="true"
                :limited="true"
                :is-only-decimal="true"
                :disabled="isInactive || disabledField"
                :check-valide="tenancy.contract.rentType !== 'RENT_TYPE_NOT_VMWG' && tenancy.contract.rentType !== 'RENT_TYPE_INDEX' && tenancy.contract.rentType !== 'RENT_TYPE_TURNOVER' && $v.referenceInterestRate.$dirty && $v.referenceInterestRate.$error ? 'is-invalid' : ''"
                :name="$t('message.onBoarding.buildings.objects.tenancies.referenceInterestRate.rate') + (tenancy.contract.rentType !== 'RENT_TYPE_NOT_VMWG' && !readOnly ? '*' : '')"
                @input="adjustmentValueChanged('referenceInterestRate.rate', $event)" />
            </div>
          </div>
        </div>
        <div
          class="col-12">
          <div
            class="row"
            :class="(getModulo(adjustment.additionalValues).length + getModulo(adjustment.advancePaymentValues).length + getModulo(adjustment.flatRateValues).length) % 2 && readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
            <div class="col-sm-12 col-md-3">
              <label for="baseYear">{{
                $t('message.onBoarding.buildings.objects.tenancies.countryIndex.baseYear') + (tenancy.contract.rentType !== 'RENT_TYPE_NOT_VMWG' && !readOnly ? '*' : '')
              }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="baseYear"
                v-model="countryIndexBaseDate"
                :disabled="isInactive || disabledField"
                :state="tenancy.contract.rentType !== 'RENT_TYPE_NOT_VMWG' && $v.dates.countryIndexBaseDate.$dirty && $v.dates.countryIndexBaseDate.$error ? false : null"
                @change="handleBaseYearChange">
                <option :value="null">
                  -
                </option>
                <option
                  v-for="baseYear of baseYearEnum"
                  :key="baseYear"
                  :value="baseYear">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.countryIndex.baseYearOptions.' + baseYear) }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="d-block">
                {{ getFullYearString(countryIndexBaseDate) }}
              </span>
            </div>
            <div class="col-sm-12 col-md-3">
              <label for="baseYear">{{
                $t('message.onBoarding.buildings.objects.tenancies.countryIndex.date') + (tenancy.contract.rentType !== 'RENT_TYPE_NOT_VMWG' && !readOnly ? '*' : '')
              }}</label>
              <date-picker
                v-if="!readOnly"
                ref="datepicker2"
                v-model="dates.countryIndexDate"
                value-type="format"
                format="MM/YYYY"
                type="month"
                :disabled="isInactive || disabledField"
                :disabled-date="disabledBefore1914"
                :lang="getLang"
                @clear="clearCountryIndexDate"
                @change="handleCountryIndexDateChange">
                <template #input>
                  <b-form-input
                    v-model="adjustmentsCountryIndexDate"
                    :class="'form-control form-control-sm'"
                    :state="$v.dates && $v.dates.countryIndexDate && $v.dates.countryIndexDate.$dirty && $v.dates.countryIndexDate.$error ? false : null"
                    type="text"
                    size="sm"
                    :disabled="isInactive || disabledField"
                    :placeholder="getLang === 'de' ? 'mm/jjjj' : 'mm/yyyy'"
                    @keydown="checkInputValue('countryIndexDate', $event)"
                    @input="mountEventListenersCountryIndex" />
                </template>
              </date-picker>
              <span
                v-else
                class="d-block">
                {{ objectToDateString(countryIndexDate) }}
              </span>
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="countryIndexInflation"
                type="number"
                :is-read-only="readOnly"
                :min="0"
                :filter="'formatNumber'"
                :is-decimal="true"
                :disabled="true"
                :check-valide="(tenancy.contract.rentType !== 'RENT_TYPE_NOT_VMWG' || dates.countryIndexDate) && ($v.countryIndexInflation && $v.countryIndexInflation.$dirty && $v.countryIndexInflation.$error) ? 'is-invalid' : ''"
                :name="$t('message.onBoarding.buildings.objects.tenancies.countryIndex.inflation') + (tenancy.contract.rentType !== 'RENT_TYPE_NOT_VMWG' && !readOnly ? '*' : '')" />
            </div>
          </div>
          <div
            class="row"
            :class="(getModulo(adjustment.advancePaymentValues).length + getModulo(adjustment.flatRateValues).length) % 2 || !readOnly ? 'details-row-bg-2' : 'details-row-bg-1'">
            <div
              v-if="tenancy.contract.rentType !== 'RENT_TYPE_INDEX' && tenancy.contract.rentType !== 'RENT_TYPE_TURNOVER'"
              class="col-sm-12 col-md-3">
              <label for="costIncreaseType">
                {{
                  $t('message.onBoarding.buildings.objects.tenancies.costIncreaseType.title') + (readOnly ? '' : '*')
                }}
              </label>
              <coozzy-form-select
                v-if="!readOnly"
                id="costIncreaseType"
                v-model="costIncreaseType"
                :disabled="isInactive || disabledField"
                @change="costIncreaseTypeChanged">
                <option value="COST_INCREASE_TYPE_FLAT_RATE">
                  {{
                    $t('message.onBoarding.buildings.objects.tenancies.costIncreaseType.COST_INCREASE_TYPE_FLAT_RATE')
                  }}
                </option>
                <option value="COST_INCREASE_TYPE_INDEX">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.costIncreaseType.COST_INCREASE_TYPE_INDEX') }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="d-block">
                <span v-if="costIncreaseType === 'COST_INCREASE_TYPE_FLAT_RATE'">
                  {{
                    $t('message.onBoarding.buildings.objects.tenancies.costIncreaseType.COST_INCREASE_TYPE_FLAT_RATE')
                  }}
                </span>
                <span v-else-if="costIncreaseType === 'COST_INCREASE_TYPE_INDEX'">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.costIncreaseType.COST_INCREASE_TYPE_INDEX') }}
                </span>
                <span v-else> - </span>
              </span>
            </div>
            <div
              v-if="tenancy.contract.rentType !== 'RENT_TYPE_INDEX' && tenancy.contract.rentType !== 'RENT_TYPE_TURNOVER'"
              class="col-sm-12 col-md-3">
              <label>{{
                $t('message.onBoarding.buildings.objects.tenancies.costIncreaseDate') + (tenancy.contract.rentType !== 'RENT_TYPE_NOT_VMWG' && !readOnly ? '*' : '')
              }}</label>
              <date-picker
                v-if="!readOnly"
                ref="datepicker3"
                v-model="dates.costIncreaseDate"
                value-type="format"
                format="MM/YYYY"
                type="month"
                :disabled="isInactive || disabledField"
                :disabled-date="disabledBefore1914"
                :lang="getLang"
                @change="handleCostIncreaseDateChange"
                @clear="clearCostIncreaseDate">
                <template #input>
                  <b-form-input
                    :id="'parentInput'"
                    ref="parentInput"
                    v-model="adjustmentsCostIncreaseDate"
                    :class="'form-control form-control-sm'"
                    :state="$v.dates && $v.dates.costIncreaseDate && $v.dates.costIncreaseDate.$dirty && $v.dates.costIncreaseDate.$error ? false : null"
                    type="text"
                    size="sm"
                    :placeholder="getLang === 'de' ? 'mm/jjjj' : 'mm/yyyy'"
                    :disabled="isInactive || disabledField"
                    @keydown="checkInputValue('costIncreaseDate', $event)"
                    @input="mountEventListenersCostIncreaseDate"
                    @blur="handleCostIncreaseDateChange()" />
                </template>
              </date-picker>
              <span v-else>
                {{ objectToDateString(costIncreaseDate) }}
              </span>
            </div>
            <div
              v-if="tenancy.contract.rentType !== 'RENT_TYPE_INDEX' && tenancy.contract.rentType !== 'RENT_TYPE_TURNOVER'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="costIncreaseValue"
                :is-read-only="readOnly"
                type="number"
                :min="0"
                :disabled="isInactive || disabledField"
                :filter="'formatNumber'"
                :is-decimal="true"
                :check-valide="tenancy.contract.rentType !== 'RENT_TYPE_NOT_VMWG' && tenancy.contract.rentType !== 'RENT_TYPE_INDEX' && tenancy.contract.rentType !== 'RENT_TYPE_TURNOVER' && $v.costIncreaseValue.$dirty && $v.costIncreaseValue.$error ? 'is-invalid' : ''"
                :name="$t('message.onBoarding.buildings.objects.tenancies.costIncreaseValue') + (tenancy.contract.rentType !== 'RENT_TYPE_NOT_VMWG' && !readOnly ? '*' : '')" />
            </div>
          </div>
        </div>
        <div
          class="col-12">
          <div
            class="row"
            :class="(getModulo(adjustment.additionalValues).length + getModulo(advancePaymentValues).length + getModulo(flatRateValues).length) % 2 && readOnly ? 'details-row-bg-1' : 'details-row-bg-2'">
            <div
              v-if="readOnly"
              class="col-sm-12 col-md-3">
              <label for="dueDates">
                {{ $t('message.onBoarding.buildings.objects.tenancies.dueDates') }}
              </label>
              <p
                v-if="dueDates.length > 0"
                class="mb-0 multi-select-value">
                {{ getDueDateMonths }}
              </p>
              <p
                v-else
                class="mb-0 multi-select-value">
                -
              </p>
            </div>
            <div
              v-else
              class="col-sm-12 col-md-3">
              <coozzy-multiselect
                id="dueDates"
                v-model="dueDates"
                :options="translatedMonths"
                :disabled="isInactive || tenancyActivatedAndNotAccountingModule || disabledField"
                :check-valide="$v.dueDates.$dirty && $v.dueDates.$error ? 'is-invalid' : ''"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                :taggable="false"
                label="label"
                track-by="label">
                {{ $t('message.onBoarding.buildings.objects.tenancies.dueDates') }} {{ (readOnly ? '' : '*') }}
              </coozzy-multiselect>
            </div>
            <div
              v-if="tenancy.contract.rentType === 'RENT_TYPE_VMWG' || tenancy.contract.rentType === 'RENT_TYPE_NOT_VMWG'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="dates.firstRentAdjustmentPossibleAt"
                :is-read-only="readOnly"
                :filter="'formatDate'"
                type="date"
                :disabled="isInactive"
                :name="$t('message.onBoarding.buildings.objects.tenancies.indexData.firstRentAdjustmentPossibleOn')"
                @blur="dateToObject()" />
            </div>
            <div class="col-sm-12 col-md-2">
              <label for="monthlyRentDueBy">
                {{ $t('message.onBoarding.buildings.objects.tenancies.monthlyRentDueBy') }}
              </label>
              <coozzy-form-select
                v-if="!readOnly"
                id="monthlyRentDueBy"
                v-model="monthlyRentDueBy"
                :disabled="isInactive || tenancyActivatedAndNotAccountingModule || disabledField">
                <option
                  v-for="i in 31"
                  :key="i"
                  :value="i">
                  {{ i }}.
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="d-block">
                <template v-if="monthlyRentDueBy && monthlyRentDueBy !== -1">{{ monthlyRentDueBy }}.</template>
                <template v-else> - </template>
              </span>
            </div>
            <div
              v-if="!readOnly"
              class="col-sm-12 col-md-1">
              <coozzy-form-checkbox
                :key="arrearsOfFirstAdjustment"
                v-model="inArrears"
                :initial="inArrears"
                :disabled="isInactive || tenancyActivatedAndNotAccountingModule || disabledField || arrearsOfFirstAdjustment"
                name="inArrears"
                class="mt-4">
                {{ $t('message.onBoarding.buildings.objects.tenancies.inArrears') }}
              </coozzy-form-checkbox>
            </div>
            <div
              v-else
              class="col-sm-12 col-md-1">
              <label>{{ $t('message.onBoarding.buildings.objects.tenancies.inArrears') }}</label>
              <span class="d-block">
                {{ inArrears ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <template>
        <!--   Rent basis possible   -->
        <div
          class="row">
          <div class="col-12">
            <hr class="mt-0">
          </div>
          <div class="col-12">
            <h6
              v-b-toggle="`collapse-tenancy-${index}-rentBasis`"
              class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
              {{ $t('message.onBoarding.buildings.objects.tenancies.rentBasisPossible.title') }}
            </h6>
          </div>
        </div>
        <b-collapse
          :id="`collapse-tenancy-${index}-rentBasis`"
          :visible="openedCollapses.includes(`collapse-tenancy-${index}-rentBasis`)">
          <div
            v-if="tenancy.contract.rentType !== 'RENT_TYPE_INDEX' && tenancy.contract.rentType !== 'RENT_TYPE_TURNOVER'"
            class="row">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="referenceInterestRatePossible"
                type="number"
                :is-read-only="readOnly"
                :filter="'formatPercent'"
                :is-decimal="true"
                :limited="true"
                :is-only-decimal="true"
                :disabled="isInactive || disabledField"
                :name="$tc('message.onBoarding.buildings.objects.tenancies.referenceInterestRate.ratePossible')"
                @input="adjustmentValueChanged('referenceInterestRate.ratePossible', $event)" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="referenceInterestRateReservationPercentage"
                :is-read-only="readOnly"
                type="number"
                :disabled="true"
                :filter="'formatNumber'"
                :is-decimal="true"
                :name="$tc('message.onBoarding.buildings.objects.tenancies.rentBasisPossible.referenceInterestRateReservationPercentage')" />
            </div>
            <div
              class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="referenceInterestRateReservationValue"
                :is-read-only="readOnly"
                type="number"
                :disabled="true"
                :filter="'formatNumber'"
                :is-decimal="true"
                :name="$tc('message.onBoarding.buildings.objects.tenancies.rentBasisPossible.valueInCHF')" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-3">
              <label for="countryIndexDatePossible">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentBasisPossible.countryIndexDatePossible') }}</label>
              <date-picker
                v-if="!readOnly"
                id="countryIndexDatePossible"
                ref="countryIndexDatePossible"
                v-model="dates.countryIndexPossibleDate"
                value-type="format"
                format="MM/YYYY"
                type="month"
                :disabled="isInactive || disabledField"
                :disabled-date="disabledBefore1914"
                :lang="getLang"
                @clear="clearCountryIndexPossibleDate"
                @pick="datePicked('countryIndexDatePossible')"
                @change="handleCountryIndexPossibleDateChange">
                <template #input>
                  <b-form-input
                    v-model="adjustmentsCountryIndexPossibleDate"
                    :class="'form-control form-control-sm'"
                    :state="$v.dates && $v.dates.countryIndexPossibleDate && $v.dates.countryIndexPossibleDate.$dirty && $v.dates.countryIndexPossibleDate.$error ? false : null"
                    type="text"
                    size="sm"
                    :disabled="isInactive || disabledField"
                    :placeholder="getLang === 'de' ? 'mm/jjjj' : 'mm/yyyy'"
                    @keydown="checkInputValue('countryIndexPossibleDate', $event)"
                    @input="mountEventListenersCountryIndexPossibleDate($event)" />
                </template>
              </date-picker>
              <span
                v-else
                class="d-block">
                {{ objectToDateString(countryIndexPossibleDate) }}
              </span>
            </div>
            <div
              class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="countryIndexInflationPossible"
                :is-read-only="readOnly"
                type="number"
                :min="0"
                :disabled="true"
                :filter="'formatNumber'"
                :is-decimal="true"
                :name="$t('message.onBoarding.buildings.objects.tenancies.rentBasisPossible.countryIndexInflationPossible')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="countryIndexInflationReservationPercentage"
                :is-read-only="readOnly"
                type="number"
                :disabled="true"
                :filter="'formatNumber'"
                :is-decimal="true"
                :name="$tc('message.onBoarding.buildings.objects.tenancies.rentBasisPossible.countryIndexInflationReservationPercentage')" />
            </div>
            <div
              class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="countryIndexInflationReservationValue"
                :is-read-only="readOnly"
                type="number"
                :disabled="true"
                :filter="'formatNumber'"
                :is-decimal="true"
                :name="$tc('message.onBoarding.buildings.objects.tenancies.rentBasisPossible.valueInCHF')" />
            </div>
          </div>
          <div
            v-if="tenancy.contract.rentType !== 'RENT_TYPE_INDEX' && tenancy.contract.rentType !== 'RENT_TYPE_TURNOVER'"
            class="row">
            <div class="col-sm-12 col-md-3">
              <label for="costIncreasePossibleDate">
                {{ $t('message.onBoarding.buildings.objects.tenancies.rentBasisPossible.costIncreasePossibleDate') }}</label>
              <date-picker
                v-if="!readOnly"
                id="costIncreasePossibleDate"
                ref="costIncreasePossibleDate"
                v-model="dates.costIncreasePossibleDate"
                value-type="format"
                format="MM/YYYY"
                type="month"
                :disabled="isInactive || disabledField"
                :disabled-date="disabledBefore1914"
                :lang="getLang"
                @pick="datePicked('costIncreasePossibleDate')"
                @blur="handleCostIncreasePossibleDateChange"
                @clear="clearCostIncreasePossibleDate"
                @change="handleCostIncreasePossibleDateChange">
                <template #input>
                  <b-form-input
                    v-model="adjustmentsCostIncreasePossibleDate"
                    :class="'form-control form-control-sm'"
                    :state="$v.dates && $v.dates.costIncreasePossibleDate && $v.dates.costIncreasePossibleDate.$dirty && $v.dates.costIncreasePossibleDate.$error ? false : null"
                    type="text"
                    size="sm"
                    :disabled="isInactive || disabledField"
                    :placeholder="getLang === 'de' ? 'mm/jjjj' : 'mm/yyyy'"
                    @keydown="checkInputValue('costIncreasePossibleDate', $event)"
                    @input="mountEventListenersCostIncreasePossibleDate($event)"
                    @blur="handleCostIncreasePossibleDateChange()" />
                </template>
              </date-picker>
              <span
                v-else
                class="d-block">
                {{ objectToDateString(costIncreasePossibleDate) }}
              </span>
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="costIncreaseReservationPercentage"
                :is-read-only="readOnly"
                type="number"
                :disabled="true"
                :filter="'formatNumber'"
                :is-decimal="true"
                :name="$tc('message.onBoarding.buildings.objects.tenancies.rentBasisPossible.costIncreaseReservationPercentage')" />
            </div>
            <div
              class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="costIncreaseReservationValue"
                :is-read-only="readOnly"
                type="number"
                :disabled="true"
                :filter="'formatNumber'"
                :is-decimal="true"
                :name="$tc('message.onBoarding.buildings.objects.tenancies.rentBasisPossible.valueInCHF')" />
            </div>
          </div>
        </b-collapse>
      </template>
      <template>
        <h6
          v-if="adjustment && adjustment.retentionOfRents && ((readOnly && adjustment.retentionOfRents.length > 0) || !readOnly)"
          :key="'retentionOfRent'"
          v-b-toggle="`collapse-retentionOfRent-index-${adjustment.internalId}`"
          class="cursor-pointer hover font-weight-bold mt-3 width-only-content">
          {{ $t('message.onBoarding.buildings.objects.tenancies.retentionOfRent.title') }}
        </h6>
        <b-collapse
          v-if="adjustment && adjustment.retentionOfRents && ((readOnly && adjustment.retentionOfRents.length > 0) || !readOnly)"
          :id="`collapse-retentionOfRent-index-${adjustment.internalId}`"
          :key="'collapse-retentionOfRent'">
          <div
            :key="'retentionOfRent'"
            class="row">
            <template v-for="(retention, x) in adjustment.retentionOfRents">
              <adjustment-retention
                :ref="`adjustment-retention-${retention.id}`"
                :key="x"
                :index="x"
                :retention="retention"
                :tenancy="tenancy"
                :adjustment="adjustment"
                :read-only="readOnly"
                :disabled-field="disabledField"
                @change-retention="changeRetention"
                @delete-retention="deleteRetention(index)" />
            </template>
            <div
              v-if="!readOnly && adjustment.retentionOfRents.length < 3 && !disabledField && !isInactive "
              class="col-2 mt-1 pr-0">
              <coozzy-button
                class="w-100"
                design="prop-green"
                @click="addRetention">
                {{ $t('message.onBoarding.buildings.objects.tenancies.retentionOfRent.add') }}
              </coozzy-button>
            </div>
          </div>
        </b-collapse>
      </template>
    </b-collapse>
    <b-modal
      :id="'cost-adjustments-confirmationModal'"
      ref="cost-adjustments-confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.titleCostAdjusment')">
      <template>
        <div class="col">
          <p>{{ $t('message.onBoarding.deleteModals.bodyCostAdjusment') }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('cost-adjustments-confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteAdjustmentClicked">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>

import CoozzyFormInput from '../../../framework/components/form/input/CoozzyFormInput'
import CoozzyFormInputNumeric from '../../../framework/components/form/input/CoozzyFormInputNumeric'
import CoozzyButton from '../../../framework/components/button/CoozzyButton'
import { onboarding } from '@/mixins/onboarding'
import { validation } from '@/mixins/validation'
import { minLength, minValue, required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import AdjustmentRetention from './AdjustmentRetention.vue'
import { routeChecks } from '@/mixins/routeChecks'
import { tables } from '@/mixins/tables'
import DatePicker from 'vue2-datepicker'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { referenceInterestRate } from '@/mixins/referenceInterestRate'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { dateUtils } from '@/mixins/dateUtils'
import { countryIndex } from '@/mixins/countryIndex'

function moreThanStarting(value) {
  if (!value) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(this.tenancy.startingAt))
}
function moreThanDebitUntil(value) {
  if (!value) {
    return true
  }
  if (!this.object.debitUntil) {
    return true
  }
  if (!this.startingAtChanged) {
    return true
  }
  if (this.byDateInit && new Date(this.$options.filters.objectToDateInput(this.byDateInit)).getTime() === new Date(this.$options.filters.objectToDateInput(this.adjustment.byDate)).getTime()) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) >= new Date(this.$options.filters.objectToDateInput(this.object.debitUntil))
}

function notEqualToOtherAdjBydate(value) {
  let flag = true
  this.tenancy.rent.adjustments.filter(adj => adj.internalId !== this.adjustment.internalId).forEach(adj => {
    const isEqual = new Date(this.$options.filters.objectToDateInput(value)).getTime() === new Date(this.$options.filters.objectToDateInput(adj.byDate)).getTime()
    if (isEqual) {
      flag = false
    }
  })
  return flag
}

function validDate(value) {
  if (value) {
    const reg = /[\d]{2}\/[\d]{4}/
    return reg.test(value)
  }
  return true
}
function moreThan1914(value) {
  if (!value) {
    return true
  }
  return new Date('01/' + value).getTime() / 1000 >= new Date('01/01/1914').getTime() / 1000
}
function validReferenceInterestRate(value) {
  return value < 100 && value > -100
}
function beforeEndOfContract(value) {
  if (!value || !this.tenancy.endingAt) {
    return true
  }
  if (this.tenancy.activated && this.tenancy.endingAt) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)) <= new Date(this.$options.filters.objectToDateInput(this.tenancy.endingAt))
}
function beforeCountryIndexDate(value) {
  if (!value || !this.dates.countryIndexDate) {
    return true
  }
  const countryIndexDateArray = this.dates.countryIndexDate.split('/')
  const countryIndexDateFormatted = new Date(countryIndexDateArray[0] + '/01/' + countryIndexDateArray[1])

  const valueArray = value.split('/')
  const valueFormatted = new Date(valueArray[0] + '/01/' + valueArray[1])

  return valueFormatted.getTime() >= countryIndexDateFormatted.getTime()
}
function beforeCostIncreaseDate(value) {
  if (!value || !this.dates.costIncreaseDate) {
    return true
  }
  const costIncreaseDateArray = this.dates.costIncreaseDate.split('/')
  const costIncreaseDateFormatted = new Date(costIncreaseDateArray[0] + '/01/' + costIncreaseDateArray[1])

  const valueArray = value.split('/')
  const valueFormatted = new Date(valueArray[0] + '/01/' + valueArray[1])

  return valueFormatted.getTime() >= costIncreaseDateFormatted.getTime()
}

export default {
  name: 'TenancyAdjustment',
  components: {
    CoozzyFormCheckbox,
    CoozzyMultiselect,
    CoozzyFormSelect,
    CoozzyButton,
    CoozzyFormInput,
    CoozzyFormInputNumeric,
    AdjustmentRetention,
    DatePicker
  },
  mixins: [routeChecks, onboarding, validation, tables, referenceInterestRate, countryIndex, dateUtils],
  props: {
    index: {
      type: Number,
      required: true
    },
    adjustment: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    opted: {
      type: Boolean,
      default: false
    },
    tenancy: {
      type: Object,
      default: null
    },
    untilDate: {
      type: String,
      default: ''
    },
    activeAndEditable: {
      type: Boolean,
      default: false
    },
    object: {
      type: Object,
      default: null
    },
    tenancyMode: {
      type: String,
      default: 'full',
      validator: function (value) {
        return ['full', 'adding', 'activate'].indexOf(value) !== -1
      }
    },
    isMostRecent: {
      type: Boolean,
      default: false
    },
    disabledField: {
      type: Boolean,
      default: false
    },
    requestId: {
      type: String,
      default: ''
    },
    fromMassRentalProcess: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      adjChanged: false,
      dates: {
        adjustmentsByDate: null,
        countryIndexBaseDate: null,
        countryIndexDate: null,
        adjustmentsReferenceInterestRateDate: null,
        costIncreaseDate: null,
        countryIndexPossibleDate: null,
        costIncreasePossibleDate: null,
        firstRentAdjustmentPossibleAt: null
      },
      isNew: false,
      byDateInit: !this.adjustment.newItem ? this.adjustment.byDate : null,
      startingAtChanged: false,
      adjIndexToDelete: null,
      tenancyActivatedAndNotAccountingModule: false,
      baseYearEnum: ['COUNTRY_INDEX_BASE_YEAR_1914', 'COUNTRY_INDEX_BASE_YEAR_1939', 'COUNTRY_INDEX_BASE_YEAR_1966', 'COUNTRY_INDEX_BASE_YEAR_1977', 'COUNTRY_INDEX_BASE_YEAR_1982', 'COUNTRY_INDEX_BASE_YEAR_1993', 'COUNTRY_INDEX_BASE_YEAR_2000', 'COUNTRY_INDEX_BASE_YEAR_2005', 'COUNTRY_INDEX_BASE_YEAR_2010', 'COUNTRY_INDEX_BASE_YEAR_2015', 'COUNTRY_INDEX_BASE_YEAR_2020'].reverse(),
      availableTerminationMonths: ['all', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      taxValue: 0,
      taxNetRent: 0,
      taxHeatingAdditionalCosts: 0,
      grossRentInclTax: 0,
      referenceInterestRatePossibleModified: false,
      costIncreaseDatePossibleModified: false,
      countryIndexPossibleDateModified: false,
      costIncreaseInflation: 0,
      costIncreaseInflationPossible: 0
    }
  },
  computed: {
    firstRentAdjustmentPossibleAt: {
      get() {
        return this.tenancy.rent.firstRentAdjustmentPossibleAt
      },
      set(value) {
        const newTenancy = this.tenancy
        newTenancy.rent.firstRentAdjustmentPossibleAt = value
        this.dispatchTenancyUpdate(newTenancy)
      }
    },
    isFirstAdjustment() {
      return (this.tenancy.startingAt && this.byDateInit && new Date(this.$options.filters.objectToDateInput(this.byDateInit)).getTime() / 1000 === new Date(this.$options.filters.objectToDateInput(this.tenancy.startingAt)).getTime() / 1000) || this.tenancy.rent.adjustments.length <= 1 || (Math.min(...this.tenancy.rent.adjustments.map(item => item.internalId)) === this.adjustment.internalId && ('newItem' in this.adjustment))
    },
    canBeEdited() {
      return new Date(this.$options.filters.objectToDateInput(this.byDateInit)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(this.object.debitUntil)).getTime() / 1000
    },
    isInactive() {
      return this.object?.inactiveBy && new Date(this.$options.filters.objectToDateInput(this.object.inactiveBy)) <= new Date()
    },
    arrearsOfFirstAdjustment() {
      return !this.isFirstAdjustment && this.tenancy.rent.adjustments.find(x => new Date(this.$options.filters.objectToDateInput(this.tenancy.startingAt)).getTime() / 1000 === new Date(this.$options.filters.objectToDateInput(x.byDate)).getTime() / 1000)?.inArrears
    },
    coldRent: {
      get() {
        return this.adjustment.coldRent !== -1 ? this.adjustment.coldRent : null
      },
      set(value) {
        if (value !== null && value >= 0) {
          if (value.toString().split('.').length > 1 && value.toString().split('.')[1].length > 2) {
            value = parseFloat(value.toFixed(2))
          }
          const newAdjustment = this.adjustment
          newAdjustment.coldRent = value
          this.dispatchAdjustmentUpdate(newAdjustment, value)
        }
      }
    },
    coldRentM2PerYear: {
      get() {
        return this.adjustment.coldRentM2PerYear !== -1 ? this.adjustment.coldRentM2PerYear : null
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.coldRentM2PerYear = value
        if (this.calculationBasis === 'TENANCY_CALCULATION_BASIS_M2_YEAR') {
          this.dispatchAdjustmentUpdate(newAdjustment, value)
        }
      }
    },
    coldRentPerMonth: {
      get() {
        return this.adjustment.coldRentPerMonth !== -1 ? this.adjustment.coldRentPerMonth : null
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.coldRentPerMonth = value
        if (this.adjChanged) {
          this.dispatchAdjustmentUpdate(newAdjustment, value)
        }
      }
    },
    additionalCostsPerMonth: {
      get() {
        return this.adjustment.additionalCostsPerMonth !== -1 ? this.adjustment.additionalCostsPerMonth : null
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.additionalCostsPerMonth = value
        if (this.adjChanged) {
          this.dispatchAdjustmentUpdate(newAdjustment, value)
        }
      }
    },
    grossRentPerMonth: {
      get() {
        return (this.getGrossRent / 12) * this.dueDates.length
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.grossRentPerMonth = value
        if (this.adjChanged) {
          this.dispatchAdjustmentUpdate(newAdjustment, value)
        }
      }
    },
    getHeatingAdditionalCostsPerMonth() {
      return (this.getHeatingAdditionalCosts / 12) * this.dueDates.length
    },
    getHeatingAdditionalCosts() {
      let result = 0
      this.adjustment.advancePaymentValues.forEach(element => {
        if (element.value > 0) {
          result += element.value
        }
      })
      this.adjustment.flatRateValues.forEach(element => {
        if (element.value > 0) {
          result += element.value
        }
      })
      return result
    },
    getAdditionalCosts() {
      let result = 0
      this.adjustment.advancePaymentValues.forEach(element => {
        if (element.value > 0) {
          result += element.value
        }
      })
      this.adjustment.flatRateValues.forEach(element => {
        if (element.value > 0) {
          result += element.value
        }
      })
      this.adjustment.additionalValues.forEach(element => {
        if (element.type !== 'ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION' && element.value > 0) {
          result += element.value
        }
      })
      return result
    },
    getGrossRent() {
      let result = 0
      this.adjustment.additionalValues.forEach(element => {
        if (element.value > 0 && element.type === 'ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION') {
          result -= element.value
        }
      })
      return (this.adjustment.coldRent !== -1 ? this.adjustment.coldRent : 0) + this.getAdditionalCosts + result
    },
    countryIndexBaseDate: {
      get() {
        if (this.adjustment.countryIndex.baseDate && this.adjustment.countryIndex.baseDate !== '-1' && this.adjustment.countryIndex.baseDate !== '0') {
          return this.baseYearEnum.find(x => x.includes(this.adjustment.countryIndex.baseDate))
        } else {
          return null
        }
      },
      set(value) {
        this.dates.countryIndexBaseDate = value
        const newAdjustment = this.adjustment
        newAdjustment.countryIndex.baseDate = value
        this.dispatchAdjustmentUpdate(newAdjustment, value)
      }
    },
    countryIndexDate: {
      get() {
        return this.adjustment.countryIndex.date || null
      },
      set(value) {
        if (!this.disabledBefore1914(value)) {
          const newAdjustment = this.adjustment
          newAdjustment.countryIndex.date = value
          this.dispatchAdjustmentUpdate(newAdjustment, value)
        }
      }
    },
    countryIndexInflation: {
      get() {
        return this.adjustment.countryIndex.inflation !== -1 ? this.adjustment.countryIndex.inflation : null
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.countryIndex.inflation = value
        this.dispatchAdjustmentUpdate(newAdjustment, value)
      }
    },
    advancePaymentValues() {
      if (this.adjustment.advancePaymentValues) {
        return this.adjustment.advancePaymentValues
      }
      return []
    },
    // flatRateValues() {
    //   if (this.adjustment.flatRateValues) {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     return this.adjustment.flatRateValues.sort(this.customFlatSort)
    //   }
    //   return []
    // },
    additionalValues() {
      if (this.adjustment.additionalValues) {
        return this.adjustment.additionalValues
      }
      return []
    },
    referenceInterestRate: {
      get() {
        return this.adjustment.referenceInterestRate.rate !== -10000 ? this.adjustment.referenceInterestRate.rate : null
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.referenceInterestRate.rate = value
        this.dispatchAdjustmentUpdate(newAdjustment, value)
        if (!this.referenceInterestRatePossibleModified) {
          this.referenceInterestRatePossible = value
        }
      }
    },
    referenceInterestRatePossible: {
      get() {
        return this.adjustment.referenceInterestRate.ratePossible !== -10000 ? this.adjustment.referenceInterestRate.ratePossible : null
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.referenceInterestRate.ratePossible = value
        this.dispatchAdjustmentUpdate(newAdjustment, value)
      }
    },
    countryIndexPossibleDate: {
      get() {
        return this.adjustment.countryIndex.possibleDate ? this.adjustment.countryIndex.possibleDate : null
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.countryIndex.possibleDate = value
        this.dispatchAdjustmentUpdate(newAdjustment, value)
      }
    },
    costIncreasePossibleDate: {
      get() {
        return this.adjustment.costIncreasePossibleDate ? this.adjustment.costIncreasePossibleDate : null
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.costIncreasePossibleDate = value
        this.dispatchAdjustmentUpdate(newAdjustment, value)
      }
    },
    countryIndexInflationPossible: {
      get() {
        return this.adjustment.countryIndex.possibleInflation !== -1 ? this.adjustment.countryIndex.possibleInflation : null
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.countryIndex.possibleInflation = value
        this.dispatchAdjustmentUpdate(newAdjustment, value)
      }
    },
    referenceInterestRateReservationPercentage() {
      const referenceInterestRate = this.referenceInterestRate || 0
      const referenceInterestRatePossible = this.referenceInterestRatePossible || 0
      return this.calculateReservationPercentage(referenceInterestRate, referenceInterestRatePossible)
    },
    referenceInterestRateReservationValue() {
      return (Math.round(((this.referenceInterestRateReservationPercentage / 100) * this.coldRent) * 20) / 20)
    },
    countryIndexInflationReservationPercentage() {
      const countryIndexInflation = this.countryIndexInflation || 0
      const countryIndexInflationPossible = this.countryIndexInflationPossible || 0
      return countryIndexInflation !== 0 && countryIndexInflationPossible !== 0 ? ((((countryIndexInflationPossible - countryIndexInflation) * 100) / countryIndexInflation) * 0.4) : 0
    },
    countryIndexInflationReservationValue() {
      return (Math.round(((this.countryIndexInflationReservationPercentage / 100) * this.coldRent) * 20) / 20)
    },
    costIncreaseReservationPercentage() {
      const costIncrease = this.costIncreaseValue || 0
      const costIncreaseInflation = this.costIncreaseInflation || 0
      const costIncreaseInflationPossible = this.costIncreaseInflationPossible || 0
      if (this.costIncreaseType === 'COST_INCREASE_TYPE_FLAT_RATE') {
        return ((costIncrease / 12) * this.calculateMonthsDifference(this.costIncreaseDate, this.costIncreasePossibleDate)) || 0
      } else {
        const value = (((costIncreaseInflationPossible - costIncreaseInflation) * 100) / costIncreaseInflation) * (costIncrease / 100)
        return value || 0
      }
    },
    costIncreaseReservationValue() {
      return (Math.round(((this.costIncreaseReservationPercentage / 100) * this.coldRent) * 20) / 20)
    },
    referenceInterestRateDate: {
      get() {
        return this.adjustment.referenceInterestRate.date !== '-1' ? this.adjustment.referenceInterestRate.date : null
      },
      set(value) {
        if (!this.disabledBefore1914(value)) {
          const newAdjustment = this.adjustment
          newAdjustment.referenceInterestRate.date = value
          this.dispatchAdjustmentUpdate(newAdjustment, value)
        }
      }
    },
    costIncreaseType: {
      get() {
        return this.adjustment.costIncreaseType
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.costIncreaseType = value
        this.dispatchAdjustmentUpdate(newAdjustment, value)
      }
    },
    costIncreaseDate: {
      get() {
        return (this.adjustment.costIncreaseDate !== -1 && this.adjustment.costIncreaseDate !== '-1') ? this.adjustment.costIncreaseDate : null
      },
      set(value) {
        if (!this.disabledBefore1914(value)) {
          const newAdjustment = this.adjustment
          newAdjustment.costIncreaseDate = value
          this.dispatchAdjustmentUpdate(newAdjustment, value)
        }
      }
    },
    costIncreaseValue: {
      get() {
        return this.adjustment.costIncreaseValue !== -1 ? this.adjustment.costIncreaseValue : null
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.costIncreaseValue = value
        this.dispatchAdjustmentUpdate(newAdjustment, value)
      }
    },
    dueDates: {
      get() {
        const tempMonths = this.adjustment.dueDates
        tempMonths.slice().sort(function (a, b) {
          return a - b
        })
        return tempMonths.map(month => {
          return {
            label: this.$t('message.generic.months.' + month),
            value: month
          }
        })
      },
      set(value) {
        const newAdjustment = this.adjustment
        for (const month of value) {
          if (month.value === 'all') {
            newAdjustment.dueDates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            this.dispatchAdjustmentUpdate(newAdjustment, value)
            return
          }
        }
        newAdjustment.dueDates = value.map(v => v.value)
        this.dispatchAdjustmentUpdate(newAdjustment, value)
      }
    },
    translatedMonths() {
      const array = []
      for (const month of this.availableTerminationMonths) {
        array.push({
          label: this.$t('message.generic.months.' + month),
          value: month
        })
      }
      return array
    },
    getDueDateMonths() {
      const array = []
      for (const month of this.adjustment.dueDates) {
        array.push(this.$t('message.generic.months.' + month))
      }
      return array.join(', ')
    },
    calculationBasis: {
      get() {
        return this.adjustment.calculationBasis
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.calculationBasis = value
        this.dispatchAdjustmentUpdate(newAdjustment, value)
      }
    },
    monthlyRentDueBy: {
      get() {
        return this.adjustment.monthlyRentDueBy
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.monthlyRentDueBy = value
        this.dispatchAdjustmentUpdate(newAdjustment, value)
      }
    },
    inArrears: {
      get() {
        return this.adjustment.inArrears
      },
      set(value) {
        const newAdjustment = this.adjustment
        newAdjustment.inArrears = value
        this.dispatchAdjustmentUpdate(newAdjustment, value)
      }
    },
    adjustmentsCountryIndexDate: {
      get() {
        return this.dates.countryIndexDate ? this.dates.countryIndexDate : this.objectToDateString(this.adjustment.countryIndex.date) !== '-' ? this.objectToDateString(this.adjustment.countryIndex.date) : ''
      },
      set(value) {
        if (!this.disabledBefore1914(value)) {
          this.dates.countryIndexDate = value === '' ? null : value
          const newAdjustment = this.adjustment
          newAdjustment.countryIndex.date = value === '' ? null : value
          this.dispatchAdjustmentUpdate(newAdjustment, value)
        }
      }
    },
    adjustmentsReferenceInterestRateDate: {
      get() {
        return this.dates.adjustmentsReferenceInterestRateDate ? this.dates.adjustmentsReferenceInterestRateDate : this.adjustment.referenceInterestRate.date !== '-1' ? this.objectToDateString(this.adjustment.referenceInterestRate.date) !== '-' ? this.objectToDateString(this.adjustment.referenceInterestRate.date) : '' : null
      },
      set(value) {
        if (!this.disabledBefore1914(value)) {
          this.dates.adjustmentsReferenceInterestRateDate = value
          const newAdjustment = this.adjustment
          newAdjustment.referenceInterestRate.date = value
          this.dispatchAdjustmentUpdate(newAdjustment, value)
        }
      }
    },
    adjustmentsCostIncreaseDate: {
      get() {
        return this.dates.costIncreaseDate ? this.dates.costIncreaseDate : (this.adjustment.costIncreaseDate !== -1 && this.adjustment.costIncreaseDate !== '-1') ? this.objectToDateString(this.adjustment.costIncreaseDate) !== '-' ? this.objectToDateString(this.adjustment.costIncreaseDate) : '' : null
      },
      set(value) {
        if (!this.disabledBefore1914(value)) {
          this.dates.costIncreaseDate = value === '' ? null : value
          // this.dates.costIncreasePossibleDate = value === '' ? null : value
          const newAdjustment = this.adjustment
          newAdjustment.costIncreaseDate = value === '' ? null : value
          if (!this.costIncreaseDatePossibleModified) {
            this.dates.costIncreasePossibleDate = this.dates.costIncreaseDate
          }
          this.dispatchAdjustmentUpdate(newAdjustment, value)
        }
      }
    },
    adjustmentsCountryIndexPossibleDate: {
      get() {
        return this.dates.countryIndexPossibleDate ? this.dates.countryIndexPossibleDate : (this.adjustment.countryIndex?.possibleDate !== -1 && this.adjustment.countryIndex?.possibleDate !== '-1') ? this.objectToDateString(this.adjustment.countryIndex?.possibleDate) !== '-' ? this.objectToDateString(this.adjustment.countryIndex?.possibleDate) : '' : null
      },
      set(value) {
        if (!this.disabledBefore1914(value)) {
          this.dates.countryIndexPossibleDate = value === '' ? null : value
          const newAdjustment = this.adjustment
          newAdjustment.countryIndex.possibleDate = value === '' ? null : value
          this.dispatchAdjustmentUpdate(newAdjustment, value)
        }
      }
    },
    adjustmentsCostIncreasePossibleDate: {
      get() {
        return this.dates.costIncreasePossibleDate ? this.dates.costIncreasePossibleDate : (this.adjustment?.costIncreasePossibleDate !== -1 && this.adjustment?.costIncreasePossibleDate !== '-1') ? this.objectToDateString(this.adjustment?.costIncreasePossibleDate) !== '-' ? this.objectToDateString(this.adjustment?.costIncreasePossibleDate) : '' : null
      },
      set(value) {
        if (!this.disabledBefore1914(value)) {
          this.dates.costIncreasePossibleDate = value === '' ? null : value
          const newAdjustment = this.adjustment
          newAdjustment.costIncreasePossibleDate = value === '' ? null : value
          this.dispatchAdjustmentUpdate(newAdjustment, value)
        }
      }
    },
    documents() {
      return this.getFilteredTenancyDocuments(this.tenancy.documentIds).sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    getLang() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    }
  },
  watch: {
    arrearsOfFirstAdjustment: function (newVal) {
      if (newVal) {
        this.inArrears = true
      }
    },
    inArrears: function (newVal) {
      if (newVal) {
        this.monthlyRentDueBy = 31
      } else {
        this.monthlyRentDueBy = 1
      }
    },
    'tenancy.startingAt': function (newVal, oldVal) {
      if (this.isFirstAdjustment && JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        const newAdjustment = this.adjustment
        newAdjustment.byDate = newVal
        this.dispatchAdjustmentUpdate(newAdjustment)
        this.dates.adjustmentsByDate = this.$options.filters.objectToDateInput(newVal)
      }
    },
    'tenancy.contract.creationDate': function (newVal) {
      if (this.isFirstAdjustment && this.dates.adjustmentsReferenceInterestRateDate === null && this.tenancy.contract.rentType !== 'RENT_TYPE_NOT_VMWG') {
        this.dates.adjustmentsReferenceInterestRateDate = this.$options.filters.objectToDateInput(newVal)
        if (this.dates.adjustmentsReferenceInterestRateDate) {
          this.referenceInterestRateDate = this.toObject(this.dates.adjustmentsReferenceInterestRateDate)
        }
      }
    },
    'adjustment.byDate': function (newVal, oldVal) {
      // this.adjustment.newItem = false
      this.startingAtChanged = true
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.getTaxValue()
      }
    },
    referenceInterestRateDate: function (newVal) {
      this.adjustReferenceInterestRate(newVal)
    },
    dueDates: function () {
      this.calculateColdRent('m2')
    },
    'adjustment.coldRentPerMonth': function (newVal) {
      this.coldRent = ((newVal) * (12)) / this.dueDates.length
      this.calculateGrossRentPerMonth()
    },
    'adjustment.additionalCostsPerMonth': function () {
      this.calculateGrossRentPerMonth()
    },
    'object.characteristics.areas.effectiveArea': function () {
      this.calculateColdRent('m2')
    },
    'object.characteristics.areas.livingArea': function () {
      this.calculateColdRent()
    },
    getAdditionalCosts: function (newVal) {
      this.getTaxValue()
      this.adjustmentValueChanged('additionalCosts', newVal)
    },
    getHeatingAdditionalCostsPerMonth: function () {
      this.calculateGrossRentPerMonth()
    },
    adjustment: function (newVal, oldVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal) && this.fromMassRentalProcess) {
        this.getDatesFromObjects()
      }
    }
  },
  mounted() {
    this.isNew = this.adjustment.newItem
    this.getDatesFromObjects()
    this.getTaxValue()
    if ((!this.activeAndEditable && this.byDateInit && this.object && this.object.debitUntil && new Date(this.$options.filters.objectToDateInput(this.byDateInit)) < new Date(this.$options.filters.objectToDateInput(this.object.debitUntil))) && !('newItem' in this.adjustment)) {
      this.tenancyActivatedAndNotAccountingModule = true
    }
    if (this.adjustment.calculationBasis === '') {
      if (this.object && (this.object.category === 'APARTMENT' || this.object.category === 'HOUSE' || this.object.category === 'SECONDARY_ROOM' || this.object.category === 'PARKING_SPACE')) {
        this.calculationBasis = 'TENANCY_CALCULATION_BASIS_DEFAULT'
        if (this.requestId !== '') {
          this.calculateColdRent('m2')
        }
      } else {
        this.calculationBasis = 'TENANCY_CALCULATION_BASIS_M2_YEAR'
        if (this.requestId !== '') {
          this.calculateColdRent()
        }
      }
    }
    if (this.countryIndexBaseDate && this.costIncreaseDate) {
      this.getInflationRate(true)
    }
    if (this.countryIndexBaseDate && this.costIncreasePossibleDate) {
      this.getPossibleInflationRate(true)
    }
  },
  methods: {
    calculateReservationPercentage(effectiveRate, possibleRate) {
      const ranges = [
        { min: 0, max: 5, percentage: 3 },
        { min: 5, max: 6, percentage: 2.5 },
        { min: 6, max: Infinity, percentage: 2 }
      ]

      let totalPercentage = 0
      let currentRate = effectiveRate

      if (effectiveRate <= possibleRate) {
        // Case 1: effectiveRate <= possibleRate
        while (currentRate < possibleRate) {
          for (let range of ranges) {
            if (currentRate >= range.min && currentRate < range.max) {
              let increment = Math.min(possibleRate - currentRate, range.max - currentRate)
              totalPercentage += (increment / 0.25) * range.percentage
              currentRate += increment
              break
            }
          }
        }
      } else {
        // Case 2: effectiveRate > possibleRate
        while (currentRate > possibleRate) {
          for (let range of ranges) {
            if (currentRate > range.min && currentRate <= range.max) {
              let decrement = Math.min(currentRate - possibleRate, currentRate - range.min)
              totalPercentage += (decrement / 0.25) * range.percentage
              currentRate -= decrement
              break
            }
          }
        }
        totalPercentage = (-totalPercentage * 100) / (100 + totalPercentage)
      }

      return totalPercentage
    },
    calculateMonthsDifference(startDate, endDate) {
      let months = 0
      if (!startDate || !endDate) {
        return months
      }
      // Ensure both dates are Date objects
      const formatedStart = this.$options.filters.objectToDateInput(startDate)
      const formatedEnd = this.$options.filters.objectToDateInput(endDate)
      const start = new Date(formatedStart)
      const end = new Date(formatedEnd)

      // Calculate the difference in months
      months = (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth()

      return months
    },
    datePicked(field) {
      if (field === 'countryIndexDatePossible') {
        this.countryIndexPossibleDateModified = true
      }
      if (field === 'costIncreasePossibleDate') {
        this.costIncreaseDatePossibleModified = true
      }
    },
    flatRateValues() {
      this.adjustment.flatRateValues.sort(this.customFlatSort)
    },
    customFlatSort(a, b) {
      // Define the prefixes you want to prioritize
      const prefixes = [
        'FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_PROPERTY_',
        'FLAT_RATE_RENT_COMPONENT_IN_FAVOR_OF_ADDITIONAL_COSTS_'
      ]

      // Find the index of the prefix in the prefixes array
      const indexOfA = prefixes.find(prefix => a?.type?.startsWith(prefix))
      const indexOfB = prefixes.find(prefix => b?.type?.startsWith(prefix))

      // If both values have a matching prefix, compare them based on their original order
      if (indexOfA && indexOfB) {
        return indexOfA.localeCompare(indexOfB)
      }

      // If only one value has a matching prefix, prioritize it
      if (indexOfA) {
        return -1
      }

      if (indexOfB) {
        return 1
      }

      // Handle cases where a or b do not have a 'value' property or it's not a string
      const aValue = a?.value || ''
      const bValue = b?.value || ''

      return aValue.toString().localeCompare(bValue.toString())
    },
    calculateGrossRentPerMonth() {
      this.grossRentPerMonth = this.coldRentPerMonth + this.getHeatingAdditionalCostsPerMonth
    },
    adjustReferenceInterestRate(date) {
      this.referenceInterestRate = this.getReferenceInterestRateByTimestamp(new Date(this.$options.filters.objectToDateInput(date)).getTime() / 1000)
      // this.referenceInterestRatePossible = this.getReferenceInterestRateByTimestamp(new Date(this.$options.filters.objectToDateInput(date)).getTime() / 1000)
    },
    getTaxValue() {
      if (this.adjustment.byDate) {
        this.tax.forEach(element => {
          const start = element.start ? new Date(element.start).getTime() / 1000 : null
          const end = element.end ? new Date(element.end).getTime() / 1000 : null
          const byDate = new Date(this.$options.filters.objectToDateInput(this.adjustment.byDate)).getTime() / 1000
          if ((!start || byDate >= start) && (!end || byDate <= end)) {
            this.taxValue = Number(element.value)
            this.taxNetRent = this.coldRent !== null ? (Math.round(((this.coldRent * this.taxValue) / 100) * 20) / 20) : 0
            this.taxHeatingAdditionalCosts = this.getHeatingAdditionalCosts !== null ? (Math.round(((this.getHeatingAdditionalCosts * this.taxValue) / 100) * 20) / 20) : 0
            this.grossRentInclTax = (this.getGrossRent + this.taxNetRent + this.taxHeatingAdditionalCosts)
          }
        })
      }
    },
    checkInputValue(from, $event) {
      if (from === 'countryIndexPossibleDate') {
        this.countryIndexPossibleDateModified = true
      } else if (from === 'costIncreasePossibleDate') {
        this.costIncreaseDatePossibleModified = true
      }
      const reg2 = /\/{2,}/g
      if (($event.keyCode >= 96 && $event.keyCode <= 105) || ($event.shiftKey && $event.keyCode >= 48 && $event.keyCode <= 57) || $event.keyCode === 8 || $event.key === 'ArrowRight' || $event.key === 'ArrowLeft' || $event.key === 'Delete') {
        return true
      } else if ($event.keyCode === 9) {
        if (from === 'countryIndexDate') {
          this.$refs.datepicker2.closePopup()
        } else if (from === 'costIncreaseDate') {
          this.$refs.datepicker3.closePopup()
        }
        return true
      } else {
        $event.preventDefault()
      }
      if (from === 'countryIndexDate') {
        this.dates.countryIndexDate = this.dates.countryIndexDate?.replace(reg2, '/')
      } else if (from === 'costIncreaseDate') {
        this.dates.costIncreaseDate = this.dates.costIncreaseDate?.replace(reg2, '/')
      }
    },
    addRetention() {
      this.adjustment.retentionOfRents.push({
        actual: 0.00,
        inPercent: false,
        possible: 0.00,
        remark: '',
        type: ''
      })
    },
    changeRetention() {
      this.$emit('change-adjustment', this.adjustment)
    },
    deleteRetention(index) {
      this.adjustment.retentionOfRents.splice(index, 1)
      this.$emit('change-adjustment', this.adjustment)
    },
    handleErrorOnFinish(e) {
      console.log(e)
      this.loading = false
      Vue.toasted.show(this.$t('message.loadingErrors.tenancy'), { type: 'error' })
    },
    adjustmentValueChanged(field, val) {
      if (field === 'countryIndex.inflation') {
        this.adjustment.countryIndex.inflation = val
      } else if (field === 'referenceInterestRate.rate') {
        this.adjustment.referenceInterestRate.rate = val
      } else if (field === 'referenceInterestRate.ratePossible') {
        this.adjustment.referenceInterestRate.ratePossible = val
        this.referenceInterestRatePossibleModified = true
      } else if (field === 'additionalCosts') {
        this.adjustment.heatingCosts = -1
        this.adjustment.additionalCosts = val
      } else {
        this.adjustment[field] = val
      }
    },
    getModulo(array) {
      let foo = []
      for (let i = 0; i < array.length / 4; i++) {
        foo.push(i)
      }
      return foo
    },
    advancePaymentValueChanged(item, val) {
      const newAdjustment = this.adjustment
      newAdjustment.advancePaymentValues.find(x => x.type === item.type).value = val
      this.dispatchAdjustmentUpdate(newAdjustment)
    },
    flatRateValueChanged(item, val) {
      const newAdjustment = this.adjustment
      newAdjustment.flatRateValues.find(x => x.type === item.type).value = val
      this.dispatchAdjustmentUpdate(newAdjustment)
    },
    additionalValuesChanged(item, val) {
      const newAdjustment = this.adjustment
      newAdjustment.additionalValues.find(x => x.type === item.type).value = val
      this.dispatchAdjustmentUpdate(newAdjustment)
    },
    dateToObject() {
      this.adjustment.byDate = this.toObject(this.dates.adjustmentsByDate)
      this.referenceInterestRateDate = this.toObject(this.dates.adjustmentsReferenceInterestRateDate)
      this.firstRentAdjustmentPossibleAt = this.toObject(this.dates.firstRentAdjustmentPossibleAt)
    },
    dispatchTenancyUpdate(newTenancy) {
      this.$store.dispatch('onboarding/updateTenancy', newTenancy)
    },
    getDatesFromObjects() {
      this.dates.adjustmentsByDate = this.$options.filters.objectToDateInput(this.adjustment.byDate)
      this.dates.adjustmentsReferenceInterestRateDate = this.$options.filters.objectToDateInput(this.referenceInterestRateDate)
      this.dates.countryIndexBaseDate = this.getCountryIndexBaseDateOptions(this.countryIndexBaseDate)
      this.dates.countryIndexDate = this.countryIndexDate ? ('0' + this.countryIndexDate.month).slice(-2) + '/' + this.countryIndexDate.year : null
      this.dates.costIncreaseDate = this.costIncreaseDate ? ('0' + this.costIncreaseDate.month).slice(-2) + '/' + this.costIncreaseDate.year : null
      this.dates.countryIndexPossibleDate = this.countryIndexPossibleDate ? ('0' + this.countryIndexPossibleDate.month).slice(-2) + '/' + this.countryIndexPossibleDate.year : null
      this.dates.firstRentAdjustmentPossibleAt = this.$options.filters.objectToDateInput(this.firstRentAdjustmentPossibleAt)
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
        if (this.$v.adjustment.byDate.$dirty && this.$v.adjustment.byDate.$error && !this.$v.adjustment.byDate.notEqualToOtherAdjBydate) {
          Vue.toasted.show(this.$t('message.savingErrors.notEqualToOtherAdjBydate'), { type: 'error' })
        } else if (this.$v.adjustment.byDate.$dirty && this.$v.adjustment.byDate.$error && !this.$v.adjustment.byDate.moreThanStarting) {
          Vue.toasted.show(this.$t('message.savingErrors.costAdjustmentAfterContractStart'), { type: 'error' })
        } else if (this.$v.adjustment.byDate.$dirty && this.$v.adjustment.byDate.$error && !this.$v.adjustment.byDate.moreThanDebitUntil) {
          Vue.toasted.show(this.$t('message.savingErrors.debitDateAndAdjustment', { debitUntil: this.$options.filters.objectToDate(this.object.debitUntil) }), { type: 'error' })
        } else if (this.$v.adjustment.byDate.$dirty && this.$v.adjustment.byDate.$error && !this.$v.adjustment.byDate.beforeEndOfContract) {
          Vue.toasted.show(this.$t('message.savingErrors.byDateAfterEndContract'), { type: 'error' })
        } else if (this.dates.countryIndexPossibleDate && this.$v.dates.countryIndexPossibleDate.$dirty && this.$v.dates.countryIndexPossibleDate.$error && !this.$v.dates.countryIndexPossibleDate.beforeCountryIndexDate) {
          Vue.toasted.show(this.$t('message.savingErrors.CountryIndexDatePossibleBeforeCountryIndexDate'), { type: 'error' })
        } else if (this.dates.costIncreasePossibleDate && this.$v.dates.costIncreasePossibleDate.$dirty && this.$v.dates.costIncreasePossibleDate.$error && !this.$v.dates.costIncreasePossibleDate.beforeCostIncreaseDate) {
          Vue.toasted.show(this.$t('message.savingErrors.CostIncreaseDatePossibleBeforeCostIncreaseDate'), { type: 'error' })
        } else {
          Vue.toasted.show(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.buildings.objects.tenancies.tenancy') + this.$t('message.savingErrors.validationError2'), { type: 'error' })
        }
      }
      return this.$v
    },
    openConfirmationModal() {
      this.$refs['cost-adjustments-confirmationModal'].show()
    },
    calculateColdRent(from = 'standard') {
      const duedate = this.dueDates.length > 0 ? this.dueDates.length : 1
      let la = 1
      let fa = 1
      if (this.object && (this.object.category === 'HOUSE' || this.object.category === 'APARTMENT')) {
        la = this.object.characteristics &&
        this.object.characteristics.areas &&
        this.object.characteristics.areas.livingArea !== -1
          ? this.object.characteristics.areas.livingArea
          : 1
        if (this.calculationBasis === 'TENANCY_CALCULATION_BASIS_M2_YEAR' && (!this.object.debitUntil || (this.object.debitUntil && this.dateObjectToTimeStamp(this.adjustment.byDate) >= this.dateObjectToTimeStamp(this.object.debitUntil)))) {
          if (from === 'standard') {
            this.coldRentM2PerYear = Math.round(duedate * this.coldRent / la)
          } else {
            this.coldRent = Math.round(la * this.coldRentM2PerYear / duedate)
          }
        } else {
          this.coldRentM2PerYear = Math.round(duedate * this.coldRent / la)
          // this.coldRent = Math.round(la * this.coldRentM2PerYear / duedate)
        }
      } else {
        fa = this.object && this.object.characteristics &&
        this.object.characteristics.areas &&
        this.object.characteristics.areas.effectiveArea !== -1
          ? this.object.characteristics.areas.effectiveArea
          : 1
        if (this.calculationBasis === 'TENANCY_CALCULATION_BASIS_M2_YEAR' && (!this.object.debitUntil || (this.object.debitUntil && this.dateObjectToTimeStamp(this.adjustment.byDate) >= this.dateObjectToTimeStamp(this.object.debitUntil)))) {
          if (from === 'standard') {
            this.coldRentM2PerYear = Math.round(duedate * this.coldRent / fa)
          } else {
            this.coldRent = Math.round(fa * this.coldRentM2PerYear / duedate)
          }
        } else {
          this.coldRentM2PerYear = Math.round(duedate * this.coldRent / fa)
          // this.coldRent = fa * this.coldRentM2PerYear / duedate
        }
      }
      this.coldRentPerMonth = ((this.coldRent) * (this.dueDates.length)) / 12
      this.adjChanged = true
      this.getTaxValue()
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          })
        } else {
          this.$router.push({
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          })
        }
      }
    },
    removeClicked() {
      this.$refs.deleteModal.show()
    },
    getFormatPriceOfReduction(value, decimal = false) {
      value = Number(value).toFixed(decimal === true ? 2 : ((value % 1) === 0 ? 0 : 2)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\'')
      return `CHF -${value}`
    },
    deleteAdjustmentClicked() {
      this.$emit('delete-adjustment', this.adjustment.internalId)
      this.$refs['cost-adjustments-confirmationModal'].hide()
    },
    clearCostIncreaseDate() {
      this.costIncreaseDate = null
      this.dates.costIncreaseDate = null
    },
    clearCountryIndexDate() {
      this.countryIndexDate = null
      this.dates.countryIndexDate = null
    },
    clearCountryIndexPossibleDate() {
      this.countryIndexPossibleDate = null
      this.dates.countryIndexPossibleDate = null
    },
    clearCostIncreasePossibleDate() {
      this.costIncreasePossibleDate = null
      this.dates.costIncreasePossibleDate = null
    },
    costIncreaseTypeChanged() {
      if (this.countryIndexBaseDate && ((this.costIncreaseType === 'COST_INCREASE_TYPE_FLAT_RATE' && this.countryIndexDate) || (this.costIncreaseType === 'COST_INCREASE_TYPE_INDEX' && this.costIncreaseDate))) {
        this.getInflationRate(true)
      }
      if (this.countryIndexBaseDate && ((this.costIncreaseType === 'COST_INCREASE_TYPE_FLAT_RATE' && this.countryIndexPossibleDate) || (this.costIncreaseType === 'COST_INCREASE_TYPE_INDEX' && this.costIncreasePossibleDate))) {
        this.getPossibleInflationRate(true)
      }
    },
    handleCostIncreaseDateChange() {
      if (this.dates.costIncreaseDate) {
        const costIncreaseDateArrayD = this.dates.costIncreaseDate.split('/')
        const costIncreaseDateFormated = new Date(costIncreaseDateArrayD[0] + '/01/' + costIncreaseDateArrayD[1])
        if (this.isValidDate(costIncreaseDateFormated) === true) {
          this.costIncreaseDate = this.toObject(costIncreaseDateArrayD[1] + '-' + costIncreaseDateArrayD[0] + '-01')
          if (this.costIncreaseType === 'COST_INCREASE_TYPE_INDEX' && this.countryIndexBaseDate && this.costIncreaseDate && this.costIncreaseDate.year > 999) {
            this.getInflationRate(true)
          }
          if (!this.costIncreaseDatePossibleModified) {
            this.dates.costIncreasePossibleDate = this.dates.costIncreaseDate
            this.handleCostIncreasePossibleDateChange()
          }
        }
      }
    },
    handleCostIncreasePossibleDateChange() {
      if (this.dates.costIncreasePossibleDate) {
        const costIncreasePossibleDateArrayD = this.dates.costIncreasePossibleDate.split('/')
        const costIncreasePossibleDateFormated = new Date(costIncreasePossibleDateArrayD[0] + '/01/' + costIncreasePossibleDateArrayD[1])
        if (this.isValidDate(costIncreasePossibleDateFormated) === true) {
          this.costIncreasePossibleDate = this.toObject(costIncreasePossibleDateArrayD[1] + '-' + costIncreasePossibleDateArrayD[0] + '-01')
          if (this.costIncreaseType === 'COST_INCREASE_TYPE_INDEX' && this.countryIndexBaseDate && this.costIncreasePossibleDate && this.costIncreasePossibleDate.year > 999) {
            this.getPossibleInflationRate(true)
          }
        }
      }
    },
    mountEventListenersCountryIndex(e) {
      if (e.which !== 8) {
        let lastChar = e.substr(e.length - 1)
        if (isNaN(lastChar) && lastChar !== '/') {
          this.$nextTick(function () {
            if (this.dates.countryIndexDate) {
              this.dates.countryIndexDate = this.dates.countryIndexDate.slice(0, -1)
            }
          })
        } else if (this.dates.countryIndexDate) {
          let numChars = this.dates.countryIndexDate.length
          if (numChars < 6) {
            if (numChars === 2) {
              let thisVal = this.dates.countryIndexDate
              thisVal += '/'
              this.dates.countryIndexDate = thisVal
            }
            if (numChars === 6) {
              this.handleCountryIndexDateChange()
            }
          } else {
            this.$nextTick(function () {
              this.dates.countryIndexDate = this.dates.countryIndexDate.slice(0, 7)
              this.handleCountryIndexDateChange()
            })
          }
        }
      }
    },
    mountEventListenersCountryIndexPossibleDate(e) {
      if (e.which !== 8) {
        let lastChar = e.substr(e.length - 1)
        if (isNaN(lastChar) && lastChar !== '/') {
          this.$nextTick(function () {
            if (this.dates.countryIndexPossibleDate) {
              this.dates.countryIndexPossibleDate = this.dates.countryIndexPossibleDate.slice(0, -1)
            }
          })
        } else if (this.dates.countryIndexPossibleDate) {
          let numChars = this.dates.countryIndexPossibleDate.length
          if (numChars < 6) {
            if (numChars === 2) {
              let thisVal = this.dates.countryIndexPossibleDate
              thisVal += '/'
              this.dates.countryIndexPossibleDate = thisVal
            }
            if (numChars === 6) {
              this.handleCountryIndexPossibleDateChange()
            }
          } else {
            this.$nextTick(function () {
              this.dates.countryIndexPossibleDate = this.dates.countryIndexPossibleDate.slice(0, 7)
              this.handleCountryIndexPossibleDateChange()
            })
          }
        }
      }
    },
    mountEventListenersCostIncreasePossibleDate(e) {
      if (e.which !== 8) {
        let lastChar = e.substr(e.length - 1)
        if (isNaN(lastChar) && lastChar !== '/') {
          this.$nextTick(function () {
            if (this.dates.costIncreasePossibleDate) {
              this.dates.costIncreasePossibleDate = this.dates.costIncreasePossibleDate.slice(0, -1)
            }
          })
        } else if (this.dates.costIncreasePossibleDate) {
          let numChars = this.dates.costIncreasePossibleDate.length
          if (numChars < 6) {
            if (numChars === 2) {
              let thisVal = this.dates.costIncreasePossibleDate
              thisVal += '/'
              this.dates.costIncreasePossibleDate = thisVal
            }
            if (numChars === 6) {
              this.handleCostIncreasePossibleDateChange()
            }
          } else {
            this.$nextTick(function () {
              this.dates.costIncreasePossibleDate = this.dates.costIncreasePossibleDate.slice(0, 7)
              this.handleCostIncreasePossibleDateChange()
            })
          }
        }
      }
    },
    mountEventListenersCostIncreaseDate(e) {
      if (e.which !== 8) {
        let lastChar = e.substr(e.length - 1)
        if (isNaN(lastChar) && lastChar !== '/') {
          this.$nextTick(function () {
            this.dates.costIncreaseDate = this.dates.costIncreaseDate.slice(0, -1)
          })
        } else if (this.dates.costIncreaseDate) {
          let numChars = this.dates.costIncreaseDate.length
          if (numChars < 6) {
            if (numChars === 2 && this.dates.costIncreaseDate.split('/').length - 1 === 0) {
              let thisVal = this.dates.costIncreaseDate
              thisVal += '/'
              this.dates.costIncreaseDate = thisVal
            }
            if (numChars === 6) {
              this.handleCostIncreaseDateChange()
            }
          } else {
            this.$nextTick(function () {
              this.dates.costIncreaseDate = this.dates.costIncreaseDate.slice(0, 7)
              this.handleCostIncreaseDateChange()
            })
          }
        }
      }
    },
    dispatchAdjustmentUpdate(newAdjustment) {
      this.$emit('change-adjustment', newAdjustment)
    }
  },
  validations() {
    const validationAdjustment = {
      adjustment: {
        byDate: {
          required,
          moreThanStarting,
          moreThanDebitUntil,
          notEqualToOtherAdjBydate,
          beforeEndOfContract
        },
        coldRent: {
          required,
          minValue: minValue(0)
        }
      },
      dueDates: {
        required,
        minLength: minLength(1)
      },
      dates: {
        countryIndexDate: {
          validDate
        },
        costIncreaseDate: {
          validDate,
          moreThan1914
        }
      }
    }
    if (this.tenancy.contract.rentType !== 'RENT_TYPE_NOT_VMWG') {
      validationAdjustment.dates = {
        countryIndexDate: {
          required,
          validDate
        },
        countryIndexBaseDate: {
          required
        }
      }
      if (this.tenancy.contract.rentType !== 'RENT_TYPE_INDEX' && this.tenancy.contract.rentType !== 'RENT_TYPE_TURNOVER') {
        validationAdjustment.costIncreaseValue = {
          required
        }
        validationAdjustment.costIncreaseType = {
          required
        }
        validationAdjustment.dates.costIncreaseDate = {
          required,
          validDate
        }
        validationAdjustment.referenceInterestRate = {
          required,
          validReferenceInterestRate
        }
        // validationAdjustment.referenceInterestRatePossible = {
        //   validReferenceInterestRate
        // }
      }
      validationAdjustment.countryIndexInflation = {
        required
      }
    }
    if (this.dates.countryIndexPossibleDate) {
      validationAdjustment.dates.countryIndexPossibleDate = {
        beforeCountryIndexDate
      }
    }
    if (this.tenancy.contract.rentType !== 'RENT_TYPE_INDEX' && this.dates.costIncreasePossibleDate) {
      validationAdjustment.dates.costIncreasePossibleDate = {
        beforeCostIncreaseDate
      }
    }
    return validationAdjustment
  }
}
</script>

<style lang="scss" scoped>
h5 > span {
  font-size: 18px !important;
}

:deep(.mx-datepicker) {
  width: 100%;
}
</style>
