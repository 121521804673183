// @ts-nocheck
import Vue from 'vue'
import Router from 'vue-router'
import CoozzyRouterViewWrapper from '../framework/layout/CoozzyRouterViewWrapper'
import { CalendarComponents } from '@/misc/loader/calendar/components'
import OwnerDashboardOverviewView from '../dashboard/owner/OwnerDashboardOverviewView'
import MarketingDashboardView from '../dashboard/marketing/MarketingDashboardView'
import AdminDashboardView from '../dashboard/admin/AdminDashboardView'
import AccountingDashboardView from '../dashboard/accounting/AccountingDashboardView'
import AssetDashboardView from '../dashboard/asset/AssetDashboardView'
import UserRequests from '../user/UserRequests'
import Login from '../auth/components/Login'
import Logout from '../auth/components/Logout'
import Registration from '../auth/components/Registration'
import Callback from '../auth/components/Callback'
import ApplicationFormLandingPage from '@/marketingBasic/components/applicationForm/ApplicationFormLandingPage'
import OwnerPropertyView from '@/owner/OwnerPropertyView'
import PropertyDetailsView from '@/property/PropertyDetailsView'
import PropertyEditingView from '@/property/onboarding/PropertyEditingView'
import OwnerBuildingsView from '@/owner/OwnerBuildingsView'
import BuildingsMapView from '@/building/BuildingsMapView'
import BuildingDetailsView from '@/building/BuildingDetailsView'
import OnboardingView from '@/building/onboarding/OnboardingView'
import OwnerRentalObjectOverview from '@/owner/OwnerRentalObjectOverview'
import OwnerCondominiumObjectOverview from '@/owner/OwnerCondominiumObjectOverview'
import OwnerTenanciesView from '@/owner/OwnerTenanciesView'
import OwnerCreatePropertyView from '@/owner/OwnerCreatePropertyView'
import OwnerNetworkPartnerView from '@/networkPartner/owner/OwnerNetworkPartnerView'
import OwnerNetworkPartnerDetailsView from '@/networkPartner/owner/OwnerNetworkPartnerDetailsView'
import AdminPropertyView from '@/admin/AdminPropertyView'
import AdminBuildingsView from '@/admin/AdminBuildingsView'
import AdminRentalObjectOverview from '@/admin/AdminRentalObjectOverview'
import AdminCondominiumObjectOverview from '@/admin/AdminCondominiumObjectOverview'
import CondominiumOverview from '@/admin/CondominiumOverview'
import AdminTenanciesView from '@/admin/AdminTenanciesView'
import AccountingPropertyView from '@/accounting/AccountingPropertyView'
import AccountingBuildingsView from '@/accounting/AccountingBuildingsView'
import AccountingRentalObjectOverview from '@/accounting/AccountingRentalObjectOverview'
import AccountingCondominiumObjectOverview from '@/accounting/AccountingCondominiumObjectOverview'
import AccountingCondominiumOverview from '@/accounting/AccountingCondominiumOverview'
import AccountingTenanciesView from '@/accounting/AccountingTenanciesView'
import AccountingMarketingObjectsView from '@/accounting/AccountingMarketingObjectsView'
import LogFilesView from '@/accounting/LogFilesView'
import MarketingObjectDetailsView from '@/marketing/MarketingObjectDetailsView'
import AdminMarketingObjectsView from '@/admin/AdminMarketingObjectsView'
import AssetPropertyView from '@/asset/AssetPropertyView'
import AssetBuildingsView from '@/asset/AssetBuildingsView'
import AssetRentalObjectOverview from '@/asset/AssetRentalObjectOverview'
import AssetTenanciesView from '@/asset/AssetTenanciesView'
import RemindersView from '@/calendar/components/RemindersView'
import SettingsView from '@/settings/SettingsView'
import MarketingObjectsView from '@/marketing/MarketingObjectsView'
import CreateOrderView from '@/order/view/CreateOrderView'
import AddTenancyView from '@/addTenancy/AddTenancyView'
import SerialLetterProcessView from '@/addTenancy/SerialLetterProcessView'
import ActivateTenancy from '@/addTenancy/ActivateTenancy'
import EnterTerminationView from '@/enterTermination/EnterTerminationView'
import MarketingEditAdvertView from '@/marketing/MarketingEditAdvertView'
import MarkForMarketingView from '@/marketing/MarkForMarketingView'
import MessagesView from '@/messages/MessagesView'
import MessagesDraftView from '@/messages/MessagesDraftView'
import HelpView from '@/staticPages/HelpView'
import AboutUsView from '@/staticPages/AboutUsView'
import TosView from '@/staticPages/TosView'
import PrivacyPolicyView from '@/staticPages/PrivacyPolicyView'
import ImprintView from '@/staticPages/ImprintView'
import CookiesView from '@/staticPages/CookiesView'
import ContactOverview from '@/contact/components/ContactOverview'
import BriefButlerOverview from '@/management/components/BriefButlerOverview'
import DeepSignOverview from '@/management/components/DeepSignOverview'
import TicketOverview from '@/management/components/ticket/TicketOverview'
import ClosedTicketOverview from '@/management/components/ticket/ClosedTicketOverview'
import TicketCreation from '@/management/components/ticket/TicketCreation'
import TicketsAndActivitiesView from '@/management/components/ticket/TicketsAndActivitiesView'
import TicketDetailsView from '@/management/components/ticket/TicketDetailsView'
import TicketPublicView from '@/management/components/ticket/TicketPublicView'
import ContactDetailsView from '@/contact/components/ContactDetailsView'
import ContactSettingsView from '@/settings/ContactSettingsView'
import UserRequestDetailView from '@/user/UserRequestDetailView'
import ApplicationFormView from '@/user/ApplicationFormView'
import UserSettings from '@/user/UserSettings'
import ReportingView from '@/reporting/ReportingView'
import EventExternalReplyView from '@/calendar/components/EventExternalReplyView'
import AddVacancyView from '@/addVacancy/AddVacancyView'
import AssetNetworkPartnerView from '@/networkPartner/asset/AssetNetworkPartnerView'
import AssetNetworkPartnerDetailsView from '@/networkPartner/asset/AssetNetworkPartnerDetailsView'
import AssetCondominiumObjectOverview from '@/asset/AssetCondominiumObjectOverview'
import GenerateExcelView from '@/generateExcel/GenerateExcelView'
import createMassRentalView from '@/massRental/CreateMassRentalView.vue'
import DraftTicketOverview from '@/management/components/ticket/DraftTicketOverview.vue'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: { name: 'Login' }
  },
  {
    path: '/de',
    redirect: { name: 'Login' }
  },
  {
    path: '/login',
    component: Login,
    name: 'Login'
  },
  {
    path: '/logout',
    component: Logout,
    name: 'Logout'
  },
  {
    path: '/register/:token',
    component: Registration,
    name: 'Registration',
    props: true
  },
  {
    path: '/callback',
    component: Callback,
    name: 'Callback'
  },
  {
    path: '/owner/dashboard',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: 'overview',
        component: OwnerDashboardOverviewView, // Do not load this async since the login callback won't work anymore with it
        name: 'OwnerDashboardOverview'
      }
    ]
  },
  {
    path: '/marketing/dashboard',
    component: MarketingDashboardView, // Do not load this async since the login callback won't work anymore with it
    name: 'MarketingDashboardView'
  },
  {
    path: '/admin/dashboard',
    component: AdminDashboardView, // Do not load this async since the login callback won't work anymore with it
    name: 'AdminDashboardView'
  },
  {
    path: '/asset/dashboard',
    component: AssetDashboardView, // Do not load this async since the login callback won't work anymore with it
    name: 'AssetDashboardView'
  },
  {
    path: '/accounting/dashboard',
    component: AccountingDashboardView, // Do not load this async since the login callback won't work anymore with it
    name: 'AccountingDashboardView'
  },
  {
    path: '/accounting/logfiles',
    component: LogFilesView,
    name: 'AccountingLogFilesView'
  },
  {
    path: '/admin/logfiles',
    component: LogFilesView,
    name: 'AdminLogFilesView'
  },
  {
    path: '/asset/logfiles',
    component: LogFilesView,
    name: 'AssetLogFilesView'
  },
  {
    path: '/marketing/logfiles',
    component: LogFilesView,
    name: 'MarketingLogFilesView'
  },
  {
    path: '/owner/logfiles',
    component: LogFilesView,
    name: 'OwnerLogFilesView'
  },
  // Reporting
  {
    path: '/owner/reporting',
    component: ReportingView,
    name: 'OwnerReportingOverview',
    props: (route) => ({
      type: 'ownerOverview',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/owner/reporting/marketing',
    component: ReportingView,
    name: 'OwnerReportingMarketing',
    props: (route) => ({
      type: 'marketing',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/owner/reporting/admin',
    component: ReportingView,
    name: 'OwnerReportingManagement',
    props: (route) => ({
      type: 'administration',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/owner/reporting/accounting',
    component: ReportingView,
    name: 'OwnerReportingAccounting',
    props: (route) => ({
      type: 'accounting',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/owner/tenant-list',
    component: ReportingView,
    name: 'OwnerReportingTenantList',
    props: (route) => ({
      type: 'tenantList',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/accounting/tenant-list',
    component: ReportingView,
    name: 'AccountingReportingTenantList',
    props: (route) => ({
      type: 'tenantList',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/accounting/reporting',
    component: ReportingView,
    name: 'AccountingReportingOverview',
    props: (route) => ({
      type: 'accountingOverview',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/accounting/reporting/admin',
    component: ReportingView,
    name: 'AccountingReportingManagement',
    props: (route) => ({
      type: 'administration',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/accounting/reporting/accounting',
    component: ReportingView,
    name: 'AccountingReportingAccounting',
    props: (route) => ({
      type: 'accounting',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/admin/reporting',
    component: ReportingView,
    name: 'AdminReportingOverview',
    props: (route) => ({
      type: 'adminOverview',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/admin/reporting/admin',
    component: ReportingView,
    name: 'AdminReportingManagement',
    props: (route) => ({
      type: 'administration',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/admin/reporting/accounting',
    component: ReportingView,
    name: 'AdminReportingAccounting',
    props: (route) => ({
      type: 'accounting',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/admin/tenant-list',
    component: ReportingView,
    name: 'AdminReportingTenantList',
    props: (route) => ({
      type: 'tenantList',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/marketing/reporting',
    component: ReportingView,
    name: 'MarketingReportingOverview',
    props: (route) => ({
      type: 'marketingOverview',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/marketing/reporting/marketing',
    component: ReportingView,
    name: 'MarketingReportingMarketing',
    props: (route) => ({
      type: 'marketing',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/marketing/publish-history',
    component: ReportingView,
    name: 'MarketingReportingPublishHistory',
    props: (route) => ({
      type: 'publishHistory',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/asset/reporting',
    component: ReportingView,
    name: 'AssetReportingOverview',
    props: (route) => ({
      type: 'assetOverview',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/asset/reporting/marketing',
    component: ReportingView,
    name: 'AssetReportingMarketing',
    props: (route) => ({
      type: 'marketing',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/asset/reporting/admin',
    component: ReportingView,
    name: 'AssetReportingManagement',
    props: (route) => ({
      type: 'administration',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/asset/reporting/accounting',
    component: ReportingView,
    name: 'AssetReportingAccounting',
    props: (route) => ({
      type: 'accounting',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/asset/tenant-list',
    component: ReportingView,
    name: 'AssetReportingTenantList',
    props: (route) => ({
      type: 'tenantList',
      selectedElement: route.query.selectedElement
    })
  },
  {
    path: '/object/:objectId?',
    component: ApplicationFormLandingPage,
    name: 'ApplicationFormLandingPage',
    props: (route) => ({
      objectId: route.params.objectId
    })
  },
  // Owner module specific routes
  {
    path: '/owner',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: 'tickets',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: TicketOverview,
            name: 'OwnerTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              defaultNewEvent: route.query.defaultNewEvent,
              nameLinked: route.query.nameLinked,
              linkEmail: route.query.linkEmail,
              ownerIds: route.query.ownerIds,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'closed',
            component: ClosedTicketOverview,
            name: 'OwnerClosedTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'draft',
            component: DraftTicketOverview,
            name: 'OwnerDraftTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'create',
            component: TicketCreation,
            name: 'OwnerTicketCreation',
            props: (route) => ({
              fromBulk: route.query.fromBulk,
              ids: route.query.ids,
              defaultAssignee: route.query.defaultAssignee,
              ticketType: route.query.ticketType,
              emailsData: route.query.emailsData,
              buildingIds: route.query.buildingIds,
              propertyIds: route.query.propertyIds,
              objectIds: route.query.objectIds,
              tenancyIds: route.query.tenancyIds,
              condoOwnerIds: route.query.condoOwnerIds,
              contactIds: route.query.contactIds
            })
          },
          {
            path: 'edit/:id',
            component: TicketCreation,
            name: 'OwnerTicketEdition',
            props: (route) => ({
              id: route.params.id,
              source: route.query.source
            })
          },
          {
            path: ':id',
            component: TicketDetailsView,
            name: 'OwnerTicketDetailsView',
            props: (route) => ({
              id: route.params.id
            })
          },
          {
            path: 'activitiesTicket/:id',
            component: TicketsAndActivitiesView,
            name: 'OwnerActivitiesTicketView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              defaultType: route.query.defaultType,
              othersIds: route.query.othersIds,
              defaultStatus: route.query.defaultStatus,
              defaultDevice: route.query.defaultDevice
            })
          }
        ]
      },
      {
        path: 'properties',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: OwnerPropertyView,
            name: 'OwnerPropertyView'
          },
          {
            path: ':id',
            component: PropertyDetailsView,
            name: 'OwnerPropertyDetailsView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              addNewItem: route.query.addNewItem,
              historyFilters: route.query.historyFilters
            })
          },
          {
            path: ':id/edit',
            component: PropertyEditingView,
            name: 'OwnerPropertyEditingView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              addNewItem: route.query.addNewItem,
              addNewItemLandRegister: route.query.addNewItemLandRegister,
              historyFilters: route.query.historyFilters
            })
          }
        ]
      },
      {
        path: 'buildings',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: OwnerBuildingsView,
            name: 'OwnerBuildingsView'
          },
          {
            path: 'map',
            component: BuildingsMapView,
            name: 'OwnerBuildingsMapView',
            props: (route) => ({
              view: route.query.view,
              elementSelected: route.query.elementSelected
            })
          },
          {
            path: ':id',
            component: BuildingDetailsView,
            name: 'OwnerBuildingDetailsView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              searchStatusObject: route.query.searchStatusObject,
              from: route.query.from,
              objectFilters: route.query.objectFilters,
              tenantFilters: route.query.tenantFilters,
              condominiumFilters: route.query.condominiumFilters,
              deviceFilters: route.query.deviceFilters,
              historyFilters: route.query.historyFilters
            })
          },
          {
            path: ':id/onboarding',
            component: OnboardingView,
            name: 'OwnerOnboardingView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              objectFilters: route.query.objectFilters,
              tenantFilters: route.query.tenantFilters,
              condominiumFilters: route.query.condominiumFilters,
              deviceFilters: route.query.deviceFilters,
              historyFilters: route.query.historyFilters,
              addNewItemDevice: route.query.addNewItemDevice
            })
          }
        ]
      },
      {
        path: 'objects',
        component: OwnerRentalObjectOverview,
        name: 'OwnerRentalObjectOverview'
      },
      {
        path: 'condominium-object',
        component: OwnerCondominiumObjectOverview,
        name: 'OwnerCondominiumObjectOverview'
      },
      {
        path: 'tenancies',
        component: OwnerTenanciesView,
        name: 'OwnerTenanciesView',
        props: (route) => ({
          query: route.query.query
        })
      },
      {
        path: 'condominium-overview',
        component: CondominiumOverview,
        name: 'OwnerCondominiumOverview'
      },
      {
        path: 'create-property',
        component: OwnerCreatePropertyView,
        name: 'OwnerCreatePropertyView'
      },
      {
        path: 'network/partners',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: OwnerNetworkPartnerView,
            name: 'OwnerNetworkPartnerView',
            props: (route) => ({
              defaultCanton: route.query.defaultCanton,
              defaultType: route.query.defaultType,
              defaultCompany: route.query.defaultCompany,
              defaultContactPerson: route.query.defaultContactPerson
            })
          },
          {
            path: ':id',
            component: OwnerNetworkPartnerDetailsView,
            name: 'OwnerNetworkPartnerDetailsView',
            props: true
          }
        ]
      }
    ]
  },
  {
    path: '/admin',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: 'tickets',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: TicketOverview,
            name: 'AdminTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              defaultNewEvent: route.query.defaultNewEvent,
              nameLinked: route.query.nameLinked,
              linkEmail: route.query.linkEmail,
              ownerIds: route.query.ownerIds,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'closed',
            component: ClosedTicketOverview,
            name: 'AdminClosedTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'draft',
            component: DraftTicketOverview,
            name: 'AdminDraftTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'create',
            component: TicketCreation,
            name: 'AdminTicketCreation',
            props: (route) => ({
              fromBulk: route.query.fromBulk,
              ids: route.query.ids,
              defaultAssignee: route.query.defaultAssignee,
              ticketType: route.query.ticketType,
              emailsData: route.query.emailsData,
              buildingIds: route.query.buildingIds,
              propertyIds: route.query.propertyIds,
              objectIds: route.query.objectIds,
              tenancyIds: route.query.tenancyIds,
              condoOwnerIds: route.query.condoOwnerIds,
              contactIds: route.query.contactIds
            })
          },
          {
            path: 'edit/:id',
            component: TicketCreation,
            name: 'AdminTicketEdition',
            props: (route) => ({
              id: route.params.id,
              source: route.query.source
            })
          },
          {
            path: ':id',
            component: TicketDetailsView,
            name: 'AdminTicketDetailsView',
            props: (route) => ({
              id: route.params.id
            })
          },
          {
            path: 'activitiesTicket/:id',
            component: TicketsAndActivitiesView,
            name: 'AdminActivitiesTicketView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              othersIds: route.query.othersIds,
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultPriority: route.query.defaultPriority,
              defaultStatus: route.query.defaultStatus,
              defaultDevice: route.query.defaultDevice
            })
          }
        ]
      },
      {
        path: 'properties',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: AdminPropertyView,
            name: 'AdminPropertyView'
          },
          {
            path: ':id',
            component: PropertyDetailsView,
            name: 'AdminPropertyDetailsView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              addNewItem: route.query.addNewItem,
              historyFilters: route.query.historyFilters
            })
          },
          {
            path: ':id/edit',
            component: PropertyEditingView,
            name: 'AdminPropertyEditingView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              addNewItem: route.query.addNewItem,
              addNewItemLandRegister: route.query.addNewItemLandRegister,
              historyFilters: route.query.historyFilters
            })
          }
        ]
      },
      {
        path: 'buildings',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: AdminBuildingsView,
            name: 'AdminBuildingsView'
          },
          {
            path: 'map',
            component: BuildingsMapView,
            name: 'AdminBuildingsMapView',
            props: (route) => ({
              view: route.query.view,
              elementSelected: route.query.elementSelected
            })
          },
          {
            path: ':id',
            component: BuildingDetailsView,
            name: 'AdminBuildingDetailsView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              objectFilters: route.query.objectFilters,
              tenantFilters: route.query.tenantFilters,
              condominiumFilters: route.query.condominiumFilters,
              deviceFilters: route.query.deviceFilters,
              historyFilters: route.query.historyFilters
            })
          },
          {
            path: ':id/onboarding',
            component: OnboardingView,
            name: 'AdminOnboardingView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              objectFilters: route.query.objectFilters,
              tenantFilters: route.query.tenantFilters,
              condominiumFilters: route.query.condominiumFilters,
              deviceFilters: route.query.deviceFilters,
              historyFilters: route.query.historyFilters,
              addNewItemDevice: route.query.addNewItemDevice
            })
          }
        ]
      },
      {
        path: 'objects',
        component: AdminRentalObjectOverview,
        name: 'AdminRentalObjectOverview'
      },
      {
        path: 'condominium-object',
        component: AdminCondominiumObjectOverview,
        name: 'AdminCondominiumObjectOverview'
      },
      {
        path: 'tenancies',
        component: AdminTenanciesView,
        name: 'AdminTenanciesView',
        props: (route) => ({
          query: route.query.query
        })
      },
      {
        path: 'condominium-overview',
        component: CondominiumOverview,
        name: 'AdminCondominiumOverview'
      },
      {
        path: 'objects/:id',
        component: MarketingObjectDetailsView,
        name: 'AdminObjectDetailsView',
        props: (route) => ({
          id: route.params.id,
          view: route.query.view
        })
      },
      {
        path: 'object/marketing',
        component: AdminMarketingObjectsView,
        name: 'AdminMarketingObjectsView'
      }
    ]
  },
  {
    path: '/accounting',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: 'tickets',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: TicketOverview,
            name: 'AccountingTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              defaultNewEvent: route.query.defaultNewEvent,
              nameLinked: route.query.nameLinked,
              linkEmail: route.query.linkEmail,
              ownerIds: route.query.ownerIds,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'closed',
            component: ClosedTicketOverview,
            name: 'AccountingClosedTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'draft',
            component: DraftTicketOverview,
            name: 'AccountingDraftTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'create',
            component: TicketCreation,
            name: 'AccountingTicketCreation',
            props: (route) => ({
              fromBulk: route.query.fromBulk,
              ids: route.query.ids,
              defaultAssignee: route.query.defaultAssignee,
              ticketType: route.query.ticketType,
              emailsData: route.query.emailsData,
              buildingIds: route.query.buildingIds,
              propertyIds: route.query.propertyIds,
              objectIds: route.query.objectIds,
              tenancyIds: route.query.tenancyIds,
              condoOwnerIds: route.query.condoOwnerIds,
              contactIds: route.query.contactIds
            })
          },
          {
            path: 'edit/:id',
            component: TicketCreation,
            name: 'AccountingTicketEdition',
            props: (route) => ({
              id: route.params.id,
              source: route.query.source
            })
          },
          {
            path: ':id',
            component: TicketDetailsView,
            name: 'AccountingTicketDetailsView',
            props: (route) => ({
              id: route.params.id
            })
          },
          {
            path: 'activitiesTicket/:id',
            component: TicketsAndActivitiesView,
            name: 'AccountingActivitiesTicketView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              othersIds: route.query.othersIds,
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultPriority: route.query.defaultPriority,
              defaultStatus: route.query.defaultStatus,
              defaultDevice: route.query.defaultDevice
            })
          }
        ]
      },
      {
        path: 'properties',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: AccountingPropertyView,
            name: 'AccountingPropertyView'
          },
          {
            path: ':id',
            component: PropertyDetailsView,
            name: 'AccountingPropertyDetailsView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              addNewItem: route.query.addNewItem,
              historyFilters: route.query.historyFilters
            })
          },
          {
            path: ':id/edit',
            component: PropertyEditingView,
            name: 'AccountingPropertyEditingView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              addNewItem: route.query.addNewItem,
              addNewItemLandRegister: route.query.addNewItemLandRegister,
              historyFilters: route.query.historyFilters
            })
          }
        ]
      },
      {
        path: 'buildings',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: AccountingBuildingsView,
            name: 'AccountingBuildingsView'
          },
          {
            path: 'map',
            component: BuildingsMapView,
            name: 'AccountingBuildingsMapView',
            props: (route) => ({
              view: route.query.view,
              elementSelected: route.query.elementSelected
            })
          },
          {
            path: ':id',
            component: BuildingDetailsView,
            name: 'AccountingBuildingDetailsView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              objectFilters: route.query.objectFilters,
              tenantFilters: route.query.tenantFilters,
              condominiumFilters: route.query.condominiumFilters,
              deviceFilters: route.query.deviceFilters,
              historyFilters: route.query.historyFilters
            })
          },
          {
            path: ':id/onboarding',
            component: OnboardingView,
            name: 'AccountingOnboardingView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              objectFilters: route.query.objectFilters,
              tenantFilters: route.query.tenantFilters,
              condominiumFilters: route.query.condominiumFilters,
              deviceFilters: route.query.deviceFilters,
              historyFilters: route.query.historyFilters,
              addNewItemDevice: route.query.addNewItemDevice
            })
          }
        ]
      },
      {
        path: 'objects',
        component: AccountingRentalObjectOverview,
        name: 'AccountingRentalObjectOverview'
      },
      {
        path: 'condominium-object',
        component: AccountingCondominiumObjectOverview,
        name: 'AccountingCondominiumObjectOverview'
      },
      {
        path: 'tenancies',
        component: AccountingTenanciesView,
        name: 'AccountingTenanciesView',
        props: (route) => ({
          query: route.query.query
        })
      },
      {
        path: 'condominium-overview',
        component: AccountingCondominiumOverview,
        name: 'AccountingCondominiumOverview'
      },
      {
        path: 'objects/:id',
        component: MarketingObjectDetailsView,
        name: 'AccountingObjectDetailsView',
        props: (route) => ({
          id: route.params.id,
          view: route.query.view
        })
      },
      {
        path: 'object/marketing',
        component: AccountingMarketingObjectsView,
        name: 'AccountingMarketingObjectsView'
      }
    ]
  },
  {
    path: '/asset',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: 'tickets',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: TicketOverview,
            name: 'AssetTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              defaultNewEvent: route.query.defaultNewEvent,
              nameLinked: route.query.nameLinked,
              linkEmail: route.query.linkEmail,
              ownerIds: route.query.ownerIds,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'closed',
            component: ClosedTicketOverview,
            name: 'AssetClosedTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'draft',
            component: DraftTicketOverview,
            name: 'AssetDraftTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'create',
            component: TicketCreation,
            name: 'AssetTicketCreation',
            props: (route) => ({
              fromBulk: route.query.fromBulk,
              ids: route.query.ids,
              defaultAssignee: route.query.defaultAssignee,
              ticketType: route.query.ticketType,
              emailsData: route.query.emailsData,
              buildingIds: route.query.buildingIds,
              propertyIds: route.query.propertyIds,
              objectIds: route.query.objectIds,
              tenancyIds: route.query.tenancyIds,
              condoOwnerIds: route.query.condoOwnerIds,
              contactIds: route.query.contactIds
            })
          },
          {
            path: 'edit/:id',
            component: TicketCreation,
            name: 'AssetTicketEdition',
            props: (route) => ({
              id: route.params.id,
              source: route.query.source
            })
          },
          {
            path: ':id',
            component: TicketDetailsView,
            name: 'AssetTicketDetailsView',
            props: (route) => ({
              id: route.params.id
            })
          },
          {
            path: 'activitiesTicket/:id',
            component: TicketsAndActivitiesView,
            name: 'AssetActivitiesTicketView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              othersIds: route.query.othersIds,
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultPriority: route.query.defaultPriority,
              defaultStatus: route.query.defaultStatus,
              defaultDevice: route.query.defaultDevice
            })
          }
        ]
      },
      {
        path: 'properties',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: AssetPropertyView,
            name: 'AssetPropertyView'
          },
          {
            path: ':id',
            component: PropertyDetailsView,
            name: 'AssetPropertyDetailsView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              addNewItem: route.query.addNewItem,
              historyFilters: route.query.historyFilters
            })
          },
          {
            path: ':id/edit',
            component: PropertyEditingView,
            name: 'AssetPropertyEditingView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              addNewItem: route.query.addNewItem,
              addNewItemLandRegister: route.query.addNewItemLandRegister,
              historyFilters: route.query.historyFilters
            })
          }
        ]
      },
      {
        path: 'buildings',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: AssetBuildingsView,
            name: 'AssetBuildingsView'
          },
          {
            path: 'map',
            component: BuildingsMapView,
            name: 'AssetBuildingsMapView',
            props: (route) => ({
              view: route.query.view,
              elementSelected: route.query.elementSelected
            })
          },
          {
            path: ':id',
            component: BuildingDetailsView,
            name: 'AssetBuildingDetailsView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              objectFilters: route.query.objectFilters,
              tenantFilters: route.query.tenantFilters,
              condominiumFilters: route.query.condominiumFilters,
              deviceFilters: route.query.deviceFilters,
              historyFilters: route.query.historyFilters
            })
          },
          {
            path: ':id/onboarding',
            component: OnboardingView,
            name: 'AssetOnboardingView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              elementSelected: route.query.elementSelected,
              objectFilters: route.query.objectFilters,
              tenantFilters: route.query.tenantFilters,
              condominiumFilters: route.query.condominiumFilters,
              deviceFilters: route.query.deviceFilters,
              historyFilters: route.query.historyFilters,
              addNewItemDevice: route.query.addNewItemDevice
            })
          }
        ]
      },
      {
        path: 'objects',
        component: AssetRentalObjectOverview,
        name: 'AssetRentalObjectOverview'
      },
      {
        path: 'condominium-object',
        component: AssetCondominiumObjectOverview,
        name: 'AssetCondominiumObjectOverview'
      },
      {
        path: 'tenancies',
        component: AssetTenanciesView,
        name: 'AssetTenanciesView',
        props: (route) => ({
          query: route.query.query
        })
      },
      {
        path: 'condominium-overview',
        component: CondominiumOverview,
        name: 'AssetCondominiumOverview'
      },
      {
        path: 'objects/:id',
        component: MarketingObjectDetailsView,
        name: 'AssetObjectDetailsView',
        props: (route) => ({
          id: route.params.id,
          view: route.query.view
        })
      },
      {
        path: 'network/partners',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: AssetNetworkPartnerView,
            name: 'AssetNetworkPartnerView',
            props: (route) => ({
              defaultCanton: route.query.defaultCanton,
              defaultType: route.query.defaultType,
              defaultCompany: route.query.defaultCompany,
              defaultContactPerson: route.query.defaultContactPerson
            })
          },
          {
            path: ':id',
            component: AssetNetworkPartnerDetailsView,
            name: 'AssetNetworkPartnerDetailsView',
            props: true
          }
        ]
      }
    ]
  },
  {
    path: '/ticket/:id',
    component: TicketPublicView,
    name: 'TicketPublicView',
    props: (route) => ({
      id: route.params.id,
      email: route.query.email
    })
  },
  {
    path: '/owner/reminders',
    component: RemindersView,
    name: 'OwnerRemindersView'
  },
  {
    path: '/marketing/reminders',
    component: RemindersView,
    name: 'MarketingRemindersView'
  },
  {
    path: '/admin/reminders',
    component: RemindersView,
    name: 'AdminRemindersView'
  },
  {
    path: '/accounting/reminders',
    component: RemindersView,
    name: 'AccountingRemindersView'
  },
  {
    path: '/asset/reminders',
    component: RemindersView,
    name: 'AssetRemindersView'
  },
  {
    path: '/owner/calendar',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: ':viewCalendar',
        component: CalendarComponents.CalendarOverview,
        name: 'OwnerCalendarOverview',
        props: (route) => ({
          calendars: route.query.calendars,
          viewCalendar: route.params.viewCalendar,
          dayToFocus: route.params.dayToFocus,
          referenceIds: route.query.referenceIds,
          elementSelected: route.query.elementSelected,
          startDate: route.query.startDate,
          fromPage: route.query.fromPage
        })
      },
      {
        path: 'export',
        component: CalendarComponents.CalendarExportView,
        name: 'OwnerCalendarExport'
      }
    ]
  },
  {
    path: '/marketing/calendar',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: ':viewCalendar',
        component: CalendarComponents.CalendarOverview,
        name: 'MarketingCalendarOverview',
        props: (route) => ({
          calendars: route.query.calendars,
          viewCalendar: route.params.viewCalendar,
          dayToFocus: route.params.dayToFocus,
          referenceIds: route.query.referenceIds,
          objectId: route.query.objectId,
          elementSelected: route.query.elementSelected,
          startDate: route.query.startDate,
          fromPage: route.query.fromPage
        })
      },
      {
        path: 'export',
        component: CalendarComponents.CalendarExportView,
        name: 'CalendarExport'
      }
    ]
  },
  {
    path: '/admin/calendar',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: ':viewCalendar',
        component: CalendarComponents.CalendarOverview,
        name: 'AdminCalendarOverview',
        props: (route) => ({
          calendars: route.query.calendars,
          viewCalendar: route.params.viewCalendar,
          dayToFocus: route.params.dayToFocus,
          referenceIds: route.query.referenceIds,
          elementSelected: route.query.elementSelected,
          startDate: route.query.startDate,
          fromPage: route.query.fromPage
        })
      },
      {
        path: 'export',
        component: CalendarComponents.CalendarExportView,
        name: 'AdminCalendarExport'
      }
    ]
  },
  {
    path: '/asset/calendar',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: ':viewCalendar',
        component: CalendarComponents.CalendarOverview,
        name: 'AssetCalendarOverview',
        props: (route) => ({
          calendars: route.query.calendars,
          viewCalendar: route.params.viewCalendar,
          dayToFocus: route.params.dayToFocus,
          referenceIds: route.query.referenceIds,
          elementSelected: route.query.elementSelected,
          startDate: route.query.startDate,
          fromPage: route.query.fromPage
        })
      },
      {
        path: 'export',
        component: CalendarComponents.CalendarExportView,
        name: 'AssetCalendarExport'
      }
    ]
  },
  {
    path: '/accounting/calendar',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: ':viewCalendar',
        component: CalendarComponents.CalendarOverview,
        name: 'AccountingCalendarOverview',
        props: (route) => ({
          calendars: route.query.calendars,
          viewCalendar: route.params.viewCalendar,
          dayToFocus: route.params.dayToFocus,
          referenceIds: route.query.referenceIds,
          elementSelected: route.query.elementSelected,
          startDate: route.query.startDate,
          fromPage: route.query.fromPage
        })
      },
      {
        path: 'export',
        component: CalendarComponents.CalendarExportView,
        name: 'AccountingCalendarExport'
      }
    ]
  },
  {
    path: '/owner/settings',
    component: SettingsView,
    name: 'OwnerSettingsView',
    props: (route) => ({
      view: route.query.view
    })
  },
  {
    path: '/marketing/settings',
    component: SettingsView,
    name: 'MarketingSettingsView',
    props: (route) => ({
      view: route.query.view
    })
  },
  {
    path: '/admin/settings',
    component: SettingsView,
    name: 'AdminSettingsView',
    props: (route) => ({
      view: route.query.view
    })
  },
  {
    path: '/asset/settings',
    component: SettingsView,
    name: 'AssetSettingsView',
    props: (route) => ({
      view: route.query.view
    })
  },
  {
    path: '/accounting/settings',
    component: SettingsView,
    name: 'AccountingSettingsView',
    props: (route) => ({
      view: route.query.view
    })
  },
  {
    path: '/marketing/objects',
    component: MarketingObjectsView,
    name: 'MarketingObjectsView',
    props: (route) => ({
      linkRequest: route.query.linkRequest,
      linkMail: route.query.linkMail,
      linkEmail: route.query.linkEmail,
      originalObjectId: route.query.originalObjectId
    })
  },
  {
    path: '/accounting/object/:objectId/addVacancy',
    component: AddVacancyView,
    name: 'AccountingAddVacancyView',
    props: (route) => ({
      objectId: route.params.objectId
    })
  },
  {
    path: '/admin/object/:objectId/addVacancy',
    component: AddVacancyView,
    name: 'AdminAddVacancyView',
    props: (route) => ({
      objectId: route.params.objectId
    })
  },
  {
    path: '/accounting/object/:objectId/addTenancy',
    component: AddTenancyView,
    name: 'AccountingAddTenancyView',
    props: (route) => ({
      objectId: route.params.objectId,
      contractualPartnerId: route.query.contractualPartnerId,
      requestId: route.query.requestId,
      tenancyId: route.query.tenancyId
    })
  },
  {
    path: '/asset/object/:objectId/addTenancy',
    component: AddTenancyView,
    name: 'AssetAddTenancyView',
    props: (route) => ({
      objectId: route.params.objectId,
      contractualPartnerId: route.query.contractualPartnerId,
      requestId: route.query.requestId,
      tenancyId: route.query.tenancyId
    })
  },
  {
    path: '/admin/object/:objectId/addTenancy',
    component: AddTenancyView,
    name: 'AdminAddTenancyView',
    props: (route) => ({
      objectId: route.params.objectId,
      contractualPartnerId: route.query.contractualPartnerId,
      requestId: route.query.requestId,
      tenancyId: route.query.tenancyId
    })
  },
  {
    path: '/marketing/object/:objectId/addTenancy',
    component: AddTenancyView,
    name: 'MarketingAddTenancyView',
    props: (route) => ({
      objectId: route.params.objectId,
      contractualPartnerId: route.query.contractualPartnerId,
      requestId: route.query.requestId,
      tenancyId: route.query.tenancyId
    })
  },
  {
    path: '/admin/serialLetter',
    component: SerialLetterProcessView,
    name: 'AdminSerialLetterProcessView',
    props: (route) => ({
      source: route.query.source,
      uuid: route.query.uuid,
      previousRoute: route.query.previousRoute
    })
  },
  {
    path: '/asset/serialLetter',
    component: SerialLetterProcessView,
    name: 'AssetSerialLetterProcessView',
    props: (route) => ({
      source: route.query.source,
      uuid: route.query.uuid,
      previousRoute: route.query.previousRoute
    })
  },
  {
    path: '/marketing/serialLetter',
    component: SerialLetterProcessView,
    name: 'MarketingSerialLetterProcessView',
    props: (route) => ({
      source: route.query.source,
      uuid: route.query.uuid,
      previousRoute: route.query.previousRoute
    })
  },
  {
    path: '/owner/serialLetter',
    component: SerialLetterProcessView,
    name: 'OwnerSerialLetterProcessView',
    props: (route) => ({
      source: route.query.source,
      uuid: route.query.uuid,
      previousRoute: route.query.previousRoute
    })
  },
  {
    path: '/accounting/serialLetter',
    component: SerialLetterProcessView,
    name: 'AccountingSerialLetterProcessView',
    props: (route) => ({
      source: route.query.source,
      uuid: route.query.uuid,
      previousRoute: route.query.previousRoute
    })
  },
  {
    path: '/owner/createOrder',
    component: CreateOrderView,
    name: 'OwnerCreateOrderView',
    props: (route) => ({
      buildingIds: route.query.buildingIds,
      deviceIds: route.query.deviceIds,
      propertyIds: route.query.propertyIds,
      objectIds: route.query.objectIds,
      tenancyIds: route.query.tenancyIds,
      condoOwnerIds: route.query.condoOwnerIds,
      mediaIds: route.query.mediaIds,
      roomId: route.query.roomId,
      contactIds: route.query.contactIds,
      source: route.query.source,
      linkedTicketId: route.query.linkedTicketId,
      emailsData: route.query.emailsData
    })
  },
  {
    path: '/admin/createOrder',
    component: CreateOrderView,
    name: 'AdminCreateOrderView',
    props: (route) => ({
      buildingIds: route.query.buildingIds,
      deviceIds: route.query.deviceIds,
      propertyIds: route.query.propertyIds,
      objectIds: route.query.objectIds,
      tenancyIds: route.query.tenancyIds,
      condoOwnerIds: route.query.condoOwnerIds,
      mediaIds: route.query.mediaIds,
      roomId: route.query.roomId,
      contactIds: route.query.contactIds,
      source: route.query.source,
      linkedTicketId: route.query.linkedTicketId,
      emailsData: route.query.emailsData
    })
  },
  {
    path: '/accounting/createOrder',
    component: CreateOrderView,
    name: 'AccountingCreateOrderView',
    props: (route) => ({
      buildingIds: route.query.buildingIds,
      deviceIds: route.query.deviceIds,
      propertyIds: route.query.propertyIds,
      objectIds: route.query.objectIds,
      tenancyIds: route.query.tenancyIds,
      condoOwnerIds: route.query.condoOwnerIds,
      mediaIds: route.query.mediaIds,
      roomId: route.query.roomId,
      contactIds: route.query.contactIds,
      source: route.query.source,
      linkedTicketId: route.query.linkedTicketId,
      emailsData: route.query.emailsData
    })
  },
  {
    path: '/asset/createOrder',
    component: CreateOrderView,
    name: 'AssetCreateOrderView',
    props: (route) => ({
      buildingIds: route.query.buildingIds,
      deviceIds: route.query.deviceIds,
      propertyIds: route.query.propertyIds,
      objectIds: route.query.objectIds,
      tenancyIds: route.query.tenancyIds,
      condoOwnerIds: route.query.condoOwnerIds,
      mediaIds: route.query.mediaIds,
      roomId: route.query.roomId,
      contactIds: route.query.contactIds,
      source: route.query.source,
      linkedTicketId: route.query.linkedTicketId,
      emailsData: route.query.emailsData
    })
  },
  {
    path: '/marketing/createOrder',
    component: CreateOrderView,
    name: 'MarketingCreateOrderView',
    props: (route) => ({
      buildingIds: route.params.buildingIds,
      deviceIds: route.query.deviceIds,
      propertyIds: route.query.propertyIds,
      objectIds: route.query.objectIds,
      tenancyIds: route.query.tenancyIds,
      condoOwnerIds: route.query.condoOwnerIds,
      mediaIds: route.query.mediaIds,
      roomId: route.query.roomId,
      contactIds: route.query.contactIds,
      source: route.query.source,
      linkedTicketId: route.query.linkedTicketId
    })
  },
  {
    path: '/admin/massRental',
    component: createMassRentalView,
    name: 'AdminCreateMassRentalView',
    props: (route) => ({
      source: route.query.source,
      buildingIds: route.query.buildingIds,
      tenantIds: route.query.tenantIds
    })
  },
  {
    path: '/accounting/massRental',
    component: createMassRentalView,
    name: 'AccountingCreateMassRentalView',
    props: (route) => ({
      source: route.query.source,
      buildingIds: route.query.buildingIds,
      tenantIds: route.query.tenantIds
    })
  },
  {
    path: '/asset/massRental',
    component: createMassRentalView,
    name: 'AssetCreateMassRentalView',
    props: (route) => ({
      source: route.query.source,
      buildingIds: route.query.buildingIds,
      tenantIds: route.query.tenantIds
    })
  },
  {
    path: '/owner/massRental',
    component: createMassRentalView,
    name: 'OwnerCreateMassRentalView',
    props: (route) => ({
      source: route.query.source,
      buildingIds: route.query.buildingIds,
      tenantIds: route.query.tenantIds
    })
  },
  {
    path: '/marketing/massRental',
    component: createMassRentalView,
    name: 'MarketingCreateMassRentalView',
    props: (route) => ({
      source: route.query.source,
      buildingIds: route.query.buildingIds,
      tenantIds: route.query.tenantIds,
      requestId: route.query.requestId,
      requestObjectId: route.query.requestObjectId
    })
  },
  {
    path: '/admin/object/:objectId/activateTenancy',
    component: ActivateTenancy,
    name: 'AdminActivateTenancy',
    props: (route) => ({
      objectId: route.params.objectId,
      requestId: route.query.requestId,
      tenancyId: route.query.tenancyId,
      readMode: route.query.readMode
    })
  },
  {
    path: '/accounting/object/:objectId/activateTenancy',
    component: ActivateTenancy,
    name: 'AccountingActivateTenancy',
    props: (route) => ({
      objectId: route.params.objectId,
      requestId: route.query.requestId,
      tenancyId: route.query.tenancyId,
      readMode: route.query.readMode
    })
  },
  {
    path: '/asset/object/:objectId/activateTenancy',
    component: ActivateTenancy,
    name: 'AssetActivateTenancy',
    props: (route) => ({
      objectId: route.params.objectId,
      requestId: route.query.requestId,
      tenancyId: route.query.tenancyId,
      readMode: route.query.readMode
    })
  },
  {
    path: '/marketing/object/:objectId/activateTenancy',
    component: ActivateTenancy,
    name: 'MarketingActivateTenancy',
    props: (route) => ({
      objectId: route.params.objectId,
      requestId: route.query.requestId,
      tenancyId: route.query.tenancyId
    })
  },
  {
    path: '/admin/object/:objectId/enterTermination',
    component: EnterTerminationView,
    name: 'AdminEnterTerminationView',
    props: (route) => ({
      contractualPartnerId: route.query.contractualPartnerId,
      requestId: route.query.requestId,
      tenancyId: route.query.tenancyId,
      objectId: route.params.objectId,
      buildingId: route.params.buildingId
    })
  },
  {
    path: '/accounting/object/:objectId/enterTermination',
    component: EnterTerminationView,
    name: 'AccountingEnterTerminationView',
    props: (route) => ({
      contractualPartnerId: route.query.contractualPartnerId,
      requestId: route.query.requestId,
      tenancyId: route.query.tenancyId,
      objectId: route.params.objectId,
      buildingId: route.params.buildingId
    })
  },
  {
    path: '/marketing/objects/:id',
    component: MarketingObjectDetailsView,
    name: 'MarketingObjectDetailsView',
    props: (route) => ({
      id: route.params.id,
      view: route.query.view,
      elementSelected: route.query.elementSelected,
      filter: route.query.filter,
      filterProgress: route.query.filterProgress,
      filterMarketPlaceName: route.query.filterMarketPlaceName,
      isTenancyActivated: route.query.isTenancyActivated,
      linkRequest: route.query.linkRequest,
      linkMail: route.query.linkMail,
      linkEmail: route.query.linkEmail
    })
  },
  {
    path: '/marketing/objects/:id/edit',
    component: MarketingEditAdvertView,
    name: 'MarketingEditAdvertView',
    props: (route) => ({
      id: route.params.id
    })
  },
  {
    path: '/admin/object/marketing/mark/:id',
    component: MarkForMarketingView,
    name: 'AdminMarkForMarketingView',
    props: (route) => ({
      id: route.params.id,
      from: route.params.from,
      editMarketingInfoMode: route.query.editMarketingInfoMode
    })
  },
  {
    path: '/accounting/object/marketing/mark/:id',
    component: MarkForMarketingView,
    name: 'AccountingMarkForMarketingView',
    props: (route) => ({
      id: route.params.id,
      from: route.params.from,
      editMarketingInfoMode: route.query.editMarketingInfoMode
    })
  },
  {
    path: '/owner/messages',
    component: MessagesView,
    name: 'OwnerMessagesView',
    props: (route) => ({
      s: route.query.s,
      f: route.query.f,
      m: route.query.m
    })
  },
  {
    path: '/owner/messages/draft',
    component: MessagesDraftView,
    name: 'OwnerDraftMessagesView',
    props: (route) => ({
      s: route.query.s,
      f: route.query.f,
      m: route.query.m
    })
  },
  {
    path: '/marketing/messages',
    component: MessagesView,
    name: 'MarketingMessagesView',
    props: (route) => ({
      s: route.query.s,
      f: route.query.f,
      m: route.query.m
    })
  },
  {
    path: '/marketing/messages/draft',
    component: MessagesDraftView,
    name: 'MarketingDraftMessagesView',
    props: (route) => ({
      s: route.query.s,
      f: route.query.f,
      m: route.query.m
    })
  },
  {
    path: '/admin/messages',
    component: MessagesView,
    name: 'AdminMessagesView',
    props: (route) => ({
      s: route.query.s,
      f: route.query.f,
      m: route.query.m
    })
  },
  {
    path: '/admin/messages/draft',
    component: MessagesDraftView,
    name: 'AdminDraftMessagesView',
    props: (route) => ({
      s: route.query.s,
      f: route.query.f,
      m: route.query.m
    })
  },
  {
    path: '/asset/messages',
    component: MessagesView,
    name: 'AssetMessagesView',
    props: (route) => ({
      s: route.query.s,
      f: route.query.f,
      m: route.query.m
    })
  },
  {
    path: '/asset/messages/draft',
    component: MessagesDraftView,
    name: 'AssetDraftMessagesView',
    props: (route) => ({
      s: route.query.s,
      f: route.query.f,
      m: route.query.m
    })
  },
  {
    path: '/accounting/messages',
    component: MessagesView,
    name: 'AccountingMessagesView'
  },
  {
    path: '/accounting/messages/draft',
    component: MessagesDraftView,
    name: 'AccountingDraftMessagesView',
    props: (route) => ({
      s: route.query.s,
      f: route.query.f,
      m: route.query.m
    })
  },
  // Static pages
  {
    path: '/help',
    component: HelpView,
    name: 'Help',
    props: (route) => ({
      language: route.query.language
    })
  },
  {
    path: '/about-us',
    component: AboutUsView,
    name: 'AboutUs',
    props: (route) => ({
      language: route.query.language
    })
  },
  {
    path: '/tos',
    component: TosView,
    name: 'ToS',
    props: (route) => ({
      language: route.query.language
    })
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicyView,
    name: 'PrivacyPolicy',
    props: (route) => ({
      language: route.query.language
    })
  },
  {
    path: '/imprint',
    component: ImprintView,
    name: 'Imprint',
    props: (route) => ({
      language: route.query.language
    })
  },
  {
    path: '/cookies',
    component: CookiesView,
    name: 'Cookies',
    props: (route) => ({
      language: route.query.language
    })
  },
  {
    path: '/admin/generateExcel',
    component: GenerateExcelView,
    name: 'AdminGenerateExcel',
    props: (route) => ({
      buildingIds: route.params.buildingIds,
      propertyIds: route.params.propertyIds
    })
  },
  {
    path: '/owner/generateExcel',
    component: GenerateExcelView,
    name: 'OwnerGenerateExcel',
    props: (route) => ({
      buildingIds: route.params.buildingIds,
      propertyIds: route.params.propertyIds
    })
  },
  {
    path: '/asset/generateExcel',
    component: GenerateExcelView,
    name: 'AssetGenerateExcel',
    props: (route) => ({
      buildingIds: route.params.buildingIds,
      propertyIds: route.params.propertyIds
    })
  },
  {
    path: '/accounting/generateExcel',
    component: GenerateExcelView,
    name: 'AccountingGenerateExcel',
    props: (route) => ({
      buildingIds: route.params.buildingIds,
      propertyIds: route.params.propertyIds
    })
  }
]

routes.push(
  {
    path: '/owner',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: 'contacts',
        component: ContactOverview,
        name: 'OwnerContactOverview',
        props: (route) => ({
          linkContact: route.query.linkContact,
          linkContactType: route.query.linkContactType,
          linkMail: route.query.linkMail,
          email: route.query.email
        })
      },
      {
        path: 'briefButler',
        component: BriefButlerOverview,
        name: 'OwnerBriefButlerOverview'
      },
      {
        path: 'deepSign',
        component: DeepSignOverview,
        name: 'OwnerDeepSignOverview'
      },
      {
        path: 'contacts/:id',
        component: ContactDetailsView,
        name: 'OwnerContactDetailsView',
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
  {
    path: '/asset',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: 'contacts',
        component: ContactOverview,
        name: 'AssetContactOverview',
        props: (route) => ({
          linkContact: route.query.linkContact,
          linkContactType: route.query.linkContactType,
          linkMail: route.query.linkMail,
          email: route.query.email
        })
      },
      {
        path: 'briefButler',
        component: BriefButlerOverview,
        name: 'AssetBriefButlerOverview'
      },
      {
        path: 'deepSign',
        component: DeepSignOverview,
        name: 'AssetDeepSignOverview'
      },
      {
        path: 'contacts/:id',
        component: ContactDetailsView,
        name: 'AssetContactDetailsView',
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
  {
    path: '/marketing',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: 'tickets',
        component: CoozzyRouterViewWrapper,
        children: [
          {
            path: '',
            component: TicketOverview,
            name: 'MarketingTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              defaultNewEvent: route.query.defaultNewEvent,
              nameLinked: route.query.nameLinked,
              linkEmail: route.query.linkEmail,
              ownerIds: route.query.ownerIds,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'closed',
            component: ClosedTicketOverview,
            name: 'MarketingClosedTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'draft',
            component: DraftTicketOverview,
            name: 'MarketingDraftTicketOverview',
            props: (route) => ({
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultOperator: route.query.defaultOperator,
              defaultPriority: route.query.defaultPriority,
              defaultOverdue: route.query.defaultOverdue,
              defaultWatched: route.query.defaultWatched,
              referenceIds: route.query.referenceIds,
              defaultEmptyAssignee: route.query.defaultEmptyAssignee
            })
          },
          {
            path: 'create',
            component: TicketCreation,
            name: 'MarketingTicketCreation',
            props: (route) => ({
              fromBulk: route.query.fromBulk,
              ids: route.query.ids,
              defaultAssignee: route.query.defaultAssignee,
              ticketPriority: route.query.ticketPriority,
              requestsData: route.query.requestsData,
              buildingIds: route.query.buildingIds,
              propertyIds: route.query.propertyIds,
              objectIds: route.query.objectIds,
              tenancyIds: route.query.tenancyIds,
              condoOwnerIds: route.query.condoOwnerIds,
              contactIds: route.query.contactIds
            })
          },
          {
            path: 'edit/:id',
            component: TicketCreation,
            name: 'MarketingTicketEdition',
            props: (route) => ({
              id: route.params.id,
              source: route.query.source
            })
          },
          {
            path: ':id',
            component: TicketDetailsView,
            name: 'MarketingTicketDetailsView',
            props: (route) => ({
              id: route.params.id
            })
          },
          {
            path: 'activitiesTicket/:id',
            component: TicketsAndActivitiesView,
            name: 'MarketingActivitiesTicketView',
            props: (route) => ({
              id: route.params.id,
              view: route.query.view,
              othersIds: route.query.othersIds,
              defaultText: route.query.defaultText,
              defaultType: route.query.defaultType,
              defaultCreator: route.query.defaultCreator,
              defaultAssigne: route.query.defaultAssigne,
              defaultPriority: route.query.defaultPriority,
              defaultStatus: route.query.defaultStatus,
              defaultDevice: route.query.defaultDevice
            })
          }
        ]
      },
      {
        path: 'contacts',
        component: ContactOverview,
        name: 'MarketingContactOverview',
        props: (route) => ({
          linkContact: route.query.linkContact,
          linkContactType: route.query.linkContactType,
          linkMail: route.query.linkMail,
          email: route.query.email
        })
      },
      {
        path: 'briefButler',
        component: BriefButlerOverview,
        name: 'MarketingBriefButlerOverview'
      },
      {
        path: 'deepSign',
        component: DeepSignOverview,
        name: 'MarketingDeepSignOverview'
      },
      {
        path: 'contacts/:id',
        component: ContactDetailsView,
        name: 'MarketingContactDetailsView',
        props: (route) => ({
          id: route.params.id
        })
      },
      {
        path: 'settings/contacts',
        component: ContactSettingsView,
        name: 'MarketingContactSettingsView',
        props: true
      }
    ]
  },
  {
    path: '/admin',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: 'contacts',
        component: ContactOverview,
        name: 'AdminContactOverview',
        props: (route) => ({
          linkContact: route.query.linkContact,
          linkContactType: route.query.linkContactType,
          linkMail: route.query.linkMail,
          email: route.query.email
        })
      },
      {
        path: 'briefButler',
        component: BriefButlerOverview,
        name: 'AdminBriefButlerOverview'
      },
      {
        path: 'deepSign',
        component: DeepSignOverview,
        name: 'AdminDeepSignOverview'
      },
      {
        path: 'contacts/:id',
        component: ContactDetailsView,
        name: 'AdminContactDetailsView',
        props: (route) => ({
          id: route.params.id
        })
      },
      {
        path: 'settings/contacts',
        component: ContactSettingsView,
        name: 'AdminContactSettingsView',
        props: true
      }
    ]
  },
  {
    path: '/accounting',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: 'contacts',
        component: ContactOverview,
        name: 'AccountingContactOverview',
        props: (route) => ({
          linkContact: route.query.linkContact,
          linkContactType: route.query.linkContactType,
          linkMail: route.query.linkMail,
          email: route.query.email
        })
      },
      {
        path: 'briefButler',
        component: BriefButlerOverview,
        name: 'AccountingBriefButlerOverview'
      },
      {
        path: 'deepSign',
        component: DeepSignOverview,
        name: 'AccountingDeepSignOverview'
      },
      {
        path: 'contacts/:id',
        component: ContactDetailsView,
        name: 'AccountingContactDetailsView',
        props: (route) => ({
          id: route.params.id
        })
      },
      {
        path: 'settings/contacts',
        component: ContactSettingsView,
        name: 'AccountingContactSettingsView',
        props: true
      }
    ]
  },
  {
    path: '/user',
    component: CoozzyRouterViewWrapper,
    children: [
      {
        path: 'requests',
        component: UserRequests, // Do not load this async since the login callback won't work anymore with it
        name: 'UserRequests'
      },
      {
        path: 'requestsDetail/:id',
        component: UserRequestDetailView,
        name: 'UserRequestDetailView',
        props: (route) => ({
          id: route.params.id
        })
      },
      {
        path: 'applicationForm',
        component: ApplicationFormView,
        name: 'ApplicationFormView',
        props: (route) => ({
          requestId: route.query.requestId
        })
      },
      {
        path: 'settings',
        component: UserSettings,
        name: 'UserSettings'
      }
    ]
  },
  {
    path: '/event-invitation/:token/:action',
    component: EventExternalReplyView,
    name: 'EventExternalReplyView',
    props: (route) => ({
      action: route.params.action,
      token: route.params.token
    })
  }
)

// Platform employees
routes.push({
  path: '/platform',
  component: CoozzyRouterViewWrapper,
  beforeEnter: (to, from, next) => {
    if (!router.app.$store.getters['user/isPlatformEmployee']) {
      return router.push({ name: 'Login' })
    } else {
      next()
    }
  },
  children: [
    {
      path: 'customers',
      component: () => import(/* webpackChunkName: "platform" */ '../platform/customers/AccountsView'),
      name: 'AccountsView',
      props: (route) => ({
        query: route.query.query,
        page: route.query.page
      })
    },
    {
      path: 'customers/create',
      component: () => import(/* webpackChunkName: "platform" */ '../platform/customers/CreateAccountView'),
      name: 'CreateAccountView'
    },
    {
      path: 'customers/user/:userId',
      component: () => import(/* webpackChunkName: "platform" */ '../platform/customers/UserDetailsView'),
      name: 'UserDetailsView',
      props: true
    },
    {
      path: 'customers/:id',
      component: () => import(/* webpackChunkName: "platform" */ '../platform/customers/AccountDetailsView'),
      name: 'AccountDetailsView',
      props: true
    },
    {
      path: 'customers/:id/invite',
      component: () => import(/* webpackChunkName: "platform" */ '../platform/customers/CreateEmployeeView'),
      name: 'CreateEmployeeView',
      props: true
    },
    {
      path: 'edit-data',
      component: () => import(/* webpackChunkName: "platform" */ '../platform/customers/EditDataView'),
      name: 'EditDataView'
    },
    {
      path: 'reporting',
      component: () => import(/* webpackChunkName: "platform" */ '../platform/customers/PlatformReportingView'),
      name: 'PlatformReportingView'
    },
    {
      path: 'settings',
      component: () => import(/* webpackChunkName: "platform" */ '../platform/customers/PlatformSettingsView'),
      name: 'PlatformSettingsView'
    },
    {
      path: 'contract-errors',
      component: () => import(/* webpackChunkName: "platform" */ '../platform/customers/ContractErrorsView'),
      name: 'PlatformContractErrorsView'
    }
  ]
})

// 404 - Redirect to login
routes.push({
  path: '*',
  redirect: { name: 'Login' }
})

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

// Do NOT add '/' as the check for public routes is done with 'startsWith' and thus it would match any route. It is
// handled in a specific manner
const publicRoutes = [
  '/callback',
  '/login',
  '/register',
  '/application-form',
  '/event-invitation',
  '/help',
  '/about-us',
  '/tos',
  '/privacy-policy',
  '/imprint',
  '/cookies',
  '/ticket/'
]
router.beforeEach((to, from, next) => {
  // console.log(from)
  if (to.path !== '' && to.path !== '/callback') {
    // store last visited Page, if not default one '/' and callback
    localStorage.setItem('previous-page', to.path)
  }
  if (publicRoutes.findIndex(r => to.path.startsWith(r)) !== -1) {
    return next()
  }
  if (router.app.$auth.isAuthenticated()) {
    if (to.path !== '/post-registration' && !router.app.$auth.isUserCompletelyRegistered()) {
      return next('/post-registration')
    } else if (to.path === '/') {
      return landingPageRedirect()
    } else {
      return next()
    }
  }

  // Renew the token if not authenticated
  router.app.$auth.renewTokens()
    .then(() => {
      if (to.path === '/') {
        return landingPageRedirect()
      }
      return next()
    })
    .catch(() => {
      // For public routes we don't have to redirect to login
      if (to.path === '/' || to.path.startsWith('/object') || to.path.startsWith('/ticket')) {
        return next()
      }
      // If we are not logged in we cannot renew anything. Redirect to login.
      router.app.$auth.login('', {
        language: router.app.$store.getters['internationalization/getCurrentLanguage']
      })
    })
})

export default router

function landingPageRedirect() {
  if (localStorage.loginRedirect) {
    window.location.href = localStorage.loginRedirect
    localStorage.removeItem('loginRedirect')
  } else {
    if (router.app.$store.getters['user/isCompanyAccount'] || router.app.$store.getters['user/isPlatformEmployee']) {
      return router.push({ name: 'OwnerDashboardOverview' })
    } else {
      window.location.href = process.env.VUE_APP_OLD_FRONTEND_URL + '/' +
        router.app.$store.getters['internationalization/getCurrentLanguage'] +
        '/profil/show/overview/' +
        router.app.$store.getters['user/getAccountId']
    }
  }
}
