<template>
  <b-modal
    :id="'add-version-modal-' + documentId"
    :modal-class="'has-confirmation-modal'"
    no-close-on-backdrop
    hide-footer
    :title="$t('message.manageDocuments.version.add')">
    <div
      :class="drag ? 'drag-zone' : ''"
      @drop.prevent="addFile"
      @dragover="drag = true"
      @dragleave.prevent>
      <div
        class="dragover-mask"
        @dragleave="drag=false" />
      <!--suppress XmlInvalidId -->
      <label for="file">{{ $t('message.manageDocuments.version.new') }}</label>
      <coozzy-form-file-input
        id="file"
        ref="file-input"
        v-model="file"
        :disabled="uploading" />
      <div
        class="w-100 float-right mt-3">
        <coozzy-button
          size="normal"
          design="green"
          class="float-right ml-3"
          :disabled="uploading"
          @click="uploadClicked">
          {{ $t('message.generic.form.upload') }}
        </coozzy-button>
        <coozzy-form-checkbox
          v-if="!noAddAnother"
          v-model="addAnotherCheckbox"
          class="float-right pr-3 mt-2 mr-0 display-none-xs font-weight-lighter">
          {{ $t('message.generic.uploadMore') }}
        </coozzy-form-checkbox>
        <coozzy-button
          size="normal"
          :disabled="uploading"
          @click="$bvModal.hide(`add-version-modal-${documentId}`)">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import MediaApi from '../../misc/apis/MediaApi'
import Vue from 'vue'
import { media } from '@/mixins/media'

export default {
  name: 'AddVersionModal',
  mixins: [media],
  props: {
    documentId: {
      type: String,
      default: ''
    },
    ownerId: {
      type: [String, Number],
      default: 0
    },
    noAddAnother: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      file: null,
      drag: false,
      addAnotherCheckbox: false,
      uploading: false
    }
  },
  watch: {
    file: {
      handler: function (val) {
        if (val !== null) {
          this.drag = false
          this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
          if (val.name) {
            this.updateUploadedFileName(this, val.name)
          }
        }
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'add-version-modal-' + this.documentId) {
        this.file = null
      }
    })
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'add-version-modal-' + this.documentId) {
        document.addEventListener('drop', function(e) {
          e.preventDefault()
        })
        document.addEventListener('dragover', function(e) {
          e.preventDefault()
        })
      }
    })
  },
  methods: {
    uploadClicked() {
      this.uploading = true
      this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      const formData = new FormData()
      const fileName = this.editFileName(this.file.name)
      formData.append('file', this.file, fileName)
      formData.append('filename', this.file.name)
      formData.append('public', 'false')
      if (this.ownerId) {
        formData.append('accountId', this.ownerId.toString())
      }

      MediaApi.uploadMedia(formData)
        .then(media => {
          MediaApi.addVersion(this.documentId, media.id)
            .then((document) => {
              this.$emit('version-added', document, this.addAnotherCheckbox)
              this.resetAndHide(this.addAnotherCheckbox === false)
            })
            .catch(e => {
              this.handleError(e)
            })
        })
        .catch(e => {
          this.uploading = false
          this.file = null
          if (this.$refs['file-input']) this.$refs['file-input'].resetPlaceholder()
          console.log(e)
          if (e.response && e.response.data && e.response.data.message === 'Not supported MIME type') {
            Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
          } else {
            Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
          }
        })
        .finally(() => {
          this.uploading = false
        })
    },
    handleError(e) {
      this.resetAndHide(this.addAnotherCheckbox === false)
      console.log(e)
      if (e.response && e.response.data && e.response.data.message === 'Not supported MIME type') {
        Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
      } else {
        Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
      }
    },
    resetAndHide(hide) {
      this.uploading = false
      this.file = null
      if (this.$refs['file-input']) this.$refs['file-input'].resetPlaceholder()
      if (hide) {
        this.$bvModal.hide(`add-version-modal-${this.documentId}`)
      }
    },
    addFile(event) {
      if (event.dataTransfer?.files?.length) {
        this.file = event.dataTransfer.files[0]
        this.updateUploadedFileName(this, event.dataTransfer.files[0].name)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  label {
    margin-bottom: 0;
  }
  .drag-zone  .dragover-mask{
    display: block !important;
    transition: z-index 0s step-end;
    z-index: 2500;;
  }
  .dragover-mask{
    display: none;
    background: rgba(255,255,255,0.3);
    border: 3px dashed $color-primary;
    border-radius: 5px;
    bottom: 0;
    left: 0;
    margin: 2px;
    position: absolute;
    right: 0;
    top: 0;
    transition: z-index .2s step-end;
    z-index: -1;
  }
</style>
