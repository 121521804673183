<template>
  <div>
    <div
      v-if="loading"
      class="col-12 text-center">
      <coozzy-spinner />
    </div>
    <template v-else>
      <coozzy-card class="global-card mb-3">
        <h5 class="cursor-pointer">
          {{ $t('message.marketingObjects.editAdvert.tabs.communication') }}
        </h5>
        <div class="row mb-2">
          <div class="col-12">
            <label>{{ $t('message.tableColumnHeadings.adminAssignee') }} </label>
            <coozzy-form-select
              v-model="contactIdAssigneeMarketing"
              name="assigneeMarketing"
              class="select-entries float-right">
              <option :value="null">
                -
              </option>
              <option
                v-for="(employee, index) in availableEmployeeContacts"
                :key="index"
                :value="employee.id">
                {{ employee.firstName }} {{ employee.lastName }}
              </option>
            </coozzy-form-select>
          </div>
          <div class="col-12 mt-3">
            <label>{{ $t('message.advertisement.contactPersonViewing') }}</label>
            <div class="row px-3 mt-3">
              <coozzy-form-radio
                v-model="viewingAppointment.contactType"
                :default-model="viewingAppointment.contactType"
                name="viewingAppointmentContactType"
                class="col"
                select-value="VIEWING_APPOINTMENT_CONTACT_TYPE_ASSIGNEE">
                {{ $t('message.contact.types.EMPLOYEE') }}
              </coozzy-form-radio>
              <coozzy-form-radio
                v-if="tenantContacts.length > 0"
                v-model="viewingAppointment.contactType"
                :default-model="viewingAppointment.contactType"
                name="viewingAppointmentContactType"
                class="col"
                select-value="VIEWING_APPOINTMENT_CONTACT_TYPE_TENANT">
                {{ $t('message.generic.tenant') }}
              </coozzy-form-radio>
              <coozzy-form-radio
                v-if="contactIdsJanitor.length > 0"
                v-model="viewingAppointment.contactType"
                :default-model="viewingAppointment.contactType"
                name="viewingAppointmentContactType"
                class="col"
                select-value="VIEWING_APPOINTMENT_CONTACT_TYPE_CARETAKER">
                {{ $t('message.contact.types.janitor') }}
              </coozzy-form-radio>
              <coozzy-form-radio
                v-if="contactIdsServiceProvider.length > 0"
                v-model="viewingAppointment.contactType"
                :default-model="viewingAppointment.contactType"
                name="viewingAppointmentContactType"
                class="col"
                select-value="VIEWING_APPOINTMENT_CONTACT_TYPE_SERVICE_PROVIDER">
                {{ $t('message.contact.types.SERVICE_PROVIDER') }}
              </coozzy-form-radio>
              <coozzy-form-radio
                v-model="viewingAppointment.contactType"
                :default-model="viewingAppointment.contactType"
                name="viewingAppointmentContactType"
                class="col"
                select-value="VIEWING_APPOINTMENT_CONTACT_TYPE_FREE_TEXT">
                {{ $t('message.contact.types.anyContact') }}
              </coozzy-form-radio>
            </div>
          </div>
          <div
            v-if="viewingAppointment.contactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_ASSIGNEE'"
            class="col-12 mt-3">
            <coozzy-form-select
              v-model="selectedViewingContactId"
              name="numberEntries"
              class="select-entries float-right">
              <option value="">
                -
              </option>
              <option :value="contactIdAssigneeMarketing">
                {{ $t('message.advertisement.sameAsAssignee') }}
              </option>
              <option
                v-for="(employee, index) in availableEmployeeContacts"
                :key="index"
                :value="employee.id">
                {{ employee.firstName }} {{ employee.lastName }}
              </option>
            </coozzy-form-select>
          </div>
          <div
            v-if="viewingAppointment.contactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_TENANT'"
            class="col-12 mt-3">
            <coozzy-form-select
              v-model="selectedViewingContactId"
              name="numberEntries"
              class="select-entries float-right">
              <option value="">
                {{ $t('message.advertisement.contactPersonViewingPlaceholderTenant') }}
              </option>
              <option
                v-for="(tenant, index) in tenantContacts"
                :key="index"
                :value="tenant.id">
                {{ tenant.name }}
              </option>
            </coozzy-form-select>
            <label v-if="hasContactPersons(selectedViewingContactId)">
              {{ $t('message.createOrder.contactPersonOfCompany') }}
            </label>
            <coozzy-form-select
              v-if="hasContactPersons(selectedViewingContactId)"
              v-model="selectedViewingContactPersonId"
              name="employeeCompany"
              class="select-entries float-right">
              <option value="">
                -
              </option>
              <option
                v-for="(ten, index) in getContactPersons(selectedViewingContactId)"
                :key="index"
                :value="ten.id">
                {{ ten.name }}
              </option>
            </coozzy-form-select>
          </div>
          <div
            v-if="viewingAppointment.contactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_CARETAKER' || viewingAppointment.contactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_SERVICE_PROVIDER'"
            class="col-12 mt-3">
            <coozzy-form-select
              v-model="selectedViewingContactId"
              name="numberEntries"
              class="select-entries float-right">
              <option value="">
                {{ viewingAppointment.contactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_CARETAKER' ? $t('message.advertisement.contactPersonViewingPlaceholderJanitor') : $t('message.advertisement.contactPersonViewingPlaceholderServiceprovider') }}
              </option>
              <option
                v-for="(contactId, index) in (viewingAppointment.contactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_CARETAKER' ? contactIdsJanitor : contactIdsServiceProvider)"
                :key="index"
                :value="contactId">
                {{ getContactName(contactId) }}
              </option>
            </coozzy-form-select>
            <label v-if="hasContactPersons(selectedViewingContactId)">
              {{ $t('message.createOrder.contactPersonOfCompany') }}
            </label>
            <coozzy-form-select
              v-if="hasContactPersons(selectedViewingContactId)"
              v-model="selectedViewingContactPersonId"
              name="employeeCompany"
              class="select-entries float-right">
              <option value="">
                -
              </option>
              <option
                v-for="(jani, index) in getContactPersons(selectedViewingContactId)"
                :key="index"
                :value="jani.id">
                {{ jani.name }}
              </option>
            </coozzy-form-select>
          </div>
          <div
            v-if="viewingAppointment.contactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_FREE_TEXT'"
            class="col-12 mt-3">
            <div class="row">
              <div class="col mb-3">
                <coozzy-form-input
                  v-model="viewingAppointmentRandom.firstName"
                  :name="$t('message.generic.firstName')" />
              </div>
              <div class="col mb-3">
                <coozzy-form-input
                  v-model="viewingAppointmentRandom.lastName"
                  :name="$t('message.generic.lastName')" />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <coozzy-form-input
                  v-model="viewingAppointmentRandom.phone"
                  :name="$t('message.generic.phoneNumber')" />
              </div>
              <div class="col">
                <coozzy-form-input
                  v-model="viewingAppointmentRandom.email"
                  :name="$t('message.generic.email')" />
              </div>
            </div>
          </div>
          <div class="col-12 mt-2">
            <coozzy-form-checkbox
              v-model="viewingAppointment.publishContact"
              :initial="viewingAppointment.publishContact"
              class="col-4"
              name="publishContact">
              {{ $t('message.advertisement.publishContactData') }}
            </coozzy-form-checkbox>
          </div>
          <div class="col-12 mt-3">
            <coozzy-form-textarea
              v-if="object"
              ref="remark"
              v-model="viewingAppointment.remark"
              :initial="viewingAppointment.remark"
              name="remark"
              :rows="5"
              :limit="200"
              :placeholder="$t('message.advertisement.viewingAppointmentTextPlaceholder')">
              {{ $t('message.advertisement.viewingAppointmentText') }}
            </coozzy-form-textarea>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 mt-3">
            <label>{{ $t('message.advertisement.defaultAnswer') }}</label>
            <div class="row px-3 mt-3">
              <coozzy-form-radio
                v-model="defaultAnswerType"
                :default-model="defaultAnswerType"
                name="defaultAnswerType"
                class="col"
                select-value="Template">
                {{ $t('message.calendar.event.template') }}
              </coozzy-form-radio>
              <coozzy-form-radio
                v-model="defaultAnswerType"
                :default-model="defaultAnswerType"
                name="defaultAnswerType"
                class="col"
                select-value="None">
                {{ $t('message.contact.types.NONE') }}
              </coozzy-form-radio>
            </div>
          </div>
          <div
            v-if="defaultAnswerType === 'Template'"
            class="col-12 mt-3">
            <div class="row">
              <div class="col-12">
                <coozzy-form-checkbox
                  v-model="defaultAnswer.includeViewingAppointmentContact"
                  :initial="defaultAnswer.includeViewingAppointmentContact"
                  name="includeContactDataInDefaultAnswer">
                  {{ $t('message.advertisement.includeContactDataInDefaultAnswer') }}
                </coozzy-form-checkbox>
              </div>
              <div class="col-12">
                <coozzy-form-checkbox
                  v-model="defaultAnswer.includeApplicationFormLink"
                  :initial="defaultAnswer.includeApplicationFormLink"
                  name="includeApplicationformLinkInDefaultAnswer">
                  {{ $t('message.advertisement.includeApplicationformLinkInDefaultAnswer') }}
                </coozzy-form-checkbox>
              </div>
              <div class="col-sm-12 col-md-12 mb-3">
                <label>{{ $t('message.marketingMessages.mail.mailbox') + '*' }}</label>
                <coozzy-form-select
                  v-model="defaultAnswer.mailboxId"
                  :state="$v.defaultAnswer?.mailboxId.$dirty && $v.defaultAnswer.mailboxId.$error ? false : null">
                  <option
                    v-for="mailbox in mailboxes"
                    :key="mailbox.id"
                    :value="mailbox.id">
                    {{ mailbox.outboundEmail }}
                  </option>
                </coozzy-form-select>
              </div>
              <div class="col-sm-12 col-md-12 mb-3">
                <label>{{ $t('message.settings.template.title') }}</label>
                <coozzy-form-select v-model="defaultAnswer.templateId">
                  <option :value="null" />
                  <option
                    v-for="template in sortedTemplate"
                    :key="template.id"
                    :value="template.id">
                    {{ template.name }}
                  </option>
                </coozzy-form-select>
              </div>
              <div class="col-sm-12 col-md-12 mb-3">
                <coozzy-form-input
                  v-model="defaultAnswer.subject"
                  :state="$v.defaultAnswer?.subject.$dirty && $v.defaultAnswer.subject.$error ? false : null"
                  :name="$t('message.marketingMessages.mail.subject') + '*'" />
              </div>
              <div
                class="col-sm-12 col-md-12 mb-3">
                <label for="description">{{ $t('message.marketingMessages.mail.body') + '*' }}</label>
                <editor
                  ref="editor-tiny"
                  v-model="defaultAnswer.text"
                  :api-key="editorApi"
                  :init="{
                    toolbar: 'undo redo | bold italic underline forecolor | fontsize | numlist bullist link table | fullscreen print',
                    plugins: 'fullscreen lists code image table link',
                    height: '370',
                    menubar: '',
                    language: currentLanguage,
                    elementpath: false,
                    invalid_styles: {
                      '*': 'font-family',
                    },
                    automatic_uploads: true,
                    license_key: 'gpl',
                    content_style: contentStyle,
                    font_formats: fontFormats,
                    branding: false,
                    remove_trailing_brs: false,
                    browser_spellcheck: true,
                    table_toolbar: '',
                    font_size_formats: fontsizeFormats
                  }" />
              </div>
              <div class="col-sm-12 col-md-12 mb-2">
                <section class="mt-2 mb-2">
                  <div
                    v-b-toggle="'collapse-placeholder'"
                    class="collapse-title">
                    <label class="cursor-pointer width-only-content">
                      {{ $t('message.generic.placeholder') }}
                    </label>
                  </div>
                  <b-collapse
                    :id="'collapse-placeholder'"
                    :visible="false"
                    class="col">
                    <p class="mb-0">
                      <span>#anrede# <br>
                        #obj.str# <br>
                        #obj.nr# <br>
                        #obj.plz# <br>
                        #obj.ort# <br>
                        #obj.zimmer# <br>
                        #obj.etage#
                      </span>
                    </p>
                  </b-collapse>
                </section>
              </div>
              <div class="col-sm-12 col-md-12 mb-3">
                <label>{{ $t('message.calendar.event.attachement') }}</label>
                <coozzy-form-file-input
                  id="fileImage"
                  v-model="attachment"
                  accept="text/*, application/*, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx" />
                <div class="row">
                  <div
                    v-for="medium in media"
                    :key="medium.id"
                    class="media-block col-2">
                    <div
                      v-if="['image/jpeg', 'image/png', 'image/gif'].indexOf(medium.mimeType) !== -1"
                      class="uploaded-file my-2"
                      :style="{ 'background-image': 'url(' + medium.url + ')' }"
                      style="background-repeat: no-repeat;background-size: cover;background-position: center center;cursor:pointer;"
                      @click="imageClicked(medium)">
                      <coozzy-button
                        design="transparent"
                        class="delete-icon"
                        @click="removeAttachment(medium.id)">
                        <coozzy-delete-icon />
                      </coozzy-button>
                    </div>
                    <div
                      v-else
                      class="file-block p-1 my-2">
                      <a
                        :href="medium.url"
                        target="_blank">
                        {{ medium.filename }}
                      </a>
                      <coozzy-button
                        design="transparent"
                        class="delete-icon"
                        @click="removeAttachment(medium.id)">
                        <coozzy-delete-icon />
                      </coozzy-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </coozzy-card>
    </template>
  </div>
</template>

<script>
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import Vue from 'vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { mapActions, mapGetters, mapState } from 'vuex'
import { user } from '@/mixins/user'
import { obj } from '@/mixins/object'
import { html } from '@/mixins/html'
import { required } from 'vuelidate/lib/validators'
import ObjectApi from '@/misc/apis/ObjectApi'
import MessageApi from '@/misc/apis/MessageApi'
import ContactApi from '@/misc/apis/ContactApi'
import CoozzyFormRadio from '@/framework/components/form/radio/CoozzyFormRadio'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon'
import MediaApi from '@/misc/apis/MediaApi'
import Editor from '@tinymce/tinymce-vue'
import { media } from '@/mixins/media'

export default {
  name: 'MarketingEditAdvertCommunicationTab',
  components: {
    CoozzyDeleteIcon,
    CoozzyButton,
    CoozzyFormFileInput,
    CoozzyFormTextarea,
    CoozzyFormCheckbox,
    CoozzyFormInput,
    CoozzyFormRadio,
    CoozzyFormSelect,
    CoozzyCard,
    CoozzySpinner,
    Editor
  },
  mixins: [obj, user, html, media],
  props: {
    object: {
      type: Object,
      default: null,
      required: false
    }
  },
  data() {
    return {
      galleryImages: [],
      employeeContacts: [],
      mailboxesLoaded: false,
      mailboxes: [],
      contactIdAssigneeMarketing: '',
      tenantContactsLoaded: false,
      tenantContacts: [],
      objectContactsLoaded: false,
      objectContacts: [],
      selectedViewingContactId: '',
      selectedViewingContactPersonId: '',
      viewingAppointment: {
        contactType: 'VIEWING_APPOINTMENT_CONTACT_TYPE_ASSIGNEE',
        publishContact: false,
        remark: ''
      },
      viewingAppointmentRandom: {
        firstName: '',
        lastName: '',
        phone: '',
        email: ''
      },
      defaultAnswerType: 'Template',
      templateInitialized: false,
      attachment: null,
      media: [],
      defaultAnswer: {
        includeViewingAppointmentContact: false,
        includeApplicationFormLink: false,
        templateId: '',
        text: '',
        subject: '',
        mailboxId: '',
        attachmentMediaIds: []
      }
    }
  },
  computed: {
    ...mapState('message', ['templates']),
    ...mapGetters('message', ['getSignatureForUserId']),
    fontSetting() {
      return this.$store.getters['user/getFontSettings']
    },
    contentStyle() {
      const defaultFontFamily = 'Lato, sans-serif'
      const fontFamily = this.fontSetting && this.fontSetting.family !== '' ? `'${this.fontSetting.family}', ` : ''
      const fontSize = this.fontSetting && this.fontSetting.size !== '' ? this.fontSetting.size + 'pt' : '10pt'
      return `body { color: #495057 !important; font-family: ${fontFamily}'${defaultFontFamily} !important'; font-size: ${fontSize};} p {margin: 0}`
    },
    fontsizeFormats() {
      let sizeOptions = []
      for (let i = 4; i <= 36; i++) {
        sizeOptions.push(`${i}pt`)
      }
      return sizeOptions.join(' ')
    },
    fontFormats() {
      const defaultFont = 'Lato=Lato, sans-serif;'
      const userFont = this.fontSetting && this.fontSetting.family !== '' ? `${this.fontSetting.family}=${this.fontSetting.family}, sans-serif;` : ''
      return `${defaultFont}${userFont}`
    },
    sortedTemplate() {
      return this.templates.filter(x => x.type === 'TEMPLATE_TYPE_EMAIL').slice().sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (b.name > a.name) {
          return -1
        }
        return 0
      })
    },
    loading() {
      return !this.object &&
        this.employeeContacts.length > 0 &&
        this.tenantContactsLoaded &&
        this.objectContactsLoaded &&
        this.mailboxesLoaded
    },
    availableEmployees() {
      return this.getEmployees().filter(e => !e.blocked && !e.deleted)
    },
    availableEmployeeContacts() {
      return this.availableEmployees
        .map(e => this.employeeContacts.find(c => c.email === e.email))
        .filter(e => !!e)
    },
    userIdAssigneeMarketing() {
      const contact = this.availableEmployeeContacts.find(e => e.id === this.contactIdAssigneeMarketing)
      let employeeUserId = ''
      if (contact) {
        employeeUserId = this.availableEmployees.find(e => e.email === contact.email)?.id || ''
      }
      return employeeUserId
    },
    contactIdsJanitor: function () {
      return this.object?.contacts?.contactIdsJanitor || []
    },
    contactIdsServiceProvider: function () {
      return this.object?.contacts?.contactIdsServiceProvider || []
    },
    // We need to clone that object to be able to get old value on deep watcher: https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
    viewingAppointmentRandomClone() {
      return Object.assign({}, this.viewingAppointmentRandom)
    },
    editorApi() {
      return 'gpl'
    },
    getSignatureId() {
      return this.mailboxes.find(x => x.id === this.defaultAnswer.mailboxId)?.signatureId
    },
    AllDataLoaded() {
      return this.mailboxesLoaded &&
        this.tenantContactsLoaded &&
        this.objectContactsLoaded &&
        this.contactIdAssigneeMarketing
    }
  },
  watch: {
    object: function (newVal, oldVal) {
      if (!oldVal) {
        // Load data that was dependent on object data
        this.loadTenants()
        this.loadObjectContacts()

        // Set data from object into component data fields
        if (newVal?.contacts?.contactIdAssigneeMarketing) {
          this.contactIdAssigneeMarketing = newVal?.contacts?.contactIdAssigneeMarketing || ''
        }

        if (newVal?.viewingAppointment) {
          this.viewingAppointment = newVal.viewingAppointment
          this.viewingAppointmentRandom = {
            firstName: newVal.viewingAppointment.firstName,
            lastName: newVal.viewingAppointment.lastName,
            email: newVal.viewingAppointment.email,
            phone: newVal.viewingAppointment.phone
          }
        }

        if (!newVal.defaultAnswer || newVal.defaultAnswer.mailboxId === '') {
          this.defaultAnswerType = 'None'
        }

        if (newVal?.defaultAnswer) {
          this.defaultAnswer = newVal.defaultAnswer
        }

        // If we have an employee or free text selected then we can assign selectedViewingContactId already
        // otherwise we need to wait until other contacts are loaded because it can also be a contact person
        if (this.viewingAppointment.contactType === 'VIEWING_APPOINTMENT_CONTACT_TYPE_FREE_TEXT' || (['VIEWING_APPOINTMENT_CONTACT_TYPE_ASSIGNEE'].includes(this.viewingAppointment.contactType))) {
          this.selectedViewingContactId = newVal.contacts.contactIdViewingAppointment
        }
      }
    },
    AllDataLoaded: function () {
      setTimeout(() => {
        this.templateInitialized = true
      }, 1000)
    },
    availableEmployees: function (newVal) {
      for (const employee of newVal) {
        for (const mailbox of this.mailboxes) {
          this.loadSignatureForUserId({
            accountId: this.accountId,
            userId: employee.id,
            signatureId: mailbox.signatureId
          })
        }
      }
    },
    'viewingAppointment.contactType': function (newVal, oldVal) {
      if (oldVal && this.templateInitialized) {
        this.selectedViewingContactId = ''
      }
    },
    defaultAnswerType: function (val) {
      if (val === 'None') {
        this.defaultAnswer = {
          includeViewingAppointmentContact: false,
          includeApplicationFormLink: false,
          text: '',
          subject: '',
          mailboxId: '',
          attachmentMediaIds: []
        }
      } else {
        if (this.contactIdAssigneeMarketing !== '' && this.defaultAnswer.text === '') {
          this.updateDefaultAnswerMessage(this.userIdAssigneeMarketing, this.selectedViewingContactId, this.selectedViewingContactPersonId, this.viewingAppointmentRandom)
        }
      }
    },
    'defaultAnswer.templateId': function (newVal) {
      if (this.templateInitialized && newVal) {
        this.renderTemplate()
      }
    },
    contactIdAssigneeMarketing: function (newVal, oldVal) {
      // load signature of mainUserId to use it on default answer
      const mailbox = this.defaultAnswer.mailboxId ? this.mailboxes.find(x => x.id === this.defaultAnswer.mailboxId) : null
      const employeeContact = this.availableEmployeeContacts.find(x => x.id === newVal)
      const employee = employeeContact ? this.availableEmployees.find(w => w.email === employeeContact.email) : null
      if (mailbox && employee && mailbox.mainUserId) {
        this.loadSignatureForUserId({
          accountId: mailbox.ownerId,
          userId: employee.id,
          signatureId: mailbox.signatureId
        })
        .then(() => {
            if (this.templateInitialized) {
              this.$emit('assignee-marketing-updated', newVal)

              const contact = this.availableEmployeeContacts.find(e => e.id === oldVal)
              let employeeUserId = ''
              if (contact) {
                employeeUserId = this.availableEmployees.find(e => e.email === contact.email)?.id || ''
              }
              this.updateDefaultAnswerMessage(employeeUserId, this.selectedViewingContactId, this.selectedViewingContactPersonId, this.viewingAppointmentRandom)
            }
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.signature'), { type: 'error' })
          })
      } else {
        if (this.templateInitialized) {
          this.$emit('assignee-marketing-updated', newVal)

          const contact = this.availableEmployeeContacts.find(e => e.id === oldVal)
          let employeeUserId = ''
          if (contact) {
            employeeUserId = this.availableEmployees.find(e => e.email === contact.email)?.id || ''
          }
          this.updateDefaultAnswerMessage(employeeUserId, this.selectedViewingContactId, this.selectedViewingContactPersonId, this.viewingAppointmentRandom)
        }
      }
    },
    'defaultAnswer.mailboxId': function (newVal) {
      // load signature of mainUserId to use it on default answer
      const mailbox = this.defaultAnswer.mailboxId ? this.mailboxes.find(x => x.id === newVal) : null
      const employeeContact = this.availableEmployeeContacts.find(x => x.id === this.contactIdAssigneeMarketing)
      const employee = employeeContact ? this.availableEmployees.find(w => w.email === employeeContact.email) : null
      if (mailbox && employee && mailbox.mainUserId) {
        this.loadSignatureForUserId({
          accountId: mailbox.ownerId,
          userId: employee.id,
          signatureId: mailbox.signatureId
        })
        .then(() => {
            if (this.defaultAnswer.text === '') {
              this.defaultAnswer.text = '<br><section id="signature">' + (this.getSignatureForUserId(this.userIdAssigneeMarketing, this.getSignatureId)).replaceAll('<br />', '<br>').replaceAll('\n', '') + '</section>'
            }
            if (this.templateInitialized && newVal) {
              this.updateDefaultAnswerMessage(this.userIdAssigneeMarketing, this.selectedViewingContactId, this.selectedViewingContactPersonId, this.viewingAppointmentRandom)
            }
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.signature'), { type: 'error' })
          })
      } else {
        if (this.defaultAnswer.text === '') {
          this.defaultAnswer.text = '<br><section id="signature">' + (this.getSignatureForUserId(this.userIdAssigneeMarketing, this.getSignatureId)).replaceAll('<br />', '<br>').replaceAll('\n', '') + '</section>'
        }
        if (this.templateInitialized && newVal) {
          this.updateDefaultAnswerMessage(this.userIdAssigneeMarketing, this.selectedViewingContactId, this.selectedViewingContactPersonId, this.viewingAppointmentRandom)
        }
      }
    },
    selectedViewingContactId: function (newVal, oldVal) {
      if (this.templateInitialized) {
        this.$emit('viewing-contact-updated', newVal)
        this.updateDefaultAnswerMessage(this.userIdAssigneeMarketing, oldVal, this.selectedViewingContactPersonId, this.viewingAppointmentRandom)
        this.selectedViewingContactPersonId = ''
      }
    },
    selectedViewingContactPersonId: function (newVal, oldVal) {
      if (this.templateInitialized) {
        if (newVal !== '') {
          this.$emit('viewing-contact-updated', newVal)
        }
        if (this.selectedViewingContactId !== '') {
          this.updateDefaultAnswerMessage(this.userIdAssigneeMarketing, this.selectedViewingContactId, oldVal, this.viewingAppointmentRandom)
        }
      }
    },
    // We need to watch cloned object to be able to get old value on deep watcher: https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
    viewingAppointmentRandomClone: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (this.templateInitialized) {
          this.updateDefaultAnswerMessage(this.userIdAssigneeMarketing, this.selectedViewingContactId, this.selectedViewingContactPersonId, oldVal)
        }
      }
    },
    'defaultAnswer.includeViewingAppointmentContact': function () {
      if (this.templateInitialized) {
        this.updateDefaultAnswerMessage(this.userIdAssigneeMarketing, this.selectedViewingContactId, this.selectedViewingContactPersonId, this.viewingAppointmentRandom)
      }
    },
    'defaultAnswer.includeApplicationFormLink': function () {
      if (this.templateInitialized) {
        this.updateDefaultAnswerMessage(this.userIdAssigneeMarketing, this.selectedViewingContactId, this.selectedViewingContactPersonId, this.viewingAppointmentRandom)
      }
    },
    'defaultAnswer.attachmentMediaIds': function (newVal) {
      this.media = []

      if (newVal.length > 0) {
        const promises = newVal.map(mediaId => MediaApi.getMediaByID(mediaId))

        Promise.all(promises)
          .then(responses => {
            this.media = responses.map(e => e.media)
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.media'), { type: 'error' })
          })
      }
    },
    defaultAnswer: {
      deep: true,
      handler: function (newVal) {
        this.$emit('default-answer-updated', newVal)
        this.$emit('empty-default-answer', this.defaultAnswerType === 'None')
      }
    },
    viewingAppointment: {
      deep: true,
      handler: function () {
        this.$emit('viewing-appointment-updated', {
          contactType: this.viewingAppointment.contactType,
          publishContact: this.viewingAppointment.publishContact,
          remark: this.viewingAppointment.remark,
          firstName: this.viewingAppointmentRandom.firstName,
          lastName: this.viewingAppointmentRandom.lastName,
          email: this.viewingAppointmentRandom.email,
          phone: this.viewingAppointmentRandom.phone
        })
      }
    },
    viewingAppointmentRandom: {
      deep: true,
      handler: function () {
        this.$emit('viewing-appointment-updated', {
          contactType: this.viewingAppointment.contactType,
          publishContact: this.viewingAppointment.publishContact,
          remark: this.viewingAppointment.remark,
          firstName: this.viewingAppointmentRandom.firstName,
          lastName: this.viewingAppointmentRandom.lastName,
          email: this.viewingAppointmentRandom.email,
          phone: this.viewingAppointmentRandom.phone
        })
      }
    },
    attachment: function (newVal) {
      if (newVal) {
        this.uploadAttachment()
      }
    },
    'defaultAnswer.text': function (newVal) {
      // check if editor has class 'is-invalid' and defaultAnswer.text not empty (class 'is-invalid' already added in validation function)
      const hasInvalide = document.getElementsByClassName('tox tox-tinymce')[0]?.classList.value.includes('is-invalid')
      if (newVal !== '' && hasInvalide) {
        document.getElementsByClassName('tox tox-tinymce')[0]?.classList.remove('is-invalid')
      }
    }
  },
  mounted() {
    this.loadEmployees()
    this.loadEmployeeContacts()
    this.loadTenants()
    this.loadObjectContacts()
    setTimeout(() => {
      this.loadAllMailboxes()
      this.loadTemplateList(this.accountId)
      this.updateEditorContentStyle()
    }, 1000)
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    ...mapActions('message', ['loadSignatureForUserId', 'loadTemplateList']),
    updateEditorContentStyle() {
      if (this.$refs['editor-tiny']) {
        this.$refs['editor-tiny'].forEach(editorRef => {
          const editor = editorRef.editor
          if (editor) {
            editor.getBody().style.fontFamily = this.fontSetting.family || 'Lato'
            editor.getBody().style.fontSize = (this.fontSetting.size || 10) + 'pt'
          }
        })
      }
    },
    loadEmployeeContacts() {
      ContactApi.listEmployees(this.accountId)
        .then(response => {
          this.employeeContacts = response.persons
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
    },
    loadTenants() {
      if (this.object) {
        ObjectApi.listCurrentByObjectIds([this.object.id])
          .then(response => {
            if (response.tenancies.length > 0 && !response.tenancies[0].vacancy) {
              const tenantContactIds = response.tenancies[0].tenant.contactIds
              ContactApi.contactResolve(tenantContactIds)
                .then(response => {
                  this.tenantContacts = response.persons.concat(response.companies).filter(e => tenantContactIds.includes(e.id))
                  const contactIdViewingAppointment = this.object?.contacts?.contactIdViewingAppointment
                  if (['VIEWING_APPOINTMENT_CONTACT_TYPE_TENANT'].includes(this.viewingAppointment.contactType) &&
                    contactIdViewingAppointment) {
                    const contact = this.tenantContacts.find(e => e.id === contactIdViewingAppointment)
                    if (contact) {
                      this.selectedViewingContactId = contactIdViewingAppointment
                    } else {
                      this.selectedViewingContactId = this.tenantContacts.find(e => {
                        if (this.hasContactPersons(e.id)) {
                          for (const contactPerson of this.getContactPersons(e.id)) {
                            if (contactPerson.id === contactIdViewingAppointment) {
                              return true
                            }
                          }
                        }
                        return false
                      })?.id || ''
                      this.$nextTick(function () {
                        this.selectedViewingContactPersonId = contactIdViewingAppointment
                      })
                    }
                  }

                  this.tenantContactsLoaded = true
                })
                .catch(e => {
                  console.log(e)
                  Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
                  this.tenantContactsLoaded = true
                })
            } else {
              this.tenantContactsLoaded = true
            }
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.tenancy'), { type: 'error' })
            this.tenantContactsLoaded = true
          })
      }
    },
    loadObjectContacts() {
      if (this.object) {
        const contactIds = (this.contactIdsJanitor).concat(this.contactIdsServiceProvider)
        if (contactIds.length > 0) {
          ContactApi.contactResolve(contactIds)
            .then(response => {
              this.objectContacts = response.persons.concat(response.companies).filter(e => contactIds.includes(e.id))

              const contactIdViewingAppointment = this.object?.contacts?.contactIdViewingAppointment
              if (['VIEWING_APPOINTMENT_CONTACT_TYPE_SERVICE_PROVIDER', 'VIEWING_APPOINTMENT_CONTACT_TYPE_CARETAKER', 'VIEWING_APPOINTMENT_CONTACT_TYPE_TENANT'].includes(this.viewingAppointment.contactType) &&
                contactIdViewingAppointment) {
                const contact = this.objectContacts.find(e => e.id === contactIdViewingAppointment)
                if (contact) {
                  this.selectedViewingContactId = contactIdViewingAppointment
                } else {
                  this.selectedViewingContactId = this.objectContacts.find(e => {
                    if (this.hasContactPersons(e.id)) {
                      for (const contactPerson of this.getContactPersons(e.id)) {
                        if (contactPerson.id === contactIdViewingAppointment) {
                          return true
                        }
                      }
                    }
                    return false
                  })?.id || ''
                  this.$nextTick(function () {
                    this.selectedViewingContactPersonId = contactIdViewingAppointment
                  })
                }
              }
              this.objectContactsLoaded = true
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
              this.objectContactsLoaded = true
            })
        } else {
          this.objectContactsLoaded = true
        }
      }
    },
    loadAllMailboxes() {
      MessageApi.listMailboxes(this.accountId)
        .then(response => {
          this.mailboxes = response.mailboxes.filter(x => x.active === true)
          this.mailboxesLoaded = true
          this.$nextTick(() => {
            if (this.defaultAnswer.text === '') {
              this.defaultAnswer.text = this.getSignatureForUserId(this.userId, this.getSignatureId)
            }
          })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.mailbox'), { type: 'error' })
        })
    },
    getContact(contactId) {
      return this.availableEmployeeContacts
        .concat(this.tenantContacts)
        .concat(this.objectContacts)
        .find(contact => contact.id === contactId || contact.objectID === contactId)
    },
    getContactName(contactId) {
      return this.getContact(contactId)?.name || ''
    },
    getContactPersons(contactId) {
      const contact = this.getContact(contactId)
      if (contact && contact.contactPersons && Array.isArray(contact.contactPersons)) {
        return contact.contactPersons
      }
      return undefined
    },
    hasContactPersons(contactId) {
      if (!contactId) {
        return false
      }
      return (this.getContactPersons(contactId) || []).length > 0
    },
    applicationFormLink() {
      return `${process.env.VUE_APP_BASE_API_URL}/object/${this.object.id}`
    },
    renderTemplate() {
      // Reset media
      this.media = []
      this.defaultAnswer.attachmentMediaIds = []

      MessageApi.renderTemplate(this.defaultAnswer.templateId, '')
        .then(response => {
          this.defaultAnswer.subject = response.subject
          const template = this.templates.find(e => e.id === this.defaultAnswer.templateId)
          this.defaultAnswer.attachmentMediaIds = template.attachmentMediaIds

          this.defaultAnswer.text = response.text + this.getDefaultAnswerSuffix()
          this.$refs['editor-tiny']?.editor?.fire('focusin') // refresh v-model
          this.$refs['editor-tiny']?.editor?.fire('focusout') // refresh v-model
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.renderTemplate'), { type: 'error' })
        })
    },
    updateDefaultAnswerMessage(oldAssigneeUserId, oldViewingMainContactId, oldViewingContactPersonId, oldRandom) {
      if (this.defaultAnswerType !== 'None') {
        this.defaultAnswer.text = this.getExtractedDefaultAnswerMessage(oldAssigneeUserId, oldViewingMainContactId, oldViewingContactPersonId, oldRandom)
        this.defaultAnswer.text = this.defaultAnswer.text + this.getDefaultAnswerSuffix()
        this.$refs['editor-tiny']?.editor?.fire('focusin') // refresh v-model
        this.$refs['editor-tiny']?.editor?.fire('focusout') // refresh v-model
      }
    },
    getDefaultAnswerSuffix() {
      let suffix = ''

      if (this.defaultAnswer.includeApplicationFormLink) {
        suffix += '<br>' + this.applicationFormLink() + '<br>'
      }
      if (this.defaultAnswer.includeViewingAppointmentContact && this.getContactInfoString(this.selectedViewingContactId, this.selectedViewingContactPersonId, this.viewingAppointmentRandom).length) {
        suffix += '<section id="contactInfo"><br>' + this.getContactInfoString(this.selectedViewingContactId, this.selectedViewingContactPersonId, this.viewingAppointmentRandom) + '</section>'
      }
      if (this.getSignatureForUserId(this.userIdAssigneeMarketing, this.getSignatureId)) {
        suffix += '<br><section id="signature">' + (this.getSignatureForUserId(this.userIdAssigneeMarketing, this.getSignatureId)).replaceAll('<br />', '<br>').replaceAll('\n', '') + '</section>'
      }
      return this.replaceDiaeresis(suffix)
    },
    getExtractedDefaultAnswerMessage(assigneeUserId, viewingMainContactId, viewingContactPersonId, random) {
      let text = this.defaultAnswer.text
      const contactInfo = text.slice(text.indexOf('<section id="contactInfo">'), text.indexOf('</section>') + 10)
      if (contactInfo.length > 10) {
        text = text.replaceAll(contactInfo, '')
      }

      text = this.decodeHtml(text.replaceAll('<br />', '<br>').replaceAll('\n', ''))
      if (this.getSignatureForUserId(assigneeUserId, this.getSignatureId).length) {
        const signature = text.slice(text.indexOf('<section id="signature">'), text.indexOf('</section>') + 10)
        text = text.replaceAll('<br>' + signature, '')
        text = text.replaceAll(signature, '')
        text = text.replaceAll('<p><br></p>', '')
      }
      if (this.getSignatureForUserId(assigneeUserId).length) {
        const signature = text.slice(text.indexOf('<section id="signature">'), text.indexOf('</section>') + 10)
        text = text.replaceAll('<br>' + signature, '')
        text = text.replaceAll(signature, '')
        text = text.replaceAll('<p><br></p>', '')
      }
      text = text.replaceAll(('<br>' + this.applicationFormLink() + '<br>'), '').replaceAll((this.applicationFormLink()), '')
      return this.replaceDiaeresis(text)
    },
    replaceDiaeresis(text) {
      return text.replaceAll('&uuml;', 'ü').replaceAll('&yuml;', 'ÿ').replaceAll('&ouml;', 'ö').replaceAll('&auml;', 'ä').replaceAll('&euml;', 'ë').replaceAll('&iuml;', 'ï').replaceAll('&auml;', 'ä')
    },
    uploadAttachment() {
      const selectedFile = this.attachment
      const formData = new FormData()
      const fileName = this.editFileName(selectedFile.name)
      formData.append('file', selectedFile, fileName)
      formData.append('filename', selectedFile.name)
      formData.append('public', 'true')
      MediaApi.uploadMedia(formData)
        .then(response => {
          this.media.push(response)
          this.defaultAnswer.attachmentMediaIds.push(response.id)
          this.attachment = null
        })
        .catch(e => {
          console.log(e)
          if (e.response && e.response.data && e.response.data.message === 'Not supported MIME type') {
            Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
          } else {
            Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
          }
        })
    },
    imageClicked(item) {
      if (item.url && item.url !== '') {
        this.galleryImages = [
          item.url
        ]
        this.$viewerApi({
          images: this.galleryImages,
          options: this.viewerOptions
        })
      }
    },
    removeAttachment(id) {
      this.defaultAnswer.attachmentMediaIds = this.defaultAnswer.attachmentMediaIds.filter(e => e !== id)
      this.media = this.media.filter(el => el.id !== id)
    },
    getContactInfoString(mainContactId, contactPersonId, random) {
      if (!mainContactId) {
        return this.getRandomInfoString(random)
      } else {
        if (contactPersonId && this.hasContactPersons(mainContactId)) {
          const contactPerson = this.getContactPersons(mainContactId).find(e => e.id === contactPersonId)
          if (contactPerson) {
            const phoneNumber = this.getPhoneNumbers(contactPerson.phoneNumbers)
            return this.$t('message.advertisement.contactTitle') + '<br>' + contactPerson.name + '<br>' + phoneNumber + contactPerson.email + '<br>'
          }
        } else {
          const contact = this.getContact(mainContactId)
          if (contact) {
            const phoneNumber = this.getPhoneNumbers(contact.phoneNumbers)
            return this.$t('message.advertisement.contactTitle') + '<br>' + contact.name + '<br>' + phoneNumber + contact.email + (phoneNumber || contact.email ? '<br>' : '')
          }
        }
      }
      return ''
    },
    getRandomInfoString(random) {
      if (random.firstName !== '' || random.lastName !== '' || random.phone !== '' || random.email !== '') {
        return this.$t('message.advertisement.contactTitle') + '<br>' + random.firstName + ' ' + random.lastName + '<br>' +
          random.phone + (random.phone ? '<br>' : '') + random.email + (random.email ? '<br>' : '')
      }
      return ''
    },
    getPhoneNumbers(phoneNumbers) {
      let stringNumber = ''
      if (phoneNumbers && phoneNumbers.length > 0) {
        const arrayWORK = phoneNumbers.filter(number => number.type === 'WORK')
        const arrayMOBIL = phoneNumbers.filter(number => number.type === 'MOBILE')
        const arrayHOME = phoneNumbers.filter(number => number.type === 'HOME')
        if (arrayMOBIL.length > 0) {
          stringNumber += arrayMOBIL[0].number + '<br>'
        }
        if (arrayHOME.length > 0) {
          stringNumber += arrayHOME[0].number + '<br>'
        }
        if (arrayWORK.length > 0) {
          stringNumber += arrayWORK[0].number + '<br>'
        }
      }
      return stringNumber || ''
    },
    isInvalid() {
      if (this.$v) {
        this.$v.$touch()
        if (this.$v.$invalid) {
          if (this.$v.defaultAnswer.text.$dirty && this.$v.defaultAnswer.text.$error) {
            document.getElementsByClassName('tox tox-tinymce')[0].classList.add('is-invalid')
          }
          this.scrollToError()
        }
        return this.$v.$invalid
      }
      return false
    }
  },
  validations() {
    let validations = {}
    if (this.defaultAnswerType !== 'None') {
      validations = {
        defaultAnswer: {
          mailboxId: {
            required
          },
          subject: {
            required
          },
          text: {
            required
          }
        }
      }
    }
    return validations
  }
}
</script>

<style lang="scss" scoped>
.media-block{
  width: 10%;
  max-width: 160px;
}
.uploaded-file {
    max-height: 100px;
    background-position: center center;
    background-size: cover;
    min-height: 5em;
  }
  @media (min-width: 768px) {
    .uploaded-file {
      max-height: 100px;
      min-height: 3em;
    }
  }

  @media (min-width: 992px) {
    .uploaded-file {
      max-height: 110px;
      min-height: 3.5em;
    }
  }

  @media (min-width: 1200px) {
    .uploaded-file {
      max-height: 120px;
      min-height: 5em;
    }
  }

  @media (min-width: 1600px) {
    .uploaded-file {
      max-height: 130px;
      min-height: 6em;
    }
  }
  @media (min-width: 1900px) {
    .uploaded-file {
      max-height: 130px;
      min-height: 7em;
    }
  }
  :deep(.is-invalid) {
    border-color: #dc3545;
  }
</style>
