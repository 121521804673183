<template>
  <section class="flex-column d-flex">
    <div>
      <h5>{{ $t('message.applicationForm.creditTrust.title') }}</h5>
      <span>{{ $t('message.applicationForm.creditTrust.description') }}</span>
      <div
        v-if="individual.address.country && individual.address.country !== 'CH'"
        class="mt-2">
        <h6>{{ $t('message.applicationForm.creditTrust.creditTrustOnlyForSwiss') }}</h6>
      </div>

      <div v-if="(!individual.address.country || individual.address.country === 'CH') && !certificateNoIssuable">
        <div class="mt-2">
          <label for="doYouHaveCertificate">{{ $t('message.applicationForm.creditTrust.doYouHaveCertificate') }}</label>
          <coozzy-form-select
            id="doYouHaveCertificate"
            v-model="doYouHaveCertificate">
            <option value="null">
              -
            </option>
            <option value="YES">
              {{ $t('message.generic.selectField.yes') }}
            </option>
            <option value="NO">
              {{ $t('message.generic.selectField.no') }}
            </option>
            <option value="NO_DEBT_REGISTER">
              {{ $t('message.applicationForm.creditTrust.noIHaveDebtRegister') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
    </div>
    <div :class="(verifiedCertificateValid === false && wannaOrderCertificate === 'YES') ? 'moveToOneBeforeLast' : ''">
      <div v-if="(!individual.address.country || individual.address.country === 'CH') && !certificateNoIssuable">
        <div v-if="doYouHaveCertificate === 'NO' || wannaOrderCertificate === 'YES'">
          <div>
            <label for="debtCertificateLastFiveYears">{{ $t('message.applicationForm.debtCertificate') }} </label>
            <coozzy-form-select
              id="debtCertificate"
              v-model="debtCertificateLastFiveYears">
              <option value="">
                -
              </option>
              <option value="YES">
                {{ $t('message.generic.selectField.yes') }}
              </option>
              <option value="NO">
                {{ $t('message.generic.selectField.no') }}
              </option>
            </coozzy-form-select>
          </div>
          <div v-if="debtCertificateLastFiveYears === 'NO' || (wannaOrderCertificate === 'YES' && verifiedCertificateValid !== false)">
            <label for="haveYouMovedLastThreeYears">{{ $t('message.applicationForm.creditTrust.haveYouMovedLastThreeYears') }}</label>
            <coozzy-form-select
              id="haveYouMovedLastThreeYears"
              v-model="haveYouMovedLastThreeYears">
              <option value="null">
                -
              </option>
              <option value="YES">
                {{ $t('message.generic.selectField.yes') }}
              </option>
              <option value="NO">
                {{ $t('message.generic.selectField.no') }}
              </option>
            </coozzy-form-select>
          </div>
          <div
            v-if="haveYouMovedLastThreeYears === 'YES' && debtCertificateLastFiveYears === 'NO'"
            class="row mt-2">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="streetOld"
                :state="$v.streetOld.$dirty && $v.streetOld.$error ? false : null"
                :name="$t('message.generic.street')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="streetNumberOld"
                :state="$v.streetNumberOld.$dirty && $v.streetNumberOld.$error ? false : null"
                :name="$t('message.generic.streetNumber')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="zipOld"
                :state="$v.zipOld.$dirty && $v.zipOld.$error ? false : null"
                :limit="4"
                :name="$t('message.generic.zip')"
                @input="getCityFromZip"
                @keydown.tab="isCitiesSuggested ? tabClicked() : ''" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-if="!isCitiesSuggested"
                :id="individualId"
                v-model="cityOld"
                :name="$t('message.generic.city')"
                :state="$v.cityOld.$dirty && $v.cityOld.$error ? false : null"
                type="text" />
              <template v-else>
                <label for="type">{{ $t('message.generic.city') }}*</label>
                <coozzy-form-select
                  id="city"
                  v-model="citySelect"
                  :state="$v.cityOld.$dirty && $v.cityOld.$error ? false : null"
                  @change="cityChanged">
                  <option
                    v-for="(item, typeIndex) in citiesValue"
                    :key="typeIndex"
                    :value="item"
                    :selected="typeIndex === 0">
                    {{ item.city }}
                  </option>
                </coozzy-form-select>
              </template>
            </div>
          </div>
          <coozzy-form-checkbox
            v-if="debtCertificateLastFiveYears === 'NO' && haveYouMovedLastThreeYears && !orderedCertificateValid"
            id="acceptsCreditTrustTerms"
            :key="'acceptsCreditTrustTerms'"
            v-model="acceptsCreditTrustTerms"
            :initial="acceptsCreditTrustTerms"
            :state="$v.acceptsCreditTrustTerms.$dirty && $v.acceptsCreditTrustTerms.$error ? false : null"
            class="align-self-center mt-3">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="termsHtml" />
            <!-- eslint-enable vue/no-v-html -->
          </coozzy-form-checkbox>
          <coozzy-form-checkbox
            v-if="debtCertificateLastFiveYears === 'NO' && haveYouMovedLastThreeYears && !orderedCertificateValid"
            id="confirmOrderAndPayment"
            :key="'confirmOrderAndPayment'"
            v-model="confirmOrderAndPayment"
            :initial="confirmOrderAndPayment"
            :state="$v.confirmOrderAndPayment.$dirty && $v.confirmOrderAndPayment.$error ? false : null"
            class="align-self-center mt-3">
            {{ $t('message.applicationForm.creditTrust.confirmOrderAndPayment') }}
          </coozzy-form-checkbox>
          <coozzy-button
            v-if="!orderCertificateProcessing && debtCertificateLastFiveYears === 'NO' && haveYouMovedLastThreeYears && !orderedCertificateValid"
            ref="btnClickInit"
            class="mt-2"
            design="prop-green"
            :disabled="!confirmOrderAndPayment || !acceptsCreditTrustTerms || errorDuringDownload"
            @click="checkIndividualBasicForOrderCertificate()">
            {{ $t('message.applicationForm.creditTrust.orderCertificate') }}
          </coozzy-button>
          <div
            v-if="errorDuringOrder"
            class="mt-1">
            <span>{{ $t('message.applicationForm.creditTrust.certificateOrderError') }}</span>
          </div>
          <div>
            <coozzy-spinner
              v-if="orderCertificateProcessing"
              class="align-self-center mt-2" />
            <span
              v-if="orderCertificateProcessing"
              style="vertical-align: super;">
              {{ $t('message.applicationForm.creditTrust.orderIsBeingProcessed') }}
            </span>
          </div>
          <div class="mt-2">
            <span v-if="orderedCertificateValid">{{ $t('message.applicationForm.creditTrust.certificateIssuedAndValid') }}</span>
            <span v-if="orderedCertificateValid === false">{{ $t('message.applicationForm.creditTrust.certificateIssuedAndNotValid') }}</span>
          </div>
          <div v-if="orderedCertificateValid">
            <div
              v-for="item in creditTrustCertificateMedia"
              :key="item.id"
              class="media-block col">
              <div
                class="file-block p-1 my-2">
                <a
                  data-v-09485260=""
                  :href="item.url"
                  target="_blank">
                  {{ item.filename }}
                </a>
                <coozzy-button
                  design="transparent"
                  class="delete-icon"
                  @click="deleteMedia(item.id, 'creditTrustCertificateMediaIds')">
                  <coozzy-delete-icon />
                </coozzy-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="doYouHaveCertificate === 'NO_DEBT_REGISTER' || debtCertificateLastFiveYears === 'YES' || orderedCertificateValid === false || wannaOrderCertificate === 'NO' || (individual.address.country && individual.address.country !== 'CH') || certificateNoIssuable"
      class="row mt-2"
      :class="verifiedCertificateValid === false ? 'moveToLast' : ''">
      <div class="col-12 col-md-12 mt-1 mb-1">
        <span v-if="!certificateNoIssuable">{{ $t('message.applicationForm.creditTrust.pleaseSubmitDebtEnforcementRegister') }}</span>
        <span v-else>{{ $t('message.applicationForm.creditTrust.certificateNoIssuable') }}</span>
      </div>
      <div class="col-12 col-md-6">
        <div>
          <b>{{ $t('message.applicationForm.debtEnforcementRegister') }}</b>
          <coozzy-button
            v-if="!debtEnforcementRegisterMediaIds.length"
            design="green"
            class="float-right"
            @click="uploadDocumentClicked('debtUpload')">
            {{ $t('message.manageDocuments.addDocument') }}
          </coozzy-button>
        </div>
        <coozzy-spinner
          v-if="debtUploadProcessing"
          class="align-self-center" />
        <div
          v-for="item in debtEnforcementRegisterMedia"
          :key="item.id"
          class="media-block col">
          <div
            class="file-block p-1 my-2">
            <a
              data-v-09485260=""
              :href="item.url"
              target="_blank">
              {{ item.filename }}
            </a>
            <coozzy-button
              design="transparent"
              class="delete-icon"
              @click="deleteMedia(item.id, 'debtEnforcementRegisterMediaIds')">
              <coozzy-delete-icon />
            </coozzy-button>
          </div>
        </div>
        <coozzy-form-file-input
          ref="debtUpload"
          v-model="debtUpload"
          class="d-none"
          accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx" />
      </div>
    </div>
    <div v-if="(!individual.address.country || individual.address.country === 'CH') && !certificateNoIssuable">
      <div
        v-if="doYouHaveCertificate === 'YES' || errorDuringDownload"
        class="mt-3">
        <div
          v-if="errorDuringDownload"
          class="mb-2">
          <span>{{ $t('message.applicationForm.creditTrust.certificateGetError') }}</span>
        </div>
        <div
          v-if="(!verifiedCertificateValid || !creditTrustCertificateMediaIds.length) && !wannaOrderCertificate">
          <coozzy-button
            v-if="!creditTrustCertificateMediaIds.length"
            design="green"
            @click="uploadDocumentClicked('creditTrustCertificate')">
            {{ $t('message.manageDocuments.addDocument') }}
          </coozzy-button>
        </div>
        <coozzy-spinner
          v-if="creditTrustCertificateUploadProcessing"
          class="align-self-center mt-2" />
        <div
          v-for="item in creditTrustCertificateMedia"
          :key="item.id"
          class="media-block col">
          <div
            class="file-block p-1 my-2">
            <a
              data-v-09485260=""
              :href="item.url"
              target="_blank">
              {{ item.filename }}
            </a>
            <coozzy-button
              design="transparent"
              class="delete-icon"
              @click="deleteMedia(item.id, 'creditTrustCertificateMediaIds')">
              <coozzy-delete-icon />
            </coozzy-button>
          </div>
        </div>
        <coozzy-form-file-input
          ref="creditTrustCertificate"
          v-model="creditTrustCertificate"
          class="d-none"
          accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx" />
        <div class="mt-2">
          <span v-if="verifiedCertificateValid && creditTrustCertificateMediaIds.length">{{ $t('message.applicationForm.creditTrust.certificateValid') }}</span>
          <span v-if="verifiedCertificateValid === false">{{ $t('message.applicationForm.creditTrust.certificateNotValid') }}</span>
        </div>
        <div
          v-if="verifiedCertificateValid === false && !errorDuringDownload"
          class="mt-2">
          <label for="haveYouMovedLastThreeYears">{{ $t('message.applicationForm.creditTrust.wannaOrderCertificate') }}</label>
          <coozzy-form-select
            id="wannaOrderCertificate"
            v-model="wannaOrderCertificate">
            <option value="">
              -
            </option>
            <option value="YES">
              {{ $t('message.generic.selectField.yes') }}
            </option>
            <option value="NO">
              {{ $t('message.generic.selectField.no') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import CommonApi from '@/misc/apis/CommonApi'
import Vue from 'vue'
import { media } from '@/mixins/media'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import router from '@/router/router'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput.vue'
import MediaApi from '@/misc/apis/MediaApi'
import { required } from 'vuelidate/lib/validators'
import { validation } from '@/mixins/validation'
import * as Sentry from '@sentry/vue'
import { address } from '@/mixins/address'

export default {
  name: 'ApplicationFormIndividualCreditTrust',
  components: {
    CoozzyFormFileInput,
    CoozzyFormCheckbox,
    CoozzyFormInput,
    CoozzySpinner,
    CoozzyButton,
    CoozzyDeleteIcon,
    CoozzyFormSelect
  },
  mixins: [
    validation,
    media,
    address
  ],
  props: {
    individualId: {
      type: String,
      required: true
    },
    isBusy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      doYouHaveCertificate: null,
      creditTrustCertificateUploadProcessing: false,
      creditTrustCertificateMedia: [],
      creditTrustCertificate: null,
      haveYouMovedLastThreeYears: null,
      streetOld: '',
      streetNumberOld: '',
      cityOld: '',
      zipOld: '',
      acceptsCreditTrustTerms: false,
      confirmOrderAndPayment: false,
      debtCertificateLastFiveYears: null,
      debtUploadProcessing: false,
      debtEnforcementRegisterMedia: [],
      debtUpload: null,
      orderedCertificateValid: null,
      verifiedCertificateValid: null,
      wannaOrderCertificate: null,
      orderCertificateProcessing: false,
      errorDuringOrder: false,
      errorDuringDownload: false,
      isCitiesSuggested: false,
      citySelect: null,
      citiesValue: [],
      certificateNoIssuable: false
    }
  },
  computed: {
    ...mapGetters('applicationForm', ['getIndividualByInternalId']),
    individual() {
      return this.getIndividualByInternalId(this.individualId)
    },
    debtEnforcementRegisterMediaIds: {
      get() {
        return this.individual.debtEnforcementRegisterMediaIds ? this.individual.debtEnforcementRegisterMediaIds : []
      },
      set(value) {
        const ind = this.individual
        ind.debtEnforcementRegisterMediaIds = value
        this.updateIndividual(ind)
      }
    },
    creditTrustCertificateMediaIds: {
      get() {
        return this.individual.creditTrustCertificateMediaIds ? this.individual.creditTrustCertificateMediaIds : []
      },
      set(value) {
        const ind = this.individual
        ind.creditTrustCertificateMediaIds = value
        this.updateIndividual(ind)
      }
    },
    termsHtml: {
      get() {
        return this.$t('message.applicationForm.creditTrust.acceptTerms')
      }
    }
  },
  watch: {
    creditTrustCertificate: function (newValue) {
      if (newValue !== null) {
        this.creditTrustCertificateUploadProcessing = true
        this.verifyCertificate(newValue, 'creditTrustCertificateMediaIds')
      }
    },
    debtUpload: function (newValue) {
      if (newValue !== null) {
        this.debtUploadProcessing = true
        this.documentCreated(newValue, 'debtEnforcementRegisterMediaIds')
      }
    },
    doYouHaveCertificate: function() {
      this.resetData()
    }
  },
  methods: {
    ...mapGetters('applicationForm', ['getMainIndividual']),
    ...mapMutations('applicationForm', ['updateIndividual']),
    uploadDocumentClicked(ref) {
      if (!this.readOnly) {
        const btn = this.$refs[ref].$el.children
        btn[0].click()
      }
    },
    deleteMedia(id, suffix) {
      this[suffix] = this[suffix].filter(e => e !== id)
      const noIdSuffix = suffix.replace('Ids', '')
      this[noIdSuffix] = this[noIdSuffix].filter(el => el.id !== id)
    },
    readFile(file, onLoadCallback) {
      return new Promise(function(resolve, reject) {
        let reader = new FileReader()
        reader.onload = function() { resolve(reader.result) }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },
    async verifyCertificate(document) {
      try {
        const promise = await this.readFile(document)
          if (promise.includes('data:application/pdf;base64,')) {
            const verifyCertificateResponse = await CommonApi.verifyCertificate(promise.replace('data:application/pdf;base64,', ''))
             if (verifyCertificateResponse?.verification?.issuerVerified) {
              this.verifiedCertificateValid = true
            } else {
              this.verifiedCertificateValid = false
               Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateNotValid'), { type: 'error' })
            }
          } else {
            Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
          }
          await this.documentCreated(document, 'creditTrustCertificateMediaIds')
          this.creditTrustCertificateUploadProcessing = false
      } catch (e) {
        if (!e.response.data?.message?.includes('Certificate was not found')) {
          Sentry.captureException(e)
        }
        this.verifiedCertificateValid = false
        Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateNotValid'), { type: 'error' })
        this.creditTrustCertificateUploadProcessing = false
      }
    },
    checkIndividualBasicForOrderCertificate() {
      this.$emit('order-certificate', this.individualId)
    },
    async orderCertificate() {
      if (this.isInvalid() === true) {
        return
      }
      this.orderCertificateProcessing = true
      this.errorDuringOrder = false
      Vue.toasted.show(this.$t('message.applicationForm.creditTrust.orderIsBeingProcessed'), { type: 'success' })
      const currentLanguage = router.app.$store.getters['internationalization/getCurrentLanguage']
      let validatedLanguage
      if (currentLanguage === 'de' || currentLanguage === 'fr') {
        validatedLanguage = currentLanguage
      } else {
        validatedLanguage = 'de'
      }
      const data = {
        locale: validatedLanguage,
        firstName: this.individual.firstName,
        lastName: this.individual.lastName,
        email: this.individual.email,
        street: this.individual.address.street,
        houseNumber: this.individual.address.streetNumber,
        postalCode: parseInt(this.individual.address.zip),
        city: this.individual.address.city,
        oldStreet: this.streetOld ? this.streetOld : null,
        oldHouseNumber: this.streetNumberOld ? this.streetNumberOld : null,
        oldPostalCode: this.zipOld ? this.zipOld : null,
        oldCity: this.cityOld ? this.cityOld : null,
        dateOfBirth: this.individual.dob
          ? {
              day: this.individual.dob.day,
              month: this.individual.dob.month,
              year: this.individual.dob.year
            }
: null
      }
      let orderCertificateResponse
      try {
        orderCertificateResponse = await CommonApi.orderCertificate(data)
      } catch (e) {
        Sentry.captureException(e)
        Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateOrderError'), { type: 'error' })
        this.errorDuringOrder = true
      }

      try {
        if (orderCertificateResponse && orderCertificateResponse.certificateIssuable && orderCertificateResponse.orderId) {
          const getCertificateResponse = await CommonApi.getCertificate(orderCertificateResponse.orderId)
          if (getCertificateResponse) {
            const blob = new Blob([getCertificateResponse], { type: 'application/pdf' })
            const file = new File([blob], 'CreditTrust Zertifikat.pdf')
            await this.documentCreated(file, 'creditTrustCertificateMediaIds', { type: 'application/pdf' })
            this.downloadCertificate(getCertificateResponse)
          }
          Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateIssuedAndValid'), { type: 'success' })
          this.orderedCertificateValid = true
        } else {
          this.certificateNoIssuable = true
          Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateNoIssuable'), { type: 'error' })
        }
      } catch (e) {
        Sentry.captureException(e)
        Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateGetError'), { type: 'error' })
        this.errorDuringDownload = true
      }
      this.mediaUploadProcessing = false
      this.debtUploadProcessing = false
      this.idCardUploadProcessing = false
      this.orderCertificateProcessing = false
    },
    downloadCertificate(data) {
      const file = new Blob([data], { type: 'application/pdf' })
      const link = document.createElement('a')
      const url = URL.createObjectURL(file)
      link.href = url
      link.download = 'CreditTrust Zertifikat.pdf'
      document.body.append(link)
      link.click()
      link.remove()
      setTimeout(() => URL.revokeObjectURL(link.href), 7000) // timeout for bigger files
    },
    async documentCreated(document, suffix) {
      const formData = new FormData()
      const fileName = this.editFileName(document.name)
      formData.append('file', document, fileName)
      formData.append('filename', document.name)
      formData.append('public', 'false')
      try {
        const response = await MediaApi.uploadMedia(formData)
        const docs = this[suffix] ? this[suffix] : []
        docs.push(response.id)
        this[suffix] = docs
        const noIdSuffix = suffix.replace('Ids', '')
        this[noIdSuffix].push(response)
        this.mediaUpload = null
        this.debtUpload = null
        this.idCardUpload = null
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message === 'Not supported MIME type') {
          Vue.toasted.show(this.$t('message.savingErrors.fileNotSupported'), { type: 'error' })
        } else {
          Sentry.captureException(e)
          Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
        }
      }
      this.mediaUploadProcessing = false
      this.debtUploadProcessing = false
      this.idCardUploadProcessing = false
    },
    async getCityFromZip() {
      if (this.zipOld.length > 3) {
        const zipData = await this.getZipInfo(this.zipOld)
          if (zipData) {
            if (zipData.cities.length > 1) {
              this.isCitiesSuggested = true
              this.citiesValue = zipData.cities
            } else {
              this.isCitiesSuggested = false
              this.$nextTick(() => {
                this.cityOld = zipData.cities[0].city
              })
            }
        }
      }
    },
    tabClicked() {
      if (this.citiesValue.length > 0) {
        this.citySelect = this.citiesValue[0].city
        this.cityChanged(this.citiesValue[0])
      }
    },
    cityChanged(event) {
      this.city = event.city
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        Sentry.setExtra('validationCreditTrust', this.$v)
        this.scrollToError()
        return true
      }
      return false
    },
    resetData() {
      this.creditTrustCertificateMedia = []
      this.creditTrustCertificate = null
      this.haveYouMovedLastThreeYears = null
      this.streetOld = ''
      this.streetNumberOld = ''
      this.cityOld = ''
      this.zipOld = ''
      this.acceptsCreditTrustTerms = false
      this.confirmOrderAndPayment = false
      this.debtCertificateLastFiveYears = null
      this.debtEnforcementRegisterMedia = []
      this.debtUpload = null
      this.orderedCertificateValid = null
      this.verifiedCertificateValid = null
      this.wannaOrderCertificate = null
      this.debtEnforcementRegisterMediaIds = []
      this.creditTrustCertificateMediaIds = []
      this.errorDuringOrder = false
      this.errorDuringDownload = false
      this.certificateNoIssuable = false
    }
  },
  validations() {
    const validationCreditTrust = {
      acceptsCreditTrustTerms: {
        checked: value => value === true
      },
      confirmOrderAndPayment: {
        checked: value => value === true
      }
    }
    if (this.haveYouMovedLastThreeYears === 'YES') {
      validationCreditTrust.streetOld = {
        required
      }
      validationCreditTrust.streetNumberOld = {
        required
      }
      validationCreditTrust.zipOld = {
        required
      }
      validationCreditTrust.cityOld = {
        required
      }
    }
    return validationCreditTrust
  }
}
</script>

<style lang="scss" scoped>
.moveToOneBeforeLast {
  order: 1
}
.moveToLast {
  order: 2
}
</style>
