<template>
  <section>
    <template>
      <div class="row">
        <div class="col-12">
          <coozzy-page-title :title="$t('message.ticketOverview.activities.title')" />
        </div>
      </div>

      <div class="row">
        <div class="col-12 custume-tabs">
          <!-- Action buttons on mobile -->
          <div class="row d-md-none">
            <div class="col-12 mb-2">
              <router-link
                :to="toPropertyClicked() !== undefined ? toPropertyClicked() : ''">
                <coozzy-button
                  design="secondary"
                  class="w-100">
                  {{ $t('message.onBoardingTabs.toProperty') }}
                </coozzy-button>
              </router-link>
            </div>
            <div
              v-if="id.startsWith('prop')"
              class="col-12 mb-2">
              <router-link
                :to="toObjectClicked() !== undefined ? toObjectClicked() : ''">
                <coozzy-button
                  design="secondary"
                  class="w-100">
                  {{ $t('message.onBoardingTabs.toObject') }}
                </coozzy-button>
              </router-link>
            </div>
            <div
              v-else-if="id.startsWith('building')"
              class="col-12 mb-2">
              <router-link
                :to="toBuildingClicked() !== undefined ? toBuildingClicked() : ''">
                <coozzy-button
                  design="secondary"
                  class="w-100">
                  {{ $t('message.onBoardingTabs.toBuilding') }}
                </coozzy-button>
              </router-link>
            </div>
            <div
              v-else-if="id.startsWith('contact')"
              class="col-12 mb-2">
              <router-link
                :to="toContactClicked() !== undefined ? toContactClicked() : ''">
                <coozzy-button
                  design="secondary"
                  class="w-100">
                  {{ $t('message.onBoardingTabs.toContact') }}
                </coozzy-button>
              </router-link>
            </div>
            <div
              v-else-if="id.startsWith('obj')"
              class="col-12 mb-2">
              <router-link
                :to="toObjectClicked() !== undefined ? toObjectClicked() : ''">
                <coozzy-button
                  design="secondary"
                  class="w-100">
                  {{ $t('message.onBoardingTabs.toObject') }}
                </coozzy-button>
              </router-link>
            </div>
            <div class="col-12 mb-2">
              <coozzy-button
                design="secondary"
                class="w-100"
                @click="backClicked">
                {{ $t('message.generic.back') }}
              </coozzy-button>
            </div>
          </div>
          <b-tabs
            v-model="tabIndex"
            no-key-nav
            class="mb-3">
            <b-tab
              :title="$t('message.ticketOverview.title')"
              class="p-0">
              <template #title>
                <router-link :to="getPropertyRoute('tickets')">
                  <span>{{ $t('message.ticketOverview.title') }}</span>
                </router-link>
              </template>
              <coozzy-card class="global-card no-top-border table-card">
                <div class="p-3">
                  <div
                    v-if="loading"
                    class="row">
                    <div class="col text-center">
                      <coozzy-spinner class="m-3" />
                    </div>
                  </div>
                  <ticket-overview-data
                    v-else
                    ref="ticket-overview-data"
                    :main-references="mainReference"
                    :all-references="selfReference"
                    :default-text="defaultText"
                    :default-type="defaultType"
                    :default-creator="defaultCreator"
                    :default-assigne="defaultAssigne"
                    :default-priority="defaultPriority"
                    :default-status="defaultStatus"
                    :default-empty-assigne="defaultEmptyAssignee"
                    :include-closed="true"
                    @create-ticket="createTicket" />
                </div>
              </coozzy-card>
            </b-tab>
            <b-tab
              v-if="!id.includes('device')"
              class="p-0"
              :title="$t('message.ticketOverview.activities.title')">
              <template #title>
                <router-link :to="getPropertyRoute('activities')">
                  <span>{{ $t('message.ticketOverview.activities.title') }}</span>
                </router-link>
              </template>
              <coozzy-card class="global-card no-top-border table-card">
                <div class="p-3">
                  <div
                    v-if="loading"
                    class="row">
                    <div class="col text-center">
                      <coozzy-spinner class="m-3" />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-12 col-md-2 offset-10">
                      <coozzy-button
                        design="prop-green"
                        class="w-100 sort"
                        @click="createActivity">
                        {{ $t('message.contact.activity.create') }}
                      </coozzy-button>
                    </div>
                  </div>
                  <div
                    id="rowConfig"
                    class="row mb-2">
                    <div class="col-12 col-md-2 mb-2 pr-md-0">
                      <coozzy-form-input
                        ref="searchText"
                        v-model="searchText"
                        :placeholder="$t('message.generic.search')"
                        @keyup="filterActivities()" />
                    </div>
                    <div class="col-12 col-md-2 mb-2 pr-md-0">
                      <coozzy-multiselect-html-option
                        v-model="searchCreator"
                        class="filter-inputs"
                        :options="creatorFilter"
                        :multiple="true"
                        :placeholder="$t('message.ticketOverview.ticket.creator')"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :custom-label="customLabelCreator"
                        :html-label="customLabelCreator"
                        :track-by="'value'"
                        :preselect-first="false"
                        :taggable="false"
                        @select="filterActivities"
                        @remove="filterActivities" />
                    </div>
                    <div
                      v-if="id.startsWith('prop')"
                      class="col-12 col-md-4 pr-md-0">
                      <coozzy-form-checkbox
                        v-model="showEntierProperty"
                        :initial="showEntierProperty"
                        :class="'pl-5 w-100'"
                        name="searchDueDate">
                        {{ $t('message.ticketOverview.ticket.showEntierProperty') }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="id.startsWith('building')"
                      class="col-12 col-md-4 pr-md-0">
                      <coozzy-form-checkbox
                        v-model="showEntierBuilding"
                        :initial="showEntierBuilding"
                        :class="'pl-5 w-100'"
                        name="searchDueDate">
                        {{ $t('message.ticketOverview.ticket.showEntierBuilding') }}
                      </coozzy-form-checkbox>
                    </div>
                    <div
                      v-if="isCompanyAdmin === true"
                      class="assembleListIcon ml-3">
                      <div
                        @click="openAssembleListModal()">
                        <coozzy-filters-icon
                          v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
                      </div>
                    </div>
                  </div>
                  <b-table
                    ref="activityTable"
                    bordered
                    hover
                    responsive="true"
                    stacked="md"
                    selectable
                    select-mode="single"
                    class="m-0"
                    :busy="isBusyActivity"
                    :fields="fieldsActivity"
                    :items="activityList"
                    @row-clicked="editActivity">
                    <!-- Busy state -->
                    <div
                      slot="table-busy"
                      class="text-center text-danger my-2">
                      <coozzy-spinner />
                    </div>

                    <!-- Headings -->
                    <template #head(ticketConnections)>
                      {{ $t('message.tableColumnHeadings.ticketConnections') }}
                    </template>
                    <template #head(subject)>
                      {{ $t('message.calendar.reminder.subject') }}
                    </template>
                    <template #head(text)>
                      {{ $t('message.tableColumnHeadings.text') }}
                    </template>
                    <template #head(duration)>
                      {{ $t('message.tableColumnHeadings.duration') }}
                    </template>

                    <!-- Columns -->
                    <template #cell(ticketConnections)="data">
                      <template
                        v-for="ref in data.item.references">
                        <p
                          v-if="getConnection(ref.id) !== undefined"
                          :key="data.item.id + ref.id"
                          class="mb-0">
                          <span
                            v-if="ref.id.startsWith('device') && computedAllConnections.devices">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDeviceDetail(getConnection(ref.id)) : ''">
                              <template v-if="getConnection(ref.id).type && getConnection(ref.id).type !== 'DEVICE_TYPE_UNDEFINED'">
                                {{ $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).length > 50 ? $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).slice(0,50)+ '...' : $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type) }}
                              </template>
                              <template v-else>
                                -
                              </template> &nbsp;
                            </router-link>
                            <span v-else>
                              <template v-if="getConnection(ref.id).type && getConnection(ref.id).type !== 'DEVICE_TYPE_UNDEFINED'">
                                {{ $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).length > 50 ? $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).slice(0,50)+ '...' : $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type) }}
                              </template>
                              <template v-else>
                                -
                              </template>
                            </span>
                          </span>
                          <span
                            v-if="ref.id.startsWith('obj') && computedAllConnections.objects">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'obj') : ''">
                              {{ getConnection(ref.id).name !== '' ? getConnection(ref.id).name : getConnection(ref.id).number ? getConnection(ref.id).number : getConnection(ref.id).numericId }}
                            </router-link>
                            <span v-else>
                              {{ getConnection(ref.id).name !== '' ? getConnection(ref.id).name : getConnection(ref.id).number ? getConnection(ref.id).number : getConnection(ref.id).numericId }}
                            </span>
                          </span>
                          <span
                            v-else-if="ref.id.startsWith('building') && computedAllConnections.buildings">
                            <router-link
                              v-if="!isMarketingModule"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'building') : ''">
                              {{ !getConnection(ref.id) ? '' : getConnection(ref.id).name }}
                            </router-link>
                            <span v-else>
                              {{ !getConnection(ref.id) ? '' : getConnection(ref.id).name }}
                            </span>
                          </span>
                          <span
                            v-else-if="ref.id.startsWith('prop') && computedAllConnections.properties">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'prop') : ''">
                              {{ !getConnection(ref.id) ? '' : getConnection(ref.id).name }}
                            </router-link>
                            <span v-else>
                              {{ !getConnection(ref.id) ? '' : getConnection(ref.id).name }}
                            </span>
                          </span>
                          <span
                            v-else-if="ref.id.startsWith('contact') && computedAllConnections.contacts">
                            <router-link
                              v-if="!isMarketingModule"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'contact') : ''">
                              {{ !getConnection(ref.id) ? '' : getConnection(ref.id).name }}
                            </router-link>
                            <span v-else>
                              {{ !getConnection(ref.id) ? '' : getConnection(ref.id).name }}
                            </span>
                          </span>
                          <span v-if="ref.id.startsWith('request')">
                            <router-link
                              v-if="getConnection(ref.id).requesters.length > 0"
                              class="external-link"
                              :to="redirectToRequestDetail(ref.objectId, ref.id) !== undefined ? redirectToRequestDetail(ref.objectId, ref.id) : ''">
                              {{ getContactName(getConnection(ref.id).requesters[0].contactId) }}
                            </router-link>
                          </span>
                          <span
                            v-else-if="ref.id.startsWith('tenancy')">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), ref.id, 'tenants') : ''">
                              <template>
                                <span
                                  v-for="(contact, j) in getConnection(ref.id).tenant.contactIds"
                                  :key="data.item.id + j">
                                  {{ getContactName(contact) }}
                                </span>
                              </template>
                            </router-link>
                            <span
                              v-else>
                              <template>
                                <span
                                  v-for="(contact, j) in getConnection(ref.id).tenant.contactIds"
                                  :key="data.item.id + j">
                                  {{ getContactName(contact) }}
                                </span>
                              </template>
                            </span>
                          </span>
                          <span
                            v-else-if="ref.id.startsWith('condo')">
                            <router-link
                              v-if="!isMarketingModule"
                              class="external-link"
                              :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), ref.id, 'condominium') : ''">
                              <span
                                v-for="(contact, j) in getConnection(ref.id).owner.contactIds"
                                :key="data.item.id + j">
                                {{ getContactName(contact) }}
                              </span>
                            </router-link>
                            <span
                              v-else>
                              <span
                                v-for="(contact, j) in getConnection(ref.id).owner.contactIds"
                                :key="data.item.id + j">
                                {{ getContactName(contact) }}
                              </span>
                            </span>
                          </span>
                        </p>
                        <p
                          v-else
                          :key="data.item.id + 'else-list-' + ref.id">
                          -
                        </p>
                      </template>
                    </template>

                    <template
                      #cell(subject)="data">
                      <p class="m-0 auto-size">
                        <coozzy-paper-clip-icon v-if="data.item.mediaIds.length > 0" />
                        {{ data.item.subject | displayOptionalValue }}
                      </p>
                    </template>
                    <template
                      #cell(text)="data">
                      {{ data.item.text | displayOptionalValue }}
                    </template>
                    <template
                      #cell(duration)="data">
                      {{ getDuration(data.item.timestampStart, data.item.timestampEnd) }}
                    </template>
                  </b-table>
                </div>
              </coozzy-card>
              <create-activity-modal
                ref="activity-creation"
                :key="keyActivity"
                :activity-detail="selectedActivity"
                :ids="selectedActivity ? selectedActivity.references.map(x => x.id) : [id]"
                @activity-updated="updateTheList"
                @activity-created="addToList" />
            </b-tab>
            <template #tabs-end>
              <b-nav-item
                v-if="id.startsWith('prop')"
                href="#"
                class="d-none d-md-block item-button">
                <router-link
                  :to="toPropertyClicked() !== undefined ? toPropertyClicked() : ''">
                  <coozzy-button
                    design="secondary"
                    :class="id.startsWith('device') || id.startsWith('tenancy') || id.startsWith('condo') ? 'nav-button-center' : 'nav-button-right'"
                    class="nav-button">
                    {{ $t('message.onBoardingTabs.toProperty') }}
                  </coozzy-button>
                </router-link>
              </b-nav-item>
              <b-nav-item
                v-else-if="id.startsWith('building')"
                href="#"
                class="d-none d-md-block item-button">
                <router-link
                  :to="toBuildingClicked() !== undefined ? toBuildingClicked() : ''">
                  <coozzy-button
                    design="secondary"
                    :class="id.startsWith('device') || id.startsWith('tenancy') || id.startsWith('condo') ? 'nav-button-center' : 'nav-button-right'"
                    class="nav-button">
                    {{ $t('message.onBoardingTabs.toBuilding') }}
                  </coozzy-button>
                </router-link>
              </b-nav-item>
              <b-nav-item
                v-else-if="id.startsWith('contact')"
                href="#"
                class="d-none d-md-block item-button">
                <router-link
                  :to="toContactClicked() !== undefined ? toContactClicked() : ''">
                  <coozzy-button
                    design="secondary"
                    :class="id.startsWith('device') || id.startsWith('tenancy') || id.startsWith('condo') ? 'nav-button-center' : 'nav-button-right'"
                    class="nav-button">
                    {{ $t('message.onBoardingTabs.toContact') }}
                  </coozzy-button>
                </router-link>
              </b-nav-item>
              <b-nav-item
                v-else-if="id.startsWith('obj')"
                href="#"
                class="d-none d-md-block item-button">
                <router-link
                  :to="toObjectClicked() !== undefined ? toObjectClicked() : ''">
                  <coozzy-button
                    design="secondary"
                    :class="id.startsWith('device') || id.startsWith('tenancy') || id.startsWith('condo') ? 'nav-button-center' : 'nav-button-right'"
                    class="nav-button">
                    {{ $t('message.onBoardingTabs.toObject') }}
                  </coozzy-button>
                </router-link>
              </b-nav-item>
              <b-nav-item
                v-if="id.startsWith('device') || id.startsWith('tenancy') || id.startsWith('condo')"
                href="#"
                class="d-none d-md-block item-button">
                <coozzy-button
                  design="secondary"
                  class="nav-button nav-button-right"
                  @click="backClicked">
                  {{ $t('message.generic.back') }}
                </coozzy-button>
              </b-nav-item>
            </template>
          </b-tabs>
        </div>
      </div>
    </template>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFieldsActivity"
      :default-fields="dataFieldsActivity"
      @save-fields="saveFields" />
  </section>
</template>

<script>
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyPaperClipIcon from '@/framework/components/icons/CoozzyPaperClipIcon'
import CoozzyMultiselectHtmlOption from '@/framework/components/multiselect/CoozzyMultiselectHtmlOption'
import TicketOverviewData from './TicketOverviewData'
import { user } from '@/mixins/user'
import { routeChecks } from '@/mixins/routeChecks'
import ContactApi from '@/misc/apis/ContactApi'
import Vue from 'vue'
import ObjectApi from '@/misc/apis/ObjectApi'
import RequestApi from '@/marketingBasic/api/RequestApi'
import UserApi from '@/misc/apis/UserApi'
import RoomApi from '@/misc/apis/RoomApi'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon.vue'
import SearchApi from '@/misc/apis/SearchApi'
import AssembleListModal from '@/contact/components/AssembleListModal.vue'
import { tables } from '@/mixins/tables'

export default {
  name: 'TicketsAndActivitiesView',
  components: {
    AssembleListModal,
    CoozzyFiltersIcon,
    CoozzyPaperClipIcon,
    CoozzyFormCheckbox,
    CoozzyMultiselectHtmlOption,
    CoozzyFormInput,
    CoozzySpinner,
    CoozzyCard,
    CoozzyPageTitle,
    CreateActivityModal,
    CoozzyButton,
    TicketOverviewData
  },
  mixins: [user, routeChecks, tables],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('onboarding/setOpenedCollapses')
    next()
  },
  beforeRouteUpdate (to, from, next) {
    if (to.path === from.path && to.query.view === 'activities' && this.tabIndex === 0) {
      this.tabIndex = 1
    }
    if (to.path === from.path && to.query.view === 'tickets' && this.tabIndex === 1) {
      this.tabIndex = 0
    }
    next()
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    view: {
      type: String,
      default: 'tickets'
    },
    elementSelected: {
      type: String,
      default: ''
    },
    othersIds: {
      type: [Array, String],
      default: () => []
    },
    defaultText: {
      type: String,
      default: ''
    },
    defaultType: {
      type: String,
      default: ''
    },
    defaultCreator: {
      type: String,
      default: ''
    },
    defaultAssigne: {
      type: String,
      default: ''
    },
    defaultPriority: {
      type: String,
      default: ''
    },
    defaultStatus: {
      type: String,
      default: ''
    },
    defaultEmptyAssignee: {
      type: Boolean,
      default: false
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.ticketOverview.activities.title')
    }
  },
  data() {
    return {
      selfReference: [],
      tabIndex: 0,
      keyActivity: 0,
      selectedActivity: null,
      tickets: [],
      isBusyActivity: false,
      activityList: [],
      activityUnsortedList: [],
      allConnections: {
        properties: [],
        buildings: [],
        objects: [],
        condominiums: [],
        tenancies: [],
        devices: [],
        contacts: [],
        requests: [],
        rooms: [],
        owners: []
      },
      listBuilding: [],
      listContacts: [],
      fieldsActivity: [],
      dataFieldsActivity: [
        {
          key: 'ticketConnections',
          label: this.$t('message.tableColumnHeadings.ticketConnections'),
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'subject',
          label: this.$t('message.calendar.reminder.subject'),
          tdClass: 'align-middle max-w-subject',
          selected: true
        },
        {
          key: 'text',
          label: this.$t('message.tableColumnHeadings.text'),
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'duration',
          label: this.$t('message.tableColumnHeadings.duration'),
          tdClass: 'align-middle',
          selected: true
        }
      ],
      selectedFieldsActivity: [],
      loading: false,
      showEntierBuilding: false,
      showEntierProperty: false,
      listOfReferences: [],
      searchText: '',
      searchCreator: [],
      allReferences: [],
      prevRoute: null
    }
  },
  computed: {
    computedAllConnections() {
      return this.allConnections
    },
    mainReference() {
      let ids = [this.id]
      if (this.othersIds !== '') {
       ids = ids.concat(this.othersIds)
      }
      return ids
    },
    creatorFilter() {
      if (this.activityUnsortedList.length > 0) {
        let creatorList = []
        const current = this
        let unassigned = 0
        const counts = []
        this.activityList.forEach(element => {
          if (element.creator) {
            creatorList.push(element)
          } else {
            unassigned += 1
          }
        })
        creatorList = [].concat.apply([], creatorList)
        creatorList.forEach(function (x) {
          const existingCount = counts.filter(e => e.value === x.creator.userId)
          if (existingCount.length === 0) {
            const sameCreator = current.activityList.filter(z => x.creator && z.creator && z.creator.userId === x.creator.userId)
            if (sameCreator.length > 1) {
              counts.push({
                count: sameCreator.length,
                value: x.creator.userId,
                company: x.creator.accountName
              })
            } else {
              counts.push({
                count: 1,
                value: x.creator.userId,
                company: x.creator.accountName
              })
            }
          }
        })
        if (unassigned !== 0) {
          counts.push({
            count: unassigned,
            value: null,
            company: null
          })
        }
        return counts.sort((a, b) => (a.company === null ? 0 : a.company > b.company ? 1 : -1))
      }
      return []
    }
  },
  watch: {
    showEntierBuilding: function (newVal) {
      this.listOfReferences = this.mainReference
      if (newVal) {
        this.listOfReferences = this.listOfReferences.concat(this.allReferences)
      }
      this.loadActivities()
    },
    showEntierProperty: function (newVal) {
      this.listOfReferences = this.mainReference
      if (newVal) {
        this.listOfReferences = this.listOfReferences.concat(this.allReferences)
      }
      this.loadActivities()
    },
    tabIndex: function (newVal) {
      const viewName = newVal === 1
        ? 'activities'
        : 'tickets'
      const othersIds = this.othersIds instanceof Array && this.othersIds.length > 0 ? this.othersIds.join(',') : this.othersIds instanceof String ? this.othersIds : ''
      if (this.$route && (!this.$route.query || this.$route.query.view !== viewName)) {
        let query = { view: viewName }
        if (othersIds !== '') {
          query.othersIds = othersIds
        }
        this.$router.replace({
          name: this.$route.name,
          params: { id: this.id },
          query: query
        })
      }
    }
  },
  mounted() {
    this.dataFieldsActivity = this.dataFieldsActivity.filter(x => x.selected).concat(this.dataFieldsActivity.filter(x => !x.selected))
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'list_activities_overview') {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fieldsActivity.push(...this.dataFieldsActivity.filter(x => x.selected))
        this.selectedFieldsActivity = JSON.parse(JSON.stringify(this.dataFieldsActivity))
      }
    }).catch(e => {
      console.log(e)
    })
    this.listOfReferences = this.mainReference
    this.loadActivities()
    if (this.id.startsWith('prop')) {
      ObjectApi.getPropertyListByIds([this.id])
        .then(response => {
          this.allReferences = this.allReferences.concat(response.properties[0].buildingIds)
          ObjectApi.getBuildingsListByIds(response.properties[0].buildingIds).then(buildings => {
            this.allReferences = this.allReferences.concat([].concat.apply([], buildings.buildings.map(x => x.objectIds)))
            ObjectApi.getDevicesByReferenceIds([this.id].concat([].concat.apply([], buildings.buildings.map(x => x.objectIds))))
              .then(response => {
                this.allReferences = this.allReferences.concat(response.devices.map(x => x.id))
                if (response.devices.filter(x => x.referenceId === this.id).length > 0) {
                  this.selfReference = this.selfReference.concat(response.devices.filter(x => x.referenceId === this.id).map(x => x.id))
                }
                const roomIds = []
                response.devices.forEach(element => {
                  if (element.roomId !== '') {
                    roomIds.push(element.roomId)
                  }
                })
                if (roomIds.length > 0) {
                  RoomApi.listById(roomIds).then(responseRoom => {
                    this.loading = false
                    this.allReferences = this.allReferences.concat(responseRoom.rooms.map(x => x.id))
                  })
                }
              })
          })
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.property'), { type: 'error' })
        })
    } else if (this.id.startsWith('building')) {
      ObjectApi.getBuildingsListByIds([this.id]).then(buildings => {
        this.allReferences = this.allReferences.concat([].concat.apply([], buildings.buildings.map(x => x.objectIds)))
        ObjectApi.getDevicesByReferenceIds([this.id].concat([].concat.apply([], buildings.buildings.map(x => x.objectIds))))
          .then(response => {
            this.allReferences = this.allReferences.concat(response.devices.map(x => x.id))
            if (response.devices.filter(x => x.referenceId === this.id).length > 0) {
              this.selfReference = this.selfReference.concat(response.devices.filter(x => x.referenceId === this.id).map(x => x.id))
            }
            RoomApi.getRoomsByReferenceIds([this.id])
              .then(responseRoom => {
                this.loading = false
                this.selfReference = this.selfReference.concat(responseRoom.rooms.map(x => x.id))
              })
            const roomIds = []
            response.devices.forEach(element => {
              if (element.roomId !== '') {
                roomIds.push(element.roomId)
              }
            })
            if (roomIds.length > 0) {
              RoomApi.listById(roomIds).then(responseRoom => {
                this.allReferences = this.allReferences.concat(responseRoom.rooms.map(x => x.id))
              })
            }
          })
      })
    } else if (this.id.startsWith('obj')) {
      ObjectApi.getObject(this.id).then(obj => {
        this.allReferences.push(obj.object.buildingId)
        ObjectApi.getBuildingsListByIds([obj.object.buildingId]).then(buildings => {
          this.allReferences = this.allReferences.concat([].concat.apply([], buildings.buildings.map(x => x.objectIds)))
          ObjectApi.getDevicesByReferenceIds([this.id].concat([].concat.apply([], buildings.buildings.map(x => x.objectIds))))
            .then(response => {
              this.allReferences = this.allReferences.concat(response.devices.map(x => x.id))
              if (response.devices.filter(x => x.referenceId === this.id).length > 0) {
                this.selfReference = this.selfReference.concat(response.devices.filter(x => x.referenceId === this.id).map(x => x.id))
              }
              RoomApi.getRoomsByReferenceIds([this.id])
                .then(responseRoom => {
                  this.loading = false
                  this.selfReference = this.selfReference.concat(responseRoom.rooms.map(x => x.id))
                })
              const roomIds = []
              response.devices.forEach(element => {
                if (element.roomId !== '') {
                  roomIds.push(element.roomId)
                }
              })
              if (roomIds.length > 0) {
                RoomApi.listById(roomIds).then(responseRoom => {
                  this.allReferences = this.allReferences.concat(responseRoom.rooms.map(x => x.id))
                })
              }
            })
        })
      })
    } else if (this.id.startsWith('tenancy')) {
      ObjectApi.getTenancyById(this.id)
        .then(response => {
          this.allReferences.push(response.tenancy.objectId)
          ObjectApi.getObject(response.tenancy.objectId).then(obj => {
            this.allReferences.push(obj.object.buildingId)
            ObjectApi.getBuildingsListByIds([obj.object.buildingId]).then(buildings => {
              this.allReferences = this.allReferences.concat([].concat.apply([], buildings.buildings.map(x => x.objectIds)))
              ObjectApi.getDevicesByReferenceIds([this.id].concat([].concat.apply([], buildings.buildings.map(x => x.objectIds))))
                .then(response => {
                  this.allReferences = this.allReferences.concat(response.devices.map(x => x.id))
                  if (response.devices.filter(x => x.referenceId === response.objectId).length > 0) {
                    this.selfReference = this.selfReference.concat(response.devices.filter(x => x.referenceId === response.objectId).map(x => x.id))
                  }
                  RoomApi.getRoomsByReferenceIds([this.id])
                    .then(responseRoom => {
                      this.loading = false
                      this.selfReference = this.selfReference.concat(responseRoom.rooms.map(x => x.id))
                    })
                  const roomIds = []
                  response.devices.forEach(element => {
                    if (element.roomId !== '') {
                      roomIds.push(element.roomId)
                    }
                  })
                  if (roomIds.length > 0) {
                    RoomApi.listById(roomIds).then(responseRoom => {
                      this.allReferences = this.allReferences.concat(responseRoom.rooms.map(x => x.id))
                    })
                  }
                })
            })
          })
        })
    } else if (this.id.startsWith('device')) {
      ObjectApi.listDevicesByIds([this.id])
        .then(response => {
          if (response.devices.length > 0) {
            const device = response.devices[0]
            this.allReferences.push(device.referenceId)
            if (device.roomId) {
              RoomApi.listById([device.roomId])
                .then(responseRoom => {
                  this.loading = false
                  this.selfReference = this.selfReference.concat(responseRoom.rooms.map(x => x.id))
                })
            } else {
              this.loading = false
            }
          } else {
            this.loading = false
          }
        })
    }
    this.$nextTick(() => {
      this.openSelectedTabs()
    })
  },
  methods: {
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFieldsActivity, setting, false, false)
      this.fieldsActivity = result?.fields
      this.selectedFieldsActivity = result?.selectedFields
      this.$refs.activityTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'list_activities_overview').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    createActivity() {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.selectedActivity = null
      this.$nextTick(() => {
        this.$refs['activity-creation'].show()
      })
    },
    updateTheList(updatedActivity) {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      // update the list of activity with the updated activity
      const index = this.activityList.findIndex(c => c.id === updatedActivity.id)
      this.$set(this.activityList, index, updatedActivity)
    },
    loadConnections(tickets) {
      const arrCondo = []
      const arrTenancy = []
      const arrObj = []
      const arrProp = []
      const arrBuilding = []
      const arrContact = []
      const arrDevice = []
      const arrRequest = []
      const arrOwner = []
      tickets.forEach(element => {
        for (let index = 0; index < element.references.length; index++) {
          const ref = element.references[index]
          if (ref.id.startsWith('condominium')) {
            arrCondo.push(ref.id)
          } else if (ref.id.startsWith('tenancy')) {
            arrTenancy.push(ref.id)
          } else if (ref.id.startsWith('obj')) {
            arrObj.push(ref.id)
          } else if (ref.id.startsWith('building')) {
            arrBuilding.push(ref.id)
          } else if (ref.id.startsWith('prop')) {
            arrProp.push(ref.id)
          } else if (ref.id.startsWith('contact')) {
            arrContact.push(ref.id)
          } else if (ref.id.startsWith('device')) {
            arrDevice.push(ref.id)
          } else if (ref.id.startsWith('request')) {
            arrRequest.push(ref.id)
          }
        }
        if (element.ownerId && !arrOwner.includes(element.ownerId)) {
          arrOwner.push(element.ownerId)
        }
      })
      const promises = []
      if (arrCondo.length > 0) {
        promises.push(ObjectApi.getCondominiumByIds(arrCondo))
      }
      if (arrTenancy.length > 0) {
        promises.push(ObjectApi.getTenanciesByIds(arrTenancy))
      }
      if (arrObj.length > 0) {
        promises.push(ObjectApi.listObjectsByIds(arrObj))
      }
      if (arrBuilding.length > 0) {
        promises.push(ObjectApi.getBuildingsListByIds(arrBuilding))
      }
      if (arrProp.length > 0) {
        promises.push(ObjectApi.getPropertyListByIds(arrProp))
      }
      if (arrContact.length > 0) {
        promises.push(ContactApi.contactResolve(arrContact))
      }
      if (arrDevice.length > 0) {
        promises.push(ObjectApi.listDevicesByIds(arrDevice))
      }
      if (arrRequest.length > 0) {
        promises.push(RequestApi.listByIds(arrRequest))
      }
      if (arrOwner.length > 0) {
        promises.push(UserApi.listAccountsByIds(arrOwner))
      }
      Promise.all(promises)
        .then(values => {
          let contactIds = []
          values.forEach(element => {
            if (element.tenancies) {
              this.allConnections.tenancies = element.tenancies
              const objectIds = element.tenancies.map(x => x.objectId)
              ObjectApi.getBuildingByObjectIds(objectIds).then(responseBuild => {
                this.listBuilding = this.listBuilding.length > 0
                  ? this.listBuilding.concat(responseBuild.buildings)
                  : responseBuild.buildings
              })
              contactIds = contactIds.concat([...new Set([].concat(...element.tenancies.map((o) => o.tenant.contactIds)))])
            } else if (element.condominiums) {
              this.allConnections.condominiums = element.condominiums
              const objectIds = element.condominiums.map(x => x.objectId)
              ObjectApi.getBuildingByObjectIds(objectIds).then(responseBuild => {
                this.listBuilding = this.listBuilding.length > 0
                  ? this.listBuilding.concat(responseBuild.buildings)
                  : responseBuild.buildings
              })
              contactIds = contactIds.concat([...new Set([].concat(...element.condominiums.map((o) => o.owner.contactIds)))])
            } else if (element.devices) {
              this.allConnections.devices = element.devices
              const objectIds = []
              const roomIds = []
              element.devices.forEach(element => {
                if (element.referenceId.startsWith('obj')) {
                  objectIds.push(element.referenceId)
                }
                if (element.roomId !== '') {
                  roomIds.push(element.roomId)
                }
              })
              if (objectIds.length > 0) {
                ObjectApi.getBuildingByObjectIds(objectIds).then(responseBuild => {
                  this.listBuilding = this.listBuilding.length > 0
                    ? this.listBuilding.concat(responseBuild.buildings)
                    : responseBuild.buildings
                })
              }
              if (roomIds.length > 0) {
                RoomApi.listById(roomIds).then(responseRoom => {
                  this.allConnections.rooms = responseRoom.rooms
                })
              }
            } else if (element.objects) {
              this.allConnections.objects = element.objects
            } else if (element.buildings) {
              this.allConnections.buildings = element.buildings
            } else if (element.properties) {
              this.allConnections.properties = element.properties
            } else if (element.requests) {
              this.allConnections.requests = element.requests
              contactIds = contactIds.concat([...new Set([].concat(...element.requests.map((req) => req.requesters[0]?.contactId)))])
            } else if (element.accounts) {
              this.allConnections.owners = element.accounts
            } else {
              this.allConnections.contacts = element.persons?.concat(element.companies)
            }
          })
          ContactApi.contactResolve(contactIds)
            .then(responseCon => {
              this.listContacts = this.listContacts.concat(responseCon.persons.concat(responseCon.companies)).filter(c => contactIds.includes(c.id))
            })
        })
    },
    getConnection(id) {
      if (id.startsWith('condominium')) {
        return this.allConnections.condominiums?.find(x => x.id === id)
      } else if (id.startsWith('tenancy')) {
        return this.allConnections.tenancies?.find(x => x.id === id)
      } else if (id.startsWith('obj')) {
        return this.allConnections.objects?.find(x => x.id === id)
      } else if (id.startsWith('building')) {
        return this.allConnections.buildings?.find(x => x.id === id)
      } else if (id.startsWith('prop')) {
        return this.allConnections.properties?.find(x => x.id === id)
      } else if (id.startsWith('contact')) {
        return this.allConnections.contacts?.find(x => x.id === id)
      } else if (id.startsWith('device')) {
        return this.allConnections.devices?.find(x => x.id === id)
      } else if (id.startsWith('request')) {
        return this.allConnections.requests?.find(x => x.id === id)
      }
    },
    redirectToDetailPage(item, type) {
      if (type === 'contact') {
        const contactId = item.id
        if (contactId !== null) {
          if (this.isOwnerModule) {
            return {
              name: 'OwnerContactDetailsView',
              params: { id: contactId }
            }
          } else if (this.isAssetModule) {
            return {
              name: 'AssetContactDetailsView',
              params: { id: contactId }
            }
          } else if (this.isAccountingModule) {
            return {
              name: 'AccountingContactDetailsView',
              params: { id: contactId }
            }
          } else if (this.isMarketingModule) {
            return {
              name: 'MarketingContactDetailsView',
              params: { id: contactId }
            }
          } else {
            return {
              name: 'AdminContactDetailsView',
              params: { id: contactId }
            }
          }
        }
      } else if (type === 'obj') {
        const buildingId = item?.buildingId
        const objectId = item?.id
        const view = 'objects'
        if (this.isOwnerModule) {
          return {
            name: 'OwnerBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAdminModule) {
          return {
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        }
      } else if (type === 'building') {
        const buildingId = item.id
        if (this.isOwnerModule) {
          return {
            name: 'OwnerBuildingDetailsView',
            params: { id: buildingId },
            query: { view: 'building' }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetBuildingDetailsView',
            params: { id: buildingId },
            query: { view: 'building' }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: { view: 'building' }
          }
        } else if (this.isAdminModule) {
          return {
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: { view: 'building' }
          }
        }
      } else if (type === 'prop') {
        const propertyId = item.id
        if (this.isOwnerModule) {
          return { name: 'OwnerPropertyDetailsView', params: { id: propertyId } }
        } else if (this.isAdminModule) {
          return { name: 'AdminPropertyDetailsView', params: { id: propertyId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetPropertyDetailsView', params: { id: propertyId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingPropertyDetailsView', params: { id: propertyId } }
        }
      }
      return ''
    },
    loadActivities() {
      this.loading = true
      ContactApi.getActivitiesByReferenceIdsV3([...new Set(this.listOfReferences)])
        .then(async response => {
          this.activityUnsortedList = response.activities
          this.activityList = response.activities
          this.loadConnections(response.activities)
          this.activityList.sort(function (a, b) {
            return b.timestamp - a.timestamp
          })
          const employeeIds = this.activityList.flatMap(d => d.employeeContactIds)
          if (employeeIds.length !== 0) {
            const ids = [...new Set(employeeIds)]
            ContactApi.contactResolve(ids)
              .then(response => {
                this.activityEmployeeList = response
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
              })
          }
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.activity'), { type: 'error' })
        })
        .finally(() => {
          this.loading = false
          this.isBusyActivity = false
          this.showModalActivity = false
        })
    },
    getPropertyRoute(view) {
      if (this.isOwnerModule) {
        return { name: 'OwnerActivitiesTicketView', params: { id: this.id }, query: { view: view, othersIds: this.othersIds } }
      } else if (this.isAdminModule) {
        return { name: 'AdminActivitiesTicketView', params: { id: this.id }, query: { view: view, othersIds: this.othersIds } }
      } else if (this.isAssetModule) {
        return { name: 'AssetActivitiesTicketView', params: { id: this.id }, query: { view: view, othersIds: this.othersIds } }
      } else if (this.isAccountingModule) {
        return { name: 'AccountingActivitiesTicketView', params: { id: this.id }, query: { view: view, othersIds: this.othersIds } }
      } else {
        return { name: 'MarketingActivitiesTicketView', params: { id: this.id }, query: { view: view, othersIds: this.othersIds } }
      }
    },
    createTicket() {
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation', query: { ids: [this.id] } }, () => {
        })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation', query: { ids: [this.id] } }, () => {
        })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation', query: { ids: [this.id] } }, () => {
        })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketCreation', query: { ids: [this.id] } }, () => {
        })
      } else {
        this.$router.push({ name: 'MarketingTicketCreation', query: { ids: [this.id] } }, () => {
        })
      }
    },
    editActivity(item) {
      this.showModalActivity = true
      this.selectedActivity = item
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.$nextTick(function () {
        this.$refs['activity-creation'].show()
      })
    },
    updateElementSelected(item) {
      if (item && item.id && this.elementSelected !== item.id) {
        this.$router.replace({
          name: this.$route.name,
          params: { id: this.id },
          query: {
            view: this.$route.query.view,
            elementSelected: item.id
          }
        })
      }
    },
    backClicked() {
      this.$router.go(-1)
    },
    toPropertyClicked() {
      if (this.isOwnerModule) {
        return { name: 'OwnerPropertyDetailsView', params: { id: this.id } }
      } else if (this.isAdminModule) {
        return { name: 'AdminPropertyDetailsView', params: { id: this.id } }
      } else if (this.isAssetModule) {
        return { name: 'AssetPropertyDetailsView', params: { id: this.id } }
      } else if (this.isAccountingModule) {
        return { name: 'AccountingPropertyDetailsView', params: { id: this.id } }
      }
    },
    toBuildingClicked() {
      let routeName = ''
      if (this.isOwnerModule) {
        routeName = 'OwnerBuildingDetailsView'
      } else if (this.isAssetModule) {
        routeName = 'AssetBuildingDetailsView'
      } else if (this.isAccountingModule) {
        routeName = 'AccountingBuildingDetailsView'
      } else if (this.isAdminModule) {
        routeName = 'AdminBuildingDetailsView'
      }
      return {
        name: routeName,
        params: { id: this.id },
        query: { view: 'building' }
      }
    },
    redirectToDetail(item, objectId, view) {
      const buildingId = this.listBuilding.find(y => y.objectIds.includes(item.objectId))?.id
      if (buildingId) {
        if (this.isOwnerModule) {
          return {
            name: 'OwnerBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAdminModule) {
          return {
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        }
      }
      return ''
    },
    getContactName(contact) {
      return this.listContacts.find(x => x.id === contact)?.name
    },
    toContactClicked() {
      if (this.isOwnerModule) {
        return { name: 'OwnerContactDetailsView', params: { id: this.id } }
      } else if (this.isAdminModule) {
        return { name: 'AdminContactDetailsView', params: { id: this.id } }
      } else if (this.isAssetModule) {
        return { name: 'AssetContactDetailsView', params: { id: this.id } }
      } else if (this.isAccountingModule) {
        return { name: 'AccountingContactDetailsView', params: { id: this.d } }
      } else {
        return { name: 'MarketingContactDetailsView', params: { id: this.id } }
      }
    },
    redirectToDeviceDetail(referenceId, deviceId) {
      if (referenceId.startsWith('obj') || referenceId.startsWith('building')) {
        let id = null
        if (referenceId.startsWith('building')) {
          id = referenceId
        } else {
          const item = this.references.filter(x => x.device && x.device.id === deviceId)
          if (item.length > 0) {
            id = item[0].building?.id
          }
        }
        if (id && deviceId) {
          if (this.isOwnerModule) {
            return { name: 'OwnerBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          } else if (this.isAdminModule) {
            return { name: 'AdminBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          } else if (this.isAssetModule) {
            return { name: 'AssetBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          } else if (this.isAccountingModule) {
            return { name: 'AccountingBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          }
        }
      } else {
        let id = null
        id = referenceId
        if (this.isOwnerModule) {
          return { name: 'OwnerPropertyDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
        } else if (this.isAdminModule) {
          return { name: 'AdminPropertyDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetPropertyDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingPropertyDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
        }
      }
    },
    toObjectClicked() {
      if (this.allReferences.length > 0) {
        let routeName = ''
          if (this.isOwnerModule) {
            routeName = 'OwnerBuildingDetailsView'
          } else if (this.isAssetModule) {
            routeName = 'AssetBuildingDetailsView'
          } else if (this.isAccountingModule) {
            routeName = 'AccountingBuildingDetailsView'
          } else if (this.isAdminModule) {
            routeName = 'AdminBuildingDetailsView'
          }
          const buildingId = this.allReferences[0]
          return {
            name: routeName,
            params: { id: buildingId },
            query: { view: 'objects', elementSelected: this.id }
          }
      } else if (this.prevRoute) {
        return this.prevRoute
      } else if (this.id.startsWith('obj')) {
        if (this.id.startsWith('building')) {
          ObjectApi.getBuildingByObjectId(this.id).then(response => {
            let routeName = ''
            if (this.isOwnerModule) {
              routeName = 'OwnerBuildingDetailsView'
            } else if (this.isAssetModule) {
              routeName = 'AssetBuildingDetailsView'
            } else if (this.isAccountingModule) {
              routeName = 'AccountingBuildingDetailsView'
            } else if (this.isAdminModule) {
              routeName = 'AdminBuildingDetailsView'
            }
            return {
              name: routeName,
              params: { id: response.building.id },
              query: { view: 'objects', elementSelected: this.id }
            }
          }).catch(e => {
            console.log(e)
          })
        }
      }
    },
    filterActivities() {
      const t = this
      this.$nextTick(function () {
        this.activityList = this.activityUnsortedList.filter(function (activity) {
          const searchCdt = activity.text.toLowerCase().includes(t.searchText.toLowerCase()) || activity.subject.toLowerCase().includes(t.searchText.toLowerCase())
          const creatorMapped = [].concat.apply([], t.searchCreator.map(x => x.value))
          const cdtCreator = ((activity.creator && creatorMapped.includes(activity.creator.userId)) || (!activity.creator && creatorMapped.includes(null))) || t.searchCreator.length === 0
          return searchCdt && cdtCreator
        })
      })
    },
    customLabelCreator(item) {
      if ('option' in item) {
        if (item.option.value === null) {
          return `${this.$t('message.generic.unassigned')} (${item.option.count})`
        }
        const data = this.activityUnsortedList.find(w => w.creator && w.creator.userId === item.option.value)?.creator
        return `${data.name} <b> - ${data.accountName} </b>  (${item.option.count})`
      } else {
        if (item.value === null) {
          return `${this.$t('message.generic.unassigned')} (${item.count})`
        }
        const name = this.activityUnsortedList.find(w => w.creator && w.creator.userId === item.value)?.creator?.name
        return `${name} (${item.count})`
      }
    },
    openSelectedTabs() {
      if (this.view === 'tickets') {
        this.tabIndex = 0
      } else {
        this.tabIndex = 1
      }
    },
    addToList(activity) {
      this.activityList.push(activity)
    },
    getDuration(timestampStart, timestampEnd) {
      if (timestampStart && timestampEnd) {
        const start = new Date(parseInt(timestampStart) * 1000)
        const end = new Date(parseInt(timestampEnd) * 1000)
        const diff = end - start
        const filterInstance = this.$options.filters
        return `${filterInstance.timestampToTimeOnly(diff / 1000)}`
      }
      return null
    },
    redirectToRequestDetail(objectId, requestId) {
      return {
        name: 'MarketingObjectDetailsView',
        params: { id: objectId },
        query: {
          view: 0,
          elementSelected: requestId
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
    cursor: pointer;
}

:deep(.details-row-bg-1) {
    background-color: $color-table-row-2;
}

:deep(.details-row-bg-2) {
    background-color: $color-table-row-1;
}

:deep(label) {
    width: 100%;
}

:deep(.is-disabled) {
    .sort button {
        display: block !important;
    }

    .multiselect,
    .custom-checkbox {
        pointer-events: none;

        .multiselect__select,
        .multiselect__placeholder {
            display: none;
        }
    }

    .filter-inputs .multiselect,
    .custom-checkbox {
        pointer-events: auto !important;

        .multiselect__select,
        .multiselect__placeholder {
            display: block !important;
        }
    }

    .basic {
        input {
            opacity: 1;
            border: thin solid #ced4da;
            -webkit-box-shadow: inherit;
            box-shadow: inherit;
            margin-bottom: 0;
            pointer-events: initial;
            padding-left: 0.2rem;
            background: none;
        }
    }

    .multi-select-value {
        line-height: 1.5;
    }
}

:deep(.global-card) {
    margin-bottom: 0;

    &>.card-body {
        -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
        -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
        box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    }

    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}

:deep(.global-card) {
    margin-bottom: 0;

    &>.card-body {
        -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
        -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
        box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    }

    border-left: 0;
    border-right: 0;
    border-bottom: 0;

    &.no-top-border {
        border-top: 0;
    }
}

.floating-wrapper {
    right: 5vw;
    bottom: 4vh;
    z-index: 999;
    position: fixed;

    .floating-button {
        border-radius: 100px !important;
        display: flex;
        align-items: center;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.20), 0 4px 4px rgba(0, 0, 0, 0.15);
        z-index: 2;
        justify-content: center;
        background-color: $color-primary-dark;
        padding: 20px !important;
        cursor: pointer;

        svg {
            color: white;
            font-size: 15px;
        }
    }
}

:deep(.tabs .nav-tabs .nav-link a.router-link-active),
:deep(.tabs .nav-tabs .nav-link a) {
    color: inherit !important;
    text-decoration: none !important;
}

:deep(.card.shadow) {
    box-shadow: none !important;
}
.item-button button.small {
  padding: 0.2rem 0.5rem;
}
</style>
