<template>
  <div class="root">
    <div class="row">
      <div class="col">
        <label
          v-if="name"
          :for="id? name+id:name"
          class="mb-0">
          {{ name }}
          <coozzy-info-circle-icon
            v-if="tooltipText"
            v-b-tooltip.hover.html="tooltipText" />
        </label>
        <div
          v-if="secondaryTitle"
          class="secondary-title ml-2">
          {{ secondaryTitle }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span
          v-if="limit !== -1 && !isReadOnly"
          class="limit text-grey-light">
          {{ value ? value.length : 0 }}/{{ limit }}
        </span>
        <b-form-input
          v-if="!isReadOnly"
          :id="id? name+id:name"
          ref="bFormInput"
          :value="value"
          :step="step"
          class="form-control"
          :class="limit !== -1 && !isReadOnly ? 'padding-right-80px' : ''"
          size="sm"
          :type="type"
          :number="type === 'number'"
          :min="type === 'date' ? '1800-01-01' : min || ''"
          :max="type === 'date' ? '9999-12-31' : max || ''"
          :state="state !== null ? state : type === 'date' && $v.value.$dirty && $v.value.$error ? false : null"
          v-bind="$attrs"
          v-on="mountEventListeners"
          @keypress="checkIfDateIsFullEntered"
          @focus="focusField"
          @blur="checkEnteredDate" />
        <span
          v-else
          class="show-data">
          <span v-if="filter === 'formatArea'">{{ value | formatArea }}</span>
          <span v-else-if="filter === 'formatVolume'">{{ value | formatVolume }}</span>
          <span v-else-if="filter === 'formatMeter'">{{ value | formatMeter }}</span>
          <span v-else-if="filter === 'formatDate'">{{ value | formatDate }}</span>
          <span v-else-if="isEllipsize === true">
            {{ truncateText(value) }}
            <coozzy-info-circle-icon
              v-if="value && value.length > 20"
              v-b-tooltip.hover.html="value" />
          </span>
          <span v-else>{{ value !=='' && value !== null ? value : '-' }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon'
function isValidDate(dateString) {
  // force revalidate by using forceValidation variable when user delete whole date
  // (value has empty string in two cases: invalid date and empty date)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const test = this.forceValidation
  if (this.isFocusField) {
    return true
  }
  if (dateString && dateString.length) {
    if (!/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateString)) {
      return false
    }

    const parts = dateString.split('-')
    const year = parseInt(parts[0], 10)

    // Check the ranges of year
    if (year < 1800) {
      return false
    }
  }
  const validityState = this.$refs.bFormInput.$el.validity
  if (Object.keys(validityState).length === 0) {
    return true
  }
  if (validityState.badInput === true) {
    // invalid date
    return false
  }
  if (validityState.valid === true) {
    // valid date
    return true
  }

  return false
}

export default {
  name: 'CoozzyFormInput',
  components: { CoozzyInfoCircleIcon },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    state: {
      type: Boolean,
      default: null
    },
    secondaryTitle: {
      type: String,
      default: null
    },
    filter: {
      type: String,
      default: ''
    },
    step: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: -1
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => {
        const types = [
          'color', 'date', 'datetime-local', 'email',
          'file', 'hidden', 'image', 'month',
          'number', 'password', 'radio', 'range',
          'reset', 'search', 'submit', 'tel',
          'text', 'time', 'url', 'week'
        ]
        return types.includes(value)
      }
    },
    isEllipsize: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    },
    tooltipText: {
      type: String,
      default: null
    },
    min: {
      type: [Number, String],
      default: null
    },
    max: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      turnOnDateValidation: false,
      forceValidation: false,
      isFocusField: false
    }
  },
  computed: {
    mountEventListeners() {
      return Object.assign({},
        this.$listeners,
        {
          input: (value) => {
            if (this.type === 'date') {
              this.$v.$touch()
            }
            this.$emit('input', value)
          }
        }
      )
    }
  },
  watch: {
    value: function () {
      if (this.type === 'date') {
        this.forceValidation = !this.forceValidation
        this.$v.$touch()
      }
    }
  },
  mounted() {
    if (this.type === 'date') {
      this.turnOnDateValidation = true
    }
  },
  methods: {
    setFocusInput() {
      this.$nextTick(() => {
        this.$refs.bFormInput.focus()
      })
    },
    checkIfDateIsFullEntered(e) {
      this.respectLimit(e)
    },
    focusField() {
      this.isFocusField = true
    },
    checkEnteredDate() {
      this.isFocusField = false
      this.forceValidation = !this.forceValidation
      this.$v.$touch()
    },
    truncateText(value) {
      if (value === null || value === '' || value === undefined || value === -1 || value === '-1') {
        return '-'
      } else {
        if (value.length > 20) {
          return value.substring(0, 20) + '...'
        }
        return value
      }
    },
    respectLimit ($event) {
      if (this.type === 'date') {
        this.$v.$touch()
      }
      if ((this.limit !== -1 && this.value && this.value.length >= this.limit) && window.getSelection().toString() === '') {
        $event?.preventDefault()
      }
    },
    isInvalid() {
      let valid = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        valid = false
      }
      return !valid
    }
  },
  validations() {
    const validationInput = {
      value: {
      }
    }
    if (this.turnOnDateValidation) {
      validationInput.value = {
        isValidDate
      }
    }
    return validationInput
  }
}
</script>

<style lang="scss" scoped>
  .root {
    input {
      padding: 0.2em 8px;
    }
  }
  .show-data{
    padding-left: 0;
  }
  .limit {
    position: absolute;
    right: 2rem;
    top: 0;
    line-height: 30px;
  }
  .padding-right-80px {
    padding-right: 80px !important;
  }
  .secondary-title{
    display: inline;
    font-size: 0.8rem;
  }
</style>
