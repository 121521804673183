<template>
  <section>
    <div class="row mb-2">
      <div class="col-12 col-md-2">
        <coozzy-form-input
          v-model="query"
          :placeholder="$t('message.generic.search')"
          type="text"
          @keyup="getFilteredContacts" />
      </div>
      <div class="col-12 col-md-2">
        <coozzy-multiselect
          v-model="rolesInBuildingFilter"
          :options="availableRolesInBuilding"
          :multiple="true"
          :custom-label="customRolesInBuildingLabel"
          :placeholder="$t('message.onBoarding.createContactTab.roleInBuilding')"
          @select="getFilteredContacts"
          @remove="getFilteredContacts" />
      </div>
      <div class="col-12 col-md-2">
        <coozzy-multiselect
          v-model="industriesFilter"
          :options="translatedIndustries"
          :multiple="true"
          :placeholder="$t('message.generic.industries')"
          :custom-label="customLabel"
          track-by="label"
          @select="getFilteredContacts"
          @remove="getFilteredContacts" />
      </div>
      <div
        v-if="isFiltered"
        class="col-12 col-md-2">
        <coozzy-button
          class="w-100 sort"
          @click="initFilter()">
          <coozzy-filter-icon /> {{ $t('message.generic.clearFilters') }}
        </coozzy-button>
      </div>
      <div
        v-if="isEditor"
        class="col-12"
        :class="isFiltered ? 'col-md-4' : 'col-md-6'">
        <coozzy-button
          size="small"
          class="float-right"
          design="prop-green"
          :disabled="objects.length === 0"
          @click="callAction('addServiceProviders')">
          {{ $t('message.manageBuilding.bulkAction.addServiceProviders') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mr-0 mr-md-2"
          design="prop-green"
          :disabled="objects.length === 0"
          @click="callAction('addCraftsmen')">
          {{ $t('message.manageBuilding.bulkAction.addCraftsmen') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mr-0 mr-md-2"
          design="prop-green"
          :disabled="objects.length === 0"
          @click="callAction('addJanitors')">
          {{ $t('message.manageBuilding.bulkAction.addJanitors') }}
        </coozzy-button>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 offset-md-10 col-md-2">
        <coozzy-form-select
          v-model="nbrPerPage"
          name="numberEntries"
          class="select-entries float-right">
          <option value="5">
            5 {{ $t('message.generic.entries') }}
          </option>
          <option value="10">
            10 {{ $t('message.generic.entries') }}
          </option>
          <option value="50">
            50 {{ $t('message.generic.entries') }}
          </option>
          <option value="100">
            100 {{ $t('message.generic.entries') }}
          </option>
          <option value="500">
            500 {{ $t('message.generic.entries') }}
          </option>
          <option value="1000">
            1000 {{ $t('message.generic.entries') }}
          </option>
        </coozzy-form-select>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          ref="contactsTable"
          hover
          bordered
          responsive="true"
          stacked="md"
          selectable
          select-mode="single"
          :busy="loading"
          :fields="fields"
          :items="paginatedContacts"
          class="overflow-auto shadow"
          @row-clicked="onRowClicked">
          <!-- Busy state -->
          <div
            slot="table-busy"
            class="text-center text-danger my-2">
            <coozzy-spinner />
          </div>

          <!-- Headings -->
          <template #head(roleInBuilding)>
            {{ $t('message.tableColumnHeadings.roleInBuilding') }}
          </template>
          <template #head(industries)>
            {{ $t('message.generic.industry') }}
          </template>
          <template #head(name)>
            {{ $t('message.generic.name') }}
          </template>
          <template #head(address)>
            {{ $t('message.generic.address') }}
          </template>
          <template #head(communication)>
            {{ $t('message.generic.communication') }}
          </template>
          <template #head(notes)>
            {{ $t('message.generic.notes') }}
          </template>
          <template #head(action)>
            <span />
          </template>

          <!-- Body -->
          <!-- Role in building -->
          <template #cell(roleInBuilding)="data">
            {{ $t('message.onBoarding.createContactTab.' + data.item.roleInBuilding) }}
          </template>
          <!-- Industries -->
          <template #cell(industries)="data">
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="getTranslatedIndustries(data.item, true)" />
            <!-- eslint-enable vue/no-v-html -->
          </template>
          <!-- Name -->
          <template #cell(name)="data">
            <router-link
              class="link"
              :to="getContactDetailRoute(data.item.id ? data.item.id : data.item.objectID)">
              <span>
                {{ data.item.name }}
              </span>
            </router-link>
          </template>
          <!-- Address -->
          <template #cell(address)="data">
            <address-text :address="data.item.address" />
          </template>
          <!-- Communication -->
          <template #cell(communication)="data">
            <div
              v-if="getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') !== '-'"
              class="row">
              <div class="col-1">
                <coozzy-mobile-alt-icon />
              </div>
              <div class="col">
                <a
                  class="link"
                  :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'MOBILE')">
                  {{ getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') }}
                </a>
              </div>
            </div>
            <div
              v-if="getPhoneNumbers(data.item.phoneNumbers, 'HOME') !== '-'"
              class="row">
              <div class="col-1">
                <coozzy-phone-icon />
              </div>
              <div class="col">
                <a
                  class="link"
                  :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'HOME')">
                  {{ getPhoneNumbers(data.item.phoneNumbers, 'HOME') }}
                </a>
              </div>
            </div>
            <div
              v-if="getPhoneNumbers(data.item.phoneNumbers, 'WORK') !== '-'"
              class="row">
              <div class="col-1">
                <coozzy-headset-icon />
              </div>
              <div class="col">
                <a
                  class="link"
                  :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'WORK')">
                  {{ getPhoneNumbers(data.item.phoneNumbers, 'WORK') }}
                </a>
              </div>
            </div>
            <div
              v-if="getPhoneNumbers(data.item.phoneNumbers, 'FAX') !== '-'"
              class="row">
              <div class="col-1">
                <coozzy-fax-icon />
              </div>
              <div class="col">
                <a
                  class="link"
                  :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'FAX')">
                  {{ getPhoneNumbers(data.item.phoneNumbers, 'FAX') }}
                </a>
              </div>
            </div>
            <div
              v-if="data.item.email !== ''"
              class="row">
              <div class="col-1">
                <img
                  class="email-icon-img"
                  src="@/assets/icon/arroba.svg"
                  alt="Placeholder">
              </div>
              <div class="col">
                <template v-if="data.item.email !== ''">
                  <a
                    class="link"
                    tabindex="0"
                    @click="sendEmailModal(data.item)">
                    {{ data.item.email }}
                  </a>
                </template>
                <template v-else>
                  {{ data.item.email | displayOptionalValue }}
                </template>
              </div>
            </div>
            <div
              v-if="data.item.website !== ''"
              class="row">
              <div class="col-1">
                <coozzy-globe-icon />
              </div>
              <div class="col">
                <template v-if="data.item.website !== ''">
                  <a
                    class="link"
                    tabindex="0"
                    target="_blank"
                    :href="getUrlWebsite(data.item.website)">
                    {{ data.item.website }}
                  </a>
                </template>
                <template v-else>
                  {{ data.item.website | displayOptionalValue }}
                </template>
              </div>
            </div>
          </template>
          <!-- Notes -->
          <template #cell(notes)="data">
            <p
              :id="`contact-note-${data.item.id}`"
              class="text-msg">
              {{ data.item.note | displayOptionalValue }}
            </p>
            <b-tooltip
              v-if="data.item.note"
              :target="`contact-note-${data.item.id}`"
              triggers="hover">
              {{ data.item.note }}
            </b-tooltip>
          </template>
          <template #cell(action)="data">
            <coozzy-button
              v-if="selectedContact !== data.item.id"
              class="col min-width-btn"
              design="transparent"
              @click="selectContact(data.item)">
              {{ $t('message.generic.select') }}
            </coozzy-button>
            <coozzy-button
              v-else
              class="col min-width-btn mb-0"
              design="alert"
              @click="selectContact(null)">
              {{ $t('message.generic.unselect') }}
            </coozzy-button>
          </template>
        </b-table>
        <b-pagination
          v-if="!loading"
          v-model="currentPage"
          align="center"
          :total-rows="filteredContacts.length"
          :per-page="nbrPerPage" />
      </div>
    </div>
    <object-contacts-bulk-action
      v-if="selectedActions"
      ref="objectContactsBulk"
      :title="selectedActions"
      :owner-ids="buildings && buildings.length > 0 ? buildings.map(b => b.ownerId) : ''"
      :building-ids="buildings && buildings.length > 0 ? buildings.map(b => b.id) : ''"
      @action-done="contactAdded" />
  </section>
</template>

<script>

import ObjectContactsBulkAction from '@/owner/ObjectContactsBulkAction'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import { tables } from '@/mixins/tables'
import AddressText from '@/framework/components/misc/AddressText'
import CoozzyMobileAltIcon from '@/framework/components/icons/CoozzyMobileAltIcon'
import CoozzyPhoneIcon from '@/framework/components/icons/CoozzyPhoneIcon'
import CoozzyHeadsetIcon from '@/framework/components/icons/CoozzyHeadsetIcon'
import CoozzyGlobeIcon from '@/framework/components/icons/CoozzyGlobeIcon'
import { contact } from '@/mixins/contact'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { mapMutations } from 'vuex'
import { algolia } from '@/mixins/algolia'
import CoozzyFaxIcon from '@/framework/components/icons/CoozzyFaxIcon'
import { user } from '@/mixins/user'
import UserApi from '@/misc/apis/UserApi'
import CoozzyFilterIcon from '@/framework/components/icons/CoozzyFilterIcon.vue'

export default {
  name: 'OrderContactsTab',
  components: {
    CoozzyFilterIcon,
    CoozzyFaxIcon,
    CoozzyFormSelect,
    CoozzyMultiselect,
    CoozzyFormInput,
    CoozzySpinner,
    CoozzyGlobeIcon,
    CoozzyHeadsetIcon,
    CoozzyPhoneIcon,
    CoozzyMobileAltIcon,
    AddressText,
    CoozzyButton,
    ObjectContactsBulkAction
  },
  mixins: [routeChecks, tables, contact, algolia, user],
  props: {
    buildings: {
      type: Array,
      default: () => []
    },
    objects: {
      type: Array,
      default: () => []
    },
    craftsmanContacts: {
      type: Array,
      default: () => []
    },
    janitorContacts: {
      type: Array,
      default: () => []
    },
    serviceProviderContacts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      fields: [
        {
          key: 'roleInBuilding',
          label: this.$t('message.tableColumnHeadings.roleInBuilding'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'industries',
          label: this.$t('message.generic.industries'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'name',
          label: this.$t('message.generic.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'communication',
          label: this.$t('message.generic.communication'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'notes',
          label: this.$t('message.generic.notes'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'action',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      selectedEntries: [],
      query: '',
      rolesInBuildingFilter: [],
      industriesFilter: [],
      availableRolesInBuilding: ['craftsmen', 'janitors', 'serviceProvider'],
      availableIndustries: [],
      currentPage: 1,
      nbrPerPage: 5,
      selectedActions: '',
      type: null,
      idContact: null,
      subtype: null,
      contactIdsAssigneeOwner: [],
      contactIdsAssigneeAdministration: [],
      contactIdsAssigneeMarketing: [],
      contactIdsAssigneeAsset: [],
      selectedContact: null,
      filteredContacts: []
    }
  },
  computed: {
    isFiltered() {
      return this.query !== '' || this.rolesInBuildingFilter.length > 0 || this.industriesFilter.length > 0
    },
    translatedIndustries() {
      const contacts = [...this.craftsmanContacts].concat([...this.janitorContacts]).concat([...this.serviceProviderContacts])
      const counts = []
      this.translatedIndustriesList(true).filter(x => {
        return contacts.flatMap(x => x.industries).includes(x.value)
      }).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x.value)
        if (existingCount.length === 0) {
          const sameNetwork = contacts.flatMap(x => x.industries).filter(z => z === x.value)
          if (existingCount.length === 0) {
            counts.push({
              count: sameNetwork.length,
              value: x.value,
              label: x.label
            })
          } else {
            counts.push({
              count: 1,
              value: x.value,
              label: x.label
            })
          }
        }
      })
      return counts
    },
    paginatedContacts() {
      return this.filteredContacts.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
    }
  },
  watch: {
    nbrPerPage: function (newVal) {
      localStorage.setItem('createContactsTab-nbrPerPage', newVal)
    },
    craftsmanContacts: function () {
      this.getFilteredContacts()
    },
    janitorContacts: function () {
      this.getFilteredContacts()
    },
    serviceProviderContacts: function () {
      this.getFilteredContacts()
    }
  },
  mounted() {
    this.loadInstantSearchInternal()
    this.nbrPerPage = localStorage.getItem('createContactsTab-nbrPerPage') ? parseInt(localStorage.getItem('createContactsTab-nbrPerPage')) : 5
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply'
    ]),
    getFilteredContacts() {
      let contacts = []
      if (this.rolesInBuildingFilter.length === 0 || this.rolesInBuildingFilter.includes('craftsmen')) {
        contacts = contacts.concat([...this.craftsmanContacts].map(c => {
          const temp = Object.assign({}, c)
          temp.roleInBuilding = 'craftsmen'
          temp.selectingID = Date.now() + Math.random().toString(36).substr(2, 9)
          return temp
        }))
      }
      if (this.rolesInBuildingFilter.length === 0 || this.rolesInBuildingFilter.includes('janitors')) {
        contacts = contacts.concat([...this.janitorContacts].map(c => {
          const temp = Object.assign({}, c)
          temp.roleInBuilding = 'janitors'
          temp.selectingID = Date.now() + Math.random().toString(36).substr(2, 9)
          return temp
        }))
      }
      if (this.rolesInBuildingFilter.length === 0 || this.rolesInBuildingFilter.includes('serviceProvider')) {
        contacts = contacts.concat([...this.serviceProviderContacts].map(c => {
          const temp = Object.assign({}, c)
          temp.roleInBuilding = 'serviceProvider'
          temp.selectingID = Date.now() + Math.random().toString(36).substr(2, 9)
          return temp
        }))
      }
      if (this.query) {
        const q = this.query.toLowerCase()
        contacts = contacts.filter(c => {
          const searchString = c.name + ' ' +
            c.birthName + ' ' +
            c.email + ' ' +
            c.phoneNumbers.map(phone => phone.number + ' ') +
            (c.address ? `${c.address.street} ${c.address.streetNumber} ${c.address.mailbox} ${c.address.subUnit} ${c.address.careOf} ${c.address.zip} ${c.address.city}` : ' ') +
            (c.contactPersons ? c.contactPersons.map(p => this.getContactPersonSearchString(p)) : ' ') +
            c.website

          return searchString.toLowerCase().includes(q)
        })
      }

      if (this.industriesFilter.length > 0) {
        const industries = this.industriesFilter.map(item => item.value)
        contacts = contacts.filter(c => {
          for (const industry of industries) {
            if (c.industries.includes(industry)) {
              return true
            }
          }
          return false
        })
      }
      this.filteredContacts = this.removeDuplicatesContact(contacts)
    },
    refreshTable() {
      this.$refs.contactsTable.refresh()
    },
    onRowClicked(item) {
      this.selectContact(item)
    },
    selectContact(contact) {
      this.selectedContact = contact?.id
      if (contact) {
        UserApi.getAccount(contact.ownerId).then(response => {
          const contactOwnerAccount = response.account
          let ownerName = ''
          if (contactOwnerAccount.company) {
            ownerName = contactOwnerAccount.company.name
          } else if (contactOwnerAccount.individual) {
            ownerName = contactOwnerAccount.individual.firstName + ' ' + contactOwnerAccount.individual.lastName
          }
          contact.owner = {
            id: contactOwnerAccount.id,
            name: ownerName
          }
          this.$nextTick(() => {
            this.$emit('recipient-changed', contact)
          })
        })
      } else {
        this.$emit('recipient-changed', null)
      }
    },
    customLabel(item) {
      return item.label + ' (' + item.count + ')'
    },
    async contactAdded(adding, contactIds) {
      this.loading = true
      if (adding && contactIds.length > 0) {
        await this.$emit('load-new-added-contacts', contactIds)
      }
      this.loading = false
    },
    removeDuplicatesContact(array) {
      return array.reverse().reduce((acc, current) => {
        const currentObjectId = current.objectID ? current.objectID : current.id
        const x = acc.find(item => (item.objectID && item.objectID === currentObjectId) || (item.id && item.id === currentObjectId))
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])
    },
    initFilter() {
      this.query = ''
      this.industriesFilter = []
      this.rolesInBuildingFilter = []
    },
    callAction(action) {
      this.selectedActions = action
      this.$nextTick(function () {
        this.$refs.objectContactsBulk.show()
      })
    },
    sendEmailModal(contact) {
      this.setSendMailContacts([contact])
      this.setSendMailPreselectedContact(contact)
      this.setSendMailReferenceIds([])
      this.setSendMailEmailToReply(null)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    getContactPersonSearchString(person) {
      return person.name + ' ' +
        person.birthName + ' ' +
        person.email + ' ' +
        person.phoneNumbers.map(phone => phone.number + ' ')
    },
    customRolesInBuildingLabel(roleInBuilding) {
      return this.$t(`message.onBoarding.createContactTab.${roleInBuilding}`)
    },
    contactSelected(contact, checked) {
      if (checked) {
        this.selectedEntries.push(contact)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.selectingID !== contact.selectingID)
      }
    },
    getUrlWebsite(item) {
      if (item.indexOf('//') > -1) {
        return item
      } else {
        return '//' + item
      }
    },
    see(refine, value) {
      refine(value)
    }
  }
}
</script>
<style lang="scss" scoped>
p.text-msg {
  line-height: 1.5em;
  height: 1.5em;
  max-width: 200px;
  overflow: hidden;
  line-break: anywhere;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.min-width-btn {
  min-width: 80px;
}
</style>
