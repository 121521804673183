<template>
  <section v-if="partner">
    <coozzy-page-title :title="$t('message.navigation.networkPartner')" />
    <h6 class="font-weight-bold pt-1 pb-2">
      {{ $t(`message.networkPartner.subTypes.${partner.subType.toLowerCase()}`) }}
      <coozzy-button
        design="secondary"
        class="float-right"
        @click="backClicked">
        {{ $t('message.generic.back') }}
      </coozzy-button>
    </h6>
    <coozzy-card
      class="card shadow mb-3"
      :title="$t('message.networkPartner.agreements_documents')">
      <section
        class="global-section is-disabled mb-3">
        <coozzy-card class="global-card no-top-border">
          <div
            v-b-toggle.collapse-customer
            class="collapse-title">
            <h5 class="cursor-pointer">
              {{ $t('message.networkPartner.customer') }}
            </h5>
          </div>
          <div class="col-12 pl-0">
            <b-collapse
              id="collapse-customer"
              visible>
              <div class="row">
                <div class="col-12">
                  <h1>
                    {{ partnerName }}
                  </h1>
                </div>
                <div class="col-8">
                  <p v-if="partner.company">
                    {{ partner.company.aboutUs }}
                  </p>
                </div>
              </div>
              <div class="row details-row-bg-1 mt-3 py-2">
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.name') }}
                  </label>
                  <p class="mb-0">
                    {{ partnerName }}
                  </p>
                </div>
                <div
                  v-if="hasStreetAddition"
                  class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.addressAddition') }}
                  </label>
                  <p class="mb-0">
                    {{ address.streetAddition }}
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.street') }}
                  </label>
                  <p class="mb-0">
                    {{ address.street }} {{ address.streetNumber }}
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.zip') }}
                  </label>
                  <p class="mb-0">
                    {{ address.zip }}
                  </p>
                </div>
                <div
                  v-if="!hasStreetAddition"
                  class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.city') }}
                  </label>
                  <p class="mb-0">
                    {{ address.city }}
                  </p>
                </div>
              </div>
              <div class="row details-row-bg-2 py-01rem">
                <div
                  v-if="hasStreetAddition"
                  class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.city') }}
                  </label>
                  <p class="mb-0">
                    {{ address.city }}
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.country') }}
                  </label>
                  <p class="mb-0">
                    {{ translateCountry(address.country) }}
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.phoneNumber') }}
                  </label>
                  <p
                    v-if="partner.company"
                    class="mb-0">
                    {{ partner.company.phoneNumber }}
                  </p>
                  <p
                    v-if="partner.individual"
                    class="mb-0">
                    {{ partner.individual.phoneNumber }}
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.email') }}
                  </label>
                  <p>
                    <a
                      v-if="partner.company"
                      class="mb-0"
                      tabindex="0"
                      @click="sendEmailModal(partner.company)">
                      {{ partner.company.email }}
                    </a>
                    <a
                      v-if="partner.individual"
                      class="mb-0"
                      tabindex="0"
                      @click="sendEmailModal(partner.individual)">
                      {{ partner.individual.email }}
                    </a>
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.generic.website') }}
                  </label>
                  <p
                    v-if="partner.company">
                    <template v-if="partner.company.website !== ''">
                      <a
                        tabindex="0"
                        target="_blank"
                        :href="getUrlWebsite(partner.company.website)">
                        {{ partner.company.website }}
                      </a>
                    </template>
                    <template v-else>
                      {{ partner.company.website | displayOptionalValue }}
                    </template>
                  </p>
                  <p
                    v-if="partner.individual">
                    <template v-if="partner.individual.website !== ''">
                      <a
                        tabindex="0"
                        target="_blank"
                        :href="getUrlWebsite(partner.individual.website)">
                        {{ partner.individual.website }}
                      </a>
                    </template>
                    <template v-else>
                      {{ partner.individual.website | displayOptionalValue }}
                    </template>
                  </p>
                </div>
              </div>
            </b-collapse>
          </div>
        </coozzy-card>
        <coozzy-card
          class="global-card">
          <div
            v-b-toggle.collapse-contactperson
            class="collapse-title">
            <h5 class="cursor-pointer">
              {{ $t('message.generic.contactPerson') }}
            </h5>
          </div>
          <div class="col-12 pl-0">
            <b-collapse
              id="collapse-contactperson">
              <div class="row mt-3 py-2">
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.networkPartner.contactPersonName') }}
                  </label>
                  <p class="mb-0">
                    {{ contactPerson && contactPerson.profile ? `${contactPerson.profile.firstName || ''} ${contactPerson.profile.lastName || ''}` : '-' }}
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.networkPartner.contactPersonPhone') }}
                  </label>
                  <p class="mb-0">
                    {{ contactPerson && contactPerson.profile && contactPerson.profile.phoneNumber ? contactPerson.profile.phoneNumber : '-' }}
                  </p>
                </div>
                <div class="col-12 col-md-3">
                  <label>
                    {{ $t('message.networkPartner.contactPersonEmail') }}
                  </label>
                  <p class="mb-0">
                    <a
                      v-if="contactPerson && contactPerson.email"
                      class="mb-0"
                      tabindex="0"
                      @click="sendEmailModal(contactPerson)">
                      {{ contactPerson.email }}
                    </a>
                    <template v-else>
                      {{ '-' }}
                    </template>
                  </p>
                </div>
              </div>
            </b-collapse>
          </div>
        </coozzy-card>
        <network-partner-region-card :partner="partner" />
        <add-document-modal
          @document-created="documentCreated" />
      </section>
    </coozzy-card>
    <coozzy-card
      class="card shadow mb-3"
      :title="$t('message.networkPartner.documents')">
      <div class="row">
        <div class="col-12 mb-2 mb-md-0">
          <div class="row mt-3">
            <div class="col-12 col-md-6">
              <coozzy-form-search-input
                v-model="documentName"
                class="search-interested-block mb-2 basic"
                :placeholder="$t('message.generic.search')"
                name="name-document" />
            </div>
            <div class="col-12 col-md-6">
              <coozzy-form-select
                v-model="documentType"
                class="mb-2 basic">
                <option value="">
                  {{ $t('message.manageDocuments.type.no_type') }}
                </option>
                <option
                  v-for="(item, index) in translatedAvailableTypes"
                  :key="index"
                  :value="item">
                  {{ $t(`message.manageDocuments.type.${item.toLowerCase()}`) }}
                </option>
              </coozzy-form-select>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <document
                v-for="doc in filteredDocuments"
                :key="doc.id"
                :document="doc"
                @version-added="versionAdded"
                @delete-document="deleteDocument" />
            </div>
            <div class="col-12 col-md-2">
              <coozzy-button
                class="w-100"
                design="prop-green"
                @click="addDocumentClicked">
                {{ $t('message.manageDocuments.addDocument') }}
              </coozzy-button>
            </div>
          </div>
        </div>
      </div>
    </coozzy-card>
  </section>
</template>

<script>
import UserApi from '../../misc/apis/UserApi'
import MediaApi from '../../misc/apis/MediaApi'
import NetworkApi from '../../misc/apis/NetworkPartnerApi'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import Document from '@/properties/components/Document'
import Vue from 'vue'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyCard from '@/framework/components/card/CoozzyCard'
import { mapMutations } from 'vuex'
import { country } from '@/mixins/country'
import NetworkPartnerRegionCard from '@/networkPartner/NetworkPartnerRegionCard'
import CoozzyFormSearchInput from '@/framework/components/form/input/CoozzyFormSearchInput'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'

export default {
  name: 'AssetNetworkPartnerDetailsView',
  components: {
    NetworkPartnerRegionCard,
    CoozzyCard,
    CoozzyButton,
    CoozzyPageTitle,
    AddDocumentModal,
    Document,
    CoozzyFormSearchInput,
    CoozzyFormSelect
  },
  mixins: [country],
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  metaInfo() {
    return {
      title: `${this.$t('message.navigation.networkPartner')} ${this.partnerName}`
    }
  },
  data() {
    return {
      file: null,
      uploading: false,
      partner: null,
      contactPerson: null,
      documents: [],
      isBusy: false,
      about: '',
      documentName: '',
      documentType: '',
      availableTypes: [
        'OTHER',
        'APPLICATION_FORM',
        'HANDOVER_PROTOCOL',
        'CONTRACT',
        'BUILDING_PLAN',
        'INVOICE',
        'HOUSE_REGULATION',
        'INSTRUCTION_MANUAL',
        'CREDIT_CHECK',
        'OWNERS_MEETING',
        'PAY_IN_SLIP',
        'ENERGY_CERTIFICATE',
        'WARRANTY_CERTIFICATE',
        'BANK_STATEMENT',
        'ADDITIONAL_COSTS_BILL',
        'SEPA_DIRECT_DEBIT_MANDATE',
        'AGREEMENT',
        'DEPOSIT_CONFIRMATION',
        'BUILDING_INSURANCE_POLICY',
        'KEY_LIST',
        'TERMINATION_DOC_TYPE',
        'RENT_ADJUSTMENT_DOC_TYPE',
        'REFERENCES_DOC_TYPE',
        'MAINTENANCE_CONTRACT_DOC_TYPE',
        'REGULATIONS',
        'REGULATIONS_FOR_USE_AND_MANAGEMENT',
        'ANNUAL_FINANCIAL_STATEMENT',
        'PURCHASE_CONTRACT',
        'LAND_REGISTER',
        'CONDOMINIUM_OWNERSHIP',
        'RENEWAL_FOND',
        'MORTGAGE',
        'ORDER',
        'OFFER',
        'IDENTITY',
        'EXTRACT_FROM_DEBT_COLLECTION_REGISTER',
        'BANK_ACCOUNT_DETAILS',
        'SITUATION_PLAN',
        'CONSTRUCTION_LAW_AGREEMENT',
        'EASEMENT_AGREEMENT',
        'CADASTRAL_ESTIMATE',
        'LETTER',
        'TRADE_REGISTER_EXCERPT',
        'PAYMENT_REMINDER',
        'PROTOCOL',
        'COURT_DOC_TYPE',
        'HONORARIUM',
        'BUDGET'
      ],
      fieldsContactTable: [
        {
          key: 'name',
          label: 'Name',
          thClass: 'th-name',
          tdClass: 'align-middle'
        },
        {
          key: 'email',
          label: 'Email',
          thClass: 'th-email',
          tdClass: 'align-middle'
        },
        {
          key: 'telephone',
          label: 'Telephone',
          thClass: 'th-telephone',
          tdClass: 'align-middle'
        }
      ]
    }
  },
  computed: {
    translatedAvailableTypes() {
      const array = this.availableTypes
      array.sort((a, b) => {
        if (this.$t(`message.manageDocuments.type.${a.toLowerCase()}`) < this.$t(`message.manageDocuments.type.${b.toLowerCase()}`)) {
          return -1
        }
        if (this.$t(`message.manageDocuments.type.${a.toLowerCase()}`) > this.$t(`message.manageDocuments.type.${b.toLowerCase()}`)) {
          return 1
        }
        return 0
      })
      return array
    },
    filteredDocuments() {
      return this.documents.filter(doc => {
        const cdtName = doc.name.includes(this.documentName)
        const cdtType = this.documentType === '' || doc.type === this.documentType
        return cdtName && cdtType
      }).sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    address() {
      if (this.partner && this.partner.company) {
        return this.partner.company.address
      } else if (this.partner && this.partner.individual) {
        return this.partner.individual.address
      }
      return {}
    },
    hasStreetAddition() {
      return !!this.address.streetAddition
    },
    partnerName() {
      if (this.partner && this.partner.company) {
        return this.partner.company.name
      } else if (this.partner && this.partner.individual) {
        return `${this.partner.individual.firstName} ${this.partner.individual.lastName}`
      } else {
        return ''
      }
    }
  },
  mounted: function () {
    const current = this
    UserApi.getAccount(this.id).then(response => {
      this.partner = response.account
      if (this.partner.contactPerson && this.partner.contactPerson.userId) {
        UserApi.getUser(this.partner.contactPerson.userId)
          .then(response => {
            this.contactPerson = response
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.user'), { type: 'error' })
          })
      }
    }).catch(e => {
      console.log(e)
      Vue.toasted.show(this.$t('message.loadingErrors.user'), { type: 'error' })
    })
    NetworkApi.getDocuments(this.$store.getters['user/getAccountId'], this.id)
      .then(response => {
        if (response.data.partnerDocuments.documentIds.length !== 0) {
          MediaApi.listDocuments(response.data.partnerDocuments.documentIds)
            .then(response => {
              current.documents = response.documents
            })
            .catch(e => {
              Vue.toasted.show(this.$t('message.loadingErrors.documents'), { type: 'error' })
              console.log(1, e)
            })
        }
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.documents'), { type: 'error' })
      })
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply'
    ]),
    addDocumentClicked() {
      this.$bvModal.show('add-document-modal')
    },
    backClicked() {
      this.$router.go(-1)
    },
    deleteDocument(document) {
      NetworkApi.deleteDocument(document.id, this.$store.getters['user/getAccountId'], this.id)
        .then(() => {
          this.documents = this.documents.filter(obj => obj.id !== document.id)
        })
        .catch(() => {
          Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
        })
    },
    documentCreated(document) {
      NetworkApi.newDocument(document.id, this.$store.getters['user/getAccountId'], this.id)
        .then(() => {
          this.documents.push(document)
        })
        .catch(() => {
          Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
        })
    },
    versionAdded(updatedDocument) {
      this.documents.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.documents.splice(index, 1, updatedDocument)
        }
      }, this)
    },
    sendEmailModal(contact) {
      contact.id = contact.objectID
      this.setSendMailContacts([contact])
      this.setSendMailPreselectedContact(contact)
      this.setSendMailReferenceIds([])
      this.setSendMailEmailToReply(null)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    getUrlWebsite(item) {
      if (item.indexOf('//') > -1) {
        return item
      } else {
        return '//' + item
      }
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.details-row-bg-1) {
  background-color: $color-table-row-2;
}

:deep(.details-row-bg-2) {
  background-color: $color-table-row-1;
}

.global-section {
  border: 1px solid #dfdfdf;
}

:deep(.global-card) {
  margin-bottom: 0;

  & > .card-body {
    -webkit-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    -moz-box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
    box-shadow: inset 0px -16px 25px -15px rgba(238, 238, 238, 1);
  }

  border-left: 0;
  border-right: 0;
  border-bottom: 0;

  &.no-top-border {
    border-top: 0;
  }
}

:deep(.collapse-title) {
  &.collapsed {
    h5 {
      background-image: url('../../assets/arrow_down.svg');
    }
  }

  h5 {
    background-image: url('../../assets/arrow_up.svg');
    background-repeat: no-repeat;
    font-size: large;
    font-weight: 500 !important;
    height: 1.2rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    background-position: right;
  }
}
</style>
