<template>
  <section v-if="loading">
    <div
      class="col-12 text-center">
      <coozzy-spinner class="m-3" />
    </div>
  </section>
  <section v-else>
    <div v-if="!editAdvertCall && (isOnboarding || isBuildingDetails)">
      <div
        v-if="actionBtn"
        class="col-12 action-object">
        <coozzy-dropdown
          class="float-right sort"
          size="sm"
          :text="$t('message.generic.actions')">
          <coozzy-dropdown-item
            v-if="objectSelected.id && isTicketingEnabled && (isEditor || isTicketEditor)"
            @click="createTicket();$event.stopPropagation()">
            {{ $t('message.ticketOverview.createTicket') }}
          </coozzy-dropdown-item>
          <coozzy-dropdown-item
            v-if="objectSelected.id && isTicketingEnabled && (isEditor || isTicketEditor)"
            @click="navigateToCreateOrder()">
            {{ $t('message.ticketOverview.createOrder') }}
          </coozzy-dropdown-item>
          <coozzy-dropdown-item @click="showActivity()">
            {{ $t('message.contact.activity.create') }}
          </coozzy-dropdown-item>
          <coozzy-dropdown-item @click="createReminder(item.id)">
            {{ $t('message.calendar.reminder.create') }}
          </coozzy-dropdown-item>
          <coozzy-dropdown-item
            v-if="checkLastTenancyForTermination(item.id) && !isOwnerModule && !isAssetModule"
            @click="redirectToTerminationView">
            {{ $t('message.onBoarding.buildings.objects.tenancies.termination') }}
          </coozzy-dropdown-item>
          <template v-if="!userWithoutRole && ($route.path.startsWith('/admin') || $route.path.startsWith('/accounting')) && !item.condominiumOwnership">
            <coozzy-dropdown-space-divider v-if="item.inactiveBy === null && (getMarketingStatus(item) === 'no' && checkAddTenancy(item.id) || checkAddVacany(item.id))" />
            <coozzy-dropdown-item
              v-if="item.inactiveBy === null && (getMarketingStatus(item) === 'no' && (checkAddTenancy(item.id) || checkAddVacany(item.id)))"
              @click="markForMarketingClicked(item.id)">
              {{ $t('message.marketingObjects.markForMarketing') }}
            </coozzy-dropdown-item>
            <div
              v-if="!userWithoutRole"
              class="m-0"
              :class="[usedLanguage === 'de' ? 'width_16r' : usedLanguage === 'it' ? 'width_14r' : '', item.inactiveBy === null && (checkAddVacany(item.id) && (getMarketingStatus(item) !== 'no') && (isAdminModule || isAccountingModule)) ? 'row' : '']">
              <coozzy-dropdown-item
                v-if="item.inactiveBy === null && checkAddVacany(item.id)"
                :disabled="(getMarketingStatus(item) !== 'no' && (isAdminModule || isAccountingModule))"
                @click="redirectToAddTenancy(item.id)">
                {{ $t('message.onBoarding.buildings.objects.addTenancy') }}
              </coozzy-dropdown-item>
              <div class="pl-0">
                <coozzy-info-circle-icon
                  v-if="item.inactiveBy === null && (checkAddVacany(item.id) && (getMarketingStatus(item) !== 'no') && (isAdminModule || isAccountingModule))"
                  v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.tooltip.addTenancy')"
                  class="mt-2" />
              </div>
            </div>
            <coozzy-dropdown-item
              v-if="!userWithoutRole && item.inactiveBy === null && checkAddTenancy(item.id)"
              :disabled="checkAvailableEndingAt(item.id)"
              @click="redirectToAddVacancy(item.id)">
              {{ $t('message.onBoarding.buildings.objects.addVacancy') }}
            </coozzy-dropdown-item>
          </template>
          <template v-if="showDeleteAction && isCompanyAdmin">
            <coozzy-dropdown-space-divider />
            <coozzy-dropdown-item
              v-if="showDeleteAction"
              @click="openModalDelete">
              {{ $t('message.generic.deleteObjectActionButton') }}
            </coozzy-dropdown-item>
          </template>
        </coozzy-dropdown>
      </div>
    </div>
    <marketing-object-basics
      v-if="editAdvertCall && building"
      :ref="`marketing-object-basics-${item.id}`"
      :key="`marketing-object-basics-${item.id}`"
      :object-selected="item"
      @edit-building="editBuildingFields" />
    <div
      id="titleObject"
      :ref="`titleObject`"
      v-b-toggle="`titleObject-${item.id}`"
      class="collapse-title">
      <h5 class="cursor-pointer">
        {{ $t('message.onBoarding.buildings.objects.titleObject') + ': ' + name }}
        <a
          v-if="!editAdvertCall && !isMarketingPath && !isAddTenancyPath && !isMarkForMartektingPath && !isAddVacancyPath && !isTermination"
          tabindex="0"
          class="lh-1 link-activity"
          @click="openActivities(); $event.stopPropagation()"
          @click.middle="openActivitiesMiddle()">
          <img
            height="15px"
            class="ml-3 mb-1"
            :src="`/img/ticket/new-window.svg`"
            alt="type">
          {{ $t('message.ticketOverview.activities.title') }}
        </a>
      </h5>
    </div>
    <b-collapse
      :id="`titleObject-${item.id}`"
      :key="`basic-${item.id}`"
      :ref="`collapse-${item.id}`"
      :visible="visible"
      @hidden="actionBtn = false"
      @shown="actionBtn = true">
      <div class="row w-100 m-0">
        <div :class="hasApplicationInfo ? 'col-12 col-md-9' : 'col-12 p-0'">
          <div
            :ref="`objBasicrow-${item.id}`"
            class="row objBasicrow py-01rem">
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="numericId"
                :disabled="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.buildings.objects.objectID')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="number"
                :is-read-only="readOnly"
                :disabled="isJanitor"
                :name="$t('message.onBoarding.buildings.objects.objectNumber')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <div v-if="readOnly && fromAdmin">
                <label for="type">{{ $t('message.onBoarding.buildings.objects.objectName') }}*</label>
                <a
                  tabindex="0"
                  @click="navigateToOverview(item)">
                  {{ name | displayOptionalValue }}
                </a>
              </div>
              <coozzy-form-input
                v-else
                v-model="name"
                :is-read-only="readOnly"
                :disabled="isJanitor"
                :name="$t('message.onBoarding.buildings.objects.objectName')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <label for="category">{{ $t('message.onBoarding.buildings.objects.category.title') }}{{ (isMarketingPath ? '*' : '') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="category"
                v-model="category"
                :disabled="isJanitor"
                :state="isMarketingPath && $v.category.$dirty && $v.category.$error ? false : null"
                @change="onCategoryChange">
                <option
                  v-if="!isMarketingPath"
                  value="UNDEFINED_CATEGORY" />
                <option
                  v-for="(categoryValue, categoryIndex) in translatedCategories"
                  :key="categoryIndex"
                  :value="categoryValue">
                  {{ $t(`message.onBoarding.buildings.objects.category.${categoryValue.toLowerCase()}`) }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{ category === '' || category === null || category === 'UNDEFINED_CATEGORY' ? '-' : $t(`message.onBoarding.buildings.objects.category.${category.toLowerCase()}`) }}</span>
            </div>
            <div class="col-sm-12 col-md-3">
              <label for="subCategory">{{ $t('message.onBoarding.buildings.objects.subCategory.title') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="subCategory"
                v-model="subCategory"
                :disabled="category=== null || category === '' || category === 'UNDEFINED_CATEGORY' || isJanitor">
                <option value="UNDEFINED_SUB_CATEGORY" />
                <option
                  v-for="(subCategoryValue, subCategoryIndex) in translatedSubCategories"
                  :key="subCategoryIndex"
                  :value="subCategoryValue.value">
                  {{ subCategoryValue.label }}
                  <!-- {{ $t('message.onBoarding.buildings.objects.subCategory.' + subCategoryValue.toLowerCase()) }} -->
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{ subCategory === '' || subCategory === null || subCategory === 'UNDEFINED_SUB_CATEGORY' ? '-' : $t(`message.onBoarding.buildings.objects.subCategory.${subCategory.toLowerCase()}`) }}</span>
            </div>
            <div
              v-if="category === 'HOUSE' || category === 'APARTMENT'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="ewid"
                type="number"
                :min="0"
                :limit="6"
                :is-read-only="readOnly"
                :disabled="isJanitor"
                :state="$v.ewid && $v.ewid.$dirty && $v.ewid.$error ? false : null"
                :name="$t('message.onBoarding.buildings.objects.ewid')" />
            </div>
            <div
              v-if="category === 'APARTMENT'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="awn"
                type="number"
                :min="0"
                :is-integer="true"
                :is-read-only="readOnly"
                :is-integer-format="true"
                :disabled="isJanitor"
                :name="$t('message.onBoarding.buildings.objects.awn')" />
            </div>
            <div
              v-if="category === 'COMMERCIAL' || category === 'GASTRONOMY' || category === 'PARKING_SPACE' || category === 'SECONDARY_ROOM' || category === 'HOUSE' || category === 'APARTMENT'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="dates.firstTimeOccupancy"
                type="date"
                :is-read-only="readOnly"
                :filter="'formatDate'"
                :disabled="isJanitor"
                :name="$t('message.onBoarding.buildings.objects.firstTimeOccupancy')"
                @blur="dateToTimestamp()" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                :value="!objectSelected.condominiumOwnership ? $t('message.onBoarding.buildings.objects.type.rent') : $t('message.manageBuilding.administrationType.condominium_ownership')"
                :disabled="true"
                :is-read-only="readOnly"
                :name="$t('message.onBoarding.buildings.objects.type.title')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <label for="type">{{ $t('message.onBoarding.buildings.objects.administrationType.title') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="administrationType"
                v-model="administrationType"
                :disabled="isJanitor">
                <option value="UNDEFINED_ADMINISTRATION_TYPE" />
                <option
                  v-for="(administrationTypeValue, administrationTypeIndex) in availableAdministrationTypes"
                  :key="administrationTypeIndex"
                  :value="administrationTypeValue">
                  {{ $t('message.onBoarding.buildings.objects.administrationType.' + administrationTypeValue) }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{ administrationType === '' || administrationType === null || administrationType === 'UNDEFINED_ADMINISTRATION_TYPE' ? '-' : $t(`message.onBoarding.buildings.objects.administrationType.${administrationType}`) }}</span>
            </div>
            <div
              v-if="category !== 'GARDEN' && category !== 'PLOT' && category !== 'AGRICULTURE'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-if="!readOnly"
                v-model="floor"
                type="number"
                :disabled="isJanitor"
                :state="$v.floor.$dirty && $v.floor.$error ? false : null"
                :name="$t('message.onBoarding.buildings.objects.floor') +'*'" />
              <div v-else>
                <label>{{ $t('message.onBoarding.buildings.objects.floor') }}</label>
                <span class="show-data">{{ formatFloor(floor) }}</span>
              </div>
            </div>
            <div
              v-if="category !== 'GARDEN' && category !== 'PLOT' && category !== 'AGRICULTURE' && category !== 'PARKING_SPACE'"
              class="col-sm-12 col-md-3">
              <label for="numberOfFloors">{{ $t('message.onBoarding.buildings.objects.numberOfFloors') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="numberOfFloors"
                v-model="numberOfFloors"
                :disabled="isJanitor">
                <option value="-1" />
                <option
                  v-for="index in valueNumberOfFloors"
                  :key="index"
                  :value="index">
                  {{ index === 20.5 ? '+20' : index }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{ numberOfFloors === '' || numberOfFloors === null ? '-' : (numberOfFloors === 20.5 ? '+20' : numberOfFloors ) }}</span>
            </div>
            <div
              v-if="category !== 'GARDEN' && category !== 'PLOT' && category !== 'AGRICULTURE' && category !== 'PARKING_SPACE'"
              class="col-sm-12 col-md-3">
              <label for="rooms">{{ $t('message.onBoarding.buildings.objects.rooms') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="rooms"
                v-model="rooms"
                :disabled="isJanitor">
                <option value="-1">
                  -
                </option>
                <option
                  v-for="index in valueRoom"
                  :key="index"
                  :value="index">
                  {{ index }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{ rooms === '' || rooms === null ? '-' : rooms }}</span>
            </div>
            <div
              v-if="category !== 'HOUSE' && category !== 'APARTMENT'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="effectiveArea"
                type="number"
                :min="0"
                placeholder="m²"
                :is-read-only="readOnly"
                :filter="'formatArea'"
                :is-decimal="true"
                :disabled="isJanitor"
                :check-valide="$v.effectiveArea.$dirty && $v.effectiveArea.$error ? 'is-invalid' : ''"
                :name="$t('message.onBoarding.buildings.objects.effectiveArea')" />
            </div>
            <div
              v-if="category !== 'GARDEN' && category !== 'COMMERCIAL' && category !== 'PLOT' && category !== 'AGRICULTURE' && category !== 'PARKING_SPACE' && category !== 'SECONDARY_ROOM' && category !== 'GASTRONOMY'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="livingArea"
                type="number"
                :min="0"
                placeholder="m²"
                :is-read-only="readOnly"
                :filter="'formatArea'"
                :is-decimal="true"
                :disabled="isJanitor"
                :check-valide="$v.livingArea.$dirty && $v.livingArea.$error ? 'is-invalid' : ''"
                :name="$t('message.onBoarding.buildings.objects.livingSpace')" />
            </div>
            <div
              v-if="category !== 'GARDEN' && category !== 'PLOT' && category !== 'AGRICULTURE'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="ceilingHeight"
                type="number"
                :min="0"
                placeholder="m"
                :is-read-only="readOnly"
                :filter="'formatMeterDecimal'"
                :is-decimal="true"
                :disabled="isJanitor"
                :check-valide="$v.ceilingHeight.$dirty && $v.ceilingHeight.$error ? 'is-invalid' : ''"
                :name="$t('message.onBoarding.buildings.objects.roomHeight')" />
            </div>
            <div
              v-if="category === 'APARTMENT' || category === 'COMMERCIAL' || category === 'HOUSE' || category === 'SECONDARY_ROOM'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input-numeric
                v-model="volume"
                type="number"
                :min="0"
                placeholder="m³"
                :is-read-only="readOnly"
                :filter="'formatVolume'"
                :is-decimal="true"
                :disabled="isJanitor"
                :check-valide="$v.volume.$dirty && $v.volume.$error ? 'is-invalid' : ''"
                :name="$t('message.onBoarding.buildings.objects.volume')" />
            </div>
            <div
              v-if="item.condominiumOwnership && !readOnly"
              class="col-sm-12 col-md-3">
              <label for="quota">
                {{ $t('message.onBoarding.buildings.objects.quote') }}
                <coozzy-info-circle-icon
                  v-b-tooltip.hover.html="$t('message.tableColumnHeadings.totalValueQuota') + ' ' + totalPropertyValueQuota"
                  class="mt-2" />
              </label>
              <coozzy-form-input-numeric
                id="quota"
                v-model="quota"
                type="number"
                :disabled="isJanitor"
                :is-decimal="true"
                :name="''" />
            </div>
            <div
              v-if="item.condominiumOwnership && readOnly"
              class="col-sm-12 col-md-3">
              <label for="quotaReadOnly">
                {{ $t('message.onBoarding.buildings.objects.quote') }}
                <coozzy-info-circle-icon
                  v-b-tooltip.hover.html="$t('message.tableColumnHeadings.totalValueQuota') + ' ' + totalPropertyValueQuota"
                  class="mt-2" />
              </label>
              <coozzy-form-input
                id="quotaReadOnly"
                v-model="quotaReadOnly"
                :is-read-only="true"
                :disabled="isJanitor"
                :name="''" />
            </div>
            <div
              v-if="category !== 'GARDEN' && category !== 'PLOT' && category !== 'AGRICULTURE' && category !== 'PARKING_SPACE'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="specialFeatures"
                :is-read-only="readOnly"
                :is-ellipsize="true"
                :disabled="isJanitor"
                :placeholder="$t('message.onBoarding.buildings.objects.specialFeatures.placeholder')"
                :name="$t('message.onBoarding.buildings.objects.specialFeatures.title')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <label for="type">{{ $t('message.onBoarding.buildings.objects.condition.title') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="condition"
                v-model="condition"
                :disabled="isJanitor">
                <option value="UNDEFINED_CONDITION" />
                <option
                  v-for="(conditionValue, conditionIndex) in availableCondition"
                  :key="conditionIndex"
                  :value="conditionValue">
                  {{ $t('message.onBoarding.buildings.objects.condition.' + conditionValue) }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{ condition === '' || condition === null || condition === 'UNDEFINED_CONDITION' ? '-' : $t(`message.onBoarding.buildings.objects.condition.${condition}`) }}</span>
            </div>
            <div
              class="col-sm-12 col-md-3">
              <label for="position">{{ $t('message.onBoarding.buildings.position') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="position"
                v-model="position"
                :disabled="isJanitor">
                <option value="UNDEFINED_LOCATION" />
                <option
                  v-for="(positionValue, positionIndex) in availablePositions"
                  :key="positionIndex"
                  :value="positionValue">
                  {{ $t('message.onBoarding.buildings.positionTypes.' + positionValue) }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{ position === '' || position === null || position === 'UNDEFINED_LOCATION' ? '-' : $t(`message.onBoarding.buildings.positionTypes.${position}`) }}</span>
            </div>
            <div
              v-if="readOnly"
              class="col-sm-12 col-md-3">
              <label for="type">{{ $t('message.onBoarding.buildings.objects.locationQualities.title') }}</label>
              <p
                v-if="getLocationQualities.length > 0"
                class="mb-0 multi-select-value">
                {{ getLocationQualities }}
              </p>
              <p
                v-else
                class="mb-0 multi-select-value">
                -
              </p>
            </div>
            <div
              v-else
              class="col-sm-12 col-md-3">
              <coozzy-multiselect
                v-model="locationQualities"
                :options="translatedLocationQualities"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :preselect-first="false"
                :taggable="false"
                :disabled="isJanitor"
                label="label"
                track-by="label">
                {{ $t('message.onBoarding.buildings.objects.locationQualities.title') }}
              </coozzy-multiselect>
            </div>
            <div
              v-if="category !== 'GARDEN' && category !== 'PARKING_SPACE' && category !== 'PLOT' && category !== 'AGRICULTURE'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="assignedSideRooms"
                :is-read-only="readOnly"
                :is-ellipsize="true"
                :disabled="isJanitor"
                :placeholder="$t('message.onBoarding.buildings.objects.assignedSideRooms.placeholder')"
                :name="$t('message.onBoarding.buildings.objects.assignedSideRooms.title')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="sharedUse"
                :is-read-only="readOnly"
                :is-ellipsize="true"
                :disabled="isJanitor"
                :placeholder="$t('message.onBoarding.buildings.objects.sharedUse.placeholder')"
                :name="$t('message.onBoarding.buildings.objects.sharedUse.title')" />
            </div>
            <div
              v-if="category === 'GARDEN' || category === 'PLOT' || category === 'AGRICULTURE' || category === 'PARKING_SPACE'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="specialFeatures"
                :is-read-only="readOnly"
                :is-ellipsize="true"
                :disabled="isJanitor"
                :placeholder="$t('message.onBoarding.buildings.objects.specialFeatures.placeholder')"
                :name="$t('message.onBoarding.buildings.objects.specialFeatures.title')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="unusualAgreement"
                :placeholder="$t('message.onBoarding.buildings.objects.unusualAgreement.placeholder')"
                :is-read-only="readOnly"
                :is-ellipsize="true"
                :disabled="isJanitor"
                :name="$t('message.onBoarding.buildings.objects.unusualAgreement.title')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <label for="type">{{ $t('message.onBoarding.buildings.objects.yearLastRenovation') }}</label>
              <coozzy-form-select
                v-if="!readOnly"
                id="yearLastRenovation"
                v-model="yearLastRenovation"
                :disabled="isJanitor">
                <option value="-1" />
                <option
                  v-for="year in years"
                  :key="year"
                  :value="year">
                  {{ year }}
                </option>
              </coozzy-form-select>
              <span
                v-else
                class="show-data">{{ yearLastRenovation === null ? '-' : yearLastRenovation }}</span>
            </div>
            <div class="col-sm-12 col-md-3">
              <label for="note">{{ $t('message.generic.note') }}</label>
              <coozzy-form-textarea
                id="note"
                ref="note"
                v-model="note"
                :initial="note"
                :value-text-area="note"
                :show-label="false"
                :is-read-only="readOnly"
                :disabled="isJanitor"
                name="note" />
            </div>
            <div class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="dates.debitUntil"
                :is-read-only="readOnly"
                :disabled="true"
                type="date"
                :filter="'formatDate'"
                :name="$t('message.generic.debitPositionUntil')" />
            </div>
            <div class="col-sm-12 col-md-3">
              <label class="w-100"> {{ $t('message.generic.tags') }} </label>
              <coozzy-form-tag
                v-if="!readOnly && !isJanitor"
                v-model="tags"
                :initial="tags"
                :suggestions="tagsList"
                @suggestionselected="onSuggestionSelected" />
              <span
                v-else
                class="show-data">
                {{ tags.length > 0 ? tags.join(', ') : '-' }}
              </span>
            </div>
            <div
              v-if="category === 'APARTMENT' || category === 'COMMERCIAL' || category === 'HOUSE'"
              class="col-sm-12 col-md-3">
              <coozzy-form-input
                v-model="otoId"
                :is-read-only="readOnly"
                :is-ellipsize="true"
                :disabled="isJanitor"
                :tooltip-text="$t('message.onBoarding.buildings.objects.otoId.tooltip')"
                :name="$t('message.onBoarding.buildings.objects.otoId.title')" />
            </div>
          </div>
          <div
            v-if="!((isAdminModule || isMarketingPath) && ($route.path.includes('/addTenancy') || $route.name === 'MarketingEditAdvertView'))"
            class="row details-row-bg-2 py-01rem">
            <div class="col-3 m-0">
              <div class="row">
                <div
                  v-if="!readOnly"
                  class="d-flex col-sm-12 col-md-5 mt-2">
                  <coozzy-form-checkbox
                    id="inactive"
                    :key="item.id+'inactive'"
                    v-model="inactive"
                    :initial="inactive"
                    :disabled="(lastTenancy && !lastTenancy.endingAt) || isJanitor"
                    class="align-self-center mt-3">
                    {{ $t('message.onBoarding.buildings.objects.inactive') }}
                    <span v-if="lastTenancy && !lastTenancy.endingAt && !isJanitor">
                      <coozzy-info-circle-icon
                        v-b-tooltip="{title : $t('message.onBoarding.buildings.objects.inactiveTooltip')}" />
                    </span>
                  </coozzy-form-checkbox>
                </div>
                <div
                  v-else
                  class="col-sm-12 col-md-5">
                  <label>
                    {{ $t('message.onBoarding.buildings.objects.inactive') }}
                  </label>
                  <span class="show-data">
                    {{ inactive ? $t('message.generic.selectField.yes') : $t('message.generic.selectField.no') }}
                  </span>
                </div>
                <div
                  v-if="inactive"
                  class="col-sm-12 col-md-7">
                  <label />
                  <coozzy-form-input
                    v-model="dates.inactiveBy"
                    :is-read-only="readOnly"
                    type="date"
                    :filter="'formatDate'"
                    :disabled="isJanitor"
                    :state="$v.inactiveBy && $v.inactiveBy.$dirty && $v.inactiveBy.$error ? false : null"
                    @blur="dateToObject()" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="hasApplicationInfo"
          class="col-12 col-md-3">
          <h5 class="application-img-title mb-4">
            {{ $t('message.onBoarding.buildings.objects.digitalApplicationform') }}
          </h5>
          <img
            class="d-block mx-auto cursor-pointer img-shahow application-img"
            src="/img/application-info.png"
            alt="Placeholder"
            @click="$bvModal.show('openApplicationInfoModal')">
          <a
            class="float-right application-download mt-2"
            @click="$bvModal.show('openApplicationInfoModal')">
            <coozzy-file-download-icon />
            {{ $t('message.generic.download') }}
          </a>
        </div>
      </div>
      <b-modal
        :id="'confirmationActive'"
        :ref="`confirmationActive-${item.id}`"
        no-close-on-backdrop
        hide-footer
        :title="$t('message.onBoarding.buildings.objects.inactivePopup.title')">
        <div class="col">
          <p>{{ $t('message.onBoarding.buildings.objects.inactivePopup.text') }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="$bvModal.hide('confirmationActive')">
            {{ $t('message.onBoarding.buildings.objects.inactivePopup.ok') }}
          </coozzy-button>
        </div>
      </b-modal>
      <b-modal
        :id="'deleteModal'"
        :ref="`deleteModal-${item.id}`"
        no-close-on-backdrop
        hide-footer
        :title="$t('message.onBoarding.deleteModals.confirmation')">
        <div class="col">
          <p>{{ $t('message.onBoarding.deleteModals.bodyObject') }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('deleteModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="removeConfirmed">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </b-modal>
    </b-collapse>
    <reminder-creation-modal
      :ref="`reminder-creation-${item.id}`"
      :source="'object'"
      :references-ids="[item.id]" />
    <b-modal
      v-if="hasApplicationInfo"
      :id="'openApplicationInfoModal'"
      ref="openApplicationInfoModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.buildings.objects.digitalApplicationform')">
      <template>
        <div class="col-12">
          <p>{{ $t('message.advertisement.action.titleModal') }}</p>
        </div>
        <div class="col-12 mt-3 mb-3">
          <coozzy-form-select
            v-model="language">
            <option
              v-for="option in optionsLanguages"
              :key="option.key"
              :value="option.key">
              {{ option.value }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('openApplicationInfoModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="applicationInfoCLicked">
            {{ $t('message.generic.confirm') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <create-activity-modal
      ref="activity-creation"
      :key="keyActivity"
      :ids="[objectSelected.id]" />
  </section>
</template>

<script>

import { onboarding } from '@/mixins/onboarding'
import { formatting } from '@/mixins/formatting'
import { minValue, required, maxLength } from 'vuelidate/lib/validators'
import MarketingObjectBasics from '@/marketing/MarketingObjectBasics'
import CoozzyFormSelect from '../../../framework/components/form/select/CoozzyFormSelect'
import CoozzyMultiselect from '../../../framework/components/multiselect/CoozzyMultiselect'
import CoozzyFormInput from '../../../framework/components/form/input/CoozzyFormInput'
import Vue from 'vue'
import ObjectApi from '@/misc/apis/ObjectApi'
import { validation } from '@/mixins/validation'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyFormInputNumeric from '@/framework/components/form/input/CoozzyFormInputNumeric'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import CoozzyDropdownSpaceDivider from '@/framework/components/dropdown/CoozzyDropdownSpaceDivider'
import { toastError } from '@/mixins/toastError'
import { routeChecks } from '@/mixins/routeChecks'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import CoozzyFileDownloadIcon from '@/framework/components/icons/CoozzyFileDownloadIcon'
import { obj } from '@/mixins/object'
import { dateUtils } from '@/mixins/dateUtils'
import { user } from '@/mixins/user'
import CreateActivityModal from '@/contact/components/CreateActivityModal.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'
import CoozzyFormTag from '@/framework/components/form/input/CoozzyFormTag.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'

function moreThanLastEnding(value) {
  if (!value) {
    return true
  }
  if (!this.lastTenancy) {
    return true
  }
  if (!this.inactive || !this.lastTenancy.endingAt) {
    return true
  }
  return new Date(this.$options.filters.objectToDateInput(value)).getTime() > new Date(this.$options.filters.objectToDateInput(this.lastTenancy.endingAt)).getTime()
}

function validateFloor(value) {
  return Number.isInteger(value) || value === 0 || value === 0.5 || value === -0.5
}

export default {
  name: 'ObjectBasic',
  components: { CoozzyFormCheckbox, CoozzyFormTag, CoozzyFormTextarea, CoozzyInfoCircleIcon, CoozzySpinner, CreateActivityModal, CoozzyFileDownloadIcon, CoozzyDropdownSpaceDivider, CoozzyDropdownItem, CoozzyDropdown, CoozzyFormInputNumeric, CoozzyButton, CoozzyFormInput, CoozzyMultiselect, CoozzyFormSelect, MarketingObjectBasics, ReminderCreationModal },
  mixins: [routeChecks, onboarding, formatting, validation, obj, user, dateUtils, toastError],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    objectSelected: {
      type: Object,
      default: null
    },
    expandByDefault: {
      type: Boolean,
      default: true
    },
    showDeleteAction: {
      type: Boolean,
      default: true
    },
    editAdvertCall: {
      type: Boolean,
      default: false
    },
    hasApplicationInfo: {
      type: Boolean,
      default: false
    },
    fromAdmin: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      keyActivity: 0,
      loading: false,
      item: this.objectSelected,
      isChanged: false,
      firstCheck: false,
      valWatch: [],
      lastTenancy: null,
      dates: {
        firstTimeOccupancy: null,
        debitUntil: null,
        inactiveBy: null
      },
      inactive: false,
      actionBtn: this.visible,
      availablePositions: ['LEFT', 'RIGHT', 'MIDDLE', 'MIDDLE_FRONT', 'MIDDLE_LEFT', 'MIDDLE_LEFT_LEFT', 'MIDDLE_RIGHT', 'MIDDLE_RIGHT_RIGHT', 'NORTH', 'SOUTH', 'WEST', 'EAST', 'NORTH_WEST', 'NORTH_EAST', 'SOUTH_WEST', 'SOUTH_EAST'],
      availableCategories: ['APARTMENT', 'HOUSE', 'COMMERCIAL', 'GASTRONOMY', 'PARKING_SPACE', 'GARDEN', 'SECONDARY_ROOM', 'PLOT', 'AGRICULTURE'],
      availableSubCategories: {
        AGRICULTURE: ['AGRICULTURAL_INSTALLATION', 'MOUNTAIN_FARM', 'FARM'],
        APARTMENT: ['DUPLEX', 'ATTIC_FLAT', 'ROOF_FLAT', 'STUDIO', 'SINGLE_ROOM', 'FURNISHED_FLAT', 'TERRACE_FLAT', 'BACHELOR_FLAT', 'LOFT', 'ATTIC', 'RETIREMENT_APARTMENT'],
        GARDEN: ['ALOTTMENT_GARDEN'],
        GASTRONOMY: ['HOTEL', 'RESTAURANT', 'BAR', 'CLUB', 'CASINO', 'MOVIE', 'SQUASH', 'INDOOR_TENNIS', 'TENNIS', 'SPORTS_HALL', 'CAMP_GROUND', 'OUTDOOR_SWIMMING_POOL', 'INDOOR_SWIMMING_POOL', 'GOLF_COURSE', 'MOTEL', 'PUB', 'FITNESS_CENTER'],
        HOUSE: ['SINGLE_HOUSE', 'BIFAMILIAR_HOUSE', 'TERRANCE_HOUSE', 'VILLA', 'FARM_HOUSE', 'MULTIPLE_DWELLING', 'CAVE_HOUSE', 'CASTLE', 'CHALET', 'RUSTICO', 'GRANNY_FLAT', 'STOECKLI'],
        COMMERCIAL: ['ARCHIVE_ROOM', 'ANTENNA_SYSTEM', 'TRAINING_ROOM', 'TOILET_FACILITY', 'OFFICE', 'SHOP', 'ADVERTISING_AREA', 'COMMERCIAL_SUB_CATEGORY', 'STORAGE_ROOM', 'PRACTICE', 'KIOSK', 'GARDENING', 'FUEL_STATION', 'CAR_REPAIR_SHOP', 'CHEESE_FACTORY', 'BUTCHER', 'BAKERY', 'HAIRDRESSER', 'SHOPPING_CENTER', 'FACTORY', 'INDUSTRIAL_OBJECT', 'ARCADE', 'LIVING_COMMERCIAL_BUILDING', 'LIBRARY', 'HOSPITAL', 'LABORATORY', 'MINI_GOLD_COURSE', 'NURSING_HOME', 'RIDING_HALL', 'SANATORIUM', 'PARTY_ROOM', 'SAUNA', 'SOLARIUM', 'CARPENTRY_SHOP', 'OLD_AGE_HOME', 'DEPARTMENT_STORE', 'HOME', 'DISPLAY_WINDOW', 'PARKING_GARAGE', 'PARKING_SURFACE', 'WORKSHOP', 'STAFF_ROOM', 'SERVER_ROOM', 'MEETING_ROOM', 'COMMERCIAL_STORAGE_ROOM', 'ATELIER'],
        PARKING_SPACE: ['BICYCLE_PARKING', 'VISITOR_PARKING_LOT', 'HANDICAPPED_INDOOR_PARKING', 'HANDICAPPED_OUTDOOR_PARKING', 'DOUBLE_OUTDOOR_PARKING', 'DOUBLE_INDOOR_PARKING', 'OPEN_SLOT', 'COVERED_SLOT', 'SINGLE_GARAGE', 'DOUBLE_GARAGE', 'UNDERGROUND_SLOT', 'BOAT_DRY_DOCK', 'BOAT_LANDING_STAGE', 'COVERED_PARKING_PLACE_BIKE', 'OUTDOOR_PARKING_PLACE_BIKE', 'HORSE_BOX', 'BOAT_MOORING', 'CUSTOMER_PARKING', 'ELECTRIC_PARKING_SPACE'],
        PLOT: ['BUILDING_LAND', 'AGRICULTURAL_LAND', 'COMMERCIAL_LAND', 'INDUSTRIAL_LAND'],
        SECONDARY_ROOM: ['STORAGE_ROOM', 'JANITOR_ROOM', 'LAUNDRY_ROOM', 'HOBBY_ROOM', 'CELLAR_COMPARTMENT', 'ATTIC_COMPARTMENT', 'BUILDING_SERVICES']
      },
      availableAdministrationTypes: ['LETTING', 'INITIAL_LETTING', 'PARTIAL_LETTING', 'CONDOMINIUM_OWNERSHIP'],
      availableCondition: ['FIRST_OCCUPANCY', 'FIRST_OCCUPANCY_AFTER_RESTORATION', 'EXCELLENT', 'DILAPIDATED', 'LIVEABLE', 'WELL_KEPT', 'GOOD', 'MODERNIZED', 'LIKE_NEW', 'PASSABLE', 'PARTLY_RENOVATED', 'PARTLY_NEED_OF_RENOVATION', 'PARTLY_RESTORATED', 'PARTLY_NEED_OF_RESTORATION', 'FULLY_RENOVATED', 'FULLY_RESTORATED'],
      availableLocationQualities: ['PARTICULARLY_QUIET', 'SUNNY', 'BEAUTIFUL_VIEW', 'HILLSIDE', 'LAKESIDE', 'UNOBSTRUCTABLE', 'FOREST', 'COMMERCIAL_CENTER', 'PEDESTRIAN_AREA', 'CENTRAL', 'WELL_DEVELOPED'],
      availableTypes: [
        'OTHER',
        'APPLICATION_FORM',
        'HANDOVER_PROTOCOL',
        'CONTRACT',
        'BUILDING_PLAN',
        'INVOICE',
        'HOUSE_REGULATION',
        'INSTRUCTION_MANUAL',
        'CREDIT_CHECK',
        'OWNERS_MEETING',
        'PAY_IN_SLIP',
        'ENERGY_CERTIFICATE',
        'WARRANTY_CERTIFICATE',
        'BANK_STATEMENT',
        'ADDITIONAL_COSTS_BILL',
        'SEPA_DIRECT_DEBIT_MANDATE',
        'AGREEMENT',
        'DEPOSIT_CONFIRMATION',
        'BUILDING_INSURANCE_POLICY',
        'KEY_LIST',
        'TERMINATION_DOC_TYPE',
        'RENT_ADJUSTMENT_DOC_TYPE',
        'REFERENCES_DOC_TYPE',
        'MAINTENANCE_CONTRACT_DOC_TYPE',
        'REGULATIONS',
        'REGULATIONS_FOR_USE_AND_MANAGEMENT',
        'ANNUAL_FINANCIAL_STATEMENT',
        'PURCHASE_CONTRACT',
        'LAND_REGISTER',
        'CONDOMINIUM_OWNERSHIP',
        'RENEWAL_FOND',
        'MORTGAGE',
        'ORDER',
        'OFFER',
        'IDENTITY',
        'EXTRACT_FROM_DEBT_COLLECTION_REGISTER',
        'BANK_ACCOUNT_DETAILS',
        'SITUATION_PLAN',
        'CONSTRUCTION_LAW_AGREEMENT',
        'EASEMENT_AGREEMENT',
        'CADASTRAL_ESTIMATE',
        'LETTER',
        'TRADE_REGISTER_EXCERPT',
        'PAYMENT_REMINDER',
        'PROTOCOL',
        'COURT_DOC_TYPE',
        'HONORARIUM',
        'BUDGET'
      ],
      language: 'DE',
      tagsList: []
    }
  },
  computed: {
    optionsLanguages() {
      return [
        { key: 'EN', value: this.$t('message.language.english') },
        { key: 'DE', value: this.$t('message.language.german') },
        { key: 'IT', value: this.$t('message.language.italian') }]
    },
    usedLanguage() {
      return this.currentLanguage
    },
    years () {
      const year = new Date().getFullYear()
      return Array.from({ length: year - 1900 }, (value, index) => 1901 + index)
    },
    isMarketingPath() {
      return this.$route.name.startsWith('Marketing')
    },
    isAddTenancyPath() {
      return this.$route.name.includes('AddTenancy')
    },
    isAddVacancyPath() {
      return this.$route.name.includes('AddVacancy')
    },
    isMarkForMartektingPath() {
      return this.$route.path.includes('marketing/mark/')
    },
    isTermination() {
      return this.$route.path.includes('enterTermination')
    },
    numericId() {
      return this.item.numericId
    },
    number: {
      get() {
        return this.item.number
      },
      set(value) {
        const object = this.item
        object.number = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    ewid: {
      get() {
        return this.item.ewid
      },
      set(value) {
        const object = this.item
        object.ewid = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    awn: {
      get() {
        return this.item.awn !== -1 && this.item.awn !== '-1' && this.item.awn !== '' && this.item.awn !== null
? parseInt(this.item.awn)
: null
      },
      set(value) {
        const object = this.item
        object.awn = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    name: {
      get() {
        return this.item.name
      },
      set(value) {
        const object = this.item
        object.name = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    type: {
      get() {
        return this.item.type
      },
      set(value) {
        const object = this.item
        object.type = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    category: {
      get() {
        return this.item.category
      },
      set(value) {
        const object = this.item
        object.category = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    subCategory: {
      get() {
        return this.item.subCategories.length > 0
? this.item.subCategories[0]
: null
      },
      set(value) {
        const object = this.item
        object.subCategories = [value]
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    administrationType: {
      get() {
        return this.item.administrationType
      },
      set(value) {
        const object = this.item
        object.administrationType = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    condition: {
      get() {
        return this.item.characteristics &&
        this.item.characteristics.condition
          ? this.item.characteristics.condition
          : null
      },
      set(value) {
        const object = this.item
        if (!object.characteristics.condition) {
          object.characteristics.condition = {}
        }
        object.characteristics.condition = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    position: {
      get() {
        return this.item.positions && this.item.positions.length > 0
? this.item.positions[0]
: 'UNDEFINED_LOCATION'
      },
      set(value) {
        const object = this.item
        object.positions[0] = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    yearLastRenovation: {
      get() {
        if (this.item.characteristics.yearLastRenovation === -1 && this.renovations.length > 0) {
          const maxEndDate = Math.max(...this.renovations.map(renovation => renovation.end))
          return new Date(maxEndDate * 1000).getFullYear()
        } else {
          return this.item.characteristics.yearLastRenovation !== -1
? this.item.characteristics.yearLastRenovation
: null
        }
      },
      set(value) {
        const object = this.item
        object.characteristics.yearLastRenovation = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    floor: {
      get() {
        if (this.item.characteristics.decimalFloor && this.item.characteristics.decimalFloor !== -10000) {
          return this.item.characteristics.decimalFloor
        } else {
          return this.item.characteristics.floor !== -10000 ? this.item.characteristics.floor : null
        }
      },
      set(value) {
        const object = this.item
        if (value === '-0') {
          object.characteristics.floor = 0
          object.characteristics.decimalFloor = -0.5
          this.$store.dispatch('onboarding/updateObject', object)
        } else {
          object.characteristics.floor = parseInt(value, 10)
          object.characteristics.decimalFloor = Math.round(parseFloat(value) * 2) / 2
          this.$store.dispatch('onboarding/updateObject', object)
        }
      }
    },
    livingArea: {
      get() {
        return this.item.characteristics &&
        this.item.characteristics.areas &&
        this.item.characteristics.areas.livingArea !== -1
          ? this.item.characteristics.areas.livingArea
          : null
      },
      set(value) {
        const object = this.item
        if (!object.characteristics.areas) {
          object.characteristics.areas = {}
        }
        object.characteristics.areas.livingArea = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    effectiveArea: {
      get() {
        return this.item.characteristics &&
        this.item.characteristics.areas &&
        this.item.characteristics.areas.effectiveArea !== -1
          ? this.item.characteristics.areas.effectiveArea
          : null
      },
      set(value) {
        const object = this.item
        if (!object.characteristics.areas) {
          object.characteristics.areas = {}
        }
        object.characteristics.areas.effectiveArea = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    volume: {
      get() {
        return this.item.characteristics.volume !== -1
          ? parseFloat(this.item.characteristics.volume)
          : null
      },
      set(value) {
        const object = this.item
        object.characteristics.volume = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    quota: {
      get() {
        return this.item.valueQuota !== -1
? this.item.valueQuota
: null
      },
      set(value) {
        const object = this.item
        object.valueQuota = value === null
? -1
: value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    quotaReadOnly: {
      get() {
        return this.item.valueQuotaWithTotal !== -1
? this.item.valueQuotaWithTotal
: null
      }
    },
    assignedSideRooms: {
      get() {
        return this.item.assignedSideRooms !== -1
? this.item.assignedSideRooms
: null
      },
      set(value) {
        const object = this.item
        object.assignedSideRooms = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    sharedUse: {
      get() {
        return this.item.sharedUse !== -1
? this.item.sharedUse
: null
      },
      set(value) {
        const object = this.item
        object.sharedUse = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    specialFeatures: {
      get() {
        return this.item.specialFeatures !== -1
? this.item.specialFeatures
: null
      },
      set(value) {
        const object = this.item
        object.specialFeatures = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    unusualAgreement: {
      get() {
        return this.item.unusualAgreement !== -1
? this.item.unusualAgreement
: null
      },
      set(value) {
        const object = this.item
        object.unusualAgreement = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    rooms: {
      get() {
        return this.item.characteristics.rooms !== -1
? this.item.characteristics.rooms
: null
      },
      set(value) {
        const object = this.item
        object.characteristics.rooms = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    numberOfFloors: {
      get() {
        return this.item.characteristics.numberOfFloors !== -1
? this.item.characteristics.numberOfFloors
: null
      },
      set(value) {
        const object = this.item
        object.characteristics.numberOfFloors = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    ceilingHeight: {
      get() {
        return this.item.characteristics.ceilingHeight !== -1
? this.item.characteristics.ceilingHeight
: null
      },
      set(value) {
        const object = this.item
        object.characteristics.ceilingHeight = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    firstTimeOccupancy: {
      get() {
        return this.item.characteristics.firstTimeOccupancy !== '-1'
? this.item.characteristics.firstTimeOccupancy
: null
      },
      set(value) {
        const object = this.item
        object.characteristics.firstTimeOccupancy = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    inactiveBy: {
      get() {
        return this.item.inactiveBy
      },
      set(value) {
        const object = this.item
        object.inactiveBy = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    note: {
      get() {
        return this.item.note
      },
      set(value) {
        const object = this.item
        object.note = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    debitUntil() {
      return this.item.debitUntil
    },
    locationQualities: {
      get() {
        return this.item.characteristics.locationQualities.map(locationQuality => {
          return {
            label: this.$t('message.onBoarding.buildings.objects.locationQualities.' + locationQuality),
            value: locationQuality
          }
        })
      },
      set(value) {
        const object = this.item
        object.characteristics.locationQualities = value.map(v => v.value)
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    getLocationQualities() {
      const array = []
      this.item.characteristics.locationQualities.forEach(locationQuality => {
        array.push(this.$t('message.onBoarding.buildings.objects.locationQualities.' + locationQuality))
      })
      return array.join(', ')
    },
    translatedLocationQualities() {
      const array = []
      this.availableLocationQualities.forEach(locationQuality => {
        array.push({
          label: this.$t('message.onBoarding.buildings.objects.locationQualities.' + locationQuality),
          value: locationQuality
        })
      })
      return array
    },
    tags: {
      get() {
        return this.item.tags
      },
      set(value) {
        const object = this.item
        object.tags = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    otoId: {
      get() {
        return this.item?.otoId ?? ''
      },
      set(value) {
        const object = this.item
        object.otoId = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    translatedSubCategories() {
      if (this.category !== null && this.category !== 'UNDEFINED_CATEGORY') {
        const arr = this.availableSubCategories[this.category].map(sub => {
          return {
            label: this.$t('message.onBoarding.buildings.objects.subCategory.' + sub.toLowerCase()),
            value: sub
          }
        })
        arr.sort((a, b) => {
          if (this.$t(`message.onBoarding.buildings.objects.subCategory.${a.value.toLowerCase()}`) < this.$t(`message.onBoarding.buildings.objects.subCategory.${b.value.toLowerCase()}`)) {
            return -1
          }
          if (this.$t(`message.onBoarding.buildings.objects.subCategory.${a.value.toLowerCase()}`) > this.$t(`message.onBoarding.buildings.objects.subCategory.${b.value.toLowerCase()}`)) {
            return 1
          }
          return 0
        })
        return arr
      }
      return []
    },
    translatedCategories() {
      const array = this.availableCategories
      array.sort((a, b) => {
        if (this.$t(`message.onBoarding.buildings.objects.category.${a.toLowerCase()}`) < this.$t(`message.onBoarding.buildings.objects.category.${b.toLowerCase()}`)) {
          return -1
        }
        if (this.$t(`message.onBoarding.buildings.objects.category.${a.toLowerCase()}`) > this.$t(`message.onBoarding.buildings.objects.category.${b.toLowerCase()}`)) {
          return 1
        }
        return 0
      })
      return array
    },
    valueRoom() {
      const array = []
      for (let index = 1; index < 20; index += 0.5) {
        array.push(index)
      }
      return array
    },
    valueNumberOfFloors() {
      const array = []
      for (let index = 1; index < 21; index += 0.5) {
        array.push(index)
      }
      return array
    },
    floorPlan: function () {
      return this.getFilteredObjectDocuments(this.item.documents.filter(doc => doc.isFloorPlan === true && doc.isPublic === true).map(d => d.documentId), this.documentType, this.documentName).sort((a, b) => (a.name > b.name
? 1
: -1))
    },
    parking: function () {
      return this.item.parking
    },
    renovations: function () {
      return this.item.renovations
    },
    vacant: {
      get() {
        return this.item.vacant
      },
      set(value) {
        const object = this.item
        object.vacant = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    published: {
      get() {
        return this.item.published
      },
      set(value) {
        const object = this.item
        object.published = value
        this.$store.dispatch('onboarding/updateObject', object)
      }
    },
    totalPropertyValueQuota() {
      const listBuildings = this.$store.getters['onboarding/getPropertyBuildings']
      if (listBuildings.length > 0) {
        return listBuildings.reduce((accumulator, object) => {
          return accumulator + object.valueQuota
        }, 0)?.toFixed(2)
      }
      return 0
    }
  },
  watch: {
    inactive: function (newVal) {
      if (newVal && (this.dates.inactiveBy === '' || this.dates.inactiveBy === null)) {
        if (this.lastTenancy) {
          let tempDate = new Date(this.$options.filters.objectToDateInput(this.lastTenancy.endingAt))
          tempDate = tempDate.setDate(tempDate.getDate() + 1)
          this.inactiveBy = this.toObject(tempDate)
          this.dates.inactiveBy = this.$options.filters.objectToDateInput(this.inactiveBy)
          localStorage.removeItem('saveAfterInactive')
        }
      } else if (!newVal) {
        this.inactiveBy = null
        this.dates.inactiveBy = null
        localStorage.setItem('saveAfterInactive', this.item.numericId)
      }
    },
    category: function (newVal, oldVal) {
      if ((oldVal === 'HOUSE' || oldVal === 'APARTMENT') && (newVal !== 'APARTMENT' && newVal !== 'HOUSE')) {
        this.livingArea = -1
      } else if ((oldVal !== 'HOUSE' && oldVal !== 'APARTMENT') && (newVal === 'APARTMENT' || newVal === 'HOUSE')) {
        this.effectiveArea = -1
      }
      if (['GARDEN', 'PLOT', 'AGRICULTURE'].includes(newVal)) {
        this.floor = '0'
      }
    },
    livingArea: function (newVal) {
      if (this.category === 'HOUSE' || this.category === 'APARTMENT') {
        this.volume = parseFloat((parseFloat(newVal) * parseFloat(this.ceilingHeight)).toFixed(2))
      }
    },
    ceilingHeight: function (newVal) {
      if (this.category === 'HOUSE' || this.category === 'APARTMENT') {
        this.volume = parseFloat((parseFloat(newVal) * parseFloat(this.livingArea)).toFixed(2))
      } else if (this.category === 'COMMERCIAL' || this.category === 'SECONDARY_ROOM' || this.category === 'GASTRONOMY') {
        this.volume = parseFloat((parseFloat(newVal) * parseFloat(this.effectiveArea)).toFixed(2))
      }
    },
    effectiveArea: function (newVal) {
      if (this.category === 'COMMERCIAL' || this.category === 'SECONDARY_ROOM' || this.category === 'GASTRONOMY') {
        this.volume = parseFloat((parseFloat(newVal) * parseFloat(this.ceilingHeight)).toFixed(2))
      }
    }
  },
  mounted() {
    document.body.onmousedown = function(e) {
      if (e.button === 1) {
        e.preventDefault()
        return false
      }
    }
    this.dates.firstTimeOccupancy = this.$options.filters.timestampToDateInput(this.firstTimeOccupancy)
    this.dates.debitUntil = this.$options.filters.objectToDateInput(this.debitUntil)
    if (this.inactiveBy) {
      this.dates.inactiveBy = this.$options.filters.objectToDateInput(this.inactiveBy)
      this.inactive = true
    }
    if (this.objectSelected.condominiumOwnership) {
      this.availableAdministrationTypes = ['CONDOMINIUM_OWNERSHIP']
      this.objectSelected.type = 'SALE'
      if (this.administrationType === 'UNDEFINED_ADMINISTRATION_TYPE') {
        this.administrationType = 'CONDOMINIUM_OWNERSHIP'
      }
    } else {
      this.availableAdministrationTypes = this.availableAdministrationTypes.filter(x => x !== 'CONDOMINIUM_OWNERSHIP')
    }
    if ((this.category === 'HOUSE' || this.category === 'APARTMENT') && this.ceilingHeight && this.livingArea && !this.volume) {
      this.volume = parseFloat(this.ceilingHeight * this.livingArea).toFixed(2)
    }
    this.$watch(vm => [vm.numericId, vm.ewid, vm.name, vm.category, vm.subCategory, vm.administrationType, vm.condition, vm.position, vm.yearLastRenovation, vm.floor, vm.livingArea, vm.effectiveArea, vm.volume, vm.assignedSideRooms, vm.sharedUse, vm.specialFeatures, vm.unusualAgreement, vm.rooms, vm.numberOfFloors, vm.ceilingHeight, vm.firstTimeOccupancy, vm.inactiveBy, vm.locationQualities, vm.vacant, vm.published, vm.otoId], {
      immediate: true, // run immediately
      deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      handler: function (val) {
        if (JSON.stringify(val) !== JSON.stringify(this.valWatch)) {
          if (this.firstCheck) {
            this.isChanged = true
          }
          this.valWatch = val
          this.firstCheck = true
        }
      }
    })
    this.$nextTick(function () {
      this.loadTags()
      this.language = this.$store.getters['user/getCurrentUser']['https://coozzy.ch/lang'].toUpperCase() || 'DE'
    })
    if (this.readOnly) {
      this.fillWholesInGreyLines()
    }
  },
  methods: {
    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const objectId = this.objectSelected.id
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { objectIds: objectId, source: 'object' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { objectIds: objectId, source: 'object' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { objectIds: objectId, source: 'object' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { objectIds: objectId, source: 'object' } }, () => {})
      }
    },
    showActivity() {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.$nextTick(() => {
        this.$refs['activity-creation'].show()
      })
    },
    openActivities() {
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerActivitiesTicketView', params: { id: this.objectSelected?.id }, query: { view: 'tickets' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminActivitiesTicketView', params: { id: this.objectSelected?.id }, query: { view: 'tickets' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetActivitiesTicketView', params: { id: this.objectSelected?.id }, query: { view: 'tickets' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingActivitiesTicketView', params: { id: this.objectSelected?.id }, query: { view: 'tickets' } }, () => {})
      } else {
        this.$router.push({ name: 'MarketingActivitiesTicketView', params: { id: this.objectSelected.id }, query: { view: 'tickets' } }, () => {})
      }
    },
    openActivitiesMiddle() {
      let routeData = ''
      if (this.isOwnerModule) {
        routeData = this.$router.resolve({ name: 'OwnerActivitiesTicketView', params: { id: this.objectSelected?.id }, query: { view: 'tickets' } }, () => {})
      } else if (this.isAdminModule) {
        routeData = this.$router.resolve({ name: 'AdminActivitiesTicketView', params: { id: this.objectSelected?.id }, query: { view: 'tickets' } }, () => {})
      } else if (this.isAssetModule) {
        routeData = this.$router.resolve({ name: 'AssetActivitiesTicketView', params: { id: this.objectSelected?.id }, query: { view: 'tickets' } }, () => {})
      } else if (this.isAccountingModule) {
        routeData = this.$router.resolve({ name: 'AccountingActivitiesTicketView', params: { id: this.objectSelected?.id }, query: { view: 'tickets' } }, () => {})
      } else {
        routeData = this.$router.resolve({ name: 'MarketingActivitiesTicketView', params: { id: this.objectSelected.id }, query: { view: 'tickets' } }, () => {})
      }
      window.open(routeData.href, '_blank')
    },
    editBuildingFields(data) {
      this.$emit('edit-building', data)
    },
    onSuggestionSelected() {
      this.$store.dispatch('onboarding/updateObject', this.item)
    },
    createTicket() {
      const defaultAssignee = this.building?.ownerId
      const list = [this.objectSelected?.id]
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      }
    },
    dateToObject() {
      this.inactiveBy = this.toObject(this.dates.inactiveBy)
    },
    redirectToTerminationView() {
      localStorage.setItem('termination-page', 'objects')
      this.$route.path.startsWith('/admin/buildings/') > 0
? this.$router.push({ name: 'AdminEnterTerminationView', params: { objectId: this.lastTenancy.objectId, tenancyId: this.lastTenancy.id, buildingId: this.building.id } })
: this.$router.push({ name: 'AccountingEnterTerminationView', params: { objectId: this.lastTenancy.objectId, tenancyId: this.lastTenancy.id, buildingId: this.building.id } })
    },
    onCategoryChange() {
      this.subCategory = 'UNDEFINED_SUB_CATEGORY'
    },
    createReminder() {
      this.$refs['reminder-creation-' + this.item.id].show()
    },
    checkAddTenancy(id) {
      const filteredTenancies = this.getFilteredTenancies(id)
      return filteredTenancies.length === 0 || filteredTenancies[0].endingAt
    },
    checkAddVacany(id) {
      const filteredTenancies = this.getFilteredTenancies(id)
      return filteredTenancies.length === 0 || filteredTenancies[0].endingAt || (filteredTenancies[0].vacancy && !filteredTenancies[0].endingAt)
    },
    checkAvailableEndingAt(id) {
      const filteredTenancies = this.getFilteredTenancies(id)
      return filteredTenancies.length > 0 && filteredTenancies.filter(x => !x.endingAt).length > 0
    },
    checkLastTenancyForTermination(id) {
      const t = this
      const tenancy = this.getFilteredTenancies(id).sort(function (a, b) {
        return new Date(t.$options.filters.objectToDateInput(b.startingAt)).getTime() - new Date(t.$options.filters.objectToDateInput(a.startingAt)).getTime()
      }).slice(0, 1)
      this.lastTenancy = tenancy.length > 0
? tenancy[0]
: null
      return tenancy.length > 0 && tenancy[0].id && (tenancy[0].contract.type === 'LIMITED' || tenancy[0].endingAt === null) && tenancy[0].activated && tenancy[0].status === 'TENANCY_STATUS_CURRENT' && !tenancy[0].vacancy
    },
    redirectToAddTenancy(id) {
      if (this.isAssetModule) {
        this.$router.push({ name: 'AssetAddTenancyView', params: { objectId: id } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingAddTenancyView', params: { objectId: id } })
      } else {
        this.$router.push({ name: 'AdminAddTenancyView', params: { objectId: id } })
      }
    },
    redirectToAddVacancy(id) {
      if (this.isAssetModule) {
        this.$router.push({ name: 'AssetAddVacancyView', params: { objectId: id } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingAddVacancyView', params: { objectId: id } })
      } else {
        this.$router.push({ name: 'AdminAddVacancyView', params: { objectId: id } })
      }
    },
    openModalDelete() {
      this.$refs['deleteModal-' + this.item.id].show()
    },
    dateToTimestamp() {
      this.firstTimeOccupancy = this.dates.firstTimeOccupancy
? this.toTimestamp(this.dates.firstTimeOccupancy)
: -1
    },
    toTimestamp(strDate) {
      return Date.parse(strDate) / 1000
    },
    async removeConfirmed() {
      this.removeObjects([this.item])
        .then(() => {
          this.item = null
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.object'), { type: 'error' })
        })
    },
    navigateToOverview(item) {
      ObjectApi.getBuildingByObjectId(item.id).then(response => {
        if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetBuildingDetailsView',
            params: { id: response.building.id },
            query: { view: 'objects', elementSelected: item.id }
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingBuildingDetailsView',
            params: { id: response.building.id },
            query: { view: 'objects', elementSelected: item.id }
          })
        } else {
          this.$router.push({
            name: 'AdminBuildingDetailsView',
            params: { id: response.building.id },
            query: { view: 'objects', elementSelected: item.id }
          })
        }
      }).catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
      })
    },
    isInvalid() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.scrollToError()
      }
      return this.$v.$invalid
    },
    isCollapseOpen() {
      const collapse = this.$refs['collapse' + this.item.id]
      if (collapse) {
        return collapse.visible
      }
      return false
    },
    async applicationInfoCLicked() {
      this.loading = true
      this.$bvModal.hide('openApplicationInfoModal')
      const blob = new Blob([await ObjectApi.getApplicationInfo(this.item.id, this.language)], { type: 'application/pdf' })
      const blobURL = URL.createObjectURL(blob)
      window.open(blobURL)
      this.loading = false
    },
    markForMarketingClicked(id) {
      const isInMarketingStatus = this.getMarketingStatus(this.item) !== 'no'
      this.$router.push({
        name: this.isAdminModule
? 'AdminMarkForMarketingView'
: 'AccountingMarkForMarketingView',
        params: {
 id: id,
from: this.isAdminModule
? 'adminBuilding'
: 'acountingBuilding'
},
        query: { editMarketingInfoMode: isInMarketingStatus }
      })
    },
    fillWholesInGreyLines() {
      if (this.$refs['objBasicrow-' + this.item.id]?.children?.length % 4 !== 0) {
        const div = document.createElement('div')
        div.classList.add('col-sm-12', 'col-md-3', 'd-sm-none', 'd-md-block')
        this.$refs['objBasicrow-' + this.item.id].appendChild(div)
        this.fillWholesInGreyLines()
      } else {
        this.paintWhiteAndGreyLines()
      }
    },
    paintWhiteAndGreyLines() {
      if (this.$refs['objBasicrow-' + this.item.id]) {
        const arrayOfChildren = this.$refs['objBasicrow-' + this.item.id].children
        for (let i = 0; i < arrayOfChildren.length; i++) {
          if (arrayOfChildren[i]) {
            if ((i === 0) || (parseInt((i / 4)) % 2) === 0) {
              arrayOfChildren[i].classList.add('grey-col')
              arrayOfChildren[i].style.backgroundColor = 'rgba(0, 68, 95, 0.03)'
            } else {
              arrayOfChildren[i].classList.add('white-col')
            }
          }
        }
      }
    },
    loadTags() {
      ObjectApi.getUsedTags(this.accountId)
        .then(response => {
          this.tagsList = response.tags
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  validations() {
    const validationObject = {
      livingArea: {
        minValue: minValue(0)
      },
      effectiveArea: {
        minValue: minValue(0)
      },
      volume: {
        minValue: minValue(0)
      },
      ceilingHeight: {
        minValue: minValue(0)
      }
    }
    if (this.inactive) {
      validationObject.inactiveBy = {
        required,
        moreThanLastEnding
      }
    }
    if (this.isMarketingPath) {
      validationObject.category = { required }
    }
    if (this.category === 'HOUSE' || this.category === 'APARTMENT') {
      validationObject.ewid = { maxLength: maxLength(6) }
    }
    if (this.category !== 'GARDEN' && this.category !== 'PLOT' && this.category !== 'AGRICULTURE') {
      validationObject.floor = {
        required,
        validateFloor
      }
    }
    return validationObject
  }
}
</script>

<style lang="scss" scoped>
.action-object {
  top: -0.5rem;
  right: 3rem;
}
.application-img {
  max-height: 411px;
  max-width: 75%;
  &.img-shahow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
.application-img-title {
  line-height: 12px;
  font-weight: 500 !important;
  margin-left: 12%;
  font-size: large;
}
.application-download {
  margin-right: 12.25%;
}
.grey-col{
  background:  $color-table-row-2;
}
.white-col{
 background:  $color-table-row-1;
}
:deep(.width_16r) {
  width: 16rem;
}
:deep(.width_14r) {
  width: 14rem;
}
</style>
