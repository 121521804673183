<template>
  <coozzy-card :title="$t('message.settings.emailFont.title')">
    <div
      v-if="loading"
      class="row">
      <div class="col">
        <coozzy-spinner />
      </div>
    </div>
    <template v-else>
      <div class="row">
        <div class="col-sm-12 col-md-3">
          <label for="fontFamily">{{ $t('message.settings.emailFont.fontFamily') }}</label>
          <coozzy-form-select
            v-model="fontSettings.family"
            :options="fontOptions"
            :name="$t('message.settings.emailFont.fontFamily')" />
        </div>
        <div class="col-sm-12 col-md-3">
          <label for="fontSize">{{ $t('message.settings.emailFont.fontSize') }}</label>
          <coozzy-form-select
            v-model="fontSettings.size"
            :options="sizeOptions"
            :name="$t('message.settings.emailFont.fontSize')" />
        </div>
        <div class="col">
          <coozzy-button
            class="float-right mt-3"
            design="primary"
            @click="setFontSettings">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
        </div>
      </div>
    </template>
  </coozzy-card>
</template>

<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import UserApi from '@/misc/apis/UserApi'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue' // Ensure you have this component
import { user } from '@/mixins/user'
import Vue from 'vue'

export default {
  name: 'EmailFontSettings',
  components: {
    CoozzySpinner,
    CoozzyButton,
    CoozzyFormSelect,
    CoozzyCard
  },
  mixins: [user],
  data: () => {
    const fontOptionString = 'Lato=Lato, sans-serif; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats; Afacad=afacad,sans-serif; Marcellus=marcellus,serif; Poppins=poppins,sans-serif; Raleway=raleway,sans-serif;'
    const fontOptions = fontOptionString.split('; ').map(option => {
      const [text, value] = option.split('=')
      return { text, value }
    })
    const sizeOptions = []
    for (let i = 4; i <= 36; i++) {
      sizeOptions.push({ text: `${i}pt`, value: i.toString() })
    }
    return {
      loading: false,
      fontSettings: {
        family: '',
        size: ''
      },
      fontOptions,
      sizeOptions
    }
  },
  computed: {
  },
  watch: {},
  mounted() {
    this.getFontSettings()
    if (localStorage.getItem('showToastAfterSavingFontSetting')) {
      Vue.toasted.show(this.$t('message.settings.emailFont.savedSuccessfully'), { type: 'success' })
      localStorage.removeItem('showToastAfterSavingFontSetting')
    }
  },
  methods: {
    getFontSettings() {
      this.loading = true
      UserApi.getFontSettings(this.accountId)
        .then(response => {
          this.fontSettings = response
        })
        .catch(e => {
          if (!e.response || e.response.status !== 404) {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.signature'), { type: 'error' })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    setFontSettings() {
      UserApi.setFontSettings(this.accountId, this.fontSettings)
        .then(() => {
          localStorage.setItem('showToastAfterSavingFontSetting', 'true')
          window.location.reload()
        })
        .catch(e => {
          if (!e.response || e.response.status !== 404) {
            console.log(e)
            Vue.toasted.show(this.$t('message.settings.emailFont.signature'), { type: 'error' })
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
