// @ts-nocheck
import store from '../store/store'
import algoliasearch from 'algoliasearch/lite'
import 'instantsearch.css/themes/algolia-min.css'
import { history } from '../misc/custumRouter'
import { mapActions, mapState } from 'vuex'
import SearchApi from '@/misc/apis/SearchApi'

const algolia = {
  data() {
    return {
      searchClient: null,
      searchClientInternal: null,
      unauthorizedMode: false,
      nbrPerPage: 10,
      executedRefinements: [],
      routing: {
        router: history({
          createURL({
            qsModule,
            location,
            routeState
          }) {
            const {
              origin,
              pathname,
              hash
            } = location
            const searchState = qsModule.parse(location.search.slice(1))
            const combinedState = Object.assign(searchState, routeState)
            const queryString = qsModule.stringify(combinedState)

            const base = `${origin}${pathname}`
            if (queryString) {
              return `${base}?${queryString}${hash}`
            }

            return `${base}${hash}`
          },
          parseURL({
            qsModule,
            location
          }) {
            return qsModule.parse(location.search.slice(1))
          }
        })
      },
      inputProps: {
        placeholder: this.$t('message.generic.search'),
        class: 'form-control form-control-sm',
        id: 'autosuggest__input'
      }
    }
  },
  computed: {
    ...mapState('algolia', ['key', 'unauthorizedKey'])
  },
  watch: {
    nbrPerPage: function (newVal) {
      localStorage.setItem('algolia-page-' + this.$route.name, newVal)
    },
    key: function (newVal) {
      if (this.unauthorizedMode === false) {
        this.searchClient = algoliasearch(
          process.env.VUE_APP_ALGOLIA_APP_ID,
          newVal
        )
      }
      this.searchClientInternal = algoliasearch(
        process.env.VUE_APP_ALGOLIA_APP_ID,
        newVal
      )
    },
    unauthorizedKey: function (newVal) {
      if (this.unauthorizedMode === true) {
        this.searchClient = algoliasearch(
          process.env.VUE_APP_ALGOLIA_APP_ID,
          newVal
        )
      }
    }
  },
  mounted: function () {
    localStorage.setItem('algolia-pagination-mounted', true)
    if (localStorage.getItem('algolia-page-' + this.$route.name)) {
      this.nbrPerPage = parseInt(localStorage.getItem('algolia-page-' + this.$route.name))
    }
  },
  methods: {
    ...mapActions('algolia', ['loadKey', 'loadUnauthorizedKey']),
    async loadInstantSearch(unauthorizedSearchClient = false) {
      this.unauthorizedMode = unauthorizedSearchClient
      if (this.unauthorizedMode === false) {
        if (!this.key) {
          await this.loadKey(store.getters['user/getAccountId'])
        } else {
          this.searchClient = algoliasearch(
            process.env.VUE_APP_ALGOLIA_APP_ID,
            this.key
          )
        }
      } else {
        if (!this.unauthorizedKey) {
          await this.loadUnauthorizedKey()
        } else {
          this.searchClient = algoliasearch(
            process.env.VUE_APP_ALGOLIA_APP_ID,
            this.unauthorizedKey
          )
        }
      }
    },
    async loadInstantSearchInternal(unauthorizedSearchClient = false) {
      this.unauthorizedMode = unauthorizedSearchClient
      if (!this.key) {
        await this.loadKey(store.getters['user/getAccountId'])
      } else {
        this.searchClientInternal = algoliasearch(
          process.env.VUE_APP_ALGOLIA_APP_ID,
          this.key
        )
      }
    },
    defaultRefining(value, items) {
      if (!this.executedRefinements.includes(value)) {
        if (value === 'searchEndingAtFilter') {
          this[value] = items.filter(obj => obj.isRefined).map(x => x.value)
          this.defaultDateEndFilter = items.filter(obj => obj.isRefined).map(x => x.value)
        } else if (value === 'searchStartingAtFilter') {
          this[value] = items.filter(obj => obj.isRefined).map(x => x.value)
          this.defaultStartDateFilter = items.filter(obj => obj.isRefined).map(x => x.value)
        } else if (value === 'searchDebitUntilFilter') {
          this[value] = items.filter(obj => obj.isRefined).map(x => x.value)
          this.defaultDebitUntilFilter = items.filter(obj => obj.isRefined).map(x => x.value)
        } else {
          this[value] = items.filter(obj => obj.isRefined)
        }
        this.executedRefinements.push(value)
      }
      return ''
    },
    refineWithNegativeNumbers(refine, item) {
      if (item < 0) {
        const str = '\\-' + Math.abs(item)
        refine(str)
      } else {
        refine(item)
      }
    },
    customLabelAssignee(item) {
      if (item.value === 'Unassigned Nicht zugeordnet Non assegnato') {
        return `${this.$t('message.generic.unassigned')} (${item.count})`
      }
      return `${item.value} (${item.count})`
    },
    async sleep(timeInMs) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), timeInMs)
      })
    },
    async blockUntilUpdateFinished(entityIds) {
      // Initially sleep for short period so search service can pick up event
      await this.sleep(100)
      let ongoing = await SearchApi.algoliaUpdateOngoing(entityIds)
      if (ongoing === true) {
        // We check 15 times if update was done. If not finished we just exit so user can continue to work
        for (let i = 0; i < 30; i++) {
          if (ongoing === false) {
            break
          }
          await this.sleep(500 + (70 * i))
          ongoing = await SearchApi.algoliaUpdateOngoing(entityIds)
        }
        await this.sleep(250)
      }
    },
    getAllAvailableDataByIndex(data: object) {
        return new Promise(resolve => {
          if (this.searchClient && data) {
            const index = this.searchClient.initIndex(data.index)
            index.search(data.query, {
              attributesToRetrieve: data.attributesToRetrieve,
              params: data.params
            }).then(({ hits }) => {
              resolve(hits)
            })
          }
        })
    },
    editAlgoliaParams(data: string) {
      let params = decodeURIComponent(data)
      // I do multiple test and I observe that the param hitsPerPage is always in the same position with maxValuesPerFacet
      // in this function we remove the param hitsPerPage and add it at the end of the string with value 1000
      const index1 = params.indexOf('hitsPerPage')
      const index2 = params.indexOf('maxValuesPerFacet')
      let ParamsToRemoved = params.substring(index1, index2)
      params = params.replace(ParamsToRemoved, '')
      params += '&hitsPerPage=1000'
      // remove the param page
      const index3 = params.indexOf('&page=')
      const index4 = params.indexOf('&tagFilters')
      ParamsToRemoved = params.substring(index3, index4)
      params = params.replace(ParamsToRemoved, '')
      params = params.replace(/\+/g, '%2B')
      return params
    }
  }
}

export { algolia }
